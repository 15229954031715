import React, { useEffect, useMemo, useState } from 'react'
import {
  InsightRevenueAlbumTemplate,
  RootTemplate,
} from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import moment from 'moment'

interface Props {
  logout: Function;
  user: Object;
}

const InsightRevenueAlbumPage = ({ user, logout, match }: Props) => {
  const { authStore, revenueStore, artistStore } = useStore()
  const { currentUser } = authStore
  const { albumRevenueData } = revenueStore
  const { artistDetail } = artistStore

  const fetchAlbumRecentMonth = revenueStore.fetchAlbumRecentMonth || (() => {})
  const fetchRevenueAristAlbumData =
    revenueStore.fetchRevenueAristAlbumData || (() => {})
  const fetchRevenueCompanyAlbumData =
    revenueStore.fetchRevenueCompanyAlbumData || (() => {})
  const fetchRevenueAlbumTrackData =
    revenueStore.fetchRevenueAlbumTrackData || (() => {})

  const { albumRecentMonth } = revenueStore

  const recentDate = useMemo(() => {
    if (!albumRecentMonth) return moment()
    return moment().set({
      year: `20${Math.floor(albumRecentMonth / 100)}`,
      month: (albumRecentMonth % 100) - 1,
    })
  }, [albumRecentMonth])

  const [currentMonth, setCurrentMonth] = useState(null)

  const handleMonth = value => {
    setCurrentMonth(moment(value))
  }

  useEffect(() => {
    if (albumRecentMonth) {
      setCurrentMonth(
        moment().set({
          year: `20${Math.floor(albumRecentMonth / 100)}`,
          month: (albumRecentMonth % 100) - 1,
        }),
      )
    }
  }, [albumRecentMonth])

  useEffect(() => {
    if (!currentMonth && currentUser && match.params.id) {
      fetchAlbumRecentMonth(currentUser?.artistId, match.params.id)
    }

    if (currentUser && currentMonth && match.params.id) {
      if (currentUser?.companyId && match.path.includes('/company/')) {
        fetchRevenueCompanyAlbumData(currentUser?.companyId, match.params.id, {
          __year: currentMonth.format('YY'),
        })
      }
      else {
        fetchRevenueAristAlbumData(currentUser?.artistId, match.params.id, {
          __year: currentMonth.format('YY'),
        })
      }
    }
  }, [
    match,
    currentUser,
    currentMonth,
    fetchAlbumRecentMonth,
    fetchRevenueAristAlbumData,
    fetchRevenueAlbumTrackData,
    fetchRevenueCompanyAlbumData,
  ])

  return (
    <RootTemplate user={user} logout={logout} artist={artistDetail}>
      <InsightRevenueAlbumTemplate
        albumRevenueData={albumRevenueData}
        currentMonth={currentMonth}
        handleMonth={handleMonth}
        recentDate={recentDate}
        isCompany={match.path.includes('/company/')}
      />
    </RootTemplate>
  )
}

export default observer(InsightRevenueAlbumPage)
