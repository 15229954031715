import { observable } from 'mobx'

export default class ArtistFollowersInfoModel {
  @observable _id
  @observable artistId
  @observable createdAt
  @observable updatedAt
  @observable currentFollowerCountDetail
  @observable increaseValue

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.artistId = props.artistId
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.currentFollowerCountDetail = props.currentFollowerCountDetail
      this.increaseValue = props.increaseValue
    }
  }
}
