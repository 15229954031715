import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Flex,
  Text,
  H4,
  Container,
  Button,
  Image,
  H5,
  H3,
  H6,
} from '@components/atoms'
import {
  AlbumRevenueStatsChart,
  RevenueTrackInflowTableChart,
  RevenueAlbumDetailTableChart,
  RevenueAdjacencyStatsPieChart,
  RevenueUploadModal,
  AlbumValuationInfoModal,
} from '@components/organisms'
import { colors } from '@colors/'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import moment from 'moment'
import 'moment/locale/ko'
import CountUp from 'react-countup'
import { inject, observer } from 'mobx-react'
import { path_menu, add_btn } from '@images/'
import { STORAGE_URL } from '@consts/'
import { convertToPricingComma } from '@utils/format'

const copy_btn_img = require('@images/copy_img_gray.png')

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 0 0 0;
`

const SectionTitle = styled(Text)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  margin-top: 50px;
  margin-bottom: 20px;
`

const InfoCard = styled(Flex)`
  width: 234px;
  height: 167px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #fcfcfc;
  padding: 5px;
  margin: 0 50px 0 0;
  justify-content: center;
`

const InsightRevenueAlbumTemplate = ({
  authStore,
  albumRevenueData,
  currentMonth,
  handleMonth,
  recentDate,
  isCompany,
}) => {
  const [showUpload, setShowUpload] = useState(false)
  const [isShowValuation, setIsShowValuation] = useState(false)

  const albumInfo = albumRevenueData || null

  const customUrlInfo = albumRevenueData?.customUrlInfo || null

  const valuationInfo = albumRevenueData?.valuationInfo || null

  const albumRevenueInfo =
    albumRevenueData?.revenueInfo?.albumRevenueInfo[0] || null

  const albumRevenueInfoByHitType =
    albumRevenueData?.revenueInfo?.albumRevenueInfoByHitType || []

  const albumRevenueInfoByCountryCode =
    albumRevenueData?.revenueInfo?.albumRevenueInfoByCountryCode || []

  const albumRevenueInfoByServiceChannel =
    albumRevenueData?.revenueInfo?.albumRevenueInfoByServiceChannel || []

  const albumMonthlyRevenueInfo =
    albumRevenueData?.revenueInfo?.albumMonthlyRevenueInfo || []

  const displayMonth = currentMonth?.format('YYYY')

  moment.locale('ko')

  const isLoginedMaster = authStore.isAdminPassword

  return (
    <Container>
      <Header>
        <Text type="Medium" size="18px" style={{ marginTop: '10px' }}>
          앨범별 나의 수익
        </Text>
        {
          isLoginedMaster && (
            <HeaderBtn onClick={() => setShowUpload(true)}>
              <HeaderBtnImg src={add_btn} alt="add_btn" />
              <Text type="Medium" size="14px" color={colors.main}>
              업로드
              </Text>
            </HeaderBtn>
          )
        }
      </Header>
      <Content>
        <Flex>
          <Flex type="column">
            <Flex>
              <Image
                src={`${STORAGE_URL}${albumInfo?.image512Path}`}
                width="160px"
                height="160px"
                borderRadius="6px"
                marginRight="30px"
              />
              <Flex type="column">
                <H5 align="left" color={colors.grey94}>
                  {albumInfo?.title}
                </H5>
                <H3 align="left">{albumRevenueData?.title}</H3>
                <Flex style={{ marginTop: 4 }}>
                  <H6>{`권리사 : ${albumInfo?.planningCompanyList[0]?.name}`}</H6>
                  <H6>
                    &nbsp;&nbsp;&nbsp;
                    {
                      `유통사 : ${albumInfo?.releaseCompanyList
                      && albumInfo?.releaseCompanyList[0]?.name}`
                    }
                  </H6>
                </Flex>
                <a
                  href={`https://plam.in/album/${customUrlInfo?.dataObjectId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Flex
                    align="center"
                    justify="center"
                    style={
                      {
                        width: 28,
                        height: 28,
                        backgroundColor: '#f4f4f4',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        marginTop: 12,
                      }
                    }
                  >
                    <img
                      src={copy_btn_img}
                      alt="copy"
                      style={
                        {
                          width: '20px',
                          height: '20px',
                        }
                      }
                    />
                  </Flex>
                </a>
              </Flex>
            </Flex>
            <Flex justify="space-between">
              <SectionTitle>
                <Image
                  src={path_menu}
                  alt="prev"
                  width="18px"
                  height="18px"
                  transform="rotate(180deg)"
                  cursor="pointer"
                  onClick={() => handleMonth(currentMonth.subtract(1, 'years'))}
                />
                {displayMonth}
                <Image
                  src={path_menu}
                  alt="next"
                  width="18px"
                  height="18px"
                  opacity={
                    moment().isSameOrBefore(currentMonth, 'year') ? 0.5 : 1
                  }
                  cursor={
                    moment().isSameOrBefore(currentMonth, 'year')
                      ? 'not-allowed'
                      : 'pointer'
                  }
                  onClick={
                    () => {
                      if (moment().isSameOrBefore(currentMonth, 'year')) return
                      handleMonth(currentMonth.add(1, 'years'))
                    }
                  }
                />
              </SectionTitle>
              <SectionTitle
                style={
                  {
                    fontFamily: 'NotoSansCJKkr-Medium',
                    textDecoration: 'underline',
                    position: 'relative',
                  }
                }
                onMouseEnter={() => setIsShowValuation(true)}
                onMouseLeave={() => setIsShowValuation(false)}
              >
                {
                  `${recentDate?.format(
                  '(YYYY년 MM월 기준)',
                )} 해당 앨범의 가치 평가액은 `
                }
                <strong style={{ fontFamily: 'NotoSansCJKkr-Bold' }}>
                  {
                    convertToPricingComma(
                      Math.floor(valuationInfo?.dayWeightedTotalValue),
                    )
                  }
                  원
                </strong>
                {` 입니다.`}
                {
                  isShowValuation && (
                    <AlbumValuationInfoModal
                      isShowing={isShowValuation}
                      valuationInfo={valuationInfo}
                    />
                  )
                }
              </SectionTitle>
            </Flex>
            <Flex>
              <InfoCard type="column">
                <H4>누적 총 수익</H4>
                <H4
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '20px' }}
                >
                  {
albumRevenueInfo?.totalRevenue && (
                      <CountUp
                        start={0}
                        end={Math.floor(albumRevenueInfo.totalRevenue)}
                        separator=","
                        duration={3}
                      />
                    )
                  }
                  원
                </H4>
              </InfoCard>
              <InfoCard type="column">
                <H4>연간 총 수익</H4>
                <H4
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '20px' }}
                >
                  <CountUp
                    start={0}
                    end={
                      Math.floor(
                        (albumRevenueInfo?.annualAdjacencyFee || 0)
                        + (albumRevenueInfo?.annualCopyRightFee || 0)
                        + (albumRevenueInfo?.annualPerformanceRightFee || 0),
                      )
                    }
                    separator=","
                    duration={3}
                  />
                  원
                </H4>
              </InfoCard>
              <InfoCard type="column">
                <H4>연간 인접권료</H4>
                <H4
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '20px' }}
                >
                  {
albumRevenueInfo?.annualAdjacencyFee && (
                      <CountUp
                        start={0}
                        end={Math.floor(albumRevenueInfo.annualAdjacencyFee)}
                        separator=","
                        duration={3}
                      />
                    )
                  }
                  원
                </H4>
              </InfoCard>
              <InfoCard type="column">
                <H4>연간 저작권료</H4>
                <H4
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '20px' }}
                >
                  {
albumRevenueInfo?.annualCopyRightFee && (
                      <CountUp
                        start={0}
                        end={Math.floor(albumRevenueInfo.annualCopyRightFee)}
                        separator=","
                        duration={3}
                      />
                    )
                  }
                  원
                </H4>
              </InfoCard>
              <InfoCard type="column">
                <H4>연간 실연권료</H4>
                <H4
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '20px' }}
                >
                  {
albumRevenueInfo?.annualPerformanceRightFee && (
                      <CountUp
                        start={0}
                        end={
                          Math.floor(
                            albumRevenueInfo.annualPerformanceRightFee,
                          )
                        }
                        separator=","
                        duration={3}
                      />
                    )
                  }
                  원
                </H4>
              </InfoCard>
            </Flex>
          </Flex>
        </Flex>
        <AlbumRevenueStatsChart
          currentMonth={currentMonth}
          data={albumMonthlyRevenueInfo}
        />

        <Flex>
          <Flex type="column">
            <SectionTitle style={{ marginTop: 0 }}>
              활동 유형별 수익 분석
            </SectionTitle>
            <RevenueTrackInflowTableChart data={albumRevenueInfoByHitType} />
          </Flex>
          <RevenueAdjacencyStatsPieChart
            data={albumRevenueInfoByCountryCode}
            data2={albumRevenueInfoByServiceChannel}
          />
        </Flex>

        <Flex type="column" style={{ marginTop: '50px' }}>
          <SectionTitle>세부 내역</SectionTitle>
          <RevenueAlbumDetailTableChart isCompany={isCompany} />
        </Flex>
      </Content>
      <RevenueUploadModal
        show={showUpload}
        handleClose={() => setShowUpload(false)}
      />
    </Container>
  )
}

export default inject('authStore')(observer(InsightRevenueAlbumTemplate))

const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: #ffffff;
`

const HeaderBtnImg = styled(Image)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`
