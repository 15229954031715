import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Text, H4, H3, Container, Button } from '@components/atoms'
import { colors } from '@colors/'

import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'

import moment from 'moment'
import 'moment/locale/ko'
import CountUp from 'react-countup'
import { DailyInflowSmartLinkChart } from '@components/organisms/Chart/DailyInflowSmartLinkChart'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`

const HeaderLinkBox = styled(Flex)`
  margin-top: 10px;
  align-items: center;
  padding: 0 6px 0 20px;
  height: 48px;
  border-radius: 24px;
  border: solid 2px #f4f4f4;
  background-color: #fff;
`

const LinkButton = styled(Button)`
  margin: 0 0 0 12px;
  height: 36px;
  padding: 9px 15px;
  border-radius: 18px;
  background-color: #f4f4f4;

  font-size: 12px;
  color: #242424;
`

const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 0 0 0;
`

const SectionTitle = styled(Text)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  margin-bottom: 20px;
`

const InfoCard = styled(Flex)`
  width: 318px;
  height: 167px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #fcfcfc;
  padding: 5px;
  margin: 0 50px 0 0;
  justify-content: center;
`

export const InsightSmartLinkTemplate = ({
  DailyInflowStatsList,
  sumInflow,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  link,
}) => {
  const [focusedInput, setFocusedInput] = useState(null)

  moment.locale('ko')
  return (
    <Container>
      <Header>
        <HeaderLinkBox>
          <Text type="Medium" size="18px">
            {link}
          </Text>
          <LinkButton onClick={() => window.open(link)}>링크로 이동</LinkButton>
        </HeaderLinkBox>
      </Header>
      <Content>
        <Flex>
          <Flex type="column">
            <SectionTitle>전체 인사이트</SectionTitle>
            <Flex>
              <InfoCard type="column">
                <H4>유입수</H4>
                <H3
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '20px' }}
                >
                  {sumInflow.sumFromCount && (
                    <CountUp
                      start={0}
                      end={Math.floor(sumInflow.sumFromCount)}
                      duration={3}
                    />
                  )}
                </H3>
              </InfoCard>
              <InfoCard type="column">
                <H4>전환수</H4>
                <H3
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '20px' }}
                >
                  {sumInflow.sumClickCount && (
                    <CountUp
                      start={0}
                      end={Math.floor(sumInflow.sumClickCount)}
                      duration={3}
                    />
                  )}
                </H3>
              </InfoCard>
              <InfoCard type="column">
                <H4>CTR(전환율)</H4>
                <H3
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '20px' }}
                >
                  {sumInflow.sumClickCount && (
                    <CountUp
                      start={0}
                      end={Math.floor(
                        (sumInflow.sumClickCount / sumInflow.sumFromCount) *
                        100,
                      )}
                      suffix="%"
                      duration={3}
                    />
                  )}
                </H3>
              </InfoCard>
            </Flex>
          </Flex>
          <Flex type="column">
            <SectionTitle>기간 조회</SectionTitle>
            <DateRangePicker
              startDateId="startDate"
              endDateId="endDate"
              startDate={startDate}
              endDate={endDate}
              onDatesChange={({ startDate, endDate }) => {
                setStartDate(startDate)
                setEndDate(endDate)
              }}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => {
                setFocusedInput(focusedInput)
              }}
              startDatePlaceholderText="YYYY / MM / DD"
              endDatePlaceholderText="YYYY / MM / DD"
              regular
              displayFormat={'YYYY/MM/DD'}
              isOutsideRange={() => false}
              minimumNights={0} // 같은 날짜를 고를 수 있게 해줌.
            />
          </Flex>
        </Flex>
        <DailyInflowSmartLinkChart data={DailyInflowStatsList} />
      </Content>
    </Container>
  )
}
