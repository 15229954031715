import styled from 'styled-components'
import { Flex } from '@components/atoms'

export const NoHeaderBox = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100vw;
`
