import { Network } from '.'

export default class FaqNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getList(params) {
    try {
      const { data } = await this.network._axiosApiAuth(`/faqs`, 'get', params)
      return data || []
    }
    catch (error) {
      console.warn('FaqNetwork getList error', error)
    }
  }

  async put(id, body) {
    if (!id || !body) return

    try {
      const { data } = await this.network._axiosApiAuth(`/faqs/${id}`, 'put', body)
      return data || null
    }
    catch (error) {
      console.warn('FaqNetwork put error', `id=${id}`, error)
    }
  }

  async post(body) {
    try {
      const { data } = await this.network._axiosApiAuth(`/faqs`, 'post', [body])
      return (data && data.length > 0 && data[0]) || null
    }
    catch (error) {
      console.warn('FaqNetwork post error', error)
    }
  }

  async delete(id) {
    try {
      const { data } = await this.network._axiosApiAuth(`/faqs/${id}`, 'delete')
      return data || null
    }
    catch (error) {
      console.warn('FaqNetwork delete error', error)
    }
  }

}
