import React, { useState, useRef, useEffect } from 'react'
import { Table, Input, Space, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { convertToPricingComma } from '@utils/format'
import { isArray } from 'lodash'
import { Link } from 'react-router-dom'
import { Caption2, Flex } from '@components/atoms'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { STORAGE_URL } from '@consts'

const copy_btn_img = require('@images/copy_img_gray.png')

const CompanyRevenueAdjacencyTrackTableChart = ({
  currentYear,
  currentMonth,
  handleMoveArtist,
  handleMoveAlbum,
}) => {
  const { authStore, revenueStore } = useStore()
  const { currentUser: user } = authStore
  const { companyTrackRevenueData: data, selectedCompanyAristId } = revenueStore

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const searchInput = useRef(null)

  // 검색 기능
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={
            e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (isArray(dataIndex)) {
        return record[dataIndex[0]][dataIndex[1]]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }
  // --end 검색기능

  useEffect(() => {
    if (user && user._id && currentMonth) {
      revenueStore.fetchRevenueCompanyTrackData(user.companyId, {
        // __year: `${currentYear?.format('YY00')}`,
        __month: `${currentMonth?.format('YYMM')}`,
        __artistIdList: selectedCompanyAristId
          ? `${selectedCompanyAristId}`
          : undefined,
      })
    }
  }, [user, revenueStore, currentMonth, selectedCompanyAristId])

  const sortHandler = (a, b, sortOrder) => {
    if (a < b) {
      return -1
    }
    else if (a > b) {
      return 1
    }
    else {
      return 0
    }
  }

  return (
    <Table
      className="inflow_table"
      columns={
        [
          {
            title: '트랙명',
            key: 'title',
            dataIndex: 'title',
            sorter: (a, b, sortOrder) => {
              return sortHandler(a?.title, b?.title)
            },
            ...getColumnSearchProps('title'),
            width: '21%',
            align: 'left',
            render: (text, record) => (
              <Link
                to={`/insight/revenue/track/${record?._id}?isCompany=1&artistId=${record.artistId}`}
              >
                <Flex align="center">
                  <img
                    src={STORAGE_URL + record?.albumInfo?.image64Path}
                    alt="cover_img"
                    style={
                      {
                        width: '36px',
                        height: '36px',
                        borderRadius: '6px',
                        margin: '0 16px',
                      }
                    }
                  />
                  <Caption2
                    style={
                      {
                        fontSize: 12,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        flex: 1,
                      }
                    }
                    align="left"
                  >
                    {text}
                  </Caption2>
                </Flex>
              </Link>
            ),
          },
          {
            title: '앨범명',
            key: 'albumInfoTitle',
            sorter: (a, b, sortOrder) => {
              return sortHandler(a?.albumInfo?.title, b?.albumInfo?.title)
            },
            dataIndex: ['albumInfo', 'title'],
            ...getColumnSearchProps(['albumInfo', 'title']),
            align: 'left',
            render: (text, record) => (
              <Flex
                onClick={() => handleMoveAlbum(record?.artistId, record?.albumId)}
                style={{ cursor: 'pointer' }}
              >
                <Caption2 align="left">{text}</Caption2>
              </Flex>
            ),
          },
          {
            title: '아티스트명',
            key: 'artistInfoName',
            sorter: (a, b, sortOrder) => {
              return sortHandler(a?.artistInfo?.name, b?.artistInfo?.name)
            },
            dataIndex: ['artistInfo', 'name'],
            ...getColumnSearchProps(['artistInfo', 'name']),
            align: 'left',
            render: (text, record) => (
              <Flex
                onClick={() => handleMoveArtist(record?.artistId)}
                style={{ cursor: 'pointer' }}
              >
                <Caption2 align="left">{text}</Caption2>
              </Flex>
            ),
          },
          {
            title: '사업자 인접 수익',
            key: 'companyMonthlyAdjacencyFee',
            dataIndex: ['revenueInfo', 'companyMonthlyAdjacencyFee'],
            defaultSortOrder: { sortOrder: 'descend' },
            render: text => `${convertToPricingComma(Math.floor(text))}원`,
            sorter: (a, b, sortOrder) => {
              return sortHandler(
              a?.revenueInfo?.companyMonthlyAdjacencyFee,
              b?.revenueInfo?.companyMonthlyAdjacencyFee,
              )
            },
            align: 'center',
          },
          {
            title: '사업자 누적 인접 수익',
            key: 'companyTotalAdjacencyFee',
            dataIndex: ['revenueInfo', 'companyTotalAdjacencyFee'],
            defaultSortOrder: { sortOrder: 'descend' },
            render: text => `${convertToPricingComma(Math.floor(text))}원`,
            sorter: (a, b, sortOrder) => {
              return sortHandler(
              a?.revenueInfo?.companyTotalAdjacencyFee,
              b?.revenueInfo?.companyTotalAdjacencyFee,
              )
            },
            align: 'center',
          },
          {
            title: '사업자 정산 비율',
            key: 'companyAdjacencyFeeRate',
            dataIndex: ['revenueInfo', 'companyAdjacencyFeeRate'],
            defaultSortOrder: { sortOrder: 'descend' },
            render: text =>
              `${typeof text === 'number' ? `${(text * 100).toFixed(2)}%` : '-'}`,
            sorter: (a, b, sortOrder) => {
              return sortHandler(
              a?.revenueInfo?.companyAdjacencyFeeRate,
              b?.revenueInfo?.companyAdjacencyFeeRate,
              )
            },
            align: 'center',
          },
          {
            title: '트랙 인접 수익',
            key: 'trackMonthlyAdjacencyFee',
            dataIndex: ['revenueInfo', 'trackMonthlyAdjacencyFee'],
            defaultSortOrder: { sortOrder: 'descend' },
            render: text =>
              typeof text === 'number'
                ? `${convertToPricingComma(Math.floor(text))}원`
                : '-',
            sorter: (a, b, sortOrder) => {
              return sortHandler(
              a?.revenueInfo?.trackMonthlyAdjacencyFee,
              b?.revenueInfo?.trackMonthlyAdjacencyFee,
              )
            },
            align: 'center',
          },
          {
            title: '트랙 누적 인접 수익',
            key: 'trackTotalAdjacencyFee',
            dataIndex: ['revenueInfo', 'trackTotalAdjacencyFee'],
            defaultSortOrder: { sortOrder: 'descend' },
            render: text => `${convertToPricingComma(Math.floor(text))}원`,
            sorter: (a, b, sortOrder) => {
              return sortHandler(
              a?.revenueInfo?.trackTotalAdjacencyFee,
              b?.revenueInfo?.trackTotalAdjacencyFee,
              )
            },
            align: 'center',
          },
          {
            title: '유통사',
            key: 'distributor',
            dataIndex: ['revenueInfo', 'distributor'],
            defaultSortOrder: { sortOrder: 'descend' },
            sorter: (a, b, sortOrder) => {
              return sortHandler(
              a?.revenueInfo?.distributor,
              b?.revenueInfo?.distributor,
              )
            },
            align: 'center',
          },

          {
            title: '음원 링크',
            key: 'dataObjectId',
            dataIndex: ['customUrlInfo', 'dataObjectId'],
            render: (text, record) => {
              return (
                <Flex justify={'center'}>
                  <a
                    href={`https://plam.in/track/${text}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={copy_btn_img}
                      alt="copy"
                      style={
                        {
                          width: '22px',
                          height: '22px',
                          cursor: 'pointer',
                        }
                      }
                    />
                  </a>
                </Flex>
              )
            },
            width: 80,
          },
        ]
      }
      dataSource={data}
      pagination={false}
      size={'small'}
      style={
        {
          width: '100%',
          height: '1180px',
          border: 'solid 1px #dddddd',
          borderRadius: '6px',
          marginBottom: '100px',
        }
      }
      rowKey="dataObjectId"
      scroll={{ y: 1120 }}
    />
  )
}

export default observer(CompanyRevenueAdjacencyTrackTableChart)
