import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Text } from '@components/atoms'

import { dateYMDMM, convertToPricingComma } from '@utils/format'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { arrowLeftGray } from '@images'
import InsightCompanyTemplate from './InsightCompanyTemplate'
import InsightCompanySnsTemplate from './InsightCompanySnsTemplate'
import { InsightCompanyTrackTemplate } from './InsightCompanyTrackTemplate'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`

const InsightCompanyTotalTemplate = () => {
  const { authStore, trendStore } = useStore()

  const { companyTrend, companyTrackTrend } = trendStore
  const { currentUser } = authStore

  const [insightType, setInsightType] = useState('total')

  const handleInsightTypeChange = value => {
    setInsightType(value || 'total')
  }

  const isTrackDetail = insightType.startsWith('트랙')

  return (
    <Flex type="column">
      <Header>
        <Flex style={{ marginTop: '10px', gap: 8, cursor: 'pointer' }}>
          {
            insightType === 'total' ? (
              <Text
                type="Medium"
                size="18px"
                color="#949494"
                onClick={() => handleInsightTypeChange('total')}
              >
              종합
              </Text>
            ) : (
              <Flex
                alignItems="center"
                gap="12px"
                onClick={() => handleInsightTypeChange('total')}
              >
                <Flex
                  style={
                    {
                      width: 32,
                      height: 32,
                      borderRadius: '50%',
                      backgroundColor: '#f4f4f4',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }
                  }
                >
                  <img src={arrowLeftGray} style={{ width: 12, height: 12 }} />
                </Flex>
                <Text
                  type="Medium"
                  size="18px"
                  color="#242424"
                  style={{ textTransform: 'capitalize' }}
                >
                  {isTrackDetail ? '트랙' : insightType}
                </Text>
              </Flex>
            )
          }
        </Flex>
        <Text
          type="Medium"
          color="#949494"
          size="14px"
          style={{ margin: '10px 50px 0 0' }}
        >
          {
            currentUser && currentUser.nowLikeCrawling
              ? '정보를 업데이트 중 입니다.'
              : isTrackDetail
                ? `${companyTrackTrend
                && dateYMDMM(companyTrackTrend.trendDataAddedAt)} 업데이트`
                : companyTrend && companyTrend.trendDataAddedAt
                  ? `${companyTrend
                && dateYMDMM(companyTrend.trendDataAddedAt)} 업데이트`
                  : '최신 데이터입니다.'
          }
        </Text>
      </Header>
      {
        insightType === 'total' ? (
          <InsightCompanyTemplate onInsightTypeChange={handleInsightTypeChange} />
        ) : isTrackDetail ? (
          <InsightCompanyTrackTemplate
            trackId={insightType.substring('트랙'.length)}
          />
        ) : (
          <InsightCompanySnsTemplate
            onInsightTypeChange={handleInsightTypeChange}
            snsType={insightType}
          />
        )
      }
    </Flex>
  )
}

export default observer(InsightCompanyTotalTemplate)
