/* eslint-disable import/no-unresolved */

import { AdjustmentTypeModel } from '@stores/models'
import { action, observable } from 'mobx'

export default class AdjustmentTypeStore {
  @observable adjustmentTypeList = []

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {}

  async fetchAdjustmentTypeList({ artistId, companyId } = {}) {
    const { selectedCompanyId } = this.store.adjustmentContractStore

    if (!artistId) {
      console.error('[fetchAdjustmentTypeList] artistId is not exist')
      return
    }

    if (!companyId) {
      if (selectedCompanyId) {
        companyId = selectedCompanyId
        console.warn(
          '[fetchAdjustmentTypeList] companyId is not exist, use selectedCompanyId',
        )
      }
      else {
        console.error('[fetchAdjustmentTypeList] companyId is not exist')
        return
      }
    }

    const res = await this.network.adjustmentTypeNetwork.getAdjustmentTypeList({
      artistId,
      companyId,
    })

    if (!res || res.error) {
      console.error('[fetchAdjustmentTypeList] failed to network')
      return
    }

    const { adjustmentTypeList } = res.data

    this.adjustmentTypeList = adjustmentTypeList.map(
      adjustmentType => new AdjustmentTypeModel(this.store, adjustmentType),
    )

    return this.adjustmentTypeList
  }

  async createAdjustmentType({
    artistId,
    adjustmentContractId = null,
    type = null,
    description = null,
  } = {}) {
    if (!artistId) {
      console.error('[createAdjustmentType] artistId is not exist')
      return
    }

    // todo: type이 빈 스트링인 경우 처리 추가
    const params = {
      artistId,
      adjustmentContractId,
      type,
      description,
    }

    const res = await this.network.adjustmentTypeNetwork.postAdjustmentType({
      params,
    })

    if (!res || res.error) {
      console.error('[createAdjustmentType] failed to network')
      return
    }

    const adjustmentType = new AdjustmentTypeModel(
      this.store,
      res.data.adjustmentType,
    )

    return adjustmentType
  }

  async updateAdjustmentType({ adjustmentTypeId, params = {} }) {
    if (!adjustmentTypeId) {
      console.error('[updateAdjustmentType] adjustmentTypeId is not exist')
      return
    }

    const res = await this.network.adjustmentTypeNetwork.putAdjustmentType({
      adjustmentTypeId,
      params,
    })

    if (!res || res.error) {
      console.error('[updateAdjustmentType] failed to network')
      return
    }

    const { adjustmentType } = res.data
    const index = this.adjustmentTypeList.findIndex(
      _adjustmentType => _adjustmentType._id === adjustmentTypeId,
    )

    if (index === -1) {
      console.error('[updateAdjustmentType] adjustmentType is not exist')
      return
    }

    this.adjustmentTypeList[index] = new AdjustmentTypeModel(
      this.store,
      adjustmentType,
    )

    return this.adjustmentTypeList[index]
  }

  async deleteAdjustmentType({ adjustmentTypeId } = {}) {
    if (!adjustmentTypeId) {
      console.error('[deleteAdjustmentType] adjustmentTypeId is not exist')
      return
    }

    const res = await this.network.adjustmentTypeNetwork.deleteAdjustmentType({
      adjustmentTypeId,
    })

    if (!res || res.error) {
      console.error('[deleteAdjustmentType] failed to network')
      return
    }

    return res.data.adjustmentType
  }
}
