import { Network } from '.'

export default class QuestionNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getList(params) {
    try {
      const { data } = await this.network._axiosApiAuth(`/questions`, 'get', params)
      return data || []
    }
    catch (error) {
      console.warn('QuestionNetwork getList error', error)
    }
  }

  async put(id, body) {
    if (!id || !body) return

    try {
      const { data } = await this.network._axiosApiAuth(`/questions/${id}`, 'put', body)
      return data || null
    }
    catch (error) {
      console.warn('QuestionNetwork put error', `id=${id}`, error)
    }
  }

  async post(body) {
    try {
      const { data } = await this.network._axiosApiAuth(`/questions`, 'post', [body])
      return (data && data.length > 0 && data[0]) || null
    }
    catch (error) {
      console.warn('QuestionNetwork post error', error)
    }
  }

  async delete(id) {
    try {
      const { data } = await this.network._axiosApiAuth(`/questions/${id}`, 'delete')
      return data || null
    }
    catch (error) {
      console.warn('QuestionNetwork delete error', error)
    }
  }

}
