import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Flex, Text, Button, Image, Container, H6 } from '@components/atoms'
import {
  NoAlbumList,
  AlbumList,
  InputTypeModal,
  MelonLinkModal,
  ConfirmPopup,
  AlbumInfo,
} from '@components/organisms'
import { useHistory, useLocation } from 'react-router-dom'
import { colors } from '@colors/'
import { useStore } from '@utils/hooks'
import qs from 'qs'
import { observer } from 'mobx-react'

import { companyNoAlbum, arrowLeftGray } from '@images/'

const ADD_BTN = require('@images/add_btn.png')

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  padding-right: 40px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
`

const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: #ffffff;
`

const HeaderBtnImg = styled(Image)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`

const CompanyAlbumTemplate = () => {
  const { authStore, albumStore, artistStore, statsStore } = useStore()

  const {
    searchKeywordCompanyReleaseAlbumList,
    firstFetchCompanyReleaseAlbumList,
    moreFetchCompanyReleaseAlbumList,
    albumDetail,
  } = albumStore

  const user = authStore?.currentUser

  const [isDetailView, setIsDetailView] = useState(false) // null => list 뷰

  const checkCustomUrl = statsStore.checkCustomUrl || (() => {})
  const modifyCustomUrl = statsStore.modifyCustomUrl || (() => {})

  const handleCompanyAlbumDetailClick = async album => {
    setIsDetailView(true)

    if (album?._id) {
      await albumStore.fetchAlbumDetail(album?._id)
    }
    else {
      albumStore.albumDetail = album
    }
  }

  /* use copy toast */
  const history = useHistory()
  const location = useLocation()
  const [isCopy, setIsCopy] = useState(false)
  const showCopyToast = () => {
    setIsCopy(true)
    setTimeout(() => setIsCopy(false), 2500)
  }
  /* use copy toast end */

  /* delete album confirm popup */
  const [showAlbumDelete, setShowAlbumDelete] = useState(false)
  const deleteReleaseAlbum = albumStore.deleteReleaseAlbum || (() => {})

  const navigateDeleteAlbum = album => {
    albumStore.albumDetail = album
    setShowAlbumDelete(true)
  }
  /* delete confirm popup end */

  const navigateDeleteTrack = async (track, album) => {
    albumStore.albumDetail = album
    await albumStore.fetchTrackDetail(track)
  }
  /* delete confirm popup end */

  /* use album modal */
  const navigateUpdateAlbum = async album => {
    // albumStore.albumDetail = album
    if (album?._id) {
      await albumStore.fetchAlbumDetail(album?._id)
    }
    else {
      albumStore.albumDetail = album
    }
  }
  /* use album modal end */

  /* use track modal */
  const navigateUpdateTrack = async (track, album) => {
    albumStore.albumDetail = album
    await albumStore.fetchTrackDetail(track)
  }
  /* use track modal end */

  const artist = artistStore.artistDetail
  const albumList = albumStore.releaseCompanyAlbumList.slice() || []

  const [isHasMore, setIsHasMore] = useState(true) // 더보기 유무

  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      // 문자열 맨 앞의 ?를 생력
    })

    const handleFirstFetchReleaseAlbumList = async companyId => {
      const res = await albumStore.firstFetchCompanyReleaseAlbumList(
        companyId,
        {
          limit: 9,
          sortyBy: 'releaseAt',
        },
      )

      // limit 보다 이하의 값일 경우 더보기 유무 false
      setIsHasMore(res)
    }

    if (query.artistId && query.artistId !== 'null') {
      artistStore.fetchArtistDetail(query.artistId)
      handleFirstFetchReleaseAlbumList(query.artistId)
    }
    else {
      artistStore.fetchArtistDetail(user && user['artistId'])
      handleFirstFetchReleaseAlbumList(user && user['companyId'])
    }
  }, [user, albumStore, artistStore, location])

  useEffect(() => {
    if (user && user['type'] !== 'company' && !user?.artistId) {
      history.push('/artist')
    }
  }, [user, history])

  return (
    <>
      <Header>
        <Flex style={{ marginTop: '10px', gap: 8, cursor: 'pointer' }}>
          {
            isDetailView && albumDetail ? (
              <Flex alignItems="center" gap="12px">
                <Flex
                  onClick={() => setIsDetailView(false)}
                  style={
                    {
                      width: 32,
                      height: 32,
                      borderRadius: '50%',
                      backgroundColor: '#f4f4f4',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }
                  }
                >
                  <img src={arrowLeftGray} style={{ width: 12, height: 12 }} />
                </Flex>
                <Text
                  type="Medium"
                  size="18px"
                  color="#242424"
                  style={{ textTransform: 'capitalize', lineHeight: '30px' }}
                >
                  {albumDetail?.title}
                </Text>
              </Flex>
            ) : (
              <Text
                type="Medium"
                size="18px"
                color="#949494"
                onClick={() => setIsDetailView(false)}
              >
              나의 앨범
              </Text>
            )
          }
        </Flex>
      </Header>
      {
        isDetailView ? (
          <Content>
            {
              albumDetail ? (
                <AlbumInfo
                  artist={artist}
                  album={albumDetail}
                  navigateUpdateTrack={navigateUpdateTrack}
                  navigateDeleteTrack={navigateDeleteTrack}
                  showCopyToast={showCopyToast}
                  checkCustomUrl={checkCustomUrl}
                  modifyCustomUrl={modifyCustomUrl}
                />
              ) : (
                <div />
              )
            }
          </Content>
        ) : (
          <Content>
            {
              user && user.nowArtistCrawling ? (
                <>
                  <Text style={{ marginTop: '16px' }}>
                    {`ArtistID : ${user && user.melonArtistId}`}
                  </Text>
                  <Text color="#1982df">앨범 정보 등록 중</Text>
                  <Text>24시간 이내에 등록됩니다.</Text>
                </>
              ) : !!albumList && albumList.length > 0 ? (
                <AlbumList
                  searchKeywordReleaseAlbumList={
                    searchKeywordCompanyReleaseAlbumList
                  }
                  firstFetchReleaseAlbumList={firstFetchCompanyReleaseAlbumList}
                  moreFetchReleaseAlbumList={moreFetchCompanyReleaseAlbumList}
                  albumList={albumList}
                  navigateUpdateAlbum={navigateUpdateAlbum}
                  navigateUpdateTrack={navigateUpdateTrack}
                  navigateDeleteTrack={navigateDeleteTrack}
                  navigateDeleteAlbum={navigateDeleteAlbum}
                  showCopyToast={showCopyToast}
                  isHasMore={isHasMore}
                  setIsHasMore={setIsHasMore}
                  artistId={user?.companyId}
                  isCompanyView
                  onCompanyAlbumDetailClick={handleCompanyAlbumDetailClick}
                />
              ) : (
                <Flex
                  type="column"
                  gap="24px"
                  justify="center"
                  align="center"
                  style={{ marginTop: 172 }}
                >
                  <img
                    src={companyNoAlbum}
                    alt="no data"
                    width="188px"
                    height="172px"
                  />
                  <H6 type="Regular">
                해당 계정과 연결된 앨범 혹은 트랙이 존재하지 않습니다.
                  </H6>
                </Flex>
              )
            }
          </Content>
        )
      }
    </>
  )
}

export default observer(CompanyAlbumTemplate)
