import { observable } from 'mobx'

export default class AdjustmentModel {
  @observable _id
  @observable companyId
  @observable artistId

  @observable title
  @observable adjustmentStartDate // 정산 시작일
  // @observable adjustmentEndDate
  @observable adjustmentConfirmDate // 정산 마감일

  @observable savedAt
  @observable confirmedAt
  @observable isConfirmed

  @observable amount
  @observable amountInfo

  @observable incomeCount
  @observable expenditureCount

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable term

  @observable adjustmentTypeList

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.companyId = props.companyId
      this.artistId = props.artistId

      this.title = props.title
      this.adjustmentStartDate = props.adjustmentStartDate
      // this.adjustmentEndDate = props.adjustmentEndDate
      this.adjustmentConfirmDate = props.adjustmentConfirmDate

      this.savedAt = props.savedAt
      this.confirmedAt = props.confirmedAt
      this.isConfirmed = props.isConfirmed

      this.amount = props.amount
      this.amountInfo = props.amountInfo

      this.incomeCount = props.incomeCount
      this.expenditureCount = props.expenditureCount

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      if (this.adjustmentStartDate && this.adjustmentConfirmDate) {
        this.term = generateDateRangeString(
          this.adjustmentStartDate,
          this.adjustmentConfirmDate,
        )
      }

      this.adjustmentTypeList = props.adjustmentTypeList || []
    }
  }
}

function formatDateToCustomFormat(date) {
  // 숫자를 문자열로 변환
  const dateString = String(date)

  // 문자열의 포맷을 바꾼다
  const year = dateString.substring(0, 4)
  const month = dateString.substring(4, 6)
  const day = dateString.substring(6, 8)

  return `${year}-${month}-${day}`
}

function generateDateRangeString(startDate, endDate) {
  const formattedStartDate = formatDateToCustomFormat(startDate)
  const formattedEndDate = formatDateToCustomFormat(endDate)

  return `${formattedStartDate} ~ ${formattedEndDate}`
}
