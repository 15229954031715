import React, { useState, useEffect } from 'react'
// antd
import { Progress, message } from 'antd'
import styled from 'styled-components'
import { Flex, Text } from '@components/atoms'
import { API_URL } from '@consts'
import Files from 'react-butterfiles'
import { colors } from '@colors/'
import { inject, observer } from 'mobx-react'

const clip_img = require('@images/clip.png')
const close_img = require('@images/x_btn_img.png')

const UploadBox = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 375px;
  height: 72px;
  border-radius: 6px;
  background-color: #f2f2f2;
  cursor: pointer;
  font-size: 14px;
  color: #949494;
`

const FileList = styled.ol`
  padding: 0;
  min-width: 400px;
  width: fit-content;
  flex-wrap: wrap;
  display: flex;
  gap: 40px;
`

const FileItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: solid 1px #949494;
  background-color: #fff;
  padding: 0 16px;

  width: 100%;

  width: 375px;
  height: 72px;

  img {
    width: 16px;
    height: 16px;
  }

  img:last-child {
    cursor: pointer;
  }
`

const MusicVideoUploadForm = props => {
  const [result, setResult] = useState(null)
  const [isUpload, setIsUpload] = useState(false)
  const [percent, setPercent] = useState(0)

  const { setMusicVideoInfoList, musicVideoInfoList, authStore } = props

  const jwt = authStore.jsonWebToken || null

  /**
   * Retrieve pre-signed POST data from a dedicated API endpoint.
   * @param selectedFile
   * @returns {Promise<any>}
   */
  const getPresignedPostData = selectedFile => {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest()
      // Set the proper URL here.
      const url = `${API_URL}/file`

      xhr.open('POST', url, true)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.setRequestHeader('Authorization', `jwt ${jwt}`)
      xhr.send(
        JSON.stringify({
          name: selectedFile.name,
          isVideo: true,
        }),
      )
      xhr.onload = function() {
        resolve(JSON.parse(this.responseText))
      }
    })
  }

  /**
   * Upload file to S3 with previously received pre-signed POST data.
   * @param presignedPostData
   * @param file
   * @returns {Promise<any>}
   */
  const uploadFileToS3 = (presignedPostData, file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      Object.keys(presignedPostData.fields).forEach(key => {
        console.log(key, ' : ', presignedPostData.fields[key])
        formData.append(key, presignedPostData.fields[key])
      })
      // Actual file has to be appended last.
      formData.append('file', file)
      const xhr = new XMLHttpRequest()
      xhr.upload.addEventListener(
        'progress',
        function(event) {
          if (event.lengthComputable) {
            setPercent(Math.floor((100 * event.loaded) / event.total))
          }
        },
        false,
      )

      xhr.open('POST', presignedPostData.url, true)
      xhr.send(formData)
      xhr.onload = function() {
        this.status === 204 ? resolve() : reject(this.responseText)
      }
    })
  }

  return (
    <Files
      multiple={false}
      maxSize="3gb"
      multipleMaxSize="3gb"
      multipleMaxCount={1}
      accept={'video/mkv,video/mp4,video/wmv'}
      onSuccess={async ([selectedFile]) => {
        setIsUpload(true)
        // Step 1 - get pre-signed POST data.
        const { data: presignedPostData } = await getPresignedPostData(
          selectedFile,
        )

        // Step 2 - upload the file to S3.
        try {
          const { file } = selectedFile.src
          await uploadFileToS3(presignedPostData, file)
          setMusicVideoInfoList(prev => {
            const newArr = prev || []
            newArr.push({
              name: selectedFile.name,
              path: `/${presignedPostData.fields.key}`,
            })

            return newArr
          })
          setPercent(100)
          message.success('업로드 성공!')
          console.log('File was successfully uploaded!')
        } catch (e) {
          console.log('An error occurred!', e.message)
          message.error('업로드 실패.')
        } finally {
          setIsUpload(false)
        }
      }}
      onError={errors => {
        if (errors.type === 'maxSizeExceeded') {
          alert('3GB 이하, mp4, wmv, mkv 파일만 업로드할 수 있습니다.')
        } else {
          setResult(errors)
        }
      }}
    >
      {({ browseFiles, getDropZoneProps }) => (
        <Flex
          style={{
            gap: 40,
            width: 'max-content',
            minWidth: 800,
            maxWidth: 1620,
            flexWrap: 'wrap',
          }}
        >
          <Flex type="column">
            <UploadBox onClick={browseFiles} {...getDropZoneProps()}>
              <Text>뮤직비디오 파일을 업로드해주세요.</Text>
              <Text>3GB 이하 mp4, wmv, mkv 파일</Text>
            </UploadBox>
            {isUpload && (
              <Progress
                percent={percent}
                strokeColor={colors.main}
                strokeWidth="4px"
              />
            )}
          </Flex>
          {!isUpload &&
            musicVideoInfoList?.length > 0 &&
            musicVideoInfoList?.map((file, index) => (
              <FileItem key={`${file.name}_${index}`}>
                <Text>{file.name}</Text>
                <img
                  src={close_img}
                  alt="close"
                  onClick={() => {
                    setMusicVideoInfoList(prev => {
                      const newArr = prev || []
                      return newArr.filter(item => item.path !== file.path)
                    })
                    setIsUpload(false)
                  }}
                />
              </FileItem>
            ))}
          {result &&
            result.map(error => (
              <li key={error.file.name}>
                {error.file.name} -{error.type}
              </li>
            ))}
        </Flex>
      )}
    </Files>
  )
}

export default inject('authStore')(observer(MusicVideoUploadForm))
