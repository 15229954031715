import { action, observable } from 'mobx'
import { Store } from '.'
import { Network } from './networks'
import {
  TotalInflowStatsModel,
  TotalInflowStatsByUrlModel,
  DailyInflowStatsModel,
  TrackLikeInfoListModel,
  ArtistLikeInfoModel,
  TrackLikeInfoModel,
} from './models'

export default class StatsStore {
  @observable TotalInflowStatsList
  @observable TotalInflowStatsByUrlList
  @observable DailyInflowStatsList

  @observable dailyInflowSmartLinkRecordList

  @observable sumInflow
  @observable sumInflowSmartLink

  @observable trackLikeInfoList
  @observable wholeTrackLikeInfo
  @observable artistLikeInfo
  @observable trackLikeInfo
  @observable artistFollowerInfo
  @observable followerInfo

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.TotalInflowStatsList = []
    this.TotalInflowStatsByUrlList = []
    this.DailyInflowStatsList = []
    this.dailyInflowSmartLinkRecordList = []

    this.sumInflow = {}
    this.sumInflowSmartLink = {}

    this.trackLikeInfoList = []
    this.wholeTrackLikeInfo = []
    this.artistLikeInfo = {}
    this.trackLikeInfo = {}
    this.artistFollowerInfo = {}
    this.followerInfo = {}
  }

  @action.bound
  async initClient() {}

  @action.bound
  async fetchStats(params) {
    return this.store.useLoading(async () => {
      if (!params) {
        this.init()
        return
      }

      const { data: fetchedStats } = await this.network.statsNetwork.getRecord(
        params,
      )

      if (!fetchedStats || !fetchedStats.totalInflowStats) return
      if (!fetchedStats || !fetchedStats.totalInflowStatsByUrl) return
      if (!fetchedStats || !fetchedStats.dailyInflowStats) return

      this.TotalInflowStatsList = fetchedStats.totalInflowStats.map(
        TotalInflow => new TotalInflowStatsModel(this.store, TotalInflow),
      )

      this.TotalInflowStatsByUrlList = fetchedStats.totalInflowStatsByUrl.map(
        TotalInflowByUrl =>
          new TotalInflowStatsByUrlModel(this.store, TotalInflowByUrl),
      )

      this.DailyInflowStatsList = fetchedStats.dailyInflowStats.map(
        DailyInflow => new DailyInflowStatsModel(this.store, DailyInflow),
      )

      this.sumInflow = fetchedStats.sumInflow
    })
  }

  @action.bound
  async fetchArtsitLike(periodType) {
    return this.store.useLoading(async () => {
      // if (!this.store.artistStore.artistDetail?._id) {
      if (!this.store.artistStore.artistDetail?._id) {
        this.init()
        return
      }

      const { data: res } = await this.network.statsNetwork.getArtistLike(
        // this.store.authStore.currentUser.artistId,
        this.store.artistStore.artistDetail._id,
        periodType,
      )
      if (!res) return

      this.artistLikeInfo = new ArtistLikeInfoModel(
        this.store,
        res.artistLikeInfo,
      )
    })
  }

  @action.bound
  async fetchTracksLike(periodType) {
    return this.store.useLoading(async () => {
      // if (!this.store.authStore.currentUser || !periodType) {
      if (!this.store.artistStore.artistDetail._id || !periodType) {
        this.init()
        return
      }

      const { data: res } = await this.network.statsNetwork.getTracksLike(
        // this.store.authStore.currentUser.artistId,
        this.store.artistStore.artistDetail._id,
        periodType,
      )
      if (!res) return

      this.wholeTrackLikeInfo = res.wholeTrackLikeInfo

      this.trackLikeInfoList = res.trackLikeInfoList
        .filter(elem => !!elem)
        .map(elem => new TrackLikeInfoListModel(this.store, elem))
    })
  }

  @action.bound
  async fetchTrackLike(trackId, periodType) {
    return this.store.useLoading(async () => {
      if (!trackId || !periodType) {
        this.init()
        return
      }

      const { data: res } = await this.network.statsNetwork.getTrackLike(
        trackId,
        periodType,
      )
      if (!res) return

      this.trackLikeInfo = new TrackLikeInfoModel(this.store, res.trackLikeInfo)
    })
  }

  // @action.bound
  // async fetchSnsListLike(periodType) {
  //   return this.store.useLoading(async () => {
  //     // if (!this.store.authStore.currentUser || !periodType) {
  //     if (!this.store.artistStore.artistDetail._id || !periodType) {
  //       this.init()
  //       return
  //     }

  //     const { data: res } = await this.network.statsNetwork.getSnsListLike(
  //       // this.store.authStore.currentUser.artistId,
  //       this.store.artistStore.artistDetail._id,
  //       periodType,
  //     )
  //     if (!res) return

  //     this.artistFollowerInfo = new ArtistFollowersInfoModel(
  //       this.store,
  //       res.artistFollowerInfo,
  //     )
  //   })
  // }

  // @action.bound
  // async fetchSnsLike(snsType, periodType) {
  //   return this.store.useLoading(async () => {
  //     // if (!snsType || !this.store.authStore.currentUser || !periodType) {
  //     if (!snsType || !this.store.artistStore.artistDetail._id || !periodType) {
  //       this.init()
  //       return
  //     }

  //     const { data: res } = await this.network.statsNetwork.getSnsLike(
  //       snsType,
  //       // this.store.authStore.currentUser.artistId,
  //       this.store.artistStore.artistDetail._id,
  //       periodType,
  //     )
  //     if (!res) return

  //     this.followerInfo = new FollowerInfoModel(this.store, res.followerInfo)
  //   })
  // }

  @action.bound
  async checkSubdomain(subdomain) {
    if (!subdomain) return

    const res = await this.network.statsNetwork.getSubdomain(subdomain)
    if (!res) return
    return !!res.data
  }

  @action.bound
  async accountSubdomain(subdomain) {
    if (!subdomain) return
    return this.store.useLoading(async () => {
      const res = await this.network.statsNetwork.postSubdomain({
        // artistId: this.store.authStore.currentUser.artistId,
        artistId: this.store.artistStore.artistDetail._id,
        subdomain,
      })

      if (!res) return

      return !!res.data.subdomainInfo
    })
  }

  @action.bound
  async modifySubdomain(subdomain) {
    if (!subdomain) return
    return this.store.useLoading(async () => {
      const res = await this.network.statsNetwork.putSubdomain({
        // artistId: this.store.authStore.currentUser.artistId,
        artistId: this.store.artistStore.artistDetail._id,
        subdomain,
      })

      if (!res) return

      return !!res.data.subdomainInfo
    })
  }

  @action.bound
  async checkCustomUrl(customUrl) {
    if (!customUrl) return

    const res = await this.network.statsNetwork.getCustomUrl(
      customUrl,
      // this.store.authStore.currentUser.artistId,
      this.store.artistStore.artistDetail._id,
    )
    if (!res) return
    return !!res.data
  }

  @action.bound
  async modifyCustomUrl(customUrl, dataType, dataObjectId) {
    if (!customUrl) return
    return this.store.useLoading(async () => {
      const res = await this.network.statsNetwork.putCustomUrl({
        // artistId: this.store.authStore.currentUser.artistId,
        artistId: this.store.artistStore.artistDetail._id,
        customUrl,
        dataObjectId,
        dataType,
      })

      if (!res) return

      return !!res.data.customUrlInfo
    })
  }

  @action.bound
  async fetchSmartLinkRecord(params) {
    return this.store.useLoading(async () => {
      if (!params) {
        this.init()
        return
      }

      const {
        data: fetchedStats,
      } = await this.network.statsNetwork.getRecordSmartLink(params)

      if (
        !fetchedStats ||
        !fetchedStats.sumInflow ||
        !fetchedStats.dailyInflowStats
      )
        return

      this.dailyInflowSmartLinkRecordList = fetchedStats.dailyInflowStats.map(
        inflow => new DailyInflowStatsModel(this.store, inflow),
      )

      this.sumInflowSmartLink = fetchedStats.sumInflow
    })
  }
}
