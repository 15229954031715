import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Flex, Text, Button } from '@components/atoms'
import { inject, observer } from 'mobx-react'
import { debounce } from 'lodash'
import ReactDOM from 'react-dom'

const X_BTN_GRAY = require('@images/x_btn_gray.png')

const InputBox = styled(Flex)`
  position: relative;
  height: 40px;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 10px 12px;
  margin: 0;
  z-index: 5000;

  border: ${props =>
    props.value !== '' ? 'solid 1px #1982df' : 'solid 1px #949494'};
`

const ValueInput = styled.input`
  border: none;
  font-size: 14px;
`

const SearchBox = styled(Flex)`
  flex-direction: column;
  position: absolute;
  width: 100%;
  margin-top: 3px;
  overflow-y: scroll;
  background-color: white;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 6000;
`

const AutoCompleteBox = styled(Flex)`
  height: 54px;
  padding: 17px 12px;
  cursor: pointer;
`

const CompanyName = styled(Text)`
  height: 20px;
  font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CompanyUniqueName = styled(Text)`
  height: 20px;
  font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: right;
  color: #66bc6a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const AutocompleteCode = ({
  albumStore,
  value,
  setValue,
  isDisabled = false,
  isDisabledRemoveBtn = false,
  onClickRemove,
  inputWidth = 410,
  keyword = 'company',
  index,
  placeholder = '유통사 코드를 입력해주세요.',
}) => {
  const fetchAutocompleteCompanyList =
    albumStore.fetchAutocompleteCompanyList || (() => { })
  const autocompleteCompanyList = albumStore.autocompleteCompanyList || []

  const inputRef = useRef(null)

  const autoCompleteRef = useRef()
  const autoCompleteNode = ReactDOM.findDOMNode(autoCompleteRef.current)
  const currentScroll = autoCompleteNode && autoCompleteNode.scrollTop

  const [input, setInput] = useState((value && value.name) || '')
  const [autoCompleteVisible, setAutoCompleteVisible] = useState(false)
  const [cursor, setCursor] = useState(-1)
  const [splitedData, setSplitedData] = useState([])
  const [focusing, setFocusing] = useState(false)
  const [finishedChange, setFinishedChange] = useState(false)

  const handleChangeValue = debounce(async _value => {
    fetchAutocompleteCompanyList(_value)

    setInput(_value || '')
    setValue({
      name: _value || '',
      _id: '',
      uniqueName: '',
    })

    setFinishedChange(true)
  }, 500)

  const onSelect = async company => {
    setInput((company && company.name) || '')
    setValue(company)

    if (document.getElementById(`autoCompleteInput_${keyword}_${index}`)) {
      document.getElementById(`autoCompleteInput_${keyword}_${index}`).value =
        company && company.name
    }

    setAutoCompleteVisible(true)
  }

  useEffect(() => {
    if (input && focusing) {
      setAutoCompleteVisible(true)
    }
    else {
      setAutoCompleteVisible(false)
    }
    setFinishedChange(false)
  }, [input, focusing, finishedChange])

  useEffect(() => {
    setInput((value && value.name) || '')

    if (document.getElementById(`autoCompleteInput_${keyword}_${index}`)) {
      document.getElementById(`autoCompleteInput_${keyword}_${index}`).value =
        (value && value.name) || null
    }
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    setCursor(-1)
    autoCompleteNode && autoCompleteNode.scrollTo(0, 0)
  }, [input, autoCompleteVisible, autoCompleteNode])

  useEffect(() => {
    setSplitedData(
      autocompleteCompanyList && autocompleteCompanyList.slice(0, 9),
    )
  }, [autocompleteCompanyList])

  // 무한 스크롤
  useEffect(() => {
    const handleScroll = async () => {
      const perc =
        (autoCompleteNode.scrollTop
          / (autoCompleteNode.scrollHeight - autoCompleteNode.clientHeight))
        * 100

      if (perc >= 100) {
        setSplitedData(prevState => [
          ...prevState,
          ...autocompleteCompanyList.slice(
            splitedData.length,
            splitedData.length + 9,
          ),
        ])
      }
    }

    if (autoCompleteNode) {
      autoCompleteNode.addEventListener('scroll', handleScroll)

      return () => {
        autoCompleteNode.removeEventListener('scroll', handleScroll)
      }
    }
  }, [
    autoCompleteNode,
    currentScroll,
    autocompleteCompanyList,
    splitedData.length,
  ])

  return (
    <div
      onKeyDown={
        e => {
          if (e.keyCode === 40) {
            e.preventDefault()
            setCursor(prevState =>
              prevState < autocompleteCompanyList.length - 1
                ? prevState + 1
                : prevState,
            )

            if (cursor > 2) {
              autoCompleteNode.scrollTo(0, currentScroll + 54)
            }
          }

          if (e.keyCode === 38) {
            e.preventDefault()
            setCursor(prevState => (prevState === 0 ? prevState : prevState - 1))

            if (cursor < autocompleteCompanyList.length - 3) {
              autoCompleteNode.scrollTo(0, currentScroll - 54)
            }
          }

          if (e.key === 'Enter' && !e?.nativeEvent?.isComposing) {
            if (cursor === -1) {
              e.preventDefault()
              e.stopPropagation()
              setAutoCompleteVisible(false)
              inputRef.current.blur()
            }
            else if (input) {
              e.preventDefault()
              e.stopPropagation()
              setAutoCompleteVisible(false)
              inputRef.current.blur()
              onSelect(autocompleteCompanyList[cursor])
            }
          }
        }
      }
    >
      <InputBox style={{ width: `${inputWidth}px` }} value={input}>
        <ValueInput
          ref={inputRef}
          id={`autoCompleteInput_${keyword}_${index}`}
          style={
            {
              width: `${(inputWidth - 50) * 0.65}px`,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              paddingRight: '3px',
            }
          }
          placeholder={placeholder}
          autoComplete={'off'}
          defaultValue={(value && value.name) || ''}
          onChange={
            e => {
              handleChangeValue(e.target.value)
            }
          }
          onBlur={
            () => {
              setFocusing(false)
              setAutoCompleteVisible(false)
            }
          }
          onFocus={
            e => {
              setFocusing(true)
              if (input) {
                fetchAutocompleteCompanyList(e.target.value)
                setAutoCompleteVisible(true)
              }
            }
          }
        />
        <Flex
          style={
            {
              position: 'absolute',
              top: '0px',
              right: '0px',
            }
          }
        >
          {
            value?.companyUserAccount && (
              <Flex style={{ margin: '10px 12px 0px 0px' }}>
                <CompanyUniqueName
                  style={{ width: `${(inputWidth - 50) * 0.3}px` }}
                >
                  {value.companyUserAccount}
                </CompanyUniqueName>
              </Flex>
            )
          }
          {
            value && value.uniqueName && (
              <Flex style={{ margin: '10px 12px 0px 0px' }}>
                <CompanyUniqueName
                  style={{ width: `${(inputWidth - 50) * 0.3}px` }}
                >
                  {value.uniqueName}
                </CompanyUniqueName>
              </Flex>
            )
          }
          {
            !isDisabledRemoveBtn && (
              <Button
                style={
                  {
                    margin: '12px 20px 0px 0px',
                    width: '16px',
                    height: '16px',
                  }
                }
                onClick={
                  async e => {
                    await onClickRemove(e)
                    if (
                      document.getElementById(
                        `autoCompleteInput_${keyword}_${index}`,
                      )
                    ) {
                      document.getElementById(
                        `autoCompleteInput_${keyword}_${index}`,
                      ).value = value && value.name
                    }
                  }
                }
              >
                <img src={X_BTN_GRAY} width={'16px'} height={'16px'} alt={'1'} />
              </Button>
            )
          }
        </Flex>
      </InputBox>
      <SearchBox
        ref={autoCompleteRef}
        style={
          {
            display:
              autoCompleteVisible && autocompleteCompanyList.length !== 0
                ? 'flex'
                : 'none',
            height:
              autocompleteCompanyList.length === 1
                ? '54px'
                : autocompleteCompanyList.length === 2
                  ? '108px'
                  : autocompleteCompanyList.length === 3
                    ? '162px'
                    : '216px',
          }
        }
      >
        {
          splitedData.map((company, index) => {
            return (
              <div
                key={company._id}
                onMouseDown={
                  e => {
                    if (input) {
                      onSelect(company)
                    }
                  }
                }
              >
                <AutoCompleteBox
                  className={
                    cursor === index
                      ? 'autoCompleteList_active'
                      : 'autoCompleteList'
                  }
                  style={{ width: `${inputWidth - 20}px` }}
                  onMouseOver={
                    () => {
                      setCursor(index)
                    }
                  }
                >
                  <CompanyName style={{ width: `${(inputWidth - 20) * 0.65}px` }}>
                    {company.name}
                  </CompanyName>
                  {
                    company?.companyUserAccount && (
                      <CompanyUniqueName
                        style={{ width: `${(inputWidth - 20) * 0.3}px` }}
                      >
                        {company.companyUserAccount}
                      </CompanyUniqueName>
                    )
                  }
                  {
                    company?.uniqueName && (
                      <CompanyUniqueName
                        style={{ width: `${(inputWidth - 20) * 0.3}px` }}
                      >
                        {company.uniqueName}
                      </CompanyUniqueName>
                    )
                  }
                </AutoCompleteBox>
              </div>
            )
          })
        }
      </SearchBox>
    </div>
  )
}

export default inject('albumStore')(observer(AutocompleteCode))
