import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Flex, Text, Button } from '@components/atoms'
import { inject, observer } from 'mobx-react'
import { debounce } from 'lodash'
import ReactDOM from 'react-dom'
import { wrapAsyncSequence } from '@utils/cache'

const X_BTN_GRAY = require('@images/x_btn_gray.png')

const InputBox = styled(Flex)`
  position: relative;
  height: 40px;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 10px 12px;
  margin: 0;
  z-index: 5000;

  border: ${props =>
    props.value !== '' ? 'solid 1px #1982df' : 'solid 1px #949494'};
`

const ValueInput = styled.input`
  border: none;
  font-size: 14px;
`

const SearchBox = styled(Flex)`
  flex-direction: column;
  position: absolute;
  width: 100%;
  margin-top: 3px;
  overflow-y: scroll;
  background-color: white;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 6000;
`

const AutoCompleteBox = styled(Flex)`
  height: 60px;
  padding: 17px 12px;
  cursor: pointer;
`

const ArtistName = styled(Text)`
  height: 20px;
  font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ArtistUniqueName = styled(Text)`
  height: 20px;
  font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: right;
  color: #66bc6a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const AutocompleteArtist = ({
  artistStore,
  value,
  setArtist,
  index,
  keyword,
  isDisabled = false,
  isDisabledRemove,
  onClickRemove,
  inputWidth = 565,
  placeholder = '아티스트 명을 입력해주세요.',
  onlyUniqueName = false,
}) => {
  const inputRef = useRef(null)

  const autoCompleteRef = useRef()
  const autoCompleteNode = ReactDOM.findDOMNode(autoCompleteRef.current)
  const currentScroll = autoCompleteNode && autoCompleteNode.scrollTop

  const fetchAutocompleteArtistList =
    artistStore.fetchAutocompleteArtistList || (() => { })
  // const autocompleteArtistList = artistStore.autocompleteArtistList || []

  const [input, setInput] = useState((value && value.name) || '')
  const [autoCompleteVisible, setAutoCompleteVisible] = useState(false)
  const [cursor, setCursor] = useState(-1)
  const [splitedData, setSplitedData] = useState([])
  const [focusing, setFocusing] = useState(false)
  const [finishedChange, setFinishedChange] = useState(false)
  const [autocompleteArtistList, setAutocompleteArtistList] = useState([])

  const _fetchAutocompleteArtistList = debounce(async _value => {
    const result = await wrapAsyncSequence({
      functionKey: 'AutocompleteArtist.fetchAutocompleteArtistList',
      currentKey: _value,
      promise: fetchAutocompleteArtistList(_value, onlyUniqueName ? 'null' : null),
    })
    if (!result?.isCanceled) {
      setAutocompleteArtistList(result?.result || [])
      setFinishedChange(true)
    }
  }, 250)
  const handleChangeValue = async _value => {
    setInput(_value || '')
    setArtist({
      name: _value || '',
      _id: '',
      uniqueName: '',
    })
    _fetchAutocompleteArtistList(_value)
  }

  const onSelect = async (artist: string) => {
    setInput((artist && artist.name) || '')
    setArtist(artist)

    if (document.getElementById(`autoCompleteInput_${keyword}_${index}`)) {
      document.getElementById(`autoCompleteInput_${keyword}_${index}`).value =
        artist && artist.name
    }

    setAutoCompleteVisible(false)
  }

  useEffect(() => {
    if (input && focusing) {
      setAutoCompleteVisible(true)
    } else {
      setAutoCompleteVisible(false)
    }
    setFinishedChange(false)
  }, [input, focusing, finishedChange])

  useEffect(() => {
    setInput((value && value.name) || '')

    if (document.getElementById(`autoCompleteInput_${keyword}_${index}`)) {
      document.getElementById(`autoCompleteInput_${keyword}_${index}`).value =
        (value && value.name) || null
    }
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    setCursor(-1)
    autoCompleteNode && autoCompleteNode.scrollTo(0, 0)
  }, [input, autoCompleteVisible, autoCompleteNode])

  useEffect(() => {
    setSplitedData(autocompleteArtistList && autocompleteArtistList.slice(0, 9))
  }, [autocompleteArtistList])

  // 무한 스크롤
  useEffect(() => {
    const handleScroll = async () => {
      const perc =
        (autoCompleteNode.scrollTop /
          (autoCompleteNode.scrollHeight - autoCompleteNode.clientHeight)) *
        100

      if (perc >= 100) {
        setSplitedData(prevState => [
          ...prevState,
          ...autocompleteArtistList.slice(
            splitedData.length,
            splitedData.length + 9,
          ),
        ])
      }
    }

    if (autoCompleteNode) {
      autoCompleteNode.addEventListener('scroll', handleScroll)

      return () => {
        autoCompleteNode.removeEventListener('scroll', handleScroll)
      }
    }
  }, [
    autoCompleteNode,
    currentScroll,
    autocompleteArtistList,
    splitedData.length,
  ])

  return (
    <div
      onKeyDown={e => {
        if (e.keyCode === 40) {
          e.preventDefault()
          setCursor(prevState =>
            prevState < autocompleteArtistList.length - 1
              ? prevState + 1
              : prevState,
          )

          if (cursor > 2) {
            autoCompleteNode.scrollTo(0, currentScroll + 60)
          }
        }

        if (e.keyCode === 38) {
          e.preventDefault()
          setCursor(prevState => (prevState === 0 ? prevState : prevState - 1))

          if (cursor < autocompleteArtistList.length - 3) {
            autoCompleteNode.scrollTo(0, currentScroll - 60)
          }
        }

        if (e.key === 'Enter' && !e?.nativeEvent?.isComposing) {
          if (cursor === -1) {
            e.preventDefault()
            e.stopPropagation()
            setAutoCompleteVisible(false)
            inputRef.current.blur()
          } else if (input) {
            e.preventDefault()
            e.stopPropagation()
            setAutoCompleteVisible(false)
            inputRef.current.blur()
            onSelect(autocompleteArtistList[cursor])
          }
        }
      }}
    >
      <InputBox style={{ width: `${inputWidth}px` }} value={input}>
        <ValueInput
          ref={inputRef}
          id={`autoCompleteInput_${keyword}_${index}`}
          style={{
            width: `${(inputWidth - 50) * 0.65}px`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            paddingRight: '3px',
          }}
          placeholder={placeholder}
          autoComplete={'off'}
          defaultValue={(value && value.name) || ''}
          onChange={e => {
            handleChangeValue(e.target.value)
          }}
          onBlur={() => {
            setFocusing(false)
            setAutoCompleteVisible(false)
          }}
          onFocus={e => {
            setFocusing(true)
            if (input) {
              _fetchAutocompleteArtistList(e.target.value)
            }
          }}
        />
        <Flex
          style={{
            position: 'absolute',
            top: '0px',
            right: '0px',
          }}
        >
          {value && value.uniqueName && (
            <Flex style={{ margin: '10px 12px 0px 0px' }}>
              <ArtistUniqueName
                style={{ width: `${(inputWidth - 50) * 0.3}px` }}
              >
                {value.uniqueName}
              </ArtistUniqueName>
            </Flex>
          )}
          {!isDisabledRemove && (
            <Button
              style={{
                margin: '12px 20px 0px 0px',
                width: '16px',
                height: '16px',
              }}
              onClick={async e => {
                await onClickRemove(e)
                if (
                  document.getElementById(
                    `autoCompleteInput_${keyword}_${index}`,
                  )
                ) {
                  document.getElementById(
                    `autoCompleteInput_${keyword}_${index}`,
                  ).value = value && value.name
                }
              }}
            >
              <img src={X_BTN_GRAY} width={'16px'} height={'16px'} alt={'1'} />
            </Button>
          )}
        </Flex>
      </InputBox>
      <SearchBox
        ref={autoCompleteRef}
        style={{
          display:
            autoCompleteVisible && autocompleteArtistList.length !== 0
              ? 'flex'
              : 'none',
          height:
            autocompleteArtistList.length === 1
              ? '60px'
              : autocompleteArtistList.length === 2
                ? '120px'
                : autocompleteArtistList.length === 3
                  ? '180px'
                  : '240px',
        }}
      >
        {splitedData.map((artist, index) => {
          return (
            <div
              key={artist._id}
              onMouseDown={e => {
                if (input) {
                  onSelect(artist)
                }
              }}
            >
              <AutoCompleteBox
                className={
                  cursor === index
                    ? 'autoCompleteList_active'
                    : 'autoCompleteList'
                }
                style={{
                  backgroundColor: `${cursor === index ? '#f2f2f2' : 'white'}`,
                  width: `${inputWidth - 20}px`,
                }}
                onMouseOver={() => {
                  setCursor(index)
                }}
              >
                <ArtistName style={{ width: `${(inputWidth - 20) * 0.65}px` }}>
                  {artist.name}
                </ArtistName>
                <ArtistUniqueName
                  style={{ width: `${(inputWidth - 20) * 0.3}px` }}
                >
                  {artist.uniqueName}
                </ArtistUniqueName>
              </AutoCompleteBox>
            </div>
          )
        })}
      </SearchBox>
    </div>
  )
}

export default inject('artistStore')(observer(AutocompleteArtist))
