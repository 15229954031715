import { Caption1, Caption2, Flex, Image } from '@components/atoms'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export const arrowLeft = require('@images/icon-arrowLeft.png')

const CheckBoxModal = ({ title, content, onReset }) => {
  const boxRef = useRef()
  const [isShow, setIsShow] = useState(false)

  const handleToggle = () => {
    setIsShow(prev => !prev)
  }

  useEffect(() => {
    const modalEventListener = e => {
      if (!boxRef.current?.contains(e.target)) setIsShow(false)
    }

    document.addEventListener('mousedown', modalEventListener)

    return () => {
      document.removeEventListener('mousedown', modalEventListener)
    }
  }, [])

  return (
    <Wrapper ref={boxRef}>
      <Button onClick={handleToggle}>
        <Caption1>{`${title} 설정`}</Caption1>
        <Image
          src={arrowLeft}
          width={12}
          height={12}
          transform={isShow ? 'rotate(90deg)' : 'rotate(-90deg)'}
        />
      </Button>
      {isShow && (
        <ContentModal>
          <Flex justify="space-between" align="center">
            <Caption2>{title}</Caption2>
            <Caption2
              color="#0066ff"
              onClick={onReset}
              style={{ cursor: 'pointer' }}
            >
              재설정
            </Caption2>
          </Flex>
          {content && content}
        </ContentModal>
      )}
    </Wrapper>
  )
}

export default CheckBoxModal

const Wrapper = styled.div`
  position: relative;
`

const Button = styled.div`
  height: 32px;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: #f4f4f4;
  cursor: pointer;

  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
`

const ContentModal = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 36px;
  left: 0;

  width: 220px;
  max-height: 404px;
  overflow-y: scroll;

  padding: 4px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #fff;

  > div {
    height: 36px;
    padding: 9px 8px;
    justify-content: flex-start;
  }

  > div:first-child {
    justify-content: space-between;
  }
`
