import styled from 'styled-components'
import { Image } from '@components/atoms'

export const FacebookLogo = styled(Image).attrs(props => ({
  src: require('@images/social_media/facebook_img.png'),
  style: {
    ...props.style,
  },
}))`
  width: 48px;
  height: 48px;
`
export const InstagramLogo = styled(Image).attrs({
  src: require('@images/social_media/instagram_img.png'),
})`
  width: 48px;
  height: 48px;
`
export const YoutubeLogo = styled(Image).attrs({
  src: require('@images/social_media/youtube_img.png'),
})`
  width: 48px;
  height: 48px;
`
export const SoundcloudLogo = styled(Image).attrs({
  src: require('@images/social_media/soundcloud_img.png'),
})`
  width: 48px;
  height: 48px;
`
export const HomePageLogo = styled(Image).attrs({
  src: require('@images/social_media/home_page_img.png'),
})`
  width: 48px;
  height: 48px;
`
export const EmailLogo = styled(Image).attrs({
  src: require('@images/social_media/email_img.png'),
})`
  width: 48px;
  height: 48px;
`
export const EtcSocialLogo = styled(Image).attrs({
  src: require('@images/social_media/etc_img.png'),
})`
  width: 48px;
  height: 48px;
`
export const XLogo = styled(Image).attrs({
  src: require('@images/social_media/x_img.png'),
})`
  width: 48px;
  height: 48px;
`
export const VliveLogo = styled(Image).attrs({
  src: require('@images/social_media/vlive_img.png'),
})`
  width: 48px;
  height: 48px;
`
export const TiktokLogo = styled(Image).attrs({
  src: require('@images/social_media/tiktok_img.png'),
})`
  width: 48px;
  height: 48px;
`
export const WeverseLogo = styled(Image).attrs({
  src: require('@images/social_media/weverse_img.png'),
})`
  width: 48px;
  height: 48px;
`
