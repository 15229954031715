import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Table, Input, Space, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { convertToPricingComma } from '@utils/format'
import { isArray } from 'lodash'
import { Link } from 'react-router-dom'
import { Caption1, Caption2, Flex, H4, H5, Text } from '@components/atoms'
import { useStore } from '@utils/hooks'
import moment from 'moment'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { colors } from '@colors/'
import CountUp from 'react-countup'
import { STORAGE_URL } from '@consts'
import MonthlyPicker from '../Card/MonthlyPicker'

const copy_btn_img = require('@images/copy_img_gray.png')

const RevenueCopyRightTrackTableChart = () => {
  const { revenueStore, authStore } = useStore()

  const fetchRevenueAristTrackData =
    revenueStore.fetchRevenueAristTrackData || (() => {})

  const { currentUser } = authStore

  const { artistTrackRevenueData, artistRecentMonth } = revenueStore

  const data = artistTrackRevenueData?.trackList || []

  const [currentMonth, setCurrentMonth] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const [enableMonthList, setEnableMonthList] = useState([])

  const searchInput = useRef(null)

  const monthlyDataList =
    artistTrackRevenueData?.revenueInfo?.artistMonthlyRevenueInfo

  const monthlyData = useMemo(() => {
    if (monthlyDataList && currentMonth) {
      const currnetMonthData = monthlyDataList?.filter(
        m => `${m.salesMonth}` === `${currentMonth?.format('YYMM')}`,
      )

      if (currnetMonthData?.length > 0) return currnetMonthData[0]
    }
    return null
  }, [monthlyDataList, currentMonth])

  const displayMonth = currentMonth?.format('YYYY년 MM월 ')

  // 검색 기능
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={
            e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (isArray(dataIndex)) {
        return record[dataIndex[0]][dataIndex[1]]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }
  // --end 검색기능

  const handleMonth = value => {
    const today = moment()

    if (today.isBefore(moment(value))) {
      setCurrentMonth(today)
    }
    else {
      setCurrentMonth(moment(value))
    }
  }

  useEffect(() => {
    if (artistRecentMonth) {
      setCurrentMonth(
        moment().set({
          year: `20${Math.floor(artistRecentMonth / 100)}`,
          month: (artistRecentMonth % 100) - 1,
        }),
      )
    }
  }, [artistRecentMonth])

  useEffect(() => {
    if (currentUser?.artistId) {
      if (currentMonth) {
        fetchRevenueAristTrackData(currentUser?.artistId, {
          __month: currentMonth.format('YYMM'),
        })
      }
    }
  }, [currentUser, currentMonth, fetchRevenueAristTrackData])

  const sortHandler = (a, b, sortOrder) => {
    if (a < b) {
      return -1
    }
    else if (a > b) {
      return 1
    }
    else {
      return 0
    }
  }

  useEffect(() => {
    const convertMonth = month => {
      if (month < 10) {
        return `0${month}`
      }
      else {
        return `${month}`
      }
    }
    const getEnableMonthList = () => {
      if (!currentMonth) return
      const today = moment()
      let enableList = []
      if (today.isSameOrAfter(currentMonth)) {
        if (today.format('YYYY') === currentMonth.format('YYYY')) {
          enableList = Array(parseInt(today.format('M')))
            .fill(1)
            .map((val, index) => {
              return `${today.format('YY')}${convertMonth(index + 1)}`
            })
        }
        else {
          enableList = Array(12)
            .fill(1)
            .map((val, index) => {
              return `${currentMonth.format('YY')}${convertMonth(index + 1)}`
            })
        }
      }
      else {
        enableList = Array(12)
          .fill(1)
          .map((val, index) => {
            return `${currentMonth.format('YY')}${convertMonth(index + 1)}`
          })
      }
      setEnableMonthList(enableList)
    }
    getEnableMonthList()
  }, [currentMonth])

  return (
    <Flex type="column">
      <Flex align="center" style={{ gap: 12 }}>
        <SectionTitle>
          <MonthlyPicker
            currentMonth={currentMonth}
            handleMonth={handleMonth}
            enableMonthList={enableMonthList}
            // enableMonthList={monthlyDataList?.map(i => i.salesMonth)}
          />
        </SectionTitle>
        <Caption1 color={colors.brown_grey}>
          *최신 데이터를 전달받지 못하였습니다. 데이터를 전달 해 주세요.
        </Caption1>
      </Flex>
      {
monthlyData?.monthlyCopyRightFee > 0 && (
          <Flex style={{ margin: '20px 0 30px' }}>
            <InfoCard>
              <H5>{`${displayMonth} 서비스로 발생한 월 저작권료는`}</H5>
              <H4 type="bold">
              &nbsp;
                {
monthlyData?.monthlyCopyRightFee && (
                    <CountUp
                      start={0}
                      end={Math.floor(monthlyData.monthlyCopyRightFee)}
                      separator=","
                      duration={3}
                    />
                  )
                }
              원 &nbsp;
              </H4>
              <H5>입니다.</H5>
            </InfoCard>
          </Flex>
        )
      }
      {
data?.length === 0 ? (
  <NoData>데이터가 없습니다.</NoData>
) : (
  <Table
    className="inflow_table"
    columns={
      [
        {
          title: '트랙명',
          key: 'title',
          dataIndex: 'title',
          align: 'left',
          sorter: (a, b, sortOrder) => {
            return sortHandler(a?.title, b?.title)
          },
          ...getColumnSearchProps('title'),
          width: '30%',
          render: (text, record) => (
            <Link to={`/insight/revenue/track/${record?._id}`}>
              <Flex align="center">
                <img
                  src={STORAGE_URL + record?.albumInfo?.image64Path}
                  alt="cover_img"
                  style={
                    {
                      width: '36px',
                      height: '36px',
                      borderRadius: '6px',
                      margin: '0 16px',
                    }
                  }
                />
                <Caption2
                  style={
                    {
                      fontSize: 12,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      flex: 1,
                    }
                  }
                  align="left"
                >
                  {text}
                </Caption2>
              </Flex>
            </Link>
          ),
        },
        {
          title: '앨범명',
          key: 'albumInfoTitle',
          align: 'left',
          sorter: (a, b, sortOrder) => {
            return sortHandler(a?.albumInfo?.title, b?.albumInfo?.title)
          },
          dataIndex: ['albumInfo', 'title'],
          ...getColumnSearchProps(['albumInfo', 'title']),
          render: (text, record) => (
            <Link to={`/insight/revenue/album/${record?.albumId}`}>
              <Caption2>{text}</Caption2>
            </Link>
          ),
        },
        {
          title: '누적 저작권료',
          key: 'artistTotalCopyRightFee',
          dataIndex: ['revenueInfo', 'artistTotalCopyRightFee'],
          render: text => `${convertToPricingComma(Math.floor(text))}원`,
          sorter: (a, b, sortOrder) => {
            return sortHandler(
                  a?.revenueInfo?.artistTotalCopyRightFee,
                  b?.revenueInfo?.artistTotalCopyRightFee,
            )
          },
        },

        {
          title: '내 저작권료',
          key: 'artistMonthlyCopyRightFee',
          dataIndex: ['revenueInfo', 'artistMonthlyCopyRightFee'],
          defaultSortOrder: { sortOrder: 'descend' },
          render: text => `${convertToPricingComma(Math.floor(text))}원`,
          sorter: (a, b, sortOrder) => {
            return sortHandler(
                  a?.revenueInfo?.artistMonthlyCopyRightFee,
                  b?.revenueInfo?.artistMonthlyCopyRightFee,
            )
          },
        },
        {
          title: '저작권 보유',
          key: 'artistCopyRightFeeRate',
          dataIndex: ['revenueInfo', 'artistCopyRightFeeRate'],
          render: text => `${(text * 100).toFixed(2)}%`,
          sorter: (a, b, sortOrder) => {
            return sortHandler(
                  a?.revenueInfo?.artistCopyRightFeeRate,
                  b?.revenueInfo?.artistCopyRightFeeRate,
            )
          },
        },
        {
          title: '트랙 누적 저작권료',
          key: 'trackTotalCopyRightFee',
          dataIndex: ['revenueInfo', 'trackTotalCopyRightFee'],
          render: text => `${convertToPricingComma(Math.floor(text))}원`,
          sorter: (a, b, sortOrder) => {
            return sortHandler(
                  a?.revenueInfo?.trackTotalCopyRightFee,
                  b?.revenueInfo?.trackTotalCopyRightFee,
            )
          },
        },
        {
          title: '트랙 저작권료',
          key: 'trackMonthlyCopyRightFee',
          dataIndex: ['revenueInfo', 'trackMonthlyCopyRightFee'],
          render: text => `${convertToPricingComma(Math.floor(text))}원`,
          sorter: (a, b, sortOrder) => {
            return sortHandler(
                  a?.revenueInfo?.trackMonthlyCopyRightFee,
                  b?.revenueInfo?.trackMonthlyCopyRightFee,
            )
          },
        },
        {
          title: '음원 링크',
          key: 'dataObjectId',
          dataIndex: ['customUrlInfo', 'dataObjectId'],
          render: (text, record) => {
            return (
              <Flex justify={'center'}>
                <a
                  href={`https://plam.in/track/${text}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={copy_btn_img}
                    alt="copy"
                    style={
                      {
                        width: '22px',
                        height: '22px',
                        cursor: 'pointer',
                      }
                    }
                  />
                </a>
              </Flex>
            )
          },
          width: 80,
        },
      ]
    }
    dataSource={data}
    pagination={false}
    size={'small'}
    style={
      {
        width: '100%',
        height: '624px',
        border: 'solid 1px #dddddd',
        borderRadius: '6px',
        marginBottom: '100px',
      }
    }
    rowKey="dataObjectId"
    scroll={{ y: 560 }}
  />
)
      }
    </Flex>
  )
}

export default observer(RevenueCopyRightTrackTableChart)

const SectionTitle = styled(Text)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
`

const InfoCard = styled(Flex)`
  width: 100%;
  height: 100px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #fcfcfc;
  padding: 5px;
  justify-content: center;
  align-items: center;
`

const NoData = styled(Flex)`
  width: 100%;
  height: 440px;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  border: 1px solid #dddddd;
  border-radius: 6px;
`
