import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Caption1, Caption2, Image } from '@components/atoms'
import { iconQeustionMark } from '@images'

export const SpotifyListenZeroTooltiop = ({}) => {
  const [isHoverQuestionMark, setIsHoverQuestionMark] = useState(false)
  return (
    <Flex>
      <Image
        src={iconQeustionMark}
        width="14px"
        height="14px"
        style={{ cursor: 'pointer' }}
        onMouseEnter={() => setIsHoverQuestionMark(true)}
        onMouseLeave={() => setIsHoverQuestionMark(false)}
      />
      <div style={{ position: 'relative' }}>
        {
          isHoverQuestionMark && (
            <QuestionTooltip style={{ width: 372, height: 95 }}>
              <Caption1 type="Bold">Listen 수집 중</Caption1>
              <Flex gap="2px">
                <Caption2 color="#949494" style={{ fontSize: 10 }} align="left">
                  Spotify에서 트랙 전체 스트리밍 수가 1,000 이상일 때 정보를 제공합니다.
                </Caption2>
              </Flex>
            </QuestionTooltip>
          )
        }
      </div>
    </Flex>
  )
}

const QuestionTooltip = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  position: absolute;

  top: 32px;
  right: -140px;

  width: 171px;
  height: 55px;
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #fff;
  z-index: 1;
`
