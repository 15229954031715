import { observable } from 'mobx'

export default class ArtistLikeInfoModel {
  /* Database fields */

  @observable _id
  @observable artistId
  @observable pageId
  @observable pageType
  // 현재 좋아요 갯수 총합
  @observable currentLikeCount
  @observable filteringDataList

  // 현재 좋아요 갯수 총합 상세
  @observable currentLikeCountDetail
  // 최신 업데이트 일
  @observable updatedAt
  // periodType 비교한 좋아요 증가량
  @observable increaseValue

  /* Database fields end */

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.artistId = props.artistId
      this.pageId = props.pageId
      this.pageType = props.pageType
      // 현재 좋아요 갯수 총합
      this.currentLikeCount = props.currentLikeCount
      this.filteringDataList = props.filteringDataList

      // 현재 좋아요 갯수 총합 상세
      this.currentLikeCountDetail = props.currentLikeCountDetail
      // 최신 업데이트 일
      this.updatedAt = props.updatedAt
      // periodType 비교한 좋아요 증가량
      this.increaseValue = props.increaseValue
    }
  }
}
