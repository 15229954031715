import React from 'react'
import { MaterialReactTable } from 'material-react-table'
import { borderColor } from '../../styles'

const columnHeaderAlign = 'center'

const CompanyRoyaltyStatementTable = ({
  readOnly,
  columns,
  data,
  changeRow,
}) => {
  return (
    <MaterialReactTable
      enableRowOrdering={!readOnly}
      columns={columns}
      data={data}
      enableGlobalFilter={false}
      enablePagination={false}
      enableColumnFilters={false}
      enableRowDragging={!readOnly}
      muiTableBodyRowDragHandleProps={
        ({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState()
            if (hoveredRow && draggingRow) {
              if (hoveredRow !== draggingRow) {
              // drag한 행과 hover된 행이 다르면 changeRow 실행
                changeRow({ hoveredRow, draggingRow })
              }
            }
          },
        })
      }
      enableRowNumbers
      enableStickyFooter={false}
      enableStickyHeader
      enableBottomToolbar={false}
      enableTopToolbar={false}
      enableColumnActions={false}
      // positionActionsColumn='last'
      displayColumnDefOptions={
        {
          'mrt-row-drag': {
            header: '', // change header text
            muiTableHeadCellProps: {
              sx: {
                width: '30px',
                minWidth: '30px',
                flex: '0 0 auto',
              },
            }, // custom props
            muiTableBodyCellProps: {
              sx: {
                width: '30px',
                minWidth: '30px',
                flex: '0 0 auto',
              },
            },
            size: 30, // make actions column wider
          },
          'mrt-row-numbers': {
            header: '', // change header text
            muiTableHeadCellProps: {
              sx: {
                width: '30px',
                minWidth: '30px',
                flex: '0 0 auto',
              },
            },
            muiTableBodyCellProps: {
              sx: {
                color: 'grey',
                width: '30px',
                minWidth: '30px',
                flex: '0 0 auto',
              },
            },
          },
        }
      }
      renderColumnActionsMenuItems={
        ({ internalColumnMenuItems }) => {
          return [...internalColumnMenuItems.slice(0, 5)]
        }
      }
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enableHiding={false}
      localization={
        {
          clearFilter: '필터 해제',
          clearSearch: '검색 해제',
          clearSort: '정렬 해제',
          filterByColumn: '{column} 필터',
          showHideFilters: '필터 표시/숨기기',
          showHideSearch: '검색 표시/숨기기',
          sortByColumnAsc: '{column} 오름차순 정렬',
          sortByColumnDesc: '{column} 내림차순 정렬',
          sortedByColumnAsc: '{column} 기준 오름차순 정렬',
          sortedByColumnDesc: '{column} 기준 내림차순 정렬',
          unsorted: '정렬되지 않음',
          columnActions: '열 작업',
          move: '이동',
          noRecordsToDisplay: '추가된 정산 내역이 없습니다.',
        }
      }
      muiTablePaperProps={
        {
          sx: {
            boxShadow: 'none',
            border: borderColor,
            ...(readOnly && { borderRight: 'none' }),
            '& > div': {
              maxHeight: 'calc(100vh - 51px)',
            },
            td: {
              color: '#242424',
            },
            th: {
              color: '#949494',
            },
            '& td > span': {
              display: 'inline-block',
              minHeight: '19px',
            },
            '& thead > tr': {
              backgroundColor: '#fafafa',
            },
            '& td, th': {
              padding: '8px 4px',
              fontFamily: 'NotoSansCJKkr-Regular',
              fontWeight: 400,
              borderRight: borderColor,
              borderBottom: 'none',
              ':last-child': {
                borderRight: 'none',
              },
            },
            '& tr:not(:last-child)': {
              borderBottom: borderColor,
            },
          },
        }
      }
      muiTableHeadCellProps={
        {
          align: columnHeaderAlign,
        }
      }
      muiTableBodyCellProps={
        {
          align: columnHeaderAlign,
        }
      }
      initialState={{ density: 'compact' }}
      layoutMode="grid"
    />
  )
}

export default CompanyRoyaltyStatementTable
