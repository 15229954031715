import { Network } from '.'

export default class AlbumNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getAlbum(albumId) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/album/${albumId}`,
        'get',
      )
      const album = data && data['data'] && data['data']['album']
      return album || null
    }
    catch (error) {
      console.warn('AlbumNetwork getAlbum error', error)
    }
  }

  async getAlbums(params) {
    try {
      const { data } = await this.network._axiosApiAuth(`/album`, 'get', params)
      const albumList = data && data['data'] && data['data']['albumList']
      return albumList || []
    }
    catch (error) {
      console.warn('AblumNetwork getAlbums error', error)
    }
  }

  async postAlbum(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/album',
        'post',
        params,
      )
      const album = data && data['data'] && data['data']['album']
      return album || null
    }
    catch (error) {
      console.warn('AlbumNetwork postAlbum error', error)
    }
  }

  async putAlbum(params) {
    try {
      const { data } = await this.network._axiosApiAuth('/album', 'put', params)
      const album = data && data['data'] && data['data']['album']
      return album || null
    }
    catch (error) {
      console.warn('AlbumNetwork putAlbum error', error)
    }
  }

  async deleteAlbum(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/album',
        'delete',
        params,
      )
      const album = data && data['data'] && data['data']['album']
      return album || null
    }
    catch (error) {
      console.warn('AlbumNetwork deleteAlbum error', error)
    }
  }

  async getCompanyList(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/company',
        'get',
        params,
      )
      const companyList = data && data['data'] && data['data']['companyList']
      return companyList || []
    }
    catch (error) {
      console.warn('AlbumNetwork getCompanyList error', error)
    }
  }
}
