import { action, observable } from 'mobx'
import { Store } from '.'
import { Network } from './networks'

export default class RevenueStore {
  @observable artistRecentMonth
  @observable companyRecentMonth
  @observable albumRecentMonth

  @observable artistRevenueData
  @observable companyRevenueData
  @observable companyAnnualRevenueData
  @observable companyTrackRevenueData
  @observable companyArtistList
  @observable artistTrackRevenueData
  @observable albumTrackRevenueData
  @observable albumRevenueData
  @observable trackRevenueData
  @observable selectedCompanyAristId

  @observable artistRevenueExpectedList
  @observable companyRevenueExpectedList
  @observable artistTrackRevenueExpectedList
  @observable artistAlbumRevenueExpectedList

  @observable revenueType

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.artistRecentMonth = null
    this.albumRecentMonth = null
    this.companyRecentMonth = null

    this.artistRevenueData = null
    this.companyRevenueData = null

    this.companyAnnualRevenueData = null

    this.companyTrackRevenueData = []
    this.artistTrackRevenueData = []
    this.albumTrackRevenueData = []
    this.albumRevenueData = null
    this.trackRevenueData = null

    this.companyArtistList = []
    this.selectedCompanyAristId = []
    this.revenueType = null

    this.artistRevenueExpectedList = []
    this.companyRevenueExpectedList = []
    this.artistTrackRevenueExpectedList = []
    this.artistAlbumRevenueExpectedList = []
  }

  @action.bound
  async initClient() {}

  @action.bound
  setRevenueType(value) {
    this.revenueType = value
  }

  @action.bound
  updateSelectedCompanyAristId(value) {
    this.selectedCompanyAristId = value
  }

  @action.bound
  async fetchRevenueAristData(artistId, params) {
    // if (!this.store.authStore.currentUser.artistId) {
    if (!artistId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      // artistId: this.store.authStore.currentUser.artistId,
      // artistId: this.store.artistStore.artistDetail._id,
      const revenueData = await this.network.revenueNetwork.getRevenueArtist(
        artistId,
        {
          __year: params?.__year,
          __month: params?.__month,
        },
      )

      if (!revenueData) return

      this.artistRevenueData = revenueData?.data?.artist
    })
  }

  @action.bound
  async fetchRevenueAristTrackData(artistId, params) {
    // if (!this.store.authStore.currentUser.artistId) {
    if (!artistId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.revenueNetwork.getRevenueArtistTrack(
        artistId,
        {
          __month: params?.__month,
          __revenueType: params?.__revenueType,
        },
      )

      if (!revenueData) return

      this.artistTrackRevenueData = revenueData?.data
    })
  }

  @action.bound
  async fetchRevenueAlbumTrackData(params) {
    // if (!this.store.authStore.currentUser.artistId) {
    return this.store.useLoading(async () => {
      let revenueData
      if (this.store.authStore.currentUser.companyId) {
        revenueData = await this.network.revenueNetwork.getRevenueCompanyTrack(
          this.store.authStore.currentUser.companyId,
          {
            __month: params?.__month,
            __albumId: params?.__albumId,
            __revenueType: params?.__revenueType,
          },
        )
      }
      else {
        revenueData = await this.network.revenueNetwork.getRevenueArtistTrack(
          this.store.authStore.currentUser.artistId,
          {
            __month: params?.__month,
            __albumId: params?.__albumId,
            __revenueType: params?.__revenueType,
          },
        )
      }

      if (!revenueData) return

      this.albumTrackRevenueData = revenueData?.data?.trackList
    })
  }

  @action.bound
  async fetchRevenueTrackData(trackId, params) {
    // if (!this.store.authStore.currentUser.trackId) {
    if (!trackId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.revenueNetwork.getRevenueTrack(
        trackId,
        {
          __year: params?.__year,
        },
      )

      if (!revenueData) return

      this.trackRevenueData = revenueData?.data?.track
    })
  }

  @action.bound
  async fetchRevenueAristAlbumData(artistId, albumId, params) {
    // if (!this.store.authStore.currentUser.artistId) {
    if (!artistId || !albumId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.revenueNetwork.getRevenueArtistAlbum(
        artistId,
        albumId,
        {
          __year: params?.__year,
        },
      )

      if (!revenueData) return

      this.albumRevenueData = revenueData?.data?.album
    })
  }

  @action.bound
  async fetchRevenueCompanyAlbumData(companyId, albumId, params) {
    // if (!this.store.authStore.currentUser.artistId) {
    if (!companyId || !albumId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.revenueNetwork.getRevenueCompanyAlbum(
        companyId,
        albumId,
        {
          __year: params?.__year,
        },
      )

      if (!revenueData) return

      this.albumRevenueData = revenueData?.data?.album
    })
  }

  @action.bound
  async fetchRevenueArtistTrackData(artistId, trackId, params) {
    // if (!this.store.authStore.currentUser.trackId) {
    if (!artistId || !trackId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.revenueNetwork.getRevenueTrackArtist(
        artistId,
        trackId,
        {
          __year: params?.__year,
        },
      )

      if (!revenueData) return

      this.trackRevenueData = revenueData?.data?.track
    })
  }

  @action.bound
  async fetchRevenueCompanyTrackDetailData(companyId, trackId, params) {
    // if (!this.store.authStore.currentUser.trackId) {
    if (!companyId || !trackId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.revenueNetwork.getRevenueTrackCompany(
        companyId,
        trackId,
        {
          __year: params?.__year,
        },
      )

      if (!revenueData) return

      this.trackRevenueData = revenueData?.data?.track
    })
  }

  @action.bound
  async accountRevenueFile(path) {
    if (!path) return

    return this.store.useLoading(async () => {
      const res = await this.network.revenueNetwork.postRevenueFile(path)
      if (!res?.data?.error) return true
      return false
    })
  }

  @action.bound
  async fetchArtistRecentMonth(artistId) {
    return this.store.useLoading(async () => {
      const res = await this.network.revenueNetwork.getRevenueArtistRecentSalesMonth(
        artistId,
      )
      if (!res) return
      this.artistRecentMonth = res?.data?.salesMonth || 'none'
    })
  }

  @action.bound
  async fetchAlbumRecentMonth(artistId, albumId) {
    return this.store.useLoading(async () => {
      let res
      if (this.store.authStore.currentUser?.companyId) {
        res = await this.network.revenueNetwork.getRevenueAlbumRecentSalesMonthByCompany(
          this.store.authStore.currentUser?.companyId,
          albumId,
        )
      }
      else {
        res = await this.network.revenueNetwork.getRevenueAlbumRecentSalesMonth(
          artistId,
          albumId,
        )
      }
      if (!res) return
      this.albumRecentMonth = res?.data?.salesMonth || 'none'
    })
  }

  @action.bound
  async fetchCompanyRecentMonth(companyId) {
    return this.store.useLoading(async () => {
      const res = await this.network.revenueNetwork.getRevenueCompanyRecentSalesMonth(
        companyId,
      )
      if (!res) return
      this.companyRecentMonth = res?.data?.salesMonth || 'none'
    })
  }

  @action.bound
  async fetchRevenueCompanyData(companyId, params) {
    // if (!this.store.authStore.currentUser.companyId) {
    if (!companyId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.revenueNetwork.getRevenueCompany(
        companyId,
        {
          __year: params?.__year,
          __artistId: params?.__artistId,
          __artistIdList: params?.__artistIdList,
        },
      )

      if (!revenueData) return

      this.companyRevenueData = revenueData?.data?.company
    })
  }

  @action.bound
  async fetchRevenueCompanyAnnualData(companyId, params) {
    // if (!this.store.authStore.currentUser.companyId) {
    if (!companyId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.revenueNetwork.getRevenueCompanyAnnual(
        companyId,
        {
          __year: params?.__year,
          __artistId: params?.__artistId,
          __artistIdList: params?.__artistIdList,
        },
      )

      if (!revenueData) return

      this.companyAnnualRevenueData = revenueData?.data?.company
    })
  }

  @action.bound
  async fetchRevenueCompanyArtistData(companyId, params) {
    // if (!this.store.authStore.currentUser.companyId) {
    if (!companyId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.revenueNetwork.getRevenueCompanyArtist(
        companyId,
        {
          __year: params?.__year,
        },
      )

      if (!revenueData) return

      this.companyArtistList = revenueData?.data?.artistList
    })
  }

  @action.bound
  async fetchRevenueCompanyTrackData(companyId, params) {
    // if (!this.store.authStore.currentUser.companyId) {
    if (!companyId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.revenueNetwork.getRevenueCompanyTrack(
        companyId,
        {
          __year: params?.__year,
          __month: params?.__month,
          __artistId: params?.__artistId,
          __artistIdList: params?.__artistIdList,
        },
      )

      if (!revenueData) return

      this.companyTrackRevenueData = revenueData?.data?.trackList
    })
  }

  @action.bound
  async fetchRevenueArtistExpected(artistId, params) {
    // if (!this.store.authStore.currentUser.artistId) {
    if (!artistId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.revenueNetwork.getRevenueArtistExpectedAPI(
        artistId,
        // {
        //   __year: params?.__year,
        //   __artistId: params?.__artistId,
        // },
      )

      if (!revenueData) return

      if (
        this.artistRecentMonth === 'none'
        && revenueData?.data?.artist?.expectedRevenueList
      ) {
        this.artistRecentMonth =
          revenueData?.data?.artist?.expectedRevenueList[
            revenueData?.data?.artist?.expectedRevenueList.length - 1
          ]?.month
      }
      this.artistRevenueExpectedList = revenueData?.data?.artist
    })
  }

  @action.bound
  async fetchRevenueCompanyExpected(params) {
    const { companyId } = this.store.authStore?.currentUser

    if (!companyId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.revenueNetwork.getRevenueCompanyExpectedAPI(
        companyId,
        params,
      )

      if (!revenueData) return

      if (
        this.companyRecentMonth === 'none'
        && revenueData?.data?.company?.expectedRevenueList
      ) {
        this.companyRecentMonth =
          revenueData?.data?.company?.expectedRevenueList[
            revenueData?.data?.artist?.expectedRevenueList.length - 1
          ]?.month
      }
      this.companyRevenueExpectedList = revenueData?.data?.company
    })
  }

  @action.bound
  async fetchRevenueArtistTrackExpected(artistId, trackId, params) {
    // if (!this.store.authStore.currentUser.artistId) {
    if (!artistId || !trackId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.revenueNetwork.getRevenueArtistTrackExpectedAPI(
        artistId,
        trackId,
        // {
        //   __year: params?.__year,
        //   __artistId: params?.__artistId,
        // },
      )

      if (!revenueData) return

      this.artistTrackRevenueExpectedList = revenueData?.data?.track
    })
  }

  @action.bound
  async fetchRevenueCompanyTrackExpected(companyId, trackId, params) {
    // if (!this.store.authStore.currentUser.companyId) {
    if (!companyId || !trackId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.revenueNetwork.getRevenueCompanyTrackExpectedAPI(
        companyId,
        trackId,
        // {
        //   __year: params?.__year,
        //   __companyId: params?.__companyId,
        // },
      )

      if (!revenueData) return

      this.artistTrackRevenueExpectedList = revenueData?.data?.track
    })
  }

  @action.bound
  async fetchRevenueAlbumExpected(albumId) {
    // if (!this.store.authStore.currentUser.artistId) {
    if (!albumId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      let revenueData
      if (this.store?.authStore?.currentUser?.companyId) {
        revenueData = await this.network.revenueNetwork.getRevenueCompanyAlbumExpectedAPI(
          this.store?.authStore?.currentUser?.companyId,
          albumId,
        )
        if (!revenueData) return

        this.artistAlbumRevenueExpectedList = revenueData?.data?.album
      }
      else {
        revenueData = await this.network.revenueNetwork.getRevenueArtistAlbumExpectedAPI(
          this.store?.authStore?.currentUser?.artistId,
          albumId,
        )
        if (!revenueData) return

        this.artistAlbumRevenueExpectedList = revenueData?.data?.album
      }
    })
  }
}
