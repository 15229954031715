import React from 'react'
import { Table } from 'antd'
import { Caption1, Flex } from '@components/atoms'
import { convertToPricingComma } from '@utils/format'
import { IncreaseTextBox } from '@components/molecules'
import styled from 'styled-components'

const TableBox = styled.div`
  width: 100%;
  height: 440px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  padding: 0 30px 30px 30px;
  margin: 30px 0;

  thead > tr > th {
    background-color: transparent;
    color: #646464;
  }

  thead > tr > th:nth-child(1),
  th:nth-child(2),
  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5) {
    text-align: center;
    min-width: 88px;
  }

  td {
    text-align: center;
  }
`

const DailySnsSubFollowerTableChart = ({ data, snsType }) => {

  const sortHandler = (a, b, sortOrder) => {
    if(a < b){
      return -1
    }
    else if(a > b){
      return 1
    }
    else{
      return 0
    }
  }


  return (
    <TableBox>
      <Table
        scroll={{ y: 380 }}
        dataSource={data || []}
        rowKey="date"
        pagination={false}
        columns={
          snsType === 'genie' || snsType === 'bugs'
            ? [
              {
                title: '날짜',
                key: 'date',
                dataIndex: 'date',
                sorter: (a, b, sortOrder) => {
                  return sortHandler(a?.date, b?.date)
                },
                render: text => {
                  const strText = `${text}`
                  return `${strText?.slice(0, 4)}년 ${strText?.slice(
                    4,
                    6,
                  )}월 ${strText?.slice(6, 8)}일`
                },
              },
              {
                title: 'Follower',
                key: `followerInfo`,
                dataIndex: `followerInfo`,
                sorter: (a, b, sortOrder) => {
                  return sortHandler(a?.followerInfo[snsType], b?.followerInfo[snsType])
                },
                render: (text, record) => {
                  return (
                    <Flex type="column">
                      <Caption1>
                        {convertToPricingComma(text?.[snsType] || 0)}
                      </Caption1>
                      <IncreaseTextBox
                        data={text.increaseValue?.[snsType]}
                        style={{ alignSelf: 'center' }}
                      />
                    </Flex>
                  )
                },
              },
              {
                title: 'Like',
                key: `likeInfo`,
                dataIndex: `likeInfo`,
                sorter: (a, b, sortOrder) => {
                  return sortHandler(a?.likeInfo[snsType], b?.likeInfo[snsType])
                },
                render: (text, record) => {
                  return (
                    <Flex type="column">
                      <Caption1>
                        {convertToPricingComma(text?.[snsType] || 0)}
                      </Caption1>
                      <IncreaseTextBox
                        data={text.increaseValue?.[snsType]}
                        style={{ alignSelf: 'center' }}
                      />
                    </Flex>
                  )
                },
              },
            ]
            : [
              {
                title: '날짜',
                key: 'date',
                dataIndex: 'date',
                sorter: (a, b, sortOrder) => {
                  return sortHandler(a?.date, b?.date)
                },
                render: text => {
                  const strText = `${text}`
                  return `${strText?.slice(0, 4)}년 ${strText?.slice(
                    4,
                    6,
                  )}월 ${strText?.slice(6, 8)}일`
                },
              },
              {
                title: 'Follower',
                key: `followerInfo`,
                dataIndex: `followerInfo`,
                sorter: (a, b, sortOrder) => {
                  return sortHandler(a?.followerInfo[snsType], b?.followerInfo[snsType])
                },
                render: (text, record) => {
                  return (
                    <Flex type="column">
                      <Caption1>
                        {convertToPricingComma(text?.[snsType] || 0)}
                      </Caption1>
                      <IncreaseTextBox
                        data={text.increaseValue?.[snsType]}
                        style={{ alignSelf: 'center' }}
                      />
                    </Flex>
                  )
                },
              },
            ]
        }
      />
    </TableBox>
  )
}

export default DailySnsSubFollowerTableChart
