import React from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import { StartDateContainer } from './styledComponents'

const StartDatePicker = ({
  readOnly,
  setIsOpenStartDatePicker,
  isOpenStartDatePicker,
  handleOpenChange,
  adjustmentDetailStore,
  adjustmentStore,
  updateAdjustment,
}) => {
  return (
    <StartDateContainer>
      <h3>정산 시작일</h3>
      <DatePicker
        onClick={() => setIsOpenStartDatePicker(true)}
        open={isOpenStartDatePicker}
        onOpenChange={handleOpenChange}
        disabledDate={
          current =>
            current
          && current
            > moment(
              adjustmentDetailStore.adjustmentDetailList
                .slice()
                .sort((a, b) => a['date'] - b['date'])[0]['date'],
              'YYYYMMDD',
            )
        }
        allowClear={false}
        inputReadOnly
        {...(readOnly ? { open: false } : {})}
        value={
          adjustmentStore?.writingAdjustment?.adjustmentStartDate
            ? moment(
                adjustmentStore?.writingAdjustment?.adjustmentStartDate,
                'YYYYMMDD',
            )
            : null
        }
        placeholder="정산 시작일을 입력해주세요"
        onChange={
          date => {
            const update = date.format('YYYYMMDD')
            if (adjustmentStore?.writingAdjustment) {
              adjustmentStore.writingAdjustment.adjustmentStartDate = update
              updateAdjustment({
                params: {
                  adjustmentStartDate:
                  adjustmentStore?.writingAdjustment?.adjustmentStartDate,
                },
              })
            }
          }
        }
        style={
          {
            width: readOnly ? '320px' : '320px',
            borderColor: readOnly && '#d9d9d9',
            boxShadow: readOnly && 'none',
          }
        }
        onBlur={
          () =>
            updateAdjustment({
              params: {
                adjustmentStartDate:
                adjustmentStore?.writingAdjustment?.adjustmentStartDate,
              },
            })
        }
      />
    </StartDateContainer>
  )
}

export default StartDatePicker
