import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, Text, H5 } from '@components/atoms'
import { AlbumCard } from '@components/organisms'
import { colors } from '@colors/'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { SearchAlbumBox } from '@components/molecules'

const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
  margin: 30px 50px 0px 0px;
`

const Header = styled(Flex)`
  /* width: '100%'; */
  justify-content: space-between;
  padding: 0px 0px 30px 0px;
`

const Content = styled(Flex)`
  // flex-direction : column;
  flex-wrap: wrap;
  // display: inline-block;
  // column-count : 3;
  // width : 1500px;
  // height : auto;

  gap: 40px 80px;
`

const CrawlingAlbum = styled.div`
  width: 400px;
  height: 468px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  h5 {
    position: absolute;
    top: 257px;
    left: 100px;
  }
`

const crawl_album_img = require('@images/crawl_album_img.png')

export const AlbumList = ({
  searchKeywordReleaseAlbumList,
  firstFetchReleaseAlbumList,
  moreFetchReleaseAlbumList,
  albumList,
  navigateUpdateAlbum,
  navigateDeleteAlbum,
  navigateUpdateTrack,
  navigateDeleteTrack,
  showCopyToast,
  isHasMore,
  setIsHasMore,
  artistId,
  isCompanyView = false,
  onCompanyAlbumDetailClick,
}) => {
  const [keyword, setKeyword] = useState('') // 검색어
  const [sortBy, setSortBy] = useState('releasedAt') //  정렬 방법
  const [offset, setOffset] = useState(0)
  const [crawlAlbumList, setCrawlAlbumList] = useState([]) // 크롤링 중인 앨범 리스트

  // 검색 함수
  const handleSearch = async keyword => {
    const res = await searchKeywordReleaseAlbumList(keyword)
    setIsHasMore(res)
    setOffset(0)
  }

  // 스크롤 시,
  // offset 카운트
  const countOffset = () => {
    setOffset(offset + 1)
  }

  // 정렬 변경 메소드
  // 정렬 변경 시, offset 초기화 및 fetch
  const handleSort = async value => {
    setSortBy(value)
    setOffset(0)

    const res = await firstFetchReleaseAlbumList(artistId, {
      sortBy: value,
      limit: 9,
    })
    setIsHasMore(res)
  }

  // 크롤링 앨범 리스트 뽑아오기
  useEffect(() => {
    const tempCrwalAlbums =
      albumList && albumList.filter(album => album.nowAlbumCrawling === true)
    setCrawlAlbumList(tempCrwalAlbums)
  }, [albumList])

  // 스크롤 시, more fetch
  // 더보기 유무 판단
  useEffect(() => {
    const onLoadMore = async () => {
      if (offset < 1) {
        // const res = await firstFetchReleaseAlbumList({ sortBy, limit: 9 })
        // setIsHasMore(res)
      }
      else {
        const res = await moreFetchReleaseAlbumList({
          sortBy,
          limit: 9,
          offset,
          keyword,
        })
        setIsHasMore(res)
      }
    }
    onLoadMore()
    // eslint-disable-next-line
  }, [sortBy, offset, moreFetchReleaseAlbumList, setIsHasMore])

  return (
    <Container>
      <Header>
        <SearchAlbumBox
          onSearch={keyword => handleSearch(keyword)}
          keyword={keyword}
          setKeyword={setKeyword}
        />
        <Flex>
          <Text
            style={{ cursor: 'pointer', paddingRight: '20px' }}
            color={sortBy === 'releasedAt' ? colors.main : '#949494'}
            onClick={() => handleSort('releasedAt')}
          >
            발매일순
          </Text>
          <Text
            style={{ cursor: 'pointer', paddingRight: '20px' }}
            color={sortBy === 'createdAt' ? colors.main : '#949494'}
            onClick={() => handleSort('createdAt')}
          >
            등록순
          </Text>
          <Text
            style={{ cursor: 'pointer' }}
            color={sortBy === 'title' ? colors.main : '#949494'}
            onClick={() => handleSort('title')}
          >
            이름순
          </Text>
        </Flex>
      </Header>
      <InfiniteScroll
        dataLength={albumList.length}
        scrollThreshold={0.9}
        next={countOffset}
        hasMore={isHasMore}
        loader={
          <ReactLoading
            type="spin"
            color="black"
            style={
              {
                width: '50px',
                height: '50px',
                margin: '0 auto 50px auto',
              }
            }
          />
        }
        className="scroll_div"
      >
        <Content>
          {
            crawlAlbumList
            && crawlAlbumList.map(album => (
              <CrawlingAlbum key={album._id}>
                <img src={crawl_album_img} alt="crawl_img" />
                <H5>{`AlbumID : ${album.melonAlbumId}`}</H5>
                <H5 color={colors.main} style={{ top: '285px' }}>
                  앨범 정보 등록 중
                </H5>
                <H5 style={{ top: '315px' }}>24시간 이내에 등록됩니다.</H5>
              </CrawlingAlbum>
            ))
          }
          {
            albumList && albumList[0] === 'NO_DATA'
              ? (setIsHasMore(false), (<div>검색 결과가 없습니다.</div>))
              : albumList
                .filter(album => album.nowAlbumCrawling !== true)
                .map(album => (
                  <AlbumCard
                    album={album}
                    key={album._id}
                    artistId={artistId}
                    navigateUpdateAlbum={navigateUpdateAlbum}
                    navigateDeleteAlbum={navigateDeleteAlbum}
                    navigateUpdateTrack={navigateUpdateTrack}
                    navigateDeleteTrack={navigateDeleteTrack}
                    showCopyToast={showCopyToast}
                    isCompanyView={isCompanyView}
                    onCompanyAlbumDetailClick={onCompanyAlbumDetailClick}
                  />
                ))
          }
        </Content>
      </InfiniteScroll>
    </Container>
  )
}
