import React from 'react'
import { InformText } from './styledComponents' // styledComponents 경로에 따라 조정해주세요.

const ReadOnlyInfoText = ({ readOnly, isDeleted }) => {
  const commentMessage = isDeleted
    ? '소속사와 계약 종료로 인해 작성중인 정산서가 중단되었습니다. 궁금하신 내용은 회사 담당자를 통해 문의주세요.'
    : '정산서는 소속사에서 작성하고 있어 수정이 불가합니다. 잘못된 내역이 있을 시 회사 담당자를 통해 문의주세요.'
  if (readOnly) {
    return <InformText isDeleted={isDeleted}>{commentMessage}</InformText>
  }

  return null
}

export default ReadOnlyInfoText
