import { observable } from 'mobx'

export default class AdjustmentContractModel {
  @observable _id
  @observable companyId
  @observable artistId

  @observable type
  @observable companyRatio
  @observable companyRatioPercent

  @observable artistRatio
  @observable artistRatioPercent

  @observable description

  @observable isConfirmed
  @observable isDeleted
  @observable isDefault

  @observable contractStartDate
  @observable contractEndDate

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.companyId = props.companyId
      this.artistId = props.artistId

      this.type = props.type
      this.companyRatio = props.companyRatio
      this.companyRatioPercent = convertToInt(props.companyRatio)
      this.artistRatio = props.artistRatio
      this.artistRatioPercent = convertToInt(props.artistRatio)
      this.description = props.description

      this.isConfirmed = props.isConfirmed
      this.isDeleted = props.isDeleted
      this.isDefault = props.isDefault

      this.contractStartDate = props.contractStartDate
      this.contractEndDate = props.contractEndDate

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt
    }
  }
}

// int 100 => double 1.00 으로 바꾸는 함수
const convertToDouble = value => {
  if (!value) {
    return 0
  }
  return value / 100
}

// double 1.00 => int 100 으로 바꾸는 함수
const convertToInt = value => {
  if (!value) {
    return 0
  }
  return value * 100
}
