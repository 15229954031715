import { observable } from 'mobx'

export default class FaqModel {
  @observable id

  @observable title
  @observable content

  @observable createdAt
  @observable updatedAt

  isEdited = false

  constructor(stores, props) {
    if (props) {
      this.id = props.id

      this.title = props.title
      this.content = props.content

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
    }
  }
}