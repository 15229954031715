export const numberWithCommas = x => {
  if (x === null || x === undefined) return
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// date를 yyyy.mm.dd.Day 로 변환
export function dateFormat(value) {
  let date = new Date(value)
  let dd = date.getDate()
  let mm = date.getMonth() + 1 // January is 0!
  const yyyy = date.getFullYear()
  const day = date.getDay()

  const week = ['일', '월', '화', '수', '목', '금', '토']
  const todayLabel = week[day]

  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  return (date = `${yyyy}.${mm}.${dd} (${todayLabel})`)
}

// yyyy.mm.dd
export function dateYMD(value) {
  let date = new Date(value)
  let dd = date.getDate()
  let mm = date.getMonth() + 1 // January is 0!
  const yyyy = date.getFullYear()

  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  return (date = `${yyyy}.${mm}.${dd}`)
}

// yyyy년 mm월 dd일 hh시 mm분
export function dateYMDMM(value) {
  let date = new Date(value)
  const min = date.getMinutes()
  const hour = date.getHours()
  let dd = date.getDate()
  let mm = date.getMonth() + 1 // January is 0!
  const yyyy = date.getFullYear()

  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  return (date = `${yyyy}년 ${mm}월 ${dd}일 ${hour}시 ${min}분`)
}

export const converseUnitEng = num => {
  let label = ''

  if (num <= 99999) {
    // 100,000 이하 숫자는 x,xxx 숫자 표현
    const str = num === null ? '0' : num.toString()
    return (
      (str && str.replace && str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')) || ''
    )
  }
  else if (num > 999999) {
    // 1,000,000 이상 숫자는 xxx.xm으로 표현
    label = 'm'
    return (Math.floor((num / 1000000) * 10) / 10).toFixed(1) + label
  }
  else if (num > 99999) {
    // 1,000,000 미만 숫자는 xxx.xk로 표현
    label = 'k'
    return (Math.floor((num / 1000) * 10) / 10).toFixed(1) + label
  }
}

/**
 * @param {*} num
 * 100,000 미만 숫자는 x,xxx 숫자 표현
 * 100,000 ~ 1,000,000 미만 숫자는 xxx.xk로 표현
 * 1,000,000 ~ 10,000,000 x.xxxm
 * 10,000,000 ~ 100,000,000 xx.xxm
 * 이상 xxx.xm
 */
export const insightConverseUnitEng = num => {
  let label = ''
  if (num <= 99999) {
    // 100,000 이하 숫자는 x,xxx 숫자 표현
    const str = num === null ? '0' : num.toString()
    return (
      (str && str.replace && str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')) || ''
    )
  }
  else if (num > 99999999) {
    // 100,000,000 이상 xxx.xm
    label = 'm'
    return parseFloat(((num / 1000000) * 10) / 10).toFixed(1) + label
  }
  else if (num > 9999999) {
    // 10,000,000 ~ 100,000,000 xx.xxm 으로 표현
    label = 'm'
    return parseFloat(((num / 1000000) * 10) / 10).toFixed(2) + label
  }
  else if (num > 999999) {
    // 1,000,000 ~ 10,000,000 x.xxxm 으로 표현
    label = 'm'
    return parseFloat(((num / 1000000) * 10) / 10).toFixed(3) + label
  }
  else if (num > 99999) {
    // 100,000 ~ 1,000,000 xxx.xk 로 표현
    label = 'k'
    return parseFloat(((num / 1000) * 10) / 10).toFixed(1) + label
  }
}

// x,xxx 숫자 표현
export const convertToPricingComma = str => {
  let value = str
  if (typeof str === 'number') {
    value = value.toString()
  }
  return (
    (value && value.replace && value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
    || ''
  )
}

// 날자 연월일 까지만해서 비교
export const dateDiff = (firstDate, lasteDate) => {
  let diffDate1 = firstDate
  let diffDate2 = lasteDate

  diffDate1 = new Date(
    diffDate1.getFullYear(),
    diffDate1.getMonth(),
    diffDate1.getDate(),
  )
  diffDate2 = new Date(
    diffDate2.getFullYear(),
    diffDate2.getMonth(),
    diffDate2.getDate(),
  )

  let diff = diffDate2.getTime() - diffDate1.getTime()
  diff = Math.ceil(diff / (1000 * 3600 * 24))

  return diff
}

export const formatMonth = monthCode => {
  // 숫자의 마지막 두 자리를 추출하여 월로 사용
  const month = parseInt(monthCode.toString().slice(-2), 10) // 마지막 두 자리 추출 및 숫자로 변환
  // 월에 따라 한국어 이름 매핑
  const monthNames = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ]
  // 실제 월은 인덱스보다 1이 크므로 인덱스를 조정
  return monthNames[month - 1] // 배열은 0부터 시작하므로 -1
}
