import React from 'react'
import styled, { css } from 'styled-components'

const CompanyTabBar = ({ activeValue, list, onSelect }) => {
  return (
    <TabBar>
      {list?.map(item => (
        <TabItem
          key={item?.value}
          active={activeValue === item?.value}
          onClick={() => onSelect(item)}
        >
          {item?.name}
        </TabItem>
      ))}
    </TabBar>
  )
}

export default CompanyTabBar

const TabBar = styled.div`
  display: flex;
  align-items: center;

  width: fit-content;
  height: 48px;
  padding: 4px;
  border-radius: 999px;
  border: solid 2px #f4f4f4;
  background-color: #fff;
`

const TabItem = styled.div`
  transition: all 0.35s;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  padding: 10px 23px;
  border-radius: 20px;

  font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;

  ${({ active }) =>
    active
      ? css`
          color: #fff;
          background-color: #242424;
        `
      : css`
          color: #949494;
        `}
`
