import { observable } from 'mobx'

export default class TotalInflowStatsModel {
  /* Database fields */
  @observable _id

  @observable totalFromCount
  @observable totalYoutubeFromCount
  @observable totalFacebookFromCount
  @observable totalInstagramFromCount
  @observable totalClickCount
  @observable totalMelonUrlClickCount
  @observable totalGenieUrlClickCount
  @observable totalBugsUrlClickCount
  @observable totalFloUrlClickCount
  @observable totalVibeUrlClickCount
  @observable totalYoutubeMusicUrlClickCount
  @observable totalAppleMusicUrlClickCount
  @observable totalSpotifyUrlClickCount
  @observable totalAmazonMusicUrlClickCount
  @observable totalEtcUrlClickCount

  @observable fromSite
  /* Database fields end */

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.totalFromCount = props.totalFromCount

      this.totalYoutubeFromCount = props.totalYoutubeFromCount
      this.totalFacebookFromCount = props.totalFacebookFromCount
      this.totalInstagramFromCount = props.totalInstagramFromCount
      this.totalClickCount = props.totalClickCount
      this.totalMelonUrlClickCount = props.totalMelonUrlClickCount
      this.totalGenieUrlClickCount = props.totalGenieUrlClickCount
      this.totalBugsUrlClickCount = props.totalBugsUrlClickCount
      this.totalFloUrlClickCount = props.totalFloUrlClickCount
      this.totalVibeUrlClickCount = props.totalVibeUrlClickCount
      this.totalYoutubeMusicUrlClickCount = props.totalYoutubeMusicUrlClickCount
      this.totalAppleMusicUrlClickCount = props.totalAppleMusicUrlClickCount
      this.totalSpotifyUrlClickCount = props.totalSpotifyUrlClickCount
      this.totalAmazonMusicUrlClickCount = props.totalAmazonMusicUrlClickCount
      this.totalEtcUrlClickCount = props.totalEtcUrlClickCount

      this.fromSite = props.fromSite
    }
  }
}
