import { Network } from '.'

export default class CrawlNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async postCrawlArtist(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/crawl/artist',
        'post',
        params,
      )
      return data || null
    }
    catch (error) {
      console.warn('CrawlNetwork postCrawlArtist error', error)
    }
  }

  async postCrawlAlbum(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/crawl/album',
        'post',
        params,
      )
      return data || null
    }
    catch (error) {
      console.warn('CrawlNetwork postCrawlAlbum error', error)
    }
  }
}
