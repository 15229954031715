import React from 'react'
import styled from 'styled-components'
import { colors } from '@colors/'

const Box = styled.div`
    width : 126px;
    height: 40px;
    margin : 15px 20px;
    :first-child, :nth-child(10n+1) {margin-left : 0;}
    :nth-child(-n+10) {margin-top 0;}
    :nth-child(10n+0){margin-right : 0;}
`

const Btn = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
  width: 188px;
  height: 40px;
  background-color: ${(props) => (props.active ? colors.main : '#ffffff')};
  border-radius: 6px;
  border: ${(props) =>
    props.active ? `1px solid ${colors.main}` : '1px solid #949494'};
  color: ${(props) => (props.active ? '#ffffff' : '#949494')};
  width: 126px;
  height: 40px;
`

export const GenreBtn = ({ active, children, onClick }) => {
  return (
    <Box>
      <Btn active={active} onClick={onClick}>
        {children}
      </Btn>
    </Box>
  )
}
