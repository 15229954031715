import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Text, Caption2 } from '@components/atoms'
import { TrackItem, RoundBtn } from '@components/molecules'
import { Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { inject } from 'mobx-react'

const Card = styled(Flex)`
  flex-direction: column;
  width: 400px;
  background: #fcfcfc;
  border: solid 1px #dddddd;
  border-radius: 6px;
  height: ${props => (props.more ? 'fit-content' : '468px')};
  overflow: hidden;
`

const TestBox = styled(Flex)`
  height: 50px;
  position: relative;
`

const AddTrackBox = styled(Flex)`
  position: absolute;
  z-index: 10;
  bottom: 1px;
  height: 70px;
  width: 100%;
  background: #fcfcfc;
`

const Header = styled(Flex)`
  padding: 16px 20px;
  justify-content: flex-end;
  flex-direction: column;
  background: radial-gradient(at 30% 20%, black, black, rgb(36, 36, 36));
  position: relative;
  width: 100%;
  height: 232px;
`

const BackColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 232px;
  background-image: url(${props =>
    props.imageOriginalPath && props.imageOriginalPath});
  background-size: 100%;
  background-position: 0px -100px;
  opacity: 0.8;
`

const Content = styled(Flex)`
  padding: 0px 20px 20px 20px;
  flex-direction: column;
  height: ${props => (props.more ? 'fit-content' : '236px')};
`

const ContentHeader = styled(Flex)`
  width: 100%;
  padding: 16px 0px;
  justify-content: space-between;
`

const List = styled(Flex)`
  flex-direction: column;
  > div {
    padding: 8px 0px;
  }
  > div:first-child {
    paddint-top: 0;
  }
  > div:last-child {
    padding-bottom: 0;
  }
`

const SubMenu = styled.div`
  text-align: center;
  position: absolute;
  bottom: 20px;
  right: 0px;
  z-index: 100;
  width: 150px;
  height: fit-content;
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
`

const MenuList = styled.div`
  padding: 10px 16px;
  cursor: pointer;
  width: 152px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #242424;
`

const up_btn_img = require('@images/up_btn.png')
const down_btn_img = require('@images/down_btn.png')
const update_btn_img = require('@images/update_img.png')
const copy_btn_img = require('@images/copy_img.png')
const add_btn_img = require('@images/add_btn.png')

const _AlbumCard = ({
  authStore,
  user,
  album,
  navigateUpdateAlbum,
  navigateUpdateTrack,
  navigateDeleteTrack,
  navigateDeleteAlbum,
  showCopyToast,
  artistId,
  isCompanyView,
  onCompanyAlbumDetailClick,
}) => {
  const [more, setMore] = useState(false) //  트랙리스트 더보기
  const [showEdit, setShowEdit] = useState(false) // 편집 보기

  // 주소 복사 팝업
  const handleCopy = e => {
    e.preventDefault()
    showCopyToast()
  }

  const handleOpen = (e, album) => {
    e.preventDefault()
    navigateUpdateAlbum(album)
  }

  const handleDelete = (e, album) => {
    e.preventDefault()
    navigateDeleteAlbum(album)
  }

  const isLoginedMaster = authStore.isAdminPassword

  return (
    <Card more={more}>
      <Link
        to={
          {
            pathname: `/album/${album['_id']}?artistId=${artistId}`,
          }
        }
        onClick={
          e => {
            if (onCompanyAlbumDetailClick) {
              e.preventDefault()
              onCompanyAlbumDetailClick(album)
            }
          }
        }
      >
        <Header>
          <BackColor imageOriginalPath={album.imageUri} />
          <Flex type="column" style={{ zIndex: '1' }}>
            <Text type="Medium" color="#ffffff" size="24px">
              {album.title}
            </Text>
            <Text color="#ffffff" size="18px">
              {album.releaseArtistName}
            </Text>
            <Flex style={{ justifyContent: 'space-between' }}>
              <Text color="#ffffff" size="12px">
                {album.mainGenre}
                {' '}
∙
                {album.subGenre}
                {' '}
∙
                {' '}
                {`${new Date(album.releasedAt).getFullYear()}년`}
              </Text>
              <Flex
                style={
                  {
                    position: 'relative',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }
                }
              >
                <CopyToClipboard text={album && album.albumUrl}>
                  <img
                    onClick={e => handleCopy(e)}
                    src={copy_btn_img}
                    alt="copy"
                    style={
                      {
                        width: '22px',
                        height: '22px',
                        margin: '0px 0px 0px 16px',
                        cursor: 'pointer',
                      }
                    }
                  />
                </CopyToClipboard>
                {
                  isLoginedMaster && album && album.isOwner && !isCompanyView && (
                    <div
                      onClick={() => setShowEdit(true)}
                      onMouseOver={() => setShowEdit(true)}
                      onMouseLeave={() => setShowEdit(false)}
                    >
                      <img
                        src={update_btn_img}
                        alt="update"
                        style={
                          {
                            width: '22px',
                            height: '22px',
                            cursor: 'pointer',
                            marginLeft: 16,
                          }
                        }
                      />
                      {
                        showEdit && (
                          <SubMenu>
                            <MenuList onClick={e => handleOpen(e, album)}>
                              앨범 수정
                            </MenuList>
                            <MenuList onClick={e => handleDelete(e, album)}>
                              앨범 삭제
                            </MenuList>
                          </SubMenu>
                        )
                      }
                    </div>
                  )
                }
              </Flex>
            </Flex>
          </Flex>
        </Header>
      </Link>
      <Content more={more}>
        <ContentHeader>
          <Text size="14px">Track List</Text>
          {
            album && album.trackList && album.trackList.length > 2 && (
              <img
                src={more ? up_btn_img : down_btn_img}
                alt="down_btn"
                style={{ cursor: 'pointer', width: '20px', height: '20px' }}
                onClick={() => setMore(!more)}
              />
            )
          }
        </ContentHeader>
        <List>
          {
            album && album.trackList && album.trackList.length > 0 ? (
              <>
                {
                  album.trackList.map(track => (
                    <TrackItem
                      album={album}
                      track={track}
                      key={track._id}
                      artistName={track.releaseArtistName}
                      navigateUpdateTrack={navigateUpdateTrack}
                      navigateDeleteTrack={navigateDeleteTrack}
                      showCopyToast={showCopyToast}
                      isCompanyView={isCompanyView}
                    />
                  ))
                }
              </>
            ) : (
              <Caption2 style={{ marginTop: '35px' }}>
                추가된 트랙이 없습니다.
              </Caption2>
            )
          }
        </List>
      </Content>
      {
        isLoginedMaster && album && album.isOwner && (
          <TestBox>
            <AddTrackBox>
              <RoundBtn
                img={add_btn_img}
                text="트랙 추가"
                onClick={() => navigateUpdateTrack(null, album)}
              />
            </AddTrackBox>
          </TestBox>
        )
      }
    </Card>
  )
}

export const AlbumCard = inject(
  'authStore',
)(_AlbumCard)
