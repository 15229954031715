/* eslint-disable import/no-unresolved */
import styled from 'styled-components'
import { Flex } from '@components/atoms'
import { Button } from 'antd'

export const TopContainer = styled(Flex)`
  max-width: calc(100vw - 290px);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`

export const TotalInfoContainer = styled(Flex)`
  flex-direction: column;
`

export const TotalInfoMarginBox = styled.div`
  width: 10px;
  height: 67px;
  margin-left: 15px;
`

export const BottomContainer = styled(Flex)`
  max-width: calc(100vw - 290px);
  justify-content: space-between;
`

export const SheetContainer = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
`

export const TitleSubContainer = styled(Flex)`
  flex-direction: column;
  margin-right: 15px;
  flex-grow: 1;
`

export const StartDateContainer = styled(Flex)`
  flex-direction: column;
  flex-shrink: 0;
`

export const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  height: 44px;
  margin: 10px;
  border-radius: 25px;
  border: solid 1px #eaeaea;
  background-color: #ffffff;
`

export const RowDiv = styled(Flex)`
  align-items: center;
`

export const SavingTextContainer = styled.div`
  margin: 0 0 5px 15px;
  color: ${props => (props.isSaving ? '#949494' : '#53a9ff')};
  font-size: 12px;
`

export const InformText = styled.div`
  width: 584px;
  padding: 10px;
  margin: 20px 0 30px;
  text-align: center;
  color: ${props => (props.isDeleted ? '#ea4653' : '#979797')};
  background-color: #f2f2f2;
  font-size: 12px;
`

export const LoadMusicData = styled.div`
  width: 144px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #646464;
  border-radius: 4px;
  color: #646464;
  cursor: pointer;
`
