export default class AdjustmentNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getAdjustmentAllowArtistList({ artistId, isConfirmed } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/artist?artistId=${artistId}&isConfirmed=${isConfirmed}`,
      )
      return data.data
    }
    catch (error) {
      console.error(
        '[AdjustmentNetwork][getAdjustmentAllowArtistList] error: ',
        error,
      )
    }
  }

  async getAdjustmentCompanyList({ artistId, isConfirmed } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/company?artistId=${artistId}&isConfirmed=${isConfirmed}`,
      )
      return data.data
    }
    catch (error) {
      console.error(
        '[AdjustmentNetwork][getAdjustmentCompanyList] error: ',
        error,
      )
    }
  }

  async getAdjustment({ adjustmentId } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/${adjustmentId}`,
      )

      return data.data
    }
    catch (error) {
      console.error('[AdjustmentNetwork][getAdjustment] error: ', error)
    }
  }

  async getAdjustmentList({ artistId, companyId, isConfirmed } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment?artistId=${artistId}&companyId=${companyId}&isConfirmed=${isConfirmed}`,
      )

      return data
    }
    catch (error) {
      console.error('[AdjustmentNetwork][getAdjustmentList] error: ', error)
    }
  }

  async postAdjustment({ params } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/adjustment',
        'post',
        params,
      )

      return data
    }
    catch (error) {
      console.error('[AdjustmentNetwork][postAdjustment] error: ', error)
    }
  }

  async putAdjustment({ adjustmentId, params } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/${adjustmentId}`,
        'put',
        params,
      )

      return data
    }
    catch (error) {
      console.error('[AdjustmentNetwork][putAdjustment] error: ', error)
    }
  }

  async deleteAdjustment({ adjustmentId } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/${adjustmentId}`,
        'delete',
      )

      return data
    }
    catch (error) {
      console.error('[AdjustmentNetwork][deleteAdjustment] error: ', error)
    }
  }

  /**
   * 이월하기: post
   * 이월해제: delete
   */

  async postAdjustmentTypeCarryOver({ adjustmentId, params } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/${adjustmentId}/carry-over`,
        'post',
        params,
      )

      return data
    }
    catch (error) {
      console.error(
        '[AdjustmentNetwork][postAdjustmentTypeCarryOver] error: ',
        error,
      )
    }
  }

  async deleteAdjustmentTypeCarryOver({ adjustmentId, params } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/${adjustmentId}/carry-over`,
        'delete',
        params,
      )

      return data
    }
    catch (error) {
      console.error(
        '[AdjustmentNetwork][deleteAdjustmentTypeCarryOver] error: ',
        error,
      )
    }
  }
}
