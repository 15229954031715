import React, { useState } from 'react'
import { Input, Caption1, H3, H5, Caption2 } from '@components/atoms'
import { LogoImg, FormGroup, NoHeaderBox } from '@components/molecules'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { colors } from '@colors/'
import { mobile } from '@styles/media'
import { Link } from 'react-router-dom'

const Form = styled.form`
  width: 328px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FindAccountTemplate = ({ findAccount }) => {
  const { register, errors, handleSubmit } = useForm()
  const [isEmpty, setIsEmpty] = useState('')
  const [email, setEmail] = useState('')

  const onSubmit = async data => {
    const res = await findAccount(data.email)
    if (res.data) {
      const { isSend } = res.data
      if (isSend) {
        setIsEmpty('completed')
        setEmail(data.email)
      } else {
        setIsEmpty('duplicated')
      }
    }
  }

  return (
    <Box>
      <LogoImg isLarge />
      <H3
        style={{
          margin: isEmpty === 'completed' ? '32px 0 0 0' : '32px 0 12px 0',
        }}
      >
        아이디 찾기
      </H3>
      {isEmpty === 'completed' ? (
        <>
          <H3>완료</H3>
          <EmailBox>
            <Caption2 color="#646464">
              아래의 이메일로 아이디를 전송했습니다.
            </Caption2>
            <H5 color={colors.main}>{email}</H5>
          </EmailBox>
          <BottomBox>
            <Link to="/login">
              <SubmitBtn type="button" style={{ marginBottom: 24 }}>
                로그인 하러가기
              </SubmitBtn>
            </Link>
            <Link to="/find/password">
              <H5>비밀번호 재설정</H5>
            </Link>
          </BottomBox>
        </>
      ) : (
        <>
          <Caption1 color="#646464">
            회원가입에 등록된 이메일로 아이디를 찾으실 수 있어요.
          </Caption1>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup style={{ margin: '32px 0', width: '100%' }}>
              <Input
                style={{ width: '100%' }}
                placeholder="이메일 주소"
                name="email"
                ref={register({ required: true, pattern: /^\S+@\S+$/i })}
              />
              {errors.email && errors.email.type === 'required' && (
                <Caption1 style={{ textAlign: 'left' }} color="#ea4653">
                  이메일을 입력해주세요.
                </Caption1>
              )}
              {errors.email && errors.email.type === 'pattern' && (
                <Caption1 style={{ textAlign: 'left' }} color="#ea4653">
                  이메일 형식이 올바르지 않습니다.
                </Caption1>
              )}
              {isEmpty === 'duplicated' && (
                <Caption1 style={{ textAlign: 'left' }} color="#ea4653">
                  등록된 이메일이 아닙니다.
                </Caption1>
              )}
            </FormGroup>
            <SubmitBtn type="submit">메일 전송</SubmitBtn>
          </Form>
        </>
      )}
    </Box>
  )
}

const Box = styled(NoHeaderBox)`
  position: relative;

  @media ${mobile} {
    height: 100dvh;
    justify-content: flex-start;
  }
`

const EmailBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;

  width: 320px;
  height: 84px;
  border-radius: 2px;
  border: solid 0.5px #eaeaea;
  background-color: #fafafa;
`

const BottomBox = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  margin-top: 164px;

  @media ${mobile} {
    margin-top: 0px;

    position: absolute;
    bottom: 40px;
    left: calc(50% - 160px);
  }
`

const SubmitBtn = styled.button`
  width: 320px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: none;
  background-color: #f4f4f4;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #242424;

  @media ${mobile} {
    margin-top: 0px;

    position: absolute;
    bottom: 20px;
    left: calc(50% - 160px);
  }
`
