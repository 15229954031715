import { axiosApi } from '../../utils'

import AuthNetwork from './AuthNetwork'
import NoticeNetwork from './NoticeNetwork'
import FaqNetwork from './FaqNetwork'
import QuestionNetwork from './QuestionNetwork'
import AlbumNetwork from './AlbumNetwork'
import ArtistNetwork from './ArtistNetwork'
import TrackNetwork from './TrackNetwork'
import StatsNetwork from './StatsNetwork'
import CrawlNetwork from './CrawlNetwork'
import ConnectionsNetwork from './ConnectionsNetwork'
import RevenueNetwork from './RevenueNetwork'
import TrendNetwork from './TrendNetwork'
import SettlementNetwork from './SettlementNetwork'

/** 정산서 */
import AdjustmentNetwork from './royaltyStatements/AdjustmentNetwork'
import AdjustmentDetailNetwork from './royaltyStatements/AdjustmentDetailNetwork'
import AdjustmentContractNetwork from './royaltyStatements/AdjustmentContractNetwork'
import AdjustmentTypeNetwork from './royaltyStatements/AdjustmentTypeNetwork'

export class Network {
  _axiosApi = axiosApi
  _axiosApiAuth = axiosApi

  authNetwork: AuthNetwork
  noticeNetwork: NoticeNetwork
  faqNetwork: FaqNetwork
  questionNetwork: QuestionNetwork
  albumNetwork: AlbumNetwork
  artistNetwork: ArtistNetwork
  trackNetwork: TrackNetwork
  statsNetwork: StatsNetwork
  crawlNetwork: CrawlNetwork
  connectionsNetwork: ConnectionsNetwork
  revenueNetwork: RevenueNetwork
  trendNetwork: TrendNetwork
  settlementNetwork: SettlementNetwork

  adjustmentNetwork: AdjustmentNetwork
  adjustmentDetailNetwork: AdjustmentDetailNetwork
  adjustmentContractNetwork: AdjustmentContractNetwork

  constructor(props) {
    props.getJwt && this.createAxiosApiAuth(props.getJwt)

    this.authNetwork = new AuthNetwork(this)
    this.noticeNetwork = new NoticeNetwork(this)
    this.faqNetwork = new FaqNetwork(this)
    this.questionNetwork = new QuestionNetwork(this)
    this.albumNetwork = new AlbumNetwork(this)
    this.artistNetwork = new ArtistNetwork(this)
    this.trackNetwork = new TrackNetwork(this)
    this.statsNetwork = new StatsNetwork(this)
    this.crawlNetwork = new CrawlNetwork(this)
    this.connectionsNetwork = new ConnectionsNetwork(this)
    this.revenueNetwork = new RevenueNetwork(this)
    this.trendNetwork = new TrendNetwork(this)
    this.settlementNetwork = new SettlementNetwork(this)

    this.adjustmentNetwork = new AdjustmentNetwork(this)
    this.adjustmentDetailNetwork = new AdjustmentDetailNetwork(this)
    this.adjustmentContractNetwork = new AdjustmentContractNetwork(this)
    this.adjustmentTypeNetwork = new AdjustmentTypeNetwork(this)
  }

  createAxiosApiAuth = getJwt => {
    this._axiosApiAuth = async (url, method = 'GET', data, options) => {
      const jwtToken = (getJwt && getJwt()) || ''
      const _options = {
        ...options,
        headers: {
          Authorization: `jwt ${jwtToken}`,
          ...(options && typeof options['headers'] === 'object'
            ? options['headers']
            : {}),
        },
      }
      return axiosApi(url, method, data, _options)
    }
  }
}
