import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React from 'react'
import styled, { css } from 'styled-components'
import { Caption1, Caption2, Flex } from '@components/atoms'
import { add_btn, x_btn_img } from '@images/index'
import { useHistory } from 'react-router-dom'
import ReactLoading from 'react-loading'
import SmartLinkModal from '../Modal/SmartLinkModal'

const ArtistSmartLinkCard = ({ item, totalLength }) => {
  const history = useHistory()
  const { isShowing, toggle } = SmartLinkModal.useModal()

  const { artistStore } = useStore()

  const { smartLinkUpdating } = artistStore
  const createSmartLink = artistStore?.createSmartLink || (() => {})
  const updateSmartLink = artistStore?.updateSmartLink || (() => {})
  const removeSmartLink = artistStore?.removeSmartLink || (() => {})

  const handleClick = link => {
    history.push(`/insight/smart-link?link=${link}`)
  }

  const handleCreate = link => {
    createSmartLink([link])
  }

  const handleUpdate = link => {
    updateSmartLink(item._id, link)
  }

  const handleSort = idx => {
    updateSmartLink(item._id, { sortIndex: idx })
  }

  const handleDelete = e => {
    e.stopPropagation()
    removeSmartLink(item._id)
  }

  const handleOpenModal = e => {
    e.stopPropagation()
    toggle()
  }

  return (
    <>
      {item ? (
        <Item onClick={() => handleClick(item?.linkUrl)}>
          <Flex type="column" style={{ flex: 1, overflow: 'hidden' }}>
            <Caption1 align="left">{item?.title}</Caption1>
            <Caption2 align="left">{item?.linkUrl}</Caption2>
          </Flex>
          <Flex
            align="center"
            gap="18px"
            style={{ width: 138, height: '100%' }}
          >
            <Caption2
              color="#0066ff"
              style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
              onClick={handleOpenModal}
            >
              수정
            </Caption2>
            <img
              onClick={handleDelete}
              src={x_btn_img}
              alt="remove_btn"
              style={{
                width: 10,
                height: 10,
                cursor: 'pointer',
              }}
            />
            {smartLinkUpdating ? (
              <Flex gap="12px" onClick={e => e.stopPropagation()}>
                <ReactLoading
                  type="spin"
                  color="black"
                  style={{
                    width: '16px',
                    height: '16px',
                  }}
                />
              </Flex>
            ) : (
              <Flex
                gap="12px"
                onClick={e => e.stopPropagation()}
                style={{ height: '50px', alignItems: 'center' }}
              >
                <LeftBtn
                  onClick={e => {
                    if (item.sortIndex === 1) {
                      handleSort(totalLength)
                    } else {
                      handleSort(item.sortIndex - 1)
                    }
                  }}
                >
                  <div />
                </LeftBtn>
                <RightBtn
                  onClick={e => {
                    if (item.sortIndex === totalLength) {
                      handleSort(0)
                    } else {
                      handleSort(item.sortIndex + 1)
                    }
                  }}
                >
                  <div />
                </RightBtn>
              </Flex>
            )}
          </Flex>
        </Item>
      ) : (
        <Item onClick={handleOpenModal} isNew>
          <Flex gap="8px">
            <img
              src={add_btn}
              style={{ width: 20, height: 20 }}
              alt="plus_icon"
            />
            <Caption1>링크 만들기</Caption1>
          </Flex>
        </Item>
      )}
      <SmartLinkModal.View
        isShowing={isShowing}
        toggle={toggle}
        smartLink={item}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
      />
    </>
  )
}

export default observer(ArtistSmartLinkCard)

const Item = styled(Flex)`
  width: 446px;
  height: 64px;

  padding: 0 12px;
  justify-content: space-between;
  align-items: center;

  border-radius: 6px;
  cursor: pointer;

  background-color: #fff;

  ${({ isNew }) =>
    isNew
      ? css`
          border: solid 1px #d4d4d4;
        `
      : css`
          border: solid 2px #242424;
        `}
`

const LeftBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 50%;

  :hover {
    background-color: #f4f4f4;
  }

  > div {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;

    border-right: 6px solid black;
  }
`
const RightBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 50%;

  :hover {
    background-color: #f4f4f4;
  }

  > div {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;

    border-left: 6px solid black;
  }
`
