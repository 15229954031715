import React from 'react'
import styled from 'styled-components'
import { Flex, Caption2, H6 } from '@components/atoms'
import { melon_img, bugs_img, genie_img } from '@images/'
import { convertToPricingComma } from '@utils/format'
import { IncreaseTextBox } from '@components/molecules'

const Box = styled(Flex)`
  width: 269px;
  height: 40px;
  // justify-content: center;

  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
`

const StreamingFollowerCard = ({
  streamingType,
  data,
  periodData,
  unit,
  onClick,
}) => {
  return (
    <Box onClick={onClick}>
      <img
        src={
          streamingType === 'melon'
            ? melon_img
            : streamingType === 'bugs'
            ? bugs_img
            : streamingType === 'genie'
            ? genie_img
            : false
        }
        alt="sns_img"
      />
      <Flex type="column">
        <Caption2 align="left" color="#949494">
          Total
        </Caption2>
        <H6 type="Bold" align="left">{`${(data &&
          convertToPricingComma(data[`${streamingType}`])) ||
          0} ${unit ? unit : 'followers'}`}</H6>
      </Flex>

      <IncreaseTextBox
        data={periodData && periodData[`${streamingType}`]}
        style={{ alignSelf: 'flex-end', marginLeft: '10px' }}
      />
    </Box>
  )
}

export default StreamingFollowerCard
