import React, { useMemo, useState } from 'react'
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  // Bar,
  Line,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import { CheckTextBox } from '@components/molecules'
import { Caption2, Flex } from '@components/atoms'

import styled from 'styled-components'
import { convertToPricingComma } from '@utils/format'
import moment from 'moment'
import { useStore } from '@utils/hooks'

const NoData = styled(Flex)`
  width: 1054px;
  height: 500px;
  justify-content: center;
  align-items: center;
`

const COLORS = ['#fcc302', '#ea4653', '#66bc6a', '#4e5cff']

const TrackRevenueStatsChart = ({
  currentMonth,
  data,
  expectedData,
  isCompany = false,
}) => {
  const { authStore } = useStore()
  const { currentUser } = authStore
  const isCompanyData = isCompany && currentUser?.type === 'company'

  const [filterMedia, setFilterMedia] = useState('')

  const convertData = useMemo(() => {
    const initData = []
    const year = moment(currentMonth).year()
    for (let i = 0; i < 12; i++) {
      const month = i < 9 ? `0${i + 1}` : i + 1
      const salesMonth = Number(`${year % 100}${month}`)
      const serverMonth = data.filter(item => item.salesMonth === salesMonth)[0]

      if (serverMonth) {
        initData[i] = serverMonth
      } else {
        initData[i] = { salesMonth }
      }
    }
    return initData
  }, [currentMonth, data])

  const firstMonthExpectedYear = expectedData[0]?.month
  const isRecentYear =
    `${firstMonthExpectedYear?.toString()?.slice(0, 2)}` ===
    moment(currentMonth).format('YY')

  const parseExpectedData = useMemo(() => {
    let parseData = []

    parseData = expectedData.map(item => ({
      ...item,
      artistMonthlyAdjacencyFee: item.adjacencyFee,
      artistMonthlyCopyRightFee: item.copyRightFee,
      artistMonthlyPerformanceRightFee: item.performanceRightFee,
      companyMonthlyAdjacencyFee: item.adjacencyFee,
      companyMonthlyCopyRightFee: item.copyRightFee,
      companyMonthlyPerformanceRightFee: item.performanceRightFee,
      salesMonth: item.month,
    }))

    return parseData
  }, [expectedData])

  return (
    <Flex>
      {convertData.length === 0 ? (
        <NoData>No data</NoData>
      ) : (
        <ResponsiveContainer width="80%" height={500}>
          <ComposedChart
            // width={1054}
            // height={500}
            margin={{
              top: 50,
              right: 0,
              bottom: 50,
              left: 50,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="salesMonth"
              tickFormatter={tick => {
                const strTick = `${tick}`
                return `${strTick[2]}${strTick[3]}월`
              }}
              allowDuplicatedCategory={false}
            />
            <YAxis
              domain={['auto', 'auto']}
              unit="원"
              tickFormatter={e => convertToPricingComma(e)}
            />
            <Tooltip
              content={({ active, payload, label }) => (
                <CustomTooltip
                  active={active}
                  payload={payload}
                  label={label}
                  isCompanyData={isCompanyData}
                  filterMedia={filterMedia}
                />
              )}
            />
            <Legend
              verticalAlign="top"
              layout="horizontal"
              align="center"
              wrapperStyle={{ marginTop: -12 }}
            />
            <Line
              data={convertData}
              stroke={COLORS[0]}
              name="총 수익"
              type="monotone"
              dataKey={`totalRevenue`}
              hide={!(filterMedia === '' || filterMedia === 'totalRevenue')}
            />
            <Line
              data={convertData}
              stroke={COLORS[1]}
              name="인접권"
              dataKey={
                isCompanyData
                  ? 'companyMonthlyAdjacencyFee'
                  : 'artistMonthlyAdjacencyFee'
              }
              hide={!(filterMedia === 'adjacencyFee' || filterMedia === '')}
            />
            <Line
              data={convertData}
              stroke={COLORS[2]}
              name="저작권"
              type="monotone"
              dataKey={
                isCompanyData
                  ? 'companyMonthlyCopyRightFee'
                  : 'artistMonthlyCopyRightFee'
              }
              hide={!(filterMedia === '' || filterMedia === 'copyRightFee')}
            />
            <Line
              data={convertData}
              stroke={COLORS[3]}
              name="실연권"
              dataKey={
                isCompanyData
                  ? 'companyMonthlyPerformanceRightFee'
                  : 'artistMonthlyPerformanceRightFee'
              }
              hide={
                !(filterMedia === 'performanceRightFee' || filterMedia === '')
              }
            />
            {isRecentYear && [
              <Line
                data={parseExpectedData}
                strokeDasharray="5 5"
                stroke={COLORS[0]}
                name="총 수익"
                type="monotone"
                dataKey={`totalRevenue`}
                hide={!(filterMedia === '' || filterMedia === 'totalRevenue')}
                legendType="none"
              />,
              <Line
                data={parseExpectedData}
                strokeDasharray="5 5"
                stroke={COLORS[1]}
                name="인접권"
                dataKey={
                  isCompanyData
                    ? 'companyMonthlyAdjacencyFee'
                    : 'artistMonthlyAdjacencyFee'
                }
                hide={!(filterMedia === 'adjacencyFee' || filterMedia === '')}
                legendType="none"
              />,
              <Line
                data={parseExpectedData}
                strokeDasharray="5 5"
                stroke={COLORS[2]}
                name="저작권"
                type="monotone"
                dataKey={
                  isCompanyData
                    ? 'companyMonthlyCopyRightFee'
                    : 'artistMonthlyCopyRightFee'
                }
                hide={!(filterMedia === '' || filterMedia === 'copyRightFee')}
                legendType="none"
              />,
              <Line
                data={parseExpectedData}
                strokeDasharray="5 5"
                stroke={COLORS[3]}
                name="실연권"
                dataKey={
                  isCompanyData
                    ? 'companyMonthlyPerformanceRightFee'
                    : 'artistMonthlyPerformanceRightFee'
                }
                hide={
                  !(filterMedia === 'performanceRightFee' || filterMedia === '')
                }
                legendType="none"
              />,
            ]}
          </ComposedChart>
        </ResponsiveContainer>
      )}
      <Flex
        type="column"
        style={{
          margin: '0 0 50px 120px',
          width: 'auto',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        {/* <H6>유입 매체</H6> */}
        <CheckTextBox
          checked={filterMedia === '' ? true : false}
          onClick={() => setFilterMedia('')}
          text="전체"
        />
        <CheckTextBox
          checked={filterMedia === 'totalRevenue' ? true : false}
          onClick={() => setFilterMedia('totalRevenue')}
          text="총수익"
        />
        <CheckTextBox
          checked={filterMedia === 'adjacencyFee' ? true : false}
          onClick={() => setFilterMedia('adjacencyFee')}
          text="인접권 수익"
        />
        <CheckTextBox
          checked={filterMedia === 'copyRightFee' ? true : false}
          onClick={() => setFilterMedia('copyRightFee')}
          text="저작권 수익"
        />
        <CheckTextBox
          checked={filterMedia === 'performanceRightFee' ? true : false}
          onClick={() => setFilterMedia('performanceRightFee')}
          text="실연권 수익"
        />
      </Flex>
    </Flex>
  )
}

export default TrackRevenueStatsChart

const ToolTipBox = styled(Flex)`
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;

  p {margin: 5px 0;}
  p:first-child {margin 0;}
`

const CustomTooltip = ({
  active,
  payload,
  label,
  isCompanyData,
  filterMedia,
}) => {
  const item = payload && payload[0] && payload[0].payload && payload[0].payload

  const strTick = `${label}`
  const customLabel = `${strTick[0]}${strTick[1]}년 ${strTick[2]}${strTick[3]}월`
  const isExpectating = !!item?.month
  const isPendingAggregation = isExpectating && `${item?.month}` === moment().format('YYMM')

  if (active) {
    return (
      <ToolTipBox type="column">
        <Caption2 type="Bold" align="left">
          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
            {`${customLabel} ${isExpectating ? '예상' : ''} 수익`}
            {isPendingAggregation && <Caption2 type="Regular" align="left" color="#949494" margin="0px">{`당월 집계중`}</Caption2>}
          </span>
        </Caption2>
        {(filterMedia === '' || filterMedia === 'totalRevenue') && (
          <Caption2 align="left">
            <div
              style={{
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: COLORS[0],
                display: 'inline-block',
                marginRight: 8,
              }}
            />
            총 수익 :
            {`${item &&
              convertToPricingComma(Math.floor(item.totalRevenue))}원`}
          </Caption2>
        )}
        {(filterMedia === 'totalRevenue' ||
          filterMedia === 'adjacencyFee' ||
          filterMedia === '') && (
            <Caption2 align="left">
              <div
                style={{
                  width: 6,
                  height: 6,
                  borderRadius: '50%',
                  backgroundColor: COLORS[1],
                  display: 'inline-block',
                  marginRight: 8,
                }}
              />
              인접권료 :
              {`${item &&
                convertToPricingComma(
                  Math.floor(
                    isCompanyData
                      ? item.companyMonthlyAdjacencyFee
                      : item.artistMonthlyAdjacencyFee,
                  ),
                )}원`}
            </Caption2>
          )}
        {(filterMedia === 'totalRevenue' ||
          filterMedia === 'copyRightFee' ||
          filterMedia === '') && (
            <Caption2 align="left">
              <div
                style={{
                  width: 6,
                  height: 6,
                  borderRadius: '50%',
                  backgroundColor: COLORS[2],
                  display: 'inline-block',
                  marginRight: 8,
                }}
              />
              저작권료 :
              {`${item &&
                convertToPricingComma(
                  Math.floor(
                    isCompanyData
                      ? item.companyMonthlyCopyRightFee
                      : item.artistMonthlyCopyRightFee,
                  ),
                )}원`}
            </Caption2>
          )}
        {(filterMedia === 'totalRevenue' ||
          filterMedia === 'performanceRightFee' ||
          filterMedia === '') && (
            <Caption2 align="left">
              <div
                style={{
                  width: 6,
                  height: 6,
                  borderRadius: '50%',
                  backgroundColor: COLORS[3],
                  display: 'inline-block',
                  marginRight: 8,
                }}
              />
              실연권료 :
              {`${item &&
                convertToPricingComma(
                  Math.floor(
                    isCompanyData
                      ? item.companyMonthlyPerformanceRightFee
                      : item.artistMonthlyPerformanceRightFee,
                  ),
                )}원`}
            </Caption2>
          )}
        {isExpectating && (
          <Caption2 align="left" color="#949494">
            *과거 정산 자료를 등록하시면
            <br />더 정확한 예상 수익을 측정할 수 있습니다.
          </Caption2>
        )}
      </ToolTipBox>
    )
  }
  return null
}
