import React, { useEffect, useRef, useState } from 'react'
import { Modal, Input, Spin } from 'antd'

const AddAdjustmentTypeModal = ({
  isVisible,
  onAdd,
  onCancel,
  newTypeText,
  setNewTypeText,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    if (isVisible) {
      inputRef.current?.focus()
    }
  }, [isVisible])

  const handleOk = async () => {
    setIsLoading(true)
    await onAdd(newTypeText)
    setIsLoading(false)
    setNewTypeText('')
  }

  const handleKeyDown = async e => {
    if (e.key === 'Enter') {
      await handleOk()
    }
  }

  return (
    <Modal
      title="정산 구분 추가"
      visible={isVisible}
      onOk={() => onAdd(newTypeText)}
      onCancel={onCancel}
      okText="추가"
      cancelText="취소"
    >
      <Spin spinning={isLoading} delay={500}>
        <Input
          autoFocus
          ref={inputRef}
          value={newTypeText}
          onChange={e => setNewTypeText(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isLoading} // 로딩 중에는 입력 불가
        />
      </Spin>
    </Modal>
  )
}

export default AddAdjustmentTypeModal
