import React from 'react'

import { Observer, Provider } from 'mobx-react'

import { Loading } from '@components/molecules'
import { Flex } from './components/atoms'
import Routes from './pages/Routes'
import { Store } from './stores'

const App: React.FC = () => {
  const stores = new Store()
  stores.initClient()

  return (
    <div className="App">
      <Provider {...stores}>
        <Routes />
        <Observer>
          {
            (): any =>
              (!stores || stores.isLoading) && (
                <Flex
                  style={
                    {
                      position: 'fixed',
                      top: 0,
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(36, 36, 36, 0.5)',
                      zIndex: 9999,
                    }
                  }
                >
                  <Loading type="spin" color="white" />
                </Flex>
              )
          }
        </Observer>
      </Provider>
    </div>
  )
}

export default App
