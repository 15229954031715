import React, { useMemo } from 'react'
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts'
import { Caption2, Flex, Text } from '@components/atoms'
import styled from 'styled-components'
import { convertToPricingComma } from '@utils/format'

const NoData = styled(Flex)`
  width: 180px;
  height: 180px;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  border: 1px solid #dddddd;
  border-radius: 50%;
  margin-top: 110px;
`

const SectionTitle = styled(Text)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  margin-bottom: 20px;
  align-self: center;
`

const COLORS = [
  '#FC0211',
  '#1877F2',
  '#FF9742',
  '#FDB624',
  '#18D63B',
  '#761CF5',
  '#3B3BFD',
  '#FC0211',
  '#2E1C7F',
  '#FF2D55',
  '#242424',
  '#20B6E6',
  '#D4D4D4',
  '#EA4653',
]

const CustomLegend = ({ entry, isChannel = false }) => {
  return (
    <li
      className="recharts-legend-item legend-item-3"
      style={{ display: 'block' }}
    >
      <svg
        className="recharts-surface"
        width="14"
        height="14"
        viewBox="0 0 32 32"
        version="1.1"
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginRight: '4px',
        }}
      >
        <path
          stroke="none"
          fill={entry?.color}
          d="M0,4h32v24h-32z"
          className="recharts-legend-icon"
        />
      </svg>
      <span className="recharts-legend-item-text">
        {isChannel ? entry.payload.serviceChannel : entry.payload.countryCode}
      </span>
      <span className="recharts-legend-item-text">
        &nbsp;:&nbsp;
        {(entry.payload.percent * 100)?.toFixed(1)}%
      </span>
    </li>
  )
}

const RevenueAdjacencyStatsPieChart = ({ data, data2 }) => {
  const sumTotalRevenue = data.reduce(
    (prev, curr) => prev + Number(curr.totalRevenue),
    0,
  )
  const topData = useMemo(() => {
    const korData = data?.filter(item => item?.countryCode === 'KR')[0]
    const sortData = data
      ?.filter(item => item?.countryCode !== 'KR')
      ?.sort((a, b) => b.totalRevenue - a.totalRevenue)
    const top5Data = sortData.slice(0, 5)
    const extraData = sortData.slice(6, sortData.length - 1)

    const etcData = {
      countryCode: 'Etc',
      totalRevenue: extraData.reduce(
        (prev, curr) => prev + Number(curr.totalRevenue),
        0,
      ),
    }

    return [...top5Data, korData, etcData]
  }, [data])

  return (
    <Flex>
      <Flex type="column" style={{ margin: '0 30px 0 50px' }}>
        <SectionTitle>국내/해외 수익 비율 분석</SectionTitle>
        {data?.length === 0 ? (
          <NoData>데이터가 없습니다.</NoData>
        ) : (
          <PieChart width={350} height={400}>
            <Pie
              dataKey="totalRevenue"
              isAnimationActive={false}
              data={topData}
              cx={'90'}
              cy={'180'}
              outerRadius={'90'}
              fill="#8884d8"
              labelLine={false}
            >
              {topData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index]} />
              ))}
            </Pie>
            <Tooltip
              content={({ active, payload }) => {
                const item =
                  payload &&
                  payload[0] &&
                  payload[0].payload &&
                  payload[0].payload

                if (active) {
                  return (
                    <ToolTipBox type="column">
                      <Caption2 align="left">
                        {`${item.countryCode} : ${convertToPricingComma(
                          item.totalRevenue.toFixed(),
                        )}원 (${(
                          (item.totalRevenue / sumTotalRevenue) *
                          100
                        ).toFixed(2)}%)`}
                      </Caption2>
                    </ToolTipBox>
                  )
                }
                return null
              }}
            />
            <Legend
              layout="vertical"
              verticalAlign="middle"
              align="right"
              content={({ payload }) => {
                return (
                  <ul>
                    {payload
                      .sort(
                        (a, b) =>
                          b.payload.totalRevenue - a.payload.totalRevenue,
                      )
                      .map((entry, index) => (
                        <CustomLegend key={`item-${index}`} entry={entry} />
                      ))}
                  </ul>
                )
              }}
            />
          </PieChart>
        )}
      </Flex>
      <Flex type="column" style={{ margin: '0 30px 0 50px' }}>
        <SectionTitle>음악 서비스별 분석</SectionTitle>
        {data2.length === 0 ? (
          <NoData>데이터가 없습니다.</NoData>
        ) : (
          <PieChart width={350} height={400}>
            <Pie
              dataKey="totalRevenue"
              isAnimationActive={false}
              data={data2}
              cx={'90'}
              cy={'180'}
              outerRadius={'90'}
              fill="#8884d8"
              labelLine={false}
            >
              {data2.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index]} />
              ))}
            </Pie>
            <Tooltip
              content={({ active, payload }) => {
                const item =
                  payload &&
                  payload[0] &&
                  payload[0].payload &&
                  payload[0].payload

                if (active) {
                  return (
                    <ToolTipBox type="column">
                      <Caption2 align="left">
                        {`${item.serviceChannel} : ${convertToPricingComma(
                          item.totalRevenue.toFixed(),
                        )}원 (${(
                          (item.totalRevenue / sumTotalRevenue) *
                          100
                        ).toFixed(2)}%)`}
                      </Caption2>
                    </ToolTipBox>
                  )
                }
                return null
              }}
            />
            <Legend
              layout="vertical"
              verticalAlign="middle"
              align="right"
              content={({ payload }) => {
                return (
                  <ul>
                    {payload
                      .sort(
                        (a, b) =>
                          b.payload.totalRevenue - a.payload.totalRevenue,
                      )
                      .map((entry, index) => (
                        <CustomLegend
                          key={`item-${index}`}
                          entry={entry}
                          isChannel
                        />
                      ))}
                  </ul>
                )
              }}
            />
          </PieChart>
        )}
      </Flex>
      {/* <Flex type="column">
        <SectionTitle>전환 매체</SectionTitle>
        {conversionData.length === 0 ? (
          <NoData>No data</NoData>
        ) : (
          <PieChart width={400} height={400}>
            <Pie
              dataKey="value"
              data={conversionData}
              cx={'90'}
              cy={'180'}
              outerRadius={'90'}
              fill="#8884d8"
              labelLine={false}
              // label={renderActiveShape}
            >
              {conversionData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    color_media.filter(function(object) {
                      return object['name'] === entry.name
                    })[0].color
                  }
                />
              ))}
            </Pie>
            <Tooltip
              formatter={function(value, name) {
                return `${value} (${Math.floor(
                  (value / sumInflow.sumClickCount) * 100,
                )}%)`
              }}
            />

            <Legend
              layout="vertical"
              verticalAlign="middle"
              align="right"
              content={({ payload }) => {
                return (
                  <ul>
                    {payload
                      .sort((a, b) => b.payload.value - a.payload.value)
                      .map((entry, index) => (
                        <CustomLegend key={`item-${index}`} entry={entry} />
                      ))}
                  </ul>
                )
              }}
            />
          </PieChart>
        )}
      </Flex> */}
    </Flex>
  )
}

export default RevenueAdjacencyStatsPieChart

const ToolTipBox = styled(Flex)`
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;

  img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  p {
    margin: 5px 0;
  }

  p:first-child {
      margin 0;
  }
`
