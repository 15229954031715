export const melon_img = require('@images/streaming/melon_img.png')
export const youtube_img = require('@images/streaming/youtube_img.png')
export const genie_img = require('@images/streaming/genie_img.png')
export const bugs_img = require('@images/streaming/bugs_img.png')
export const search_img = require('@images/search_img.png')

export const instagram_follower_img = require('@images/social_media/instagram_follower_img.png')
export const youtube_follower_img = require('@images/social_media/youtube_follower_img.png')
export const twitter_follower_img = require('@images/social_media/x_img.png')
export const soundcloud_follower_img = require('@images/social_media/soundcloud_follower_img.png')
export const x_btn_img = require('@images/x_btn_img.png')
export const add_btn = require('@images/add_btn.png')
export const exist_subdomain_img = require('@images/exist_subdomain_img.png')
export const clip_img_blue = require('@images/clip_img_blue.png')
export const add_btn_gray = require('@images/add_btn_gray.png')
export const modify_img = require('@images/modify_img.png')
export const select_empty_img = require('@images/select_empty_img.png')
export const select_img = require('@images/select_img.png')
export const path_menu = require('@images/path_menu.png')
export const down_btn = require('@images/down_btn.png')
export const loginPageImage = require('@images/loginpage_img.png')
export const loginPageImageMobile = require('@images/loginpage_img_mobile.png')

export const loginPageBanner01 = require('@images/login_page_img_01.png')
export const loginPageBanner02 = require('@images/login_page_img_02.png')
export const loginPageBanner03 = require('@images/login_page_img_03.png')
export const loginPageBanner04 = require('@images/login_page_img_04.png')
export const loginPageBanner05 = require('@images/login_page_img_05.png')
export const loginPageBanner06 = require('@images/login_page_img_06.png')
export const loginPageBanner07 = require('@images/login_page_img_07.png')
export const loginPageBanner08 = require('@images/login_page_img_08.png')
export const loginPageBanner09 = require('@images/login_page_img_09.png')
export const loginPageBanner10 = require('@images/login_page_img_10.png')
export const loginPageBanner11 = require('@images/login_page_img_11.png')
export const loginPageBanner12 = require('@images/login_page_img_12.png')
export const loginPageBanner13 = require('@images/login_page_img_13.png')
export const loginPageBanner14 = require('@images/login_page_img_14.png')
export const loginPageBanner15 = require('@images/login_page_img_15.png')

export const firstMyPageLogoImg = require('@images/first_mypage_logo.png')
export const firstAlbumPageLogoImg = require('@images/first_albumpage_logo.png')
export const firstRevenuePageLogoImg = require('@images/first_revenuepage_logo.png')

export const youtubeTrendImg = require('@images/streaming/youtube_trend_img.png')
export const bugsTrendImg = require('@images/streaming/bugs_trend_img.png')
export const spotifyTrendImg = require('@images/streaming/spotify_trend_img.png')
export const melonTrendImg = require('@images/streaming/melon_trend_img.png')
export const genieTrendImg = require('@images/streaming/genie_trend_img.png')

export const genieSnsImg = require('@images/social_media/genie_sns_img.png')
export const bugsSnsImg = require('@images/social_media/bugs_sns_img.png')
export const twitterSnsImg = require('@images/social_media/x_img.png')
export const youtubeSnsImg = require('@images/social_media/youtube_sns_img.png')
export const melonSnsImg = require('@images/social_media/melon_sns_img.png')
export const soundcloudSnsImg = require('@images/social_media/soundcloud_sns_img.png')
export const instagramSnsImg = require('@images/social_media/instagram_sns_img.png')
export const spotifySnsImg = require('@images/social_media/spotify_sns_img.png')

export const copyGrayImg = require('@images/copy_img_gray.png')

export const arrowRight = require('@images/icon_arrow_right.png')
export const arrowLeft = require('@images/icon-arrowLeft.png')
export const arrowLeftGray = require('@images/icon-arrowLeft-gray.png')
export const plaminLogo = require('@images/plamin_logo.png')
export const cameraWhite = require('@images/camera_img_white.png')

// rs
export const RSImageEmpty = require('@images/rs/rs_list_empty.png')
export const RSWritingEmpty = require('@images/rs/rs_writing_empty.png')
export const youtubePreviewFail = require('@images/youtube_preview_fail_image.png')
export const iconQeustionMark = require('@images/icon-questionMark.png')

export const companyNoInsight = require('@images/company_no_insight_img.png')
export const companyNoAlbum = require('@images/company_no_album_img.png')
export const subImg = require('@images/sub.png')

export const youtubeMusicImg = require('@images/youtubeMusic_img.png')
export const plamImg = require('@images/plam_img.png')
export const companyDefaultLogoImg = require('@images/company_default_logo.png')
/* snsType to snsImgSource */
export const SNS_SOURCES = {
  genie: genieSnsImg,
  melon: melonSnsImg,
  spotify: spotifySnsImg,
  instagram: instagramSnsImg,
  youtube: youtubeSnsImg,
  'x(twitter)': twitterSnsImg,
  soundcloud: soundcloudSnsImg,
  bugs: bugsSnsImg,
}
