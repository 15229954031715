import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Caption1, Flex, Image, Input } from '@components/atoms'
import { x_btn_img, youtubePreviewFail } from '@images/index'
import _ from 'lodash'

const getDebounceFunc = () =>
  _.debounce(async (value, setPreviewMetadata) => {
    const _setPreviewMetadata = _previewMetadata => {
      setPreviewMetadata(_previewMetadata)
      if (_previewMetadata) {
        Object.keys(_previewMetadata).forEach(key => {
          value[key] = _previewMetadata[key]
        })
      }
    }

    let response = null
    if (value?.url) {
      const _response = await fetch(
        `https://rlp-proxy.plam.kr/v2?url=${encodeURIComponent(value?.url)}`,
      )
      response = (await _response?.json()) || null
    }

    if (response?.metadata?.image) {
      const _previewMetadata = {
        fetchedPreviewMetadataId: value?.url,
        ...response?.metadata,
      }
      _setPreviewMetadata(_previewMetadata)
    } else {
      const _previewMetadata = null
      _setPreviewMetadata(_previewMetadata)
    }
  }, 250)

const debounceFuncPool = {}
const YoutubeUrlListInput = ({
  value,
  sortIndex,
  updateUrl,
  removeUrlObj,
  sortPrevUrlObj,
  sortNextUrlObj,
}) => {
  const [previewMetadata, setPreviewMetadata] = useState(value)

  useEffect(() => {
    if (
      value?.fetchedPreviewMetadataId &&
      value?.fetchedPreviewMetadataId === value?.url
    ) {
      setPreviewMetadata(value)
    } else if (
      !previewMetadata?.fetchedPreviewMetadataId ||
      previewMetadata.fetchedPreviewMetadataId !== value?.url
    ) {
      if (!debounceFuncPool[sortIndex]) {
        debounceFuncPool[sortIndex] = getDebounceFunc()
      }
      const getPreviewImage = debounceFuncPool[sortIndex]

      getPreviewImage(value, setPreviewMetadata)
    }
  }, [value?.url])

  return (
    <Item isNew={!value?.url}>
      <InputArea>
        <Input
          style={{
            width: '100%',
            border: 'none',
            height: 20,
            paddingLeft: 0,
            outline: 0,
          }}
          placeholder="Url 링크를 입력해주세요."
          value={value?.url}
          onChange={e => updateUrl(sortIndex, e.target.value)}
        />
        <Flex align="center" gap="18px" style={{ height: '100%' }}>
          <img
            onClick={() => removeUrlObj(sortIndex)}
            src={x_btn_img}
            alt="remove_btn"
            style={{
              width: 10,
              height: 10,
              cursor: 'pointer',
            }}
          />

          <Flex
            gap="12px"
            onClick={e => e.stopPropagation()}
            style={{ height: '50px', alignItems: 'center' }}
          >
            <LeftBtn onClick={sortPrevUrlObj} />
            <RightBtn onClick={sortNextUrlObj} />
          </Flex>
        </Flex>
      </InputArea>
      {!!previewMetadata?.fetchedPreviewMetadataId && (
        <PreviewArea>
          {previewMetadata ? (
            <>
              <Image src={previewMetadata.image} widht="64px" height="36px" />
              <Caption1
                align="left"
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {previewMetadata?.title}
              </Caption1>
            </>
          ) : (
            <>
              <Flex
                align="center"
                justify="center"
                style={{
                  width: 64,
                  height: 36,
                  backgroundColor: '#f4f4f4',
                }}
              >
                <Image
                  src={youtubePreviewFail}
                  widht="12px"
                  height="12px"
                  alt="fail"
                />
              </Flex>

              <Caption1
                color="#646464"
                align="left"
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                유튜브 링크를 가져오는데 오류가 발생했습니다.
                <br />
                링크를 확인하고 다시 시도해주세요.
              </Caption1>
            </>
          )}
        </PreviewArea>
      )}
    </Item>
  )
}

export default YoutubeUrlListInput

const Item = styled(Flex)`
  width: 375px;
  height: fit-content;
  max-height: 90px;

  padding: 10px 12px;
  flex-direction: column;
  gap: 12px;

  border-radius: 6px;

  background-color: #fff;

  ${({ isNew }) =>
    isNew
      ? css`
          border: solid 1px #949494;
        `
      : css`
          border: solid 2px #242424;
        `}
`

const InputArea = styled(Flex)`
  gap: 12px;
  width: 100%;
  height: 20px;

  justify-content: space-between;
  align-items: center;
`

const PreviewArea = styled(Flex)`
  gap: 8px;
  width: 100%;
  height: 36px;
  align-items: center;
`

const LeftBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  border-right: 6px solid black;
`
const RightBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid black;
`
