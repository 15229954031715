import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts'
import { Flex, Caption1, Caption2 } from '@components/atoms'
import styled from 'styled-components'
import { convertToPricingComma, insightConverseUnitEng } from '@utils/format'

const ToolTipBox = styled(Flex)`
  width: 280px;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  gap: 12px;
`

const COLORS = ['#0066ff', '#ea4653', '#66bc6a']

const ArtistSpotifyFollowerChart = ({ dailyListData, snsType, isNowCrawling = false }) => {
  const lastDate = Math.max(...(dailyListData?.map(elem => elem?.date) || []))

  return (
    <div
      style={{
        marginTop: '30px',
        borderRadius: '6px',
        boxShadow: '0 4px 10px 0 rgba(36,36,36,0.16)',
        backgroundColor: '#ffffff',
        maxWidth: 762,
        width: '100%',
        height: '440px',
      }}
    >
      {!dailyListData ? (
        <Flex
          style={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Caption1>No data</Caption1>
        </Flex>
      ) : (
        <ResponsiveContainer width="100%" height={440}>
          <LineChart
            data={dailyListData.reverse()}
            margin={{
              top: 10,
              right: 20,
              bottom: 10,
              left: 10,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="date"
              tickFormatter={tick => {
                const strTick = `${tick}`
                return `          ${strTick.slice(2, 4)}.${strTick.slice(
                  4,
                  6,
                )}.${strTick.slice(6, 8)}          `
              }}
              allowDuplicatedCategory={false}
              padding={{ left: 40, right: 40 }}
            />
            <YAxis
              dataKey={`listenerInfo.${snsType}`}
              tick={<TiltedYAxisTick />}
              domain={[
                dataMin => Math.floor(dataMin * 0.95),
                dataMax => Math.floor(dataMax * 1.05),
              ]}
            />
            <Tooltip content={<CustomTooltip snsType={snsType} isNowCrawling={isNowCrawling} lastDate={lastDate} />} />
            <Legend
              verticalAlign="top"
              layout="horizontal"
              align="left"
              height="50px"
            />
            <Line
              dataKey={`listenerInfo.${snsType}`}
              name="Listeners"
              type="monotone"
              stroke={COLORS[2]}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}

export default ArtistSpotifyFollowerChart

const CustomTooltip = ({ active, payload, label: _label, snsType, isNowCrawling, lastDate }) => {
  const item = payload && payload[0] && payload[0].payload && payload[0].payload
  const label = `${_label}`

  const _isNowCrawling = isNowCrawling
    && lastDate
    && item?.date
    && `${lastDate}` === `${item?.date}`
  if (active) {
    return (
      <ToolTipBox type="column">
        <Caption2
          type="Bold"
          align="left"
          color="#646464"
          style={{ fontSize: 10 }}
        >
          {`${label.slice(0, 4)}년 ${label.slice(4, 6)}월 ${label.slice(
            6,
            8,
          )}일`}
        </Caption2>
        {
          _isNowCrawling
            ? (
              <Caption2 align="left" style={{ fontSize: 10 }} color="#949494">
                * 데이터 수집중입니다
              </Caption2>
            )
            : (<></>)
        }
        <Flex justify="space-between">
          <Flex align="center" style={{ gap: 4 }}>
            <div
              style={{
                display: 'inline-block',
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: COLORS[2],
              }}
            />
            <Caption2>Listen</Caption2>
          </Flex>
          <Caption2 align="left">
            {`${item &&
              convertToPricingComma(item.listenerInfo[snsType] || 0)}명`}
          </Caption2>
        </Flex>

        <Flex justify="space-between">
          <Flex align="center" style={{ gap: 4 }}>
            <div
              style={{
                display: 'inline-block',
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: COLORS[0],
              }}
            />
            <Caption2>Follower</Caption2>
          </Flex>
          <Caption2 align="left">
            {`${item &&
              convertToPricingComma(
                (item.followerInfo && item.followerInfo[snsType]) || 0,
              )}명`}
          </Caption2>
        </Flex>
        <Flex justify="space-between">
          <Flex align="center" style={{ gap: 4 }}>
            <div
              style={{
                display: 'inline-block',
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: COLORS[1],
              }}
            />
            <Caption2>Popularity</Caption2>
          </Flex>
          <Caption2 align="left">
            {`${item && convertToPricingComma(item.rankInfo[snsType] || 0)}`}
          </Caption2>
        </Flex>
      </ToolTipBox>
    )
  }
  return null
}

const TiltedYAxisTick = props => {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-7} y={5} textAnchor="end" fill="#666">
        {insightConverseUnitEng(payload.value)}
      </text>
    </g>
  )
}
