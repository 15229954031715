export default class AdjustmentTypeNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getAdjustmentTypeList({ artistId, companyId } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/type?artistId=${artistId}&companyId=${companyId}`,
      )
      return data
    } catch (error) {
      console.error(
        '[AdjustmentTypeNetwork][getAdjustmentTypeList] error: ',
        error,
      )
    }
  }

  async postAdjustmentType({ params } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/type`,
        'post',
        params,
      )
      return data
    } catch (error) {
      console.error(
        '[AdjustmentTypeNetwork][postAdjustmentType] error: ',
        error,
      )
    }
  }

  async putAdjustmentType({ adjustmentTypeId, params } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/type/${adjustmentTypeId}`,
        'put',
        params,
      )
      return data
    } catch (error) {
      console.error('[AdjustmentTypeNetwork][putAdjustmentType] error: ', error)
    }
  }

  async deleteAdjustmentType({ adjustmentTypeId } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/type/${adjustmentTypeId}`,
        'delete',
      )
      return data
    } catch (error) {
      console.error(
        '[AdjustmentTypeNetwork][deleteAdjustmentType] error: ',
        error,
      )
    }
  }
}
