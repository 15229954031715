import styled from 'styled-components'

export const Input = styled.input`
    font-size: 14px;
    height: 40px;
    border-radius: 6px;
    background-color: #ffffff;
    padding : 10px 12px;
    margin : 0;
    // valid 체크 css
    :placeholder-shown {
        border : solid 1px #949494;
    }
    border : solid 1px #1985df;
`