/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react'
import { RootTemplate } from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import QueryString from 'qs'
import InsightSettlementTrackTemplate from '@components/templates/InsightSettlementTrackTemplate'

const InsightSettlementTrackPage = ({ user, logout, match }) => {
  const { authStore, revenueStore, artistStore, settlementStore } = useStore()
  const { currentUser } = authStore
  const { trackAnnualData, trackCurrentMonth } = settlementStore
  const { artistDetail } = artistStore
  const { artistRecentMonth, companyRecentMonth } = revenueStore

  const location = useLocation()
  const query = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const artistId = query?.artistId
  const isCompany = query?.isCompany === '1'

  const isCompanyData = currentUser?.type === 'company' && isCompany
  const isCompanyArtistData = currentUser?.type === 'company' && !isCompany

  const fetchArtistRecentMonth =
    revenueStore.fetchArtistRecentMonth || (() => {})
  const fetchCompanyRecentMonth =
    revenueStore.fetchCompanyRecentMonth || (() => {})
  const setTrackCurrentMonth =
    settlementStore.setTrackCurrentMonth || (() => {})
  const fetchSettlementArtistTrackAnnual =
    settlementStore.fetchSettlementArtistTrackAnnual || (() => {})
  const fetchSettlementCompanyTrackAnnual =
    settlementStore.fetchSettlementCompanyTrackAnnual || (() => {})
  const fetchSettlementCompanyArtistTrackAnnual =
    settlementStore.fetchSettlementCompanyArtistTrackAnnual || (() => {})

  useEffect(() => {
    if (artistRecentMonth) {
      setTrackCurrentMonth(
        moment().set({
          year: `20${Math.floor(artistRecentMonth / 100)}`,
          month: (artistRecentMonth % 100) - 1,
        }),
      )
    }
    else if (companyRecentMonth) {
      setTrackCurrentMonth(
        moment().set({
          year: `20${Math.floor(companyRecentMonth / 100)}`,
          month: (companyRecentMonth % 100) - 1,
        }),
      )
    }
  }, [artistRecentMonth, companyRecentMonth, setTrackCurrentMonth])

  useEffect(() => {
    if (currentUser) {
      if (isCompanyData && currentUser?.companyId) {
        fetchCompanyRecentMonth(currentUser?.companyId)
      }
      else {
        fetchArtistRecentMonth(currentUser?.artistId)
      }
    }
    if (
      currentUser
      && trackCurrentMonth
      && trackCurrentMonth
      && match.params.id
    ) {
      if (!isCompanyData && !isCompanyArtistData && currentUser?.artistId) {
        fetchSettlementArtistTrackAnnual(
          currentUser?.artistId,
          match.params.id,
          {
            __year: trackCurrentMonth.format('YY'),
          },
        )
      }
      else if (
        !isCompanyData
        && isCompanyArtistData
        && currentUser?.companyId
      ) {
        fetchSettlementCompanyArtistTrackAnnual(
          currentUser?.companyId,
          currentUser?.artistId,
          match.params.id,
          {
            __year: trackCurrentMonth.format('YY'),
          },
        )
      }
      else if (isCompanyData && currentUser?.companyId) {
        fetchSettlementCompanyTrackAnnual(
          currentUser?.companyId,
          match.params.id,
          {
            __year: trackCurrentMonth.format('YY'),
          },
        )
      }
    }
  }, [
    artistId,
    match,
    currentUser,
    trackCurrentMonth,
    fetchArtistRecentMonth,
    fetchCompanyRecentMonth,
    fetchSettlementArtistTrackAnnual,
    fetchSettlementCompanyTrackAnnual,
    fetchSettlementCompanyArtistTrackAnnual,
  ])

  const determineType = () => {
    if (currentUser?.type === 'company') {
      return match.path?.includes('/company/') && isCompany
        ? 'company'
        : 'company-artist'
    }
    else {
      return 'artist'
    }
  }

  return (
    <RootTemplate user={user} logout={logout} artist={artistDetail}>
      <InsightSettlementTrackTemplate
        type={determineType()}
        trackAnnualData={trackAnnualData}
      />
    </RootTemplate>
  )
}

export default observer(InsightSettlementTrackPage)
