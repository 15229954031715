import { action, observable } from 'mobx'
import { Store } from '.'
import { Network } from './networks'

export default class SettlementStore {
  @observable companyAnnualData
  @observable artistAnnualData
  @observable trackAnnualData
  @observable albumAnnualData
  @observable artistMonthlyData
  @observable companyMonthlyData

  @observable trackCurrentMonth
  @observable albumCurrentMonth

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.artistAnnualData = null
    this.companyAnnualData = null
    this.trackAnnualData = null
    this.albumAnnualData = null
    this.artistMonthlyData = null
    this.companyMonthlyData = null

    this.trackCurrentMonth = null
    this.albumCurrentMonth = null
  }

  @action.bound
  async initClient() {}

  @action.bound
  async setTrackCurrentMonth(value) {
    this.trackCurrentMonth = value
  }
  @action.bound
  async setAlbumCurrentMonth(value) {
    this.albumCurrentMonth = value
  }

  @action.bound
  async fetchSettlementArtistAnnual(artistObjectId, { __year }) {
    if (!artistObjectId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.settlementNetwork.getSettlementArtistAnnual(
        artistObjectId,
        {
          __year,
        },
      )

      if (!revenueData) {
        this.artistAnnualData = null
        return
      }

      this.artistAnnualData = revenueData?.data?.artist
      return this.artistAnnualData
    })
  }

  @action.bound
  async fetchSettlementCompanyArtistAnnual({
    companyObjectId,
    artistObjectId,
    __year,
  }) {
    if (!artistObjectId || !companyObjectId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.settlementNetwork.getSettlementCompanyArtistAnnual(
        companyObjectId,
        artistObjectId,
        {
          __year,
        },
      )

      if (!revenueData) {
        this.artistAnnualData = null
        return
      }

      this.artistAnnualData = revenueData?.data?.artist
      return this.artistAnnualData
    })
  }

  @action.bound
  async fetchSettlementCompanyAnnual(companyObjectId, { __year }) {
    if (!companyObjectId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.settlementNetwork.getSettlementCompanyAnnual(
        companyObjectId,
        {
          __year,
        },
      )

      if (!revenueData) return

      this.companyAnnualData = revenueData?.data?.company
      return this.companyAnnualData
    })
  }

  @action.bound
  async fetchSettlementArtistMonthly(
    artostObjectId,
    { __month, __distributor, __companyId },
  ) {
    if (!artostObjectId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.settlementNetwork.getSettlementArtistMonthly(
        artostObjectId,
        {
          __month,
          __distributor,
          __companyId,
        },
      )

      if (!revenueData) return

      this.artistMonthlyData = revenueData?.data?.artist
    })
  }

  @action.bound
  async fetchSettlementCompanyArtistMonthly(
    companyObjectId,
    artostObjectId,
    { __month, __distributor },
  ) {
    if (!artostObjectId || !companyObjectId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.settlementNetwork.getSettlementCompanyArtistMonthly(
        companyObjectId,
        artostObjectId,
        {
          __month,
          __distributor,
        },
      )

      if (!revenueData) return

      this.artistMonthlyData = revenueData?.data?.artist
    })
  }

  @action.bound
  async fetchSettlementCompanyMonthly(
    companyObjectId,
    { __month, __distributor },
  ) {
    if (!companyObjectId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.settlementNetwork.getSettlementCompanyMonthly(
        companyObjectId,
        {
          __month,
          __distributor,
        },
      )

      if (!revenueData) return

      this.companyMonthlyData = revenueData?.data?.company
    })
  }

  @action.bound
  async fetchSettlementArtistTrackAnnual(
    artistObjectId,
    trackObjectId,
    { __year },
  ) {
    if (!artistObjectId || !trackObjectId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.settlementNetwork.getSettlementArtistTrackAnnual(
        artistObjectId,
        trackObjectId,
        {
          __year,
        },
      )

      if (!revenueData) return

      this.trackAnnualData = revenueData?.data?.track
    })
  }

  @action.bound
  async fetchSettlementCompanyTrackAnnual(
    companyObjectId,
    trackObjectId,
    { __year },
  ) {
    if (!companyObjectId || !trackObjectId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.settlementNetwork.getSettlementCompanyTrackAnnual(
        companyObjectId,
        trackObjectId,
        {
          __year,
        },
      )

      if (!revenueData) return

      this.trackAnnualData = revenueData?.data?.track
    })
  }

  @action.bound
  async fetchSettlementCompanyArtistTrackAnnual(
    companyObjectId,
    artistObjectId,
    trackObjectId,
    { __year },
  ) {
    if (!companyObjectId || !trackObjectId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.settlementNetwork.getSettlementCompanyArtistTrackAnnual(
        companyObjectId,
        artistObjectId,
        trackObjectId,
        {
          __year,
        },
      )

      if (!revenueData) return

      this.trackAnnualData = revenueData?.data?.track
    })
  }

  @action.bound
  async fetchSettlementArtistAlbumAnnual(
    artistObjectId,
    albumObjectId,
    { __year, __trackMonth },
  ) {
    if (!artistObjectId || !albumObjectId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.settlementNetwork.getSettlementArtistAlbumAnnual(
        artistObjectId,
        albumObjectId,
        {
          __year,
          __trackMonth,
        },
      )

      if (!revenueData) return

      this.albumAnnualData = revenueData?.data?.album
    })
  }

  @action.bound
  async fetchSettlementCompanyAlbumAnnual(
    companyObjectId,
    albumObjectId,
    { __year, __trackMonth },
  ) {
    if (!companyObjectId || !albumObjectId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.settlementNetwork.getSettlementCompanyAlbumAnnual(
        companyObjectId,
        albumObjectId,
        {
          __year,
          __trackMonth,
        },
      )

      if (!revenueData) return

      this.albumAnnualData = revenueData?.data?.album
    })
  }

  @action.bound
  async fetchSettlementCompanyArtistAlbumAnnual(
    companyObjectId,
    artistObjectId,
    albumObjectId,
    { __year, __trackMonth },
  ) {
    if (!companyObjectId || !albumObjectId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const revenueData = await this.network.settlementNetwork.getSettlementCompanyArtistAlbumAnnual(
        companyObjectId,
        artistObjectId,
        albumObjectId,
        {
          __year,
          __trackMonth,
        },
      )

      if (!revenueData) return

      this.albumAnnualData = revenueData?.data?.album
    })
  }
}
