import React, { useState } from 'react'
import { Flex, Text } from '@components/atoms'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styled from 'styled-components'
import { inject } from 'mobx-react'

const SubMenu = styled.div`
  text-align: center;
  position: absolute;
  bottom: 20px;
  right: 0px;
  z-index: 100;
  width: 150px;
  height: fit-content;
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  overflow: visible;
`

const MenuList = styled.div`
  padding: 10px 16px;
  cursor: pointer;
  width: 152px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #242424;
`

const Title_img = require('@images/title_img.png')

const onelineStyle = {
  display: 'inline-block',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '290px',
}

const update_btn_img = require('@images/update_img_gray.png')
const copy_btn_img = require('@images/copy_img_gray.png')

const _TrackItem = ({
  authStore,
  album,
  track,
  artistName,
  navigateUpdateTrack,
  navigateDeleteTrack,
  showCopyToast,
  isCompanyView = false
}) => {
  // 주소 복사 팝업
  const handleCopy = () => {
    showCopyToast()
  }

  const [showEdit, setShowEdit] = useState(false) // 편집 보기

  const isLoginedMaster = authStore.isAdminPassword

  return (
    <Flex
      style={
        {
          justifyContent: 'center',
          alignItems: 'center',
        }
      }
    >
      <Flex
        style={
          {
            paddingRight: '20px',
            justifyContent: 'center',
            alignItems: 'center',
          }
        }
      >
        <Text size="14px" color="#646464">
          {track && track.no}
        </Text>
      </Flex>
      <Flex type="column" style={{ width: '256px' }}>
        <Flex
          style={
            {
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '2px',
            }
          }
        >
          {
            track && track.isTitle && (
              <img
                src={Title_img}
                alt="title"
                style={{ width: '34px', height: '16px', marginRight: '6px' }}
              />
            )
          }
          <Text style={onelineStyle} type="Medium" size="12px">
            {track && track.title}
          </Text>
        </Flex>
        <Text type="Medium" size="10px" color="#949494">
          {artistName}
        </Text>
      </Flex>
      <Flex
        style={
          {
            position: 'relative',
            width: '100%',
            justifyContent: 'flex-end',
          }
        }
      >
        <CopyToClipboard
          text={track && track.trackUrl}
          onCopy={() => handleCopy()}
        >
          <img
            src={copy_btn_img}
            alt="copy"
            style={
              {
                width: '22px',
                height: '22px',
                cursor: 'pointer',
              }
            }
          />
        </CopyToClipboard>
        {
          isLoginedMaster && album && album.isOwner && !isCompanyView && (
            <div
              onClick={() => setShowEdit(true)}
              onMouseOver={() => setShowEdit(true)}
              onMouseLeave={() => setShowEdit(false)}
            >
              <img
                src={update_btn_img}
                alt="update"
                style={
                  {
                    width: '22px',
                    height: '22px',
                    cursor: 'pointer',
                    marginLeft: 16,
                  }
                }
              />
              {
                showEdit && (
                  <SubMenu>
                    <MenuList onClick={() => navigateUpdateTrack(track, album)}>
                      트랙 수정
                    </MenuList>
                    <MenuList onClick={() => navigateDeleteTrack(track, album)}>
                      트랙 삭제
                    </MenuList>
                  </SubMenu>
                )
              }
            </div>
          )
        }

        {/* <button onClick={() => navigateDeleteTrack(track, album)}>삭제</button> */}
      </Flex>
    </Flex>
  )
}

export const TrackItem = inject(
  'authStore',
)(_TrackItem)
