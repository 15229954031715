import React from 'react'
import { FindAccountTemplate } from '@components/templates'
import { inject, observer } from 'mobx-react'
import { AuthStore } from '../stores'

interface Props {
  authStore: AuthStore;
}

const FindAccountPage = ({ authStore }: Props) => {
  const findAccount = authStore.findAccount || (() => {})
  return <FindAccountTemplate findAccount={findAccount} />
}

export default inject('authStore')(observer(FindAccountPage))
