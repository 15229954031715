import React, { useEffect } from 'react'
import {
  RootTemplate,
  ArtistTemplate,
  NoAuthEmailTemplate,
} from '@components/templates'

import { inject, observer } from 'mobx-react'
import qs from 'qs'
import { AuthStore, StatsStore, ConnectionsStore } from '../stores'
// import { defaultKakaoConsulting } from '@utils/kakao'

interface ArtistPageProps {
  artistStore: ArtistStore;
  authStore: AuthStore;
  statsStore: StatsStore;
  connectionsStore: ConnectionsStore;
  match: Any;
  location: Any;
  logout: Function;
  user: Object;
}

const ArtistPage = ({
  location,
  authStore,
  artistStore,
  statsStore,
  albumStore,
  connectionsStore,
  logout,
  user,
}: ArtistPageProps) => {
  const artist = artistStore.artistDetail

  const createArtist = artistStore.createArtist || (() => { })
  const updateArtist = artistStore.updateArtist || (() => { })
  const crawlArtist = artistStore.crawlArtist || (() => { })
  const fetchArtistDetail = artistStore.fetchArtistDetail || (() => { })

  const createStageName = artistStore.createStageName || (() => { })
  const updateStageName = artistStore.updateStageName || (() => { })
  const removeStageName = artistStore.removeStageName || (() => { })

  const checkSubdomain = statsStore.checkSubdomain || (() => { })
  const accountSubdomain = statsStore.accountSubdomain || (() => { })
  const modifySubdomain = statsStore.modifySubdomain || (() => { })

  const emailAuth = authStore.emailAuth || (() => { })

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  useEffect(() => {
    if (query.artistId && query.artistId !== 'null') {
      artistStore.fetchArtistDetail(query.artistId)
      if (
        user &&
        user['type'] !== 'company' &&
        user['artistId'] === query.artistId
      ) {
        connectionsStore.fetchArtistConnectionList(query.artistId)
      }
    } else {
      artistStore.fetchArtistDetail(user && user['artistId'])
      connectionsStore.fetchArtistConnectionList(user && user['artistId'])
    }
  }, [user, artistStore, connectionsStore, albumStore, query.artistId])

  // defaultKakaoConsulting.useKakaotalkConsulting()

  return (
    <div style={{ paddingBottom: 200 }}>
      {user && user.isAuthEmail ? (
        <RootTemplate user={user} artist={artist} logout={logout}>
          <ArtistTemplate
            isOpenUpdate={query?.isOpenUpdate === '1'}
            isMyView={
              query.artistId
                ? user &&
                user['type'] !== 'company' &&
                user['artistId'] === query.artistId
                : true
            }
            user={user}
            artist={artist}
            onCreateArtist={createArtist}
            onUpdateArtist={updateArtist}
            crawlArtist={crawlArtist}
            checkSubdomain={checkSubdomain}
            accountSubdomain={accountSubdomain}
            modifySubdomain={modifySubdomain}
            createStageName={createStageName}
            updateStageName={updateStageName}
            removeStageName={removeStageName}
            fetchArtistDetail={fetchArtistDetail}
            artistCompanyConnectionList={
              connectionsStore.artistCompanyConnectionList || []
            }
            artistConnectionList={connectionsStore.artistConnectionList || []}
            newArtistConnectionList={
              connectionsStore.newArtistConnectionList || []
            }
            newCompanyConnectionList={
              connectionsStore.newCompanyConnectionList || []
            }
            updateAccountConnection={
              connectionsStore.updateAccountConnection || (() => { })
            }
            removeConnection={connectionsStore.removeConnection || (() => { })}
            createConnectionsArtists={
              connectionsStore.createConnectionsArtists || (() => { })
            }
          />
        </RootTemplate>
      ) : (
        <NoAuthEmailTemplate user={user} emailAuth={emailAuth} />
      )}
      {/* <defaultKakaoConsulting.KakaotalkConsulting /> */}
    </div>
  )
}

export default inject(
  'authStore',
  'artistStore',
  'statsStore',
  'albumStore',
  'connectionsStore',
)(observer(ArtistPage))
