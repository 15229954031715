import React from 'react'
import { FindPasswordTemplate } from '@components/templates'
import { inject, observer } from 'mobx-react'
import { AuthStore } from '../stores'

interface Props {
  authStore: AuthStore;
}

const FindPasswordPage = ({ authStore }: Props) => {
  const findPassword = authStore.findPassword || (() => {})
  return <FindPasswordTemplate findPassword={findPassword} />
}

export default inject('authStore')(observer(FindPasswordPage))
