import React, { useState, useEffect } from 'react'
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts'
import { Flex, Text } from '@components/atoms'
import styled from 'styled-components'

const NoData = styled(Flex)`
  width: 360px;
  height: 360px;
  justify-content: center;
  align-items: center;
`

const SectionTitle = styled(Text)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  margin-bottom: 20px;
  align-self: center;
`

const color_media = [
  { name: 'Youtube', color: '#FC0211' },
  { name: 'Facebook', color: '#1877F2' },
  { name: 'Instagram', color: '#FF9742' },
  { name: 'Bugs', color: '#FDB624' },
  { name: 'Melon', color: '#18D63B' },
  { name: 'Vibe', color: '#761CF5' },
  { name: 'Flo', color: '#3B3BFD' },
  { name: 'YoutubeMusic', color: '#FC0211' },
  { name: 'AmazonMusic', color: '#2E1C7F' },
  { name: 'AppleMusic', color: '#FF2D55' },
  { name: 'Spotify', color: '#242424' },
  { name: 'Genie', color: '#20B6E6' },
  { name: 'Etc', color: '#D4D4D4' },
  { name: 'YoutubePlayer', color: '#EA4653' },
]

// const renderActiveShape = (props) => {
//   const RADIAN = Math.PI / 180
//   const { cx, cy, midAngle, outerRadius, fill, percent } = props
//   const sin = Math.sin(-RADIAN * midAngle)
//   const cos = Math.cos(-RADIAN * midAngle)
//   const sx = cx + (outerRadius + 5) * cos
//   const sy = cy + (outerRadius + 5) * sin
//   const mx = cx + (outerRadius + 15) * cos
//   const my = cy + (outerRadius + 15) * sin
//   const ex = mx + (cos >= 0 ? 1 : -1) * 11
//   const ey = my
//   const textAnchor = cos >= 0 ? 'start' : 'end'

//   return (
//     <g>
//       <path
//         d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
//         stroke={fill}
//         fill="none"
//       />
//       <text
//         x={ex + (cos >= 0 ? 1 : -1) * 6}
//         y={ey}
//         dy={9}
//         textAnchor={textAnchor}
//         fill="#999"
//       >
//         {`${(percent * 100).toFixed(2)}%`}
//       </text>
//     </g>
//   )
// }

const CustomLegend = ({ entry }) => {
  return (
    <li
      className="recharts-legend-item legend-item-3"
      style={{ display: 'block' }}
    >
      <svg
        className="recharts-surface"
        width="14"
        height="14"
        viewBox="0 0 32 32"
        version="1.1"
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginRight: '4px',
        }}
      >
        <path
          stroke="none"
          fill={entry.color}
          d="M0,4h32v24h-32z"
          className="recharts-legend-icon"
        ></path>
      </svg>
      <span className="recharts-legend-item-text">{entry.value}</span>
      <span className="recharts-legend-item-text">
        &nbsp;:&nbsp;{Math.floor(entry.payload.percent * 100)}%
      </span>
    </li>
  )
}

export const TotalInflowStatsChart = ({ TotalInflowStatsList, sumInflow }) => {
  const [inflowData, setInflowData] = useState([])
  const [conversionData, setConversionData] = useState([])

  useEffect(() => {
    let test1 = []
    TotalInflowStatsList.map(item =>
      test1.push({ name: item.fromSite, value: item.totalFromCount }),
    )
    setInflowData(test1)

    if (sumInflow.sumClickCount !== 0) {
      setConversionData([
        { name: 'Melon', value: sumInflow.sumMelonUrlClickCount },
        { name: 'YoutubeMusic', value: sumInflow.sumYoutubeMusicUrlClickCount },
        { name: 'AmazonMusic', value: sumInflow.sumAmazonMusicUrlClickCount },
        { name: 'AppleMusic', value: sumInflow.sumAppleMusicUrlClickCount },
        { name: 'Bugs', value: sumInflow.sumBugsUrlClickCount },
        { name: 'Flo', value: sumInflow.sumFloUrlClickCount },
        { name: 'Etc', value: sumInflow.sumEtcUrlClickCount },
        { name: 'Spotify', value: sumInflow.sumSpotifyUrlClickCount },
        { name: 'Vibe', value: sumInflow.sumVibeUrlClickCount },
        { name: 'Genie', value: sumInflow.sumGenieUrlClickCount },
        { name: 'YoutubePlayer', value: sumInflow.sumYoutubePlayClickCount },
      ])
    } else {
      setConversionData([])
    }
  }, [TotalInflowStatsList, sumInflow])

  return (
    <Flex>
      <Flex type="column" style={{ margin: '0 30px 0 50px' }}>
        <SectionTitle>유입 매체</SectionTitle>
        {inflowData.length === 0 ? (
          <NoData>No data</NoData>
        ) : (
          <PieChart width={380} height={400}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={inflowData}
              cx={'90'}
              cy={'180'}
              outerRadius={'90'}
              fill="#8884d8"
              labelLine={false}
              // label={renderActiveShape}
            >
              {inflowData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  // fill={COLORS[index % COLORS.length]}
                  fill={
                    color_media.filter(function(object) {
                      return object['name'] === entry.name
                    })[0].color
                  }
                />
              ))}
            </Pie>
            <Tooltip
              formatter={function(value, name) {
                return `${value} (${Math.floor(
                  (value / sumInflow.sumFromCount) * 100,
                )}%)`
              }}
            />
            <Legend
              layout="vertical"
              verticalAlign="middle"
              align="right"
              content={({ payload }) => {
                return (
                  <ul>
                    {payload
                      .sort((a, b) => b.payload.value - a.payload.value)
                      .map((entry, index) => (
                        <CustomLegend key={`item-${index}`} entry={entry} />
                      ))}
                  </ul>
                )
              }}
            />
          </PieChart>
        )}
      </Flex>
      <Flex type="column">
        <SectionTitle>전환 매체</SectionTitle>
        {conversionData.length === 0 ? (
          <NoData>No data</NoData>
        ) : (
          <PieChart width={400} height={400}>
            <Pie
              dataKey="value"
              data={conversionData}
              cx={'90'}
              cy={'180'}
              outerRadius={'90'}
              fill="#8884d8"
              labelLine={false}
              // label={renderActiveShape}
            >
              {conversionData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    color_media.filter(function(object) {
                      return object['name'] === entry.name
                    })[0].color
                  }
                />
              ))}
            </Pie>
            <Tooltip
              formatter={function(value, name) {
                return `${value} (${Math.floor(
                  (value / sumInflow.sumClickCount) * 100,
                )}%)`
              }}
            />

            <Legend
              layout="vertical"
              verticalAlign="middle"
              align="right"
              content={({ payload }) => {
                return (
                  <ul>
                    {payload
                      .sort((a, b) => b.payload.value - a.payload.value)
                      .map((entry, index) => (
                        <CustomLegend key={`item-${index}`} entry={entry} />
                      ))}
                  </ul>
                )
              }}
            />
          </PieChart>
        )}
      </Flex>
    </Flex>
  )
}
