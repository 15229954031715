import React, { useState, useEffect } from 'react'
import { Flex, Input, Caption1, H6, H4, H3 } from '@components/atoms'
import { LogoImg, FormGroup, MoveBtn, NoHeaderBox } from '@components/molecules'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from '@colors/'
import { mobile } from '@styles/media'

const Form = styled.form`
  width: 328px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FindPasswordTemplate = ({ findPassword }) => {
  const { register, setValue, handleSubmit } = useForm()
  const [isAccount, setIsAccount] = useState(null)
  const [isEmail, setIsEmail] = useState(null)
  const [accountStep, setAccountStep] = useState(1)

  useEffect(() => {
    setValue([{ account: '' }, { email: '' }])
    // eslint-disable-next-line
  }, [accountStep])

  const onSubmit = async data => {
    if (accountStep === 1) {
      const params = {
        stage: accountStep,
        account: data.account,
      }
      const res = await findPassword(params)
      if (res && !res.data.isSuccess) {
        setIsAccount('noExist')
      } else {
        setIsAccount(data.account)
        setAccountStep(2)
      }
    } else if (accountStep === 2) {
      const params = {
        stage: accountStep,
        account: isAccount,
        email: data.email,
      }
      const res = await findPassword(params)
      if (res && !res.data.isSend) {
        setIsEmail('noExist')
      } else {
        setIsEmail(data.email)
        setAccountStep(3)
      }
    }
  }

  return (
    <Box>
      <LogoImg isLarge />
      <H3 style={{ margin: '24px' }}>임시 비밀번호 전송</H3>
      <Flex style={{ marginTop: '20px' }}>
        <H6 color={accountStep === 1 ? colors.main : '#646464'}>아이디 입력</H6>
        <H6 color="#646464">&nbsp;>&nbsp;</H6>
        <H6 color={accountStep === 2 ? colors.main : '#646464'}>본인 확인</H6>
        <H6 color="#646464">&nbsp;>&nbsp;</H6>
        <H6 color={accountStep === 3 ? colors.main : '#646464'}>
          비밀번호 발급
        </H6>
      </Flex>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {accountStep === 1 ? (
          <FormGroup style={{ margin: '50px 0', width: '100%' }}>
            <Input
              style={{ width: '100%' }}
              placeholder="비밀번호를 찾고자 하는 아이디를 입력해주세요"
              name="account"
              ref={register({ required: true })}
            />
            {isAccount === 'noExist' && (
              <Caption1 style={{ textAlign: 'left' }} color="#ea4653">
                가입된 아이디가 아닙니다.
              </Caption1>
            )}
          </FormGroup>
        ) : accountStep === 2 ? (
          <FormGroup style={{ margin: '50px 0', width: '100%' }}>
            <Input
              style={{ width: '100%' }}
              placeholder="등록된 이메일 입력해주세요"
              name="email"
              ref={register({ required: true })}
            />
            {isEmail === 'noExist' && (
              <Caption1 style={{ textAlign: 'left' }} color="#ea4653">
                등록된 이메일이 아닙니다.
              </Caption1>
            )}
          </FormGroup>
        ) : (
          <FormGroup>
            <Flex
              style={{
                margin: '100px 0 20px 0',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <H4 color={colors.main}>{isAccount}</H4>
              <H4>님</H4>
            </Flex>
            <H6 color="#949494">
              임시 비밀번호가 발송되었습니다.
              <br />
              24시간 이내에 새로운 비밀번호로 변경해 주세요
            </H6>
            <MoveBtn
              text="로그인 하러가기"
              href="/login"
              style={{ margin: '80px 0 20px 0' }}
            />
            <MoveBtn
              text="이메일 재전송"
              onClick={async () => {
                await findPassword({
                  stage: 2,
                  account: isAccount,
                  email: isEmail,
                })
              }}
            />
          </FormGroup>
        )}
        {accountStep !== 3 && (
          <>
            <Flex style={{ marginBottom: '40px' }} type={'column'}>
              <H6 color="#646464">아이디를 분실하셨다면&nbsp;</H6>
              <Flex>
                <Link to="/find/account">
                  <H6 color={colors.main} type="Bold">
                    아이디 찾기
                  </H6>
                </Link>
                <H6 color="#646464">&nbsp;를 이용해주세요.</H6>
              </Flex>
            </Flex>
            <SubmitBtn type="submit">다음</SubmitBtn>
          </>
        )}
      </Form>
    </Box>
  )
}

const Box = styled(NoHeaderBox)`
  position: relative;

  @media ${mobile} {
    height: 100dvh;
    justify-content: flex-start;
  }
`

const SubmitBtn = styled.button`
  width: 320px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: none;
  background-color: #f4f4f4;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #242424;

  @media ${mobile} {
    margin-top: 0px;

    position: absolute;
    bottom: 20px;
    left: calc(50% - 160px);
  }
`
