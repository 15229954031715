/* eslint-disable import/no-unresolved */

import { Flex } from '@components/atoms'
import { Select } from 'antd'
import React from 'react'
import { STORAGE_URL } from '@consts'
import styled from 'styled-components'

import { companyDefaultLogoImg } from '@images/index'
import { PAGE_TYPE } from '../consts'

const CompanySelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border-radius: 25px;
  border: solid 1px #eaeaea;
  background-color: #ffffff;
  min-width: 200px;
  position: relative;
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
`

const DeletedCompanyNotice = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ea4653;
  background-color: #f4f4f4;
  font-size: 12px;
  font-family: NotoSansCJKKR-Regular;
  margin-left: 20px;
  padding: 0 12px;
`

const LogoWithCompanyName = ({ logoUrl, companyName }) => {
  const displayLogoUrl = logoUrl || companyDefaultLogoImg

  return (
    <ItemContainer>
      <Logo src={displayLogoUrl} alt="Company Logo" />
      {companyName}
    </ItemContainer>
  )
}

const getIsDeletedComment = _pageType => {
  if (_pageType === PAGE_TYPE.CONTRACT_PAGE) {
    return '계약이 종료된 소속사 입니다.'
  }

  if (_pageType === PAGE_TYPE.LIST_PAGE) {
    return '계약이 종료된 소속사 입니다. (지난 정산서 내역 확인은 가능합니다.)'
  }
}

export const CompanySelector = ({
  isDeleted = false,
  isLender = false,
  onChange = () => {},
  selectedCompanyId,
  companyList = [],
  pageType,
}) => {
  const sortedCompanyList = [...companyList].sort((a, b) => {
    // 날짜 형식이 'YYYY-MM-DD' 등 ISO 8601이라고 가정
    return new Date(b.updatedAt) - new Date(a.updatedAt)
  })

  if (!isLender) return <div />

  return (
    <Flex>
      <CompanySelectContainer>
        <Select
          onChange={
            value => {
              const selected = companyList.find(company => company._id === value)
              onChange(selected?._id)
            }
          }
          value={selectedCompanyId}
          bordered={false}
          style={{ minWidth: '200px' }}
        >
          {
            sortedCompanyList.map(company => (
              <Select.Option key={company._id} value={company._id}>
                <LogoWithCompanyName
                  logoUrl={
                    company.userInfo.profileImage128Path
                  && `${STORAGE_URL}${company.userInfo.profileImage128Path}`
                  }
                  companyName={company.name}
                />
              </Select.Option>
            ))
          }
        </Select>
      </CompanySelectContainer>
      {
        isDeleted && (
          <DeletedCompanyNotice>
            {getIsDeletedComment(pageType)}
          </DeletedCompanyNotice>
        )
      }
    </Flex>
  )
}
