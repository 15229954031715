import { observable } from 'mobx'

export default class TrackLikeInfoModel {
  /* Database fields */
  @observable _id

  @observable artistId
  @observable title
  @observable currentLikeCount
  @observable currentLikeCountDetail
  @observable createdAt
  @observable updatedAt

  @observable filteringDataList

  @observable imgaeOriginalPath
  @observable image64Path
  @observable image128Path
  @observable image512Path

  @observable trackId
  /* Database fields end */

  // periodType 비교한 좋아요 증가량
  @observable increaseValue

  @observable melonUrl
  @observable genieUrl
  @observable bugsUrl

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.artistId = props.artistId
      this.title = props.title
      this.currentLikeCount = props.currentLikeCount
      this.currentLikeCountDetail = props.currentLikeCountDetail
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      this.filteringDataList = props.filteringDataList

      this.imgaeOriginalPath = props.imgaeOriginalPath
      this.image64Path = props.image64Path
      this.image128Path = props.image128Path
      this.image512Path = props.image512Path

      this.trackId = props.trackId

      this.increaseValue = props.increaseValue

      this.melonUrl = props.melonUrl
      this.genieUrl = props.genieUrl
      this.bugsUrl = props.bugsUrl
    }
  }
}
