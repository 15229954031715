import styled from 'styled-components'

export const Button = styled.button.attrs((props) => ({
  type: props.type || 'button',
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-appearance: none;
  border: none;
  width: fit-content;
  background: none;
  font-size: 16px;
  padding: 0;
  margin: 0;
`
