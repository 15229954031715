import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import { NoAuthEmailTemplate, RootTemplate } from '@components/templates'
import { Div } from '@components/atoms'
// import { defaultKakaoConsulting } from '@utils/kakao'
import CompanyInsightSettlementTemplate from '@components/templates/CompanyInsightSettlementTemplate'
import moment from 'moment'

const CompanyInsightSettlementPage = () => {
  const location = useLocation()
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const [currentMonth, setCurrentMonth] = useState()
  const [filterDistributor, setFilterDistributor] = useState(null)

  const { authStore, settlementStore } = useStore()

  const fetchSettlementCompanyMonthly =
    settlementStore?.fetchSettlementCompanyMonthly || (() => {})
  const logout = authStore?.logout || (() => {})

  const { currentUser } = authStore
  const { companyMonthlyData } = settlementStore

  const handleMonth = value => {
    const updateMonth = moment(value)
    const displayMonth = updateMonth?.format('YYMM')

    setCurrentMonth(updateMonth)
    window.location.href = `/company/insight/settlement?date=${displayMonth}`
  }

  const handleFilterDistributor = value => {
    setFilterDistributor(value)
  }

  const handleMoveArtist = async artistId => {
    await authStore.artistViewMode(artistId)
  }

  useEffect(() => {
    if (currentUser && currentMonth) {
      fetchSettlementCompanyMonthly(currentUser?.companyId, {
        __month: currentMonth.format('YYMM'),
        __distributor: filterDistributor ?? null,
      })
    }
  }, [
    currentUser,
    fetchSettlementCompanyMonthly,
    currentMonth,
    filterDistributor,
  ])

  useEffect(() => {
    if (query?.date && !currentMonth) {
      setCurrentMonth(
        moment().set({
          year: `20${Math.floor(query.date / 100)}`,
          month: (query.date % 100) - 1,
        }),
      )
    }
  }, [query, currentMonth])

  // defaultKakaoConsulting.useKakaotalkConsulting()

  return (
    <div style={{ paddingBottom: 200 }}>
      {
currentUser?.isAuthEmail ? (
  <RootTemplate user={currentUser} logout={logout}>
    <Div
      display="flex"
      width="100%"
      flexDirection="column"
      paddingRight="50px"
    >
      <CompanyInsightSettlementTemplate
        companyMonthlyData={companyMonthlyData}
        currentMonth={currentMonth}
        handleMonth={handleMonth}
        filterDistributor={filterDistributor}
        onFilterDistributor={handleFilterDistributor}
        handleMoveArtist={handleMoveArtist}
      />
    </Div>
  </RootTemplate>
) : (
  <NoAuthEmailTemplate
    user={currentUser}
    emailAuth={authStore.emailAuth}
  />
)
      }
      {/* <defaultKakaoConsulting.KakaotalkConsulting /> */}
    </div>
  )
}

export default observer(CompanyInsightSettlementPage)
