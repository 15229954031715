import { observable } from 'mobx'

export default class CompanyModel {
  @observable _id
  @observable name
  @observable companyUserAccount
  @observable companyUserId

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.name = props.name
      this.companyUserAccount = props.companyUserAccount
      this.companyUserId = props.companyUserId
    }
  }
}
