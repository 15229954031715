import React from 'react'
import { colors } from '@colors/'
import { Flex, Input, Button } from '@components/atoms'
import styled from 'styled-components'

const SearchInput = styled(Input)`
  width: 294px;
  margin-right: 10px;
  border: solid 1px ${colors.brown_grey};
`

const SearchBtn = styled(Button)`
  width: 96px;
  height: 40px;
  background-color: ${colors.brownish_grey};
  border-radius: 6px;
  color: white;
`

const SearchAlbumBox = ({ keyword, setKeyword, onSearch }) => {
  const handleSearch = () => {
    onSearch(keyword)
  }

  return (
    <Flex>
      <SearchInput
        placeholder="검색어를 입력해주세요"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        onKeyDown={(e) => e.keyCode === 13 && handleSearch()}
      />
      <SearchBtn onClick={() => handleSearch()}>검색</SearchBtn>
    </Flex>
  )
}

export default SearchAlbumBox
