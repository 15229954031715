import React, { useMemo } from 'react'
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts'
import { Caption2, Flex } from '@components/atoms'
import styled from 'styled-components'
import { convertToPricingComma } from '@utils/format'

const NoData = styled(Flex)`
  width: 1054px;
  height: 500px;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  border: 1px solid #dddddd;
  border-radius: 6px;
  margin: 20px 0;
`

const ArtistRevenueCopyRightStatsChart = ({ data, expectedData }) => {
  const parseExpectedData = useMemo(() => {
    let parseData = []

    parseData = expectedData?.map(item => ({
      ...item,
      monthlyAdjacencyFee: item.adjacencyFee,
      monthlyCopyRightFee: item.copyRightFee,
      monthlyPerformanceRightFee: item.performanceRightFee,
      salesMonth: item.month,
    }))

    return parseData
  }, [expectedData])

  console.log('data', data)

  return (
    <Flex>
      {data?.length === 0 ? (
        <NoData>No data</NoData>
      ) : (
        <ResponsiveContainer width="80%" height={500}>
          <ComposedChart
            // width={1054}
            // height={500}
            margin={{
              top: 50,
              right: 0,
              bottom: 50,
              left: 50,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="salesMonth"
              tickFormatter={tick => {
                const strTick = `${tick}`
                return `${strTick[2]}${strTick[3]}월`
              }}
              allowDuplicatedCategory={false}
            />
            <YAxis
              domain={['auto', 'auto']}
              unit="원"
              tickFormatter={e => convertToPricingComma(e)}
            />
            <Tooltip content={CustomTooltip} />
            {/* <Tooltip /> */}
            <Legend verticalAlign="top" layout="horizontal" align="center" />
            <Line
              data={data}
              stroke="black"
              name="전체 저작권료"
              dataKey="monthlyCopyRightFee"
              legendType="none"
            />
            <Line
              data={parseExpectedData}
              stroke="black"
              name="전체 저작권료"
              dataKey="monthlyCopyRightFee"
              legendType="none"
              strokeDasharray="5 5"
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </Flex>
  )
}

export default ArtistRevenueCopyRightStatsChart

const ToolTipBox = styled(Flex)`
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;

  p {margin: 5px 0;}
  p:first-child {margin 0;}
`

const CustomTooltip = ({ active, payload, label }) => {
  const item = payload && payload[0] && payload[0].payload && payload[0].payload

  const strTick = `${label}`
  const customLabel = `${strTick[0]}${strTick[1]}년 ${strTick[2]}${strTick[3]}월`

  if (active) {
    if (item?.month) {
      const _uniquedData = {}
      return (
        <ToolTipBox type="column">
          <Caption2 type="Bold" align="left">
            {`${customLabel} 예상 수익`}
          </Caption2>
          {payload?.map(data => {
            if (_uniquedData[data.name]) {
              return ''
            }
            _uniquedData[data.name] = true
            return (
              <Caption2 align="left" color={data.stroke} key={data.name}>
                {`${data.name} : ${data &&
                  convertToPricingComma(
                    Math.floor(data?.payload?.monthlyCopyRightFee),
                  )}원`}
              </Caption2>
            )
          })}
          <Caption2 align="left" color="#949494">
            *과거 정산 자료를 등록하시면
            <br />더 정확한 예상 수익을 측정할 수 있습니다.
          </Caption2>
        </ToolTipBox>
      )
    } else {
      const _uniquedData = {}
      return (
        <ToolTipBox type="column">
          <Caption2 type="Bold" align="left">{`${customLabel}`}</Caption2>
          {payload?.map(data => {
            if (_uniquedData[data.name]) {
              return ''
            }
            _uniquedData[data.name] = true
            return (
              <Caption2 align="left" color={data.stroke} key={data.name}>
                {`${data.name} : ${data &&
                  convertToPricingComma(
                    Math.floor(data?.payload?.monthlyCopyRightFee),
                  )}원`}
              </Caption2>
            )
          })}
        </ToolTipBox>
      )
    }
  }
  return null
}
