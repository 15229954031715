import React, { useState } from 'react'
import { H3, Input, Caption1, H6 } from '@components/atoms'
import { LogoImg, FormGroup, NoHeaderBox } from '@components/molecules'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { useHistory } from 'react-router-dom'
import { isEmptyObject } from '@utils/helper'

const Form = styled.form`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 32px;
  gap: 12px;
`

const Button = styled.button`
  cursor: pointer;
  width: 328px;
  height: 48px;
  border-radius: 2px;
  background-color: #f4f4f4;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;

  margin-top: 122px;
  @media ${mobile} {
    margin-top: initial;
    position: fixed;
    bottom: 20px;
  }
`

export const ChangePasswordTemplate = ({ currentUser, changePassword }) => {
  const history = useHistory()

  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onChange',
  })
  const [errorType, setErrorType] = useState(null)

  const {
    password: watchedPassword,
    newPassword: watchedNewPassword,
    confirmNewPassword: watchedConfirmNewPassword,
  } = watch()

  const isDisabled =
    !isEmptyObject(errors) ||
    watchedNewPassword !== watchedConfirmNewPassword ||
    !watchedPassword ||
    !watchedConfirmNewPassword ||
    !watchedNewPassword

  const onSubmit = async data => {
    const params = {
      _id: currentUser?._id,
      password: data.password,
      newPassword: data.newPassword,
    }
    const res = await changePassword(params)
    if (res && !res.data.isModify) {
      if (res?.message === 'The current passwords do not match.') {
        setErrorType('unmatch')
      } else {
        setErrorType('nouser')
      }
    } else {
      setErrorType('')
      history.push('/')
    }
  }

  return (
    <NoHeaderBox>
      <LogoImg />
      <H3>
        새 비밀번호
        <br />
        설정
      </H3>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup style={{ width: '100%' }}>
          <Input
            type="password"
            style={{ width: '100%' }}
            placeholder="현재 비밀번호"
            name="password"
            ref={register({ required: true, minLength: 8 })}
          />
          {errorType === 'nouser' && (
            <Caption1 style={{ textAlign: 'left' }} color="#ea4653">
              가입된 아이디가 아닙니다.
            </Caption1>
          )}
          {errorType === 'unmatch' && (
            <Caption1 style={{ textAlign: 'left' }} color="#ea4653">
              현재 비밀번호가 일치하지 않습니다.
            </Caption1>
          )}
          {errors.password && errors.password.type === 'minLength' && (
            <Caption1 style={{ textAlign: 'left' }} color="#ea4653">
              비밀번호 8자 이상 입력해주세요.
            </Caption1>
          )}
        </FormGroup>
        <FormGroup style={{ width: '100%' }}>
          <Input
            type="password"
            style={{ width: '100%' }}
            placeholder="새 비밀번호"
            name="newPassword"
            ref={register({ required: true, minLength: 8 })}
          />
          {errors.newPassword && errors.newPassword.type === 'minLength' && (
            <Caption1 style={{ textAlign: 'left' }} color="#ea4653">
              비밀번호 8자 이상 입력해주세요.
            </Caption1>
          )}
        </FormGroup>
        <FormGroup style={{ width: '100%' }}>
          <Input
            type="password"
            style={{ width: '100%' }}
            placeholder="새 비밀번호 확인"
            name="confirmNewPassword"
            ref={register({
              required: true,
              minLength: 8,
            })}
          />
          {watchedNewPassword !== watchedConfirmNewPassword && (
            <Caption1 style={{ textAlign: 'left' }} color="#ea4653">
              비밀번호가 일치하지 않습니다.
            </Caption1>
          )}
          {errors.confirmNewPassword &&
            errors.confirmNewPassword.type === 'minLength' && (
              <Caption1 style={{ textAlign: 'left' }} color="#ea4653">
                비밀번호 8자 이상 입력해주세요.
              </Caption1>
            )}
        </FormGroup>
        <FormGroup style={{ width: '100%' }}>
          <Button type="submit" disabled={isDisabled}>
            <H6 color={isDisabled ? '#bfbfbf' : 'black'}>비밀번호 재설정</H6>
          </Button>
        </FormGroup>
      </Form>
    </NoHeaderBox>
  )
}
