import React from 'react'
import { RegisterCompleteTemplate } from '@components/templates'

import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import { AuthStore } from '../stores'

interface RegisterCompletePageProps {
  user: Object;
  authStore: AuthStore;
}

const RegisterCompletePage = ({
  user,
  authStore,
}: RegisterCompletePageProps) => {
  const emailAuth = authStore.emailAuth || (() => {})

  return (
    <>
      {user.isAuthEmail && <Redirect to="/" />}
      <RegisterCompleteTemplate
        user={user}
        emailAuth={emailAuth}
        logout={authStore.logout}
      />
    </>
  )
}

export default inject('authStore')(observer(RegisterCompletePage))
