import { Network } from '.'

export default class SettlementNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getSettlementArtistAnnual(artistObjectId, { __year }) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/settlement/artist/${artistObjectId}/annual`,
        'get',
        {
          __year,
        },
      )
      return data || []
    }
    catch (error) {
      console.warn('SettlementNetwork getSettlementArtistAnnual error', error)
    }
  }

  async getSettlementCompanyArtistAnnual(
    companyObjectId,
    artistObjectId,
    { __year },
  ) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/settlement/company/${companyObjectId}/artist/${artistObjectId}/annual`,
        'get',
        {
          __year,
        },
      )
      return data || []
    }
    catch (error) {
      console.warn(
        'SettlementNetwork getSettlementCompanyArtistAnnual error',
        error,
      )
    }
  }

  async getSettlementCompanyAnnual(companyObjectId, { __year }) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/settlement/company/${companyObjectId}/annual`,
        'get',
        {
          __year,
        },
      )
      return data || []
    }
    catch (error) {
      console.warn('SettlementNetwork getSettlementCompanyAnnual error', error)
    }
  }

  async getSettlementArtistMonthly(
    artistObjectId,
    { __month, __distributor, __companyId },
  ) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/settlement/artist/${artistObjectId}/monthly`,
        'get',
        {
          __month,
          __distributor,
          __companyId,
        },
      )
      return data || []
    }
    catch (error) {
      console.warn('SettlementNetwork getSettlementArtistMonthly error', error)
    }
  }

  async getSettlementCompanyArtistMonthly(
    companyObjectId,
    artistObjectId,
    { __month, __distributor },
  ) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/settlement/company/${companyObjectId}/artist/${artistObjectId}/monthly`,
        'get',
        {
          __month,
          __distributor,
        },
      )
      return data || []
    }
    catch (error) {
      console.warn(
        'SettlementNetwork getSettlementCompanyArtistMonthly error',
        error,
      )
    }
  }

  async getSettlementCompanyMonthly(
    companyObjectId,
    { __month, __distributor },
  ) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/settlement/company/${companyObjectId}/monthly`,
        'get',
        {
          __month,
          __distributor,
        },
      )
      return data || []
    }
    catch (error) {
      console.warn('SettlementNetwork getSettlementCompanyMonthly error', error)
    }
  }

  async getSettlementCompanyTrackAnnual(
    companyObjectId,
    trackObejctId,
    { __year },
  ) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/settlement/company/${companyObjectId}/track/${trackObejctId}/annual`,
        'get',
        {
          __year,
        },
      )
      return data || []
    }
    catch (error) {
      console.warn(
        'SettlementNetwork getSettlementCompanyTrackAnnual error',
        error,
      )
    }
  }

  async getSettlementCompanyArtistTrackAnnual(
    companyObjectId,
    artistObjectId,
    trackObejctId,
    { __year },
  ) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/settlement/company/${companyObjectId}/artist/${artistObjectId}/track/${trackObejctId}/annual`,
        'get',
        {
          __year,
        },
      )
      return data || []
    }
    catch (error) {
      console.warn(
        'SettlementNetwork getSettlementCompanyArtistTrackAnnual error',
        error,
      )
    }
  }

  async getSettlementCompanyAlbumAnnual(
    companyObjectId,
    albumObejctId,
    { __year, __trackMonth },
  ) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/settlement/company/${companyObjectId}/album/${albumObejctId}/annual`,
        'get',
        {
          __year,
          __trackMonth,
        },
      )
      return data || []
    }
    catch (error) {
      console.warn(
        'SettlementNetwork getSettlementCompanyAlbumAnnual error',
        error,
      )
    }
  }

  async getSettlementCompanyArtistAlbumAnnual(
    companyObjectId,
    artistObjectId,
    albumObejctId,
    { __year, __trackMonth },
  ) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/settlement/company/${companyObjectId}/artist/${artistObjectId}/album/${albumObejctId}/annual`,
        'get',
        {
          __year,
          __trackMonth,
        },
      )
      return data || []
    }
    catch (error) {
      console.warn(
        'SettlementNetwork getSettlementCompanyArtistAlbumAnnual error',
        error,
      )
    }
  }

  async getSettlementArtistTrackAnnual(
    artistObjectId,
    trackObejctId,
    { __year },
  ) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/settlement/artist/${artistObjectId}/track/${trackObejctId}/annual`,
        'get',
        {
          __year,
        },
      )
      return data || []
    }
    catch (error) {
      console.warn(
        'SettlementNetwork getSettlementArtistTrackAnnual error',
        error,
      )
    }
  }

  async getSettlementArtistAlbumAnnual(
    artistObjectId,
    albumObejctId,
    { __year, __trackMonth },
  ) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/settlement/artist/${artistObjectId}/album/${albumObejctId}/annual`,
        'get',
        {
          __year,
          __trackMonth,
        },
      )
      return data || []
    }
    catch (error) {
      console.warn(
        'SettlementNetwork getSettlementArtistAlbumAnnual error',
        error,
      )
    }
  }
}
