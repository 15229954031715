import { observable } from 'mobx'

export default class TrackLikeInfoListModel {
  /* Database fields */
  @observable _id
  @observable pageId
  @observable artistId
  @observable currentLikeCountDetail

  // 현재 좋아요 갯수 상세
  @observable trackId
  @observable title
  @observable imgaeOriginalPath
  @observable image64Path
  @observable image128Path
  @observable image512Path

  @observable releasedAt
  @observable createdAt

  /* Database fields end */

  // periodType 비교한 좋아요 증가량
  @observable increaseValue

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.pageId = props.pageId
      this.artistId = props.artistId
      this.currentLikeCountDetail = props.currentLikeCountDetail

      // 현재 좋아요 갯수 상세
      this.trackId = props.trackId
      this.title = props.title
      this.imgaeOriginalPath = props.imgaeOriginalPath
      this.image64Path = props.image64Path
      this.image128Path = props.image128Path
      this.image512Path = props.image512Path

      // periodType 비교한 좋아요 증가량
      this.increaseValue = props.increaseValue

      this.releasedAt = props.releasedAt
      this.createdAt = props.createdAt
    }
  }
}
