import { observable } from 'mobx'

export default class AdjustmentDetailModel {
  @observable _id
  @observable companyId
  @observable artistId
  @observable adjustmentId
  @observable adjustmentContractId

  @observable sortIndex
  @observable type
  @observable date
  @observable description
  @observable tag
  @observable amount
  @observable amountAbs
  @observable fileList
  @observable isConfirmed
  @observable isCarryOver // 이월된 내역이면 true, 아니면 없음
  @observable carryOverAdjustmentId // 이월된 이전정산서의 adjustmentId
  @observable settlementInfo

  @observable isSaved
  @observable savedAt

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.companyId = props.companyId
      this.artistId = props.artistId
      this.adjustmentId = props.adjustmentId
      this.adjustmentContractId = props.adjustmentContractId

      this.sortIndex = props.sortIndex
      this.type = props.type
      this.date = props.date
      this.description = props.description
      this.tag = props.tag
      this.amount = props.amount
      this.amountAbs = props.amount && Math.abs(props.amount)
      this.fileList = props.fileList
      this.isConfirmed = props.isConfirmed
      this.isCarryOver = props.isCarryOver
      this.carryOverAdjustmentId = props.carryOverAdjustmentId
      this.settlementInfo = props.settlementInfo || null

      this.isSaved = props.isSaved
      this.savedAt = props.savedAt

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt
    }
  }
}
