import React from 'react'
import styled from 'styled-components'
import { Button, Image, Text } from '@components/atoms'
import { colors } from '@colors/'

const Btn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: #ffffff;
`

const BtnImg = styled(Image)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`

export const RoundBtn = ({ img, text, onClick, style, textColor }) => {
  return (
    <Btn onClick={onClick} style={style && style}>
      {img && <BtnImg src={img} alt={`${img}_btn`} />}
      <Text
        type="Medium"
        size="14px"
        color={textColor ? textColor : colors.main}
      >
        {text}
      </Text>
    </Btn>
  )
}
