import { Network } from '.'

export default class TrackNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getTrack(trackId) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/track/${trackId}`,
        'get',
      )
      const track = data && data['data'] && data['data']['track']
      return track || null
    } catch (error) {
      console.warn('TrackNetwork getTrack error', error)
    }
  }

  async postTrack(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/track',
        'post',
        params,
      )
      const track = data && data['data'] && data['data']['track']
      return track || null
    } catch (error) {
      console.warn('TrackNetwork postTrack error', error)
    }
  }

  async putTrack(params) {
    try {
      const { data } = await this.network._axiosApiAuth('/track', 'put', params)
      const track = data && data['data'] && data['data']['track']
      return track || null
    } catch (error) {
      console.warn('TrackNetwork putTrack error', error)
    }
  }

  async deleteTrack(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/track',
        'delete',
        params,
      )
      const track = data && data['data'] && data['data']['track']
      return track || null
    } catch (error) {
      console.warn('TrackNetwork deleteTrack error', error)
    }
  }
}
