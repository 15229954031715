import { observable } from 'mobx'
import TrackModel from './TrackModel'

export default class ArtistTrendTrackModel extends TrackModel {
  /* Database fields */

  @observable albumInfo
  @observable likeCountInfo
  @observable listenerCountInfo
  @observable rankInfo

  /* Database fields end */

  constructor(stores, props) {
    super(stores, props)

    this.albumInfo = props?.albumInfo
    this.likeCountInfo = props?.likeCountInfo
    this.listenerCountInfo = props?.listenerCountInfo
    this.rankInfo = props?.rankInfo
  }
}
