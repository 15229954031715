import React from 'react'
import { AuthEmailTemplate } from '@components/templates'

const AuthEmailPage = ({ match }) => {
  return (
    <AuthEmailTemplate
      type={match.params.type}
      userId={match.params.id && match.params.id}
    />
  )
}

export default AuthEmailPage
