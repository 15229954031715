import React from 'react'
import styled from 'styled-components'

const StreamingLogo = styled.img.attrs(props => ({
  src: require(`@images/streaming/${props.type}_img.png`),
  style: {
    ...props.style,
  },
  alt: props.type,
}))`
  width: 48px;
  height: 48px;
`

const StreamingButton = ({ type = 'etc' }) => {
  const jp_img = require(`@images/streaming/jp_img.png`)
  const tw_img = require(`@images/streaming/tw_img.png`)
  const back_img = require('@images/streaming/streaming_shadow.png')

  return (
    <div
      style={{
        position: 'relative',
        width: '60px',
        height: '60px',
      }}
    >
      <img
        style={{
          width: '60px',
          height: '60px',
          position: 'absolute',
          top: '0',
          left: '0',
        }}
        src={back_img}
        alt="back"
      />
      <StreamingLogo
        type={type}
        style={{
          position: 'absolute',
          top: '6px',
          left: '6px',
        }}
      />
      {(type === 'linetwUrl' || type === 'linejpUrl') && (
        <img
          src={type === 'linetwUrl' ? tw_img : jp_img}
          alt={type === 'linetwUrl' ? 'tw' : 'jp'}
          style={{
            width: 20,
            height: 20,
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
        />
      )}
    </div>
  )
}

export { StreamingLogo, StreamingButton }
