import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Flex,
  Text,
  H4,
  Container,
  Button,
  Image,
  Caption2,
  H6,
} from '@components/atoms'
import {
  ArtistRevenuePerformanceStatsChart,
  RevenuePerformanceTrackTableChart,
  RevenueUploadModal,
} from '@components/organisms'
import { colors } from '@colors/'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import moment from 'moment'
import 'moment/locale/ko'
import CountUp from 'react-countup'
import { inject, observer } from 'mobx-react'
import { path_menu, add_btn } from '@images/'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 0 0 0;
`

const SectionTitle = styled(Text)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  margin-bottom: 20px;
`

const InfoCard = styled(Flex)`
  width: 234px;
  height: 167px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #fcfcfc;
  padding: 5px;
  margin: 0 50px 0 0;
  justify-content: center;
`

const InsightRevenuePerformanceTemplate = ({
  authStore,
  artistRevenueData,
  artistRevenueExpectedList,
  currentYear,
  handleYear,
}) => {
  const [showUpload, setShowUpload] = useState(false)

  const artistRevenueInfo =
    artistRevenueData?.revenueInfo?.artistRevenueInfo[0] || null

  const valuationInfo = artistRevenueData?.valuationInfo || null

  const displayYear = currentYear?.format('YYYY년')

  const isLoginedMaster = authStore.isAdminPassword

  return (
    <Container>
      <Header>
        <Text type="Medium" size="18px" style={{ marginTop: '10px' }}>
          나의 실연권료
        </Text>
        {
          isLoginedMaster && (
            <HeaderBtn onClick={() => setShowUpload(true)}>
              <HeaderBtnImg src={add_btn} alt="add_btn" />
              <Text type="Medium" size="14px" color={colors.main}>
              업로드
              </Text>
            </HeaderBtn>
          )
        }
      </Header>
      <Content>
        <Flex>
          <Flex type="column">
            <SectionTitle>
              <Image
                src={path_menu}
                alt="prev"
                width="18px"
                height="18px"
                transform="rotate(180deg)"
                cursor="pointer"
                onClick={() => handleYear(currentYear.subtract(1, 'years'))}
              />
              {displayYear}
              <Image
                src={path_menu}
                alt="next"
                width="18px"
                height="18px"
                opacity={moment().isSameOrBefore(currentYear, 'year') ? 0.5 : 1}
                cursor={
                  moment().isSameOrBefore(currentYear, 'year')
                    ? 'not-allowed'
                    : 'pointer'
                }
                onClick={
                  () => {
                    if (moment().isSameOrBefore(currentYear, 'year')) return
                    handleYear(currentYear.add(1, 'years'))
                  }
                }
              />
            </SectionTitle>
            <Flex>
              <InfoCard type="column">
                <H4>누적 실연권료</H4>
                <H4
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '20px' }}
                >
                  {
artistRevenueInfo?.totalPerformanceRightFee ? (
  <>
    <CountUp
      start={0}
      end={
        Math.floor(
          artistRevenueInfo.totalPerformanceRightFee,
        )
      }
      separator=","
      duration={3}
    />
                      원
  </>
) : (
  '-'
)
                  }
                </H4>
              </InfoCard>
              <InfoCard type="column">
                <Caption2 color={colors.brown_grey}>
                  {`(${displayYear || 0})`}
                </Caption2>
                <H4>연간 실연권료</H4>
                <H4
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '20px' }}
                >
                  {
artistRevenueInfo?.annualPerformanceRightFee ? (
  <>
    <CountUp
      start={0}
      end={
        Math.floor(
          artistRevenueInfo.annualPerformanceRightFee,
        )
      }
      separator=","
      duration={3}
    />
                      원
  </>
) : (
  '-'
)
                  }
                </H4>
              </InfoCard>
              <InfoCard type="column">
                <H4>보유 실연권</H4>
                <H4
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '20px' }}
                >
                  {
artistRevenueInfo?.performanceRightTrackCount ? (
  <>
    <CountUp
      start={0}
      end={
        Math.floor(
          artistRevenueInfo.performanceRightTrackCount,
        )
      }
      separator=","
      duration={3}
    />
                      건
  </>
) : (
  '-'
)
                  }
                </H4>
              </InfoCard>
              <InfoCard type="column">
                <Caption2 color={colors.brown_grey}>
                  {`(${currentYear?.format('YYYY년 MM월 기준') || 0})`}
                </Caption2>
                <H6>보유 실연재산권 미래 예상수익</H6>
                {
valuationInfo?.dayWeightedPerformanceRightValue ? (
  <H4
    type="Medium"
    color={colors.main}
    style={{ marginTop: '20px' }}
  >
    <CountUp
      start={0}
      end={
        Math.floor(
                        valuationInfo?.dayWeightedPerformanceRightValue,
        )
      }
      separator=","
      duration={3}
    />
                    원
  </H4>
) : (
  <H4
    type="Medium"
    color={colors.main}
    style={{ marginTop: '20px' }}
  >
                    -
  </H4>
)
                }
              </InfoCard>
            </Flex>
          </Flex>
        </Flex>
        <ArtistRevenuePerformanceStatsChart
          data={artistRevenueData?.revenueInfo?.artistMonthlyRevenueInfo || []}
          expectedData={artistRevenueExpectedList?.expectedRevenueList || []}
        />

        <Flex type="column" style={{ marginTop: '50px' }}>
          <SectionTitle>세부 내역</SectionTitle>
          <RevenuePerformanceTrackTableChart />
        </Flex>
      </Content>
      <RevenueUploadModal
        show={showUpload}
        handleClose={() => setShowUpload(false)}
      />
    </Container>
  )
}

export default inject('authStore')(observer(InsightRevenuePerformanceTemplate))

const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: #ffffff;
`

const HeaderBtnImg = styled(Image)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`
