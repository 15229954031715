import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  Flex,
  Text,
  TextArea,
  Image,
  Caption1,
  H3,
  H5,
} from '@components/atoms'
import {
  FacebookLogo,
  InstagramLogo,
  YoutubeLogo,
  SoundcloudLogo,
  HomePageLogo,
  EmailLogo,
  EtcSocialLogo,
  XLogo,
  TiktokLogo,
  StageNameBox,
  WeverseLogo,
} from '@components/molecules'
import { StreamingButton } from '@components/molecules/icon/StramingButton'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { ConfirmPopup } from '..'
import ArtistSmartLinkCard from '../Card/ArtistSmartLinkCard'

const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
  margin: 0px 50px 0px 0px;
`
const StreamingContainer = styled(Flex)`
  flex-direction: column;
  flex: 1 1 40%;
`
const SocialMediaContainer = styled(Flex)`
  flex-direction: column;
  flex: 1 1 40%;
`

const Header = styled(Flex)`
  /* width: '100%'; */
  padding: 30px 0px 0px 0px;
  /* border-bottom: solid 0.5px #242424; */
`
const UserImage = styled(Image).attrs(props => ({
  src: props.src,
}))`
  width: 400px;
  height: 400px;
  border-radius: 5px;
`
const HeaderContent = styled(Flex)`
  flex: 1;
  flex-direction: column;
  margin: 0px 0px 0px 50px;
`
const NameBox = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 4px 0px 10px 0px;
  border-bottom: solid 0.5px #242424;
`
const DescriptionTitle = styled(Text)`
  padding: 30px 0px 0px 0px;
  font-size: 18px;
`
const Description = styled(TextArea)`
  margin: 16px 0px 12px 0px;
  width: 912px;
  height: 240px;
  font-size: 14px;
  color: #646464;
  overflow: hidden;
  border: none;
`

const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 30px 0px 0px 0px;
`

const StageNameContent = styled(Flex)`
  flex-direction: column;
  padding: 0px;
  margin-bottom: 30px;
  border-bottom: solid 0.5px #242424;
`

const StreamingContent = styled(Flex)`
  flex-direction: row;
  padding: 0px;
  flex-wrap: wrap;
`
const SocialMediaContent = styled(Flex)`
  flex-direction: row;
  flex-wrap: wrap;
  // margin: 0px 0px 0px 138px;
`

const ContentTitle = styled(Text)`
  font-family: 'NotoSansCJKkr-Medium';
  padding: 0px 0px 15px 0px;
  font-size: 18px;
`
const ContentItem = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  padding: 15px 5px 15px 0px;
  align-items: center;
  overflow-wrap: break-word;
  cursor: pointer;
`
const MedaiContentItem = styled(ContentItem)`
  padding-right: 12px;
`

const ArtistList = styled(Flex)`
  width: auto;
  jusitfy-content: flex-start;
  flex-wrap: wrap;

  > div {
    margin-bottom: 6px;
    margin-right: 20px;
  }

  > div:nth-child(3n) {
    margin-right: 0px;
  }
`

const SocialMediaLogo = ({ type }) => {
  switch (type) {
    case 'facebook':
      return <FacebookLogo />
    case 'instagram':
      return <InstagramLogo />
    case 'youtube':
      return <YoutubeLogo />
    case 'soundcloud':
      return <SoundcloudLogo />
    case 'homepage':
      return <HomePageLogo />
    case 'email':
      return <EmailLogo />
    case 'twitter':
      return <XLogo />
    case 'etcSocial':
      return <EtcSocialLogo />
    case 'tiktok':
      return <TiktokLogo />
    case 'weverse':
      return <WeverseLogo />
    default:
      return <div />
  }
}

const ArtistInfo = ({
  artist,
  createStageName,
  updateStageName,
  removeStageName,
  onUpdateArtist,
}) => {
  const { artistStore } = useStore()

  const { artistSmartLinkList } = artistStore
  const fetchSmartLinkList = artistStore?.fetchSmartLinkList || (() => { })

  const { isShowing, toggle } = ConfirmPopup.usePopup()

  const StreamingItem = ({ type }) => {
    return (
      <ContentItem onClick={() => window.open(artist[`${type}`])}>
        <StreamingButton type={type} />
      </ContentItem>
    )
  }

  const SocialMediaItem = ({ type }) => {
    return (
      <MedaiContentItem onClick={() => window.open(artist[`${type}Url`])}>
        <SocialMediaLogo type={type} />
      </MedaiContentItem>
    )
  }

  const handleUpdateType = async () => {
    if (artist.type === '개인') {
      await onUpdateArtist({
        _id: artist._id,
        type: '그룹',
      })
    } else if (artist.type === '그룹') {
      const res = await onUpdateArtist({
        _id: artist._id,
        type: '개인',
      })
      if (res === 'conflict') toggle()
    }
  }

  useEffect(() => {
    fetchSmartLinkList()
  }, [fetchSmartLinkList])

  const { imageUri, masterName, nameEng, description, type } = artist || {}
  return (
    <Container>
      <Header>
        <UserImage src={imageUri} />
        <HeaderContent>
          <NameBox>
            <Flex type={'column'}>
              <H3
                type="Bold"
                size="28px"
                align="left"
                style={{ width: 439, overflow: 'hidden' }}
              >
                {masterName}
              </H3>
              <H5
                type="Medium"
                align="left"
                style={{ padding: '10px 2px', width: 439, overflow: 'hidden' }}
              >
                {nameEng}
              </H5>
            </Flex>
            {artist && artist.isOwner && (
              <Caption1
                color="#1982df"
                style={{ cursor: 'pointer', paddingBottom: '20px' }}
                onClick={handleUpdateType}
              >
                {`${type === '개인' ? '그룹' : '개인'}회원 전환`}
              </Caption1>
            )}
          </NameBox>
          <Description
            readOnly
            value={description ? description : '등록된 설명이 없습니다.'}
            style={{ overflowY: 'scroll' }}
          />
        </HeaderContent>
      </Header>
      <Content>
        <StageNameContent>
          <ContentTitle>활동명</ContentTitle>
          <ArtistList style={{ marginBottom: '20px' }}>
            <Flex style={{ width: '440px', position: 'relative' }}>
              <StageNameBox
                createStageName={createStageName}
                updateStageName={updateStageName}
                removeStageName={removeStageName}
                isOwner={artist && artist.isOwner}
              />
            </Flex>
            {artist &&
              artist.stageNameList &&
              artist.stageNameList.map(item => (
                <Flex
                  style={{ width: '440px', position: 'relative' }}
                  key={item._id}
                >
                  <StageNameBox
                    item={item}
                    createStageName={createStageName}
                    updateStageName={updateStageName}
                    removeStageName={removeStageName}
                    isOwner={artist && artist.isOwner}
                  />
                </Flex>
              ))}
          </ArtistList>

          <ContentTitle>스마트 링크</ContentTitle>
          <ArtistList style={{ marginBottom: '20px' }}>
            <Flex style={{ width: '440px', position: 'relative' }}>
              <ArtistSmartLinkCard />
            </Flex>
            {artistSmartLinkList?.map(item => (
              <Flex
                style={{ width: '440px', position: 'relative' }}
                key={item._id}
              >
                <ArtistSmartLinkCard
                  item={item}
                  totalLength={artistSmartLinkList.length}
                />
              </Flex>
            ))}
          </ArtistList>
        </StageNameContent>

        <Flex>
          <StreamingContainer>
            <ContentTitle>음악 서비스</ContentTitle>
            <StreamingContent>
              {artist.spotifyUrl && <StreamingItem type="spotifyUrl" />}
              {artist.youtubeMusicUrl && (
                <StreamingItem type="youtubeMusicUrl" />
              )}
              {artist.melonUrl && <StreamingItem type="melonUrl" />}
              {artist.genieUrl && <StreamingItem type="genieUrl" />}
              {artist.bugsUrl && <StreamingItem type="bugsUrl" />}
              {artist.vibeUrl && <StreamingItem type="vibeUrl" />}
              {artist.floUrl && <StreamingItem type="floUrl" />}
              {artist.appleMusicUrl && <StreamingItem type="appleMusicUrl" />}
              {artist.amazonMusicUrl && <StreamingItem type="amazonMusicUrl" />}
              {artist.tidalUrl && <StreamingItem type="tidalUrl" />}
              {artist.deezerUrl && <StreamingItem type="deezerUrl" />}
              {artist.jooxUrl && <StreamingItem type="jooxUrl" />}
              {artist.mymusicUrl && <StreamingItem type="mymusicUrl" />}
              {artist.kkboxUrl && <StreamingItem type="kkboxUrl" />}
              {artist.linejpUrl && <StreamingItem type="linejpUrl" />}
              {artist.linetwUrl && <StreamingItem type="linetwUrl" />}
              {artist.yandexUrl && <StreamingItem type="yandexUrl" />}
              {artist.nctUrl && <StreamingItem type="nctUrl" />}
              {artist.zingUrl && <StreamingItem type="zingUrl" />}
              {artist.anghmiUrl && <StreamingItem type="anghmiUrl" />}
            </StreamingContent>
          </StreamingContainer>
          <SocialMediaContainer>
            <ContentTitle>소셜미디어</ContentTitle>
            <SocialMediaContent>
              {artist.homepageUrl && <SocialMediaItem type="homepage" />}
              {artist.instagramUrl && <SocialMediaItem type="instagram" />}
              {artist.twitterUrl && <SocialMediaItem type="twitter" />}
              {artist.weverseUrl && <SocialMediaItem type="weverse" />}
              {artist.youtubeUrl && <SocialMediaItem type="youtube" />}
              {artist.soundcloudUrl && <SocialMediaItem type="soundcloud" />}
              {artist.facebookUrl && <SocialMediaItem type="facebook" />}
              {artist.tiktokUrl && <SocialMediaItem type="tiktok" />}
              {artist.emailUrl && <SocialMediaItem type="email" />}
              {artist.etcSocialUrl && <SocialMediaItem type="etcSocial" />}
            </SocialMediaContent>
          </SocialMediaContainer>
        </Flex>
      </Content>
      <ConfirmPopup.View
        isShowing={isShowing}
        toggle={toggle}
        title="전환 실패"
        body="연결된 아티스트가 존재합니다."
      />
    </Container>
  )
}

export default observer(ArtistInfo)
