import React, { useState } from 'react'
import styled from 'styled-components'
import { H4, Flex, Input, Caption1 } from '@components/atoms'
import { CustomModal } from '@components/molecules'
import { colors } from '@colors/'

const ModalBody = styled(Flex)`
  width: 580px;
  height: 300px;
  padding: 30px;
  flex-direction: column;
  align-items: center;
`

const Btn = styled.div`
  display: flex;
  font-family: NotoSansCJKkr-Medium;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 48px;
  border-radius: 25px;
  cursor: pointer;
  background-color: ${props => (props.disabled ? '#f4f4f4' : colors.main)};
  color: ${props => (props.disabled ? '#949494' : '#f4f4f4')};
  border: ${props => (props.disabled ? 'none' : colors.main)};
`

const View = ({ isShowing, toggle, pageType, handleCrawl, confirmToggle }) => {
  const [isVallidation, setIsVallidation] = useState(false)
  const [melonUrl, setMelonUrl] = useState('')

  const handleConfirm = async () => {
    if (!melonUrl) return
    const res = await handleCrawl(melonUrl)
    if (res && res.isRequired) {
      setIsVallidation('isRequired')
      return
    } if (res && res.isDuplicated) {
      setIsVallidation('isDuplicated')
      return
    }
    toggle()
    confirmToggle()
  }

  return (
    <>
      <CustomModal.View isShowing={isShowing} toggle={toggle}>
        <ModalBody type="column">
          <H4
            style={{ marginTop: 30 }}
          >
            {`멜론 ${pageType} 페이지 입력 링크`}
          </H4>
          <Flex type="column">
            <Input
              placeholder="링크 입력"
              style={{ margin: '40px 0 16px 0', width: '400px' }}
              value={melonUrl}
              onChange={e => setMelonUrl(e.target.value)}
            />
            {
              isVallidation === 'isRequired' ? (
                <Caption1
                  color="#ea4653"
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  {`페이지 링크가 유효하지 않습니다.`}
                </Caption1>
              ) : isVallidation === 'isDuplicated' ? (
                <Caption1
                  color="#ea4653"
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  {`이미 등록된 ${pageType}입니다.`}
                </Caption1>
              ) : (
                <Caption1
                  color="#949494"
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  {`* 멜론 ${pageType} 페이지 링크를 입력해주세요.`}
                </Caption1>
              )
            }
          </Flex>
          <Btn onClick={() => handleConfirm()} disabled={!melonUrl}>
            등록
          </Btn>
        </ModalBody>
      </CustomModal.View>
    </>
  )
}

export default {
  View,
  useModal: CustomModal.useModal,
}
