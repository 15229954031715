import { action, observable } from 'mobx'
import { Store } from '.'
import { ArtistCompanyConnectionModel, ArtistConnectionModel } from './models'
import { Network } from './networks'

export default class ConnectionsStore {
  @observable artistCompanyConnectionList = []
  @observable artistConnectionList = []
  @observable newArtistConnectionList = []
  @observable newCompanyConnectionList = []

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.artistCompanyConnectionList = []
    this.artistConnectionList = []
    this.newArtistConnectionList = []
    this.newCompanyConnectionList = []
  }

  @action.bound
  async initClient() {}

  fetchartistCompanyConnectionList = async companyUserId => {
    return this.store.useLoading(async () => {
      const res = await this.network.connectionsNetwork.getConnectionsCompanies(
        companyUserId,
      )
      if (!res) return

      this.artistCompanyConnectionList =
        res.artistCompanyConnectionList &&
        res.artistCompanyConnectionList.map(
          elem => new ArtistCompanyConnectionModel(this.store, elem),
        )
      // this.artistConnectionList = res.artistConnectionList

      return !!res
    })
  }

  fetchArtistConnectionList = async artistId => {
    return this.store.useLoading(async () => {
      const res = await this.network.connectionsNetwork.getConnectionsArtists(
        artistId,
      )
      if (!res) return

      this.artistCompanyConnectionList =
        res.artistCompanyConnectionList &&
        res.artistCompanyConnectionList.map(
          elem => new ArtistCompanyConnectionModel(this.store, elem),
        )
      this.artistConnectionList =
        res.artistConnectionList &&
        res.artistConnectionList.map(
          elem => new ArtistConnectionModel(this.store, elem),
        )
      this.newArtistConnectionList =
        res.newArtistConnectionList &&
        res.newArtistConnectionList.map(
          elem => new ArtistConnectionModel(this.store, elem),
        )
      this.newCompanyConnectionList =
        res.newCompanyConnectionList &&
        res.newCompanyConnectionList.map(
          elem => new ArtistCompanyConnectionModel(this.store, elem),
        )
      return !!res
    })
  }

  @action.bound
  createConnectionsCompanies = async (requesterRole, targetId, requesterId) => {
    return this.store.useLoading(async () => {
      const res = await this.network.connectionsNetwork.postConnectionsCompanies(
        {
          requesterRole,
          targetId,
          requesterId,
        },
      )

      if (!res) return

      this.artistCompanyConnectionList = this.artistCompanyConnectionList.concat(
        new ArtistCompanyConnectionModel(this.store, res),
      )

      return res
    })
  }

  @action.bound
  createConnectionsArtists = async (targetId, requesterId) => {
    return this.store.useLoading(async () => {
      const res = await this.network.connectionsNetwork.postConnectionsArtists({
        targetId,
        requesterId,
      })

      if (!res) return

      this.artistConnectionList = this.artistConnectionList.concat(
        new ArtistConnectionModel(this.store, res),
      )

      return res
    })
  }

  @action.bound
  removeConnection = async (type, id) => {
    if (!id || !type) return
    return this.store.useLoading(async () => {
      const res = await this.network.connectionsNetwork.deleteConnection(
        type,
        id,
      )

      if (!res) return

      if (type === 'artists')
        this.artistConnectionList = this.artistConnectionList.filter(
          elem => elem._id !== id,
        )
      if (type === 'companies')
        this.artistCompanyConnectionList = this.artistCompanyConnectionList.filter(
          elem => elem._id !== id,
        )
    })
  }

  @action.bound
  removeConnections = async (type, connectionsIdList) => {
    if (!connectionsIdList || !type) return
    return this.store.useLoading(async () => {
      const res = await this.network.connectionsNetwork.deleteConnections(
        type,
        connectionsIdList,
      )
      if (!res) return

      this.fetchartistCompanyConnectionList(
        this.store.authStore.currentUser._id,
      )
      return !!res
    })
  }

  @action.bound
  updateAccountConnection = (type, id, params) => {
    if (!id || !type) return
    return this.store.useLoading(async () => {
      const res = await this.network.connectionsNetwork.putConnection(
        type,
        id,
        params,
      )

      if (!res) return

      await this.fetchArtistConnectionList(
        this.store.authStore.currentUser['artistId'],
      )
    })
  }
}
