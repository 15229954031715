import React from 'react'
import { Table } from 'antd'
import { convertToPricingComma } from '@utils/format'

const RevenueTrackInflowTableChart = ({ data }) => {
  const sumHitCount = data.reduce((prev, curr) => prev + curr.annualHitCount, 0)
  const sumTotalRevenue = data.reduce(
    (prev, curr) => prev + curr.totalRevenue,
    0,
  )

  return (
    <Table
      className="inflow_table"
      columns={
        [
          { title: '유형', key: '유형', dataIndex: 'hitType', width: '137px' },
          {
            title: '활용수',
            key: '활용수',
            dataIndex: 'annualHitCount',
            width: '135px',
            sorter: (a, b) => (a._isDisabledSort || b._isDisabledSort) ? 0 : a.annualHitCount - b.annualHitCount,
            render: text => `${convertToPricingComma(Math.floor(text))}`,
          },
          {
            title: '수익',
            key: '수익',
            dataIndex: 'totalRevenue',
            width: '135px',
            sorter: (a, b) => (a._isDisabledSort || b._isDisabledSort) ? 0 : a.totalRevenue - b.totalRevenue,
            render: text => `${convertToPricingComma(Math.floor(text))}원`,
          },
          {
            title: '비율',
            key: '비율',
            dataIndex: 'monthlyAdjacencyFee',
            width: '135px',
            sorter: (a, b) => (a._isDisabledSort || b._isDisabledSort) ? 0 : a.totalRevenue - b.totalRevenue,
            render: (text, record) =>
              `${((record.totalRevenue / sumTotalRevenue) * 100)?.toFixed(1)  }%`,
          },
        ]
      }
      dataSource={
        [
          ...data,
          {
            hitType: '합계',
            annualHitCount: sumHitCount,
            totalRevenue: sumTotalRevenue,
            rate: Math.floor((sumTotalRevenue / sumTotalRevenue) * 100),
            _isDisabledSort: true,
          },
        ]
      }
      pagination={false}
      size={'small'}
      style={
        {
          width: '542px',
          height: 'fit-content',
          border: 'solid 1px #dddddd',
          borderRadius: '6px',
        }
      }
      rowKey="hitType"
    />
  )
}

export default RevenueTrackInflowTableChart
