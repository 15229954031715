import React from 'react'
import { Caption2, H3, H5, H6 } from '@components/atoms'
import styled from 'styled-components'
import { NoHeaderBox } from '@components/molecules'
import { Link } from 'react-router-dom'

import { colors } from '@colors/'
import { plaminLogo } from '@images/index'
import { mobile } from '@styles/media'

const EmailBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;

  width: 320px;
  height: 84px;
  border-radius: 2px;
  border: solid 0.5px #eaeaea;
  background-color: #fafafa;
`

export const RegisterCompleteTemplate = ({ user, emailAuth, logout }) => {
  return (
    <Box>
      <img src={plaminLogo} width={182.9} height={24.8} alt="logo" />
      <H3 style={{ margin: '28px 0 32px 0 ' }}>회원가입 완료</H3>
      <EmailBox>
        <Caption2 color="#646464">
          아래의 이메일로 인증메일이 전송되었습니다.
        </Caption2>
        <H5 color={colors.main}>
          {user && user.type === 'company' ? user.staffEmail : user.email}
        </H5>
      </EmailBox>
      <BottomBox>
        <ResendBtn onClick={() => emailAuth(user && user._id)}>
          인증메일 재전송
        </ResendBtn>
        <Link to="/register/update-email">
          <H6 color="#646464">다른 이메일로 인증받기</H6>
        </Link>
      </BottomBox>
    </Box>
  )
}

const Box = styled(NoHeaderBox)`
  position: relative;

  @media ${mobile} {
    height: 100dvh;
    justify-content: flex-start;
  }
`

const BottomBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 164px;

  @media ${mobile} {
    margin-top: 0px;

    position: absolute;
    bottom: 40px;
    left: calc(50% - 160px);
  }
`

const ResendBtn = styled.button`
  width: 320px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: none;
  background-color: #f4f4f4;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #242424;
`
