import React, { useEffect, useState } from 'react'
import { RootTemplate, ActivityLogsTemplate } from '@components/templates'
import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import qs from 'qs'

interface Props {
  artistStore: ArtistStore;
  authStore: AuthStore;
  statsStore: StatsStore;
  match: Any;
  location: Any;
  logout: Function;
  user: Object;
}

const ActivityLogsPage = ({
  statsStore,
  authStore,
  artistStore,
  location,
  logout,
  user,
}: Props) => {
  const [offset, setOffset] = useState(0)

  const artist = artistStore.artistDetail
  const fetchArtistModifyRecordList =
    artistStore.fetchArtistModifyRecordList || (() => {})

  const artistModifyRecordList = artistStore.artistModifyRecordList || null
  const artistModifyRecordListCount =
    artistStore.artistModifyRecordListCount || null

  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      // 문자열 맨 앞의 ?를 생력
    })
    if (query.artistId && query.artistId !== 'null') {
      artistStore.fetchArtistDetail(query.artistId)
      fetchArtistModifyRecordList(query.artistId, 0)
    } else {
      artistStore.fetchArtistDetail(user && user['artistId'])
      fetchArtistModifyRecordList(user && user['artistId'], 0)
    }
  }, [user, artistStore, fetchArtistModifyRecordList, location])

  useEffect(() => {
    if (offset > 0) {
      fetchArtistModifyRecordList(artistStore.artistDetail._id, offset)
    }
  }, [offset, artistStore, fetchArtistModifyRecordList])

  return (
    <>
      <RootTemplate user={user} artist={artist} logout={logout}>
        {!(user && user['artistId']) && <Redirect to="/" />}
        <ActivityLogsTemplate
          fetchArtistModifyRecordList={fetchArtistModifyRecordList}
          artistModifyRecordList={artistModifyRecordList}
          artistModifyRecordListCount={artistModifyRecordListCount}
          setOffset={setOffset}
          offset={offset}
        />
      </RootTemplate>
    </>
  )
}

export default inject(
  'authStore',
  'artistStore',
  'statsStore',
)(observer(ActivityLogsPage))
