import React, { useState, useEffect } from 'react'
import { Modal, Button, Spin } from 'antd'

const DeleteConfirmModal = ({ isVisible, onClose, onConfirm }) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const handleEnterKey = event => {
      if (event.key === 'Enter' && isVisible && !isLoading) {
        handleConfirm()
      }
    }

    if (isVisible) {
      document.addEventListener('keydown', handleEnterKey)
    }

    return () => {
      document.removeEventListener('keydown', handleEnterKey)
    }
  }, [isVisible, onConfirm, isLoading])

  const handleConfirm = async () => {
    setIsLoading(true)
    await onConfirm() // 비동기 작업을 여기서 기다림
    setIsLoading(false)
    onClose() // 작업 완료 후 모달 닫기
  }

  return (
    <Modal
      title="삭제 확인"
      visible={isVisible}
      onCancel={onClose}
      footer={
        [
          <Button key="cancel" onClick={onClose} disabled={isLoading}>
          취소
          </Button>,
          <Button key="confirm" type="danger" onClick={handleConfirm}>
          삭제
          </Button>,
        ]
      }
    >
      <Spin spinning={isLoading}>
        <p>정말 삭제하시겠습니까?</p>
      </Spin>
    </Modal>
  )
}

export default DeleteConfirmModal
