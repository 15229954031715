import { observable } from 'mobx'

export default class QuestionModel {
  @observable id

  @observable type
  @observable title
  @observable content
  @observable isCompleted
  @observable answerTitle
  @observable answerContent
  @observable answeredAt

  @observable createdAt
  @observable updatedAt

  @observable appIdentifierId

  isEdited = false

  constructor(stores, props) {
    if (props) {
      this.id = props.id

      this.type = props.type
      this.title = props.title
      this.content = props.content
      this.isCompleted = props.isCompleted
      this.answerTitle = props.answerTitle
      this.answerContent = props.answerContent
      this.answeredAt = props.answeredAt

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      this.appIdentifierId = props.appIdentifierId
    }
  }
}