import React, { useEffect, useState } from 'react'
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts'
import { CheckTextBox } from '@components/molecules'
import {
  Caption1,
  Caption2,
  Flex,
  H4,
  H5,
  Image,
  Text,
} from '@components/atoms'

import styled from 'styled-components'
import { convertToPricingComma } from '@utils/format'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { Table } from 'antd'
import moment from 'moment'
import { path_menu } from '@images/'
import CountUp from 'react-countup'
import { useHistory } from 'react-router-dom'
import CheckBoxModal from '../Modal/CheckBoxModal'

const NoData = styled(Flex)`
  width: 1054px;
  height: 500px;
  justify-content: center;
  align-items: center;
`

const COLORS = [
  '#FC0211',
  '#1877F2',
  '#FF9742',
  '#FDB624',
  '#18D63B',
  '#761CF5',
  '#3B3BFD',
  '#FC0211',
  '#2E1C7F',
  '#FF2D55',
  '#242424',
  '#20B6E6',
  '#D4D4D4',
  '#EA4653',
]

const CompanySettlementAnnualChart = () => {
  const [currentYear, setCurrentYear] = useState()

  const handleYear = value => {
    const _currentYear = moment(value)
    setCurrentYear(_currentYear)
    fetchSettlementCompanyAnnual(currentUser.companyId, {
      __year: _currentYear ? `${_currentYear?.format('YY')}00` : null,
    })
  }

  const history = useHistory()
  const { authStore, settlementStore, revenueStore } = useStore()

  const { currentUser } = authStore
  const { companyAnnualData } = settlementStore
  const { companyRecentMonth } = revenueStore

  const [filterDistributor, setFilterDistributor] = useState([])

  const settlementInfo = companyAnnualData?.settlementInfo

  const fetchSettlementCompanyAnnual =
    settlementStore?.fetchSettlementCompanyAnnual || (() => {})

  // 전체 월별 리스트
  const companyMonthlySettlementList =
    settlementInfo?.companyMonthlySettlementList || []

  // 회사별 월별 리스트
  const companyMonthlySettlementByDistributor =
    settlementInfo?.companyMonthlySettlementByDistributor || []

  // 기여 회사 리스트
  const distributorList =
    settlementInfo?.companyAnnualSettlement?.distributorList || []

  const displayYear = currentYear?.format('YYYY년')

  useEffect(() => {
    if (currentUser) {
      fetchSettlementCompanyAnnual(currentUser.companyId, {
        __year: null,
      }).then(_companyAnnualData => {
        setCurrentYear(
          moment().set({
            year: `20${Math.floor(
              _companyAnnualData.settlementInfo.companyAnnualSettlement.year
                / 100,
            )}`,
          }),
        )
      })
    }
  }, [currentUser, fetchSettlementCompanyAnnual])

  return (
    <Flex type="column">
      <SectionTitle>
        <Image
          src={path_menu}
          alt="prev"
          width="18px"
          height="18px"
          transform="rotate(180deg)"
          cursor="pointer"
          onClick={() => handleYear(currentYear.subtract(1, 'years'))}
        />
        {displayYear}
        <Image
          src={path_menu}
          alt="next"
          width="18px"
          height="18px"
          opacity={moment().isSameOrBefore(currentYear, 'year') ? 0.5 : 1}
          cursor={
            moment().isSameOrBefore(currentYear, 'year')
              ? 'not-allowed'
              : 'pointer'
          }
          onClick={
            () => {
              if (moment().isSameOrBefore(currentYear, 'year')) return
              handleYear(currentYear.add(1, 'years'))
            }
          }
        />
      </SectionTitle>
      <Flex style={{ margin: '20px 0 30px' }}>
        {
companyMonthlySettlementList[companyMonthlySettlementList.length - 1]
          ?.month ? (
    <Flex style={{ gap: '16px', width: '100%' }}>
      <InfoCard>
        <Flex style={{ marginBottom: '16px', alignItems: 'center' }}>
          <H5>
            {
              `20${companyMonthlySettlementList[
                companyMonthlySettlementList.length - 1
              ]?.month
                    ?.toString()
                    ?.slice(0, 2)}년 ${companyMonthlySettlementList[
                      companyMonthlySettlementList.length - 1
                    ]?.month
                    ?.toString()
                    ?.slice(2, 4)}월 정산금액은`
            }
          </H5>
          <H4 type="bold">
                  &nbsp;
            {
              (companyMonthlySettlementList[
                companyMonthlySettlementList.length - 1
              ]?.companyMonthlySettlementFee
                    || companyMonthlySettlementList[
                      companyMonthlySettlementList.length - 1
                    ]?.artistMonthlySettlementFee) && (
                <CountUp
                  start={0}
                  end={
                    Math.floor(
                          companyMonthlySettlementList[
                            companyMonthlySettlementList.length - 1
                          ]?.companyMonthlySettlementFee,
                    )
                        + Math.floor(
                          companyMonthlySettlementList[
                            companyMonthlySettlementList.length - 1
                          ]?.artistMonthlySettlementFee,
                        )
                  }
                  separator=","
                  duration={3}
                />
              )
            }
                  원 &nbsp;
          </H4>
        </Flex>
        {
          <H5
            style={{ fontSize: '18px' }}
            type={'Regular'}
            color={'#646464'}
          >
            {
              `(사업자 ${convertToPricingComma(
                Math.floor(
                      companyMonthlySettlementList[
                        companyMonthlySettlementList.length - 1
                      ]?.companyMonthlySettlementFee,
                ),
              )}원, 아티스트 ${convertToPricingComma(
                Math.floor(
                      companyMonthlySettlementList[
                        companyMonthlySettlementList.length - 1
                      ]?.artistMonthlySettlementFee,
                ),
              )}원)`
            }
                  &nbsp;
          </H5>
        }
      </InfoCard>
      <InfoCard>
        <Flex style={{ marginBottom: '16px', alignItems: 'center' }}>
          <H5>{`${displayYear} 연간 총 정산금액은`}</H5>
          <H4 type="bold">
                  &nbsp;
            {
settlementInfo?.companyAnnualSettlement
                    ?.companyAnnualSettlementFee && (
                <CountUp
                  start={0}
                  end={
                    Math.floor(
                          settlementInfo?.companyAnnualSettlement
                            ?.companyAnnualSettlementFee,
                    )
                        + Math.floor(
                          settlementInfo?.companyAnnualSettlement
                            ?.artistAnnualSettlementFee,
                        )
                  }
                  separator=","
                  duration={3}
                />
              )
            }
                  원 &nbsp;
          </H4>
        </Flex>
        {
          <H5
            style={{ fontSize: '18px' }}
            type={'Regular'}
            color={'#646464'}
          >
            {
              `(사업자 ${convertToPricingComma(
                Math.floor(
                      settlementInfo?.companyAnnualSettlement
                        ?.companyAnnualSettlementFee,
                ),
              )}원, 아티스트 ${convertToPricingComma(
                Math.floor(
                      settlementInfo?.companyAnnualSettlement
                        ?.artistAnnualSettlementFee,
                ),
              )}원)`
            }
                  &nbsp;
          </H5>
        }
      </InfoCard>
    </Flex>
  ) : (
    <InfoCard>
      <H5>{`${displayYear} 정산 데이터가 없습니다.`}</H5>
    </InfoCard>
  )
        }
      </Flex>

      <ChartWrapper type="column">
        <Flex style={{ zIndex: 2, margin: '4px 0' }}>
          <CheckBoxModal
            title="유통사"
            onReset={() => setFilterDistributor([])}
            content={
              <>
                <CheckTextBox
                  checked={filterDistributor.length === 0 ? true : false}
                  onClick={() => setFilterDistributor([])}
                  text="전체"
                />
                {
distributorList
                  ?.sort((a, b) => {
                    return a > b ? 1 : -1
                  })
                  ?.map(distributor => (
                    <CheckTextBox
                      key={distributor}
                      checked={
                        filterDistributor.includes(distributor) ? true : false
                      }
                      onClick={
                        () =>
                          setFilterDistributor(prev => {
                            if (prev.findIndex(e => e === distributor) > -1) {
                              return prev.filter(e => e !== distributor)
                            }

                            return prev.concat(distributor)
                          })
                      }
                      text={distributor}
                    />
                  ))
                }
              </>
            }
          />
        </Flex>

        {
companyMonthlySettlementList?.length === 0 ? (
  <NoData>No data</NoData>
) : (
  <ResponsiveContainer width="100%" height={438}>
    <ComposedChart
      // width={1054}
      // height={500}
      data={companyMonthlySettlementList}
      margin={
        {
          top: 20,
          right: 0,
          left: 50,
        }
      }
    >
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis
        dataKey="month"
        tickFormatter={
          tick => {
            const strTick = `${tick}`
            return `${strTick[2]}${strTick[3]}월`
          }
        }
        allowDuplicatedCategory={false}
        domain={companyMonthlySettlementList.map(elem => elem.month)}
      />
      <YAxis
        dataKey="companyMonthlySettlementFee"
        domain={[dataMin => dataMin * 0.95, 'auto']}
        unit="원"
        tickFormatter={e => convertToPricingComma(e)}
      />
      <Tooltip
        content={
          ({ active, payload, label }) => (
            <CustomTooltip
              active={active}
              payload={payload}
              label={label}
              distributorList={distributorList}
              filterDistributor={filterDistributor}
            />
          )
        }
      />
      {/* <Tooltip /> */}
      <Legend
        verticalAlign="top"
        layout="horizontal"
        align="right"
        content={
          ({ payload }) => {
            return (
              <Flex align="center" justify="flex-end" gap="20px">
                {
                  payload.map(item => (
                    <Flex align="center" gap="4px" key={item.id}>
                      <div
                        style={
                          {
                            width: 10,
                            height: 10,
                            border: `2px solid ${item.color}`,
                            borderRadius: '50%',
                          }
                        }
                      />
                      <Caption1>{item.value}</Caption1>
                    </Flex>
                  ))
                }
              </Flex>
            )
          }
        }
      />
      {
        filterDistributor.length === 0
          ? [
            <Line
              key="전체"
              stroke="black"
              name="전체"
              data={companyMonthlySettlementList}
              legendType="none"
              dataKey="companyMonthlySettlementFee"
            />,
            distributorList.map((distributor, index) => (
              <Line
                key={distributor}
                stroke={COLORS[index || 0]}
                name={distributor}
                data={
                  companyMonthlySettlementByDistributor[distributor]
                }
                legendType="none"
                dataKey="companyMonthlySettlementFee"
              />
            )),
          ]
          : filterDistributor.map((distributor, index) => (
            <Line
              key={distributor}
              stroke={COLORS[index || 0]}
              name={distributor}
              data={companyMonthlySettlementByDistributor[distributor]}
              legendType="none"
              dataKey="companyMonthlySettlementFee"
            />
          ))
      }
    </ComposedChart>
  </ResponsiveContainer>
)
        }
      </ChartWrapper>
      <H5 type="bold" align="left" style={{ margin: '50px 0 20px' }}>
        정산 세부내역
      </H5>
      <Flex>
        <TableBox>
          <Table
            pagination={false}
            rowKey="_id"
            dataSource={companyMonthlySettlementList || []}
            onRow={
              record => {
                return {
                  onClick: () => {
                    history.push(
                      `/company/insight/settlement?date=${record.month}`,
                    )
                  },
                }
              }
            }
            columns={
              [
                {
                  title: '월',
                  key: 'month',
                  dataIndex: 'month',
                  render: text => {
                    return `20${parseInt(text / 100)}년 ${text % 100}월`
                  },
                  width: '200px',
                  align: 'center',
                  sorter: (a, b) => a.month > b.month,
                  defaultSortOrder: 'descend',
                },
                {
                  title: '유통사',
                  key: 'distributorList',
                  dataIndex: 'distributorList',
                  render: list => {
                    return Array.isArray(list)
                      ? list?.sort((a, b) => (a > b ? 1 : -1))?.join(', ')
                      : list
                  },
                  align: 'center',
                },
                {
                  title: '사업자 정산 금액',
                  key: 'companyMonthlySettlementFee',
                  dataIndex: 'companyMonthlySettlementFee',
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  width: '400px',
                  align: 'center',
                },
                {
                  title: '아티스트 정산 금액',
                  key: 'artistMonthlySettlementFee',
                  dataIndex: 'artistMonthlySettlementFee',
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  width: '400px',
                  align: 'center',
                },
                {
                  title: '비고',
                  key: 'month',
                  dataIndex: 'month',
                  render: () => {
                    return '-'
                  },
                  width: '100px',
                  align: 'center',
                },
              ]
            }
          />
        </TableBox>
      </Flex>
    </Flex>
  )
}

export default observer(CompanySettlementAnnualChart)

const ToolTipBox = styled(Flex)`
  width:360px;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;

  p {margin: 3px 0;}
  p:first-child {margin 0;}
`

const SectionTitle = styled(Text)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  margin-bottom: 20px;
`

const InfoCard = styled(Flex)`
  flex: 1;
  flex-direction: column;
  /* width: 234px; */
  height: 167px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #fcfcfc;
  padding: 5px;
  justify-content: center;
  align-items: center;
`

const TableBox = styled.div`
  width: 100%;

  table {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #ddd;
    background-color: #fcfcfc;
  }

  tr {
    cursor: pointer;
  }

  thead > tr > th {
    background-color: transparent;
    color: #646464;
  }

  thead > tr > th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5),
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(8) {
    text-align: center;
    min-width: 88px;
  }
`

const ChartWrapper = styled(Flex)`
  width: 100%;
  height: 500px;
  padding: 12px 33px 8px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #fff;

  margin-bottom: 38px;
`

const CustomTooltip = ({ active, payload, label, distributorList }) => {
  const strTick = `${label}`
  const customLabel = `${strTick[0]}${strTick[1]}년 ${strTick[2]}${strTick[3]}월`

  if (active) {
    return (
      <ToolTipBox type="column">
        <Caption2 type="Bold" align="left">{`${customLabel}`}</Caption2>
        {/* <Caption1 align="left">{distributorList?.join()}</Caption1> */}
        <Caption2 align="left" color="#646464">
          선택한 유통사의 정산 내역(인접권료)
        </Caption2>
        <div
          style={
            {
              backgroundColor: '#f4f4f4',
              width: '100%',
              height: 1,
              margin: '12px 0 15px 0',
            }
          }
        />
        {
payload
          ?.sort((a, b) => {
            if (b.name === '전체') {
              return 1
            }
            else {
              return a.name > b.name ? 1 : -1
            }
          })
          ?.map(data => (
            <Flex key={data.name} align="center" justify="space-between">
              <Caption1 align="left">
                <strong
                  style={
                    {
                      color: data.stroke,
                    }
                  }
                >
                  •
                </strong>
                {`${data.name} : `}
              </Caption1>
              <Caption1>
                {
                  `${data
                  && convertToPricingComma(
                    Math.floor(data?.payload?.companyMonthlySettlementFee),
                  )}원`
                }
              </Caption1>
            </Flex>
          ))
        }
      </ToolTipBox>
    )
  }
  return null
}
