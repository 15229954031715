import { Network } from '.'

export default class AuthNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async postLogin(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/user/login',
        'post',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postLogin error', error)
    }
  }

  async postTokenLogin(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/user/token',
        'post',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postTokenLogin error', error)
    }
  }

  async postUser(params) {
    try {
      const { data } = await this.network._axiosApiAuth('/user', 'post', params)
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postUser error', error)
    }
  }

  async putUser(params) {
    try {
      const { data } = await this.network._axiosApiAuth('/user', 'put', params)
      return data || null
    } catch (error) {
      console.warn('AuthNetwork putUser error', error)
    }
  }

  async postEmailAuth(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/user/email/auth',
        'post',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postEmailAuth error', error)
    }
  }

  async putEmail(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/user/email',
        'put',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork putEmail error', error)
    }
  }

  async postAccount(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/user/account',
        'post',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postAccount error', error)
    }
  }

  async postPassword(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/user/password',
        'post',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postPassword error', error)
    }
  }

  async putPassword(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/user/password',
        'put',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('AuthNetwork postPassword error', error)
    }
  }
}
