import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Flex, Text, Input, Button } from '@components/atoms'
import {
  CheckTextBox,
  FormLabel,
  LogoImg,
  NoHeaderBox,
} from '@components/molecules'
import { useForm } from 'react-hook-form'
import DaumPostcode from 'react-daum-postcode'
import { ServiceAgreeModal, InfoAgreeModal } from '@components/organisms'
import { colors } from '@colors/'
import CertificateUploadForm from './CertificateUploadForm'

const Form = styled.form`
  max-width: 518px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  width: 100%;
`

const RegisterBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: ${colors.main};
  color: #ffffff;

  :hover {
    background-color: ${colors.sub_main};
    border-color: ${colors.sub_main};
    transition-property: background-color, border-color;
    transition-duration: 0.2s;
    transition-timing-function: linear;
  }
`

const SearchAddressBtn = styled(Flex)`
  width 104px;
  height:40px;
  margin-left:8px;
  cursor:pointer;
  justify-content:center;
  align-items:center;
  border-radius: 7px;
  border: solid 1px #949494;
`

export const RegisterCompanyForm = ({
  postUser,
  setIsRegister,
  login,
  emailAuth,
}) => {
  const [isDuplicateAccount, setIsDuplicateAccount] = useState(false)
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false)

  const [certificatePath, setCertificatePath] = useState(null)
  const [showSearchAddress, setShowSearchAddress] = useState(false)

  const [checkConfirm, setCheckConfirm] = useState(false)
  const [showAgree1, setShowAgree1] = useState(false)
  const [showAgree2, setShowAgree2] = useState(false)
  const [agree1, setAgree1] = useState(false)
  const [agree2, setAgree2] = useState(false)
  const [allAgree, setAllAgree] = useState(false)
  const { register, errors, handleSubmit, getValues, setValue } = useForm({
    mode: 'onBlur',
  })

  const idRef = useRef()

  useEffect(() => {
    if (idRef.current) {
      register(idRef.current)
      idRef.current.focus()
    }

    if (agree1 && agree2) {
      setAllAgree(false)
    }
  }, [allAgree, agree1, agree2, register])

  // 비밀번호 확인
  const confirmPw = value => {
    if (value === getValues().password) {
      setCheckConfirm(false)
    } else {
      setCheckConfirm(true)
    }
  }

  const handleAddressComplete = data => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }

    if (data && data.zonecode) setValue('zoneCode', data.zonecode)
    setValue('fullAddress', fullAddress)
    setShowSearchAddress(false)
  }

  const onSubmit = async data => {
    const {
      account,
      password,
      companyName,
      companyRegistrationNumber,
      zoneCode,
      fullAddress,
      fullAddressDetail,

      chiefName,
      chiefEmail,
      chiefPhone,

      staffName,
      staffEmail,
      staffPhone,
    } = data

    if (!(agree1 && agree2)) {
      setAllAgree(true)
      return false
    }
    if (!certificatePath) return
    if (checkConfirm) return

    const params = {
      type: 'company',
      account,
      password,
      companyName,
      address: {
        zoneCode,
        fullAddress,
        fullAddressDetail,
      },
      companyRegistrationNumber,
      certificatePath,

      chiefName,
      chiefEmail,
      chiefPhone,

      staffName,
      staffEmail,
      staffPhone,
    }

    const res = await postUser(params)

    if (res) {
      const { isDuplicateAccount, isRegister, isDuplicateEmail } = res.data
      setIsDuplicateAccount(isDuplicateAccount)
      setIsDuplicateEmail(isDuplicateEmail)
      setIsRegister(isRegister)

      if (res.data.user) {
        await login({ account, password })
        await emailAuth(res.data.user._id)
      }
    }
  }

  return (
    <NoHeaderBox>
      <LogoImg isBeta />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Text color="#242424" type="Bold" size="64px">
          회원가입
        </Text>

        <FormGroup>
          <Input
            style={{
              width: '100%',
              //  paddingLeft: '110px'
            }}
            placeholder="아이디"
            type="text"
            name="account"
            // ref={register({
            //   required: true,
            //   pattern: /^([a-z0-9_-]{5,20})?$/,
            // })}
            // ref={idRef}
            ref={e => {
              register(e, { required: true, pattern: /^([a-z0-9_-]{5,20})?$/ })
              idRef.current = e // you can still assign to your own ref
            }}
          />
          {errors.account && (
            <Text size="14px" type="Medium" color="#ea4653">
              5 ~ 20자의 영문 소문자, 숫자와 특수 기호(-), (_)만 사용
              가능합니다.
            </Text>
          )}
          {isDuplicateAccount && (
            <Text size="14px" type="Medium" color="#ea4653">
              중복된 아이디입니다.
            </Text>
          )}
        </FormGroup>
        <FormGroup>
          <Input
            style={{ width: '100%' }}
            type="password"
            placeholder="비밀번호 (8자 이상)"
            name="password"
            ref={register({ required: true, minLength: 8 })}
          />
          {errors.password && errors.password.type === 'minLength' && (
            <Text size="14px" type="Medium" color="#ea4653">
              비밀번호 8자 이상 입력해주세요.
            </Text>
          )}
        </FormGroup>
        <FormGroup>
          <Input
            style={{ width: '100%' }}
            type="password"
            placeholder="비밀번호 확인"
            name="confirm_password"
            onBlur={e => confirmPw(e.target.value)}
          />
          {checkConfirm && (
            <Text size="14px" type="Medium" color="#ea4653">
              비밀번호가 일치하지 않습니다.
            </Text>
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel>회사 정보 *</FormLabel>
          <Input
            type="text"
            placeholder="회사명"
            name="companyName"
            ref={register({
              required: true,
            })}
          />
          <Input
            style={{ marginTop: 20 }}
            type="text"
            placeholder="사업자등록번호 (-없이)"
            name="companyRegistrationNumber"
            ref={register({
              required: true,
            })}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>사업자등록증 *</FormLabel>
          <CertificateUploadForm
            fileUrl={certificatePath}
            setFileUrl={setCertificatePath}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>회사 주소 *</FormLabel>
          <Flex>
            <Input
              type="text"
              placeholder="우편번호"
              name="zoneCode"
              style={{ width: 410 }}
              ref={register({
                required: true,
              })}
              onClick={() => setShowSearchAddress(true)}
              disabled
            />
            <SearchAddressBtn onClick={() => setShowSearchAddress(true)}>
              주소 검색
            </SearchAddressBtn>
          </Flex>
          {showSearchAddress && (
            <DaumPostcode
              onComplete={handleAddressComplete}
              height={424}
              // autoClose
              // autoResize
              // animation
              style={{
                marginTop: 20,
                border: 'solid 1px #242424',
                borderRadius: 20,
                padding: 10,
                overflow: 'hidden',
              }}
            />
          )}
          <Input
            style={{ marginTop: 20 }}
            type="text"
            placeholder="도로명 주소"
            name="fullAddress"
            ref={register({
              required: true,
            })}
            onClick={() => setShowSearchAddress(true)}
            disabled
          />
          <Input
            style={{ marginTop: 20 }}
            type="text"
            placeholder="상세 주소"
            name="fullAddressDetail"
            ref={register({
              required: true,
            })}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>대표자 정보</FormLabel>
          <Input
            type="text"
            placeholder="대표자명"
            name="chiefName"
            ref={register()}
          />
          <Input
            style={{ marginTop: 20 }}
            type="text"
            placeholder="대표자 연락처 ex. 01005260221 (-없이)"
            name="chiefPhone"
            ref={register({
              // pattern: /^(?:(010-?\d{4})|(01[1|6|7|8|9]-?\d{3,4}))-?\d{4}$/,
              pattern: /^(?:(010-?\d{4})|(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]|70)-?\d{3,4})|(01[1|6|7|8|9]-?\d{3,4}))-?\d{4}$/,
            })}
          />
          {errors.chiefPhone && errors.chiefPhone.type === 'pattern' && (
            <Text size="14px" type="Medium" color="#ea4653">
              올바르지 않은 번호입니다.
            </Text>
          )}
          <Input
            style={{ marginTop: 20 }}
            type="text"
            placeholder="대표자 이메일 주소 입력"
            autoComplete="none"
            name="chiefEmail"
            ref={register({
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })}
          />
          {errors.chiefEmail && errors.chiefEmail.type === 'pattern' && (
            <Text size="14px" type="Medium" color="#ea4653">
              올바르지 않은 이메일 형식입니다.
            </Text>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>실무자 정보*</FormLabel>
          <Input
            type="text"
            placeholder="실무자명"
            name="staffName"
            ref={register({
              required: true,
            })}
          />
          <Input
            style={{ marginTop: 20 }}
            type="text"
            placeholder="실무자 연락처 ex. 01005260221 (-없이)"
            name="staffPhone"
            ref={register({
              required: true,
              pattern: /^(?:(010-?\d{4})|(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]|70)-?\d{3,4})|(01[1|6|7|8|9]-?\d{3,4}))-?\d{4}$/,
            })}
          />
          {errors.staffPhone && errors.staffPhone.type === 'pattern' && (
            <Text size="14px" type="Medium" color="#ea4653">
              올바르지 않은 번호입니다.
            </Text>
          )}
          <Input
            style={{ marginTop: 20 }}
            type="text"
            placeholder="실무자 이메일 주소 입력"
            autoComplete="none"
            name="staffEmail"
            ref={register({
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })}
          />
          {errors.staffEmail && errors.staffEmail.type === 'pattern' && (
            <Text size="14px" type="Medium" color="#ea4653">
              올바르지 않은 이메일 형식입니다.
            </Text>
          )}
          {isDuplicateEmail && (
            <Text size="14px" type="Medium" color="#ea4653">
              중복된 이메일입니다.
            </Text>
          )}
        </FormGroup>

        {/* <FormGroup>
          <Input
            style={{ width: '100%' }}
            type="text"
            placeholder="휴대폰 번호 ex. 01005260221 (-없이)"
            name="phone"
            ref={
              register({
                required: true,
                pattern: /^(?:(010-?\d{4})|(01[1|6|7|8|9]-?\d{3,4}))-?\d{4}$/,
              })
            }
          />
          {
            errors.phone && errors.phone.type === 'pattern' && (
              <Text size="14px" type="Medium" color="#ea4653">
              올바르지 않은 휴대폰 번호입니다.
              </Text>
            )
          }
        </FormGroup> */}

        {/* footer */}
        <Flex
          style={{
            width: '100%',
            justifyContent: 'space-around',
            marginBottom: '30px',
          }}
        >
          <CheckTextBox
            checked={agree1 && true}
            onClick={() => setShowAgree1(!showAgree1)}
            text="이용약관 동의"
            style={{ width: 'fit-content', textDecoration: 'underline' }}
          />
          <CheckTextBox
            checked={agree2 && true}
            onClick={() => setShowAgree2(!showAgree2)}
            text="개인정보 수집이용 동의"
            style={{ width: 'fit-content', textDecoration: 'underline' }}
          />
        </Flex>

        {allAgree && (
          <Text size="14px" type="Medium" color="#ea4653">
            약관에 모두 동의해주세요.
          </Text>
        )}

        <RegisterBtn type="submit">가입하기</RegisterBtn>
      </Form>

      <ServiceAgreeModal
        show={showAgree1}
        handleClose={() => setShowAgree1(!showAgree1)}
        isAgree={agree1}
        setIsAgree={setAgree1}
      />
      <InfoAgreeModal
        show={showAgree2}
        handleClose={() => setShowAgree2(!showAgree2)}
        isAgree={agree2}
        setIsAgree={setAgree2}
      />
    </NoHeaderBox>
  )
}
