import { observable } from 'mobx'

export default class StageNameModel {
  /* Database fields */
  @observable _id
  @observable artistId
  @observable createdAt
  @observable deleteAt
  @observable isAuto
  @observable name
  @observable uniqueName
  @observable updatedAt
  @observable deletedAt
  @observable linkDeletedAt
  /* Database fields end */

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.artistId = props.artistId
      this.createdAt = props.createdAt
      this.deleteAt = props.deleteAt
      this.isAuto = props.isAuto
      this.name = props.name
      this.uniqueName = props.uniqueName
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt
      this.linkDeletedAt = props.linkDeletedAt
    }
  }
}
