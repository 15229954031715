import React, { useEffect, useState } from 'react'
import { RootTemplate, AlbumDetailTemplate } from '@components/templates'
import {
  AlbumFormModal,
  TrackFormModal,
  LinkCopiedPopup,
  DeletePopup,
} from '@components/organisms'

import { inject, observer } from 'mobx-react'
import qs from 'qs'
import { ArtistStore, AlbumStore, StatsStore } from '../stores'

interface AlbumDetailPageProps {
  artistStore: ArtistStore;
  albumStore: AlbumStore;
  statsStore: StatsStore;
  match: Any;
  location: Any;
  logout: Function;
  user: Object;
}

const AlbumDetailPage = ({
  artistStore,
  albumStore,
  statsStore,
  match,
  location,
  logout,
  user,
}: AlbumDetailPageProps) => {
  /* use copy toast */
  const [isCopy, setIsCopy] = useState(false)
  const showCopyToast = () => {
    setIsCopy(true)
    setTimeout(() => setIsCopy(false), 2500)
  }
  /* use copy toast end */

  /* delete track confirm popup */
  const [showTrackDelete, setShowTrackDelete] = useState(false)
  const deleteTrack = albumStore.deleteTrack || (() => {})

  const navigateDeleteTrack = async (track, album) => {
    albumStore.albumDetail = album
    await albumStore.fetchTrackDetail(track)
    setShowTrackDelete(true)
  }
  /* delete confirm popup end */

  /* use album modal */
  const [showAlbumModal, setShowAlbumModal] = useState(false)
  const createReleaseAlbum = albumStore.createReleaseAlbum || (() => {})
  const updateReleaseAlbum = albumStore.updateReleaseAlbum || (() => {})
  const handleCloseAlbumModal = () => setShowAlbumModal(false)
  const navigateUpdateAlbum = album => {
    albumStore.albumDetail = album
    setShowAlbumModal(true)
  }
  const albumDetail = albumStore.albumDetail || null
  /* use album modal end */

  /* use track modal */
  const [showTrackModal, setShowTrackModal] = useState(false)
  const createTrack = albumStore.createTrack || (() => {})
  const updateTrack = albumStore.updateTrack || (() => {})
  const handleCloseTrackModal = () => setShowTrackModal(false)
  const navigateUpdateTrack = async (track, album) => {
    albumStore.albumDetail = album
    await albumStore.fetchTrackDetail(track)
    setShowTrackModal(true)
  }
  const trackDetail = albumStore.trackDetail || null
  /* use track modal end */

  const checkCustomUrl = statsStore.checkCustomUrl || (() => {})
  const modifyCustomUrl = statsStore.modifyCustomUrl || (() => {})

  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      // 문자열 맨 앞의 ?를 생력
    })
    if (query.artistId && query.artistId !== 'null') {
      artistStore.fetchArtistDetail(query.artistId)
    }
    else {
      artistStore.fetchArtistDetail(user && user['artistId'])
    }

    albumStore.fetchAlbumDetail(match.params.albumId)
  }, [user, albumStore, artistStore, match.params.albumId, location])

  const artist = artistStore.artistDetail
  return (
    <RootTemplate user={user} artist={artist} logout={logout}>
      <AlbumDetailTemplate
        artist={artist}
        album={albumDetail}
        navigateUpdateAlbum={navigateUpdateAlbum}
        navigateUpdateTrack={navigateUpdateTrack}
        navigateDeleteTrack={navigateDeleteTrack}
        showCopyToast={showCopyToast}
        checkCustomUrl={checkCustomUrl}
        modifyCustomUrl={modifyCustomUrl}
      />
      {/* use album modal */}
      <AlbumFormModal
        artist={artist}
        show={showAlbumModal}
        handleClose={handleCloseAlbumModal}
        defaultAlbum={albumDetail}
        createAlbum={album => createReleaseAlbum(album)}
        updateAlbum={album => updateReleaseAlbum(album, albumStore.albumDetail)}
        isUpdating={!!albumDetail}
      />
      {/* use album modal end */}
      {/* use track modal */}
      <TrackFormModal
        artist={artist}
        album={albumDetail}
        show={showTrackModal}
        handleClose={handleCloseTrackModal}
        defaultTrack={trackDetail}
        createTrack={track => createTrack(track, albumStore.albumDetail)}
        updateTrack={
          track =>
            updateTrack(track, albumStore.trackDetail, albumStore.albumDetail)
        }
        isUpdating={!!trackDetail}
      />
      {/* use track modal end */}
      {/* use copy toast */}
      {isCopy && <LinkCopiedPopup />}
      {/* use copy toast end */}
      {/* use delete track confrim popup  */}
      {
        showTrackDelete && (
          <DeletePopup
            text="트랙을 정말로 삭제하시겠습니까?"
            onCancel={() => setShowTrackDelete(false)}
            onDelete={
              () => {
                deleteTrack(albumStore.trackDetail, albumStore.albumDetail, true)
                setShowTrackDelete(false)
              }
            }
          />
        )
      }
      {/* use delete track confrim popup end */}
    </RootTemplate>
  )
}

export default inject(
  'artistStore',
  'albumStore',
  'statsStore',
)(observer(AlbumDetailPage))
