import React, { useState } from 'react'
import { RegisterUpdateEmailTemplate } from '@components/templates'
import { inject, observer } from 'mobx-react'
import { AuthStore } from '../stores'
import { Redirect } from 'react-router-dom'

interface Props {
  authStore: AuthStore;
}

const RegisterUpdateEmailPage = ({ authStore }: Props) => {
  const [isUpdate, setIsUpdate] = useState(false)
  const updateEmail = authStore.updateEmail || (() => {})
  const emailAuth = authStore.emailAuth || (() => {})

  return (
    <>
      {isUpdate && <Redirect to="/" />}
      <RegisterUpdateEmailTemplate
        user={authStore.currentUser}
        updateEmail={updateEmail}
        emailAuth={emailAuth}
        setIsUpdate={setIsUpdate}
      />
    </>
  )
}

export default inject('authStore')(observer(RegisterUpdateEmailPage))
