import React from 'react'
import { plaminLogo } from '@images/index'
import { Link } from 'react-router-dom'

export const LogoImg = ({ isBeta, isLarge }) => {
  return (
    <>
      <Link to="/">
        <img
          src={plaminLogo}
          width={isLarge ? 126 : 87}
          height={isLarge ? 36 : 24.8}
          alt="logo"
        />
      </Link>
    </>
  )
}
