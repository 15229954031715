import { observable } from 'mobx'
import AdjustmentContractModel from './AdjustmentContractModel'

export default class AdjustmentTypeModel {
  @observable _id
  @observable type
  @observable adjustmentContractId
  @observable description = null
  @observable artistId
  @observable companyId
  @observable isConfirmed = false
  @observable isDefault = true

  @observable createdAt
  @observable updatedAt
  @observable deleledAt = null

  @observable adjustmentContract

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.type = props.type
      this.adjustmentContractId = props.adjustmentContractId
      this.description = props.description
      this.artistId = props.artistId
      this.companyId = props.companyId
      this.isConfirmed = props.isConfirmed
      this.isDefault = props.isDefault
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deleledAt = props.deleledAt

      this.adjustmentContract = new AdjustmentContractModel(
        stores,
        props.adjustmentContractInfo,
      )
    }
  }
}
