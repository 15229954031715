import React, { useState, useRef } from 'react'
import { Table, Input, Space, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { LinkCopiedPopup } from '@components/organisms'
import { Caption2, Flex } from '@components/atoms'
import { STORAGE_URL } from '@consts/'

const copy_btn_img = require('@images/copy_img_gray.png')

export const InflowDetailTableChart = ({ data }) => {
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isCopy, setIsCopy] = useState(false) // 복사 팝업

  const searchInput = useRef(null)

  // 검색 기능
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={
            e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }
  // --end 검색기능

  // 주소 복사 팝업
  const handleCopy = () => {
    setIsCopy(true)
    setTimeout(() => setIsCopy(false), 2500)
  }

  const sortHandler = (a, b, sortOrder) => {
    if (a < b) {
      return -1
    }
    else if (a > b) {
      return 1
    }
    else {
      return 0
    }
  }

  return (
    <>
      <Table
        className="inflow_table"
        columns={
          [
            {
              title: '구분',
              key: '구분',
              dataIndex: 'urlType',
              width: '137px',
              render: (text, record) => {
                return text === 'artist'
                  ? '아티스트'
                  : text === 'album'
                    ? '앨범'
                    : '트랙'
              },
            },
            {
              title: '페이지',
              key: 'title',
              dataIndex: 'title',
              sorter: (a, b, sortOrder) => {
                return sortHandler(a?.title, b?.title)
              },
              ...getColumnSearchProps('title'),
              width: '30%',
              align: 'left',
              render: (text, record) => {
                const imageUrl = () => {
                  if (record?.artist) {
                    return record?.artist?.image64Path
                  }
                  else if (record?.album) {
                    return record?.album?.image64Path
                  }
                  else if (record?.track) {
                    return record?.track?.albumInfo?.image64Path
                  }
                  else {
                    return ''
                  }
                }
                return (
                  <Flex align="center">
                    {
                      imageUrl() && (
                        <img
                          src={STORAGE_URL + imageUrl()}
                          alt="cover_img"
                          style={
                            {
                              width: '36px',
                              height: '36px',
                              borderRadius: '6px',
                              margin: '0px 16px',
                            }
                          }
                        />
                      )
                    }
                    <Caption2
                      style={
                        {
                          display: 'inline-block',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          width: '100%',
                        }
                      }
                      align={'left'}
                    >
                      {text}
                    </Caption2>
                  </Flex>
                )
              },
            },
            {
              title: '유입',
              key: 'totalFromCount',
              dataIndex: 'totalFromCount',
              width: '112px',
              defaultSortOrder: { sortOrder: 'descend' },
              sorter: (a, b, sortOrder) => {
                return sortHandler(a?.totalFromCount, b?.totalFromCount)
              },
            },
            {
              title: '전환',
              key: 'totalClickCount',
              dataIndex: 'totalClickCount',
              width: '112px',
              sorter: (a, b, sortOrder) => {
                return sortHandler(a?.totalClickCount, b?.totalClickCount)
              },
            },
            {
              title: 'CTR(전환율)',
              key: 'cvr',
              dataIndex: 'totalClickCount',
              width: '112px',
              render: (text, record) => {
                return `${Math.floor(
                  (record.totalClickCount / record.totalFromCount) * 100,
                )}%`
              },
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                  Math.floor((a.totalClickCount / a.totalFromCount) * 100),
                  Math.floor((b.totalClickCount / b.totalFromCount) * 100),
                )
              },
            },
            {
              title: 'Youtube',
              key: 'totalYoutubeFromCount',
              dataIndex: 'totalYoutubeFromCount',
              width: '112px',
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                a?.totalYoutubeFromCount,
                b?.totalYoutubeFromCount,
                )
              },
            },
            {
              title: 'Facebook',
              key: 'totalFacebookFromCount',
              dataIndex: 'totalFacebookFromCount',
              width: '112px',
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                a?.totalFacebookFromCount,
                b?.totalFacebookFromCount,
                )
              },
            },
            {
              title: 'Instagram',
              key: 'totalInstagramFromCount',
              dataIndex: 'totalInstagramFromCount',
              width: '112px',
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                a?.totalInstagramFromCount,
                b?.totalInstagramFromCount,
                )
              },
            },
            {
              title: '유입경로',
              key: 'plamUrl',
              dataIndex: 'plamUrl',
              render: (text, record) => {
                return (
                  <CopyToClipboard
                    text={record.plamUrl}
                    onCopy={() => handleCopy()}
                  >
                    <img
                      src={copy_btn_img}
                      alt="copy"
                      style={
                        {
                          width: '22px',
                          height: '22px',
                          cursor: 'pointer',
                        }
                      }
                    />
                  </CopyToClipboard>
                )
              },
              width: '100px',
            },
          ]
        }
        dataSource={data}
        pagination={false}
        size={'small'}
        style={
          {
            width: '100%',
            height: '360px',
            border: 'solid 1px #dddddd',
            borderRadius: '6px',
            marginBottom: '100px',
          }
        }
        rowKey="plamUrl"
        scroll={{ y: 300 }}
      />
      {isCopy && <LinkCopiedPopup />}
    </>
  )
}
