import React from 'react'
import styled from 'styled-components'
import { colors } from '@colors/'

const Btn = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
  width: 126px;
  height: 40px;
  border-radius: 6px;
  background-color: ${props => (props.active ? colors.main : '#ffffff')};
  cursor: pointer;
  border: ${props =>
    props.active ? `1px solid ${colors.main}` : '1px solid #949494'};
  color: ${props => (props.active ? '#ffffff' : '#949494')};
`

export const AlbumTypeBtn = ({ children, active, onClick }) => {
  return (
    <Btn active={active} onClick={onClick}>
      {children}
    </Btn>
  )
}
