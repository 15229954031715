import styled from 'styled-components'

export const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.type && props.type};
  justify-content: ${props => props.justify && props.justify};
  align-items: ${props => props.align && props.align};
  width: ${props => props.width && props.width};
  height: ${props => props.height && props.height};
  gap: ${props => props.gap && props.gap};
`
