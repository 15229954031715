import React, { useEffect } from 'react'
import { RootTemplate } from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import QueryString from 'qs'
import InsightSettlementAlbumTemplate from '@components/templates/InsightSettlementAlbumTemplate'

interface Props {
  logout: Function;
  user: Object;
}

const InsightSettlementAlbumPage = ({ user, logout, match }: Props) => {
  const { authStore, revenueStore, artistStore, settlementStore } = useStore()
  const { currentUser } = authStore
  const {
    albumAnnualData,
    albumCurrentMonth,
    trackCurrentMonth,
  } = settlementStore
  const { artistDetail } = artistStore
  const { artistRecentMonth, companyRecentMonth } = revenueStore

  const location = useLocation()
  const query = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const artistId = query?.artistId
  const date = query?.date
  const isCompanyData = currentUser?.type === 'company'

  const fetchArtistRecentMonth =
    revenueStore.fetchArtistRecentMonth || (() => {})
  const fetchCompanyRecentMonth =
    revenueStore.fetchCompanyRecentMonth || (() => {})
  const setTrackCurrentMonth =
    settlementStore.setTrackCurrentMonth || (() => {})
  const setAlbumCurrentMonth =
    settlementStore.setAlbumCurrentMonth || (() => {})
  const fetchSettlementArtistAlbumAnnual =
    settlementStore.fetchSettlementArtistAlbumAnnual || (() => {})
  const fetchSettlementCompanyAlbumAnnual =
    settlementStore.fetchSettlementCompanyAlbumAnnual || (() => {})
  const fetchSettlementCompanyArtistAlbumAnnual =
    settlementStore.fetchSettlementCompanyArtistAlbumAnnual || (() => {})

  useEffect(() => {
    // if (artistRecentMonth) {
    //   const parseMonth = moment().set({
    //     year: `20${Math.floor(artistRecentMonth / 100)}`,
    //     month: (artistRecentMonth % 100) - 1,
    //   })
    //   setAlbumCurrentMonth(parseMonth)
    //   setTrackCurrentMonth(parseMonth)
    // } else if (companyRecentMonth) {
    //   const parseMonth = moment().set({
    //     year: `20${Math.floor(companyRecentMonth / 100)}`,
    //     month: (companyRecentMonth % 100) - 1,
    //   })
    //   setAlbumCurrentMonth(parseMonth)
    //   setTrackCurrentMonth(parseMonth)
    // }
    if (date) {
      setAlbumCurrentMonth(moment(`20${date.slice(0, 2)}-${date.slice(2)}`))
      setTrackCurrentMonth(moment(`20${date.slice(0, 2)}-${date.slice(2)}`))
    }
  }, [
    artistRecentMonth,
    companyRecentMonth,
    setAlbumCurrentMonth,
    setTrackCurrentMonth,
  ])

  useEffect(() => {
    if (currentUser) {
      if (isCompanyData && currentUser?.companyId) {
        fetchCompanyRecentMonth(currentUser?.companyId)
      }
      else {
        fetchArtistRecentMonth(currentUser?.artistId)
      }
    }
    if (
      currentUser
      && albumCurrentMonth
      && albumCurrentMonth
      && match.params.id
    ) {
      if (!isCompanyData && currentUser?.artistId) {
        fetchSettlementArtistAlbumAnnual(
          currentUser?.artistId,
          match.params.id,
          {
            __year: albumCurrentMonth.format('YY'),
            __trackMonth: trackCurrentMonth.format('YYMM'),
          },
        )
      }
      else if (
        isCompanyData
        && currentUser?.companyId
        && match.path?.includes('/company/')
      ) {
        fetchSettlementCompanyAlbumAnnual(
          currentUser?.companyId,
          match.params.id,
          {
            __year: albumCurrentMonth.format('YY'),
            __trackMonth: trackCurrentMonth.format('YYMM'),
          },
        )
      }
      else if (isCompanyData && currentUser?.companyId) {
        fetchSettlementCompanyArtistAlbumAnnual(
          currentUser?.companyId,
          currentUser?.artistId,
          match.params.id,
          {
            __year: albumCurrentMonth.format('YY'),
            __trackMonth: trackCurrentMonth.format('YYMM'),
          },
        )
      }
    }
  }, [
    artistId,
    match,
    currentUser,
    albumCurrentMonth,
    trackCurrentMonth,
    fetchArtistRecentMonth,
    fetchCompanyRecentMonth,
    fetchSettlementArtistAlbumAnnual,
    fetchSettlementCompanyArtistAlbumAnnual,
    fetchSettlementCompanyAlbumAnnual,
  ])

  return (
    <RootTemplate user={user} logout={logout} artist={artistDetail}>
      <InsightSettlementAlbumTemplate
        type={
          currentUser?.type === 'company'
            ? match.path?.includes('/company/')
              ? 'company'
              : 'company-artist'
            : 'artist'
        }
        albumAnnualData={albumAnnualData}
      />
    </RootTemplate>
  )
}

export default observer(InsightSettlementAlbumPage)
