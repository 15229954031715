import React from 'react'
import styled from 'styled-components'
import { Flex, Caption1, Caption2, Div } from '@components/atoms'
import { convertToPricingComma } from '@utils/format'

const ModalBody = styled(Flex)`
  z-index: 1000;
  position: absolute;
  top: 24px;
  right: 0px;
  width: 320px;
  height: 494px;
  padding: 16px 0 20px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #fff;

  flex-direction: column;
  align-items: center;
`

const Card = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 280px;
  height: 48px;
  padding: 15px 16px;
  border-radius: 6px;
  border: solid 1px #ddd;
  background-color: #fcfcfc;
`

const AlbumValuationInfoModal = ({ valuationInfo, type = '앨범' }) => {
  return (
    <ModalBody type="column">
      <Caption1 type="bold">
        {type} 가치 평가액{' '}
        <Caption1 type="Medium" color="#949494">
          (합계)
        </Caption1>
      </Caption1>
      <Flex type="column" style={{ marginTop: '16px' }}>
        <Caption2 type="Regular" color="#646464">
          보유한 {type}의 가치 평가액은
        </Caption2>
        <Caption1 style={{ margin: '12px 0' }}>
          {convertToPricingComma(
            Math.floor(valuationInfo?.dayWeightedTotalValue),
          )}
          원
        </Caption1>
        <Caption2 size="10px" color="#646464">
          (인접권 + 저작권 + 실연권의 예상수익 현재가치 환산)
        </Caption2>
      </Flex>
      <Flex
        type="column"
        style={{
          gap: '12px',
          margin: '12px 0',
          border: '1px solid #f4f4f4',
          borderRight: 'none',
          borderLeft: 'none',
          width: '100%',
          padding: '16px 20px',
        }}
      >
        <Card>
          <Caption2 color="#646464">인접권</Caption2>
          <Caption2 type="Bold">
            {convertToPricingComma(
              Math.floor(valuationInfo?.dayWeightedAdjacencyValue),
            )}
            원
          </Caption2>
        </Card>
        <Card>
          <Caption2 color="#646464">저작권</Caption2>
          <Caption2 type="Bold">
            {convertToPricingComma(
              Math.floor(valuationInfo?.dayWeightedCopyRightValue),
            )}
            원
          </Caption2>
        </Card>
        <Card>
          <Caption2 color="#646464">실연권</Caption2>
          <Caption2 type="Bold">
            {convertToPricingComma(
              Math.floor(valuationInfo?.dayWeightedPerformanceRightValue),
            )}
            원
          </Caption2>
        </Card>
      </Flex>
      <Flex type="column" style={{ padding: '0 20px', gap: '10px' }}>
        <Caption2 align="left">담당자 이메일</Caption2>
        <Caption1 align="left" type="bold">
          hammond@plam.in
        </Caption1>
        <Caption2 align="left" size="10px" type="bold" color="#949494">
          비욘드뮤직, 뮤직카우 등 파트너 음악 권리 투자사를 통한 판매를 고려하실
          경우, 위 이메일을 주시면 담당자가 24시간 내에 답변드립니다.
        </Caption2>
      </Flex>
    </ModalBody>
  )
}

export default AlbumValuationInfoModal
