import React, { useEffect, useState } from 'react'
import { RootTemplate, InsightTemplate } from '@components/templates'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/ko'
import { Redirect } from 'react-router-dom'
import qs from 'qs'

interface Props {
  artistStore: ArtistStore;
  authStore: AuthStore;
  statsStore: StatsStore;
  match: Any;
  location: Any;
  logout: Function;
  user: Object;
}

const InsightPage = ({
  statsStore,
  authStore,
  artistStore,
  logout,
  location,
  user,
}: Props) => {
  moment.locale('ko')
  const artist = artistStore.artistDetail
  const { TotalInflowStatsList } = statsStore
  const { TotalInflowStatsByUrlList } = statsStore
  const { DailyInflowStatsList } = statsStore
  const { sumInflow } = statsStore

  const [startDate, setStartDate] = useState(moment().subtract(1, 'months'))
  const [endDate, setEndDate] = useState(moment())
  const [searchType, setSearchType] = useState({ label: '전체', value: 'all' })

  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      // 문자열 맨 앞의 ?를 생력
    })
    if (query.artistId && query.artistId !== 'null') {
      artistStore.fetchArtistDetail(query.artistId)
      statsStore.fetchStats({
        artistId: query.artistId,
        periodType: 'custom',
        fromDate: startDate && startDate.format('YYYY-MM-DD'),
        toDate: endDate && endDate.format('YYYY-MM-DD'),
        searchType: searchType.value,
      })
    }
    else {
      artistStore.fetchArtistDetail(user && user['artistId'])
      statsStore.fetchStats({
        artistId: user && user['artistId'],
        periodType: 'custom',
        fromDate: startDate && startDate.format('YYYY-MM-DD'),
        toDate: endDate && endDate.format('YYYY-MM-DD'),
        searchType: searchType.value,
      })
    }
  }, [user, artistStore, statsStore, startDate, endDate, searchType, location])
  return (
    <>
      <RootTemplate user={user} artist={artist} logout={logout}>
        {!(user && user['artistId']) && <Redirect to="/" />}
        <InsightTemplate
          TotalInflowStatsList={TotalInflowStatsList}
          TotalInflowStatsByUrlList={TotalInflowStatsByUrlList}
          DailyInflowStatsList={DailyInflowStatsList}
          sumInflow={sumInflow}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          searchType={searchType}
          setSearchType={setSearchType}
        />
      </RootTemplate>
    </>
  )
}

export default inject(
  'authStore',
  'artistStore',
  'statsStore',
)(observer(InsightPage))
