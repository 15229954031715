import React from 'react'

import { Div, Image } from '@components/atoms'

const LoginPageBox = ({ src, type }) => {
  if (type === 'circle') {
    return (
      <Div
        width="72px"
        height="72px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.08)"
        backgroundColor="white"
      >
        <Image src={src} width="72px" height="72px" />
      </Div>
    )
  }
  return (
    <Div
      width="160px"
      height="80px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="8px"
      boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.08)"
      backgroundColor="white"
    >
      <Image src={src} width="160px" height="40px" />
    </Div>
  )
}

export default LoginPageBox
