import React, { useState } from 'react'
import { Input, Caption1, H3 } from '@components/atoms'
import { LogoImg, FormGroup, NoHeaderBox } from '@components/molecules'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { mobile } from '@styles/media'

const Form = styled.form`
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const RegisterUpdateEmailTemplate = ({
  user,
  updateEmail,
  setIsUpdate,
  emailAuth,
}) => {
  const [isDuplicate, setIsDuplicate] = useState(false)
  const { register, errors, handleSubmit } = useForm()

  const onSubmit = async data => {
    const params = {
      _id: user._id,
      email: data.email,
    }

    const res = await updateEmail(params)
    if (!res.data.isModify) {
      const { isDuplicateEmail } = res.data
      setIsDuplicate(isDuplicateEmail)
    } else {
      emailAuth(user._id)
      setIsUpdate(true)
    }
  }

  return (
    <Box>
      <LogoImg />
      <H3 style={{ margin: '28px 0 32px 0' }}>이메일 수정</H3>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup style={{ width: '100%' }}>
          <Input
            style={{ width: '100%' }}
            placeholder="이메일 주소"
            name="email"
            ref={register({ required: true, pattern: /^\S+@\S+$/i })}
          />
          {errors.email && errors.email.type === 'required' && (
            <Caption1 style={{ textAlign: 'left' }} color="#ea4653">
              이메일을 입력해주세요.
            </Caption1>
          )}
          {errors.email && errors.email.type === 'pattern' && (
            <Caption1 style={{ textAlign: 'left' }} color="#ea4653">
              이메일 형식이 올바르지 않습니다.
            </Caption1>
          )}
          {isDuplicate && (
            <Caption1 style={{ textAlign: 'left' }} color="#ea4653">
              이미 존재하는 이메일입니다.
            </Caption1>
          )}
        </FormGroup>
        <SubmitBtn type="submit">수정하기</SubmitBtn>
      </Form>
    </Box>
  )
}

const Box = styled(NoHeaderBox)`
  position: relative;

  @media ${mobile} {
    height: 100dvh;
    justify-content: flex-start;
  }
`

const SubmitBtn = styled.button`
  width: 320px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: none;
  background-color: #f4f4f4;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #242424;

  @media ${mobile} {
    margin-top: 0px;

    position: absolute;
    bottom: 20px;
    left: calc(50% - 160px);
  }
`
