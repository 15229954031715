import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Flex, H5, Image } from '@components/atoms'
import styled from 'styled-components'

import { path_menu, down_btn } from '@images/'
import moment from 'moment'

const MonthlyPicker = ({ currentMonth, handleMonth, enableMonthList }) => {
  const boxRef = useRef()

  const [isFocused, setIsFocused] = useState(false)

  const displayMonth = currentMonth?.format('YYYY년 MM월')
  const displayYear = currentMonth?.format('YYYY년')

  const currentMonthList = useMemo(() => {
    const list = []

    if (currentMonth && enableMonthList) {
      for (let i = 0; i < 12; i++) {
        const strI = `${currentMonth?.format('YY')}${
          i < 9 ? `0${i + 1}` : i + 1
        }`

        const isEnable = enableMonthList?.filter(m => `${m}` === strI)

        list.push({
          isEnable: isEnable?.length > 0,
          value: moment(currentMonth).set({
            month: i,
          }),
          name: `${i + 1}월`,
        })
      }
    }

    return list
  }, [currentMonth, enableMonthList])

  const handleSelectMonth = value => {
    setIsFocused(false)
    handleMonth(value)
  }

  const handleFocusClick = () => {
    setIsFocused(prev => !prev)
  }

  useEffect(() => {
    const modalEventListener = e => {
      if (!boxRef.current?.contains(e.target)) setIsFocused(false)
    }

    document.addEventListener('mousedown', modalEventListener)

    return () => {
      document.removeEventListener('mousedown', modalEventListener)
    }
  }, [])

  return (
    <Area ref={boxRef}>
      <Flex align="center">
        <Image
          src={path_menu}
          alt="prev"
          width="18px"
          height="18px"
          transform="rotate(180deg)"
          cursor="pointer"
          onClick={() => handleMonth(currentMonth.subtract(1, 'months'))}
        />
        <CurrentDate onClick={handleFocusClick}>{displayMonth}</CurrentDate>
        <Image
          src={path_menu}
          alt="next"
          width="18px"
          height="18px"
          opacity={moment().isSameOrBefore(currentMonth, 'months') ? 0.5 : 1}
          cursor={
            moment().isSameOrBefore(currentMonth, 'months')
              ? 'not-allowed'
              : 'pointer'
          }
          onClick={
            () => {
              if (moment().isSameOrBefore(currentMonth, 'months')) return
              handleMonth(currentMonth.add(1, 'months'))
            }
          }
        />
      </Flex>
      {
        isFocused && (
          <Picker>
            <PickerHeader>
              <Image
                src={down_btn}
                alt="prev"
                width="12px"
                height="12px"
                transform="rotate(90deg)"
                cursor="pointer"
                onClick={() => handleMonth(currentMonth.subtract(1, 'years'))}
              />
              <CurrentDate onClick={handleFocusClick}>{displayYear}</CurrentDate>
              <Image
                src={down_btn}
                alt="next"
                width="12px"
                height="12px"
                transform="rotate(-90deg)"
                opacity={moment().isSameOrBefore(currentMonth, 'years') ? 0.5 : 1}
                cursor={
                  moment().isSameOrBefore(currentMonth, 'years')
                    ? 'not-allowed'
                    : 'pointer'
                }
                onClick={
                  () => {
                    if (moment().isSameOrBefore(currentMonth, 'years')) return
                    handleMonth(currentMonth.add(1, 'years'))
                  }
                }
              />
            </PickerHeader>

            <MonthList>
              {
currentMonthList?.map(month => (
  <Month
    isEnable={month.isEnable}
    key={month.value}
    active={month.value === currentMonth}
    onClick={
      () => {
        handleSelectMonth(month.value)
      }
    }
  >
    {month.name}
  </Month>
))
              }
            </MonthList>
          </Picker>
        )
      }
    </Area>
  )
}

export default MonthlyPicker

const Area = styled.div`
  position: relative;
  z-index: 2;
`

const CurrentDate = styled(H5)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  cursor: pointer;
  text-decoration: underline;
`

const Picker = styled(Flex)`
  flex-direction: column;
  position: absolute;
  top: 30px;

  width: 232px;
  height: 214px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #fff;
`

const PickerHeader = styled(Flex)`
  width: 100%;
  height: 50px;

  padding: 20px 16px;

  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #f4f4f4;
`

const MonthList = styled(Flex)`
  flex-wrap: wrap;
  padding: 8px;
  gap: 8px 16px;
`

const Month = styled(Flex)`
  cursor: pointer;
  width: 40px;
  height: 40px;

  align-items: center;
  justify-content: center;

  font-family: NotoSansCJKkr-Medium;
  font-size: 12px;

  ${({ active }) =>
    active
    && `
  background-color: black;
  color: white;
  border-radius: 36px;
  `}

  ${({ isEnable }) =>
    isEnable
      ? `
      opacity: 1;

  `
      : `
      opacity:0.5;
      pointer-events: none;
      `}
`
