/* eslint-disable import/no-unresolved */
import { action, observable } from 'mobx'
import { AdjustmentDetailModel } from '@stores/models'
import { Network } from '../networks'
import { Store } from '..'

export default class AdjustmentDetailStore {
  @observable adjustmentDetailList = []

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {}

  /**
   * [정산서 세부 내역 조회]
   */
  async fetchAdjustmentDetail({
    adjustmentId,
    defaultAdjustmentTypeId = null,
  } = {}) {
    if (!adjustmentId) {
      console.error('[fetchAdjustmentDetail] adjustmentId is not exist')
      return
    }

    const res = await this.network.adjustmentDetailNetwork.getAdjustmentDetail({
      adjustmentId,
    })

    if (!res || res.error) {
      console.error('[fetchAdjustmentDetail] failed to network')
      return
    }

    const { adjustmentDetailList } = res.data

    adjustmentDetailList.map(item =>
      console.log('moonsae fetch', item.sortIndex, item.date, item.description),
    )

    if (
      adjustmentDetailList?.length === 0
      && this.store.authStore?.currentUser?.type === 'company'
    ) {
      const promises = []

      for (let i = 1; i < 11; i++) {
        promises.push(
          this.createAdjustmentDetail({
            adjustmentId,
            params: {
              tag: '수입',
              typeId: defaultAdjustmentTypeId, // 새로 생성시 default 값 받아와서 설정
              sortIndex: i,
            },
          }),
        )
      }

      const resList = await Promise.all(promises)
      this.adjustmentDetailList = resList.map(
        _res => new AdjustmentDetailModel(this.store, _res),
      )

      this.adjustmentDetailList.replace(
        this.adjustmentDetailList.slice().sort((a, b) => {
          if (a.sortIndex < b.sortIndex) return -1
          if (a.sortIndex > b.sortIndex) return 1
          return 0
        }),
      )
      return this.adjustmentDetailList
    }

    this.adjustmentDetailList = adjustmentDetailList.map(
      _adjustmentDetail =>
        new AdjustmentDetailModel(this.store, _adjustmentDetail),
    )
    this.adjustmentDetailList.replace(
      this.adjustmentDetailList.slice().sort((a, b) => {
        if (a.sortIndex < b.sortIndex) return -1
        if (a.sortIndex > b.sortIndex) return 1
        return 0
      }),
    )
    return this.adjustmentDetailList
  }

  /**
   * [정산서 증빙 자료 업로드]
   */
  async uploadAdjustmentEvidenceFile(file) {
    const res = await this.network.adjustmentDetailNetwork.postAdjustmentEvidenceFile(
      { filename: file.name },
    )

    if (!res || res.error) {
      console.error(
        '[uploadAdjustmentEvidenceFile] failed to get pre-signed data',
      )
      return
    }
    // 받아온 pre-signed data formData에 넣어서 s3에 업로드
    const presignedPostData = res.data
    const formData = new FormData()
    Object.keys(presignedPostData.fields).forEach(key => {
      formData.append(key, presignedPostData.fields[key])
    })
    formData.append('file', file)

    const res2 = await this.network.adjustmentDetailNetwork.postPreSignedData({
      formdata: formData,
      url: presignedPostData.url,
    })
    if (res2.error) {
      console.error(
        '[uploadAdjustmentEvidenceFile] failed to post pre-signed data',
      )
      return
    }
    return presignedPostData
  }

  /**
   * [정산서 생성]
   */
  async createAdjustmentDetail({ adjustmentId, params } = {}) {
    if (!this.store?.authStore?.currentUser) {
      console.error('[createAdjustmentDetail] currentUser is not exist')
      return
    }
    const { companyId, artistId } = this.store.authStore.currentUser
    const _params = {
      ...params,
      companyId,
      artistId,
    }

    if (!adjustmentId) {
      console.error('[createAdjustmentDetail] adjustmentId is not exist')
      return
    }

    const res = await this.network.adjustmentDetailNetwork.postAdjustmentDetail(
      { adjustmentId, params: _params },
    )

    if (!res || res.error) {
      console.error('[createAdjustmentDetail] failed to network')
      return
    }
    return new AdjustmentDetailModel(this.store, res?.data?.adjustmentDetail)
  }

  /**
   * [정산서 세부 내역 업데이트]
   */
  async updateAdjustmentDetail({ adjustmentId, adjustmentDetailId, params }) {
    if (!adjustmentId || !adjustmentDetailId || !params) {
      console.error(
        '[updateAdjustmentDetail] adjustmentId or adjustmentDetailId or params is not exist',
      )
      return
    }

    const res = await this.network.adjustmentDetailNetwork.putAdjustmentDetail({
      adjustmentId,
      adjustmentDetailId,
      params,
    })

    if (!res || res.error) {
      console.error('[updateAdjustmentDetail] failed to network')
      return
    }

    return res
  }

  /**
   * [정산서 세부 내역 삭제]
   */
  async deleteAdjustmentDetail({ adjustmentId, adjustmentDetailId } = {}) {
    if (!adjustmentId || !adjustmentDetailId) {
      console.error(
        '[deleteAdjustmentDetail] adjustmentId or adjustmentDetailId is not exist',
      )
      return
    }

    const res = await this.network.adjustmentDetailNetwork.deleteAdjustmentDetail(
      { adjustmentId, adjustmentDetailId },
    )

    if (!res || res.error) {
      console.error('[updateAdjustmentDetail] failed to network')
      return
    }

    console.log('[updateAdjustmentDetail] res', res)
    return res.data.adjustmentDetail
  }
}
