import React, { useState } from 'react'
import { Flex, H4, H6 } from '@components/atoms'
import { Modal, SubmitBtn } from '@components/molecules'
import { RevenueUploadForm } from '@components/organisms'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { API_URL } from '@consts'
import { message } from 'antd'

interface RevenueUploadModalProps {
  show: Boolean;
  handleClose: () => void;
}

const RevenueUploadModal = ({ show, handleClose }: RevenueUploadModalProps) => {
  const [selectedFile, setSelectedFile] = useState(null) // 오디오 파일
  const [percent, setPercent] = useState(0)

  const { authStore, revenueStore } = useStore()
  const jwt = authStore.jsonWebToken || null
  const accountRevenueFile = revenueStore.accountRevenueFile || (() => {})
  /**
   * Retrieve pre-signed POST data from a dedicated API endpoint.
   * @param selectedFile
   * @returns {Promise<any>}
   */
  const getPresignedPostData = selectedFile => {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest()
      // Set the proper URL here.
      const url = `${API_URL}/file`

      xhr.open('POST', url, true)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.setRequestHeader('Authorization', `jwt ${jwt}`)
      xhr.send(
        JSON.stringify({
          name: selectedFile.name,
          isRevenueRawData: 1,
        }),
      )
      xhr.onload = function() {
        resolve(JSON.parse(this.responseText))
      }
    })
  }

  /**
   * Upload file to S3 with previously received pre-signed POST data.
   * @param presignedPostData
   * @param file
   * @returns {Promise<any>}
   */
  const uploadFileToS3 = (presignedPostData, file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      Object.keys(presignedPostData.fields).forEach(key => {
        console.log(key, ' : ', presignedPostData.fields[key])
        formData.append(key, presignedPostData.fields[key])
      })
      // Actual file has to be appended last.
      formData.append('file', file)
      const xhr = new XMLHttpRequest()
      xhr.upload.addEventListener(
        'progress',
        function(event) {
          if (event.lengthComputable) {
            setPercent(Math.floor((100 * event.loaded) / event.total))
          }
        },
        false,
      )

      xhr.open('POST', presignedPostData.url, true)
      xhr.send(formData)
      xhr.onload = function() {
        this.status === 204 ? resolve() : reject(this.responseText)
      }
    })
  }

  const handleUploadClick = async () => {
    // Step 1 - get pre-signed POST data.
    const { data: presignedPostData } = await getPresignedPostData(selectedFile)

    // Step 2 - upload the file to S3.
    try {
      const { file } = selectedFile.src
      await uploadFileToS3(presignedPostData, file)
      setPercent(100)

      if (presignedPostData?.fields?.key) {
        const res = await accountRevenueFile(presignedPostData?.fields?.key)
        if (res) {
          message.success('업로드 성공!')
          setSelectedFile(null)
        }
      }
      handleClose()
      console.log('File was successfully uploaded!')
    } catch (e) {
      console.log('An error occurred!', e.message)
      message.error('업로드 실패.')
    }
  }

  return (
    <Modal
      style={{ minHeight: '442px', width: '490px' }}
      show={show}
      handleClose={handleClose}
    >
      <Flex type="column" align="center">
        <H4>파일 업로드</H4>
        <H6 type="Regular">
          음원 유통정산 데이터를 보내주시면
          <br />
          영업일 기준{' '}
          <strong style={{ fontFamily: 'NotoSansCJKkr-Bold' }}>
            48시간 이내
          </strong>
          로 반영됩니다.
        </H6>
        <Flex style={{ margin: '32px 0 28px' }}>
          <RevenueUploadForm
            setSelectedFile={setSelectedFile}
            percent={percent}
          />
        </Flex>
        <SubmitBtn onClick={handleUploadClick}>업로드</SubmitBtn>
      </Flex>
    </Modal>
  )
}

export default observer(RevenueUploadModal)
