import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Text, Button, Image, Container } from '@components/atoms'
import {
  NoAlbumList,
  AlbumList,
  InputTypeModal,
  MelonLinkModal,
  ConfirmPopup,
} from '@components/organisms'
import { useHistory } from 'react-router-dom'
import { colors } from '@colors/'
import { inject } from 'mobx-react'

const ADD_BTN = require('@images/add_btn.png')

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  padding-right: 40px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
`

const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: #ffffff;
`

const HeaderBtnImg = styled(Image)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`

interface AlbumTemplateProps {
  authStore: any;
  firstFetchReleaseAlbumList: (album: Object | null) => void;
  moreFetchReleaseAlbumList: (album: Object | null) => void;
  albumList: Array;
  navigateUpdateAlbum: (album: Object | null) => void;
  navigateDeleteTrack: (album: Object | null) => void;
  navigateUpdateTrack: (track: Object | null) => void;
  navigateDeleteAlbum: (album: Object | null) => void;
  showCopyToast: () => void;
  crawlAlbum: () => void;
}
const _AlbumTemplate = ({
  authStore,
  searchKeywordReleaseAlbumList,
  firstFetchReleaseAlbumList,
  moreFetchReleaseAlbumList,
  albumList,
  user,
  navigateUpdateAlbum,
  navigateUpdateTrack,
  navigateDeleteTrack,
  navigateDeleteAlbum,
  showCopyToast,
  crawlAlbum,
  artist,
  isHasMore,
  setIsHasMore,
}: AlbumTemplateProps) => {
  const [type, setType] = useState('') // 입력 방법
  // const [isHasMore, setIsHasMore] = useState(true) // 더보기 유무

  const history = useHistory()

  const {
    isShowing: typeShowing,
    toggle: typeToggle,
  } = InputTypeModal.useModal()
  const {
    isShowing: melonShowing,
    toggle: melonToggle,
  } = MelonLinkModal.useModal()
  const {
    isShowing: showConfirm,
    toggle: confirmToggle,
  } = ConfirmPopup.usePopup()

  // 처음 기본값 앨범 리스트 fetch
  // useEffect(() => {
  //   const handleFirstFetchReleaseAlbumList = async () => {
  //     const res = await firstFetchReleaseAlbumList({
  //       limit: 9,
  //       sortyBy: 'releaseAt',
  //     })

  //     // limit 보다 이하의 값일 경우 더보기 유무 false
  //     setIsHasMore(res)
  //   }
  //   if (artist) handleFirstFetchReleaseAlbumList()
  // }, [artist, firstFetchReleaseAlbumList])

  const isLoginedMaster = authStore.isAdminPassword

  return (
    <Container>
      <Header>
        <Text type="Medium" size="18px" style={{ marginTop: '10px' }}>
          나의 앨범
        </Text>
        {
          isLoginedMaster && artist && artist.isOwner && (
            <Flex style={{ flexDirection: 'row', alignItems: 'center' }}>
              <HeaderBtn onClick={() => typeToggle()}>
                <HeaderBtnImg src={ADD_BTN} alt="header_btn" />
                <Text type="Medium" size="14px" color={colors.main}>
                  앨범 추가
                </Text>
              </HeaderBtn>
            </Flex>
          )
        }
      </Header>
      <Content>
        {
          user && user.nowArtistCrawling ? (
            <>
              <Text style={{ marginTop: '16px' }}>
                {`ArtistID : ${user && user.melonArtistId}`}
              </Text>
              <Text color="#1982df">앨범 정보 등록 중</Text>
              <Text>24시간 이내에 등록됩니다.</Text>
            </>
          ) : !!albumList && albumList.length > 0 ? (
            <AlbumList
              searchKeywordReleaseAlbumList={searchKeywordReleaseAlbumList}
              firstFetchReleaseAlbumList={firstFetchReleaseAlbumList}
              moreFetchReleaseAlbumList={moreFetchReleaseAlbumList}
              albumList={albumList}
              navigateUpdateAlbum={navigateUpdateAlbum}
              navigateUpdateTrack={navigateUpdateTrack}
              navigateDeleteTrack={navigateDeleteTrack}
              navigateDeleteAlbum={navigateDeleteAlbum}
              showCopyToast={showCopyToast}
              isHasMore={isHasMore}
              setIsHasMore={setIsHasMore}
              artistId={artist && artist._id}
            />
          ) : (
            <NoAlbumList typeToggle={typeToggle} />
          )
        }
      </Content>
      <InputTypeModal.View
        isShowing={typeShowing}
        toggle={typeToggle}
        melonToggle={melonToggle}
        type={type}
        setType={setType}
        openDirect={() => navigateUpdateAlbum(null)}
      />
      <MelonLinkModal.View
        isShowing={melonShowing}
        toggle={melonToggle}
        pageType="앨범"
        handleCrawl={crawlAlbum}
        confirmToggle={confirmToggle}
      />
      <ConfirmPopup.View
        isShowing={showConfirm}
        toggle={confirmToggle}
        title={`등록 신청 완료`}
        body={`24시간 이내에 앨범 정보가 등록됩니다.`}
        onClose={() => history.go(0)}
      />
    </Container>
  )
}

export const AlbumTemplate = inject(
  'authStore',
)(_AlbumTemplate)