import React, { useMemo } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts'
import { Flex, Caption1, Caption2 } from '@components/atoms'
import styled from 'styled-components'
import { convertToPricingComma, insightConverseUnitEng } from '@utils/format'

const ToolTipBox = styled(Flex)`
  width: 280px;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  gap: 12px;
`

const COLORS = ['#2f070a', '#ea4653']

const TrackYoutubeListenChart = ({ dailyListData }) => {
  const parseData = useMemo(() => {
    let data = []

    data = dailyListData?.map(info => ({
      ...info,
      youtube: info?.listenerCountInfo?.youtube,
      youtubeVideo: info?.listenerCountInfo?.youtubeVideo,
      youtubeMusic: info?.listenerCountInfo?.youtubeMusic,
    }))

    return data
  }, [dailyListData])

  return (
    <div
      style={{
        marginTop: '30px',
        borderRadius: '6px',
        boxShadow: '0 4px 10px 0 rgba(36,36,36,0.16)',
        backgroundColor: '#ffffff',
        padding: '20px',
        width: '100%',
        height: '440px',
      }}
    >
      {!parseData ? (
        <Flex
          style={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Caption1>No data</Caption1>
        </Flex>
      ) : (
        <ResponsiveContainer width="100%" height={440}>
          <LineChart
            data={parseData}
            margin={{
              top: 0,
              right: 0,
              bottom: 50,
              left: 50,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="date"
              tickFormatter={tick => {
                const strTick = `${tick}`
                return `          ${strTick.slice(2, 4)}.${strTick.slice(
                  4,
                  6,
                )}.${strTick.slice(6, 8)}          `
              }}
              allowDuplicatedCategory={false}
              padding={{ left: 40, right: 40 }}
            />
            <YAxis
              dataKey="youtubeMusic"
              yAxisId="left"
              domain={['auto', 'auto']}
              tickFormatter={value => insightConverseUnitEng(value)}
            />
            <YAxis
              dataKey="youtubeVideo"
              yAxisId="right"
              orientation="right"
              domain={['auto', 'auto']}
              tickFormatter={value => insightConverseUnitEng(value)}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              verticalAlign="top"
              layout="horizontal"
              align="left"
              height="50px"
            />
            <Line
              dataKey="youtubeMusic"
              yAxisId="left"
              name="Listen(Youtube Music)"
              type="monotone"
              stroke={COLORS[0]}
            />
            <Line
              dataKey="youtubeVideo"
              yAxisId="right"
              name="View(Youtube)"
              type="monotone"
              stroke={COLORS[1]}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}

export default TrackYoutubeListenChart

const CustomTooltip = ({ active, payload, label: _label }) => {
  const item = payload && payload[0] && payload[0].payload && payload[0].payload
  const label = `${_label}`

  if (active) {
    return (
      <ToolTipBox type="column">
        <Caption2
          type="Bold"
          align="left"
          color="#646464"
          style={{ fontSize: 10 }}
        >
          {`${label.slice(0, 4)}년 ${label.slice(4, 6)}월 ${label.slice(
            6,
            8,
          )}일`}
        </Caption2>
        <Flex justify="space-between">
          <Flex align="center" style={{ gap: 4 }}>
            <div
              style={{
                display: 'inline-block',
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: COLORS[0],
              }}
            />
            <Caption2>Listen</Caption2>
            <Caption2 style={{ fontSize: 10 }} color="#949494">
              *Y.T Music
            </Caption2>
          </Flex>
          <Caption2 align="left">
            {`${item && convertToPricingComma(item.youtubeMusic)}`}
          </Caption2>
        </Flex>
        <Flex justify="space-between">
          <Flex align="center" style={{ gap: 4 }}>
            <div
              style={{
                display: 'inline-block',
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: COLORS[1],
              }}
            />
            <Caption2>View</Caption2>
            <Caption2 style={{ fontSize: 10 }} color="#949494">
              *Youtube
            </Caption2>
          </Flex>
          <Caption2 align="left">
            {`${item && convertToPricingComma(item.youtubeVideo)}`}
          </Caption2>
        </Flex>
      </ToolTipBox>
    )
  }
  return null
}
