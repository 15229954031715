import { Network } from '.'

export default class ConnectionsNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getConnectionsCompanies(companyUserId) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/connections/companies',
        'get',
        {
          companyUserId,
        },
      )
      return (data && data['data'] && data['data']['connectionInfo']) || null
    }
    catch (error) {
      console.warn('ConnectionsNetwork getConnectionsCompanies error', error)
    }
  }

  async getConnectionsArtists(artistId) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/connections/artists',
        'get',
        { artistId },
      )
      return (data && data['data'] && data['data']['connectionInfo']) || null
    }
    catch (error) {
      console.warn('ConnectionsNetwork getConnectionsArtists error', error)
    }
  }

  postConnectionsCompanies = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/connections/companies',
        'post',
        params,
      )
      return (data && data['data'] && data['data']['connectionInfo']) || null
    }
    catch (error) {
      console.warn('ConnectionsNetwork postConnectionsCompanies error', error)
    }
  }

  postConnectionsArtists = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/connections/artists',
        'post',
        params,
      )
      return (data && data['data'] && data['data']['connectionInfo']) || null
    }
    catch (error) {
      console.warn('ConnectionsNetwork postConnectionsArtists error', error)
    }
  }

  deleteConnection = async (type, id) => {
    if (!type || !id) return
    try {
      const { data } = await this.network._axiosApiAuth(
        `/connections/${type}/${id}`,
        'delete',
      )
      return (data && data['data'] && data['data']['connectionInfo']) || null
    }
    catch (error) {
      console.warn('ConnectionsNetwork deleteConnection error', error)
    }
  }

  deleteConnections = async (type, connectionsIdList) => {
    if (!type || !connectionsIdList) return
    try {
      const { data } = await this.network._axiosApiAuth(
        `/connections/${type}`,
        'delete',
        {
          connectionsIdList,
        },
      )
      return (data && data['data'] && data['data']['connectionInfo']) || null
    }
    catch (error) {
      console.warn('ConnectionsNetwork deleteConnections error', error)
    }
  }

  putConnection = async (type, id, params) => {
    if (!type || !id) return
    try {
      const { data } = await this.network._axiosApiAuth(
        `/connections/${type}/${id}`,
        'put',
        params,
      )
      return (data && data['data'] && data['data']['connectionInfo']) || null
    }
    catch (error) {
      console.warn('ConnectionsNetwork putConnection error', error)
    }
  }
}
