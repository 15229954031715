import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import { NoAuthEmailTemplate, RootTemplate } from '@components/templates'
import { Div } from '@components/atoms'
// import { defaultKakaoConsulting } from '@utils/kakao'
import moment from 'moment'
import ArtistInsightSettlementTemplate from '@components/templates/ArtistInsightSettlementTemplate'

const InsightSettlementMonthPage = () => {
  const location = useLocation()
  const history = useHistory()
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const [currentMonth, setCurrentMonth] = useState()
  const [filterDistributor, setFilterDistributor] = useState(null)
  const [filterCompanyId, setFilterCompanyId] = useState(null)

  const { authStore, settlementStore, artistStore } = useStore()

  const artist = artistStore.artistDetail || null
  const fetchSettlementArtistMonthly =
    settlementStore?.fetchSettlementArtistMonthly || (() => {})

  const fetchSettlementCompanyArtistMonthly =
    settlementStore?.fetchSettlementCompanyArtistMonthly || (() => {})

  const logout = authStore?.logout || (() => {})

  const { currentUser } = authStore
  const { artistMonthlyData } = settlementStore

  const handleMonth = value => {
    const updateMonth = moment(value)
    const displayMonth = updateMonth?.format('YYMM')

    setCurrentMonth(updateMonth)
    // history.push(`/insight/settlement/month?date=${displayMonth}`)
  }

  const handleFilterDistributor = value => {
    setFilterDistributor(value)
  }

  const handleFilterCompanyId = value => {
    setFilterCompanyId(value)
  }

  useEffect(() => {
    if (currentUser?.artistId && currentMonth) {
      if (currentUser?.companyId) {
        fetchSettlementCompanyArtistMonthly(
          currentUser?.companyId,
          currentUser?.artistId,
          {
            __month: currentMonth.format('YYMM'),
            __distributor: filterDistributor ?? null,
          },
        )
      }
      else {
        fetchSettlementArtistMonthly(currentUser?.artistId, {
          __month: currentMonth.format('YYMM'),
          __distributor: filterDistributor ?? null,
          __companyId: filterCompanyId ?? null,
        })
      }
    }
  }, [
    currentUser,
    fetchSettlementArtistMonthly,
    fetchSettlementCompanyArtistMonthly,
    currentMonth,
    filterDistributor,
    filterCompanyId,
  ])

  useEffect(() => {
    if (query?.date && !currentMonth) {
      setCurrentMonth(
        moment().set({
          year: `20${Math.floor(query?.date / 100)}`,
          month: (query?.date % 100) - 1,
        }),
      )
    }

    if (filterCompanyId !== query?.companyId) {
      handleFilterCompanyId(query?.companyId ?? null)
    }
  }, [query, currentMonth])

  // defaultKakaoConsulting.useKakaotalkConsulting()

  return (
    <div style={{ paddingBottom: 200 }}>
      {
currentUser?.isAuthEmail ? (
  <RootTemplate user={currentUser} artist={artist} logout={logout}>
    <ArtistInsightSettlementTemplate
      artistMonthlyData={artistMonthlyData}
      currentUser={currentUser}
      currentMonth={currentMonth}
      handleMonth={handleMonth}
      filterDistributor={filterDistributor}
      onFilterDistributor={handleFilterDistributor}
      filterCompanyId={filterCompanyId}
      onFilterCompany={handleFilterCompanyId}
    />
  </RootTemplate>
) : (
  <NoAuthEmailTemplate
    user={currentUser}
    emailAuth={authStore.emailAuth}
  />
)
      }
      {/* <defaultKakaoConsulting.KakaotalkConsulting /> */}
    </div>
  )
}

export default observer(InsightSettlementMonthPage)
