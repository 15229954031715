import styled from 'styled-components'
import { fullHD } from '@styles/media'
import { Flex } from './Flex'

export const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
  margin: 0px 60px 0px 40px;
  width: 100%;
  max-width: 1412px;
  /* min-width: 1230px; */

  @media ${fullHD} {
    margin: 0 60px 0 168px;
  }
`
