import { observable } from 'mobx'

export default class ModifyRecordModel {
  @observable _id
  @observable createdAt
  @observable message

  /* Database end */

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.createdAt = props.createdAt
      this.message = props.message
    }
  }
}
