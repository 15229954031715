import { observable } from 'mobx'
import TrackModel from './TrackModel'

export default class ArtistTrackTrendModel extends TrackModel {
  /* Database fields */

  @observable albumInfo
  @observable likeCountInfo
  @observable dailyDetailList
  @observable listenerInfo
  @observable rankInfo
  @observable trendDataAddedAt
  @observable youtubeVideoInfoList
  @observable youtubeDailyList
  @observable youtubeMusicUrl

  /* Database fields end */

  constructor(stores, props) {
    super(stores, props)

    this.albumInfo = props?.albumInfo
    this.likeCountInfo = props?.likeCountInfo
    this.dailyDetailList = props?.dailyDetailList
    this.listenerInfo = props?.listenerInfo
    this.rankInfo = props?.rankInfo
    this.trendDataAddedAt = props?.trendDataAddedAt
    this.youtubeVideoInfoList = props?.youtubeVideoInfoList
    this.youtubeDailyList = props?.youtubeDailyList
    this.youtubeMusicUrl = props?.youtubeMusicUrl
  }
}
