import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Input, Text } from '@components/atoms'
import Textarea from 'react-textarea-autosize'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'
import { StreamingButton } from '@components/molecules/icon/StramingButton'
import { AccountCustomUrlModal, ConfirmPopup } from '..'
import { inject } from 'mobx-react'

const Title_img = require('@images/title_img.png')

const StreamingContainer = styled(Flex)`
  flex-direction: column;
`
const StreamingContent = styled(Flex)`
  flex-direction: row;
  padding: 0 0 10px 0;
  flex-wrap: wrap;
`

const TrackTitle = styled(Text)`
  display: inline-block;
  tesxt-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  width: 100%;
  font-family: NotoSansCJKkr-Medium;
`

const TrackContent = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin: 34px 0px 0px 0px;
  padding: 20px 30px;
  border-radius: 6px;
  border: solid 1px #dddddd;
`
const ContentTitle = styled(Text)`
  padding: 0px 0px 15px 0px;
  font-size: 18px;
  font-family: 'NotoSansCJKkr-Medium';
`
const ContentItem = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  padding: 15px 0px 15px 0px;
  align-items: center;
  overflow-wrap: break-word;
  cursor: pointer;
`
const InfoItem = styled(Flex)`
  padding: 6px 0px;

  span:first-child {
    width: 66px;
  }
`

const SubMenu = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  width: 280px;
  height: fit-content;
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
`

const MenuList = styled.div`
  padding: 10px 16px;
  cursor: pointer;
  width: 152px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #242424;
`

const update_btn_img = require('@images/update_img_gray.png')
const copy_btn_img = require('@images/copy_img_gray.png')
const up_btn_img = require('@images/up_btn.png')
const down_btn_img = require('@images/down_btn.png')
const exist_subdomain_img = require('@images/exist_subdomain_img.png')

const _TrackInfo = ({
  authStore,
  artist,
  track,
  album,
  navigateDeleteTrack,
  navigateUpdateTrack,
  showCopyToast,
  checkCustomUrl,
  modifyCustomUrl,
}) => {
  const history = useHistory()

  const [more, setMore] = useState(false) //  트랙리스트 더보기
  const [showEdit, setShowEdit] = useState(false) // 편집 보기
  const [showUrlEdit, setShowUrlEdit] = useState(false) // URL 편집 보기

  const { isShowing, toggle } = AccountCustomUrlModal.useModal()
  const {
    isShowing: showConfirm,
    toggle: confirmToggle,
  } = ConfirmPopup.usePopup()

  const StreamingItem = ({ type }) => {
    return (
      <ContentItem onClick={() => window.open(track[`${type}`])}>
        <StreamingButton type={type} />
      </ContentItem>
    )
  }

  const isLoginedMaster = authStore.isAdminPassword

  return (
    <Flex type="column">
      <Flex
        style={{
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Flex
          style={{
            paddingRight: '20px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text color="#646464">{track && track.no}</Text>
        </Flex>
        <Flex type="column">
          <Flex
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '4px',
            }}
          >
            {track && track.isTitle && (
              <img
                src={Title_img}
                alt="title"
                style={{ width: '50px', height: '24px', marginRight: '8px' }}
              />
            )}
            <TrackTitle>{track && track.title}</TrackTitle>
          </Flex>
          <Text type="Medium" size="14px" color="#949494">
            {track.releaseArtistName}
          </Text>
        </Flex>
        <Flex style={{ marginLeft: 'auto' }}>
          <div
            style={{ position: 'relative' }}
            onClick={() => setShowUrlEdit(true)}
            onMouseOver={() => setShowUrlEdit(true)}
            onMouseLeave={() => setShowUrlEdit(false)}
          >
            <img
              src={
                track &&
                  track.subdomainInfo &&
                  track.subdomainInfo.subdomain &&
                  track.subdomainInfo.isAccepted &&
                  track &&
                  track.customUrlInfo &&
                  track.customUrlInfo.customUrl
                  ? exist_subdomain_img
                  : copy_btn_img
              }
              alt="copy"
              style={{
                width: '24px',
                height: '24px',
                marginRight: '20px',
                cursor: 'pointer',
              }}
            />
            {showUrlEdit && (
              <SubMenu>
                <MenuList style={{ width: '100%' }}>
                  <Input
                    style={{
                      width: '100%',
                      height: '32px',
                      border: 'solid 1px #d4d4d4',
                      backgroundColor: '#f4f4f4',
                      textAlign: 'center',
                    }}
                    value={track && track.trackUrl}
                    readOnly
                  />
                </MenuList>
                <CopyToClipboard
                  text={track && track.trackUrl}
                  onCopy={() => showCopyToast()}
                >
                  <MenuList>URL 복사</MenuList>
                </CopyToClipboard>
                {track &&
                  track.subdomainInfo &&
                  track.subdomainInfo.subdomain &&
                  track &&
                  track.customUrlInfo && (
                    // new Date(track.customUrlInfo.modifiableAt) <= new Date() && (
                    <MenuList onClick={() => toggle()}>
                      커스텀 URL 수정
                    </MenuList>
                  )
                  // )
                }
              </SubMenu>
            )}
          </div>
          <div
            style={{ position: 'relative' }}
            onClick={() => setShowEdit(true)}
            onMouseOver={() => setShowEdit(true)}
            onMouseLeave={() => setShowEdit(false)}
          >
            {
              isLoginedMaster && track && track.isOwner
              && (
                <img
                  src={update_btn_img}
                  alt="update"
                  style={{
                    width: '24px',
                    height: '24px',
                    marginRight: '20px',
                    cursor: 'pointer',
                  }}
                />
              )
            }
            {
              isLoginedMaster && track && track.isOwner && showEdit
              && (
                <SubMenu>
                  <MenuList onClick={() => navigateUpdateTrack(track, album)}>
                    트랙 수정
                  </MenuList>
                  <MenuList onClick={() => navigateDeleteTrack(track, album)}>
                    트랙 삭제
                  </MenuList>
                </SubMenu>
              )
            }
          </div>
          <div style={{ position: 'relative' }}>
            <img
              src={more ? up_btn_img : down_btn_img}
              alt="down_btn"
              style={{ cursor: 'pointer', width: '20px', height: '20px' }}
              onClick={() => setMore(!more)}
            />
          </div>
        </Flex>
      </Flex>
      {more && (
        <TrackContent>
          <Flex>
            <Flex type="column">
              <ContentTitle>트랙 정보</ContentTitle>
              <InfoItem>
                <Text size="14px" color="#646464">
                  작사
                </Text>
                <Text size="14px">
                  {track.lyricstArtistList &&
                    track.lyricstArtistList.map(
                      (lyricst, index) =>
                        (lyricst && index === track.lyricstArtistList.length - 1
                          ? lyricst.name
                          : `${lyricst.name}, `) || '',
                    )}
                </Text>
              </InfoItem>
              <InfoItem>
                <Text size="14px" color="#646464">
                  작곡
                </Text>
                <Text size="14px">
                  {track.composerArtistList &&
                    track.composerArtistList.map(
                      (composer, index) =>
                        (composer &&
                          index === track.composerArtistList.length - 1
                          ? composer.name
                          : `${composer.name}, `) || '',
                    )}
                </Text>
              </InfoItem>
              <InfoItem>
                <Text size="14px" color="#646464">
                  편곡
                </Text>
                <Text size="14px">
                  {track.arrangerArtistList &&
                    track.arrangerArtistList.map(
                      (arranger, index) =>
                        (arranger &&
                          index === track.arrangerArtistList.length - 1
                          ? arranger.name
                          : `${arranger.name}, `) || '',
                    )}
                </Text>
              </InfoItem>
            </Flex>
            <StreamingContainer style={{ marginLeft: 'auto', width: '50%' }}>
              <ContentTitle>음악 서비스</ContentTitle>
              <StreamingContent>
                {track.spotifyUrl && <StreamingItem type="spotifyUrl" />}
                {track.youtubeMusicUrl && (
                  <StreamingItem type="youtubeMusicUrl" />
                )}
                {track.melonUrl && <StreamingItem type="melonUrl" />}
                {track.genieUrl && <StreamingItem type="genieUrl" />}
                {track.bugsUrl && <StreamingItem type="bugsUrl" />}
                {track.vibeUrl && <StreamingItem type="vibeUrl" />}
                {track.floUrl && <StreamingItem type="floUrl" />}
                {track.appleMusicUrl && <StreamingItem type="appleMusicUrl" />}
                {track.amazonMusicUrl && (
                  <StreamingItem type="amazonMusicUrl" />
                )}
                {track.tidalUrl && <StreamingItem type="tidalUrl" />}
                {track.deezerUrl && <StreamingItem type="deezerUrl" />}
                {track.jooxUrl && <StreamingItem type="jooxUrl" />}
                {track.mymusicUrl && <StreamingItem type="mymusicUrl" />}
                {track.kkboxUrl && <StreamingItem type="kkboxUrl" />}
                {track.linejpUrl && <StreamingItem type="linejpUrl" />}
                {track.linetwUrl && <StreamingItem type="linetwUrl" />}
                {track.yandexUrl && <StreamingItem type="yandexUrl" />}
                {track.nctUrl && <StreamingItem type="nctUrl" />}
                {track.zingUrl && <StreamingItem type="zingUrl" />}
                {track.anghmiUrl && <StreamingItem type="anghmiUrl" />}
              </StreamingContent>
            </StreamingContainer>
          </Flex>

          <ContentTitle style={{ paddingTop: '30px' }}>가사</ContentTitle>
          <Textarea
            readOnly
            value={track.lyrics}
            minRows={1}
            maxRows={1000}
            className="lyric"
          />
        </TrackContent>
      )}
      <AccountCustomUrlModal.View
        isUpdate
        subdomain={
          artist && artist.subdomainInfo && artist.subdomainInfo.subdomain
        }
        isArtist={false}
        isShowing={isShowing}
        toggle={toggle}
        confirmToggle={confirmToggle}
        checkSubdomain={checkCustomUrl}
        modifyCustomUrl={(customUrl, dataType, dataObjectId) => {
          track.customUrl = customUrl
          return modifyCustomUrl(customUrl, dataType, dataObjectId)
        }}
        dataType="track"
        dataObjectId={
          track && track.customUrlInfo && track.customUrlInfo.dataObjectId
        }
      />
      <ConfirmPopup.View
        isShowing={showConfirm}
        toggle={confirmToggle}
        title={`커스텀 URL 수정 완료`}
      // body={`수정 후 60일에 한번씩 수정할 수 있습니다.`}
      // onClose={() => history.go(0)}
      />
    </Flex>
  )
}

export const TrackInfo = inject(
  'authStore',
)(_TrackInfo)
