import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { H4, H6, Flex } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: white;
  border-radius: 16px;
  overflow: scroll;
  min-width: 550px;

  @media ${mobile} {
    height: 199px;
    top: 40%;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const CancelBtn = styled.button`
  width: 230px;
  height: 48px;
  border-radius: 24px;
  border: solid 1px #ea4653;
  background-color: #ffffff;
  color: #ea4653;
  cursor: pointer;
`

const Okbtn = styled.button`
  width: 230px;
  height: 48px;
  border-radius: 24px;
  border: none;
  background-color: #242424;
  color: #ffffff;
  cursor: pointer;
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({ isShowing, toggle, onCancel, onOk, target, type }) => {
  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    toggle()
  }

  const handleOk = () => {
    if (onOk) {
      onOk()
    }
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <H4 type="Bold">관리 요청</H4>
              <Flex
                type="column"
                style={{ margin: '40px 0 20px 0' }}
                align="center"
              >
                <Flex>
                  <H6 type="Bold">{target && target.name}</H6>
                  <H6>에서 관리 아티스트 등록이 요청되었습니다.</H6>
                </Flex>
                <H6>
                  {
                    `해당 ${
                      type === 'artists' ? '아티스트' : '기업'
                    }의 요청을 승인할까요?`
                  }
                </H6>
              </Flex>
              <H6 type="Bold">{`역할 : ${target && target.role}`}</H6>
              <Flex
                style={{ justifyContent: 'space-between', marginTop: '32px' }}
              >
                <Okbtn onClick={handleOk}>승인</Okbtn>
                <CancelBtn onClick={handleCancel}>거부</CancelBtn>
              </Flex>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}
