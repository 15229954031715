import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Flex,
  Input,
  Text,
  TextArea,
  InvisibleInput,
  Div,
} from '@components/atoms'
import {
  Modal,
  ModalContent,
  ModalFooter,
  LinkInput,
  FormLabel,
  FormGroup,
  SubmitBtn,
  AddBtn,
  GenreBtn,
  SelectBox,
  CheckBox,
  SwitchBtn,
} from '@components/molecules'
import { AudioUploadForm } from '@components/organisms'

import { useForm } from 'react-hook-form'
import moment from 'moment'

import { SITE_LIST } from '@consts'
import AutocompleteArtist from './AutocompleteArtist'
import ReleaseArtistModel from './ReleaseArtistModel'
import AutocompleteCode from './AutocompleteCode'
import AutoCompleteCodeModel from './AutoCompleteCodeModel'
import MusicVideoUploadForm from '../Form/MusicVideoUploadForm'
import YoutubeUrlListInput from '../Card/YoutubeUrlListInput'
import AutocompleteTrendCompany from './AutocompleteTrendCompany'
import AutoCompleteCompanyModel from './AutoCompleteCompanyModel'

const GET_NO_SELECT_CONFIG_LIST = (start, end) => {
  const NO_SELECT_CONFIG_LIST = []
  for (let date = start; date <= end; date++) {
    NO_SELECT_CONFIG_LIST.push({ value: `${date}`, label: `${date}` })
  }
  return NO_SELECT_CONFIG_LIST
}
const NO_SELECT_CONFIG_LIST = GET_NO_SELECT_CONFIG_LIST(1, 50)

const MAIN_GENRE_SELECT_CONFIG_LIST = [
  { value: '발라드', label: '발라드' },
  { value: '댄스', label: '댄스' },
  { value: '랩/힙합', label: '랩/힙합' },
  { value: 'R&B/Soul', label: 'R&B/Soul' },
  { value: '팝', label: '팝' },
  { value: '인디', label: '인디' },
  { value: '록/메탈', label: '록/메탈' },
  { value: '트로트', label: '트로트' },
  { value: 'EDM/하우스', label: 'EDM/하우스' },
  { value: '포크/브루스', label: '포크/브루스' },
  { value: '클래식', label: '클래식' },
  { value: 'J-POP', label: 'J-POP' },
  { value: '일렉트로니카', label: '일렉트로니카' },
  { value: '재즈', label: '재즈' },
  { value: '뉴에이지', label: '뉴에이지' },
  { value: '종교음악', label: '종교음악' },
]
const SUB_GENRE_SELECT_CONFIG_LIST = [
  { value: '발라드', label: '발라드' },
  { value: '댄스', label: '댄스' },
  { value: '랩/힙합', label: '랩/힙합' },
  { value: 'R&B/Soul', label: 'R&B/Soul' },
  { value: '팝', label: '팝' },
  { value: '인디', label: '인디' },
  { value: '록/메탈', label: '록/메탈' },
  { value: '트로트', label: '트로트' },
  { value: 'EDM/하우스', label: 'EDM/하우스' },
  { value: '포크/브루스', label: '포크/브루스' },
  { value: '클래식', label: '클래식' },
  { value: '해외', label: '해외' },
  { value: '7080', label: '7080' },
  { value: '8090', label: '8090' },
  { value: '2000년대', label: '2000년대' },
  { value: 'J-POP', label: 'J-POP' },
  { value: '일렉트로니카', label: '일렉트로니카' },
  { value: '재즈', label: '재즈' },
  { value: '뉴에이지', label: '뉴에이지' },
  { value: '종교음악', label: '종교음악' },
]

const IS_REQUIRED = true

const Section = styled(Flex)`
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 0px;

  :first-child {
    padding-top: 0px;
  }
`

const ArtistList = styled(Flex)`
  width: 1170px;
  justify-content: flex-start;
  flex-wrap: wrap;

  > div:nth-child(odd) {
    margin-bottom: 6px;
    margin-right: 40px;
  }
`
const YoutubeUrlList = styled(Flex)`
  gap: 20px 40px;
  justify-content: flex-start;
  flex-wrap: wrap;
`
const ArtistList2 = styled(Flex)`
  width: 375px;
  justify-content: flex-start;
  flex-wrap: wrap;

  > div {
    margin-bottom: 16px;
    margin-right: 40px;
  }
`

const CodeList = styled(Flex)`
  width: 410px;
  justify-content: flex-start;
  flex-wrap: wrap;

  > div {
    margin-bottom: 6px;
  }
`

const LinkList = styled(Flex)`
  padding: 0px 0px 16px 0px;
  justify-content: space-between;
  flex-wrap: wrap;
`

const GenreList = styled(Flex)`
  flex-wrap: wrap;
  width: 1620px;
  justify-content: flex-start;
`

interface TrackFormModalProps {
  artist: Object;
  album: Object;
  show: Boolean;
  handleClose: () => void;
  defaultTrack: Object | null;

  createTrack: (album: Object) => Promise;
  updateTrack: (album: Object) => Promise;
  isUpdating: Boolean;
}

export const TrackFormModal = ({
  artist,
  album,
  show,
  handleClose,
  defaultTrack,

  createTrack,
  updateTrack,
  isUpdating,
}: TrackFormModalProps) => {
  const { register, handleSubmit, setValue, errors, watch } = useForm({
    defaultValues: {},
  })

  const [allRequired, setAllRequired] = useState(false) // 모든 필수 입력사항 체크
  const [audioOriginalPath, setAudioUrl] = useState(null) // 오디오 파일

  const [noValue, setNoValue] = useState('')
  const [mainGenreValue, setMainGenreValue] = useState(false)
  const [subGenreValue, setSubGenreValue] = useState(false)

  const [musicVideoInfoList, setMusicVideoInfoList] = useState([])

  const [releaseArtistList, setReleaseArtistList] = useState([])
  const appendReleaseArtistList = () => {
    setReleaseArtistList([...releaseArtistList, new ReleaseArtistModel()])
  }
  const removeReleaseArtistList = index => {
    const clonedReleaseArtistList = [...releaseArtistList]
    if (releaseArtistList && index >= 0 && index < releaseArtistList.length) {
      clonedReleaseArtistList.splice(index, 1)
      setReleaseArtistList(clonedReleaseArtistList)
    }
  }

  const [youtubeUrlObjList, setYoutubeUrlObjList] = useState([{ url: '' }])
  const appendYoutubeUrlObjList = () => {
    setYoutubeUrlObjList([...(youtubeUrlObjList || []), { url: '' }])
  }
  const removeYoutubeUrlObjList = index => {
    const clonedYoutubeUrlList = [...youtubeUrlObjList]
    if (youtubeUrlObjList && index >= 0 && index < youtubeUrlObjList.length) {
      clonedYoutubeUrlList.splice(index, 1)
      setYoutubeUrlObjList(clonedYoutubeUrlList)
    }
  }
  const updateYoutubeUrl = (index, youtubeUrl) => {
    setYoutubeUrlObjList(prev => {
      const clonedYoutubeUrlList = [...prev]
      if (youtubeUrlObjList && index >= 0 && index < youtubeUrlObjList.length) {
        clonedYoutubeUrlList[index].url = youtubeUrl
        return clonedYoutubeUrlList
      }
    })
  }

  const [featuringArtistList, setFeaturingArtistList] = useState([])

  const appendFeaturingArtistList = () => {
    setFeaturingArtistList([...featuringArtistList, new ReleaseArtistModel()])
  }
  const removeFeaturingArtistList = index => {
    const clonedFeaturingArtistList = [...featuringArtistList]
    if (
      featuringArtistList &&
      index >= 0 &&
      index < featuringArtistList.length
    ) {
      clonedFeaturingArtistList.splice(index, 1)
      setFeaturingArtistList(clonedFeaturingArtistList)
    }
  }

  const [lyricstArtistList, setLyricstArtistList] = useState([])
  const appendLyricstArtistList = () => {
    setLyricstArtistList([...lyricstArtistList, new ReleaseArtistModel()])
  }
  const removeLyricstArtistList = index => {
    const clonedLyricstArtistList = [...lyricstArtistList]
    if (lyricstArtistList && index >= 0 && index < lyricstArtistList.length) {
      clonedLyricstArtistList.splice(index, 1)
      setLyricstArtistList(clonedLyricstArtistList)
    }
  }

  const [composerArtistList, setComposerArtistList] = useState([])
  const appendComposerArtistList = () => {
    setComposerArtistList([...composerArtistList, new ReleaseArtistModel()])
  }
  const removeComposerArtistList = index => {
    const clonedComposerArtistList = [...composerArtistList]
    if (composerArtistList && index >= 0 && index < composerArtistList.length) {
      clonedComposerArtistList.splice(index, 1)
      setComposerArtistList(clonedComposerArtistList)
    }
  }

  const [arrangerArtistList, setArrangerArtistList] = useState([])
  const appendArrangerArtistList = () => {
    setArrangerArtistList([...arrangerArtistList, new ReleaseArtistModel()])
  }
  const removeArrangerArtistList = index => {
    const clonedArrangerArtistList = [...arrangerArtistList]
    if (arrangerArtistList && index >= 0 && index < arrangerArtistList.length) {
      clonedArrangerArtistList.splice(index, 1)
      setArrangerArtistList(clonedArrangerArtistList)
    }
  }

  // 유통사 앨범코드
  const [TrackCodeList, setTrackCodeList] = useState([
    new AutoCompleteCodeModel(),
  ])
  const appendTrackCodeList = () => {
    setTrackCodeList([...TrackCodeList, new AutoCompleteCodeModel()])
  }
  const removeTrackCodeList = index => {
    const clonedTrackCodeList = [...TrackCodeList]
    if (TrackCodeList && index >= 0 && index < TrackCodeList.length) {
      clonedTrackCodeList.splice(index, 1)
      setTrackCodeList(clonedTrackCodeList)
    }
  }

  // 트랙 데이터 연결
  const [trendCompanyIdList, setTrendCompanyIdList] = useState([
    new AutoCompleteCompanyModel(),
  ])
  const appendTrendCompanyIdList = () => {
    setTrendCompanyIdList(prev => [...prev, new AutoCompleteCompanyModel()])
  }
  const removeTrendCompanyIdList = index => {
    const clonedTrendCompanyIdList = [...trendCompanyIdList]
    if (trendCompanyIdList && index >= 0 && index < trendCompanyIdList.length) {
      clonedTrendCompanyIdList.splice(index, 1)
      setTrendCompanyIdList(clonedTrendCompanyIdList)
    }
  }

  useEffect(() => {
    setAllRequired(false)
    setAudioUrl(null)

    setMainGenreValue(false)
    setSubGenreValue(false)

    setReleaseArtistList(
      (defaultTrack &&
        defaultTrack.releaseArtistList &&
        defaultTrack.releaseArtistList.map(
          elem => new ReleaseArtistModel(elem),
        )) || [new ReleaseArtistModel(artist)],
    )

    if (defaultTrack && defaultTrack.youtubeUrlList?.length > 0) {
      setYoutubeUrlObjList(defaultTrack.youtubeUrlList.map(url => ({ url })))
    }
    else {
      setYoutubeUrlObjList([{ url: '' }])
    }

    setFeaturingArtistList(
      (defaultTrack &&
        defaultTrack.featuringArtistList &&
        defaultTrack.featuringArtistList.map(
          elem => new ReleaseArtistModel(elem),
        )) || [new ReleaseArtistModel()],
    )
    setLyricstArtistList(
      (defaultTrack &&
        defaultTrack.lyricstArtistList &&
        defaultTrack.lyricstArtistList.map(
          elem => new ReleaseArtistModel(elem),
        )) || [new ReleaseArtistModel()],
    )
    setComposerArtistList(
      (defaultTrack &&
        defaultTrack.composerArtistList &&
        defaultTrack.composerArtistList.map(
          elem => new ReleaseArtistModel(elem),
        )) || [new ReleaseArtistModel()],
    )
    setArrangerArtistList(
      (defaultTrack &&
        defaultTrack.arrangerArtistList &&
        defaultTrack.arrangerArtistList.map(
          elem => new ReleaseArtistModel(elem),
        )) || [new ReleaseArtistModel()],
    )
    setTrackCodeList(
      (defaultTrack &&
        defaultTrack.distributionCodeList &&
        defaultTrack.distributionCodeList.map(
          elem => new AutoCompleteCodeModel({ name: elem }),
        )) || [new AutoCompleteCodeModel()],
    )
    setTrendCompanyIdList(
      (defaultTrack &&
        defaultTrack.trendCompanyList &&
        defaultTrack.trendCompanyList.map(
          elem => new AutoCompleteCompanyModel(elem),
        )) || [new AutoCompleteCompanyModel()],
    )

    if (show && defaultTrack) {
      const defaultValues = { ...defaultTrack }

      if (defaultValues && defaultValues.releasedAt) {
        const releasedAtMoment = moment(defaultValues.releasedAt)
        defaultValues['year'] = `${releasedAtMoment.year()}`
        defaultValues['month'] = `${releasedAtMoment.month() + 1}`
        defaultValues['date'] = `${releasedAtMoment.date()}`
      }
      setAudioUrl(defaultValues && defaultValues.audioOriginalPath)

      setNoValue(defaultValues && defaultValues.no)
      setMainGenreValue(defaultValues && defaultValues.mainGenre)
      setSubGenreValue(defaultValues && defaultValues.subGenre)
      setMusicVideoInfoList(defaultValues && defaultValues.musicVideoInfoList)

      setValue(
        Object.keys(defaultValues).map(key => ({
          [key]: defaultValues[key],
        })),
      )
    }

    const defaultValues = { ...defaultTrack }
    // 트랙 번호 자동생성
    if (!(defaultValues && defaultValues.no)) {
      if (album && album.trackList.length > 0) {
        setValue(
          'no',
          String(
            Number(album && album.trackList[album.trackList.length - 1].no) + 1,
          ),
        )
        setNoValue(
          String(
            Number(album && album.trackList[album.trackList.length - 1].no) + 1,
          ),
        )
      } else {
        setValue('no', '1')
        setNoValue('1')
      }
    }
  }, [show, defaultTrack, setValue, artist, album])

  const selectNo = noConfig => {
    if (noConfig && noConfig.value) {
      setValue('no', noConfig.value)
      setNoValue(noConfig.value)
    }
  }
  const selectMainGenre = value => {
    setValue('mainGenre', value)
    setMainGenreValue(value)
  }

  const selectSubGenre = value => {
    setValue('subGenre', value)
    setSubGenreValue(value)
  }

  const onSubmit = async data => {
    const {
      isTitle,
      no,
      title,
      titleEng,
      youtubeUrl,
      ISRC,
      UCI,
      lyrics,

      //
      spotifyUrl,
      youtubeMusicUrl,
      melonUrl,
      genieUrl,
      bugsUrl,
      vibeUrl,
      floUrl,
      appleMusicUrl,
      amazonMusicUrl,
      tidalUrl,
      deezerUrl,
      jooxUrl,
      mymusicUrl,
      kkboxUrl,
      linejpUrl,
      linetwUrl,
      yandexUrl,
      nctUrl,
      zingUrl,
      anghmiUrl,

      isDisplaySpotifyUrl,
      isDisplayYoutubeMusicUrl,
      isDisplayMelonUrl,
      isDisplayGenieUrl,
      isDisplayBugsUrl,
      isDisplayVibeUrl,
      isDisplayFloUrl,
      isDisplayAppleMusicUrl,
      isDisplayAmazonMusicUrl,
      isDisplayTidalUrl,
      isDisplayDeezerUrl,
      isDisplayJooxUrl,
      isDisplayMymusicUrl,
      isDisplayKkboxUrl,
      isDisplayLinejpUrl,
      isDisplayLinetwUrl,
      isDisplayYandexUrl,
      isDisplayNctUrl,
      isDisplayZingUrl,
      isDisplayAnghmiUrl,
      //

      // featuringArtistIdList,
      // lyricstArtistIdList,
      // composerArtistIdList,
      // arrangerArtistIdList,

      ...others
    } = data
    if (!album) {
      return false
    }

    // if (!audioOriginalPath) {
    //   setAllRequired(true)
    //   return false
    // }

    if (
      spotifyUrl === '' &&
      youtubeMusicUrl === '' &&
      melonUrl === '' &&
      genieUrl === '' &&
      bugsUrl === '' &&
      vibeUrl === '' &&
      floUrl === '' &&
      appleMusicUrl === '' &&
      amazonMusicUrl === '' &&
      tidalUrl === '' &&
      deezerUrl === '' &&
      jooxUrl === '' &&
      mymusicUrl === '' &&
      kkboxUrl === '' &&
      linejpUrl === '' &&
      linetwUrl === '' &&
      yandexUrl === '' &&
      nctUrl === '' &&
      zingUrl === '' &&
      anghmiUrl === ''
    ) {
      // setAllRequired(true)
      // return false
    }

    // 입력 api
    const newTrackData = {
      isTitle,
      no,
      title,
      titleEng,
      youtubeUrl,
      audioOriginalPath,
      ISRC,
      UCI,
      lyrics,

      spotifyUrl,
      youtubeMusicUrl,
      melonUrl,
      genieUrl,
      bugsUrl,
      vibeUrl,
      floUrl,
      appleMusicUrl,
      amazonMusicUrl,
      tidalUrl,
      deezerUrl,
      jooxUrl,
      mymusicUrl,
      kkboxUrl,
      linejpUrl,
      linetwUrl,
      yandexUrl,
      nctUrl,
      zingUrl,
      anghmiUrl,
      isDisplaySpotifyUrl: JSON.parse(isDisplaySpotifyUrl || 'false'),
      isDisplayYoutubeMusicUrl: JSON.parse(isDisplayYoutubeMusicUrl || 'false'),
      isDisplayMelonUrl: JSON.parse(isDisplayMelonUrl || 'false'),
      isDisplayGenieUrl: JSON.parse(isDisplayGenieUrl || 'false'),
      isDisplayBugsUrl: JSON.parse(isDisplayBugsUrl || 'false'),
      isDisplayVibeUrl: JSON.parse(isDisplayVibeUrl || 'false'),
      isDisplayFloUrl: JSON.parse(isDisplayFloUrl || 'false'),
      isDisplayAppleMusicUrl: JSON.parse(isDisplayAppleMusicUrl || 'false'),
      isDisplayAmazonMusicUrl: JSON.parse(isDisplayAmazonMusicUrl || 'false'),
      isDisplayTidalUrl: JSON.parse(isDisplayTidalUrl || 'false'),
      isDisplayDeezerUrl: JSON.parse(isDisplayDeezerUrl || 'false'),
      isDisplayJooxUrl: JSON.parse(isDisplayJooxUrl || 'false'),
      isDisplayMymusicUrl: JSON.parse(isDisplayMymusicUrl || 'false'),
      isDisplayKkboxUrl: JSON.parse(isDisplayKkboxUrl || 'false'),
      isDisplayLinejpUrl: JSON.parse(isDisplayLinejpUrl || 'false'),
      isDisplayLinetwUrl: JSON.parse(isDisplayLinetwUrl || 'false'),
      isDisplayYandexUrl: JSON.parse(isDisplayYandexUrl || 'false'),
      isDisplayNctUrl: JSON.parse(isDisplayNctUrl || 'false'),
      isDisplayZingUrl: JSON.parse(isDisplayZingUrl || 'false'),
      isDisplayAnghmiUrl: JSON.parse(isDisplayAnghmiUrl || 'false'),

      ...others,

      // featuringArtistIdList: [{ _id: featuringArtistName, isPLAM: false }],
      // lyricstArtistIdList: [{ _id: lyricstArtistName, isPLAM: false }],
      // composerArtistIdList: [{ _id: composerArtistName, isPLAM: false }],
      // arrangerArtistIdList: [{ _id: arrangerArtistName, isPLAM: false }],

      releaseArtistIdList: (releaseArtistList || []).map(elem =>
        elem.toIdObj(),
      ),
      releaseArtistList,
      featuringArtistIdList: (featuringArtistList || []).map(elem =>
        elem.toIdObj(),
      ),
      featuringArtistList,
      lyricstArtistIdList: (lyricstArtistList || []).map(elem =>
        elem.toIdObj(),
      ),
      lyricstArtistList,
      composerArtistIdList: (composerArtistList || []).map(elem =>
        elem.toIdObj(),
      ),
      composerArtistList,
      arrangerArtistIdList: (arrangerArtistList || []).map(elem =>
        elem.toIdObj(),
      ),
      arrangerArtistList,

      distributionCodeList: TrackCodeList.map(elem => elem['name']),
      trendCompanyIdList: trendCompanyIdList.map(elem => elem['_id']),

      youtubeUrlList:
        youtubeUrlObjList?.map(obj => obj?.url)?.filter(elem => !!elem) || [],

      albumId:
        defaultTrack && defaultTrack.albumId ? defaultTrack.albumId : album._id,
      artistId:
        defaultTrack && defaultTrack.artistId
          ? defaultTrack.artistId
          : artist._id,

      musicVideoInfoList,
    }
    if (isUpdating) {
      await updateTrack(newTrackData)
    } else {
      await createTrack(newTrackData)
    }

    handleClose()
  }

  return (
    <Modal
      style={{ minHeight: '1504px', width: '1720px' }}
      show={show}
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <Flex type="column" style={{ width: '100%' }}>
            <Flex type="column" style={{ width: '100%' }}>
              <Section>
                <FormGroup>
                  <CheckBox text="타이틀" value="isTitle" _ref={register()} />
                </FormGroup>
                <FormGroup>
                  <FormLabel required>트랙 번호</FormLabel>
                  <InvisibleInput
                    name="no"
                    ref={register({ required: IS_REQUIRED })}
                  />
                  <SelectBox
                    options={NO_SELECT_CONFIG_LIST}
                    placeholder={'번호'}
                    value={
                      NO_SELECT_CONFIG_LIST.find(
                        noConfig => noValue && noConfig['value'] === noValue,
                      ) || null
                    }
                    onChange={selectNo}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel required>트랙 명</FormLabel>
                  <Input
                    style={{ width: '615px' }}
                    placeholder="트랙 명을 입력해주세요."
                    name={`title`}
                    ref={register({ required: IS_REQUIRED })}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>영문 트랙 명</FormLabel>
                  <Input
                    style={{ width: '615px' }}
                    placeholder="영문 트랙 명을 입력해주세요."
                    name={`titleEng`}
                    ref={register({ required: false })}
                  />
                </FormGroup>
              </Section>
              <Section style={{ justifyContent: 'initial', gap: 40 }}>
                <FormGroup>
                  <FormLabel required>음원 파일</FormLabel>
                  <AudioUploadForm
                    setAudioUrl={setAudioUrl}
                    audioOriginalPath={audioOriginalPath}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel required>아티스트 명</FormLabel>
                  <ArtistList>
                    {releaseArtistList.map((item, index) => {
                      return (
                        <div
                          key={`track-release-artist-${index}`}
                          style={{ position: 'relative' }}
                        >
                          <AutocompleteArtist
                            value={releaseArtistList[index]}
                            setArtist={autocompleteArtist => {
                              const clonedReleaseArtistList = [
                                ...releaseArtistList,
                              ]
                              Object.keys(
                                clonedReleaseArtistList[index],
                              ).forEach(key => {
                                clonedReleaseArtistList[index][key] =
                                  autocompleteArtist[key]
                              })
                              setReleaseArtistList(clonedReleaseArtistList)
                            }}
                            index={index}
                            keyword={'artist'}
                            isDisableRemove={index === 0}
                            onClickRemove={() => removeReleaseArtistList(index)}
                          />
                        </div>
                      )
                    })}
                    <AddBtn onClick={() => appendReleaseArtistList()} />
                  </ArtistList>
                </FormGroup>
              </Section>
              <Section>
                <FormGroup style={{ width: '100%' }}>
                  <FormLabel>뮤직 비디오 업로드</FormLabel>
                  <MusicVideoUploadForm
                    setMusicVideoInfoList={setMusicVideoInfoList}
                    musicVideoInfoList={musicVideoInfoList}
                  />
                </FormGroup>
              </Section>
              <FormGroup>
                <FormLabel>유튜브 링크</FormLabel>
                <YoutubeUrlList>
                  {youtubeUrlObjList?.map((item, index) => {
                    return (
                      <YoutubeUrlListInput
                        key={`track-youtube-list-${index}`}
                        value={item}
                        sortIndex={index}
                        updateUrl={updateYoutubeUrl}
                        removeUrlObj={removeYoutubeUrlObjList}
                        sortPrevUrlObj={() => {
                          setYoutubeUrlObjList(prev => {
                            const clonedYoutubeUrlList = [...prev]
                            if (index === 0) {
                              const [first, ...rest] = clonedYoutubeUrlList
                              clonedYoutubeUrlList.length = 0
                              clonedYoutubeUrlList.push(...rest, first)
                            } else {
                              const temp = clonedYoutubeUrlList[index]
                              clonedYoutubeUrlList[index] =
                                clonedYoutubeUrlList[index - 1]
                              clonedYoutubeUrlList[index - 1] = temp
                            }

                            return clonedYoutubeUrlList
                          })
                        }}
                        sortNextUrlObj={() => {
                          setYoutubeUrlObjList(prev => {
                            const clonedYoutubeUrlList = [...prev]
                            if (index === youtubeUrlObjList.length - 1) {
                              const last = clonedYoutubeUrlList.pop()
                              clonedYoutubeUrlList.unshift(last)
                            } else {
                              const temp = clonedYoutubeUrlList[index]
                              clonedYoutubeUrlList[index] =
                                clonedYoutubeUrlList[index + 1]
                              clonedYoutubeUrlList[index + 1] = temp
                            }

                            return clonedYoutubeUrlList
                          })
                        }}
                      />
                      // <div
                      //   key={`track-youtube-list-${index}`}
                      //   style={{ position: 'relative' }}
                      // >
                      //   <Input
                      //     style={{ width: '375px' }}
                      //     placeholder="Url 링크를 입력해주세요."
                      //     value={item}
                      //     onChange={e => {
                      //       updateYoutubeUrl(index, e.target.value)
                      //     }}
                      //   />
                      // </div>
                    )
                  })}
                  <AddBtn onClick={() => appendYoutubeUrlObjList()} />
                </YoutubeUrlList>
              </FormGroup>
              <Section style={{ justifyContent: 'flex-start' }}>
                {/* <FormGroup style={{ paddingRight: '40px' }}>
                  <FormLabel>유튜브 링크</FormLabel>
                  <Input
                    style={{ width: '565px' }}
                    placeholder="Url 링크를 입력해주세요."
                    name="youtubeUrl"
                    ref={register()}
                  />
                </FormGroup> */}
                <FormGroup style={{ paddingRight: '40px' }}>
                  <FormLabel>ISRC 코드</FormLabel>
                  <Input
                    style={{ width: '410px' }}
                    placeholder="ISRC 코드를 입력해주세요."
                    name="ISRC"
                    ref={register()}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>UCI 코드</FormLabel>
                  <Input
                    style={{ width: '410px' }}
                    placeholder="UCI 코드를 입력해주세요."
                    name="UCI"
                    ref={register()}
                  />
                </FormGroup>
              </Section>
              <Section>
                <FormGroup>
                  <FormLabel>피처링</FormLabel>
                  <ArtistList2>
                    {featuringArtistList.map((item, index) => {
                      return (
                        <div
                          key={`track-featuring-artist-${index}`}
                          style={{ position: 'relative' }}
                        >
                          <AutocompleteArtist
                            value={featuringArtistList[index]}
                            setArtist={autocompleteArtist => {
                              const clonedFeaturingArtistList = [
                                ...featuringArtistList,
                              ]
                              Object.keys(
                                clonedFeaturingArtistList[index],
                              ).forEach(key => {
                                clonedFeaturingArtistList[index][key] =
                                  autocompleteArtist[key]
                              })
                              setFeaturingArtistList(clonedFeaturingArtistList)
                            }}
                            index={index}
                            keyword="featuring"
                            isDisableRemove={index === 0}
                            onClickRemove={() =>
                              removeFeaturingArtistList(index)
                            }
                            inputWidth={375}
                          />
                        </div>
                      )
                    })}
                    <AddBtn onClick={() => appendFeaturingArtistList()} />
                  </ArtistList2>
                </FormGroup>

                <FormGroup>
                  <FormLabel>작사</FormLabel>
                  <ArtistList2>
                    {lyricstArtistList.map((item, index) => {
                      return (
                        <div
                          key={`track-lyricst-artist-${index}`}
                          style={{ position: 'relative' }}
                        >
                          <AutocompleteArtist
                            value={lyricstArtistList[index]}
                            setArtist={autocompleteArtist => {
                              const clonedLyricstArtistList = [
                                ...lyricstArtistList,
                              ]
                              Object.keys(
                                clonedLyricstArtistList[index],
                              ).forEach(key => {
                                clonedLyricstArtistList[index][key] =
                                  autocompleteArtist[key]
                              })
                              setLyricstArtistList(clonedLyricstArtistList)
                            }}
                            index={index}
                            keyword={'lyrics'}
                            isDisableRemove={index === 0}
                            onClickRemove={() => removeLyricstArtistList(index)}
                            inputWidth={375}
                          />
                        </div>
                      )
                    })}
                    <AddBtn onClick={() => appendLyricstArtistList()} />
                  </ArtistList2>
                </FormGroup>

                <FormGroup>
                  <FormLabel>작곡</FormLabel>
                  <ArtistList2>
                    {composerArtistList.map((item, index) => {
                      return (
                        <div
                          key={`track-composer-artist-${index}`}
                          style={{ position: 'relative' }}
                        >
                          <AutocompleteArtist
                            value={composerArtistList[index]}
                            setArtist={autocompleteArtist => {
                              const clonedComposerArtistList = [
                                ...composerArtistList,
                              ]
                              Object.keys(
                                clonedComposerArtistList[index],
                              ).forEach(key => {
                                clonedComposerArtistList[index][key] =
                                  autocompleteArtist[key]
                              })
                              setComposerArtistList(clonedComposerArtistList)
                            }}
                            index={index}
                            keyword={'composition'}
                            isDisableRemove={index === 0}
                            onClickRemove={() =>
                              removeComposerArtistList(index)
                            }
                            inputWidth={375}
                          />
                        </div>
                      )
                    })}
                    <AddBtn onClick={() => appendComposerArtistList()} />
                  </ArtistList2>
                </FormGroup>

                <FormGroup>
                  <FormLabel>편곡</FormLabel>
                  <ArtistList2>
                    {arrangerArtistList.map((item, index) => {
                      return (
                        <div
                          key={`track-arranger-artist-${index}`}
                          style={{ position: 'relative' }}
                        >
                          <AutocompleteArtist
                            value={arrangerArtistList[index]}
                            setArtist={autocompleteArtist => {
                              const clonedArrangerArtistList = [
                                ...arrangerArtistList,
                              ]
                              Object.keys(
                                clonedArrangerArtistList[index],
                              ).forEach(key => {
                                clonedArrangerArtistList[index][key] =
                                  autocompleteArtist[key]
                              })
                              setArrangerArtistList(clonedArrangerArtistList)
                            }}
                            index={index}
                            keyword={'arrangement'}
                            isDisableRemove={index === 0}
                            onClickRemove={() =>
                              removeArrangerArtistList(index)
                            }
                            inputWidth={375}
                          />
                        </div>
                      )
                    })}
                    <AddBtn onClick={() => appendArrangerArtistList()} />
                  </ArtistList2>
                </FormGroup>
              </Section>
            </Flex>

            <Section>
              <FormGroup>
                <FormLabel required="onlyOne">주 장르</FormLabel>
                <InvisibleInput
                  name="mainGenre"
                  ref={register({ required: IS_REQUIRED })}
                />
                <GenreList>
                  {MAIN_GENRE_SELECT_CONFIG_LIST.map(mainGenreSelectConfig => (
                    <GenreBtn
                      key={`main-genre-${mainGenreSelectConfig.value}`}
                      active={mainGenreValue === mainGenreSelectConfig.value}
                      onClick={() =>
                        selectMainGenre(mainGenreSelectConfig.value)
                      }
                    >
                      {mainGenreSelectConfig.label}
                    </GenreBtn>
                  ))}
                </GenreList>
              </FormGroup>
            </Section>
            <Section>
              <FormGroup>
                <FormLabel>
                  하위 장르
                  <Text color="red" size="14px">
                    {' '}
                    1개 선택
                  </Text>
                </FormLabel>
                <InvisibleInput name="subGenre" ref={register()} />
                <GenreList>
                  {SUB_GENRE_SELECT_CONFIG_LIST.map(subGenreSelectConfig => (
                    <GenreBtn
                      key={`sub-genre-${subGenreSelectConfig.value}`}
                      active={subGenreValue === subGenreSelectConfig.value}
                      onClick={() => {
                        subGenreValue === subGenreSelectConfig.value
                          ? selectSubGenre(null)
                          : selectSubGenre(subGenreSelectConfig.value)
                      }}
                    >
                      {subGenreSelectConfig.label}
                    </GenreBtn>
                  ))}
                </GenreList>
              </FormGroup>
            </Section>
            <Section style={{ paddingBottom: '0px' }}>
              <FormGroup>
                <FormLabel>가사</FormLabel>
                <TextArea
                  style={{
                    width: '410px',
                    height: '264px',
                    boxSizing: 'border-box',
                  }}
                  placeholder="가사를 입력해주세요."
                  name="lyrics"
                  ref={register()}
                />
                <FormLabel style={{ paddingTop: '29px' }}>
                  유통사 곡코드
                  <Text color="#949494" size="14px">
                    {' '}
                    * 중복 입력 가능
                  </Text>
                </FormLabel>
                <CodeList>
                  {TrackCodeList.map((item, index) => {
                    return (
                      <div
                        key={`album-planning-company-${index}`}
                        style={{ position: 'relative' }}
                      >
                        <AutocompleteCode
                          value={TrackCodeList[index]}
                          setValue={autocompleteCompany => {
                            const clonedTrackCodeList = [...TrackCodeList]
                            Object.keys(clonedTrackCodeList[index]).forEach(
                              key => {
                                clonedTrackCodeList[index][key] =
                                  autocompleteCompany[key]
                              },
                            )
                            setTrackCodeList(clonedTrackCodeList)
                          }}
                          index={`track_code_${index}`}
                          // isDisabled={index === 0}
                          isDisabledRemoveBtn={index === 0}
                          onClickRemove={() => removeTrackCodeList(index)}
                        />
                      </div>
                    )
                  })}
                  <AddBtn onClick={() => appendTrackCodeList()} />
                </CodeList>
                <FormLabel style={{ paddingTop: '29px' }}>
                  트랙 데이터 연결
                  <Text color="#949494" size="14px">
                    {' '}
                    * 인사이트 데이터
                  </Text>
                </FormLabel>
                <CodeList>
                  {trendCompanyIdList.map((item, index) => {
                    return (
                      <div
                        key={`track-company-${index}`}
                        style={{ position: 'relative' }}
                      >
                        <AutocompleteTrendCompany
                          value={trendCompanyIdList[index]}
                          setValue={autocompleteCompany => {
                            const clonedTrendCompanyIdList = [
                              ...trendCompanyIdList,
                            ]
                            Object.keys(
                              clonedTrendCompanyIdList[index],
                            ).forEach(key => {
                              clonedTrendCompanyIdList[index][key] =
                                autocompleteCompany[key]
                            })
                            setTrendCompanyIdList(clonedTrendCompanyIdList)
                          }}
                          index={`track_code_company_${index}`}
                          // isDisabled={index === 0}
                          onClickRemove={() => removeTrendCompanyIdList(index)}
                        />
                      </div>
                    )
                  })}
                  <AddBtn onClick={() => appendTrendCompanyIdList()} />
                </CodeList>
              </FormGroup>
              <FormGroup style={{ width: '1170px' }}>
                <FormLabel>음악 서비스</FormLabel>
                <LinkList>
                  {SITE_LIST.map(site => {
                    return (
                      <Div
                        key={site.name}
                        width="565px"
                        margin="8px 0"
                        display="flex"
                        alignItems="center"
                      >
                        <InvisibleInput
                          ref={register()}
                          name={site.displayName}
                        />
                        <SwitchBtn
                          id={site.displayName}
                          isSwitchOn={JSON.parse(
                            watch()[`${site.displayName}`] || 'false',
                          )}
                          handleSwitch={e => {
                            setValue(
                              `${site.displayName}`,
                              e?.currentTarget?.checked,
                            )
                          }}
                        />
                        <LinkInput
                          placeholder={site.text}
                          name={site.name}
                          ref={register()}
                          onChange={e => {
                            if (e?.currentTarget?.value !== '')
                              setValue(`${site.displayName}`, true)
                          }}
                        />
                      </Div>
                    )
                  })}
                </LinkList>
              </FormGroup>
            </Section>
          </Flex>
        </ModalContent>
        <ModalFooter>
          {allRequired ||
            errors.no ||
            errors.name ||
            errors.releaseArtistIdList ||
            errors.mainGenre ? (
            <Text
              type="Medium"
              size="14px"
              style={{ paddingBottom: '25px' }}
              color="#ea4653"
            >
              필수 입력 사항을 모두 입력해주세요.
            </Text>
          ) : (
            false
          )}
          <SubmitBtn type="submit">
            {isUpdating ? '수정하기' : '트랙 추가'}
          </SubmitBtn>
        </ModalFooter>
      </form>
    </Modal>
  )
}
