import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Flex, Text, Input, Button, H4 } from '@components/atoms'
import { FormLabel, CustomModal } from '@components/molecules'
import { useForm } from 'react-hook-form'
import DaumPostcode from 'react-daum-postcode'
import { colors } from '@colors/'
import { useStore } from '@utils/hooks'
import { STORAGE_URL } from '@consts'
import CompanyImageUploadForm from '../Form/CompanyImageUploadForm'

const ModalBody = styled(Flex)`
  width: 580px;
  height: 840px;
  padding: 30px;
  flex-direction: column;
  align-items: center;
`

const Form = styled.form`
  max-width: 518px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  width: 100%;
`

const RegisterBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: ${colors.main};
  color: #ffffff;

  :hover {
    background-color: ${colors.sub_main};
    border-color: ${colors.sub_main};
    transition-property: background-color, border-color;
    transition-duration: 0.2s;
    transition-timing-function: linear;
  }
`

const SearchAddressBtn = styled(Flex)`
  width 104px;
  height:40px;
  margin-left:8px;
  cursor:pointer;
  justify-content:center;
  align-items:center;
  border-radius: 7px;
  border: solid 1px #949494;
`

const View = ({ isShowing, toggle }) => {
  const { authStore } = useStore()

  const { currentUser } = authStore
  const updateUser = authStore?.updateUser || (() => {})

  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false)

  const [showSearchAddress, setShowSearchAddress] = useState(false)
  const [imageUrl, setImageUrl] = useState('')

  const { register, errors, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
    defaultValues: {
      companyName: currentUser?.companyName,
      companyRegistrationNumber: currentUser?.companyRegistrationNumber,
      zoneCode: currentUser?.address?.zoneCode,
      fullAddress: currentUser?.address?.fullAddress,
      fullAddressDetail: currentUser?.address?.fullAddressDetail,
      chiefName: currentUser?.chiefName,
      chiefPhone: currentUser?.chiefPhone,
      chiefEmail: currentUser?.chiefEmail,
      staffName: currentUser?.staffName,
      staffPhone: currentUser?.staffPhone,
      staffEmail: currentUser?.staffEmail,
    },
  })

  const idRef = useRef()

  useEffect(() => {
    if (idRef.current) {
      register(idRef.current)
      idRef.current.focus()
    }
  }, [register])

  const handleAddressComplete = data => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }

    if (data && data.zonecode) setValue('zoneCode', data.zonecode)
    setValue('fullAddress', fullAddress)
    setShowSearchAddress(false)
  }

  const onSubmit = async data => {
    const {
      account,
      password,
      companyName,
      companyRegistrationNumber,
      zoneCode,
      fullAddress,
      fullAddressDetail,

      chiefName,
      chiefEmail,
      chiefPhone,

      staffName,
      staffEmail,
      staffPhone,
    } = data

    const params = {
      type: 'company',
      account,
      password,
      companyName,
      address: {
        zoneCode,
        fullAddress,
        fullAddressDetail,
      },
      companyRegistrationNumber,

      chiefName,
      chiefEmail,
      chiefPhone,

      staffName,
      staffEmail,
      staffPhone,
    }

    if (imageUrl) params['profileImageOriginalPath'] = imageUrl

    const res = await updateUser(params)

    if (res) {
      const { error, isDuplicateEmail: _isDuplicateEmail } = res.data

      setIsDuplicateEmail(_isDuplicateEmail)

      if (!error && res.data.user) {
        window.location.reload()
      } else {
        alert('변경 실패')
      }
    }
  }

  return (
    <>
      <CustomModal.View isShowing={isShowing} toggle={toggle}>
        <ModalBody type="column">
          <H4 style={{ marginTop: 30 }}>사업자 정보 수정</H4>
          <Flex type="column">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <CompanyImageUploadForm
                  label={'앨범 자켓*'}
                  imageOriginalPath={
                    currentUser?.profileImage128Path &&
                    STORAGE_URL + currentUser?.profileImage128Path
                  }
                  setImageUrl={setImageUrl}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>회사 정보 *</FormLabel>
                <Input
                  type="text"
                  placeholder="회사명"
                  name="companyName"
                  ref={register({
                    required: true,
                  })}
                />
                <Input
                  style={{ marginTop: 20 }}
                  type="text"
                  placeholder="사업자등록번호 (-없이)"
                  name="companyRegistrationNumber"
                  ref={register({
                    required: true,
                  })}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>회사 주소 *</FormLabel>
                <Flex>
                  <Input
                    type="text"
                    placeholder="우편번호"
                    name="zoneCode"
                    style={{ width: 410 }}
                    ref={register({
                      required: true,
                    })}
                    onClick={() => setShowSearchAddress(true)}
                    disabled
                  />
                  <SearchAddressBtn onClick={() => setShowSearchAddress(true)}>
                    주소 검색
                  </SearchAddressBtn>
                </Flex>
                {showSearchAddress && (
                  <DaumPostcode
                    onComplete={handleAddressComplete}
                    height={424}
                    // autoClose
                    // autoResize
                    // animation
                    style={{
                      marginTop: 20,
                      border: 'solid 1px #242424',
                      borderRadius: 20,
                      padding: 10,
                      overflow: 'hidden',
                    }}
                  />
                )}
                <Input
                  style={{ marginTop: 20 }}
                  type="text"
                  placeholder="도로명 주소"
                  name="fullAddress"
                  ref={register({
                    required: true,
                  })}
                  onClick={() => setShowSearchAddress(true)}
                  disabled
                />
                <Input
                  style={{ marginTop: 20 }}
                  type="text"
                  placeholder="상세 주소"
                  name="fullAddressDetail"
                  ref={register({
                    required: true,
                  })}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>대표자 정보</FormLabel>
                <Input
                  type="text"
                  placeholder="대표자명"
                  name="chiefName"
                  ref={register()}
                />
                <Input
                  style={{ marginTop: 20 }}
                  type="text"
                  placeholder="대표자 연락처 ex. 01005260221 (-없이)"
                  name="chiefPhone"
                  ref={register({
                    // pattern: /^(?:(010-?\d{4})|(01[1|6|7|8|9]-?\d{3,4}))-?\d{4}$/,
                    pattern: /^(?:(010-?\d{4})|(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]|70)-?\d{3,4})|(01[1|6|7|8|9]-?\d{3,4}))-?\d{4}$/,
                  })}
                />
                {errors.chiefPhone && errors.chiefPhone.type === 'pattern' && (
                  <Text size="14px" type="Medium" color="#ea4653">
                    올바르지 않은 번호입니다.
                  </Text>
                )}
                <Input
                  style={{ marginTop: 20 }}
                  type="text"
                  placeholder="대표자 이메일 주소 입력"
                  autoComplete="none"
                  name="chiefEmail"
                  ref={register({
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                />
                {errors.chiefEmail && errors.chiefEmail.type === 'pattern' && (
                  <Text size="14px" type="Medium" color="#ea4653">
                    올바르지 않은 이메일 형식입니다.
                  </Text>
                )}
              </FormGroup>

              <FormGroup>
                <FormLabel>실무자 정보*</FormLabel>
                <Input
                  type="text"
                  placeholder="실무자명"
                  name="staffName"
                  ref={register({
                    required: true,
                  })}
                />
                <Input
                  style={{ marginTop: 20 }}
                  type="text"
                  placeholder="실무자 연락처 ex. 01005260221 (-없이)"
                  name="staffPhone"
                  ref={register({
                    required: true,
                    pattern: /^(?:(010-?\d{4})|(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]|70)-?\d{3,4})|(01[1|6|7|8|9]-?\d{3,4}))-?\d{4}$/,
                  })}
                />
                {errors.staffPhone && errors.staffPhone.type === 'pattern' && (
                  <Text size="14px" type="Medium" color="#ea4653">
                    올바르지 않은 번호입니다.
                  </Text>
                )}
                <Input
                  style={{ marginTop: 20 }}
                  type="text"
                  placeholder="실무자 이메일 주소 입력"
                  autoComplete="none"
                  name="staffEmail"
                  ref={register({
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                />
                {errors.staffEmail && errors.staffEmail.type === 'pattern' && (
                  <Text size="14px" type="Medium" color="#ea4653">
                    올바르지 않은 이메일 형식입니다.
                  </Text>
                )}
                {isDuplicateEmail && (
                  <Text size="14px" type="Medium" color="#ea4653">
                    중복된 이메일입니다.
                  </Text>
                )}
              </FormGroup>

              {/* <FormGroup>
          <Input
            style={{ width: '100%' }}
            type="text"
            placeholder="휴대폰 번호 ex. 01005260221 (-없이)"
            name="phone"
            ref={
              register({
                required: true,
                pattern: /^(?:(010-?\d{4})|(01[1|6|7|8|9]-?\d{3,4}))-?\d{4}$/,
              })
            }
          />
          {
            errors.phone && errors.phone.type === 'pattern' && (
              <Text size="14px" type="Medium" color="#ea4653">
              올바르지 않은 휴대폰 번호입니다.
              </Text>
            )
          }
        </FormGroup> */}
              <RegisterBtn type="submit">수정</RegisterBtn>
            </Form>
          </Flex>
        </ModalBody>
      </CustomModal.View>
    </>
  )
}

export default {
  View,
  useModal: CustomModal.useModal,
}
