import React from 'react'
import styled from 'styled-components'
import { Flex, Text, Button, Image, Container } from '@components/atoms'
import {
  AlbumInfo,
  AccountCustomUrlModal,
  ConfirmPopup,
} from '@components/organisms'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'
import { colors } from '@colors/'
import { inject, observer } from 'mobx-react'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
`

const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: #ffffff;
`

const HeaderBtnImg = styled(Image)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`

// const SubMenu = styled.div`
//   text-align: center;
//   position: absolute;
//   top: 40px;
//   right: 0;
//   z-index: 100;
//   width: 156px;
//   height: 57px;
//   border-radius: 6px;
//   box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
//   background-color: #ffffff;
//   padding: 7px;
// `

// const MenuList = styled.div`
//   width: 156px;

//   display: flex;
//   align-items: center;
//   justify-content: center;

//   color: #242424;
//   font-size: 12px;
// `

const _AlbumDetailTemplate = observer(({
  authStore,
  album,
  artist,
  checkCustomUrl,
  navigateUpdateAlbum,
  navigateUpdateTrack,
  navigateDeleteTrack,
  showCopyToast,
  modifyCustomUrl,
}) => {
  const history = useHistory()

  const url_copy_img = require('@images/copyUrl_img.png')
  const modify_img = require('@images/modify_img.png')

  // const [showModifiable, setShowModifiable] = useState(false)

  const { isShowing, toggle } = AccountCustomUrlModal.useModal()
  const {
    isShowing: showConfirm,
    toggle: confirmToggle,
  } = ConfirmPopup.usePopup()

  const isLoginedMaster = authStore.isAdminPassword

  return (
    <Container>
      <Header>
        <Text type="Medium" size="18px" style={{ marginTop: '10px' }}>
          나의 앨범 > {album && album.title}
        </Text>
        <Flex style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text type="Medium" size="18px">
            {album && album.albumUrl}
          </Text>
          <CopyToClipboard
            text={album && album.albumUrl}
            onCopy={() => showCopyToast()}
          >
            <HeaderBtn>
              <HeaderBtnImg src={url_copy_img} alt="header_btn" />
              <Text type="Medium" size="14px" color={colors.main}>
                URL 복사
              </Text>
            </HeaderBtn>
          </CopyToClipboard>
          {album && album.isOwner && (
            <>
              {
                isLoginedMaster
                && (
                  <HeaderBtn onClick={() => navigateUpdateAlbum(album)}>
                    <HeaderBtnImg src={modify_img} alt="header_btn" />
                    <Text type="Medium" size="14px" color={colors.main}>
                      페이지 수정
                    </Text>
                  </HeaderBtn>
                )
              }
              {album && album.subdomainInfo && album.subdomainInfo.subdomain
                ? album &&
                album.customUrlInfo && (
                  // new Date(album.customUrlInfo.modifiableAt) <= new Date() ? (
                  <HeaderBtn onClick={() => toggle()}>
                    <Text type="Medium" size="14px" color={colors.main}>
                      커스텀 URL 수정
                    </Text>
                  </HeaderBtn>
                )
                : // ) : (  // 트랙, 앨범 URL 수정 기간 60일 로직 제거
                //   <HeaderBtn
                //     style={{
                //       backgroundColor: '#f4f4f4',
                //       borderColor: '#f4f4f4',
                //       cursor: 'default',
                //       position: 'relative',
                //     }}
                //     onClick={() => setShowModifiable(true)}
                //     onMouseOver={() => setShowModifiable(true)}
                //     onMouseLeave={() => setShowModifiable(false)}
                //   >
                //     <Text type="Medium" size="14px" color="#949494">
                //       커스텀 URL 수정
                //     </Text>
                //     {showModifiable && (
                //       <SubMenu>
                //         <MenuList>수정 가능 기간이 아닙니다.</MenuList>
                //         <MenuList
                //           style={{ color: '#ea4653', marginTop: '7px' }}
                //         >{`${dateYMD(
                //           album &&
                //             album.customUrlInfo &&
                //             album.customUrlInfo.modifiableAt,
                //         )} 수정 가능`}</MenuList>
                //       </SubMenu>
                //     )}
                //   </HeaderBtn>
                // )
                false}
            </>
          )}
        </Flex>
      </Header>
      <Content>
        {album ? (
          <AlbumInfo
            artist={artist}
            album={album}
            navigateUpdateTrack={navigateUpdateTrack}
            navigateDeleteTrack={navigateDeleteTrack}
            showCopyToast={showCopyToast}
            checkCustomUrl={checkCustomUrl}
            modifyCustomUrl={modifyCustomUrl}
          />
        ) : (
          <div />
        )}
      </Content>
      <AccountCustomUrlModal.View
        isUpdate={true}
        subdomain={
          artist && artist.subdomainInfo && artist.subdomainInfo.subdomain
        }
        isArtist={false}
        isShowing={isShowing}
        toggle={toggle}
        confirmToggle={confirmToggle}
        checkSubdomain={checkCustomUrl}
        modifyCustomUrl={(customUrl, dataType, dataObjectId) => {
          album.customUrl = customUrl
          return modifyCustomUrl(customUrl, dataType, dataObjectId)
        }}
        dataType="album"
        dataObjectId={
          album && album.customUrlInfo && album.customUrlInfo.dataObjectId
        }
      />
      <ConfirmPopup.View
        isShowing={showConfirm}
        toggle={confirmToggle}
        title={`커스텀 URL 수정 완료`}
      // body={`수정 후 60일에 한번씩 수정할 수 있습니다.`}
      // onClose={() => history.go(0)}
      />
    </Container>
  )
})

export const AlbumDetailTemplate = inject(
  'authStore',
)(_AlbumDetailTemplate)
