import React from 'react'
import styled from 'styled-components'
import { Flex, Text, Button } from '@components/atoms'
import { colors } from '@colors/'

const PopupInner = styled.div`
  position: fixed;
  width: 328px;
  height: 150px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  left: 42vw;
  top: 30vh;
  background: #ffffff;
  z-index: 1000;
  border-radius: 16px;
  color: #ea4653;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Btn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 134px;
  height: 48px;
  border-radius: 24px;
  margin-top: 30px;
`

export const DeletePopup = ({ text, onCancel, onDelete }) => {
  return (
    <PopupInner>
      <Text type="Medium" size="16px">
        {text}
      </Text>
      <Flex>
        <Btn
          style={{
            border: '2px solid #f4f4f4',
            backgroundColor: '#f4f4f4',
            marginRight: '20px',
            color: '#242424',
          }}
          onClick={onCancel && onCancel}
        >
          취소
        </Btn>
        <Btn
          style={{
            border: `2px solid ${colors.main}`,
            backgroundColor: colors.main,
            color: '#ffffff',
          }}
          onClick={onDelete}
        >
          삭제
        </Btn>
      </Flex>
    </PopupInner>
  )
}
