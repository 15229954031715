import { computed, observable } from 'mobx'
import { ArtistModel } from '.'

export default class ArtistConnectionModel {
  /* Database fields */
  @observable _id

  @observable requesterId // 신청한 사업자 회원
  @observable requesterRole // 연결된 회사의 역활
  @observable targetId // 요청받은 아티스트 회원
  @observable isAccepted // 승인여부
  @observable isRejected // 거절여부

  @observable rejectedAt
  @observable createdAt
  @observable deletedAt
  @observable acceptedAt

  @observable targetArtistInfo
  @observable requesterArtistInfo

  @computed get isProgress() {
    return !(this.isAccepted || this.isRejected)
  }

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.requesterId = props.requesterId // 신청한 사업자 회원
      this.requesterRole = props.requesterRole // 연결된 회사의 역활
      this.targetId = props.targetId // 요청받은 아티스트 회원
      this.isAccepted = props.isAccepted // 승인여부
      this.isRejected = props.isRejected // 거절여부

      this.rejectedAt = props.rejectedAt
      this.createdAt = props.createdAt
      this.deletedAt = props.deletedAt
      this.acceptedAt = props.acceptedAt

      this.targetArtistInfo = new ArtistModel(stores, props.targetArtistInfo)
      this.requesterArtistInfo = new ArtistModel(
        stores,
        props.requesterArtistInfo,
      )
    }
  }
}
