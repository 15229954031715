import React from 'react'
import styled from 'styled-components'
import { Flex, Caption2, H6, Caption1 } from '@components/atoms'
import { convertToPricingComma } from '@utils/format'
import { IncreaseTextBox } from '@components/molecules'
import {
  SNS_SOURCES,
} from '@images/'

const Box = styled(Flex)`
  min-width: 158px;
  flex: 1;
  margin: 0 16px 0 0;
  height: 114px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  padding: 12px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
`
const LastBox = styled(Box)`
  margin: 0 0 0 0;
`

const SnsFollowerCard = ({
  followerData,
  increaseFollowerData,
  snsType,
  unit,
  onClick,
  link,
  isLast
}) => {
  const CBox = isLast ? LastBox : Box
  return (
    <>
      {!link || !followerData ? (
        <CBox
          style={{
            backgroundColor: '#f4f4f4',
            boxShadow: 'none',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'capitalize',
            flexDirection: 'column',
            gap: '20px',
            whiteSpace: 'pre-wrap',
            cursor: 'pointer',
          }}
        >
          <Caption2 color="#242424">
            {`${snsType}\n링크가 올바르지 않습니다.`}
          </Caption2>
          <Btn onClick={onClick}>링크 수정</Btn>
        </CBox>
      ) : (
        <CBox
          type="column"
          style={{
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          onClick={onClick}
        >
          <Flex>
            <img
              src={SNS_SOURCES[snsType] || false}
              alt="sns_img"
            />
            <Caption1
              color="#242424"
              type="Bold"
              style={{ alignSelf: 'center', textTransform: 'capitalize' }}
            >
              {snsType}
            </Caption1>
          </Flex>
          <Flex>
            <Flex type="column">
              <Caption2 align="left" color="#949494">
                Follower
              </Caption2>
              <H6 type="Bold" align="left">
                {`${convertToPricingComma(followerData || 0)} ${unit ? unit : '명'}`}
              </H6>
            </Flex>

            <IncreaseTextBox
              data={increaseFollowerData}
              style={{ alignSelf: 'flex-end', margin: '0 0 5px 10px' }}
            />
          </Flex>
        </CBox>
      )}
    </>
  )
}

export default SnsFollowerCard

const Btn = styled.button`
  display: flex;
  font-family: NotoSansCJKkr-Medium;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 32px;
  border-radius: 25px;
  cursor: pointer;
  background-color: #242424;
  border-color: #242424;
  color: #f4f4f4;
  font-size: 12px;
`
