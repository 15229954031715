import { Network } from '.'

export default class ArtistNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getArtist(artistId) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/artist/${artistId}`,
        'get',
      )
      const artist = data && data['data'] && data['data']['artist']
      return artist || null
    } catch (error) {
      console.warn('ArtistNetwork getArtist error', error)
      return error.response
    }
  }

  async postArtist(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/artist',
        'post',
        params,
      )
      const artist = data && data['data'] && data['data']['artist']
      return artist || null
    } catch (error) {
      console.warn('ArtistNetwork postArtist error', error)
    }
  }

  async putArtist(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/artist',
        'put',
        params,
      )
      const album = data && data['data'] && data['data']['artist']
      return album || null
    } catch (error) {
      console.warn('ArtistNetwork putArtist error', error)
      return error.response
    }
  }

  async getStageNameList(query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/stage-name`,
        'get',
        query,
      )
      const artistList = data && data['data'] && data['data']['stageNameList']
      return artistList || []
    } catch (error) {
      console.warn('ArtistNetwork getStageNameList error', error)
    }
  }

  /* body : artistId, name */
  async postStageName(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/stage-name',
        'post',
        params,
      )
      const stageName = data && data['data'] && data['data']['stageName']
      return stageName || null
    } catch (error) {
      console.warn('ArtistNetwork postStageName error ', error)
    }
  }

  /* body : stageNameId, name */
  async putStageName(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/stage-name',
        'put',
        params,
      )
      const stageName = data && data['data'] && data['data']['stageName']
      return stageName || null
    } catch (error) {
      console.warn('ArtistNetwork putStageName error ', error)
    }
  }

  /* body : stageNameId */
  async deleteStageName(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/stage-name',
        'delete',
        params,
      )
      const stageName = data && data['data'] && data['data']['stageName']
      return stageName || null
    } catch (error) {
      console.warn('ArtistNetwork deleteStageName error ', error)
    }
  }

  getModifyRecords = async (artistId: string, params: any) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/modify-records/${artistId}`,
        'get',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('ArtistNetwork getModifyRecords error', error)
    }
  }

  async getSmartLinkList(artistObjectId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/artist/${artistObjectId}/smart-link`,
        'get',
        query,
      )
      const smartLinkList =
        data && data['data'] && data['data']['smartLinkList']
      return smartLinkList || []
    } catch (error) {
      console.warn('ArtistNetwork getSmartLinkList error', error)
    }
  }

  /* body : artistId, name */
  async postSmartLink(artistObjectId, params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/artist/${artistObjectId}/smart-link`,
        'post',
        params,
      )
      const smartLink = data && data['data'] && data['data']['smartLinkList']
      return smartLink || null
    } catch (error) {
      console.warn('ArtistNetwork postSmartLink error ', error)
    }
  }

  /* body : smartLinkId, name */
  async putSmartLink(artistObjectId, smartLinkObjectId, params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/artist/${artistObjectId}/smart-link/${smartLinkObjectId}`,
        'put',
        params,
      )
      const smartLink = data && data['data'] && data['data']['smartLink']
      return smartLink || null
    } catch (error) {
      console.warn('ArtistNetwork putSmartLink error ', error)
    }
  }

  /* body : smartLinkId */
  async deleteSmartLink(artistObjectId, smartLinkObjectId) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/artist/${artistObjectId}/smart-link/${smartLinkObjectId}`,
        'delete',
      )
      const smartLink = data && data['data'] && data['data']['smartLink']
      return smartLink || null
    } catch (error) {
      console.warn('ArtistNetwork deleteSmartLink error ', error)
    }
  }
}
