import React, { useState } from 'react'
import { Button } from 'antd'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  &.ant-btn {
    border: none;
    border-radius: 5px; // 버튼의 모서리를 둥글게 합니다.
    margin-right: 5px; // 버튼 사이의 여백을 제공합니다.
    margin-bottom: 5px;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
    &.selected {
      background-color: black;
      color: white;
    }
    &:not(.selected) {
      background-color: #f0f0f0;
      color: gray;
    }
  }
`

export const AdjustmentTypeHeader = ({
  typeList = [],
  selectedTypes = [],
  onClick = () => {},
}) => {
  return (
    <div
      style={
        {
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
        }
      }
    >
      <StyledButton
        key={'전체'}
        className={selectedTypes.length === 0 ? 'selected' : ''}
        onClick={() => onClick('전체')}
      >
        {'전체'}
      </StyledButton>
      {
        typeList.map(button => (
          <StyledButton
            key={button.value}
            className={selectedTypes.includes(button.value) ? 'selected' : ''}
            onClick={() => onClick(button.value)}
          >
            {button.label}
          </StyledButton>
        ))
      }
    </div>
  )
}
