import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Flex,
  Text,
  Container,
  Button,
  Image,
  H5,
  H3,
  H6,
} from '@components/atoms'
import { RevenueUploadModal } from '@components/organisms'
import { colors } from '@colors/'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import moment from 'moment'
import 'moment/locale/ko'
import { inject, observer } from 'mobx-react'
import { add_btn } from '@images/'
import { STORAGE_URL } from '@consts/'
import AlbumSettlementAnnualChart from '@components/organisms/Chart/AlbumSettlementAnnualChart'
import CompanyArtistAlbumSettlementAnnualChart from '@components/organisms/Chart/CompanyArtistAlbumSettlementAnnualChart'
import CompanyAlbumSettlementAnnualChart from '@components/organisms/Chart/CompanyAlbumSettlementAnnualChart'

const copy_btn_img = require('@images/copy_img_gray.png')

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 0 120px 0;
`

const InsightSettlementAlbumTemplate = ({
  authStore,
  type,
  albumAnnualData,
}) => {
  const [showUpload, setShowUpload] = useState(false)

  const customUrlInfo = albumAnnualData?.customUrlInfo || null

  moment.locale('ko')

  const isLoginedMaster = authStore.isAdminPassword

  return (
    <Container>
      <Header>
        <Text type="Medium" size="18px" style={{ marginTop: '10px' }}>
          앨범별 정산
        </Text>
        {
          isLoginedMaster && (
            <HeaderBtn onClick={() => setShowUpload(true)}>
              <HeaderBtnImg src={add_btn} alt="add_btn" />
              <Text type="Medium" size="14px" color={colors.main}>
              업로드
              </Text>
            </HeaderBtn>
          )
        }
      </Header>
      <Content>
        <Flex style={{ marginBottom: 50 }}>
          <Flex type="column">
            <Flex>
              <Image
                src={`${STORAGE_URL}${albumAnnualData?.image512Path}`}
                width="160px"
                height="160px"
                borderRadius="6px"
                marginRight="30px"
              />
              <Flex type="column">
                <H5 align="left" color={colors.grey94}>
                  {albumAnnualData?.title}
                </H5>
                <H3 align="left">{albumAnnualData?.title}</H3>
                <Flex style={{ marginTop: 4 }}>
                  <H6>{`권리사 : ${albumAnnualData?.planningCompanyList[0]?.name}`}</H6>
                  <H6>
                    &nbsp;&nbsp;&nbsp;
                    {`유통사 : ${albumAnnualData?.releaseCompanyList[0]?.name}`}
                  </H6>
                </Flex>
                <a
                  href={`https://plam.in/album/${customUrlInfo?.dataObjectId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Flex
                    align="center"
                    justify="center"
                    style={
                      {
                        width: 28,
                        height: 28,
                        backgroundColor: '#f4f4f4',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        marginTop: 12,
                      }
                    }
                  >
                    <img
                      src={copy_btn_img}
                      alt="copy"
                      style={
                        {
                          width: '20px',
                          height: '20px',
                        }
                      }
                    />
                  </Flex>
                </a>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {
          type === 'company' ? (
            <CompanyAlbumSettlementAnnualChart />
          ) : type === 'company-artist' ? (
            <CompanyArtistAlbumSettlementAnnualChart />
          ) : (
            <AlbumSettlementAnnualChart />
          )
        }
      </Content>
      <RevenueUploadModal
        show={showUpload}
        handleClose={() => setShowUpload(false)}
      />
    </Container>
  )
}

export default inject('authStore')(observer(InsightSettlementAlbumTemplate))

const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: #ffffff;
`

const HeaderBtnImg = styled(Image)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`
