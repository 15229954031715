import {
  Button,
  Caption1,
  Caption2,
  Flex,
  H4,
  H6,
  Image,
} from '@components/atoms'
import React, { useState } from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { ConnectArtistModal, OkCancelPopup } from '..'

const ADD_BTN = require('@images/add_btn.png')

const ArtistCompanyConnectionListItem = ({
  item,
  userArtistId,
  openDeletePopup,
  isOwner,
}) => {
  return (
    <Flex align="center">
      <H6 type="Regular" color={colors.black} style={{ marginRight: 8 }}>
        {`${item?.companyInfo?.companyName} (${item?.requesterRole})`}
      </H6>
      {isOwner && (
        <Caption2
          color={colors.lightish_red}
          onClick={() => openDeletePopup(item, 'companies')}
          style={{ cursor: 'pointer' }}
        >
          삭제
        </Caption2>
      )}
    </Flex>
  )
}

const ArtistConnectionListItem = ({
  item,
  userArtistId,
  openDeletePopup,
  fetchArtistDetail,
  isOwner,
  history,
}) => {
  return (
    <Flex align="center">
      <H6
        type="Regular"
        color={item.isAccepted ? '#1982df' : colors.black}
        style={{
          marginRight: 8,
          cursor: item.isAccepted ? 'pointer' : 'initial',
        }}
        onClick={() => {
          if (item.isAccepted) {
            // fetchArtistDetail(
            //   item.targetId === userArtistId ? item.requesterId : item.targetId,
            // )
            history.push(
              `artist?artistId=${
                item.targetId === userArtistId
                  ? item.requesterId
                  : item.targetId
              }`,
            )
          }
        }}
      >
        {item.targetId === userArtistId
          ? `${item.requesterArtistInfo.name} (${item.requesterArtistInfo.type})`
          : `${item.targetArtistInfo.name} (${item.targetArtistInfo.type})`}
      </H6>
      {isOwner && (
        <Caption2
          color={colors.lightish_red}
          onClick={() => openDeletePopup(item, 'artists')}
          style={{ cursor: 'pointer' }}
        >
          삭제
        </Caption2>
      )}
    </Flex>
  )
}

const ArtistConnectionInfo = ({
  artistCompanyConnectionList,
  artistConnectionList,
  user,
  artist,
  removeConnection,
  createConnectionsArtists,
  fetchArtistDetail,
}) => {
  const history = useHistory()
  const { isShowing, toggle: deleteToggle } = OkCancelPopup.usePopup()
  const [targetItem, setTargetItem] = useState({})

  const {
    isShowing: addShowing,
    toggle: addToggle,
  } = ConnectArtistModal.useModal()

  const openDeletePopup = (target, type) => {
    if (!target) return

    setTargetItem({
      id: target._id,
      name: target.companyInfo
        ? target.companyInfo.companyName
        : target.targetId === user.artistId
        ? target.requesterArtistInfo.name
        : target.targetArtistInfo.name,
      type,
    })

    deleteToggle()
  }

  return (
    <Flex
      type="column"
      style={{
        borderTop: 'solid 1px black',
        paddingTop: 24,
        marginRight: 48,

        marginBottom: 100,
      }}
    >
      <Flex justify="space-between" align="center">
        <H4>연결유저</H4>
        {artist && artist.isOwner && (
          <HeaderBtn onClick={addToggle}>
            <HeaderBtnImg src={ADD_BTN} alt="header_btn" />
            <Caption1 color={colors.main}>추가</Caption1>
          </HeaderBtn>
        )}
      </Flex>
      {artistCompanyConnectionList && artistCompanyConnectionList.length > 0 && (
        <Flex style={{ marginBottom: 24 }}>
          <H6
            type="Regular"
            color={colors.brownish_grey}
            style={{ marginRight: 16 }}
          >
            회&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;사
          </H6>
          <Flex type="column" style={{ gap: 16 }}>
            {artistCompanyConnectionList.map(item => (
              <ArtistCompanyConnectionListItem
                item={item}
                key={item._id}
                userArtistId={user && user.artistId}
                openDeletePopup={openDeletePopup}
                isOwner={artist && artist.isOwner}
              />
            ))}
          </Flex>
        </Flex>
      )}
      {artistConnectionList && artistConnectionList.length > 0 && (
        <Flex>
          <H6
            type="Regular"
            color={colors.brownish_grey}
            style={{ marginRight: 16 }}
          >
            아티스트
          </H6>
          <Flex type="column">
            {artistConnectionList.map(item => (
              <ArtistConnectionListItem
                item={item}
                key={item._id}
                userArtistId={user && user.artistId}
                openDeletePopup={openDeletePopup}
                fetchArtistDetail={fetchArtistDetail}
                isOwner={artist && artist.isOwner}
                history={history}
              />
            ))}
          </Flex>
        </Flex>
      )}
      <OkCancelPopup.View
        isShowing={isShowing}
        toggle={deleteToggle}
        body={
          <>
            <H6 color="#949494">{`${targetItem.name} 을/를`}</H6>
            <H6 color="#ea4653">연결유저에서 삭제합니다</H6>
          </>
        }
        okText="삭제"
        onOk={() => {
          removeConnection(targetItem.type, targetItem.id)
        }}
      />
      <ConnectArtistModal.View
        isShowing={addShowing}
        toggle={addToggle}
        userArtistId={user && user.artistId}
        createConnectionsArtists={createConnectionsArtists}
      />
    </Flex>
  )
}

export default ArtistConnectionInfo

const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: #ffffff;
`

const HeaderBtnImg = styled(Image)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`
