import React, { useState, useRef, useEffect } from 'react'
import { Table, Input, Space, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { convertToPricingComma } from '@utils/format'
import { isArray } from 'lodash'
import { Link, useParams } from 'react-router-dom'
import { Caption1, Caption2, Flex, Text } from '@components/atoms'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import moment from 'moment'
import styled from 'styled-components'
import { colors } from '@colors/'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { STORAGE_URL } from '@consts/'
import MonthlyPicker from '../Card/MonthlyPicker'

const copy_btn_img = require('@images/copy_img_gray.png')
const Title_img = require('@images/title_img.png')

const RevenueAlbumDetailTableChart = ({ isCompany }) => {
  const params = useParams()

  const { revenueStore, authStore } = useStore()

  const { currentUser } = authStore
  const {
    albumRevenueData,
    albumTrackRevenueData: data,
    albumRecentMonth,
    revenueType,
  } = revenueStore

  const fetchRevenueAlbumTrackData =
    revenueStore.fetchRevenueAlbumTrackData || (() => {})

  const [currentMonth, setCurrentMonth] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const searchInput = useRef(null)

  const monthlyDataList = albumRevenueData?.revenueInfo?.albumMonthlyRevenueInfo

  const isCompanyData = currentUser?.type === 'company' && isCompany

  // 검색 기능
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={
            e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (isArray(dataIndex)) {
        return record[dataIndex[0]][dataIndex[1]]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }
  // --end 검색기능

  const handleMonth = value => {
    setCurrentMonth(moment(value))
  }

  useEffect(() => {
    if (albumRecentMonth) {
      setCurrentMonth(
        moment().set({
          year: `20${Math.floor(albumRecentMonth / 100)}`,
          month: (albumRecentMonth % 100) - 1,
        }),
      )
    }
  }, [albumRecentMonth])

  useEffect(() => {
    if (currentUser) {
      if (currentMonth && params?.id) {
        fetchRevenueAlbumTrackData({
          __albumId: params?.id,
          __month: currentMonth.format('YYMM'),
          __revenueType: revenueType ? revenueType : null,
        })
      }
    }
  }, [
    params,
    currentUser,
    currentMonth,
    fetchRevenueAlbumTrackData,
    revenueType,
  ])

  const sortHandler = (a, b, sortOrder) => {
    if (a < b) {
      return -1
    }
    else if (a > b) {
      return 1
    }
    else {
      return 0
    }
  }

  return (
    <Flex type="column">
      <Flex align="center" style={{ gap: 12, marginBottom: 24 }}>
        <SectionTitle>
          <MonthlyPicker
            currentMonth={currentMonth}
            handleMonth={handleMonth}
            enableMonthList={monthlyDataList?.map(i => i.salesMonth)}
          />
        </SectionTitle>
        <Caption1 color={colors.brown_grey}>
          *최신 데이터를 전달받지 못하였습니다. 데이터를 전달 해 주세요.
        </Caption1>
      </Flex>
      {
        isCompanyData ? (
          <Table
            className="inflow_table"
            columns={
              [
                {
                  title: '트랙명',
                  key: 'title',
                  dataIndex: 'title',
                  align: 'left',
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(a?.title, b?.title)
                  },
                  ...getColumnSearchProps('title'),
                  width: '30%',
                  render: (text, record) => (
                    <Link
                      to={`/insight/revenue/company/track/${record?._id}?isCompany=1`}
                    >
                      <Flex align="center">
                        {
record?.albumInfo?.image64Path && (
                            <img
                              src={STORAGE_URL + record?.albumInfo?.image64Path}
                              alt="cover_img"
                              style={
                                {
                                  width: '36px',
                                  height: '36px',
                                  borderRadius: '6px',
                                  margin: '0px 16px',
                                }
                              }
                            />
                          )
                        }
                        {
record?.isTitle && (
                            <img
                              src={Title_img}
                              alt="title"
                              style={
                                {
                                  width: '34px',
                                  height: '16px',
                                  marginRight: '6px',
                                }
                              }
                            />
                          )
                        }
                        <Caption2
                          style={
                            {
                              display: 'inline-block',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              width: '100%',
                            }
                          }
                          align={'left'}
                        >
                          {text}
                        </Caption2>
                      </Flex>
                    </Link>
                  ),
                },
                {
                  title: '누적수익',
                  key: 'totalRevenue',
                  dataIndex: ['revenueInfo', 'totalRevenue'],
                  defaultSortOrder: { sortOrder: 'descend' },
                  render: text => `${convertToPricingComma(Math.floor(text))}원`,
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                  a?.revenueInfo?.totalRevenue,
                  b?.revenueInfo?.totalRevenue,
                    )
                  },
                },
                {
                  title: '월 수익',
                  key: 'companyMonthlyAdjacencyFee',
                  dataIndex: ['revenueInfo', 'companyMonthlyAdjacencyFee'],
                  defaultSortOrder: { sortOrder: 'descend' },
                  render: (text, record) => {
                    const total =
                  record?.revenueInfo?.companyMonthlyAdjacencyFee
                  + record?.revenueInfo?.companyMonthlyPerformanceRightFee
                  + record?.revenueInfo?.companyMonthlyCopyRightFee
                    return `${convertToPricingComma(Math.floor(total))}원`
                  },
                  sorter: (a, b, sortOrder) => {
                    const aTotal =
                  a?.revenueInfo?.companyMonthlyAdjacencyFee
                  + a?.revenueInfo?.companyMonthlyPerformanceRightFee
                  + a?.revenueInfo?.companyMonthlyCopyRightFee
                    const bTotal =
                  b?.revenueInfo?.companyMonthlyAdjacencyFee
                  + b?.revenueInfo?.companyMonthlyPerformanceRightFee
                  + b?.revenueInfo?.companyMonthlyCopyRightFee

                    return sortHandler(aTotal, bTotal)
                  },
                },
                {
                  title: '인접권료',
                  key: 'companyMonthlyAdjacencyFee',
                  dataIndex: ['revenueInfo', 'companyMonthlyAdjacencyFee'],
                  defaultSortOrder: { sortOrder: 'descend' },
                  render: text => `${convertToPricingComma(Math.floor(text))}원`,
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                  a?.revenueInfo?.companyMonthlyAdjacencyFee,
                  b?.revenueInfo?.companyMonthlyAdjacencyFee,
                    )
                  },
                },
                {
                  title: '저작권료',
                  key: 'companyMonthlyCopyRightFee',
                  dataIndex: ['revenueInfo', 'companyMonthlyCopyRightFee'],
                  defaultSortOrder: { sortOrder: 'descend' },
                  render: text => `${convertToPricingComma(Math.floor(text))}원`,
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                  a?.revenueInfo?.companyMonthlyCopyRightFee,
                  b?.revenueInfo?.companyMonthlyCopyRightFee,
                    )
                  },
                },
                {
                  title: '실연권료',
                  key: 'companyMonthlyPerformanceRightFee',
                  dataIndex: ['revenueInfo', 'companyMonthlyPerformanceRightFee'],
                  defaultSortOrder: { sortOrder: 'descend' },
                  render: text => `${convertToPricingComma(Math.floor(text))}원`,
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                  a?.revenueInfo?.companyMonthlyPerformanceRightFee,
                  b?.revenueInfo?.companyMonthlyPerformanceRightFee,
                    )
                  },
                },
                {
                  title: '유통사',
                  key: 'distributor',
                  dataIndex: ['revenueInfo', 'distributor'],
                  defaultSortOrder: { sortOrder: 'descend' },
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                  a?.revenueInfo?.distributor,
                  b?.revenueInfo?.distributor,
                    )
                  },
                },

                {
                  title: '음원 링크',
                  key: 'dataObjectId',
                  dataIndex: ['customUrlInfo', 'dataObjectId'],
                  render: (text, record) => {
                    return (
                      <Flex justify={'center'} style={{ flex: 1 }}>
                        <a
                          href={`https://plam.in/track/${text}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={copy_btn_img}
                            alt="copy"
                            style={
                              {
                                width: '22px',
                                height: '22px',
                                cursor: 'pointer',
                              }
                            }
                          />
                        </a>
                      </Flex>
                    )
                  },
                  width: 80,
                },
              ]
            }
            dataSource={data}
            pagination={false}
            size={'small'}
            style={
              {
                width: '100%',
                height: '360px',
                border: 'solid 1px #dddddd',
                borderRadius: '6px',
                marginBottom: '40px',
              }
            }
            rowKey="dataObjectId"
            scroll={{ y: 300 }}
          />
        ) : (
          <Table
            className="inflow_table"
            columns={
              [
                {
                  title: '트랙명',
                  key: 'title',
                  dataIndex: 'title',
                  align: 'left',
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(a?.title, b?.title)
                  },
                  ...getColumnSearchProps('title'),
                  width: '30%',
                  render: (text, record) => (
                    <Link to={`/insight/revenue/track/${record?._id}`}>
                      <Flex align="center">
                        {
record?.albumInfo?.image64Path && (
                            <img
                              src={STORAGE_URL + record?.albumInfo?.image64Path}
                              alt="cover_img"
                              style={
                                {
                                  width: '36px',
                                  height: '36px',
                                  borderRadius: '6px',
                                  margin: '0px 16px',
                                }
                              }
                            />
                          )
                        }
                        {
record?.isTitle && (
                            <img
                              src={Title_img}
                              alt="title"
                              style={
                                {
                                  width: '34px',
                                  height: '16px',
                                  marginRight: '6px',
                                }
                              }
                            />
                          )
                        }
                        <Caption2
                          style={
                            {
                              display: 'inline-block',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              width: '100%',
                            }
                          }
                          align={'left'}
                        >
                          {text}
                        </Caption2>
                      </Flex>
                    </Link>
                  ),
                },
                {
                  title: '누적수익',
                  key: 'totalRevenue',
                  dataIndex: ['revenueInfo', 'totalRevenue'],
                  defaultSortOrder: { sortOrder: 'descend' },
                  render: text => `${convertToPricingComma(Math.floor(text))}원`,
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                  a?.revenueInfo?.totalRevenue,
                  b?.revenueInfo?.totalRevenue,
                    )
                  },
                },
                {
                  title: '월 수익',
                  key: 'artistMonthlyAdjacencyFee',
                  dataIndex: ['revenueInfo', 'artistMonthlyAdjacencyFee'],
                  defaultSortOrder: { sortOrder: 'descend' },
                  render: (text, record) => {
                    const total =
                  record?.revenueInfo?.artistMonthlyAdjacencyFee
                  + record?.revenueInfo?.artistMonthlyPerformanceRightFee
                  + record?.revenueInfo?.artistMonthlyCopyRightFee
                    return `${convertToPricingComma(Math.floor(total))}원`
                  },
                  sorter: (a, b, sortOrder) => {
                    const aTotal =
                  a?.revenueInfo?.artistMonthlyAdjacencyFee
                  + a?.revenueInfo?.artistMonthlyPerformanceRightFee
                  + a?.revenueInfo?.artistMonthlyCopyRightFee
                    const bTotal =
                  b?.revenueInfo?.artistMonthlyAdjacencyFee
                  + b?.revenueInfo?.artistMonthlyPerformanceRightFee
                  + b?.revenueInfo?.artistMonthlyCopyRightFee

                    return sortHandler(aTotal, bTotal)
                  },
                },
                {
                  title: '인접권료',
                  key: 'artistMonthlyAdjacencyFee',
                  dataIndex: ['revenueInfo', 'artistMonthlyAdjacencyFee'],
                  defaultSortOrder: { sortOrder: 'descend' },
                  render: text => `${convertToPricingComma(Math.floor(text))}원`,
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                  a?.revenueInfo?.artistMonthlyAdjacencyFee,
                  b?.revenueInfo?.artistMonthlyAdjacencyFee,
                    )
                  },
                },
                {
                  title: '저작권료',
                  key: 'artistMonthlyCopyRightFee',
                  dataIndex: ['revenueInfo', 'artistMonthlyCopyRightFee'],
                  defaultSortOrder: { sortOrder: 'descend' },
                  render: text => `${convertToPricingComma(Math.floor(text))}원`,
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                  a?.revenueInfo?.artistMonthlyCopyRightFee,
                  b?.revenueInfo?.artistMonthlyCopyRightFee,
                    )
                  },
                },
                {
                  title: '실연권료',
                  key: 'artistMonthlyPerformanceRightFee',
                  dataIndex: ['revenueInfo', 'artistMonthlyPerformanceRightFee'],
                  defaultSortOrder: { sortOrder: 'descend' },
                  render: text => `${convertToPricingComma(Math.floor(text))}원`,
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                  a?.revenueInfo?.artistMonthlyPerformanceRightFee,
                  b?.revenueInfo?.artistMonthlyPerformanceRightFee,
                    )
                  },
                },
                {
                  title: '유통사',
                  key: 'distributor',
                  dataIndex: ['revenueInfo', 'distributor'],
                  defaultSortOrder: { sortOrder: 'descend' },
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                  a?.revenueInfo?.distributor,
                  b?.revenueInfo?.distributor,
                    )
                  },
                },

                {
                  title: '음원 링크',
                  key: 'dataObjectId',
                  dataIndex: ['customUrlInfo', 'dataObjectId'],
                  render: (text, record) => {
                    return (
                      <Flex justify={'center'} style={{ flex: 1 }}>
                        <a
                          href={`https://plam.in/track/${text}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={copy_btn_img}
                            alt="copy"
                            style={
                              {
                                width: '22px',
                                height: '22px',
                                cursor: 'pointer',
                              }
                            }
                          />
                        </a>
                      </Flex>
                    )
                  },
                  width: 80,
                },
              ]
            }
            dataSource={data}
            pagination={false}
            size={'small'}
            style={
              {
                width: '100%',
                height: '360px',
                border: 'solid 1px #dddddd',
                borderRadius: '6px',
                marginBottom: '40px',
              }
            }
            rowKey="dataObjectId"
            scroll={{ y: 300 }}
          />
        )
      }
      <SectionTitle>월간 세부 내역</SectionTitle>
      <Table
        className="inflow_table"
        columns={
          [
            {
              title: '월',
              key: 'salesMonth',
              dataIndex: 'salesMonth',
              defaultSortOrder: 'descend',
              sorter: (a, b) => a.salesMonth > b.salesMonth,
              render: (text, record) =>
                `20${parseInt(record.salesMonth / 100)}년 ${record.salesMonth
                % 100}월`,
            },
            {
              title: '총 수익',
              key: 'totalRevenue',
              dataIndex: 'totalRevenue',
              defaultSortOrder: { sortOrder: 'descend' },
              render: text => `${convertToPricingComma(Math.floor(text))}원`,
              sorter: (a, b) => a?.totalRevenue - b?.totalRevenue,
            },
            {
              title: '내 인접권료',
              key: 'monthlyAdjacencyFee',
              dataIndex: 'monthlyAdjacencyFee',
              defaultSortOrder: { sortOrder: 'descend' },
              render: text => `${convertToPricingComma(Math.floor(text))}원`,
              sorter: (a, b) => a?.monthlyAdjacencyFee - b?.monthlyAdjacencyFee,
            },
            {
              title: '트랙 인접권료',
              key: 'albumMonthlyAdjacencyFee',
              dataIndex: 'albumMonthlyAdjacencyFee',
              defaultSortOrder: { sortOrder: 'descend' },
              render: text =>
                typeof text === 'number'
                  ? `${convertToPricingComma(Math.floor(text))}원`
                  : '-',
              sorter: (a, b) =>
              a?.albumMonthlyAdjacencyFee - b?.albumMonthlyAdjacencyFee,
            },
            {
              title: '내 저작권료',
              key: 'monthlyCopyRightFee',
              dataIndex: 'monthlyCopyRightFee',
              defaultSortOrder: { sortOrder: 'descend' },
              render: text => `${convertToPricingComma(Math.floor(text))}원`,
              sorter: (a, b) => a?.monthlyCopyRightFee - b?.monthlyCopyRightFee,
            },
            {
              title: '트랙 저작권료',
              key: 'albumMonthlyCopyRightFee',
              dataIndex: 'albumMonthlyCopyRightFee',
              defaultSortOrder: { sortOrder: 'descend' },
              render: text => `${convertToPricingComma(Math.floor(text))}원`,
              sorter: (a, b) =>
              a?.albumMonthlyCopyRightFee - b?.albumMonthlyCopyRightFee,
            },
            {
              title: '내 실연권료',
              key: 'monthlyPerformanceRightFee',
              dataIndex: 'monthlyPerformanceRightFee',
              defaultSortOrder: { sortOrder: 'descend' },
              render: text => `${convertToPricingComma(Math.floor(text))}원`,
              sorter: (a, b) =>
              a?.monthlyPerformanceRightFee - b?.monthlyPerformanceRightFee,
            },
            {
              title: '트랙 실연권료',
              key: 'albumMonthlyPerformanceRightFee',
              dataIndex: 'albumMonthlyPerformanceRightFee',
              defaultSortOrder: { sortOrder: 'descend' },
              render: text => `${convertToPricingComma(Math.floor(text))}원`,
              sorter: (a, b) =>
              a?.albumMonthlyPerformanceRightFee
              - b?.albumMonthlyPerformanceRightFee,
            },
          ]
        }
        dataSource={monthlyDataList}
        pagination={false}
        size={'small'}
        style={
          {
            width: '100%',
            border: 'solid 1px #dddddd',
            borderRadius: '6px',
            marginTop: '24px',
            marginBottom: '100px',
          }
        }
        rowKey="dataObjectId"
      />
    </Flex>
  )
}

export default observer(RevenueAlbumDetailTableChart)

const SectionTitle = styled(Text)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
`
