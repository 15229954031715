export const colors = {
  black: '#000000', // 주로 텍스트 및 아이콘에 들어가는 스타일, 텍스트 및 컴포넌트 정리하면서 정리 필요
  white50: '#ffffff50', // 송 이미지 마스크
  black60: '#00000060', // 액션시트 상단 배경 색
  grayLight: '#dddddd', // 라인 등에 사용.. 적당한 색상이 없어서 일단 놔둠

  purplish_blue: '#7e2cff',
  water_blue: '#1982df',
  lightish_red: '#ea4653',
  black_two: '#242424',
  brownish_grey: '#646464',
  brown_grey: '#949494',
  white_two: '#f4f4f4',
  white: '#ffffff',
  boring_green: '#66bc6a',
  orange: '#ff9742',

  main: '#242424',
  sub_main: '#646464',
  sub: '#949494',
}
