/* eslint-disable import/no-unresolved */
import { Flex } from '@components/atoms'
import styled from 'styled-components'

export const TagButtonContainer = styled(Flex)`
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  justify-content: space-between;
  width: 100%;
`
