import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Flex,
  Text,
  H4,
  Caption2,
  Container,
  Button,
  Image,
} from '@components/atoms'
import {
  ArtistRevenueStatsChart,
  RevenueTrackTableChart,
  RevenueUploadModal,
  ValuationInfoModal,
} from '@components/organisms'
import { colors } from '@colors/'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import moment from 'moment'
import 'moment/locale/ko'
import CountUp from 'react-countup'
import { inject, observer } from 'mobx-react'
import { path_menu, add_btn } from '@images/'
import { convertToPricingComma } from '@utils/format'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 0 0 0;
`

const SectionTitle = styled(Text)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  margin-bottom: 20px;
`

const InfoCard = styled(Flex)`
  width: 234px;
  height: 167px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #fcfcfc;
  padding: 5px;
  margin: 0 50px 0 0;
  justify-content: center;

  ${({ enableClick }) =>
    enableClick
    && `:hover {
    background-color: black;
    > * {
      color: white;
    }
  }`}
`

const InsightRevenueTemplate = ({
  authStore,
  artistRevenueData,
  artistRevenueExpectedList,
  currentYear,
  handleYear,
  handleType,
  recentDate,
}) => {
  const [showUpload, setShowUpload] = useState(false)
  const [isShowValuation, setIsShowValuation] = useState(false)

  const artistRevenueInfo =
    artistRevenueData?.revenueInfo?.artistRevenueInfo[0] || null

  const expectedRevenueList =
    artistRevenueExpectedList?.expectedRevenueList || []

  const valuationInfo = artistRevenueData?.valuationInfo || null

  const displayYear = currentYear?.format('YYYY년')

  moment.locale('ko')

  const isLoginedMaster = authStore.isAdminPassword

  return (
    <Container>
      <Header>
        <Text type="Medium" size="18px" style={{ marginTop: '10px' }}>
          아티스트 수익 (판매월 기준)
        </Text>
        {
          isLoginedMaster && (
            <HeaderBtn onClick={() => setShowUpload(true)}>
              <HeaderBtnImg src={add_btn} alt="add_btn" />
              <Text type="Medium" size="14px" color={colors.main}>
              업로드
              </Text>
            </HeaderBtn>
          )
        }
      </Header>
      <Content>
        {
          artistRevenueInfo ? (
            <Flex>
              <Flex type="column">
                <Flex justify="space-between">
                  <SectionTitle>
                    <Image
                      src={path_menu}
                      alt="prev"
                      width="18px"
                      height="18px"
                      transform="rotate(180deg)"
                      cursor="pointer"
                      onClick={() => handleYear(currentYear.subtract(1, 'years'))}
                    />
                    {displayYear}
                    <Image
                      src={path_menu}
                      alt="next"
                      width="18px"
                      height="18px"
                      opacity={
                        moment().isSameOrBefore(currentYear, 'years') ? 0.5 : 1
                      }
                      cursor={
                        moment().isSameOrBefore(currentYear, 'years')
                          ? 'not-allowed'
                          : 'pointer'
                      }
                      onClick={
                        () => {
                          if (moment().isSameOrBefore(currentYear, 'years')) return
                          handleYear(currentYear.add(1, 'years'))
                        }
                      }
                    />
                  </SectionTitle>
                  <SectionTitle
                    style={
                      {
                        fontFamily: 'NotoSansCJKkr-Medium',
                        textDecoration: 'underline',
                        position: 'relative',
                      }
                    }
                    onMouseEnter={() => setIsShowValuation(true)}
                    onMouseLeave={() => setIsShowValuation(false)}
                  >
                    {
                      `${recentDate?.format(
                    '(YYYY년 MM월 기준)',
                  )} 보유 음악 가치 평가액은 `
                    }
                    <strong style={{ fontFamily: 'NotoSansCJKkr-Bold' }}>
                      {
                        convertToPricingComma(
                          Math.floor(valuationInfo?.dayWeightedTotalValue),
                        )
                      }
                    원
                    </strong>
                    {` 입니다.`}
                    {
                      isShowValuation && (
                        <ValuationInfoModal
                          isShowing={isShowValuation}
                          valuationInfo={valuationInfo}
                          handleType={handleType}
                        />
                      )
                    }
                  </SectionTitle>
                </Flex>
                <Flex>
                  <InfoCard type="column">
                    <H4>누적 수익</H4>
                    <H4
                      type="Medium"
                      color={colors.main}
                      style={{ marginTop: '20px' }}
                    >
                      {
artistRevenueInfo?.totalRevenue && (
                          <CountUp
                            start={0}
                            end={Math.floor(artistRevenueInfo.totalRevenue)}
                            separator=","
                            duration={3}
                          />
                        )
                      }
                    원
                    </H4>
                  </InfoCard>
                  <InfoCard type="column">
                    <Caption2 color={colors.brown_grey}>
                      {`(${displayYear || 0})`}
                    </Caption2>
                    <H4>연간 수익</H4>
                    <H4
                      type="Medium"
                      color={colors.main}
                      style={{ marginTop: '20px' }}
                    >
                      {
artistRevenueInfo?.annualRevenue && (
                          <CountUp
                            start={0}
                            end={Math.floor(artistRevenueInfo.annualRevenue)}
                            separator=","
                            duration={3}
                          />
                        )
                      }
                    원
                    </H4>
                  </InfoCard>
                  <InfoCard
                    type="column"
                    onClick={() => handleType('adjacency')}
                    style={{ cursor: 'pointer' }}
                    enableClick
                  >
                    <Caption2 color={colors.brown_grey}>
                      {`(${displayYear || 0})`}
                    </Caption2>
                    <H4>연간 인접권료</H4>
                    <H4
                      type="Medium"
                      color={colors.main}
                      style={{ marginTop: '20px' }}
                    >
                      {
artistRevenueInfo?.annualAdjacencyFee && (
                          <CountUp
                            start={0}
                            end={Math.floor(artistRevenueInfo.annualAdjacencyFee)}
                            separator=","
                            duration={3}
                          />
                        )
                      }
                    원
                    </H4>
                  </InfoCard>
                  <InfoCard
                    type="column"
                    onClick={() => handleType('copyRight')}
                    style={{ cursor: 'pointer' }}
                    enableClick
                  >
                    <Caption2 color={colors.brown_grey}>
                      {`(${displayYear || 0})`}
                    </Caption2>
                    <H4>연간 저작권료</H4>
                    <H4
                      type="Medium"
                      color={colors.main}
                      style={{ marginTop: '20px' }}
                    >
                      {
artistRevenueInfo?.annualCopyRightFee && (
                          <CountUp
                            start={0}
                            end={Math.floor(artistRevenueInfo.annualCopyRightFee)}
                            separator=","
                            duration={3}
                          />
                        )
                      }
                    원
                    </H4>
                  </InfoCard>
                  <InfoCard
                    type="column"
                    onClick={() => handleType('performanceRight')}
                    style={{ cursor: 'pointer' }}
                    enableClick
                  >
                    <Caption2 color={colors.brown_grey}>
                      {`(${displayYear || 0})`}
                    </Caption2>
                    <H4>연간 실연권료</H4>
                    <H4
                      type="Medium"
                      color={colors.main}
                      style={{ marginTop: '20px' }}
                    >
                      {
artistRevenueInfo?.annualPerformanceRightFee && (
                          <CountUp
                            start={0}
                            end={
                              Math.floor(
                                artistRevenueInfo.annualPerformanceRightFee,
                              )
                            }
                            separator=","
                            duration={3}
                          />
                        )
                      }
                    원
                    </H4>
                  </InfoCard>
                </Flex>
              </Flex>
            </Flex>
          ) : (
            <Flex>
              <Flex type="column">
                <Flex justify="space-between">
                  <SectionTitle>예상 수익</SectionTitle>
                </Flex>
              </Flex>
            </Flex>
          )
        }
        <ArtistRevenueStatsChart
          currentYear={currentYear}
          data={artistRevenueData?.revenueInfo?.artistMonthlyRevenueInfo || []}
          expectedData={expectedRevenueList}
        />

        {
          artistRevenueInfo && (
            <Flex type="column" style={{ marginTop: '50px' }}>
              <SectionTitle>세부 내역</SectionTitle>
              <RevenueTrackTableChart />
            </Flex>
          )
        }
      </Content>
      <RevenueUploadModal
        show={showUpload}
        handleClose={() => setShowUpload(false)}
      />
    </Container>
  )
}

export default inject('authStore')(observer(InsightRevenueTemplate))

const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: #ffffff;
`

const HeaderBtnImg = styled(Image)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`
