import React, { useState } from 'react'
import { Modal, Button, DatePicker, Input, message } from 'antd'
import moment from 'moment'

const ClosingAdjustmentModal = ({
  isVisible,
  onClose,
  onConfirm,
  adjustmentDetailList,
}) => {
  const [selectedDate, setSelectedDate] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [showInput, setShowInput] = useState(false)

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  const handleInputChange = e => {
    setInputValue(e.target.value)
  }

  const handleNext = () => {
    if (!selectedDate) {
      message.warning('정산 마감일을 선택해주세요.')
      return
    }
    setShowInput(true)
  }

  const handleConfirmClosing = () => {
    if (inputValue.trim().toLowerCase() !== '마감완료') {
      message.warning("입력란에 '마감완료'를 정확히 입력해주세요.")
      return
    }
    onConfirm(selectedDate)
    handleCloseClosingModal()
  }

  const handleCloseClosingModal = () => {
    setShowInput(false)
    setSelectedDate(null)
    setInputValue('')
    onClose()
  }

  return (
    <Modal
      title="정산서 마감"
      visible={isVisible}
      onCancel={handleCloseClosingModal}
      footer={
        [
          <Button key="back" onClick={handleCloseClosingModal}>
          취소
          </Button>,

          showInput ? (
            <Button
              key="submit"
              type="primary"
              disabled={!(inputValue === '마감완료')}
              onClick={handleConfirmClosing}
            >
            마감완료
            </Button>
          ) : (
            <Button
              key="submit"
              type="primary"
              disabled={!selectedDate}
              onClick={handleNext}
            >
            다음
            </Button>
          ),
        ]
      }
    >
      {
        showInput ? (
          <>
            <h3>
              {selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : ''}
            </h3>
            <div style={{ marginBottom: '20px' }}>
            해당 일로 마감을 하시겠습니까? 마감을 하면 되돌릴 수 없습니다.
              <br />
            마감을 하시려면 &apos;마감완료&apos;를 입력해주세요.
            </div>
            <Input
              value={inputValue}
              onChange={handleInputChange}
              placeholder="마감완료"
            />
          </>
        ) : (
          <DatePicker
            disabledDate={
              current =>
                current
            && current
              < moment(
                adjustmentDetailList
                  .slice()
                  .sort((a, b) => b['date'] - a['date'])[0]['date'],
                'YYYYMMDD',
              )
                .subtract(1, 'days')
                .endOf('day')
            }
            onChange={handleDateChange}
            placeholder="마감일 선택"
          />
        )
      }
    </Modal>
  )
}

export default ClosingAdjustmentModal
