import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { H4, H6, Caption1, Flex } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { colors } from '@colors/'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2050;
  background: white;
  border-radius: 16px;
  /* overflow: scroll; */
  overflow: auto;
  min-width: 320px;
  min-height: 170px;

  @media ${mobile} {
    height: 199px;
    top: 40%;
  }
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 45px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const ConfirmBtn = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 24px;
  border: none;
  background-color: ${colors.main};
  color: #ffffff;
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({ isShowing, toggle, onClose, title, body, customBody }) => {
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <H4 type="Bold" style={{ margin: '12px 0px 20px 0px' }}>
                {title}
              </H4>
              {customBody}
              <Caption1 color="#949494" style={{ whiteSpace: 'pre' }}>
                {body}
              </Caption1>
              <Flex style={{ justifyContent: 'center', marginTop: '30px' }}>
                <ConfirmBtn onClick={() => handleClose()}>
                  <H6 color="#ffffff">확인</H6>
                </ConfirmBtn>
              </Flex>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}
