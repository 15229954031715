/* eslint-disable import/no-unresolved */
import './custom.css'
import { observer, inject } from 'mobx-react'
import React, { useEffect, useState, useMemo } from 'react'
import { RootTemplate } from '@components/templates'
import qs from 'qs'
import styled from 'styled-components'
import { MaterialReactTable } from 'material-react-table'
import { Flex, Text, Image } from '@components/atoms'

import 'react-data-grid/lib/styles.css'
import { Button, Spin } from 'antd'
import moment from 'moment'

import { toJS } from 'mobx'
import { numberWithCommas } from '@utils/format'
import { Link } from 'react-router-dom'
import { AdjustmentTypeHeader } from './components/AdjustmentTypeHeader'
import { TotalInfo } from './components/TotalInfo'
import { RoyaltyStatementContainer } from './components/RoyaltyStatementContainer'
import { RSHeader } from './components/RSHeader'
import { CarryMark } from './components/CarryMark'
import { TagButtonContainer } from './components/TagButtonContainer'
import { borderColor } from './styles'

const SheetContainer = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
`

const TotalContainer = styled(Flex)`
  flex-direction: column;
  /* align-self: flex-start; */
  /* width: 250px; */
  padding: 10px 15px;
  border: solid 1px #eaeaea;
  height: inherit;
`

const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 1px #eaeaea;
  background-color: #ffffff;
`
const UserImage = styled(Image)`
  width: 30px;
  height: 30px;
  border-radius: 24px;
`

const ContentText = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  min-height: 25px;
`

const FileListItemDiv = styled(Flex)`
  align-items: center;
  justify-content: center;
`

const FileListDiv = styled.div`
  flex: 9;
  min-width: 0;
  max-width: 114px;
`
const FileNameDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-decoration: underline;
`
const EllipsisText = styled(Text)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
`

const inoutOptions = {
  수입: '수입',
  지출: '지출',
}

moment.locale('ko')

const CompanyClosedRoyaltyStatementPage = ({
  location,
  authStore,
  artistStore,
  user,
  adjustmentStore,
  adjustmentContractStore,
  adjustmentDetailStore,
}) => {
  const [adjustment, setAdjustment] = useState(null)

  const artist = artistStore.artistDetail
  const logout = authStore?.logout || (() => {})
  const [data, setData] = useState([])
  const [companyName, setCompanyName] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  /** 정산 구분 */
  const { adjustmentTypeList = null } = adjustment || {}

  let adjustmentTypes = []
  if (adjustment && adjustmentTypeList) {
    adjustmentTypes = [
      ...(adjustmentTypeList
        ? adjustmentTypeList
            ?.filter(item => item.type !== null)
            ?.map(item => ({
              value: item.type,
              label: item.type,
            }))
        : []),
    ]
  }

  const [selectedTypes, setSelectedTypes] = useState([])
  const handleClickAdjustmentType = buttonValue => {
    if (buttonValue === '전체') {
      setSelectedTypes([])
      return
    }

    const selectedIndex = selectedTypes.indexOf(buttonValue)

    if (selectedIndex === -1) {
      setSelectedTypes([...selectedTypes, buttonValue])
    }
    else {
      setSelectedTypes(selectedTypes.filter(value => value !== buttonValue))
    }
  }
  /** 정산 구분 */

  const openFile = file => {
    window.open(file.path)
  }

  const columnHeaderAlign = 'center'

  const columns = useMemo(
    () => [
      {
        accessorKey: 'date', // simple recommended way to define a column
        header: '날짜',
        muiTableHeadCellProps: {
          sx: {
            width: '110px',
            minWidth: '110px',
            flex: '0 0 auto',
          },
        }, // custom props
        muiTableBodyCellProps: {
          sx: {
            width: '110px',
            minWidth: '110px',
            flex: '0 0 auto',
          },
        },
        // Footer: () => {
        //   <Input style={{width: 100}} />
        // }
        Cell: ({ cell }) => {
          const _date = cell.getValue()
          return <Text>{moment(_date, 'YYYYMMDD').format('YYYY-MM-DD')}</Text>
        },
      },
      {
        accessorKey: 'type', // simple recommended way to define a column
        header: '정산 구분',
        muiTableHeadCellProps: {
          sx: {
            width: '120px',
            minWidth: '120px',
            flex: '0 0 auto',
          },
        }, // custom props
        muiTableBodyCellProps: {
          sx: {
            width: '120px',
            minWidth: '120px',
            flex: '0 0 auto',
          },
        },
        Cell: ({ cell }) => {
          return <EllipsisText>{cell.getValue()}</EllipsisText>
        },
      },
      {
        accessorKey: 'description', // simple recommended way to define a column
        header: '내용',
        muiTableHeadCellProps: {
          sx: { flex: '1 0 auto' },
        }, // custom props
        muiTableBodyCellProps: {
          sx: { flex: '1 0 auto', whiteSpace: 'break-word' },
        },
        Cell: ({ cell }) => {
          const _isCarryOver = cell.row.original.isCarryOver

          return (
            <Flex>
              {_isCarryOver && <CarryMark />}
              <Text>{cell.getValue()}</Text>
            </Flex>
          )
        },
      },
      {
        accessorKey: 'tag', // simple recommended way to define a column
        header: '수입/지출',
        muiTableHeadCellProps: {
          sx: {
            width: '110px',
            minWidth: '110px',
            flex: '0 0 auto',
          },
        }, // custom props
        muiTableBodyCellProps: {
          sx: {
            width: '110px',
            minWidth: '110px',
            flex: '0 0 auto',
          },
        },
        Cell: ({ cell }) => (
          <Text
            color={
              inoutOptions[cell.getValue()] === '수입' ? '#0FC129' : '#F07E87'
            }
          >
            {inoutOptions[cell.getValue()]}
          </Text>
        ),
        enableEditing: false,
      },
      {
        accessorKey: 'amountAbs', // simple recommended way to define a column
        header: '금액',
        muiTableHeadCellProps: {
          sx: {
            width: '110px',
            minWidth: '110px',
            flex: '0 0 auto',
          },
        }, // custom props
        muiTableBodyCellProps: {
          sx: {
            width: '110px',
            minWidth: '110px',
            flex: '0 0 auto',
          },
        },
        Cell: ({ cell }) => {
          return <Text>{`${numberWithCommas(cell.getValue()) || 0}`}</Text>
        },
      },
      // {
      //   accessorKey: 'adjustmentContractId', // simple recommended way to define a column
      //   header: '계약',
      //   muiTableHeadCellProps: {
      //     sx: { color: 'grey' },
      //     align: columnHeaderAlign,
      //   }, // custom props
      //   muiTableBodyCellProps: {
      //     align: columnHeaderAlign,
      //   },
      //   size: 150,
      //   Cell: ({ cell }) => {
      //     const contractId = cell.getValue()
      //     const contract = adjustmentContractStore.contracts.find(
      //       item => item._id === contractId,
      //     )

      //     return <Text>{contract?.type || ''}</Text>
      //   },
      // },
      {
        accessorKey: 'fileList', // simple recommended way to define a column
        header: '증빙',
        muiTableHeadCellProps: {
          sx: {
            width: '130px',
            minWidth: '130px',
            flex: '0 0 auto',
          },
        }, // custom props
        muiTableBodyCellProps: {
          sx: {
            width: '130px',
            minWidth: '130px',
            flex: '0 0 auto',
          },
        },
        Cell: ({ cell }) => {
          const _isCarryOver = cell.row.original.isCarryOver
          const _carryOverAdjustmentId = cell.row.original.carryOverAdjustmentId

          if (_isCarryOver) {
            if (query.artistId && _carryOverAdjustmentId) {
              return (
                <Link
                  to={`closed?artistId=${query.artistId}&adjustmentId=${_carryOverAdjustmentId}`}
                >
                  <Text>마감 정산서 확인</Text>
                </Link>
              )
            }
          }

          return (
            <FileListDiv>
              {
toJS(
  adjustmentDetailStore.adjustmentDetailList[cell.row.index][
    cell.column.id
  ],
)?.map((file, index) => (
  <FileListItemDiv key={index}>
    <FileNameDiv
      role="presentation"
      onClick={() => openFile(file)}
      title={file.name}
    >
      {`증빙 자료 ${index + 1}`}
    </FileNameDiv>
  </FileListItemDiv>
))
              }
            </FileListDiv>
          )
        },
      },
      // {
      //   accessorKey: 'remarks', // simple recommended way to define a column
      //   header: '비고',
      //   muiTableHeadCellProps: {
      //     sx: { color: 'grey' },
      //     align: columnHeaderAlign,
      //   }, // custom props
      //   muiTableBodyCellProps: {
      //     align: columnHeaderAlign,
      //   },
      //   size: 200,
      //   Cell: ({ cell }) => {
      //     return <Text>{cell.getValue()}</Text>
      //   },
      // },
    ],
    [data],
  )

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  useEffect(() => {
    const _fetch = async () => {
      setIsLoading(true)
      if (query.artistId && query.artistId !== 'null') {
        if (user?.type === 'company') {
          await authStore.artistViewMode(query.artistId)
        }
        const _adjustment = await adjustmentStore.fetchAdjustment({
          adjustmentId: query.adjustmentId,
        })

        const adjustmentDetails = await adjustmentDetailStore.fetchAdjustmentDetail(
          { adjustmentId: query.adjustmentId },
        )

        if (!adjustmentDetails) {
          setIsLoading(false)
          return
        }

        setAdjustment(_adjustment)
        setData([...adjustmentDetails])

        await adjustmentContractStore.fetchAdjustmentContractList({
          artistId: query.artistId,
        })

        const res = await adjustmentContractStore.fetchContractCompanyList({
          artistId: query.artistId,
        })

        const company = res.find(item => item._id === _adjustment?.companyId)
        setCompanyName(company?.name)
      }
      setIsLoading(false)
    }

    _fetch()
  }, [artistStore])

  const { imageUri, name } = artist || {}

  const filteredData = data?.filter(item => {
    if (selectedTypes.length === 0) return true
    return selectedTypes.includes(item.type)
  })

  return (
    <>
      <RootTemplate user={user} artist={artist} logout={logout}>
        <RoyaltyStatementContainer>
          <RSHeader>
            <HeaderBtn style={{ backgroundColor: 'black' }}>
              <UserImage src={imageUri} />
              <Text style={{ marginLeft: '10px', color: 'white' }}>
                {`${name}`}
              </Text>
            </HeaderBtn>
            <HeaderBtn
              onClick={
                () => {
                  alert('해당 기능은 준비중입니다')
                }
              }
            >
              다운로드
            </HeaderBtn>
          </RSHeader>
          {
            isLoading ? (
              <Spin />
            ) : (
              <>
                <Flex style={{ maWidth: 'calc(100vw - 280px)' }}>
                  <TotalContainer
                    style={
                      {
                        flexGrow: '1',
                        marginRight: '20px',
                        minWidth: '500px',
                      }
                    }
                  >
                  정산서 제목
                    <ContentText>{adjustment?.title}</ContentText>
                  </TotalContainer>
                  <TotalContainer
                    style={
                      {
                        flexShrink: '0',
                        marginRight: '20px',
                        width: '250px',
                      }
                    }
                  >
                  소속사
                    <ContentText>{companyName}</ContentText>
                  </TotalContainer>
                  <TotalContainer
                    style={
                      {
                        flexShrink: '0',
                        width: '250px',
                      }
                    }
                  >
                    <div>정산기간</div>
                    <ContentText>
                      {
                        `${moment(
                      adjustment?.['adjustmentStartDate'],
                      'YYYYMMDD',
                        ).format('YYYY-MM-DD') || ''} ~ ${moment(
                      adjustment?.['adjustmentConfirmDate'],
                      'YYYYMMDD',
                        ).format('YYYY-MM-DD') || ''}`
                      }
                    </ContentText>
                  </TotalContainer>
                </Flex>
                <TagButtonContainer>
                  <AdjustmentTypeHeader
                    typeList={adjustmentTypes}
                    selectedTypes={selectedTypes}
                    onClick={handleClickAdjustmentType}
                  />
                </TagButtonContainer>
                <Flex style={{ maxWidth: 'calc(100vw - 280px)' }}>
                  <SheetContainer>
                    <MaterialReactTable
                      enableRowOrdering={false}
                      columns={columns}
                      data={filteredData}
                      // enableRowSelection // enable some features
                      // enableColumnOrdering
                      // enableEditing
                      // editingMode='cell'
                      enableGlobalFilter={false}
                      enablePagination={false}
                      // onEditingCellChange={
                      //   (e) => {
                      //     console.log('onEditingCellChange', e)
                      //     return e
                      //   }
                      // }
                      // onDraggingRowChange={e => console.log("onDraggingRowChange", e)}
                      enableRowNumbers
                      enableStickyFooter={false}
                      enableStickyHeader
                      enableBottomToolbar={false}
                      enableDensityToggle={false}
                      enableFullScreenToggle={false}
                      enableHiding={false}
                      enableTopToolbar={false}
                      enableColumnActions={false}
                      displayColumnDefOptions={
                        {
                          'mrt-row-numbers': {
                            header: '', // change header text
                            muiTableHeadCellProps: {
                              sx: {
                                width: '30px',
                                minWidth: '30px',
                                flex: '0 0 auto',
                              },
                            },
                            muiTableBodyCellProps: {
                              sx: {
                                color: 'grey',
                                width: '30px',
                                minWidth: '30px',
                                flex: '0 0 auto',
                              },
                            },
                          },
                        }
                      }
                      localization={
                        {
                          clearFilter: '필터 해제',
                          clearSearch: '검색 해제',
                          clearSort: '정렬 해제',
                          filterByColumn: '{column} 필터',
                          showHideFilters: '필터 표시/숨기기',
                          showHideSearch: '검색 표시/숨기기',
                          sortByColumnAsc: '{column} 오름차순 정렬',
                          sortByColumnDesc: '{column} 내림차순 정렬',
                          sortedByColumnAsc: '{column} 기준 오름차순 정렬',
                          sortedByColumnDesc: '{column} 기준 내림차순 정렬',
                          unsorted: '정렬되지 않음',
                          columnActions: '열 작업',
                          move: '이동',
                        }
                      }
                      muiTablePaperProps={
                        {
                          sx: {
                            boxShadow: 'none',
                            border: borderColor,
                            maxHeight: '100vh',
                            td: {
                              color: '#242424',
                            },
                            th: {
                              color: '#949494',
                            },
                            '& td > span': {
                              display: 'inline-block',
                              minHeight: '19px',
                            },
                            '& thead > tr': {
                              backgroundColor: '#fafafa',
                            },
                            '& td, th': {
                              padding: '8px 4px',
                              fontFamily: 'NotoSansCJKkr-Regular',
                              fontWeight: 400,
                              borderRight: borderColor,
                              borderBottom: 'none',
                              ':last-child': {
                                borderRight: 'none',
                              },
                            },
                            '& tr:not(:last-child)': {
                              borderBottom: borderColor,
                            },
                          },
                        }
                      }
                      muiTableHeadCellProps={
                        {
                          align: columnHeaderAlign,
                        }
                      }
                      muiTableBodyCellProps={
                        {
                          align: columnHeaderAlign,
                        }
                      }
                      initialState={{ density: 'compact' }}
                      layoutMode="grid"
                    />
                  </SheetContainer>
                  <TotalInfo
                    amountInfo={adjustment?.amountInfo}
                    selectedTypes={selectedTypes}
                  />
                </Flex>
              </>
            )
          }
        </RoyaltyStatementContainer>
      </RootTemplate>
    </>
  )
}

export default inject(
  'authStore',
  'artistStore',
  'adjustmentStore',
  'adjustmentContractStore',
  'adjustmentDetailStore',
)(observer(CompanyClosedRoyaltyStatementPage))
