import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Text, Input, H5, H4 } from '@components/atoms'
import { CheckTextBox, LogoImg } from '@components/molecules'
import { useForm } from 'react-hook-form'
import { ServiceAgreeModal, InfoAgreeModal } from '@components/organisms'

import { arrowLeft } from '@images'
import { mobile } from '@styles/media'

const Wrapper = styled.div`
  position: relative;

  margin: 0 auto;
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;

  @media ${mobile} {
    height: 100dvh;
  }
`

const Form = styled.form`
  margin: 28px 0;

  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  width: 100%;
`

export const RegisterForm = ({ postUser, setIsRegister, login, emailAuth }) => {
  const [isDuplicateAccount, setIsDuplicateAccount] = useState(false)
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false)

  const [sex, setSex] = useState('')
  const [showSexError, setShowSexError] = useState(false)
  const [checkConfirm, setCheckConfirm] = useState(false)
  const [showAgree1, setShowAgree1] = useState(false)
  const [showAgree2, setShowAgree2] = useState(false)

  const [step, setStep] = useState(0)
  const [agree1, setAgree1] = useState(false)
  const [agree2, setAgree2] = useState(false)

  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'onChange',
  })

  const { account, password, confirm_password } = watch()

  // 비밀번호 확인
  const confirmPw = value => {
    if (value === password) {
      setCheckConfirm(false)
    } else {
      setCheckConfirm(true)
    }
  }

  const onSubmit = async data => {
    const { account, email, password, name, birth, phone, code } = data

    if (!agree1 || !agree2) return false

    if (sex === '') {
      setShowSexError(true)
      return false
    }

    const params = {
      account,
      email,
      password,
      name,
      birth,
      sex,
      phone,
      isAuthPhone: false,
      code,
    }

    const res = await postUser(params)

    if (res) {
      const { isDuplicateAccount, isDuplicateEmail, isRegister } = res.data

      if (isDuplicateAccount) {
        setIsDuplicateAccount(isDuplicateAccount)
        setStep(1)
      }
      setIsDuplicateEmail(isDuplicateEmail)
      setIsRegister(isRegister)

      if (res.data.user) {
        await login({ account, password })
        await emailAuth(res.data.user._id)
      }
    }
  }

  return (
    <>
      <Wrapper>
        {step === 0 || !agree1 || !agree2 ? (
          <Flex type="column" width="100%">
            <Flex
              align="center"
              justify="flex-start"
              style={{ width: '100%', height: 56 }}
            >
              <img src={arrowLeft} width={24} height={24} alt="arrow" />
            </Flex>
            <Flex
              width="360px"
              height="2px"
              style={{ backgroundColor: '#f4f4f4' }}
            >
              <Flex
                width="120px"
                height="2px"
                style={{ backgroundColor: '#242424' }}
              />
            </Flex>
            <Flex style={{ width: '100%', padding: '24px 20px' }}>
              <H4 align="left">
                서비스 이용 약관을
                <br />
                동의해주세요.
              </H4>
            </Flex>
            <Flex type="column" style={{ margin: '32px 0 0 0', gap: '20px' }}>
              <Flex width="100%" height="48px" align="center" gap="10px">
                <CheckTextBox
                  checked={agree1 && agree2}
                  onClick={() => {
                    setAgree1(agree1 && agree2 ? false : true)
                    setAgree2(agree1 && agree2 ? false : true)
                  }}
                  text={<H5>전체 동의</H5>}
                />
              </Flex>
              <Flex width="100%" height="48px" align="center" gap="10px">
                <CheckTextBox
                  checked={agree1}
                  onClick={() => {
                    setAgree1(prev => !prev)
                  }}
                  text={
                    <H5
                      style={{ textDecoration: 'underline' }}
                      onClick={() => setShowAgree1(true)}
                    >
                      이용약관 동의(필수)
                    </H5>
                  }
                />
              </Flex>
              <Flex width="100%" height="48px" align="center" gap="10px">
                <CheckTextBox
                  checked={agree2}
                  onClick={() => {
                    setAgree2(prev => !prev)
                  }}
                  text={
                    <H5
                      style={{ textDecoration: 'underline' }}
                      onClick={() => setShowAgree2(true)}
                    >
                      개인정보 수집이용 동의(필수)
                    </H5>
                  }
                />
              </Flex>
            </Flex>
            <NextBtn disabled={!agree1 || !agree2} onClick={() => setStep(1)}>
              다음
            </NextBtn>
          </Flex>
        ) : (
          <Flex
            type="column"
            align="center"
            width="100%"
            style={{ marginTop: 56, padding: '0 20px' }}
          >
            <LogoImg />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Text color="#242424" type="Bold" size="38px">
                {step === 1 ? '회원가입' : '기본정보'}
              </Text>

              <div
                style={{
                  width: '100%',
                  display: step === 1 ? 'initial' : 'none',
                }}
              >
                <FormGroup>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="아이디"
                    type="text"
                    name="account"
                    ref={register({
                      required: true,
                      pattern: /^([a-z0-9_-]{5,20})?$/,
                    })}
                    onChange={() => setIsDuplicateAccount(false)}
                  />
                  {errors.account && (
                    <Text size="14px" type="Medium" color="#ea4653">
                      5 ~ 20자의 영문 소문자, 숫자와 특수 기호(-), (_)만 사용
                      가능합니다.
                    </Text>
                  )}
                  {isDuplicateAccount && (
                    <Text size="14px" type="Medium" color="#ea4653">
                      중복된 아이디입니다.
                    </Text>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    style={{ width: '100%' }}
                    type="password"
                    placeholder="비밀번호 (8자 이상)"
                    name="password"
                    ref={register({ required: true, minLength: 8 })}
                  />
                  {errors.password && errors.password.type === 'minLength' && (
                    <Text size="14px" type="Medium" color="#ea4653">
                      비밀번호 8자 이상 입력해주세요.
                    </Text>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    style={{ width: '100%' }}
                    type="password"
                    placeholder="비밀번호 확인"
                    name="confirm_password"
                    ref={register({
                      required: true,
                      minLength: 8,
                    })}
                    onChange={e => confirmPw(e.target.value)}
                  />
                  {checkConfirm && (
                    <Text size="14px" type="Medium" color="#ea4653">
                      비밀번호가 일치하지 않습니다.
                    </Text>
                  )}
                </FormGroup>
                <NextBtn
                  type="button"
                  onClick={() => setStep(2)}
                  disabled={
                    checkConfirm ||
                    errors.account ||
                    errors.password ||
                    !confirm_password ||
                    !password ||
                    !account
                  }
                >
                  다음
                </NextBtn>
              </div>
              <div
                style={{
                  width: '100%',
                  display: step !== 1 ? 'initial' : 'none',
                }}
              >
                <FormGroup style={{ flexDirection: 'row', gap: '8px' }}>
                  <GenderBtn
                    type="button"
                    active={sex === 'male'}
                    onClick={() => {
                      setSex('male')
                      setShowSexError(false)
                    }}
                  >
                    남성
                  </GenderBtn>
                  <GenderBtn
                    type="button"
                    active={sex === 'female'}
                    onClick={() => {
                      setSex('female')
                      setShowSexError(false)
                    }}
                  >
                    여성
                  </GenderBtn>
                </FormGroup>
                <FormGroup>
                  <Input
                    style={{ width: '100%' }}
                    type="email"
                    placeholder="이메일 주소"
                    name="email"
                    ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                  />
                  {errors.email && errors.email.type === 'pattern' && (
                    <Text size="14px" type="Medium" color="#ea4653">
                      올바르지 않은 이메일 주소입니다.
                    </Text>
                  )}
                  {isDuplicateEmail && (
                    <Text size="14px" type="Medium" color="#ea4653">
                      중복된 이메일입니다.
                    </Text>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="휴대폰 번호(-없이)"
                    name="phone"
                    ref={register({
                      required: true,
                      pattern: /^(?:(010-?\d{4})|(01[1|6|7|8|9]-?\d{3,4}))-?\d{4}$/,
                    })}
                  />
                  {errors.phone && errors.phone.type === 'pattern' && (
                    <Text size="14px" type="Medium" color="#ea4653">
                      올바르지 않은 휴대폰 번호입니다.
                    </Text>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="생년월일 8자리"
                    name="birth"
                    ref={register({ required: true, minLength: 8 })}
                  />
                  {(errors.birth || showSexError === true) && (
                    <Text size="14px" type="Medium" color="#ea4653">
                      생년월일 또는 성별을 바르게 입력해주세요.
                    </Text>
                  )}
                </FormGroup>
                <NextBtn type="submit">가입하기</NextBtn>
              </div>
            </Form>
          </Flex>
        )}
      </Wrapper>
      <ServiceAgreeModal
        show={showAgree1}
        handleClose={() => setShowAgree1(!showAgree1)}
        isAgree={agree1}
        setIsAgree={setAgree1}
      />
      <InfoAgreeModal
        show={showAgree2}
        handleClose={() => setShowAgree2(!showAgree2)}
        isAgree={agree2}
        setIsAgree={setAgree2}
      />
    </>
  )
}

const NextBtn = styled.button`
  max-width: 320px;
  width: 100%;
  height: 48px;
  border-radius: 2px;
  background-color: black;
  color: white;
  border: none;

  font-size: 16px;
  line-height: 1.5;
  text-align: center;

  margin-top: 164px;

  @media ${mobile} {
    margin-top: 0px;

    position: absolute;
    bottom: 20px;
    left: calc(50% - 160px);
  }

  :disabled {
    background-color: #f4f4f4;
    color: #bfbfbf;
  }
`

const GenderBtn = styled.button`
  width: 156px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background-color: #fff;

  font-size: 16px;
  line-height: 1.5;
  text-align: center;

  ${props =>
    props.active
      ? `
    border: solid 1px #242424;
    color: #242424;
  `
      : `
  border: solid 1px #949494;
  color: #646464;
  `}
`
