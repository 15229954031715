import axios from 'axios'
import { API_URL } from '../resources'

const IS_DEV =
  process.env['NODE_ENV'] === 'development' || process.env['NODE_ENV'] === 'dev'

const apiHandler = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
  validateStatus: status => {
    return status >= 200 && status < 300
  },
})

apiHandler.interceptors.response.use(
  response => {
    return response
  },
  error => {
    // console.warn('[error][request]', error)
    if (error && error.response) {
      if (error.response.data) {
        if (error.response.data.toastMessage) {
          // showToast(error.response.data.toastMessage)
        }

        if (error.response.data.message) {
          alert(error.response.data.message)
        }
      }
    }
    else {
      // alert('네트워크 상태를 확인해주세요.')
    }
    throw error
  },
)

export async function axiosApi(
  url,
  method = 'GET',
  data,
  options = {},
  baseURL = API_URL,
) {
  try {
    const _method = method.toLowerCase()
    data =
      _method === 'get'
        ? { ...options, params: { ...data } }
        : _method === 'delete'
          ? { ...options, data }
          : data

    if (IS_DEV) {
      const _startTime = new Date().getTime()
      try {
        const res = await apiHandler[_method](baseURL + url, data, options)
        console.log(
          '[DEV][LOG][axiosApi][respond]',
          `[${new Date().getTime() - _startTime}]`,
          _method,
          url,
          data,
          res['data'],
        )
        return res
      }
      catch (error) {
        console.warn(
          '[DEV][ERROR][axiosApi][respond]',
          `[${new Date().getTime() - _startTime}]`,
          _method,
          url,
          data,
          error,
        )
        throw error
      }
    }
    else {
      const _startTime = new Date().getTime()
      try {
        const res = await apiHandler[_method](baseURL + url, data, options)
        console.log(
          '[DEV][LOG][axiosApi][respond]',
          `[${new Date().getTime() - _startTime}]`,
          _method,
          url,
          data,
          res['data'],
        )
        return res
      }
      catch (error) {
        console.warn(
          '[DEV][ERROR][axiosApi][respond]',
          `[${new Date().getTime() - _startTime}]`,
          _method,
          url,
          data,
          error,
        )
        throw error
      }
    }
  }
  catch (error) {
    console.log('axiosApi error', error)
    throw error
  }
}

export const upload = async (url, formData, config = {}, baseURL = API_URL) => {
  const { headers } = config
  const _config = {
    headers: { 'Content-Type': 'multipart/form-data', ...headers },
  }
  const res = await axios.post(baseURL + url, formData, _config)

  return res
}
