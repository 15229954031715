import React from 'react'
import styled from 'styled-components'

const CheckBoxWrapper = styled.div`
  position: relative;
`
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: calc(50% - 32px);
  width: 48px;
  height: 24px;
  border-radius: 30px;
  /* background: ${({ color }) => (color ? '#949494' : 'black')}; */
  background: rgba(120, 120, 128, 0.16);
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;

    width: 20px;
    height: 20px;
    margin: 2px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 23px;
  width: 48px;
  height: 24px;
  margin: 0;
  &:checked + ${CheckBoxLabel} {
    background: ${({ color }) => (color ? color : 'black')};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-left: 26px;
      transition: 0.2s;
    }
  }
`

const SwitchBtn = ({ isSwitchOn, handleSwitch, color, style, id }) => {
  return (
    <div style={style}>
      <CheckBoxWrapper>
        <CheckBox
          id={id || 'checkbox'}
          type="checkbox"
          checked={isSwitchOn && isSwitchOn}
          onChange={handleSwitch}
          color={color}
        />
        <CheckBoxLabel htmlFor={id || 'checkbox'} color={color} />
      </CheckBoxWrapper>
    </div>
  )
}

export default SwitchBtn
