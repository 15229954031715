import { action } from 'mobx'
import { Store } from '.'
import { Network } from './networks'

export default class AppStateStore {
  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {}

  @action.bound
  async initClient() {}
}
