/* eslint-disable import/no-unresolved */
import { Flex } from '@components/atoms'
import styled from 'styled-components'

export const RSHeader = styled(Flex)`
  width: '100%';
  height: 73px;

  justify-content: space-between;
  align-items: center;
  /* border-bottom: solid 0.5px #242424; */
`
