/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, Text, Image } from '@components/atoms'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { colors } from '@colors/'
import './Sidebar.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Bar = styled(Flex)`
  flex-direction: column;
  padding: 20px 0px 0px 0px;
  width: 200px;
`
const SidebarItem = styled(Flex)`
  padding-left: 60px;
  width: 200px;
  height: 50px;
  background-color: ${props => (props.isSelected ? '#f2f2f2' : '')};
  color: ${props => (props.isSelected ? colors.main : '#646464')};
  border-radius: 0px 25px 25px 0px;
  justify-content: flex-start;
  align-items: center;

  & .selected {
    background-color: #f2f2f2;
  }

  span {
    width: 50%;
  }
`

const SubItem = styled(Flex)`
  padding-right: 20px;
  width: 200px;
  height: 50px;
  background-color: ${props => (props.isSelected ? '#f2f2f2' : '')};
  color: ${props => (props.isSelected ? colors.main : '#646464')};
  border-radius: 0px 25px 25px 0px;
  justify-content: flex-end;
  align-items: center;

  & .selected {
    background-color: #f2f2f2;
  }

  span {
    width: 50%;
  }
`

const SubNavItem = styled(Flex)`
  flex-direction: column;
  width: 200px;
  min-height: 50px;
  background-color: ${props => (props.isSelected ? '#f2f2f2' : '')};
  color: ${props => (props.isSelected ? colors.main : '#646464')};
  border-radius: 0px 25px 25px 0px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  & .selected {
    background-color: #f2f2f2;
  }

  span {
    width: 50%;
  }
`

const SidebarText = styled.div`
  font-family: 'NotoSansCJKkr-medium';
  font-size: '14px';
`

// const NoSideImg = styled(Image).attrs({
//   src: require('@images/nopath_menu.png'),
// })`
//   width: 18px;
//   height: 18px;
// `

const CurrentSideImg = styled(Image).attrs({
  src: require('@images/path_menu.png'),
})`
  width: 18px;
  height: 18px;
`

const SideImg = styled(Image).attrs({
  src: require('@images/nopath_menu.png'),
})`
  width: 18px;
  height: 18px;
`

const OpenSideImg = styled(Image).attrs({
  src: require('@images/open_nav_img.png'),
})`
  width: 18px;
  height: 18px;
`

export const CompanySidebar = ({ artistId, authStore }) => {
  const location = useLocation()
  const history = useHistory()
  const [currentPage, setCurrenPage] = useState('/')

  const [openInsight, setOpenInsight] = useState(true)

  useEffect(() => {
    // setOpenInsight(location.pathname.indexOf('/insight') !== -1)
    setCurrenPage(location.pathname)
  }, [location])

  if (
    currentPage === '/statement'
    || currentPage === '/contract'
    || currentPage === '/statement-list'
    || currentPage === '/statement-list/closed'
  ) {
    return (
      <Bar>
        <NavLink
          exact
          to={`/statement?artistId=${artistId}`}
          activeClassName="sidebar_selected"
        >
          <SidebarItem>
            <SidebarText>작성중인 정산서</SidebarText>
          </SidebarItem>
        </NavLink>
        <NavLink
          exact
          to={`/statement-list?artistId=${artistId}`}
          activeClassName="sidebar_selected"
        >
          <SidebarItem>
            <SidebarText type="Medium" size="14px">
              지난 정산서
            </SidebarText>
          </SidebarItem>
        </NavLink>
        <NavLink
          exact
          to={`/contract?artistId=${artistId}`}
          activeClassName="sidebar_selected"
          onClick={
            event => {
              history.push(`/contract`)
            }
          }
        >
          <SidebarItem>
            <SidebarText type="Medium" size="14px">
              계약 설정
            </SidebarText>
          </SidebarItem>
        </NavLink>
        <NavLink
          exact
          to={`/artist?artistId=${artistId}`}
          activeClassName="sidebar_selected"
          onClick={
            async event => {
              event.preventDefault()
              const res = await authStore.artistViewMode(artistId)
              // history.push(`/artist?artistId=${artistId}`)
              window.open(`/artist?artistId=${artistId}`)
            }
          }
        >
          <SidebarItem>
            <SidebarText type="Medium" size="14px" st>
              아티스트 페이지로 이동
            </SidebarText>
          </SidebarItem>
        </NavLink>
      </Bar>
    )
  }
  return <></>
}

// const handleMoveArtist = async artistId => {
//   await authStore.artistViewMode(artistId)
//   history.push(`/artist?artistId=${artistId}`)
// }
