import { observable } from 'mobx'

export default class FollowerInfoModel {
  @observable _id
  @observable artistId
  @observable updatedAt
  @observable currentFollowerCountDetail
  @observable increaseValue
  @observable filteringDataList

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.artistId = props.artistId
      this.updatedAt = props.updatedAt
      this.currentFollowerCountDetail = props.currentFollowerCountDetail
      this.increaseValue = props.increaseValue
      this.filteringDataList = props.filteringDataList
    }
  }
}
