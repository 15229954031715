import React from 'react'
import { useHistory } from 'react-router-dom'
import { ArtistHeader } from '../ArtistHeader'
import { RSHeader } from '../RSHeader'
import { HeaderBtn } from './styledComponents'

const TopHeader = ({
  allowViewArtistList,
  type,
  currentArtistId,
  imageUri,
  readOnly,
  onClosingClick,
  name,
}) => {
  const history = useHistory()
  return (
    <RSHeader>
      <ArtistHeader
        artistList={allowViewArtistList}
        type={type}
        currentArtistId={currentArtistId}
        imageUri={imageUri}
        name={name}
        onClick={
          artistId => {
            history.push(`/statement?artistId=${artistId}`)
          }
        }
      />
      {
        !readOnly && (
          <HeaderBtn onClick={() => onClosingClick()}>마감완료</HeaderBtn>
        )
      }
    </RSHeader>
  )
}

export default TopHeader
