import React, { useState, useEffect } from 'react'
import { RootTemplate, AlbumTemplate } from '@components/templates'
import {
  AlbumFormModal,
  TrackFormModal,
  LinkCopiedPopup,
  DeletePopup,
} from '@components/organisms'

import { inject, observer } from 'mobx-react'
import qs from 'qs'
import { ArtistStore, AlbumStore } from '../stores'
import { useHistory } from 'react-router-dom'

interface AlbumPageProps {
  artistStore: ArtistStore;
  albumStore: AlbumStore;
  match: Any;
  location: Any;
  logout: Function;
  user: Object;
}

const AlbumPage = ({
  artistStore,
  albumStore,
  match,
  logout,
  user,
  location,
}: AlbumPageProps) => {
  /* use copy toast */
  const history = useHistory()
  const [isCopy, setIsCopy] = useState(false)
  const showCopyToast = () => {
    setIsCopy(true)
    setTimeout(() => setIsCopy(false), 2500)
  }
  /* use copy toast end */

  /* delete album confirm popup */
  const [showAlbumDelete, setShowAlbumDelete] = useState(false)
  const deleteReleaseAlbum = albumStore.deleteReleaseAlbum || (() => {})

  const navigateDeleteAlbum = album => {
    albumStore.albumDetail = album
    setShowAlbumDelete(true)
  }
  /* delete confirm popup end */

  /* delete track confirm popup */
  const [showTrackDelete, setShowTrackDelete] = useState(false)
  const deleteTrack = albumStore.deleteTrack || (() => {})

  const navigateDeleteTrack = async (track, album) => {
    albumStore.albumDetail = album
    await albumStore.fetchTrackDetail(track)
    setShowTrackDelete(true)
  }
  /* delete confirm popup end */

  /* use album modal */
  const [showAlbumModal, setShowAlbumModal] = useState(false)
  const createReleaseAlbum = albumStore.createReleaseAlbum || (() => {})
  const updateReleaseAlbum = albumStore.updateReleaseAlbum || (() => {})
  const crawlAlbum = albumStore.crawlAlbum || (() => {})
  const handleCloseAlbumModal = () => setShowAlbumModal(false)
  const navigateUpdateAlbum = async album => {
    // albumStore.albumDetail = album
    if (album?._id) {
      await albumStore.fetchAlbumDetail(album?._id)
    } else {
      albumStore.albumDetail = album
    }
    setShowAlbumModal(true)
  }
  const albumDetail = albumStore.albumDetail || null
  /* use album modal end */

  /* use track modal */
  const [showTrackModal, setShowTrackModal] = useState(false)
  const createTrack = albumStore.createTrack || (() => {})
  const updateTrack = albumStore.updateTrack || (() => {})
  const handleCloseTrackModal = () => setShowTrackModal(false)
  const navigateUpdateTrack = async (track, album) => {
    albumStore.albumDetail = album
    await albumStore.fetchTrackDetail(track)
    setShowTrackModal(true)
  }
  const trackDetail = albumStore.trackDetail || null
  /* use track modal end */

  const artist = artistStore.artistDetail
  const releaseAlbumList = albumStore.releaseAlbumList.slice() || []

  const [isHasMore, setIsHasMore] = useState(true) // 더보기 유무

  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      // 문자열 맨 앞의 ?를 생력
    })

    const handleFirstFetchReleaseAlbumList = async artistId => {
      const res = await albumStore.firstFetchReleaseAlbumList(artistId, {
        limit: 9,
        sortyBy: 'releaseAt',
      })

      // limit 보다 이하의 값일 경우 더보기 유무 false
      setIsHasMore(res)
    }

    if (query.artistId && query.artistId !== 'null') {
      artistStore.fetchArtistDetail(query.artistId)
      handleFirstFetchReleaseAlbumList(query.artistId)
    } else {
      artistStore.fetchArtistDetail(user && user['artistId'])
      handleFirstFetchReleaseAlbumList(user && user['artistId'])
    }
  }, [user, albumStore, artistStore, location])

  useEffect(() => {
    if (user && user['type'] !== 'company' && !user?.artistId) {
      history.push('/artist')
    }
  }, [user, history])

  return (
    <RootTemplate user={user} artist={artist} logout={logout}>
      <AlbumTemplate
        searchKeywordReleaseAlbumList={albumStore.searchKeywordReleaseAlbumList}
        firstFetchReleaseAlbumList={albumStore.firstFetchReleaseAlbumList}
        moreFetchReleaseAlbumList={albumStore.moreFetchReleaseAlbumList}
        albumList={releaseAlbumList}
        navigateUpdateAlbum={navigateUpdateAlbum}
        navigateUpdateTrack={navigateUpdateTrack}
        navigateDeleteTrack={navigateDeleteTrack}
        navigateDeleteAlbum={navigateDeleteAlbum}
        crawlAlbum={crawlAlbum}
        showCopyToast={showCopyToast}
        user={user}
        artist={artist}
        isHasMore={isHasMore}
        setIsHasMore={setIsHasMore}
      />
      {/* use album modal */}
      <AlbumFormModal
        artist={artist}
        show={showAlbumModal}
        handleClose={handleCloseAlbumModal}
        defaultAlbum={albumDetail}
        createAlbum={album => createReleaseAlbum(album)}
        updateAlbum={album => updateReleaseAlbum(album, albumStore.albumDetail)}
        isUpdating={!!albumDetail}
      />
      {/* use album modal end */}
      {/* use track modal */}
      <TrackFormModal
        artist={artist}
        album={albumDetail}
        show={showTrackModal}
        handleClose={handleCloseTrackModal}
        defaultTrack={trackDetail}
        createTrack={track => createTrack(track, albumStore.albumDetail)}
        updateTrack={track =>
          updateTrack(track, albumStore.trackDetail, albumStore.albumDetail)
        }
        isUpdating={!!trackDetail}
      />
      {/* use track modal end */}
      {/* use copy toast */}
      {isCopy && <LinkCopiedPopup />}
      {/* use copy toast end */}

      {/* use delete album confrim popup  */}
      {showAlbumDelete && (
        <DeletePopup
          text="앨범을 정말로 삭제하시겠습니까?"
          onCancel={() => setShowAlbumDelete(false)}
          onDelete={() => {
            deleteReleaseAlbum(albumStore.albumDetail)
            setShowAlbumDelete(false)
          }}
        />
      )}
      {/* use delete album confrim popup end */}

      {/* use delete track confrim popup  */}
      {showTrackDelete && (
        <DeletePopup
          text="트랙을 정말로 삭제하시겠습니까?"
          onCancel={() => setShowTrackDelete(false)}
          onDelete={() => {
            deleteTrack(albumStore.trackDetail, albumStore.albumDetail)
            setShowTrackDelete(false)
          }}
        />
      )}
      {/* use delete track confrim popup end */}
    </RootTemplate>
  )
}

export default inject('artistStore', 'albumStore')(observer(AlbumPage))
