import { action, observable, } from 'mobx'
import { Store } from '.'
import { Network } from './networks'

import { NoticeModel, } from './models'

export default class NoticeStore {
  @observable noticeDetail = null
  @observable noticeList = []

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.noticeList = []
    this.noticeDetail = null
  }

  @action.bound
  async initClient() {
  }

  async fetchNoticeList(params) {
    return this.store.useLoading(async () => {
      const fetchedDataList = await this.network.noticeNetwork.getList({ __order: 'createdAtDesc', ...params, })
      if (!fetchedDataList) return

      this.noticeList = fetchedDataList.map(fortune => new NoticeModel(this.store, fortune))
      return this.noticeList
    })
  }

  async updateNotice(id, index, data) {
    return this.store.useLoading(async () => {
      if (!id || isNaN(parseInt(index)) || !data) return

      const updatedData = await this.network.noticeNetwork.put(id, data)
      if (!updatedData) return

      this.noticeList[index] = new NoticeModel(this.store, updatedData)
      return this.noticeList[index]
    })
  }

  async createNotice(data) {
    return this.store.useLoading(async () => {
      const createdData = await this.network.noticeNetwork.post(data)
      if (!createdData) return

      const notice = new NoticeModel(this.store, createdData)
      this.noticeList.splice(0, 0, notice)
      return notice
    })
  }

  async deleteNotice(id, index) {
    return this.store.useLoading(async () => {
      if (!id || isNaN(parseInt(index))) return

      const deletedNotice = await this.network.noticeNetwork.delete(id)
      if (!deletedNotice) return

      this.noticeList.splice(index, 1)
      return deletedNotice
    })
  }

}