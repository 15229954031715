import { Network } from '.'

export default class RevenueNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getRevenueArtist(artistId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/artist/${artistId}`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn('RevenueNetwork getRevenueArtist error', error)
    }
  }

  // __albumId query 추가를 통해 앨범 트랙리스트 수익 조회 가능
  async getRevenueArtistTrack(artistId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/artist/${artistId}/track`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn('RevenueNetwork getRevenueArtistTrack error', error)
    }
  }

  async getRevenueTrack(trackId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/track/${trackId}`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn('RevenueNetwork getRevenueTrack error', error)
    }
  }

  async getRevenueTrackArtist(artistObjectId, trackId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/artist/${artistObjectId}/track/${trackId}`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn('RevenueNetwork getRevenueTrackArtist error', error)
    }
  }

  async getRevenueTrackCompany(companyObjectId, trackId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/company/${companyObjectId}/track/${trackId}`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn('RevenueNetwork getRevenueTrackCompany error', error)
    }
  }

  async getRevenueArtistRecentSalesMonth(artistObjectId) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/artist/${artistObjectId}/recent-sales-month`,
        'get',
      )
      return data || []
    } catch (error) {
      console.warn(
        'RevenueNetwork getRevenueArtistRecentSalesMonth error',
        error,
      )
    }
  }

  // 앨범 최근 수익월 조회
  async getRevenueAlbumRecentSalesMonth(artistObjectId, albumObjectId) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/artist/${artistObjectId}/album/${albumObjectId}/recent-sales-month`,
        'get',
      )
      return data || []
    } catch (error) {
      console.warn(
        'RevenueNetwork getRevenueAlbumRecentSalesMonth error',
        error,
      )
    }
  }

  async getRevenueAlbumRecentSalesMonthByCompany(
    companyObjectId,
    albumObjectId,
  ) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/company/${companyObjectId}/album/${albumObjectId}/recent-sales-month`,
        'get',
      )
      return data || []
    } catch (error) {
      console.warn(
        'RevenueNetwork getRevenueAlbumRecentSalesMonthByCompany error',
        error,
      )
    }
  }

  // 앨범 수익 조회
  async getRevenueArtistAlbum(artistObjectId, albumId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/artist/${artistObjectId}/album/${albumId}`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn('RevenueNetwork getRevenueAlbumArtist error', error)
    }
  }

  async getRevenueCompanyRecentSalesMonth(companyObjectId) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/company/${companyObjectId}/recent-sales-month`,
        'get',
      )
      return data || []
    } catch (error) {
      console.warn(
        'RevenueNetwork getRevenueCompanyRecentSalesMonth error',
        error,
      )
    }
  }

  async postRevenueFile(originalFilePath) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/file`,
        'post',
        { originalFilePath },
      )
      return data || []
    } catch (error) {
      console.warn('RevenueNetwork postRevenueFile error', error)
    }
  }

  async getRevenueCompany(companyObejctId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/company/${companyObejctId}`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn('RevenueNetwork getRevenueCompany error', error)
    }
  }

  async getRevenueCompanyTrack(companyObejctId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/company/${companyObejctId}/track`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn('RevenueNetwork getRevenueCompanyTrack error', error)
    }
  }

  async getRevenueCompanyAlbum(companyObjectId, albumId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/company/${companyObjectId}/album/${albumId}`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn('RevenueNetwork getRevenueCompanyAlbum error', error)
    }
  }

  async getRevenueCompanyAnnual(companyObejctId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/company/${companyObejctId}/annual`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn('RevenueNetwork getRevenueCompanyAnnual error', error)
    }
  }

  async getRevenueCompanyArtist(companyObejctId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/company/${companyObejctId}/artist`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn('RevenueNetwork getRevenueCompanyArtist error', error)
    }
  }

  async getRevenueArtistExpectedAPI(artistObjectId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/artist/${artistObjectId}/expected`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn('RevenueNetwork getRevenueArtistExpectedAPI error', error)
    }
  }

  async getRevenueCompanyExpectedAPI(companyObjectId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/company/${companyObjectId}/expected`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn('RevenueNetwork getRevenueCompanyExpectedAPI error', error)
    }
  }

  async getRevenueArtistTrackExpectedAPI(artistObjectId, trackId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/artist/${artistObjectId}/track/${trackId}/expected`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn(
        'RevenueNetwork getRevenueArtistTrackExpectedAPI error',
        error,
      )
    }
  }

  async getRevenueCompanyTrackExpectedAPI(companyObjectId, trackId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/company/${companyObjectId}/track/${trackId}/expected`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn(
        'RevenueNetwork getRevenueCompanyTrackExpectedAPI error',
        error,
      )
    }
  }

  async getRevenueArtistAlbumExpectedAPI(artistObjectId, albumId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/artist/${artistObjectId}/album/${albumId}/expected`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn(
        'RevenueNetwork getRevenueArtistAlbumExpectedAPI error',
        error,
      )
    }
  }

  async getRevenueCompanyAlbumExpectedAPI(companyObjectId, albumId, query) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue/company/${companyObjectId}/album/${albumId}/expected`,
        'get',
        query,
      )
      return data || []
    } catch (error) {
      console.warn(
        'RevenueNetwork getRevenueCompanyAlbumExpectedAPI error',
        error,
      )
    }
  }
}
