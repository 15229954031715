import { useState , useEffect } from 'react';


const useResponsive = () => {
  const [screen, setScreen] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setScreen({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [setScreen]);

  return screen;
};

export default useResponsive;
