import { observable } from 'mobx'

export default class UserModel {
  /* Database fields */
  @observable _id

  @observable account
  @observable email
  @observable isAuthEmail
  @observable password
  @observable birth
  @observable sex
  @observable phone
  @observable isAuthPhone

  @observable createdAt
  @observable updatedAt
  @observable deleteAt

  @observable socialType
  @observable name

  /* Database fields end */
  @observable nowArtistCrawling
  @observable artistId
  @observable companyId
  @observable melonArtistId

  @observable address
  @observable certificatePath
  @observable chiefEmail
  @observable chiefName
  @observable chiefPhone
  @observable companyName
  @observable companyRegistrationNumber
  @observable isInplamNotification
  @observable role
  @observable staffEmail
  @observable staffName
  @observable staffPhone
  @observable type

  @observable artistInfo

  @observable profileImageOriginalPath
  @observable profileImage64Path
  @observable profileImage128Path
  @observable profileImage512Path

  @observable isArtistViewMode

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.account = props.account
      this.email = props.email
      this.isAuthEmail = props.isAuthEmail
      this.password = props.password
      this.birth = props.birth
      this.sex = props.sex
      this.phone = props.phone
      this.isAuthPhone = props.isAuthPhone

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deleteAt = props.deleteAt

      this.socialType = props.socialType
      this.name = props.name

      this.artistId = props.artistId
      this.companyId = props.companyId
      this.nowArtistCrawling = props.nowArtistCrawling
      this.melonArtistId = props.melonArtistId

      this.address = props.address
      this.certificatePath = props.certificatePath
      this.chiefEmail = props.chiefEmail
      this.chiefName = props.chiefName
      this.chiefPhone = props.chiefPhone
      this.companyName = props.companyName
      this.companyRegistrationNumber = props.companyRegistrationNumber
      this.isInplamNotification = props.isInplamNotification
      this.role = props.role
      this.staffEmail = props.staffEmail
      this.staffName = props.staffName
      this.staffPhone = props.staffPhone
      this.type = props.type

      this.artistInfo = props.artistInfo || null

      this.profileImageOriginalPath = props.profileImageOriginalPath
      this.profileImage64Path = props.profileImage64Path
      this.profileImage128Path = props.profileImage128Path
      this.profileImage512Path = props.profileImage512Path

      this.isArtistViewMode = props.isArtistViewMode
    }
  }
}
