import React from 'react'
import styled from 'styled-components'
import { Text } from '@components/atoms'

const Label = styled(Text)`
  font-size: 20px;
  padding-bottom: 16px;
  font-family: NotoSansCJKkr-Medium;
`

const RequiredLabel = styled(Text)`
  font-size: 14px;
  padding-bottom: 16px;
`

export const FormLabel = ({ style, required, children }) => {
  return (
    <Label style={style && style}>
      {children}
      {required && <RequiredLabel style={{ color: 'red' }}> *</RequiredLabel>}
      {required === 'onlyOne' && (
        <RequiredLabel style={{ color: 'red' }}> 1개 선택</RequiredLabel>
      )}
      {required === 'minOne' && (
        <RequiredLabel style={{ color: 'red' }}>
          {' '}
          최소 1개 이상 입력
        </RequiredLabel>
      )}
    </Label>
  )
}
