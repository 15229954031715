class AutoCompleteCodeModel {
  _id = ''
  name = ''
  constructor(props) {
    if (props) {
      this._id = props._id || ''
      this.name = props.name || ''
    }
  }
  toIdObj() {
    return {
      _id: this._id || this.name,
      isPLAM: !!this._id,
    }
  }
}

export default AutoCompleteCodeModel
