import React from 'react'
import styled from 'styled-components'
import { Flex, Text, Button, Image } from '@components/atoms'

import { firstRevenuePageLogoImg } from '@images'
import { inject } from 'mobx-react'

const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 124px;
`
const EditArtistButton = styled(Button)`
  width: 230px;
  height: 48px;
  background-color: #242424;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  color: #f4f4f4;
  font-family: NotoSansCJKkr-Medium;

  :hover {
    background-color: #d4d4d4;
  }
`

const _NoRevenue = ({
  authStore,
  typeToggle,
}) => {

  const isLoginedMaster = authStore.isAdminPassword

  if (isLoginedMaster) {
    return (
      <Container>
        <Image
          src={firstRevenuePageLogoImg}
          width="188px"
          height="172px"
          alt="first_logo"
        />
        <Text style={{ margin: '24px 0 28px', textAlign: 'center' }}>
          음원 유통정산 데이터를 보내주시면
          <br />
          영업일 기준 <Text type="bold">48시간 이내</Text>로 반영됩니다.
        </Text>
        <EditArtistButton onClick={() => typeToggle()}>업로드</EditArtistButton>
      </Container>
    )
  }
  else {
    return (
      <Container>
        <Image
          src={firstRevenuePageLogoImg}
          width="188px"
          height="172px"
          alt="first_logo"
        />
        <Text style={{ margin: '24px 0 28px', textAlign: 'center' }}>
          음원 유통정산 데이터를 보내주시면
          <br />
          영업일 기준 <Text type="bold">48시간 이내</Text>로 반영됩니다.
          <br />
          <br />
          계정 관리자에게 데이터 업로드를 요청하세요
        </Text>
      </Container>
    )
  }
}

export const NoRevenue = inject(
  'authStore',
)(_NoRevenue)
