/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react'
import { Modal, Spin } from 'antd'
import { Text } from '@components/atoms'
import styled from 'styled-components'
import {
  CaretLeftFilled,
  CaretRightFilled,
  LoadingOutlined,
} from '@ant-design/icons'
import { formatMonth } from '@utils/format'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const YearChangeContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
`

const SettlementListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background-color: #fafafa;
  padding-left: 40px;
  padding-right: 20px;
`

const SettlementListHeaderText = styled.text`
  font-size: 12px;
  font-family: NotoSansCJKkr-Regular;
  color: #646464;
`

const SettlementListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;
  cursor: pointer;

  align-items: center;
  border-bottom: 1px solid #f4f4f4;
`

const SettlementItemLeftContainer = styled.div`
  display: flex;
  align-items: center;
`

const RadioButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SettlementItemText = styled(Text)`
  font-size: 14px;
  font-family: NotoSansCJKkr-Regular;
  color: #242424;
`

const LoadMusicDataModal = ({
  isVisible,
  onClose,
  onAddComplete,
  fetchData,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [year, setYear] = useState(new Date().getFullYear())
  const [data, setData] = useState(null)
  const [selectedSettlement, setSelectedSettlement] = useState(null)

  const artistMonthlySettlementList =
    data?.settlementInfo?.artistMonthlySettlementList ?? []

  useEffect(() => {
    const loadData = async () => {
      if (isVisible) {
        const fetchedData = await fetchData(adjustYear(year))
        setData(fetchedData)
      }
    }

    loadData()
  }, [isVisible, year])

  const handleClose = () => {
    onClose()
    setYear(new Date().getFullYear())
    setData(null)
    setSelectedSettlement(null)
  }

  const handleYearBackward = () => {
    setYear(year - 1)
    setSelectedSettlement(null)
  }

  const handleYearForward = () => {
    if (year === new Date().getFullYear()) return
    setYear(year + 1)
    setSelectedSettlement(null)
  }

  const adjustYear = _year => {
    return Math.floor(_year % 100) * 100
  }

  const handleSelectSettlement = item => {
    setSelectedSettlement(item)
  }

  const handleAddComplete = async () => {
    if (!selectedSettlement) return
    setIsLoading(true) // 로딩 시작
    try {
      await onAddComplete(selectedSettlement)
    }
    finally {
      setIsLoading(false) // 로딩 종료
    }
  }

  return (
    <Modal
      open={isVisible}
      onCancel={handleClose}
      onOk={handleAddComplete}
      okText={'불러오기'}
      cancelText="취소"
      okButtonProps={
        {
          disabled: !selectedSettlement || isLoading, // selectedSettlement가 없으면 버튼 비활성화
          style: {
            backgroundColor:
            selectedSettlement && !isLoading ? '#1890ff' : '#d9d9d9', // 선택된 항목이 있으면 파란색, 없으면 회색
            borderColor: selectedSettlement ? '#1890ff' : '#d9d9d9',
            color: 'white',
          },
        }
      }
    >
      <Container>
        <Text type="bold" size="20px">
          음원 정산 내역 불러오기
        </Text>
        <YearChangeContainer>
          <CaretLeftFilled onClick={handleYearBackward} />
          <Text>{year}</Text>
          <CaretRightFilled
            onClick={handleYearForward}
            style={{ color: year === new Date().getFullYear() && '#d4d4d4' }}
          />
        </YearChangeContainer>
        <SettlementListHeader>
          <SettlementListHeaderText>월</SettlementListHeaderText>
          <SettlementListHeaderText>아티스트 정산금액</SettlementListHeaderText>
        </SettlementListHeader>
        {
          artistMonthlySettlementList
          && artistMonthlySettlementList.map((item, index) => (
            <SettlementListItem
              key={index}
              onClick={() => handleSelectSettlement(item)}
            >
              <SettlementItemLeftContainer>
                <RadioButton>
                  <input
                    type="radio"
                    name="settlementSelection"
                    value={item._id}
                    checked={selectedSettlement?._id === item._id}
                    onChange={() => handleSelectSettlement(item)} // onChange 이벤트 핸들러 추가
                    onClick={event => event.stopPropagation()}
                  />
                </RadioButton>
                <SettlementItemText>
                  {formatMonth(item.month)}
                </SettlementItemText>
              </SettlementItemLeftContainer>
              <SettlementItemText>
                {
                  Math.floor(item.artistMonthlySettlementFee).toLocaleString(
                    'ko-KR',
                  )
                }
                원
              </SettlementItemText>
            </SettlementListItem>
          ))
        }
      </Container>
    </Modal>
  )
}

export default LoadMusicDataModal
