/* eslint-disable import/no-unresolved */
import { observer, inject } from 'mobx-react'
import React, { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { RootTemplate } from '@components/templates'
import qs from 'qs'
import styled from 'styled-components'
import { MaterialReactTable } from 'material-react-table'
import { Spin, Button } from 'antd'
import { Flex, Text, Image } from '@components/atoms'

import 'react-data-grid/lib/styles.css'
import moment from 'moment'

import { numberWithCommas } from '@utils/format'
import { ArtistHeader } from './components/ArtistHeader'
import { RoyaltyStatementContainer } from './components/RoyaltyStatementContainer'
import { RSHeader } from './components/RSHeader'
import { ListEmpty } from './components/listPage/ListEmpty'
import { CompanySelector } from './components/CompanySelector'
import { borderColor } from './styles'
import { PAGE_TYPE } from './consts'

const SheetContainer = styled(Flex)`
  flex-direction: column;
  max-width: calc(100vw - 280px);
`

moment.locale('ko')

const CompanyArtistStatementListPage = ({
  location,
  authStore,
  artistStore,
  user,
  adjustmentStore,
  adjustmentContractStore,
}) => {
  const history = useHistory()
  const artist = artistStore.artistDetail
  const [currentArtistId, setCurrentArtistId] = useState(null)
  const [allowViewArtistList, setAllowViewArtistList] = useState([]) // 정산서 조회 권한이
  const [isLoading, setIsLoading] = useState(true)
  const logout = authStore?.logout || (() => {})

  // company selector
  const { selectedCompanyId, setSelectedCompanyId } = adjustmentContractStore
  const [companyList, setCompanyList] = useState([])

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const _fetchContractCompanyList = async () => {
    if (user?.type === 'company') {
      adjustmentContractStore.selectedCompanyId = user.companyId
    }
    else if (user?.type === 'artist') {
      const companys = await adjustmentStore.fetchAdjustmentCompanyList({
        artistId: query.artistId,
      })
      if (!companys || companys.length === 0) {
        // alert('소속사를 불러오는데 실패했습니다. 관리자에게 문의하세요')
        console.error('소속사를 불러오는데 실패했습니다.')
        setIsLoading(false)
        return
      }
      setCompanyList(companys)
      if (!adjustmentContractStore.selectedCompanyId) {
        adjustmentContractStore.selectedCompanyId = companys?.[0]._id
      }
    }
    else {
      adjustmentContractStore.selectedCompanyId = null
    }
  }

  const _fetchAdjustmentList = async () => {
    if (query.artistId && query.artistId !== 'null') {
      // 현재 보고 있는 아티스트 (개인, 그룹 구별용)
      setCurrentArtistId(query.artistId)
      if (user?.type === 'company') {
        await authStore.artistViewMode(query.artistId)
      }
      await adjustmentStore.fetchAdjustmentList({
        artistId: query.artistId,
        companyId: adjustmentContractStore.selectedCompanyId,
      })
    }
  }

  const _fetchAdjustmentAllowArtistList = async () => {
    // 아티스트가 그룹도 보기위해..
    if (user?.type === 'artist') {
      // currentArtistId가 아니라 현재 아티스트 아이디
      const artists = await adjustmentStore.fetchAdjustmentAllowArtistList()

      if (artists && artists.length > 0) {
        const _sortedArtist = artists.sort((a, b) => {
          if (a.type === '그룹' && b.type === '개인') return -1 // '그룹'이 '개인'보다 먼저 오도록
          if (a.type === '개인' && b.type === '그룹') return 1 // '개인'이 '그룹'보다 나중에 오도록
          return 0 // 동일한 경우 순서 변경 없음
        })
        setAllowViewArtistList([..._sortedArtist])
      }
    }
  }

  const _init = async () => {
    setIsLoading(true)
    await _fetchContractCompanyList()
    await _fetchAdjustmentList()
    await _fetchAdjustmentAllowArtistList()
    setIsLoading(false)
  }

  // 아티스트 회사 변경시 refetch 용
  const _fetch = async () => {
    if (user?.type === 'company' || !adjustmentContractStore.selectedCompanyId)
      return

    setIsLoading(true)
    await _fetchAdjustmentList()
    await _fetchAdjustmentAllowArtistList()
    setIsLoading(false)
  }

  useEffect(() => {
    _init()
  }, [artistStore])

  useEffect(() => {
    _fetch()
  }, [selectedCompanyId])

  const onDetailClick = ({ adjustmentId } = {}) => {
    if (adjustmentId) {
      history.push(
        `/statement-list/closed?artistId=${user.artistId}&adjustmentId=${adjustmentId}`,
      )
    }
  }

  const columnHeaderAlign = 'center'

  const columns = useMemo(() => [
    {
      accessorKey: 'term',
      header: '정산 기간',
      muiTableHeadCellProps: {
        sx: {
          width: '300px',
          minWidth: '300px',
          flex: '0 0 auto',
        },
      }, // custom props
      muiTableBodyCellProps: {
        sx: {
          width: '300px',
          minWidth: '300px',
          flex: '0 0 auto',
        },
      },
      Cell: ({ cell }) => {
        return <Text>{cell.getValue()}</Text>
      },
    },
    {
      accessorKey: 'title',
      header: '제목',
      muiTableHeadCellProps: {
        sx: { flex: '1 0 auto' },
      }, // custom props
      muiTableBodyCellProps: {
        sx: { flex: '1 0 auto', whiteSpace: 'break-word' },
      },
      Cell: ({ cell }) => {
        return <Text color={'black'}>{cell.getValue()}</Text>
      },
    },
    {
      accessorKey: 'amountInfo.amount',
      header: '합계금액(-포함)',
      muiTableHeadCellProps: {
        sx: {
          width: '200px',
          minWidth: '200px',
          flex: '0 0 auto',
        },
      }, // custom props
      muiTableBodyCellProps: {
        sx: {
          width: '200px',
          minWidth: '200px',
          flex: '0 0 auto',
        },
      },
      Cell: ({ cell }) => {
        return <Text>{`${numberWithCommas(cell.getValue()) || 0}원`}</Text>
      },
    },
    {
      accessorKey: 'amountInfo.finalAdjustmentAmount',
      header: '정산금액',
      muiTableHeadCellProps: {
        sx: {
          width: '200px',
          minWidth: '200px',
          flex: '0 0 auto',
        },
      }, // custom props
      muiTableBodyCellProps: {
        sx: {
          width: '200px',
          minWidth: '200px',
          flex: '0 0 auto',
        },
      },
      Cell: ({ cell }) => {
        return <Text>{`${numberWithCommas(cell.getValue()) || 0}원`}</Text>
      },
    },
  ])

  const { imageUri, name } = artist || {}

  const selectedCompany = companyList.find(
    company => company._id === selectedCompanyId,
  )

  const isDeleted = selectedCompany?.connectionInfo?.isDeleted

  return (
    <>
      <RootTemplate user={user} artist={artist} logout={logout}>
        <RoyaltyStatementContainer>
          <RSHeader>
            <ArtistHeader
              artistList={allowViewArtistList}
              type={user?.type}
              currentArtistId={currentArtistId}
              imageUri={imageUri}
              name={name}
              onClick={
                artistId => {
                  history.push(`/statement-list?artistId=${artistId}`)
                }
              }
            />
          </RSHeader>
          <CompanySelector
            isLender={user?.type === 'artist' && !isLoading}
            onChange={
              _companyId => {
                setSelectedCompanyId(_companyId)
              }
            }
            companyList={companyList}
            selectedCompanyId={selectedCompanyId}
            isDeleted={isDeleted}
            pageType={PAGE_TYPE.LIST_PAGE}
          />
          <div style={{ marginTop: '20px' }} />
          {
            isLoading ? (
              <Spin />
            ) : adjustmentStore.adjustmentList.length > 0 ? (
              <SheetContainer>
                <MaterialReactTable
                  columns={columns}
                  data={adjustmentStore.adjustmentList}
                  // enableBottomToolbar={false}
                  enableTopToolbar={false}
                  enableColumnActions={false}
                  enableStickyFooter={false}
                  enableRowNumbers
                  displayColumnDefOptions={
                    {
                      'mrt-row-numbers': {
                        header: '', // change header text
                        muiTableHeadCellProps: {
                          sx: {
                            width: '30px',
                            minWidth: '30px',
                            flex: '0 0 auto',
                          },
                        },
                        muiTableBodyCellProps: {
                          sx: {
                            color: 'grey',
                            width: '30px',
                            minWidth: '30px',
                            flex: '0 0 auto',
                          },
                        },
                      },
                    }
                  }
                  muiTablePaperProps={
                    {
                      sx: {
                        boxShadow: 'none',
                        border: borderColor,
                        maxHeight: '100vh',
                        td: {
                          color: '#242424',
                        },
                        th: {
                          color: '#949494',
                        },
                        '& td > span': {
                          display: 'inline-block',
                          minHeight: '19px',
                        },
                        '& thead > tr': {
                          backgroundColor: '#fafafa',
                          borderBottom: borderColor,
                        },
                        '& td, th': {
                          padding: '8px 4px',
                          fontFamily: 'NotoSansCJKkr-Regular',
                          fontWeight: 400,
                          borderRight: borderColor,
                          borderBottom: 'none',
                          ':last-child': {
                            borderRight: 'none',
                          },
                        },
                        '& tr:not(:last-child)': {
                          borderBottom: borderColor,
                        },
                      },
                    }
                  }
                  muiTableHeadCellProps={
                    {
                      align: columnHeaderAlign,
                    }
                  }
                  muiTableBodyCellProps={
                    {
                      align: columnHeaderAlign,
                    }
                  }
                  muiTableBodyRowProps={
                    ({ row }) => ({
                      onClick: () =>
                        onDetailClick({
                          adjustmentId:
                        adjustmentStore.adjustmentList[row.index]._id,
                        }),
                      sx: {
                        cursor: 'pointer', // you might want to change the cursor too when adding an onClick
                      },
                    })
                  }
                  muiTablePaginationProps={
                    {
                      rowsPerPageOptions: [],
                      labelDisplayedRows: ({ from, to, count }) => {
                        // 하단 display
                        return `${from}-${to} / ${count}`
                      },
                    }
                  }
                  initialState={
                    {
                      density: 'compact',
                      pagination: {
                        pageSize: 15, // 기본 페이지 크기를 15로 설정
                        pageIndex: 0, // 기본 페이지 인덱스를 0으로 설정
                      },
                    }
                  }
                  layoutMode="grid"
                />
              </SheetContainer>
            ) : (
              <ListEmpty />
            )
          }
        </RoyaltyStatementContainer>
      </RootTemplate>
    </>
  )
}

export default inject(
  'authStore',
  'artistStore',
  'adjustmentStore',
  'adjustmentContractStore',
)(observer(CompanyArtistStatementListPage))
