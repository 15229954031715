import React from 'react'
import styled from 'styled-components'
import { Flex, Button } from '@components/atoms'
import './Modal.css'

export const ModalContent = styled(Flex)`
  justify-content: space-between;
`

export const ModalFooter = styled(Flex)`
  flex-direction: column;
  margin-top: 6px;
  min-height: 93px;
  justify-content: center;
  align-items: center;
`

const CloseBtn = styled(Button)`
  width: fit-content;
  position: absolute;
  top: 20px;
  right: 20px;
`
const x_btn = require('@images/x_btn_img.png')

export const Modal = ({ show, handleClose, children, style }) => {
  return (
    <>
      {show && (
        <div className="modal_outer">
          <div className="modal_inner" style={style && style}>
            <CloseBtn onClick={() => handleClose()}>
              <img src={x_btn} alt="close_btn" width="20px" height="20px" />
            </CloseBtn>
            {children}
          </div>
        </div>
      )}
    </>
  )
}
