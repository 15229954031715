import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, Text, Button, Image, Container } from '@components/atoms'
import {
  NoArtistInfo,
  ArtistInfo,
  ArtistFormModal,
  LinkCopiedPopup,
  AccountCustomUrlModal,
  ConfirmPopup,
  ArtistConnectionInfo,
  AccountManagePopup,
} from '@components/organisms'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'
import { dateDiff, dateYMD } from '@utils/format'
import { colors } from '@colors/'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  padding-right: 40px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
`

const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: #ffffff;
`

const HeaderBtnImg = styled(Image)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`

const SubMenu = styled.div`
  text-align: center;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 100;
  width: 156px;
  height: 57px;
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 7px;
`

const MenuList = styled.div`
  width: 156px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #242424;
  font-size: 12px;
`

export const ArtistTemplate = ({
  isOpenUpdate,
  isMyView,
  user,
  artist,
  onUpdateArtist,
  onCreateArtist,
  crawlArtist,
  checkSubdomain,
  accountSubdomain,
  modifySubdomain,
  createStageName,
  updateStageName,
  removeStageName,
  artistCompanyConnectionList,
  artistConnectionList,
  newArtistConnectionList,
  newCompanyConnectionList,
  removeConnection,
  updateAccountConnection,
  createConnectionsArtists,
  fetchArtistDetail,
}) => {
  const history = useHistory()
  const [showUpdate, setShowUpdate] = useState(false) // 아티스트 정보 입력 모달
  const [isCopy, setIsCopy] = useState(false) // 복사 팝업
  const [showModifiable, setShowModifiable] = useState(false)
  const [isCustomUrlUpdate, setIsCustomUrlUpdate] = useState(false)

  const [newAccountItem, setNewAccountItem] = useState({})

  const { isShowing, toggle } = AccountCustomUrlModal.useModal()
  const {
    isShowing: showConfirm,
    toggle: confirmToggle,
  } = ConfirmPopup.usePopup()
  const {
    isShowing: showCompleteAccount,
    toggle: completeAccountToggle,
  } = ConfirmPopup.usePopup()

  const {
    isShowing: showRejectAccount,
    toggle: rejectAccountToggle,
  } = ConfirmPopup.usePopup()

  const {
    isShowing: showAccountArtist,
    toggle: accountArtistToggle,
  } = AccountManagePopup.usePopup()

  const handleClose = () => {
    setShowUpdate(false)
  }

  // 주소 복사 팝업
  const handleCopy = () => {
    setIsCopy(true)
    setTimeout(() => setIsCopy(false), 2500)
  }

  const url_copy_img = require('@images/copyUrl_img.png')
  const modify_img = require('@images/modify_img.png')

  useEffect(() => {
    if (
      artist &&
      artist.subdomainInfo &&
      artist.subdomainInfo.isAccepted &&
      !artist.subdomainInfo.isRejected &&
      !artist.isExistsSubdomainNotificaion
    ) {
      completeAccountToggle()
    }

    if (
      artist &&
      artist.subdomainInfo &&
      artist.subdomainInfo.isRejected &&
      !artist.isExistsSubdomainNotificaion
    ) {
      rejectAccountToggle()
    }
    // eslint-disable-next-line
  }, [artist])

  useEffect(() => {
    if (newArtistConnectionList && newArtistConnectionList.length > 0) {
      setNewAccountItem({
        id: newArtistConnectionList[0]._id,
        name: newArtistConnectionList[0].requesterArtistInfo.name,
        type: 'artists',
        role: newArtistConnectionList[0].requesterArtistInfo.type,
      })
    }
    if (newCompanyConnectionList && newCompanyConnectionList.length > 0) {
      setNewAccountItem({
        id: newCompanyConnectionList[0]._id,
        name: newCompanyConnectionList[0].companyInfo.companyName,
        role: newCompanyConnectionList[0].requesterRole,
        type: 'companies',
      })
    } else if (
      newArtistConnectionList.length === 0 &&
      newCompanyConnectionList.length === 0
    ) {
      setNewAccountItem(null)
    }
  }, [newArtistConnectionList, newCompanyConnectionList])

  useEffect(() => {
    if (!showAccountArtist && newAccountItem) accountArtistToggle()
    if (showAccountArtist && !newAccountItem) accountArtistToggle()
  }, [showAccountArtist, newAccountItem, accountArtistToggle])

  useEffect(() => {
    if (isOpenUpdate) setShowUpdate(true)
  }, [isOpenUpdate, setShowUpdate])

  return (
    <Container>
      <Header>
        <Text type="Medium" size="18px" style={{ marginTop: '10px' }}>
          마이페이지
        </Text>
        {artist && (
          <Flex style={{ alignItems: 'center' }}>
            <Text type="Medium" size="18px">
              {artist && artist.artistUrl}
            </Text>
            <CopyToClipboard
              text={artist && artist.artistUrl}
              onCopy={() => handleCopy()}
            >
              <HeaderBtn>
                <HeaderBtnImg src={url_copy_img} alt="header_btn" />
                <Text type="Medium" size="14px" color={colors.main}>
                  URL 복사
                </Text>
              </HeaderBtn>
            </CopyToClipboard>
            {artist && artist.isOwner && (
              <>
                <HeaderBtn onClick={() => setShowUpdate(true)}>
                  <HeaderBtnImg src={modify_img} alt="header_btn" />
                  <Text type="Medium" size="14px" color={colors.main}>
                    페이지 수정
                  </Text>
                </HeaderBtn>
                {artist &&
                artist.subdomainInfo &&
                (!artist.subdomainInfo.isAccepted ||
                  artist.subdomainInfo.nextSubdomain) ? (
                  <HeaderBtn
                    style={{
                      backgroundColor: '#f4f4f4',
                      borderColor: '#f4f4f4',
                      cursor: 'default',
                    }}
                  >
                    <Text type="Medium" size="14px" color="#949494">
                      커스텀 URL 심사 중
                    </Text>
                  </HeaderBtn>
                ) : artist.subdomainInfo && artist.subdomainInfo.isAccepted ? (
                  dateDiff(
                    new Date(artist.subdomainInfo.modifiableAt),
                    new Date(),
                  ) >= 0 ? (
                    <HeaderBtn
                      onClick={() => {
                        setIsCustomUrlUpdate(true)
                        toggle()
                      }}
                    >
                      <Text type="Medium" size="14px" color={colors.main}>
                        커스텀 URL 수정
                      </Text>
                    </HeaderBtn>
                  ) : (
                    <HeaderBtn
                      style={{
                        backgroundColor: '#f4f4f4',
                        borderColor: '#f4f4f4',
                        cursor: 'default',
                        position: 'relative',
                      }}
                      onClick={() => setShowModifiable(true)}
                      onMouseOver={() => setShowModifiable(true)}
                      onMouseLeave={() => setShowModifiable(false)}
                    >
                      <Text type="Medium" size="14px" color="#949494">
                        커스텀 URL 수정
                      </Text>
                      {showModifiable && (
                        <SubMenu>
                          <MenuList>수정 가능 기간이 아닙니다.</MenuList>
                          <MenuList
                            style={{ color: '#ea4653', marginTop: '7px' }}
                          >
                            {`${dateYMD(
                              artist &&
                                artist.subdomainInfo &&
                                artist.subdomainInfo.modifiableAt,
                            )} 수정 가능`}
                          </MenuList>
                        </SubMenu>
                      )}
                    </HeaderBtn>
                  )
                ) : (
                  <HeaderBtn onClick={() => toggle()}>
                    <Text type="Medium" size="14px" color={colors.main}>
                      커스텀 URL 신청
                    </Text>
                  </HeaderBtn>
                )}
              </>
            )}
          </Flex>
        )}
      </Header>
      <Content>
        {artist ? (
          <>
            <ArtistInfo
              artist={artist}
              onUpdateArtist={onUpdateArtist}
              createStageName={createStageName}
              updateStageName={updateStageName}
              removeStageName={removeStageName}
            />
            {isMyView && (
              <ArtistConnectionInfo
                artistCompanyConnectionList={artistCompanyConnectionList}
                artistConnectionList={artistConnectionList}
                user={user}
                artist={artist}
                removeConnection={removeConnection}
                createConnectionsArtists={createConnectionsArtists}
                fetchArtistDetail={fetchArtistDetail}
              />
            )}
          </>
        ) : (
          <NoArtistInfo
            onCreateArtist={onCreateArtist}
            user={user}
            crawlArtist={crawlArtist}
          />
        )}
      </Content>
      <ArtistFormModal
        show={showUpdate}
        handleClose={handleClose}
        defaultArtist={artist}
        onUpdateArtist={onUpdateArtist}
        isUpdating
      />
      {isCopy && <LinkCopiedPopup />}
      <AccountCustomUrlModal.View
        isUpdate={isCustomUrlUpdate}
        isArtist
        isShowing={isShowing}
        toggle={toggle}
        confirmToggle={confirmToggle}
        checkSubdomain={checkSubdomain}
        accountSubdomain={accountSubdomain}
        modifySubdomain={modifySubdomain}
      />
      <ConfirmPopup.View
        isShowing={showConfirm}
        toggle={confirmToggle}
        title={`커스텀 URL 신청 완료`}
        body={`24시간 이내 승인됩니다.`}
        onClose={() => history.go(0)}
      />
      <ConfirmPopup.View
        isShowing={showCompleteAccount}
        toggle={completeAccountToggle}
        title={'커스텀 URL 승인'}
        body={'60일에 한번 씩 수정할 수 있습니다.'}
        onClose={() => {
          completeAccountToggle()
          onUpdateArtist({
            _id: artist._id,
            isExistsSubdomainNotificaion: true,
          })
        }}
      />
      <ConfirmPopup.View
        isShowing={showRejectAccount}
        toggle={rejectAccountToggle}
        title={'커스텀 URL 반려'}
        body={'자세한 사항은 채널톡으로 문의주시기 바랍니다.'}
        onClose={() => {
          rejectAccountToggle()
          onUpdateArtist({
            _id: artist._id,
            isExistsSubdomainNotificaion: true,
          })
        }}
      />
      <AccountManagePopup.View
        type={newAccountItem && newAccountItem.type}
        isShowing={showAccountArtist}
        toggle={accountArtistToggle}
        target={newAccountItem}
        onOk={() => {
          updateAccountConnection(newAccountItem.type, newAccountItem.id, {
            isAccepted: true,
          })
        }}
        onCancel={() => {
          updateAccountConnection(newAccountItem.type, newAccountItem.id, {
            isRejected: true,
          })
        }}
      />
    </Container>
  )
}
