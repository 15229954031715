import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Button } from '@components/atoms'
import { colors } from '@colors/'
import { x_btn_img, add_btn } from '@images/'

const CreateBtn = styled(Button)`
  width: 440px;
  height: 40px;
  border-radius: 6px;
  border: solid 1px #d4d4d4;
  background-color: #ffffff;
  justify-content: start;
  padding: 12px;
  color: #242424;
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
`

const StageNameItem = styled.input`
  width: 440px;
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
  height: 40px;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 10px 12px;
  margin: 0;
  /* valid 체크 css */
  border: solid 1px ${colors.black};
  :focus {
    border: solid 1px ${colors.water_blue};
  }
`

const RemoveBtn = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
`

const StageNameBox = ({
  item,
  createStageName,
  updateStageName,
  removeStageName,
  isOwner,
}) => {
  const [inputValue, setInputValue] = useState(item && item.name) // 활동명 값
  const [isCreate, setIsCreate] = useState(false) // 생성 모드 유무

  const inputRef = useRef() // 입력 박스 ref

  const handleCreate = async inputValue => {
    await createStageName(inputValue)
    setInputValue('')
    setIsCreate(false)
  }

  const handleClickCreate = () => {
    setIsCreate(true)
    // 가상으로 생기는 ref sync 문제 임시 방편
    setTimeout(() => {
      inputRef.current.focus()
    }, 1)
  }

  const handleUpdate = (id, inputValue) => {
    // 값의 변화가 없을 시
    if (inputValue === item.name) return
    updateStageName(id, inputValue)
  }

  // 활동명 item이 있을 때
  if (item) {
    return (
      <>
        <StageNameItem
          ref={inputRef}
          value={inputValue}
          onChange={e => isOwner && setInputValue(e.target.value)}
          onKeyDown={e => e.keyCode === 13 && inputRef.current.blur()}
          onBlur={() => isOwner && handleUpdate(item._id, inputValue)}
        />
        {isOwner && (
          <RemoveBtn
            src={x_btn_img}
            onClick={() => removeStageName(item._id)}
          />
        )}
      </>
    )
    // 생성 모드 일 때
  }
  if (!isOwner) {
    return <></>
  }
  if (isCreate) {
    return (
      <StageNameItem
        ref={inputRef}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onKeyDown={e => (e.keyCode === 13 ? handleCreate(inputValue) : {})}
        onBlur={() =>
          !inputValue ? setIsCreate(false) : handleCreate(inputValue)
        }
      />
    )
    // 활동명 값이 없을 때, 추가 버튼
  }
  return (
    <CreateBtn onClick={() => handleClickCreate()}>
      <img
        src={add_btn}
        style={{ width: '16px', height: '16px', marginRight: '8px' }}
        alt="add_btn_img"
      />
      활동명 입력
    </CreateBtn>
  )
}

export default StageNameBox
