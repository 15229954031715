import React from 'react'
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons'
import moment from 'moment'
import { RowDiv, SavingTextContainer } from './styledComponents'

const TitleSavingStatus = ({ isSaving, showSaving, lastSavedTime }) => (
  <RowDiv>
    <h3>정산서 제목</h3>
    {
      showSaving && (
        <SavingTextContainer isSaving={isSaving}>
          {isSaving ? <LoadingOutlined /> : <CheckOutlined />}
          {isSaving ? ' 저장 중...' : ' 저장됨'}
        </SavingTextContainer>
      )
    }
    {
      lastSavedTime && (
        <SavingTextContainer isSaving="true">
          {`${moment(lastSavedTime).format('YYYY년 MM월 DD일 HH:mm')} 저장`}
        </SavingTextContainer>
      )
    }
  </RowDiv>
)

export default TitleSavingStatus
