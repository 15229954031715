import React, { useState } from 'react'
import styled from 'styled-components'
import { H4, Flex, Input, Caption1 } from '@components/atoms'
import { CustomModal } from '@components/molecules'
import { DOMAIN_URL } from '@consts'
import { colors } from '@colors/'

const ModalBody = styled(Flex)`
  width: 580px;
  /* height: 300px; */
  padding: 30px;
  flex-direction: column;
  align-items: center;
`

const Btn = styled.div`
  display: flex;
  font-family: NotoSansCJKkr-Medium;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 48px;
  border-radius: 25px;
  cursor: pointer;
  background-color: ${props => (props.disabled ? '#f4f4f4' : colors.main)};
  color: ${props => (props.disabled ? '#949494' : '#f4f4f4')};
  border: ${props => (props.disabled ? 'none' : colors.main)};
`

const PlaceHolder = styled(Caption1)`
  color: #949494;
  align-self: flex-end;
  width: fit-content;
  white-space: nowrap;
  margin: 0;
  padding: 0;
`

const View = ({
  isShowing,
  toggle,
  pageType,
  accountSubdomain,
  confirmToggle,
  checkSubdomain,
  isUpdate,
  isArtist,
  subdomain,
  modifySubdomain,
  modifyCustomUrl,
  dataType,
  dataObjectId,
}) => {
  const [customUrl, setCustomUrl] = useState('')
  const [isError, setIsError] = useState('')

  const handleChangeUrl = async value => {
    setCustomUrl(value)
    // 1~20자의 영문 소문자, 숫자, 특수기호 (-), (_)만 사용 정규 표현식
    const regExp = /^[0-9a-z.;_-]{1,20}$/

    if (value.match(regExp) == null) {
      setIsError('vallidation')
    } else {
      const isCheck = await checkSubdomain(value)
      if (isCheck) {
        setIsError('isDuplicated')
      } else {
        setIsError('pass')
      }
    }
  }

  const handleConfirm = async () => {
    let res = null
    if (!customUrl) return
    if (isError !== 'pass') return
    if (isUpdate) {
      toggle()
      if (isArtist) {
        res = await modifySubdomain(customUrl)
      } else {
        res = await modifyCustomUrl(customUrl, dataType, dataObjectId)
      }
    } else {
      toggle()
      res = await accountSubdomain(customUrl)
    }

    if (!res) return

    confirmToggle()
  }

  return (
    <>
      <CustomModal.View isShowing={isShowing} toggle={toggle}>
        <ModalBody type="column">
          <H4 style={{ marginTop: 30 }}>
            커스텀 URL {isUpdate ? '수정' : '신청'}
          </H4>
          <Flex type="column">
            <Flex
              style={{
                margin: '40px 0 16px 0',
                padding: '10px 12px',
                width: '400px',
                height: '40px',
                border:
                  isError === '' || isError === 'pass'
                    ? '1px solid #949494'
                    : '1px solid#ea4653',
                borderRadius: '6px',
                flexDirection: isArtist ? 'row-reverse' : 'row',
                alignItems: 'center',
              }}
            >
              <PlaceHolder>
                {isArtist ? `.${DOMAIN_URL}` : `${subdomain}.${DOMAIN_URL}/`}
              </PlaceHolder>
              <Input
                style={{
                  width: '100%',
                  border: 'none',
                  height: '20px',
                  padding: 0,
                }}
                value={customUrl}
                onChange={e => handleChangeUrl(e.target.value)}
              />
            </Flex>
            {isError === 'vallidation' ? (
              <Caption1
                color="#ea4653"
                style={{ width: '100%', textAlign: 'left' }}
              >
                1~20자의 영문 소문자, 숫자, 특수기호 (-), (_)만 사용 가능합니다.
              </Caption1>
            ) : isError === 'isDuplicated' ? (
              <Caption1
                color="#ea4653"
                style={{ width: '100%', textAlign: 'left' }}
              >
                {`이미 존재하는 URL입니다.`}
              </Caption1>
            ) : isArtist ? (
              <Caption1
                color="#949494"
                style={{ width: '100%', textAlign: 'left', marginBottom: 16 }}
              >
                * 신청 후 60일에 한번씩 수정할 수 있습니다.
              </Caption1>
            ) : (
              false
            )}
          </Flex>
          <Btn onClick={() => handleConfirm()} disabled={isError !== 'pass'}>
            신청
          </Btn>
        </ModalBody>
      </CustomModal.View>
    </>
  )
}

export default {
  View,
  useModal: CustomModal.useModal,
}
