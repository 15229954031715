import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, Text, Image } from '@components/atoms'
import { NavLink, useLocation } from 'react-router-dom'
import { colors } from '@colors/'
import './Sidebar.css'

const Bar = styled(Flex)`
  flex-direction: column;
  padding: 20px 0px 0px 0px;
  width: 200px;
`
const SidebarItem = styled(Flex)`
  padding-left: 60px;
  width: 200px;
  height: 50px;
  background-color: ${props => (props.isSelected ? '#f2f2f2' : '')};
  color: ${props => (props.isSelected ? colors.main : '#646464')};
  border-radius: 0px 25px 25px 0px;
  justify-content: flex-start;
  align-items: center;

  & .selected {
    background-color: #f2f2f2;
  }

  span {
    width: 50%;
  }
`

const SidebarImageItem = styled(Flex)`
  padding-left: 20px;
  width: 200px;
  height: 50px;
  background-color: ${props => (props.isSelected ? '#f2f2f2' : '')};
  color: ${props => (props.isSelected ? colors.main : '#646464')};
  border-radius: 0px 25px 25px 0px;
  justify-content: flex-start;
  align-items: center;

  & .selected {
    background-color: #f2f2f2;
  }

  span {
    width: 50%;
  }
`

const SubItem = styled(Flex)`
  padding-left: 80px;
  width: 200px;
  height: 50px;
  background-color: ${props => (props.isSelected ? '#f2f2f2' : '')};
  color: ${props => (props.isSelected ? colors.main : '#646464')};
  border-radius: 0px 25px 25px 0px;
  justify-content: flex-start;
  align-items: center;

  & .selected {
    background-color: #f2f2f2;
  }

  span {
    width: 50%;
  }
`

const SubNavItem = styled(Flex)`
  flex-direction: column;
  width: 200px;
  min-height: 50px;
  background-color: ${props => (props.isSelected ? '#f2f2f2' : '')};
  color: ${props => (props.isSelected ? colors.main : '#646464')};
  border-radius: 0px 25px 25px 0px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  & .selected {
    background-color: #f2f2f2;
  }

  span {
    width: 50%;
  }
`

const SidebarText = styled.div`
  font-family: 'NotoSansCJKkr-medium';
  font-size: '14px';
`

const SizebarInfoAboutCompany = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 180px;
  margin-left: 10px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #eaeaea;
`

// const NoSideImg = styled(Image).attrs({
//   src: require('@images/nopath_menu.png'),
// })`
//   width: 18px;
//   height: 18px;
// `

const CurrentSideImg = styled(Image).attrs({
  src: require('@images/path_menu.png'),
})`
  width: 18px;
  height: 18px;
`

const SideImg = styled(Image).attrs({
  src: require('@images/nopath_menu.png'),
})`
  width: 18px;
  height: 18px;
`

const OpenSideImg = styled(Image).attrs({
  src: require('@images/open_nav_img.png'),
})`
  width: 18px;
  height: 18px;
`

export const Sidebar = ({ artistId, userType }) => {
  const location = useLocation()
  const [currentPage, setCurrenPage] = useState('/')

  const [openInsight, setOpenInsight] = useState(true)
  const [openAdjustment, setOpenAdjustment] = useState(true)

  useEffect(() => {
    // setOpenInsight(location.pathname.indexOf('/insight') !== -1)
    setCurrenPage(location.pathname)
  }, [location])

  return (
    <Bar>
      {userType === 'artist' && (
        <SubNavItem onClick={() => setOpenAdjustment(!openAdjustment)}>
          <SidebarImageItem>
            {openAdjustment ? <OpenSideImg /> : <SideImg />}
            <SidebarText style={{ paddingLeft: '20px' }}>정산서</SidebarText>
          </SidebarImageItem>
          {openAdjustment && (
            <Flex type="column">
              <NavLink
                exact
                to={`/statement?artistId=${artistId}`}
                activeClassName="sidebar_selected"
              >
                <SubItem>
                  <SidebarText>작성중인 정산서</SidebarText>
                </SubItem>
              </NavLink>
              <NavLink
                exact
                to={`/statement-list?artistId=${artistId}`}
                activeClassName="sidebar_selected"
              >
                <SubItem>
                  <SidebarText>지난 정산서</SidebarText>
                </SubItem>
              </NavLink>
              <NavLink
                exact
                to={`/contract?artistId=${artistId}`}
                activeClassName="sidebar_selected"
              >
                <SubItem>
                  <SidebarText>계약 내용</SidebarText>
                </SubItem>
              </NavLink>
            </Flex>
          )}
        </SubNavItem>
      )}

      <SubNavItem onClick={() => setOpenInsight(!openInsight)}>
        <SidebarImageItem>
          {openInsight ? <OpenSideImg /> : <SideImg />}
          <SidebarText style={{ paddingLeft: '20px' }}>음원</SidebarText>
        </SidebarImageItem>
        {openInsight && (
          <Flex type="column">
            <NavLink
              // exact
              to={`/insight/artist?artistId=${artistId}`}
              activeClassName="sidebar_selected"
            >
              <SubItem>
                <SidebarText>종합</SidebarText>
              </SubItem>
            </NavLink>
            <NavLink
              exact
              to={`/insight/settlement`}
              activeClassName="sidebar_selected"
            >
              <SubItem>
                <SidebarText>정산</SidebarText>
              </SubItem>
            </NavLink>
            <NavLink
              exact
              to={`/insight/revenue`}
              activeClassName="sidebar_selected"
            >
              <SubItem>
                <SidebarText>수익</SidebarText>
              </SubItem>
            </NavLink>
            <NavLink
              exact
              to={`/insight/marketing?artistId=${artistId}`}
              activeClassName="sidebar_selected"
            >
              <SubItem>
                <SidebarText>스마트링크</SidebarText>
              </SubItem>
            </NavLink>
          </Flex>
        )}
      </SubNavItem>

      <NavLink
        exact
        to={`/artist?artistId=${artistId}`}
        activeClassName="sidebar_selected"
      >
        <SidebarItem>
          <SidebarText>마이페이지</SidebarText>
        </SidebarItem>
      </NavLink>
      <NavLink
        exact
        to={`/album?artistId=${artistId}`}
        activeClassName="sidebar_selected"
      >
        <SidebarItem>
          <SidebarText>나의 앨범</SidebarText>
        </SidebarItem>
      </NavLink>
      <NavLink
        exact
        to={`/activity-logs?artistId=${artistId}`}
        activeClassName="sidebar_selected"
      >
        <SidebarItem>
          <SidebarText>활동 로그</SidebarText>
        </SidebarItem>
      </NavLink>
      {userType === 'company' && (
        <SizebarInfoAboutCompany>
          <h4 style={{ color: 'grey', fontStyle: 'italic' }}>
            사업자 회원으로 아티스트
            <br />
            정보를 확인하고 있습니다
          </h4>
        </SizebarInfoAboutCompany>
      )}
    </Bar>
  )
}
