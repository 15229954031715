import { observable } from 'mobx'
import { STORAGE_URL } from '@consts'
import { DOMAIN_URL } from '@consts/'
import { ArtistModel, TrackModel } from '.'

export default class AlbumModel {
  /* Database fields */
  @observable _id

  @observable UPC

  @observable imageOriginalPath
  @observable image64Path
  @observable image128Path
  @observable image512Path

  @observable title
  @observable titleEng

  @observable releaseArtistIdList
  @observable releaseCompnayIdList
  @observable planningCompnayIdList

  @observable description
  @observable albumType
  @observable releasedAt
  @observable mainGenre
  @observable subGenre
  @observable releaseCompanyId
  @observable planningCompanyId

  //
  @observable spotifyUrl
  @observable youtubeMusicUrl
  @observable melonUrl
  @observable genieUrl
  @observable bugsUrl
  @observable vibeUrl
  @observable floUrl
  @observable appleMusicUrl
  @observable amazonMusicUrl
  @observable tidalUrl
  @observable deezerUrl
  @observable jooxUrl
  @observable mymusicUrl
  @observable kkboxUrl
  @observable linejpUrl
  @observable linetwUrl
  @observable yandexUrl
  @observable nctUrl
  @observable zingUrl
  @observable anghmiUrl

  @observable isDisplaySpotifyUrl
  @observable isDisplayYoutubeMusicUrl
  @observable isDisplayMelonUrl
  @observable isDisplayGenieUrl
  @observable isDisplayBugsUrl
  @observable isDisplayVibeUrl
  @observable isDisplayFloUrl
  @observable isDisplayAppleMusicUrl
  @observable isDisplayAmazonMusicUrl
  @observable isDisplayTidalUrl
  @observable isDisplayDeezerUrl
  @observable isDisplayJooxUrl
  @observable isDisplayMymusicUrl
  @observable isDisplayKkboxUrl
  @observable isDisplayLinejpUrl
  @observable isDisplayLinetwUrl
  @observable isDisplayYandexUrl
  @observable isDisplayNctUrl
  @observable isDisplayZingUrl
  @observable isDisplayAnghmiUrl

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable artistId
  @observable userId
  @observable variousArtists
  /* Database fields end */

  @observable releaseCompany
  @observable planningCompany

  @observable nowAlbumCrawling
  @observable melonAlbumId
  @observable customUrlInfo
  @observable subdomainInfo

  @observable albumUrl
  set customUrl(value) {
    // const _customUrl = props?.customUrlInfo?.customUrl || props?.customUrlInfo?.autoUrl || props?.albumDetail?.customUrlInfo?.autoUrl || ''
    const _customUrl = value || ''

    if (_customUrl) {
      this.albumUrl = this?.subdomainInfo?.subdomain && this?.subdomainInfo?.isAccepted
        ? `https://${this.subdomainInfo.subdomain}.${DOMAIN_URL}/${_customUrl}`
        : `https://${DOMAIN_URL}/album/${_customUrl}`
    }
  }

  @observable _releaseArtistList = []
  set releaseArtistList(value) {
    if (Array.isArray(value)) {
      this._releaseArtistList = value.sort((a, b) =>
        a['_id'] && this.artistId && a['_id'] === this.artistId ? -1 : 0,
      )
    } else {
      this._releaseArtistList = value
    }
  }
  get releaseArtistList() {
    return this._releaseArtistList
  }

  @observable releaseCompanyList
  @observable planningCompanyList

  @observable trackList = []

  get imageUri() {
    return `${STORAGE_URL}${this.imageOriginalPath}`
  }

  get releaseArtistName() {
    if (this.variousArtists) {
      return 'Various Artists'
    }
    if (Array.isArray(this.releaseArtistList)) {
      return this.releaseArtistList.map(artist => artist['name']).join(', ')
    }
    return ''
  }
  get releaseCompanyName() {
    if (Array.isArray(this.releaseCompanyList)) {
      return this.releaseCompanyList.map(company => company['name']).join(', ')
    }
    return ''
  }
  get planningCompanyName() {
    if (Array.isArray(this.planningCompanyList)) {
      return this.planningCompanyList.map(company => company['name']).join(', ')
    }
    return ''
  }

  @observable isOwner

  @observable distributionCodeList

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.UPC = props.UPC

      this.imageOriginalPath = props.imageOriginalPath
      this.image64Path = props.image64Path
      this.image128Path = props.image128Path
      this.image512Path = props.image512Path

      this.title = props.title
      this.titleEng = props.titleEng

      this.releaseArtistIdList = props.releaseArtistIdList
      this.releaseCompnayIdList = props.releaseCompnayIdList
      this.planningCompnayIdList = props.planningCompnayIdList

      this.description = props.description
      this.albumType = props.albumType
      this.releasedAt = props.releasedAt
      this.mainGenre = props.mainGenre
      this.subGenre = props.subGenre
      this.releaseCompanyId = props.releaseCompanyId
      this.planningCompanyId = props.planningCompanyId

      //
      this.spotifyUrl = props.spotifyUrl
      this.youtubeMusicUrl = props.youtubeMusicUrl
      this.melonUrl = props.melonUrl
      this.genieUrl = props.genieUrl
      this.bugsUrl = props.bugsUrl
      this.vibeUrl = props.vibeUrl
      this.floUrl = props.floUrl
      this.appleMusicUrl = props.appleMusicUrl
      this.amazonMusicUrl = props.amazonMusicUrl
      this.tidalUrl = props.tidalUrl
      this.deezerUrl = props.deezerUrl
      this.jooxUrl = props.jooxUrl
      this.mymusicUrl = props.mymusicUrl
      this.kkboxUrl = props.kkboxUrl
      this.linejpUrl = props.linejpUrl
      this.linetwUrl = props.linetwUrl
      this.yandexUrl = props.yandexUrl
      this.nctUrl = props.nctUrl
      this.zingUrl = props.zingUrl
      this.anghmiUrl = props.anghmiUrl

      this.isDisplaySpotifyUrl = props.isDisplaySpotifyUrl
      this.isDisplayYoutubeMusicUrl = props.isDisplayYoutubeMusicUrl
      this.isDisplayMelonUrl = props.isDisplayMelonUrl
      this.isDisplayGenieUrl = props.isDisplayGenieUrl
      this.isDisplayBugsUrl = props.isDisplayBugsUrl
      this.isDisplayVibeUrl = props.isDisplayVibeUrl
      this.isDisplayFloUrl = props.isDisplayFloUrl
      this.isDisplayAppleMusicUrl = props.isDisplayAppleMusicUrl
      this.isDisplayAmazonMusicUrl = props.isDisplayAmazonMusicUrl
      this.isDisplayTidalUrl = props.isDisplayTidalUrl
      this.isDisplayDeezerUrl = props.isDisplayDeezerUrl
      this.isDisplayJooxUrl = props.isDisplayJooxUrl
      this.isDisplayMymusicUrl = props.isDisplayMymusicUrl
      this.isDisplayKkboxUrl = props.isDisplayKkboxUrl
      this.isDisplayLinejpUrl = props.isDisplayLinejpUrl
      this.isDisplayLinetwUrl = props.isDisplayLinetwUrl
      this.isDisplayYandexUrl = props.isDisplayYandexUrl
      this.isDisplayNctUrl = props.isDisplayNctUrl
      this.isDisplayZingUrl = props.isDisplayZingUrl
      this.isDisplayAnghmiUrl = props.isDisplayAnghmiUrl
      //

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      this.artistId = props.artistId
      this.userId = props.userId
      this.variousArtists = props.variousArtists

      this.releaseCompany = props.releaseCompany
      this.planningCompany = props.planningCompany

      this.nowAlbumCrawling = props.nowAlbumCrawling
      this.melonAlbumId = props.melonAlbumId
      this.customUrlInfo = props.customUrlInfo
      this.subdomainInfo = props.subdomainInfo

      this.albumUrl =
        props &&
          props.subdomainInfo &&
          props.subdomainInfo.subdomain &&
          props.subdomainInfo.isAccepted
          ? props && props.customUrlInfo && props.customUrlInfo.customUrl
            ? `https://${props.subdomainInfo.subdomain}.${DOMAIN_URL}/${props &&
            props.customUrlInfo &&
            props.customUrlInfo.customUrl}`
            : `https://${props.subdomainInfo.subdomain}.${DOMAIN_URL}/${props &&
            props.customUrlInfo &&
            props.customUrlInfo.autoUrl}`
          : props && props.customUrlInfo && props.customUrlInfo.autoUrl
            ? `https://${DOMAIN_URL}/album/${props &&
            props.customUrlInfo &&
            props.customUrlInfo.autoUrl}`
            : `https://${DOMAIN_URL}/album/${props.albumDetail &&
            props.albumDetail.customUrlInfo &&
            props.albumDetail.customUrlInfo.autoUrl}`

      this.releaseArtistList =
        (Array.isArray(props.releaseArtistList) &&
          props.releaseArtistList.map(elem => new ArtistModel(stores, elem))) ||
        []
      this.releaseCompanyList = props.releaseCompanyList
      this.planningCompanyList = props.planningCompanyList
      this.trackList =
        (Array.isArray(props.trackList) &&
          props.trackList
            .filter(elem => !!elem)
            .map(elem => new TrackModel(stores, elem))) ||
        []

      // 회사일 경우, 회사와 연결된 가수 리스트랑 해당 아티스트를 비교해서 권한 검사
      this.isOwner =
        stores.authStore.currentUser.type === 'company'
          ? stores.connectionsStore.artistCompanyConnectionList.filter(
            elem => elem.targetId === props.artistId,
          ).length === 0
            ? false
            : true
          : stores.authStore.currentUser.artistId === props.artistId

      this.distributionCodeList = props.distributionCodeList || []
    }
  }
}
