import { observable } from 'mobx'

export default class TotalInflowStatsByUrlModel {
  /* Database fields */
  @observable _id

  @observable plamUrl
  @observable urlType

  @observable totalFromCount
  @observable totalYoutubeFromCount
  @observable totalFacebookFromCount
  @observable totalInstagramFromCount
  @observable totalClickCount
  @observable totalMelonUrlClickCount
  @observable totalGenieUrlClickCount
  @observable totalBugsUrlClickCount
  @observable totalFloUrlClickCount
  @observable totalVibeUrlClickCount
  @observable totalYoutubeMusicUrlClickCount
  @observable totalAppleMusicUrlClickCount
  @observable totalSpotifyUrlClickCount
  @observable totalAmazonMusicUrlClickCount
  @observable totalEtcUrlClickCount

  @observable album
  @observable artist
  @observable track

  @observable title
  /* Database fields end */

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.plamUrl = props.plamUrl
      this.urlType = props.urlType

      this.totalFromCount = props.totalFromCount
      this.totalYoutubeFromCount = props.totalYoutubeFromCount
      this.totalFacebookFromCount = props.totalFacebookFromCount
      this.totalInstagramFromCount = props.totalInstagramFromCount
      this.totalClickCount = props.totalClickCount
      this.totalMelonUrlClickCount = props.totalMelonUrlClickCount
      this.totalGenieUrlClickCount = props.totalGenieUrlClickCount
      this.totalBugsUrlClickCount = props.totalBugsUrlClickCount
      this.totalFloUrlClickCount = props.totalFloUrlClickCount
      this.totalVibeUrlClickCount = props.totalVibeUrlClickCount
      this.totalYoutubeMusicUrlClickCount = props.totalYoutubeMusicUrlClickCount
      this.totalAppleMusicUrlClickCount = props.totalAppleMusicUrlClickCount
      this.totalSpotifyUrlClickCount = props.totalSpotifyUrlClickCount
      this.totalAmazonMusicUrlClickCount = props.totalAmazonMusicUrlClickCount
      this.totalEtcUrlClickCount = props.totalEtcUrlClickCount
      this.album = props.album
      this.artist = props.artist
      this.track = props.track

      this.title = props.title
    }
  }
}
