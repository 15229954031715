import React, { useMemo, useState } from 'react'
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
  Bar,
} from 'recharts'
import { CheckTextBox } from '@components/molecules'
import { Flex } from '@components/atoms'

import styled from 'styled-components'

const NoData = styled(Flex)`
  width: 1054px;
  height: 500px;
  justify-content: center;
  align-items: center;
`

export const DailyInflowSmartLinkChart = ({ data }) => {
  const [filterType, setFilterType] = useState('')

  const parseData = useMemo(() => {
    return data.map(item => ({
      ...item,
      rate: `${(
        ((item.dailyClickCount || 0) / (item.dailyFromCount || 0)) *
        100
      ).toFixed(2)}%`,
    }))
  }, [data])

  return (
    <Flex>
      {parseData.length === 0 ? (
        <NoData>No data</NoData>
      ) : (
        <ResponsiveContainer width="80%" height={500}>
          <ComposedChart
            // width={1054}
            // height={500}
            data={parseData}
            margin={{
              top: 50,
              right: 0,
              bottom: 50,
              left: 0,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="date" />
            <YAxis />
            <YAxis dataKey={'rate'} yAxisId="hide" hide />
            <Tooltip />
            <Legend verticalAlign="top" layout="horizontal" align="center" />
            <Bar
              name="CTR(전환율)"
              dataKey="rate"
              barSize={20}
              fill="red"
              hide={!(filterType === 'rate' || filterType === '')}
              yAxisId="hide"
            />
            <Line
              name="유입"
              type="monotone"
              dataKey={`dailyFromCount`}
              stroke="red"
              hide={!(filterType === 'dailyFromCount' || filterType === '')}
            />
            <Line
              name="전환"
              type="monotone"
              dataKey={`dailyClickCount`}
              stroke="blue"
              hide={!(filterType === 'dailyClickCount' || filterType === '')}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
      <Flex
        type="column"
        style={{
          margin: '0 0 50px 120px',
          width: 'auto',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        {/* <H6>유입 매체</H6> */}
        <CheckTextBox
          checked={filterType === ''}
          onClick={() => setFilterType('')}
          text="전체"
        />
        <CheckTextBox
          checked={filterType === 'dailyFromCount'}
          onClick={() => setFilterType('dailyFromCount')}
          text="유입수"
        />
        <CheckTextBox
          checked={filterType === 'dailyClickCount'}
          onClick={() => setFilterType('dailyClickCount')}
          text="전환수"
        />
        <CheckTextBox
          checked={filterType === 'rate'}
          onClick={() => setFilterType('rate')}
          text="CTR(전환율)"
        />
      </Flex>
    </Flex>
  )
}
