import React from 'react'
import { Flex, Caption1 } from '@components/atoms'
import styled from 'styled-components'
import { colors } from '@colors/'

const artist_unactive_img = require('@images/artist_btn_gray.png')
const artist_active_img = require('@images/artist_btn_plam.png')

const BtnImg = styled.img`
  width: 88px;
  height: 88px;
  margin-bottom: 20px;
`

export const ToggleArtistBtn = ({ active, onClick }) => {
  return (
    <Flex type="column" style={{ cursor: 'pointer' }} onClick={onClick}>
      <BtnImg src={active ? artist_active_img : artist_unactive_img} />
      <Caption1 color={active ? colors.main : '#949494'}>아티스트</Caption1>
    </Flex>
  )
}
