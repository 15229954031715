import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import { borderColor } from '../../styles'

const StyledButton = styled(Button)`
  background-color: #fafafa;
  color: #949494;
  border: ${borderColor};
`

const AddRowButton = ({ addRow, readOnly, isSaving = false }) => {
  if (readOnly) return null

  return (
    <StyledButton onClick={addRow} loading={isSaving} disabled={isSaving}>
      + 추가하기
    </StyledButton>
  )
}

export default AddRowButton
