import React, { useState } from 'react'
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  // Bar,
  Line,
  ResponsiveContainer,
} from 'recharts'
import { CheckTextBox } from '@components/molecules'
import { Flex } from '@components/atoms'

import styled from 'styled-components'

const NoData = styled(Flex)`
  width: 1054px;
  height: 500px;
  justify-content: center;
  align-items: center;
`

export const DailyInflowStatsChart = ({ data }) => {
  const [filterMedia, setFilterMedia] = useState('')
  return (
    <Flex>
      {data.length === 0 ? (
        <NoData>No data</NoData>
      ) : (
        <ResponsiveContainer width="80%" height={500}>
          <ComposedChart
            // width={1054}
            // height={500}
            data={data}
            margin={{
              top: 50,
              right: 0,
              bottom: 50,
              left: 0,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" layout="horizontal" align="center" />
            {/* <Bar name="유입" dataKey={filterMedia} barSize={20} fill="red" /> */}
            <Line
              name="유입"
              type="monotone"
              dataKey={`daily${filterMedia}FromCount`}
              stroke="red"
            />
            <Line
              name="전환"
              type="monotone"
              dataKey={
                filterMedia !== ''
                  ? `dailyClickCountFrom${filterMedia}`
                  : `dailyClickCount`
              }
              stroke="blue"
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
      <Flex
        type="column"
        style={{
          margin: '0 0 50px 120px',
          width: 'auto',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        {/* <H6>유입 매체</H6> */}
        <CheckTextBox
          checked={filterMedia === '' ? true : false}
          onClick={() => setFilterMedia('')}
          text="All"
        />
        <CheckTextBox
          checked={filterMedia === 'Youtube' ? true : false}
          onClick={() => setFilterMedia('Youtube')}
          text="Youtube"
        />
        <CheckTextBox
          checked={filterMedia === 'Facebook' ? true : false}
          onClick={() => setFilterMedia('Facebook')}
          text="Facebook"
        />
        <CheckTextBox
          checked={filterMedia === 'Instagram' ? true : false}
          onClick={() => setFilterMedia('Instagram')}
          text="Instagram"
        />
        <CheckTextBox
          checked={filterMedia === 'Etc' ? true : false}
          onClick={() => setFilterMedia('Etc')}
          text="기타"
        />
      </Flex>
    </Flex>
  )
}
