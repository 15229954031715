import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Flex,
  Input,
  Text,
  TextArea,
  InvisibleInput,
  H6,
  Div,
} from '@components/atoms'
import {
  Modal,
  ModalContent,
  ModalFooter,
  LinkInput,
  FormLabel,
  FormGroup,
  SubmitBtn,
  AddBtn,
  AlbumTypeBtn,
  GenreBtn,
  SelectBox,
  SwitchBtn,
} from '@components/molecules'
import { ImageUploadForm } from '@components/organisms'

import { useForm } from 'react-hook-form'
import moment from 'moment'
import { STORAGE_URL, SITE_LIST } from '@consts'

import { colors } from '@colors/'
import AutocompleteArtist from './AutocompleteArtist'
import ReleaseArtistModel from './ReleaseArtistModel'
import AutoCompleteCompanyModel from './AutoCompleteCompanyModel'
import AutocompleteCompany from './AutocompleteCompany'
import AutocompleteCode from './AutocompleteCode'
import AutoCompleteCodeModel from './AutoCompleteCodeModel'

const GET_YEAR_SELECT_CONFIG_LIST = (start, end) => {
  const YEAR_SELECT_CONFIG_LIST = []
  for (let year = end; year >= start; year--) {
    YEAR_SELECT_CONFIG_LIST.push({ value: `${year}`, label: `${year}년` })
  }
  return YEAR_SELECT_CONFIG_LIST
}
const GET_MONTH_SELECT_CONFIG_LIST = (start, end) => {
  const MONTH_SELECT_CONFIG_LIST = []
  for (let month = start; month <= end; month++) {
    MONTH_SELECT_CONFIG_LIST.push({ value: `${month}`, label: `${month}월` })
  }
  return MONTH_SELECT_CONFIG_LIST
}
const GET_DATE_SELECT_CONFIG_LIST = (start, end) => {
  const DATE_SELECT_CONFIG_LIST = []
  for (let date = start; date <= end; date++) {
    DATE_SELECT_CONFIG_LIST.push({ value: `${date}`, label: `${date}일` })
  }
  return DATE_SELECT_CONFIG_LIST
}

const currentYear = new Date().getFullYear()
const YEAR_SELECT_CONFIG_LIST = GET_YEAR_SELECT_CONFIG_LIST(1990, currentYear + 1)
const MONTH_SELECT_CONFIG_LIST = GET_MONTH_SELECT_CONFIG_LIST(1, 12)
const DATE_SELECT_CONFIG_LIST = GET_DATE_SELECT_CONFIG_LIST(1, 31)
const TIME_SELECT_CONFIG_LIST = [
  { value: '12', label: '12:00' },
  { value: '18', label: '18:00' },
]

const MAIN_GENRE_SELECT_CONFIG_LIST = [
  { value: '발라드', label: '발라드' },
  { value: '댄스', label: '댄스' },
  { value: '랩/힙합', label: '랩/힙합' },
  { value: 'R&B/Soul', label: 'R&B/Soul' },
  { value: '팝', label: '팝' },
  { value: '인디', label: '인디' },
  { value: '록/메탈', label: '록/메탈' },
  { value: '트로트', label: '트로트' },
  { value: 'EDM/하우스', label: 'EDM/하우스' },
  { value: '포크/브루스', label: '포크/브루스' },
  { value: '클래식', label: '클래식' },
  { value: 'J-POP', label: 'J-POP' },
  { value: '일렉트로니카', label: '일렉트로니카' },
  { value: '재즈', label: '재즈' },
  { value: '뉴에이지', label: '뉴에이지' },
  { value: '종교음악', label: '종교음악' },
]
const SUB_GENRE_SELECT_CONFIG_LIST = [
  { value: '발라드', label: '발라드' },
  { value: '댄스', label: '댄스' },
  { value: '랩/힙합', label: '랩/힙합' },
  { value: 'R&B/Soul', label: 'R&B/Soul' },
  { value: '팝', label: '팝' },
  { value: '인디', label: '인디' },
  { value: '록/메탈', label: '록/메탈' },
  { value: '트로트', label: '트로트' },
  { value: 'EDM/하우스', label: 'EDM/하우스' },
  { value: '포크/브루스', label: '포크/브루스' },
  { value: '클래식', label: '클래식' },
  { value: '해외', label: '해외' },
  { value: '7080', label: '7080' },
  { value: '8090', label: '8090' },
  { value: '2000년대', label: '2000년대' },
  { value: 'J-POP', label: 'J-POP' },
  { value: '일렉트로니카', label: '일렉트로니카' },
  { value: '재즈', label: '재즈' },
  { value: '뉴에이지', label: '뉴에이지' },
  { value: '종교음악', label: '종교음악' },
]

const IS_REQUIRED = true

const Section = styled(Flex)`
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 0px;

  :first-child {
    padding-top: 0px;
  }
`

const ArtistList = styled(Flex)`
  width: 1170px;
  jusitfy-content: flex-start;
  flex-wrap: wrap;

  > div:nth-child(odd) {
    margin-bottom: 6px;
    margin-right: 40px;
  }
`

const CompanyList = styled(Flex)`
  width: 410px;
  jusitfy-content: flex-start;
  flex-wrap: wrap;

  > div {
    margin-bottom: 6px;
  }
`

const CodeList = styled(Flex)`
  width: 410px;
  jusitfy-content: flex-start;
  flex-wrap: wrap;

  > div {
    margin-bottom: 6px;
  }
`

const LinkList = styled(Flex)`
  padding: 0px 0px 16px 0px;
  justify-content: space-between;
  flex-wrap: wrap;
`

const GenreList = styled(Flex)`
  flex-wrap: wrap;
  width: 1620px;
  justify-content: flex-start;
`

interface AlbumFormModalProps {
  artist: Object;
  show: Boolean;
  handleClose: () => void;
  defaultAlbum: Object | null;

  createAlbum: (album: Object) => Promise;
  updateAlbum: (album: Object) => Promise;
  isUpdating: Boolean;
}

export const AlbumFormModal = ({
  artist,
  show,
  handleClose,
  defaultAlbum,

  createAlbum,
  updateAlbum,
  isUpdating,
}: AlbumFormModalProps) => {
  const { register, handleSubmit, setValue, errors, watch } = useForm({
    defaultValues: {},
  })

  const [allRequired, setAllRequired] = useState(false) // 모든 필수 입력사항 체크
  const [imageOriginalPath, setImageUrl] = useState() // 이미지 파일

  const [albumTypeValue, setAlbumTypeValue] = useState(false)
  const [mainGenreValue, setMainGenreValue] = useState(false)
  const [subGenreValue, setSubGenreValue] = useState(false)

  const [variousArtists, setVariousArtists] = useState(false)

  const [yearValue, setYearValue] = useState('')
  const [monthValue, setMonthValue] = useState('')
  const [dateValue, setDateValue] = useState('')
  const [hoursValue, setHoursValue] = useState('')

  // 발매 가수 데이터 수집
  const [releaseArtistList, setReleaseArtistList] = useState([])
  const append = () => {
    setReleaseArtistList([...releaseArtistList, new ReleaseArtistModel()])
  }
  const remove = index => {
    const clonedReleaseArtistList = [...releaseArtistList]
    if (releaseArtistList && index >= 0 && index < releaseArtistList.length) {
      clonedReleaseArtistList.splice(index, 1)
      setReleaseArtistList(clonedReleaseArtistList)
    }
  }

  // 유통사 자동완성 데이터 수집
  const [releaseCompanyList, setReleaseCompanyList] = useState([])
  const appendReleaseCompanyList = () => {
    setReleaseCompanyList([
      ...releaseCompanyList,
      new AutoCompleteCompanyModel(),
    ])
  }
  const removeReleaseCompanyList = index => {
    const clonedReleaseCompanyList = [...releaseCompanyList]
    if (releaseCompanyList && index >= 0 && index < releaseCompanyList.length) {
      clonedReleaseCompanyList.splice(index, 1)
      setReleaseCompanyList(clonedReleaseCompanyList)
    }
  }

  // 권리사 자동완성 데이터 수집
  const [planningCompanyList, setPlanningCompanyList] = useState([])
  const appendPlanningCompanyList = () => {
    setPlanningCompanyList([
      ...planningCompanyList,
      new AutoCompleteCompanyModel(),
    ])
  }
  const removePlanningCompanyList = index => {
    const clonedPlanningCompanyList = [...planningCompanyList]
    if (
      planningCompanyList &&
      index >= 0 &&
      index < planningCompanyList.length
    ) {
      clonedPlanningCompanyList.splice(index, 1)
      setPlanningCompanyList(clonedPlanningCompanyList)
    }
  }

  // 유통사 앨범코드
  const [AlbumCodeList, setAlbumCodeList] = useState([
    new AutoCompleteCodeModel(),
  ])
  const appendAlbumCodeList = () => {
    setAlbumCodeList([...AlbumCodeList, new AutoCompleteCodeModel()])
  }
  const removeAlbumCodeList = index => {
    const clonedAlbumCodeList = [...AlbumCodeList]
    if (AlbumCodeList && index >= 0 && index < AlbumCodeList.length) {
      clonedAlbumCodeList.splice(index, 1)
      setAlbumCodeList(clonedAlbumCodeList)
    }
  }

  useEffect(() => {
    setAllRequired(false)
    setImageUrl(null)

    setAlbumTypeValue(false)
    setMainGenreValue(false)
    setSubGenreValue(false)

    setYearValue('')
    setMonthValue('')
    setDateValue('')

    setReleaseArtistList(
      (defaultAlbum &&
        defaultAlbum.releaseArtistList &&
        defaultAlbum.releaseArtistList.map(
          elem => new ReleaseArtistModel(elem),
        )) || [new ReleaseArtistModel(artist)],
    )
    setReleaseCompanyList(
      (defaultAlbum &&
        defaultAlbum.releaseCompanyList &&
        defaultAlbum.releaseCompanyList.map(
          elem => new AutoCompleteCompanyModel(elem),
        )) ||
      [],
    )
    setPlanningCompanyList(
      (defaultAlbum &&
        defaultAlbum.planningCompanyList &&
        defaultAlbum.planningCompanyList.map(
          elem => new AutoCompleteCompanyModel(elem),
        )) ||
      [],
    )
    setAlbumCodeList(
      (defaultAlbum &&
        defaultAlbum.distributionCodeList &&
        defaultAlbum.distributionCodeList.map(
          elem => new AutoCompleteCodeModel({ name: elem }),
        )) || [new AutoCompleteCodeModel()],
    )

    if (show && defaultAlbum) {
      const defaultValues = { ...defaultAlbum }

      if (defaultValues && defaultValues.releasedAt) {
        const releasedAtMoment = moment(defaultValues.releasedAt)
        defaultValues['year'] = `${releasedAtMoment.year()}`
        defaultValues['month'] = `${releasedAtMoment.month() + 1}`
        defaultValues['date'] = `${releasedAtMoment.date()}`
        defaultValues['hours'] = `${releasedAtMoment.hours()}`
      }
      setImageUrl(defaultValues && defaultValues.imageOriginalPath)

      setMainGenreValue(defaultValues && defaultValues.mainGenre)
      setSubGenreValue(defaultValues && defaultValues.subGenre)
      setAlbumTypeValue(defaultValues && defaultValues.albumType)
      setVariousArtists(defaultValues && defaultValues.variousArtists)

      setYearValue((defaultValues && defaultValues.year) || '')
      setMonthValue((defaultValues && defaultValues.month) || '')
      setDateValue((defaultValues && defaultValues.date) || '')
      setHoursValue((defaultValues && defaultValues.hours) || '')

      setValue(
        Object.keys(defaultValues).map(key => ({
          [key]: defaultValues[key],
        })),
      )
    }
  }, [show, defaultAlbum, setValue, artist])

  const selectAlbumType = value => {
    setValue('albumType', value)
    setAlbumTypeValue(value)
  }

  const selectMainGenre = value => {
    setValue('mainGenre', value)
    setMainGenreValue(value)
  }

  const selectSubGenre = value => {
    setValue('subGenre', value)
    setSubGenreValue(value)
  }

  const selectYear = yearConfig => {
    if (yearConfig && yearConfig.value) {
      setValue('year', yearConfig.value)
      setYearValue(yearConfig.value)
    }
  }
  const selectMonth = monthConfig => {
    if (monthConfig && monthConfig.value) {
      setValue('month', monthConfig.value)
      setMonthValue(monthConfig.value)
    }
  }
  const selectDate = dateConfig => {
    if (dateConfig && dateConfig.value) {
      setValue('date', dateConfig.value)
      setDateValue(dateConfig.value)
    }
  }
  const selectHours = hoursConfig => {
    if (hoursConfig && hoursConfig.value) {
      setValue('hours', hoursConfig.value)
      setHoursValue(hoursConfig.value)
    }
  }

  const onSubmit = async data => {
    const {
      UPC,

      title,
      titleEng,
      description,
      albumType,

      mainGenre,
      subGenre,

      //
      spotifyUrl,
      youtubeMusicUrl,
      melonUrl,
      genieUrl,
      bugsUrl,
      vibeUrl,
      floUrl,
      appleMusicUrl,
      amazonMusicUrl,
      tidalUrl,
      deezerUrl,
      jooxUrl,
      mymusicUrl,
      kkboxUrl,
      linejpUrl,
      linetwUrl,
      yandexUrl,
      nctUrl,
      zingUrl,
      anghmiUrl,

      isDisplaySpotifyUrl,
      isDisplayYoutubeMusicUrl,
      isDisplayMelonUrl,
      isDisplayGenieUrl,
      isDisplayBugsUrl,
      isDisplayVibeUrl,
      isDisplayFloUrl,
      isDisplayAppleMusicUrl,
      isDisplayAmazonMusicUrl,
      isDisplayTidalUrl,
      isDisplayDeezerUrl,
      isDisplayJooxUrl,
      isDisplayMymusicUrl,
      isDisplayKkboxUrl,
      isDisplayLinejpUrl,
      isDisplayLinetwUrl,
      isDisplayYandexUrl,
      isDisplayNctUrl,
      isDisplayZingUrl,
      isDisplayAnghmiUrl,
      //

      year,
      month,
      date,
      hours,

      ...others
    } = data

    // if (!imageOriginalPath) {
    //     setAllRequired(true)
    //     return false
    // }

    if (
      spotifyUrl === '' &&
      youtubeMusicUrl === '' &&
      melonUrl === '' &&
      genieUrl === '' &&
      bugsUrl === '' &&
      vibeUrl === '' &&
      floUrl === '' &&
      appleMusicUrl === '' &&
      amazonMusicUrl === '' &&
      tidalUrl === '' &&
      deezerUrl === '' &&
      jooxUrl === '' &&
      mymusicUrl === '' &&
      kkboxUrl === '' &&
      linejpUrl === '' &&
      linetwUrl === '' &&
      yandexUrl === '' &&
      nctUrl === '' &&
      zingUrl === '' &&
      anghmiUrl === ''
    ) {
      // setAllRequired(true)
      // return false
    }

    const releasedAt = moment
      .utc(`${year}-${month}-${date} ${parseInt(hours, 10) - 9}:00`)
      .format('YYYY-MM-DDTHH:mm:ss.SSSSZ')

    // 입력 api
    const newAlbumData = {
      // _id: (defaultAlbum && defaultAlbum._id) || null,
      imageOriginalPath,
      title,
      titleEng,
      UPC,
      description,
      albumType,

      mainGenre,
      subGenre,

      spotifyUrl,
      youtubeMusicUrl,
      melonUrl,
      genieUrl,
      bugsUrl,
      vibeUrl,
      floUrl,
      appleMusicUrl,
      amazonMusicUrl,
      tidalUrl,
      deezerUrl,
      jooxUrl,
      mymusicUrl,
      kkboxUrl,
      linejpUrl,
      linetwUrl,
      yandexUrl,
      nctUrl,
      zingUrl,
      anghmiUrl,
      isDisplaySpotifyUrl: JSON.parse(isDisplaySpotifyUrl || 'false'),
      isDisplayYoutubeMusicUrl: JSON.parse(isDisplayYoutubeMusicUrl || 'false'),
      isDisplayMelonUrl: JSON.parse(isDisplayMelonUrl || 'false'),
      isDisplayGenieUrl: JSON.parse(isDisplayGenieUrl || 'false'),
      isDisplayBugsUrl: JSON.parse(isDisplayBugsUrl || 'false'),
      isDisplayVibeUrl: JSON.parse(isDisplayVibeUrl || 'false'),
      isDisplayFloUrl: JSON.parse(isDisplayFloUrl || 'false'),
      isDisplayAppleMusicUrl: JSON.parse(isDisplayAppleMusicUrl || 'false'),
      isDisplayAmazonMusicUrl: JSON.parse(isDisplayAmazonMusicUrl || 'false'),
      isDisplayTidalUrl: JSON.parse(isDisplayTidalUrl || 'false'),
      isDisplayDeezerUrl: JSON.parse(isDisplayDeezerUrl || 'false'),
      isDisplayJooxUrl: JSON.parse(isDisplayJooxUrl || 'false'),
      isDisplayMymusicUrl: JSON.parse(isDisplayMymusicUrl || 'false'),
      isDisplayKkboxUrl: JSON.parse(isDisplayKkboxUrl || 'false'),
      isDisplayLinejpUrl: JSON.parse(isDisplayLinejpUrl || 'false'),
      isDisplayLinetwUrl: JSON.parse(isDisplayLinetwUrl || 'false'),
      isDisplayYandexUrl: JSON.parse(isDisplayYandexUrl || 'false'),
      isDisplayNctUrl: JSON.parse(isDisplayNctUrl || 'false'),
      isDisplayZingUrl: JSON.parse(isDisplayZingUrl || 'false'),
      isDisplayAnghmiUrl: JSON.parse(isDisplayAnghmiUrl || 'false'),

      variousArtists,

      releasedAt,

      ...others,
      releaseArtistIdList: (releaseArtistList || []).map(elem =>
        elem.toIdObj(),
      ),
      releaseArtistList,

      releaseCompanyIdList: (releaseCompanyList || []).map(elem =>
        elem.toIdObj(),
      ),
      releaseCompanyList,

      planningCompanyIdList: (planningCompanyList || []).map(elem =>
        elem.toIdObj(),
      ),
      planningCompanyList,

      distributionCodeList: AlbumCodeList.map(elem => elem['name']),

      artistId: defaultAlbum ? defaultAlbum.artistId : artist._id,
      // userId: ,
    }

    if (isUpdating) {
      await updateAlbum(newAlbumData)
    } else {
      await createAlbum(newAlbumData)
    }

    handleClose()
  }

  return (
    <Modal
      style={{ minHeight: '1504px', width: '1720px' }}
      show={show}
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <Flex type="column" style={{ width: '100%' }}>
            <Section>
              {/* 로직 붙여야함 */}
              <Flex style={{ gap: 40 }}>
                <ImageUploadForm
                  label={'앨범 자켓*'}
                  imageOriginalPath={
                    imageOriginalPath && STORAGE_URL + imageOriginalPath
                  }
                  setImageUrl={setImageUrl}
                />
                <Flex type="column" style={{ gap: 30 }}>
                  <Flex style={{ gap: 40 }}>
                    <FormGroup>
                      <FormLabel required>앨범 명</FormLabel>
                      <Input
                        style={{ width: '565px' }}
                        placeholder="앨범 명을 입력해주세요."
                        name="title"
                        ref={register({ required: IS_REQUIRED })}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>영문 앨범 명</FormLabel>
                      <Input
                        style={{ width: '565px' }}
                        placeholder="앨범 명을 입력해주세요."
                        name="titleEng"
                        ref={register({ required: false })}
                      />
                    </FormGroup>
                  </Flex>
                  <Flex style={{ gap: 40 }}>
                    <FormGroup>
                      <FormLabel>UPC 코드</FormLabel>
                      <Input
                        style={{ width: '410px' }}
                        placeholder="UPC 코드를 입력해주세요."
                        name="UPC"
                        ref={register()}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel required>발매일</FormLabel>
                      <Flex
                        style={{
                          width: '644px',
                          justifyContent: 'space-between',
                        }}
                      >
                        <InvisibleInput
                          name="year"
                          ref={register({ required: IS_REQUIRED })}
                        />
                        <SelectBox
                          options={YEAR_SELECT_CONFIG_LIST}
                          placeholder={'연도'}
                          value={
                            YEAR_SELECT_CONFIG_LIST.find(
                              yearConfig =>
                                yearValue && yearConfig['value'] === yearValue,
                            ) || null
                          }
                          onChange={selectYear}
                        />
                        <InvisibleInput
                          name="month"
                          ref={register({ required: IS_REQUIRED })}
                        />
                        <SelectBox
                          options={MONTH_SELECT_CONFIG_LIST}
                          placeholder={'월'}
                          value={
                            MONTH_SELECT_CONFIG_LIST.find(
                              monthConfig =>
                                monthValue &&
                                monthConfig['value'] === monthValue,
                            ) || null
                          }
                          onChange={selectMonth}
                        />
                        <InvisibleInput
                          name="date"
                          ref={register({ required: IS_REQUIRED })}
                        />
                        <SelectBox
                          options={DATE_SELECT_CONFIG_LIST}
                          placeholder={'일'}
                          value={
                            DATE_SELECT_CONFIG_LIST.find(
                              dateConfig =>
                                dateValue && dateConfig['value'] === dateValue,
                            ) || null
                          }
                          onChange={selectDate}
                        />
                        <InvisibleInput
                          name="hours"
                          ref={register({ required: IS_REQUIRED })}
                        />
                        <SelectBox
                          options={TIME_SELECT_CONFIG_LIST}
                          placeholder={'시'}
                          value={
                            TIME_SELECT_CONFIG_LIST.find(
                              hoursConfig =>
                                hoursValue &&
                                hoursConfig['value'] === hoursValue,
                            ) || null
                          }
                          onChange={selectHours}
                        />
                      </Flex>
                    </FormGroup>
                  </Flex>

                  <FormGroup>
                    <FormLabel>앨범 소개</FormLabel>
                    <TextArea
                      placeholder="앨범을 소개 해주세요."
                      name="description"
                      ref={register()}
                    />
                  </FormGroup>
                </Flex>
              </Flex>
              <FormGroup
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <FormGroup>
                  <Flex
                    style={{
                      width: 565,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: 47,
                    }}
                  >
                    <FormLabel required style={{ paddingBottom: 0 }}>
                      아티스트 명
                    </FormLabel>
                    <Flex>
                      <H6 type="Regular" style={{ marginRight: 22 }}>
                        Various Artists
                      </H6>
                      <SwitchBtn
                        id="variousArtists"
                        isSwitchOn={variousArtists}
                        handleSwitch={() => setVariousArtists(!variousArtists)}
                        color={colors.main}
                      />
                    </Flex>
                  </Flex>
                  <ArtistList>
                    {releaseArtistList.map((item, index) => {
                      return (
                        <div
                          key={`album-release-artist-${index}`}
                          style={{ position: 'relative' }}
                        >
                          <AutocompleteArtist
                            value={releaseArtistList[index]}
                            setArtist={autocompleteArtist => {
                              const clonedReleaseArtistList = [
                                ...releaseArtistList,
                              ]
                              Object.keys(
                                clonedReleaseArtistList[index],
                              ).forEach(key => {
                                clonedReleaseArtistList[index][key] =
                                  autocompleteArtist[key]
                              })
                              setReleaseArtistList(clonedReleaseArtistList)
                            }}
                            index={index}
                            keyword={'albumArtist'}
                            isDisabled={index === 0}
                            isDisabledRemoveBtn={index === 0}
                            onClickRemove={() => remove(index)}
                          />
                        </div>
                      )
                    })}
                    <AddBtn onClick={() => append()} />
                  </ArtistList>
                </FormGroup>
              </FormGroup>
            </Section>
            <Section>
              <FormGroup>
                <FormLabel required="onlyOne">앨범 유형</FormLabel>
                <Flex
                  style={{ width: '912px', justifyContent: 'space-between' }}
                >
                  <InvisibleInput
                    name="albumType"
                    ref={register({ required: IS_REQUIRED })}
                  />
                  <AlbumTypeBtn
                    active={albumTypeValue === '정규'}
                    onClick={() => selectAlbumType('정규')}
                  >
                    정규
                  </AlbumTypeBtn>
                  <AlbumTypeBtn
                    active={albumTypeValue === '싱글'}
                    onClick={() => selectAlbumType('싱글')}
                  >
                    싱글
                  </AlbumTypeBtn>
                  <AlbumTypeBtn
                    active={albumTypeValue === 'EP'}
                    onClick={() => selectAlbumType('EP')}
                  >
                    EP
                  </AlbumTypeBtn>
                  <AlbumTypeBtn
                    active={albumTypeValue === 'OST'}
                    onClick={() => selectAlbumType('OST')}
                  >
                    OST
                  </AlbumTypeBtn>
                  <AlbumTypeBtn
                    active={albumTypeValue === '옴니버스'}
                    onClick={() => selectAlbumType('옴니버스')}
                  >
                    옴니버스
                  </AlbumTypeBtn>
                  <div style={{ width: 84 }} />
                </Flex>
              </FormGroup>
            </Section>
            <Section>
              <FormGroup>
                <FormLabel required="onlyOne">주 장르</FormLabel>
                <InvisibleInput
                  name="mainGenre"
                  ref={register({ required: IS_REQUIRED })}
                />
                <GenreList>
                  {MAIN_GENRE_SELECT_CONFIG_LIST.map(mainGenreSelectConfig => (
                    <GenreBtn
                      key={`main-genre-${mainGenreSelectConfig.value}`}
                      active={mainGenreValue === mainGenreSelectConfig.value}
                      onClick={() =>
                        selectMainGenre(mainGenreSelectConfig.value)
                      }
                    >
                      {mainGenreSelectConfig.label}
                    </GenreBtn>
                  ))}
                </GenreList>
              </FormGroup>
            </Section>
            <Section>
              <FormGroup>
                <FormLabel>
                  하위 장르
                  <Text color="red" size="14px">
                    {' '}
                    1개 선택
                  </Text>
                </FormLabel>
                <InvisibleInput name="subGenre" ref={register()} />
                <GenreList>
                  {SUB_GENRE_SELECT_CONFIG_LIST.map(subGenreSelectConfig => (
                    <GenreBtn
                      key={`sub-genre-${subGenreSelectConfig.value}`}
                      active={subGenreValue === subGenreSelectConfig.value}
                      onClick={() => {
                        subGenreValue === subGenreSelectConfig.value
                          ? selectSubGenre(null)
                          : selectSubGenre(subGenreSelectConfig.value)
                      }}
                    >
                      {subGenreSelectConfig.label}
                    </GenreBtn>
                  ))}
                </GenreList>
              </FormGroup>
            </Section>
            <Section style={{ paddingBottom: '0px' }}>
              <FormGroup>
                <FormLabel required>유통사</FormLabel>
                <CompanyList>
                  {releaseCompanyList.map((item, index) => {
                    return (
                      <div
                        key={`album-release-company-${index}`}
                        style={{ position: 'relative' }}
                      >
                        <AutocompleteCompany
                          value={releaseCompanyList[index]}
                          setCompany={autocompleteCompany => {
                            const clonedReleaseCompanyList = [
                              ...releaseCompanyList,
                            ]
                            Object.keys(
                              clonedReleaseCompanyList[index],
                            ).forEach(key => {
                              clonedReleaseCompanyList[index][key] =
                                autocompleteCompany[key]
                            })
                            setReleaseCompanyList(clonedReleaseCompanyList)
                          }}
                          index={`release_${index}`}
                          // isDisabled={index === 0}
                          isDisabledRemoveBtn={index === 0}
                          onClickRemove={() => removeReleaseCompanyList(index)}
                        />
                      </div>
                    )
                  })}
                  <AddBtn onClick={() => appendReleaseCompanyList()} />
                </CompanyList>
                <FormLabel required style={{ paddingTop: '29px' }}>
                  권리사
                </FormLabel>
                <CompanyList>
                  {planningCompanyList.map((item, index) => {
                    return (
                      <div
                        key={`album-planning-company-${index}`}
                        style={{ position: 'relative' }}
                      >
                        <AutocompleteCompany
                          value={planningCompanyList[index]}
                          setCompany={autocompleteCompany => {
                            const clonedPlanningCompanyList = [
                              ...planningCompanyList,
                            ]
                            Object.keys(
                              clonedPlanningCompanyList[index],
                            ).forEach(key => {
                              clonedPlanningCompanyList[index][key] =
                                autocompleteCompany[key]
                            })
                            setPlanningCompanyList(clonedPlanningCompanyList)
                          }}
                          index={`planning_${index}`}
                          // isDisabled={index === 0}
                          isDisabledRemoveBtn={index === 0}
                          onClickRemove={() => removePlanningCompanyList(index)}
                        />
                      </div>
                    )
                  })}
                  <AddBtn onClick={() => appendPlanningCompanyList()} />
                </CompanyList>
                <FormLabel style={{ paddingTop: '29px' }}>
                  유통사 앨범코드
                  <Text color="#949494" size="14px">
                    {' '}
                    * 중복 입력 가능
                  </Text>
                </FormLabel>
                <CodeList>
                  {AlbumCodeList.map((item, index) => {
                    return (
                      <div
                        key={`album-planning-company-${index}`}
                        style={{ position: 'relative' }}
                      >
                        <AutocompleteCode
                          value={AlbumCodeList[index]}
                          setValue={autocompleteCompany => {
                            const clonedAlbumCodeList = [...AlbumCodeList]
                            Object.keys(clonedAlbumCodeList[index]).forEach(
                              key => {
                                clonedAlbumCodeList[index][key] =
                                  autocompleteCompany[key]
                              },
                            )
                            setAlbumCodeList(clonedAlbumCodeList)
                          }}
                          index={`album_code_${index}`}
                          // isDisabled={index === 0}
                          isDisabledRemoveBtn={index === 0}
                          onClickRemove={() => removeAlbumCodeList(index)}
                        />
                      </div>
                    )
                  })}
                  <AddBtn onClick={() => appendAlbumCodeList()} />
                </CodeList>
              </FormGroup>
              <FormGroup style={{ width: '1170px' }}>
                <FormLabel>음악 서비스</FormLabel>
                <LinkList>
                  {SITE_LIST.map(site => {
                    return (
                      <Div
                        key={site.name}
                        width="565px"
                        margin="8px 0"
                        display="flex"
                        alignItems="center"
                      >
                        <InvisibleInput
                          ref={register()}
                          name={site.displayName}
                        />
                        <SwitchBtn
                          id={site.displayName}
                          isSwitchOn={JSON.parse(
                            watch()[`${site.displayName}`] || 'false',
                          )}
                          handleSwitch={e => {
                            setValue(
                              `${site.displayName}`,
                              e?.currentTarget?.checked,
                            )
                          }}
                        />
                        <LinkInput
                          placeholder={site.text}
                          name={site.name}
                          ref={register()}
                          onChange={e => {
                            if (e?.currentTarget?.value !== '')
                              setValue(`${site.displayName}`, true)
                          }}
                        />
                      </Div>
                    )
                  })}
                </LinkList>
              </FormGroup>
            </Section>
          </Flex>
        </ModalContent>
        <ModalFooter>
          {allRequired ||
            errors.name ||
            errors.year ||
            errors.month ||
            errors.date ||
            errors.hours ||
            errors.releaseArtistList ||
            errors.albumType ||
            errors.mainGenre ||
            errors.subGenre ? (
            <Text
              type="Medium"
              size="14px"
              style={{ paddingBottom: '25px' }}
              color="#ea4653"
            >
              필수 입력 사항을 모두 입력해주세요.
            </Text>
          ) : (
            false
          )}
          <SubmitBtn type="submit">
            {isUpdating ? '수정하기' : '앨범 추가'}
          </SubmitBtn>
        </ModalFooter>
      </form>
    </Modal>
  )
}
