import React from 'react'
import styled from 'styled-components'
import { Flex, Caption2 } from '@components/atoms'
import { convertToPricingComma } from '@utils/format'

const IncreaseTextBox = ({ data, style }) => {
  return (
    <Flex style={{ height: '18px', alignItems: 'center', ...style }}>
      {/* <Box data={data || 0} /> */}
      {data === 0 || data === null ? (
        <div>-</div>
      ) : data > 0 ? (
        <Up />
      ) : (
        <Down />
      )}
      {data !== 0 && (
        <Caption2 color="#949494">
          {data && convertToPricingComma(Math.abs(data))}
        </Caption2>
      )}
    </Flex>
  )
}

export default IncreaseTextBox

const Up = styled.div`
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;

  border-bottom: 5px solid #66bc6a;
  margin-right: 3px;
`

const Down = styled.div`
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;

  border-top: 5px solid #ea4653;
  margin-right: 3px;
  margin-top: 2px;
`
