import { action, observable, } from 'mobx'
import { Store } from '.'
import { Network } from './networks'

import { FaqModel, } from './models'

export default class FaqStore {
  @observable faqDetail = null
  @observable faqList = []

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.faqList = []
    this.faqDetail = null
  }

  @action.bound
  async initClient() {
  }

  async fetchFaqList(params) {
    return this.store.useLoading(async () => {
      const fetchedDataList = await this.network.faqNetwork.getList({ __order: 'createdAtDesc', ...params, })
      if (!fetchedDataList) return

      this.faqList = fetchedDataList.map(fortune => new FaqModel(this.store, fortune))
      return this.faqList
    })
  }

  async updateFaq(id, index, data) {
    return this.store.useLoading(async () => {
      if (!id || isNaN(parseInt(index)) || !data) return

      const updatedData = await this.network.faqNetwork.put(id, data)
      if (!updatedData) return

      this.faqList[index] = new FaqModel(this.store, updatedData)
      return this.faqList[index]
    })
  }

  async createFaq(data) {
    return this.store.useLoading(async () => {
      const createdData = await this.network.faqNetwork.post(data)
      if (!createdData) return

      const faq = new FaqModel(this.store, createdData)
      this.faqList.splice(0, 0, faq)
      return faq
    })
  }

  async deleteFaq(id, index) {
    return this.store.useLoading(async () => {
      if (!id || isNaN(parseInt(index))) return

      const deletedFaq = await this.network.faqNetwork.delete(id)
      if (!deletedFaq) return

      this.faqList.splice(index, 1)
      return deletedFaq
    })
  }

}