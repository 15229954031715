import styled from 'styled-components'
import { Button } from '@components/atoms'
import { colors } from '@colors/'

export const SubmitBtn = styled(Button)`
  width: 230px;
  height: 48px;
  border-radius: 24px;
  border: solid 2px ${colors.main};
  background-color: ${colors.main};
  color: white;
  justify-content: center;
  align-items: center;
  font-family: NotoSansCJKkr-Medium;

  :hover {
    background-color: ${colors.sub_main};
    border-color: ${colors.sub_main};
    transition-property: background-color, border-color;
    transition-duration: 0.2s;
    transition-timing-function: linear;
  }
`
