import React from 'react'
import { Input } from 'antd'

const AdjustmentTitleInput = ({
  readOnly,
  title,
  onUpdateTitle,
  onSaveTitle,
}) => {
  const handleChange = event => {
    const newTitle = event.target.value
    if (newTitle.length > 50) {
      alert('제목의 최대수는 50자입니다.')
      return
    }
    onUpdateTitle(newTitle)
  }
  const handleBlur = event => {
    onSaveTitle(event.target.value)
  }

  return (
    <Input
      maxLength={50}
      readOnly={readOnly}
      // disabled={disabled}
      value={title}
      onChange={handleChange}
      placeholder="정산서 제목을 입력해주세요"
      style={
        {
          minWidth: '500px',
          borderColor: readOnly && '#d9d9d9',
          boxShadow: readOnly && 'none',
        }
      }
      onBlur={handleBlur}
    />
  )
}

export default AdjustmentTitleInput
