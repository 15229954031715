import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Text, H4, H3, Container } from '@components/atoms'
import {
  DailyInflowStatsChart,
  TotalInflowStatsChart,
  InflowTableChart,
  InflowDetailTableChart,
} from '@components/organisms'
import { colors } from '@colors/'

import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'

import moment from 'moment'
import 'moment/locale/ko'
import { ChartDivisionBox } from '@components/molecules'
import CountUp from 'react-countup'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 0 0 0;
`

const SectionTitle = styled(Text)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  margin-bottom: 20px;
`

const InfoCard = styled(Flex)`
  width: 318px;
  height: 167px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #fcfcfc;
  padding: 5px;
  margin: 0 50px 0 0;
  justify-content: center;
`

export const InsightTemplate = ({
  TotalInflowStatsList,
  TotalInflowStatsByUrlList,
  DailyInflowStatsList,
  sumInflow,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  searchType,
  setSearchType,
}) => {
  const [focusedInput, setFocusedInput] = useState(null)

  moment.locale('ko')
  return (
    <Container>
      <Header>
        <Text type="Medium" size="18px" style={{ marginTop: '10px' }}>
          스마트링크
        </Text>
      </Header>
      <Content>
        <Flex>
          <Flex type="column">
            <SectionTitle>전체 인사이트</SectionTitle>
            <Flex>
              <InfoCard type="column">
                <H4>유입수</H4>
                <H3
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '20px' }}
                >
                  {sumInflow.sumFromCount && (
                    <CountUp
                      start={0}
                      end={Math.floor(sumInflow.sumFromCount)}
                      duration={3}
                    />
                  )}
                </H3>
              </InfoCard>
              <InfoCard type="column">
                <H4>전환수</H4>
                <H3
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '20px' }}
                >
                  {sumInflow.sumClickCount && (
                    <CountUp
                      start={0}
                      end={Math.floor(sumInflow.sumClickCount)}
                      duration={3}
                    />
                  )}
                </H3>
              </InfoCard>
              <InfoCard type="column">
                <H4>CTR(전환율)</H4>
                <H3
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '20px' }}
                >
                  {sumInflow.sumClickCount && (
                    <CountUp
                      start={0}
                      end={Math.floor(
                        (sumInflow.sumClickCount / sumInflow.sumFromCount) *
                        100,
                      )}
                      suffix="%"
                      duration={3}
                    />
                  )}
                </H3>
              </InfoCard>
            </Flex>
          </Flex>
          <Flex type="column">
            <SectionTitle>기간 조회</SectionTitle>
            <DateRangePicker
              startDateId="startDate"
              endDateId="endDate"
              startDate={startDate}
              endDate={endDate}
              onDatesChange={({ startDate, endDate }) => {
                setStartDate(startDate)
                setEndDate(endDate)
              }}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => {
                setFocusedInput(focusedInput)
              }}
              startDatePlaceholderText="YYYY / MM / DD"
              endDatePlaceholderText="YYYY / MM / DD"
              regular
              displayFormat={'YYYY/MM/DD'}
              isOutsideRange={() => false}
              minimumNights={0} // 같은 날짜를 고를 수 있게 해줌.
            />
            <SectionTitle style={{ marginTop: '40px' }}>구분</SectionTitle>
            <ChartDivisionBox
              options={[
                { label: '전체', value: 'all' },
                { label: '트랙', value: 'tracks' },
                { label: '앨범', value: 'albums' },
                { label: '아티스트', value: 'artists' },
              ]}
              value={searchType}
              onChange={value => setSearchType(value)}
            />
          </Flex>
        </Flex>
        <DailyInflowStatsChart data={DailyInflowStatsList} />
        <Flex>
          <Flex type="column">
            <SectionTitle>유입 / 전환</SectionTitle>
            <InflowTableChart
              data={TotalInflowStatsList}
              sumInflow={sumInflow}
            />
          </Flex>
          <TotalInflowStatsChart
            TotalInflowStatsList={TotalInflowStatsList}
            sumInflow={sumInflow}
          />
        </Flex>
        <Flex type="column" style={{ marginTop: '50px' }}>
          <SectionTitle>세부 내역</SectionTitle>
          <InflowDetailTableChart data={TotalInflowStatsByUrlList} />
        </Flex>
      </Content>
    </Container>
  )
}
