import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Text, Container, Button, Image } from '@components/atoms'
import { RevenueUploadModal } from '@components/organisms'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import moment from 'moment'
import 'moment/locale/ko'
import { observer } from 'mobx-react'
import ArtistSettlementAnnualChart from '@components/organisms/Chart/ArtistSettlementAnnualChart'
import { useStore } from '@utils/hooks'
import { colors } from '@colors/'
import { add_btn } from '@images/'
import CompanyArtistSettlementAnnualChart from '@components/organisms/Chart/CompanyArtistSettlementAnnualChart'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 0 120px 0;
`

const InsightSettlementTemplate = () => {
  const { authStore } = useStore()

  const { currentUser } = authStore

  const [showUpload, setShowUpload] = useState(false)

  moment.locale('ko')

  const isLoginedMaster = authStore.isAdminPassword

  return (
    <Container>
      <Header>
        <Text type="Medium" size="18px" style={{ marginTop: '10px' }}>
          아티스트 정산 (정산월 기준)
        </Text>
        {
          isLoginedMaster && (
            <HeaderBtn onClick={() => setShowUpload(true)}>
              <HeaderBtnImg src={add_btn} alt="add_btn" />
              <Text type="Medium" size="14px" color={colors.main}>
              업로드
              </Text>
            </HeaderBtn>
          )
        }
      </Header>
      <Content>
        {
currentUser?.type === 'company' ? (
  <CompanyArtistSettlementAnnualChart />
) : (
  <ArtistSettlementAnnualChart />
)
        }
      </Content>
      <RevenueUploadModal
        show={showUpload}
        handleClose={() => setShowUpload(false)}
      />
    </Container>
  )
}

export default observer(InsightSettlementTemplate)

const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: #ffffff;
`

const HeaderBtnImg = styled(Image)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`
