import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { H4, H6, Flex } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: white;
  border-radius: 16px;
  overflow: scroll;
  min-width: 320px;
  min-height: 174px;

  @media ${mobile} {
    height: 199px;
    top: 40%;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const CancelBtn = styled.button`
  width: 134px;
  height: 48px;
  border-radius: 10px;
  border: none;
  background-color: #f4f4f4;
  color: #242424;
`

const Okbtn = styled.button`
  width: 134px;
  height: 48px;
  border-radius: 10px;
  border: none;
  background-color: #242424;
  color: #ffffff;
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({
  isShowing,
  toggle,
  onCancel,
  onOk,
  okText = '확인',
  title,
  body,
}) => {
  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    toggle()
  }

  const handleOk = () => {
    if (onOk) {
      onOk()
    }
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <H4 type="Bold" style={{ margin: '12px 0px 20px 0px' }}>
                {title}
              </H4>
              {body}
              <Flex
                style={{ justifyContent: 'space-between', marginTop: '30px' }}
              >
                <Okbtn onClick={handleOk}>
                  <H6 color="#ffffff">{okText}</H6>
                </Okbtn>
                <CancelBtn onClick={handleCancel}>
                  <H6>취소</H6>
                </CancelBtn>
              </Flex>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}
