import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Table, Input, Space, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { convertToPricingComma } from '@utils/format'
import { isArray } from 'lodash'
import { Link } from 'react-router-dom'
import { Caption1, Caption2, Flex, H4, Text } from '@components/atoms'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import moment from 'moment'
import styled from 'styled-components'
import { colors } from '@colors/'
import CountUp from 'react-countup'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { STORAGE_URL } from '@consts/'
import MonthlyPicker from '../Card/MonthlyPicker'

const copy_btn_img = require('@images/copy_img_gray.png')

const RevenueTrackTableChart = () => {
  const { revenueStore, authStore } = useStore()

  const { currentUser } = authStore
  const {
    artistTrackRevenueData,
    artistRecentMonth,
    revenueType,
  } = revenueStore

  const data = artistTrackRevenueData?.trackList || []

  const fetchRevenueAristTrackData =
    revenueStore.fetchRevenueAristTrackData || (() => {})

  const [currentMonth, setCurrentMonth] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const [enableMonthList, setEnableMonthList] = useState([])

  const searchInput = useRef(null)

  const monthlyDataList =
    artistTrackRevenueData?.revenueInfo?.artistMonthlyRevenueInfo

  const monthlyData = useMemo(() => {
    if (monthlyDataList && currentMonth) {
      const currnetMonthData = monthlyDataList?.filter(
        m => `${m.salesMonth}` === `${currentMonth?.format('YYMM')}`,
      )

      if (currnetMonthData?.length > 0) return currnetMonthData[0]
    }
    return null
  }, [monthlyDataList, currentMonth])

  // 검색 기능
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={
            e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (isArray(dataIndex)) {
        return record[dataIndex[0]][dataIndex[1]]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }
  // --end 검색기능

  const handleMonth = value => {
    setCurrentMonth(moment(value))
  }

  useEffect(() => {
    if (artistRecentMonth) {
      setCurrentMonth(
        moment().set({
          year: `20${Math.floor(artistRecentMonth / 100)}`,
          month: (artistRecentMonth % 100) - 1,
        }),
      )
    }
  }, [artistRecentMonth])

  useEffect(() => {
    if (currentUser?.artistId) {
      if (currentMonth) {
        fetchRevenueAristTrackData(currentUser?.artistId, {
          __month: currentMonth.format('YYMM'),
          __revenueType: revenueType ? revenueType : null,
        })
      }
    }
  }, [currentUser, currentMonth, fetchRevenueAristTrackData, revenueType])

  const sortHandler = (a, b, sortOrder) => {
    if (a < b) {
      return -1
    }
    else if (a > b) {
      return 1
    }
    else {
      return 0
    }
  }

  useEffect(() => {
    const convertMonth = month => {
      if (month < 10) {
        return `0${month}`
      }
      else {
        return `${month}`
      }
    }
    const getEnableMonthList = () => {
      if (!currentMonth) return
      const today = moment()
      let enableList = []
      if (today.isSameOrAfter(currentMonth)) {
        if (today.format('YYYY') === currentMonth.format('YYYY')) {
          enableList = Array(parseInt(today.format('M')))
            .fill(1)
            .map((val, index) => {
              return `${today.format('YY')}${convertMonth(index + 1)}`
            })
        }
        else {
          enableList = Array(12)
            .fill(1)
            .map((val, index) => {
              return `${currentMonth.format('YY')}${convertMonth(index + 1)}`
            })
        }
      }
      else {
        enableList = Array(12)
          .fill(1)
          .map((val, index) => {
            return `${currentMonth.format('YY')}${convertMonth(index + 1)}`
          })
      }
      setEnableMonthList(enableList)
    }
    getEnableMonthList()
  }, [currentMonth])

  return (
    <Flex type="column">
      <Flex align="center" style={{ gap: 12 }}>
        <SectionTitle>
          <MonthlyPicker
            currentMonth={currentMonth}
            handleMonth={handleMonth}
            enableMonthList={enableMonthList}
            // enableMonthList={monthlyDataList?.map(i => i.salesMonth)}
          />
        </SectionTitle>
        <Caption1 color={colors.brown_grey}>
          *최신 데이터를 전달받지 못하였습니다. 데이터를 전달 해 주세요.
        </Caption1>
      </Flex>
      <Flex style={{ margin: '20px 0 30px' }}>
        <InfoCard>
          <H4>월 수익</H4>
          <H4 type="Medium" color={colors.main} style={{ marginTop: '20px' }}>
            {
monthlyData?.totalRevenue && (
                <CountUp
                  start={0}
                  end={Math.floor(monthlyData.totalRevenue)}
                  separator=","
                  duration={3}
                />
              )
            }
            원
          </H4>
        </InfoCard>
        <InfoCard>
          <H4>월 인접권료</H4>
          <H4 type="Medium" color={colors.main} style={{ marginTop: '20px' }}>
            {
monthlyData?.monthlyAdjacencyFee && (
                <CountUp
                  start={0}
                  end={Math.floor(monthlyData.monthlyAdjacencyFee)}
                  separator=","
                  duration={3}
                />
              )
            }
            원
          </H4>
        </InfoCard>
        <InfoCard>
          <H4>월 저작권료</H4>
          <H4 type="Medium" color={colors.main} style={{ marginTop: '20px' }}>
            {
monthlyData?.monthlyCopyRightFee && (
                <CountUp
                  start={0}
                  end={Math.floor(monthlyData.monthlyCopyRightFee)}
                  separator=","
                  duration={3}
                />
              )
            }
            원
          </H4>
        </InfoCard>
        <InfoCard>
          <H4>월 실연권료</H4>
          <H4 type="Medium" color={colors.main} style={{ marginTop: '20px' }}>
            {
monthlyData?.monthlyPerformanceRightFee && (
                <CountUp
                  start={0}
                  end={Math.floor(monthlyData.monthlyPerformanceRightFee)}
                  separator=","
                  duration={3}
                />
              )
            }
            원
          </H4>
        </InfoCard>
      </Flex>
      <Table
        className="inflow_table"
        columns={
          [
            {
              title: '트랙명',
              key: 'title',
              dataIndex: 'title',
              align: 'left',
              sorter: (a, b, sortOrder) => {
                return sortHandler(a?.title, b?.title)
              },
              ...getColumnSearchProps('title'),
              width: '30%',
              render: (text, record) => (
                <Link to={`/insight/revenue/track/${record?._id}`}>
                  <Flex align="center">
                    <img
                      src={STORAGE_URL + record?.albumInfo?.image64Path}
                      alt="cover_img"
                      style={
                        {
                          width: '36px',
                          height: '36px',
                          borderRadius: '6px',
                          margin: '0 16px',
                        }
                      }
                    />
                    <Caption2
                      style={
                        {
                          fontSize: 12,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          flex: 1,
                        }
                      }
                      align="left"
                    >
                      {text}
                    </Caption2>
                  </Flex>
                </Link>
              ),
            },
            {
              title: '앨범명',
              key: 'albumInfoTitle',
              align: 'left',
              sorter: (a, b, sortOrder) => {
                return sortHandler(a?.albumInfo?.title, b?.albumInfo?.title)
              },
              dataIndex: ['albumInfo', 'title'],
              ...getColumnSearchProps(['albumInfo', 'title']),
              render: (text, record) => (
                <Link to={`/insight/revenue/album/${record?.albumId}`}>
                  <Caption2 align="left">{text}</Caption2>
                </Link>
              ),
            },
            {
              title: '누적수익',
              key: 'totalRevenue',
              dataIndex: ['revenueInfo', 'totalRevenue'],
              render: text => `${convertToPricingComma(Math.floor(text))}원`,
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                a?.revenueInfo?.totalRevenue,
                b?.revenueInfo?.totalRevenue,
                )
              },
              align: 'center',
            },
            {
              title: '월 수익',
              key: 'artistMonthlyAdjacencyFee',
              dataIndex: ['revenueInfo', 'artistMonthlyAdjacencyFee'],
              defaultSortOrder: { sortOrder: 'descend' },
              render: (text, record) => {
                const total =
                record?.revenueInfo?.artistMonthlyAdjacencyFee
                + record?.revenueInfo?.artistMonthlyPerformanceRightFee
                + record?.revenueInfo?.artistMonthlyCopyRightFee
                return `${convertToPricingComma(Math.floor(total))}원`
              },
              sorter: (a, b, sortOrder) => {
                const aTotal =
                a?.revenueInfo?.artistMonthlyAdjacencyFee
                + a?.revenueInfo?.artistMonthlyPerformanceRightFee
                + a?.revenueInfo?.artistMonthlyCopyRightFee
                const bTotal =
                b?.revenueInfo?.artistMonthlyAdjacencyFee
                + b?.revenueInfo?.artistMonthlyPerformanceRightFee
                + b?.revenueInfo?.artistMonthlyCopyRightFee

                return sortHandler(aTotal, bTotal)
              },
              align: 'center',
            },
            {
              title: '인접권료',
              key: 'artistMonthlyAdjacencyFee',
              dataIndex: ['revenueInfo', 'artistMonthlyAdjacencyFee'],
              render: text => `${convertToPricingComma(Math.floor(text))}원`,
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                a?.revenueInfo?.artistMonthlyAdjacencyFee,
                b?.revenueInfo?.artistMonthlyAdjacencyFee,
                )
              },
              align: 'center',
            },
            {
              title: '저작권료',
              key: 'artistMonthlyCopyRightFee',
              dataIndex: ['revenueInfo', 'artistMonthlyCopyRightFee'],
              render: text => `${convertToPricingComma(Math.floor(text))}원`,
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                a?.revenueInfo?.artistMonthlyCopyRightFee,
                b?.revenueInfo?.artistMonthlyCopyRightFee,
                )
              },
              align: 'center',
            },
            {
              title: '실연권료',
              key: 'artistMonthlyPerformanceRightFee',
              dataIndex: ['revenueInfo', 'artistMonthlyPerformanceRightFee'],
              render: text => `${convertToPricingComma(Math.floor(text))}원`,
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                a?.revenueInfo?.artistMonthlyPerformanceRightFee,
                b?.revenueInfo?.artistMonthlyPerformanceRightFee,
                )
              },
              align: 'center',
            },
            {
              title: '유통사',
              key: 'distributor',
              dataIndex: ['revenueInfo', 'distributor'],
              sorter: (a, b, sortOrder) => {
                return sortHandler(
                a?.revenueInfo?.distributor,
                b?.revenueInfo?.distributor,
                )
              },
              align: 'center',
            },

            {
              title: '음원 링크',
              key: 'dataObjectId',
              dataIndex: ['customUrlInfo', 'dataObjectId'],
              render: (text, record) => {
                return (
                  <Flex justify={'center'}>
                    <a
                      href={`https://plam.in/track/${text}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={copy_btn_img}
                        alt="copy"
                        style={
                          {
                            width: '22px',
                            height: '22px',
                            cursor: 'pointer',
                          }
                        }
                      />
                    </a>
                  </Flex>
                )
              },
              width: 80,
            },
          ]
        }
        dataSource={data}
        pagination={false}
        size={'small'}
        style={
          {
            width: '100%',
            height: '1335px',
            border: 'solid 1px #dddddd',
            borderRadius: '6px',
            marginBottom: '100px',
          }
        }
        rowKey="dataObjectId"
        scroll={{ y: 1335 }}
      />
    </Flex>
  )
}

export default observer(RevenueTrackTableChart)

const SectionTitle = styled(Text)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
`

const InfoCard = styled(Flex)`
  flex-direction: column;
  width: 234px;
  height: 167px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #fcfcfc;
  padding: 5px;
  margin: 0 50px 0 0;
  justify-content: center;
`
