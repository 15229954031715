/* eslint-disable import/no-unresolved */
import React from 'react'
import { Flex, Text } from '@components/atoms'
import { RSImageEmpty } from '@images/index'

export const ListEmpty = () => {
  return (
    <Flex
      style={
        {
          flexDirection: 'column',
          width: '100%',
          height: '500px',
          alignItems: 'center',
          justifyContent: 'center',
        }
      }
    >
      <img style={{ width: '200px' }} src={RSImageEmpty} alt={'empty data'} />
      <Text>아직 마감된 정산서가 없어요.</Text>
    </Flex>
  )
}
