import React from 'react'
import styled from 'styled-components'
import { Flex, Text, Container, H6, Caption1 } from '@components/atoms'
import { dateYMD } from '@utils/format'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 0 10px 0;
`

const ListBox = styled(Flex)`
  flex-direction: column;
  div + div {
    margin-top: 8px;
  }
`

export const ActivityLogsTemplate = ({
  artistModifyRecordList,
  artistModifyRecordListCount,
  setOffset,
  offset,
}) => {
  return (
    <Container>
      <Header>
        <Text type="Medium" size="18px" style={{ marginTop: '10px' }}>
          활동로그
        </Text>
      </Header>
      <Content>
        <Flex type="column">
          <ListBox>
            {artistModifyRecordList &&
              artistModifyRecordList.length > 0 &&
              artistModifyRecordList.map((item, index) => {
                const _date = item && item.createdAt && dateYMD(item.createdAt)
                return (
                  item.message && (
                    <Flex
                      style={{
                        marginBottom:
                          index < artistModifyRecordList.length - 1 &&
                          dateYMD(
                            artistModifyRecordList[index + 1].createdAt,
                          ).slice(0, 7) !== _date.slice(0, 7) &&
                          '20px',
                      }}
                    >
                      <H6
                        type="Regular"
                        align="left"
                        style={{ marginRight: 16 }}
                        color="#949494"
                      >
                        {_date}
                      </H6>
                      <H6 align="left">{item.message}</H6>
                    </Flex>
                  )
                )
              })}
          </ListBox>
          {artistModifyRecordList &&
            artistModifyRecordListCount >= artistModifyRecordList.length &&
            (offset + 1) * 9 < artistModifyRecordListCount && (
              <Flex
                style={{
                  alignSelf: 'flex-start',
                  width: '100%',
                  height: 32,
                  backgroundColor: '#d4d4d4',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 4,
                }}
                onClick={() => setOffset(prevState => prevState + 1)}
              >
                <Caption1 type="Bold" color="#646464">
                  더보기
                </Caption1>
              </Flex>
            )}
        </Flex>
      </Content>
    </Container>
  )
}
