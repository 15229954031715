import { observable } from 'mobx'

export default class DailyInflowStatsModel {
  /* Database fields */
  @observable _id

  @observable dailyFromCount
  @observable dailyYoutubeFromCount
  @observable dailyFacebookFromCount
  @observable dailyInstagramFromCount
  @observable dailyEtcFromCount

  @observable dailyClickCount
  @observable dailyClickCountFromFacebook
  @observable dailyClickCountFromInstagram
  @observable dailyClickCountFromYoutube
  @observable dailyClickCountFromEtc

  @observable dailyMelonUrlClickCount
  @observable dailyGenieUrlClickCount
  @observable dailyBugsUrlClickCount
  @observable dailyFloUrlClickCount
  @observable dailyVibeUrlClickCount
  @observable dailyYoutubeMusicUrlClickCount
  @observable dailyAppleMusicUrlClickCount
  @observable dailySpotifyUrlClickCount
  @observable dailyAmazonMusicUrlClickCount
  @observable dailyEtcUrlClickCount

  /* Database fields end */

  @observable date

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.dailyFromCount = props.dailyFromCount
      this.dailyYoutubeFromCount = props.dailyYoutubeFromCount
      this.dailyFacebookFromCount = props.dailyFacebookFromCount
      this.dailyInstagramFromCount = props.dailyInstagramFromCount
      this.dailyEtcFromCount = props.dailyEtcFromCount

      this.dailyClickCount = props.dailyClickCount
      this.dailyClickCountFromFacebook = props.dailyClickCountFromFacebook
      this.dailyClickCountFromInstagram = props.dailyClickCountFromInstagram
      this.dailyClickCountFromYoutube = props.dailyClickCountFromYoutube
      this.dailyClickCountFromEtc = props.dailyClickCountFromEtc

      this.dailyMelonUrlClickCount = props.dailyMelonUrlClickCount
      this.dailyGenieUrlClickCount = props.dailyGenieUrlClickCount
      this.dailyBugsUrlClickCount = props.dailyBugsUrlClickCount
      this.dailyFloUrlClickCount = props.dailyFloUrlClickCount
      this.dailyVibeUrlClickCount = props.dailyVibeUrlClickCount
      this.dailyYoutubeMusicUrlClickCount = props.dailyYoutubeMusicUrlClickCount
      this.dailyAppleMusicUrlClickCount = props.dailyAppleMusicUrlClickCount
      this.dailySpotifyUrlClickCount = props.dailySpotifyUrlClickCount
      this.dailyAmazonMusicUrlClickCount = props.dailyAmazonMusicUrlClickCount
      this.dailyEtcUrlClickCount = props.dailyEtcUrlClickCount

      this.date = `${props._id.fromMonth}월 ${props._id.fromDay}일`
    }
  }
}
