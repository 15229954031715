import React, { useState } from 'react'
// antd
import { Progress } from 'antd'
import styled from 'styled-components'
import { Flex, Text } from '@components/atoms'
import Files from 'react-butterfiles'
import { colors } from '@colors/'
import { observer } from 'mobx-react'

const clip_img = require('@images/clip.png')
const close_img = require('@images/x_btn_img.png')

const UploadBox = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 410px;
  height: 160px;
  border-radius: 6px;
  background-color: #f2f2f2;
  cursor: pointer;
  font-size: 14px;
  color: #949494;
`

const FileItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  span {
    flex-grow: 1;
  }

  img {
    width: 16px;
    height: 16px;
  }

  img:last-child {
    cursor: pointer;
  }
`

const RevenueUploadForm = props => {
  const [fileList, setFileList] = useState()
  // const [result, setResult] = useState(null)
  const [isUpload, setIsUpload] = useState(false)

  const { setSelectedFile, percent } = props

  return (
    <Files
      multiple={false}
      maxSize="500mb"
      multipleMaxSize="500mb"
      multipleMaxCount={1}
      // accept={'*'}
      onSuccess={async ([selectedFile]) => {
        setFileList(false)
        setIsUpload(true)
        setSelectedFile(selectedFile)
        setFileList([selectedFile])
      }}
      // onError={errors => setResult(errors)}
    >
      {({ browseFiles, getDropZoneProps }) => (
        <>
          <UploadBox
            onClick={browseFiles}
            {...getDropZoneProps({ className: 'myDropZone' })}
          >
            {fileList ? (
              <Text>
                {fileList.map(file => (
                  <FileItem key={file.name}>
                    <img src={clip_img} alt="clip" />
                    <Text>{file.name}</Text>
                    <img
                      src={close_img}
                      alt="close"
                      onClick={() => {
                        setFileList(null)
                        setIsUpload(false)
                      }}
                      style={{ width: 14, height: 14 }}
                    />
                  </FileItem>
                ))}
              </Text>
            ) : (
              <>
                <Text>
                  <Text type="Bold" color="black">
                    이곳을 클릭
                  </Text>
                  하거나 마우스로 옮겨서 업로드하세요.
                </Text>
                <Text>* 500mb 이하</Text>
              </>
            )}
          </UploadBox>
          {isUpload
            ? !fileList && (
                <Progress
                  percent={percent}
                  strokeColor={colors.main}
                  strokeWidth="4px"
                />
              )
            : null}
        </>
      )}
    </Files>
  )
}

export default observer(RevenueUploadForm)
