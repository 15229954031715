import { Caption1, H6 } from '@components/atoms'
import React from 'react'
import styled from 'styled-components'

const unCheck_img = require('@images/unCheck_img.png')
const check_img = require('@images/check_plam.png')

const CheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: NotoSansCJKkr-Medium;
  font-size: 16px;
  color: #646464;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
    margin: 8px;
  }
`

export const CheckTextBox = props => {
  return (
    <CheckBox onClick={props.onClick} style={props.style && props.style}>
      {props.checked ? (
        <img src={check_img} alt="check_img" />
      ) : (
        <img src={unCheck_img} alt="check_img" />
      )}
      <Caption1
        style={{
          display: 'inline-block',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {props.text}
      </Caption1>
    </CheckBox>
  )
}
