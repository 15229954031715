
import React from 'react'
// eslint-disable-next-line import/no-unresolved
import { Text } from '@components/atoms'

export const CarryMark = () => {
  return (
    <div style={{ backgroundColor: 'orange', marginRight: '4px', paddingLeft: '4px', paddingRight: '4px', paddingBottom: '1px', borderRadius: '3px', }}>
      <Text style={{ color: 'white', fontSize: '12px', }}>이월</Text>
    </div>
  )
}