import React from 'react'
import styled from 'styled-components'
import { H4, Flex, Button } from '@components/atoms'
import {
  CustomModal,
  ToggleCompanyBtn,
  ToggleArtistBtn,
} from '@components/molecules'
import { colors } from '@colors/'
import { mobile } from '@styles/media'

const ModalBody = styled(Flex)`
  width: 490px;
  @media ${mobile} {
    width: 100%;
  }
  height: 386px;
  padding: 30px;
  flex-direction: column;
  align-items: center;
`

const Btn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 48px;
  border-radius: 25px;
  background-color: ${props => (props.disabled ? '#f4f4f4' : colors.main)};
  color: ${props => (props.disabled ? '#949494' : '#f4f4f4')};
  border: ${props => (props.disabled ? 'none' : colors.main)};
`

const View = ({
  isShowing,
  toggle,
  type,
  setType,
  handleArtist,
  handleCompany,
}) => {
  const handleConfirm = () => {
    if (type === 'melon') {
      toggle()
      handleCompany()
    } else if (type === 'direct') {
      handleArtist()
      toggle()
    }
  }

  return (
    <>
      <CustomModal.View isShowing={isShowing} toggle={toggle}>
        <ModalBody type="column">
          <H4 style={{ marginTop: 30 }}>계정 유형을 선택해주세요</H4>

          <Flex
            style={{
              margin: '42px 0px',
              width: '216px',
              justifyContent: 'space-between',
            }}
          >
            <ToggleCompanyBtn
              active={type === 'melon'}
              onClick={() => setType('melon')}
            />
            <ToggleArtistBtn
              active={type === 'direct'}
              onClick={() => setType('direct')}
            />
          </Flex>
          <Btn
            text="확인"
            onClick={() => handleConfirm()}
            disabled={type !== '' ? false : true}
          >
            확인
          </Btn>
        </ModalBody>
      </CustomModal.View>
    </>
  )
}

export default {
  View,
  useModal: CustomModal.useModal,
}
