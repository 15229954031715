import React, { useState } from 'react'
import styled from 'styled-components'
import { H4, Flex, Input } from '@components/atoms'
import { CustomModal } from '@components/molecules'
import { colors } from '@colors/'

const ModalBody = styled(Flex)`
  width: 580px;
  /* height: 300px; */
  padding: 30px;
  flex-direction: column;
  align-items: center;
`

const Btn = styled.div`
  display: flex;
  font-family: NotoSansCJKkr-Medium;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 48px;
  border-radius: 25px;
  cursor: pointer;
  background-color: ${props => (props.disabled ? '#f4f4f4' : colors.main)};
  color: ${props => (props.disabled ? '#949494' : '#f4f4f4')};
  border: ${props => (props.disabled ? 'none' : colors.main)};
  margin-top: 32px;
`

const View = ({ isShowing, toggle, smartLink, onCreate, onUpdate }) => {
  const [title, setTitle] = useState(smartLink?.title || '')
  const [linkUrl, setLinkUrl] = useState(smartLink?.linkUrl || '')

  const handleTitleChange = e => {
    setTitle(e.currentTarget.value)
  }

  const handleUrlChange = e => {
    setLinkUrl(e.currentTarget.value)
  }

  const handleSubmit = async () => {
    if (smartLink?.title) {
      await onUpdate({ title, linkUrl })
    } else {
      await onCreate({ title, linkUrl })
      setTitle('')
      setLinkUrl('')
    }
    toggle()
  }

  return (
    <>
      <CustomModal.View isShowing={isShowing} toggle={toggle}>
        <ModalBody type="column">
          <H4 style={{ margin: '30px 0 40px 0' }}>스마트 링크</H4>
          <Flex type="column" gap="16px" style={{ width: 400 }}>
            <Input
              placeholder="링크 제목 입력"
              value={title}
              onChange={handleTitleChange}
              width={400}
              height={40}
            />
            <Input
              placeholder="링크 주소 입력"
              value={linkUrl}
              onChange={handleUrlChange}
              width={400}
              height={40}
            />
          </Flex>
          <Btn onClick={handleSubmit} disabled={!title || !linkUrl}>
            {smartLink?.title ? '링크 수정' : '링크 만들기'}
          </Btn>
        </ModalBody>
      </CustomModal.View>
    </>
  )
}

export default {
  View,
  useModal: CustomModal.useModal,
}
