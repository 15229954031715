import React from 'react'
import { Upload, message } from 'antd'

import { API_URL } from '@consts'
import styled from 'styled-components'
import { clip_img_blue } from '@images/'

const getPresignedPostData = selectedFile => {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest()
    // Set the proper URL here.
    const url = `${API_URL}/file/certificate`

    xhr.open('POST', url, true)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.setRequestHeader('Authorization', `jwt`)
    xhr.send(
      JSON.stringify({
        name: selectedFile.name,
        type: selectedFile.type,
        isCertificate: true,
      }),
    )
    xhr.onload = function() {
      resolve(JSON.parse(this.responseText))
    }
  })
}

const uploadFileToS3 = (presignedPostData, file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    Object.keys(presignedPostData.fields).forEach(key => {
      formData.append(key, presignedPostData.fields[key])
    })
    // Actual file has to be appended last.
    formData.append('file', file)
    const xhr = new XMLHttpRequest()
    xhr.open('POST', presignedPostData.url, true)
    xhr.send(formData)
    xhr.onload = function(ev) {
      this.status === 204 ? resolve(true) : reject(this.responseText)
    }
  })
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === 'image/jpg' ||
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'application/pdf'

  if (!isJpgOrPng) {
    message.error('jpg나 pdf 파일 형식을 업로드해주세요.')
  }
  const isLt10M = file.size / 1024 / 1024 < 10
  if (!isLt10M) {
    message.error('10MB 이하의 파일을 업로드해주세요.')
  }
  return isJpgOrPng && isLt10M
}

const CertificateUploadForm = ({
  imageOriginalPath,
  fileUrl,
  setFileUrl,
  label,
}) => {
  const _onChange = async info => {
    let key = null
    if (info.file.status !== 'uploading') {
      const { data: presignedPostData } = await getPresignedPostData(info.file)
      key = presignedPostData.fields.key
      try {
        await uploadFileToS3(presignedPostData, info.file.originFileObj)
        setFileUrl(`/${key}`)
        message.success(`${info.file.name} 업로드 완료!`)
      } catch (e) {
        console.log('upload error occurred!', e.message)
        message.error(`${info.file.name} 업로드 실패.`)
      }
    }
  }

  return (
    <CustomUpload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={_onChange}
      accept={['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']}
    >
      <div
        style={{
          maxWidth: '518px',
          width: '100%',
          height: '216px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          borderRadius: '6px',
          backgroundColor: '#f2f2f2',
          cursor: 'pointer',
          border: 'dashed 2px #949494',
        }}
      >
        {fileUrl ? (
          <p
            className="ant-upload-hint"
            style={{ color: 'rgba(0, 0, 0, .45)', fontSize: 14 }}
          >
            <img
              src={clip_img_blue}
              alt="clip_icon"
              style={{ width: 20, height: 20, marginRight: 4 }}
            />
            {fileUrl.replace(
              '/upload/certificate_of_business_registration/',
              '',
            )}
          </p>
        ) : (
          <>
            <p
              className="ant-upload-hint"
              style={{ color: '#242424', fontSize: 14 }}
            >
              {label}
              <strong>이곳을 클릭</strong>
              하거나 마우스로 옮겨서 업로드 해주세요.
            </p>
            PDF 파일 또는 jpg 파일
          </>
        )}
      </div>
    </CustomUpload>
  )
}

export default CertificateUploadForm

const CustomUpload = styled(Upload)`
  .ant-upload {
    padding: 0 !important;
  }
`
