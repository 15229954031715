import React from 'react'
import { H1, H4, H6 } from '@components/atoms'
import styled from 'styled-components'
import { LogoImg, MoveBtn, NoHeaderBox } from '@components/molecules'
import { colors } from '@colors/'

const EmailBox = styled.div`
  margin: 60px 0 80px 0;
  width: 734px;
  height: 140px;
  border-radius: 16px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NoAuthEmailTemplate = ({ user, emailAuth }) => {
  return (
    <NoHeaderBox>
      <LogoImg />
      <H1>
        이메일 인증을
        <br />
        완료해주세요.
      </H1>
      <EmailBox>
        <H4 color={colors.main}>
          {
            user && user.type === 'company'
              ? user.staffEmail
              : user && user.email
                ? user.email
                : false
          }
        </H4>
      </EmailBox>
      <H6 color="#949494">
        받은 메일함에서 이메일을 인증하고 PLAM을 시작하세요.
        <br />
        메일이 보이지 않는다면 스팸 메일함을 확인해주세요.
      </H6>
      <H6
        onClick={() => emailAuth(user && user._id)}
        style={{ margin: '80px 0 20px 0', cursor: 'pointer' }}
        color="#646464"
      >
        인증메일 재전송
      </H6>

      <MoveBtn href="/register/update-email" text="이메일 주소 수정" />
    </NoHeaderBox>
  )
}
