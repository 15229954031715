import React, { useEffect } from 'react'
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts'
import {
  Caption1,
  Caption2,
  Flex,
  H4,
  H5,
  Image,
  Text,
} from '@components/atoms'

import styled from 'styled-components'
import { convertToPricingComma } from '@utils/format'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { Table } from 'antd'
import moment from 'moment'
import { path_menu } from '@images/'
import CountUp from 'react-countup'
import CopyToClipboard from 'react-copy-to-clipboard'

const copy_btn_img = require('@images/copy_img_gray.png')

const COLORS = ['#fcc302', '#ea4653', '#66bc6a', '#4e5cff']

const NoData = styled(Flex)`
  width: 1054px;
  height: 500px;
  justify-content: center;
  align-items: center;
`

const CompanyTrackSettlementAnnualChart = () => {
  // const [trackCurrentMonth, setTrackCurrentMonth] = useState()

  const handleYear = value => {
    setTrackCurrentMonth(moment(value))
  }

  const { settlementStore, revenueStore } = useStore()

  const { trackAnnualData, trackCurrentMonth } = settlementStore
  const { companyRecentMonth } = revenueStore

  const settlementInfo = trackAnnualData?.settlementInfo

  const setTrackCurrentMonth =
    settlementStore.setTrackCurrentMonth || (() => {})

  // 전체 월별 리스트
  const companyTrackMonthlySettlementList =
    settlementInfo?.companyTrackMonthlySettlementList?.sort(
      (a, b) => b.month - a.month,
    ) || []

  const companyTrackRecentMonthlySettlementInfo =
    companyTrackMonthlySettlementList[0]

  const displayYear = trackCurrentMonth?.format('YYYY년')
  const displayMonth = moment()
    .set({
      year: `20${Math.floor(
        companyTrackRecentMonthlySettlementInfo?.month / 100,
      )}`,
      month: (companyTrackRecentMonthlySettlementInfo?.month % 100) - 1,
    })
    ?.format('YYYY년 MM월')

  useEffect(() => {
    if (companyRecentMonth) {
      setTrackCurrentMonth(
        moment().set({
          year: `20${Math.floor(companyRecentMonth / 100)}`,
        }),
      )
    }
  }, [companyRecentMonth, setTrackCurrentMonth])

  return (
    <Flex type="column">
      <SectionTitle>
        <Image
          src={path_menu}
          alt="prev"
          width="18px"
          height="18px"
          transform="rotate(180deg)"
          cursor="pointer"
          onClick={() => handleYear(trackCurrentMonth.subtract(1, 'years'))}
        />
        {displayYear}
        <Image
          src={path_menu}
          alt="next"
          width="18px"
          height="18px"
          opacity={moment().isSameOrBefore(trackCurrentMonth, 'year') ? 0.5 : 1}
          cursor={
            moment().isSameOrBefore(trackCurrentMonth, 'year')
              ? 'not-allowed'
              : 'pointer'
          }
          onClick={
            () => {
              if (moment().isSameOrBefore(trackCurrentMonth, 'year')) return
              handleYear(trackCurrentMonth.add(1, 'years'))
            }
          }
        />
      </SectionTitle>
      <Flex style={{ margin: '20px 0 50px' }}>
        <InfoCard>
          <H5>{`${displayYear} 트랙의 총 정산금액은`}</H5>
          <H4 type="bold">
            &nbsp;
            {
              (settlementInfo?.companyAnnualSettlementFee
              || settlementInfo?.artistAnnualSettlementFee) && (
                <CountUp
                  start={0}
                  end={
                    Math.floor(settlementInfo?.companyAnnualSettlementFee || 0)
                  + Math.floor(settlementInfo?.artistAnnualSettlementFee || 0)
                  }
                  separator=","
                  duration={3}
                />
              )
            }
            원 &nbsp;
          </H4>
          {
            <H5 style={{ fontSize: '18px' }} type={'Regular'} color={'#646464'}>
              {
                `(사업자 ${convertToPricingComma(
                  Math.floor(settlementInfo?.companyAnnualSettlementFee || 0),
                )}원, 아티스트 ${convertToPricingComma(
                  Math.floor(settlementInfo?.artistAnnualSettlementFee || 0),
                )}원)`
              }
              &nbsp;
            </H5>
          }
          <H5>입니다.</H5>
        </InfoCard>
      </Flex>

      <Flex
        style={
          {
            borderRadius: '6px',
            boxShadow: '0 4px 10px 0 rgba(36, 36, 36, 0.16)',
            backgroundColor: '#fff',
          }
        }
      >
        {
companyTrackMonthlySettlementList?.length === 0 ? (
  <NoData>No data</NoData>
) : (
  <ResponsiveContainer width="95%" height={500}>
    <ComposedChart
      // width={1054}
      // height={500}
      data={companyTrackMonthlySettlementList.slice().reverse()}
      margin={
        {
          top: 50,
          right: 0,
          bottom: 50,
          left: 50,
        }
      }
    >
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis
        dataKey="month"
        tickFormatter={
          tick => {
            const strTick = `${tick}`
            return `${strTick[2]}${strTick[3]}월`
          }
        }
        allowDuplicatedCategory={false}
      />
      <YAxis
        dataKey="trackMonthlySettlementFee"
        // domain={['auto', 'auto']}
        unit="원"
        tickFormatter={e => convertToPricingComma(e)}
      />
      <Tooltip
        content={
          ({ active, payload, label }) => (
            <CustomTooltip
              active={active}
              payload={payload}
              label={label}
            />
          )
        }
      />
      {/* <Tooltip /> */}
      <Legend verticalAlign="top" layout="horizontal" align="center" />
      <Line
        name="전체"
        legendType="none"
        dataKey="trackMonthlySettlementFee"
        stroke={COLORS[2]}
      />
      <Line
        name="사업자"
        legendType="none"
        dataKey="companyMonthlySettlementFee"
        stroke={COLORS[1]}
      />
      <Line
        name="아티스트"
        legendType="none"
        dataKey="artistMonthlySettlementFee"
        stroke={COLORS[3]}
      />
    </ComposedChart>
  </ResponsiveContainer>
)
        }
      </Flex>
      <H5 type="bold" align="left" style={{ margin: '50px 0 20px' }}>
        월간 정산 세부내역
      </H5>
      <Flex style={{ margin: '20px 0' }}>
        <InfoCard>
          <H5>{`${displayMonth} 트랙의 총 정산금액은`}</H5>
          <H4 type="bold">
            &nbsp;
            {
companyTrackRecentMonthlySettlementInfo?.trackMonthlySettlementFee && (
                <CountUp
                  start={0}
                  end={
                    Math.floor(
                  companyTrackRecentMonthlySettlementInfo?.trackMonthlySettlementFee,
                    )
                  }
                  separator=","
                  duration={3}
                />
              )
            }
            원 &nbsp;
          </H4>
          <H5>입니다.</H5>
        </InfoCard>
      </Flex>
      <Flex>
        <TableBox>
          <Table
            pagination={false}
            rowKey="_id"
            dataSource={companyTrackMonthlySettlementList || []}
            columns={
              [
                {
                  title: '월',
                  key: 'month',
                  dataIndex: 'month',
                  render: text => {
                    return `20${parseInt(text / 100)}년 ${text % 100}월`
                  },
                  width: '200px',
                  align: 'center',
                },
                {
                  title: '유통사',
                  key: 'distributor',
                  dataIndex: 'distributor',
                  align: 'center',
                  width: '400px',
                },
                {
                  title: '사업자 정산수익',
                  key: 'companyMonthlySettlementFee',
                  dataIndex: 'companyMonthlySettlementFee',
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  width: '238px',
                  align: 'center',
                },
                {
                  title: '아티스트 정산수익',
                  key: 'artistMonthlySettlementFee',
                  dataIndex: 'artistMonthlySettlementFee',
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  width: '238px',
                  align: 'center',
                },
                {
                  title: '인접권 정산 비율',
                  key: 'companySettlementFeeRatio',
                  dataIndex: 'companySettlementFeeRatio',
                  sorter: (a, b) =>
                  a?.companySettlementFeeRatio - b?.companySettlementFeeRatio,
                  render: text =>
                    `${
                      typeof text === 'number'
                        ? `${(text * 100).toFixed(2)}%`
                        : '-'
                    }`,
                  width: '166px',
                  align: 'center',
                },
                {
                  title: '트랙 정산 수익',
                  key: 'trackMonthlySettlementFee',
                  dataIndex: 'trackMonthlySettlementFee',
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  width: '238px',
                  align: 'center',
                },
              ]
            }
          />
        </TableBox>
      </Flex>
    </Flex>
  )
}

export default observer(CompanyTrackSettlementAnnualChart)

const ToolTipBox = styled(Flex)`
  width:360px;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;

  p {margin: 3px 0;}
  p:first-child {margin 0;}
`

const SectionTitle = styled(Text)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  margin-bottom: 20px;
`

const InfoCard = styled(Flex)`
  flex: 1;
  /* width: 234px; */
  height: 100px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #fcfcfc;
  padding: 5px;
  justify-content: center;
  align-items: center;
`

const TableBox = styled.div`
  width: 100%;

  table {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #ddd;
    background-color: #fcfcfc;
  }

  tr {
    cursor: pointer;
  }

  thead > tr > th {
    background-color: transparent;
    color: #646464;
  }

  thead > tr > th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5),
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(8) {
    text-align: center;
    min-width: 88px;
  }
`

const CustomTooltip = ({ active, payload, label }) => {
  const strTick = `${label}`
  const customLabel = `${strTick[0]}${strTick[1]}년 ${strTick[2]}${strTick[3]}월`

  if (active && payload && payload[0]) {
    return (
      <ToolTipBox type="column">
        <Caption2 type="Bold" align="left">{`${customLabel}`}</Caption2>

        {/* <Flex key={payload[0].name} align="center" justify="space-between">
          <Caption1 align="left">정산금액</Caption1>
          <Caption1>
            {
              `${payload[0]
              && convertToPricingComma(
                Math.floor(payload[0]?.payload?.companyMonthlySettlementFee),
              )}원`
            }
          </Caption1>
        </Flex> */}

        <Flex key={payload[0].name} align="center" justify="space-between">
          <Flex align="center" style={{ gap: 4 }}>
            <div
              style={
                {
                  display: 'inline-block',
                  width: 6,
                  height: 6,
                  borderRadius: '50%',
                  backgroundColor: COLORS[2],
                }
              }
            />
            <Caption1 align="left">총 정산금액</Caption1>
          </Flex>
          <Caption1>
            {
              `${payload[0]
              && convertToPricingComma(
                Math.floor(payload[0]?.payload?.trackMonthlySettlementFee),
              )}원`
            }
          </Caption1>
        </Flex>
        <Flex key={payload[0].name} align="center" justify="space-between">
          <Flex align="center" style={{ gap: 4 }}>
            <div
              style={
                {
                  display: 'inline-block',
                  width: 6,
                  height: 6,
                  borderRadius: '50%',
                  backgroundColor: COLORS[1],
                }
              }
            />
            <Caption1 align="left">사업자 정산금액</Caption1>
          </Flex>

          <Caption1>
            {
              `${payload[0]
              && convertToPricingComma(
                Math.floor(payload[0]?.payload?.companyMonthlySettlementFee),
              )}원`
            }
          </Caption1>
        </Flex>
        <Flex key={payload[0].name} align="center" justify="space-between">
          <Flex align="center" style={{ gap: 4 }}>
            <div
              style={
                {
                  display: 'inline-block',
                  width: 6,
                  height: 6,
                  borderRadius: '50%',
                  backgroundColor: COLORS[3],
                }
              }
            />
            <Caption1 align="left">아티스트 정산금액</Caption1>
          </Flex>
          <Caption1>
            {
              `${payload[0]
              && convertToPricingComma(
                Math.floor(payload[0]?.payload?.artistMonthlySettlementFee),
              )}원`
            }
          </Caption1>
        </Flex>
      </ToolTipBox>
    )
  }
  return null
}
