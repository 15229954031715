import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  Flex,
  Text,
  H4,
  Button,
  Image,
  H6,
  H3,
  Caption1,
  Div,
  H5,
} from '@components/atoms'
import {
  CompanyRevenueAdjacencyStatsChart,
  CompanyRevenueAdjacencyTrackTableChart,
  RevenueUploadModal,
} from '@components/organisms'
import { colors } from '@colors/'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import moment from 'moment'
import 'moment/locale/ko'
import CountUp from 'react-countup'
import { observer } from 'mobx-react'
import { path_menu, add_btn } from '@images/'
import { fullHD } from '@styles/media'
import CompanySettlementAnnualChart from '@components/organisms/Chart/CompanySettlementAnnualChart'
import MonthlyPicker from '@components/organisms/Card/MonthlyPicker'
import { convertToPricingComma } from '@utils/format'

const Line = styled(Flex)`
  /* width: '100%'; */
  height: 1px;
  margin: 30px 0;
  justify-content: space-between;
  align-items: center;
  border-top: solid 0.5px #d4d4d4;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 0 0 0;
`

const SectionTitle = styled(Text)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  margin-bottom: 20px;
`

const InfoCard = styled(Flex)`
  flex: 1;
  /* width: 234px; */
  height: 167px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #fcfcfc;
  padding: 5px;
  margin: 0 50px 0 0;
  justify-content: center;
  align-items: center;
`

const CompanyInsightRevenueAdjacencyTemplate = ({
  companyRevenueData,
  companyAnnualRevenueData,
  currentYear,
  handleYear,
  handleMoveArtist,
  handleMoveAlbum,
}) => {
  const [showUpload, setShowUpload] = useState(false)
  const [currentMonth, setCurrentMonth] = useState(null)

  const companyRevenueInfo =
    companyRevenueData?.revenueInfo?.companyRevenueInfo[0] || null

  const monthlyDataList =
    companyAnnualRevenueData?.revenueInfo?.companyMonthlyRevenueInfo

  const displayYear = currentYear?.format('YYYY년')

  const handleMonth = value => {
    setCurrentMonth(moment(value))
  }
  useEffect(() => {
    if (monthlyDataList?.length > 0) {
      const recentMonth =
        monthlyDataList[monthlyDataList.length - 1]?.salesMonth
      if (recentMonth) {
        setCurrentMonth(
          moment().set({
            year: `20${Math.floor(recentMonth / 100)}`,
            month: (recentMonth % 100) - 1,
          }),
        )
      }
    }
  }, [monthlyDataList])

  moment.locale('ko')

  const currentMonthlyDataListData = useMemo(() => {
    return monthlyDataList?.filter(
      item => `${item.salesMonth}` === currentMonth?.format('YYMM'),
    )
  }, [currentMonth, monthlyDataList])

  return (
    <Flex type="column">
      <Content>
        <Flex type="row">
          <H3 align="left" style={{ flex: 1 }}>
            음원 수익 (인접권료, 정산월 기준)
          </H3>
          <HeaderBtn onClick={() => setShowUpload(true)}>
            <HeaderBtnImg src={add_btn} alt="add_btn" />
            <Text type="Medium" size="14px" color={colors.main}>
              업로드
            </Text>
          </HeaderBtn>
        </Flex>

        <CompanySettlementAnnualChart />

        <Line style={{ margin: '50px 0' }} />

        <Flex type="column">
          <H3 align="left">음원 수익 (인접권료, 판매월 기준)</H3>
        </Flex>
        <SectionTitle>
          <Image
            src={path_menu}
            alt="prev"
            width="18px"
            height="18px"
            transform="rotate(180deg)"
            cursor="pointer"
            onClick={() => handleYear(currentYear.subtract(1, 'years'))}
          />
          {displayYear}
          <Image
            src={path_menu}
            alt="next"
            width="18px"
            height="18px"
            opacity={moment().isSameOrBefore(currentYear, 'year') ? 0.5 : 1}
            cursor={
              moment().isSameOrBefore(currentYear, 'year')
                ? 'not-allowed'
                : 'pointer'
            }
            onClick={
              () => {
                if (moment().isSameOrBefore(currentYear, 'year')) return
                handleYear(currentYear.add(1, 'years'))
              }
            }
          />
        </SectionTitle>
        <CompanyRevenueAdjacencyStatsChart currentYear={currentYear} />

        <Flex>
          <Flex type="column" style={{ width: '100%' }}>
            <Flex style={{ width: '100%' }}>
              <InfoCard type="column" style={{ position: 'relative' }}>
                <H6
                  color={colors.sub}
                  style={
                    {
                      position: 'absolute',
                      top: '16px',
                      right: '16px',
                    }
                  }
                >
                  {`*${currentMonth?.format('YYYY년 MM월')}`}
                </H6>
                <H4>월간 인접 수익</H4>
                <H4
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '8px', marginBottom: '8px' }}
                >
                  {
                    currentMonthlyDataListData
                    && (currentMonthlyDataListData[0]?.monthlyAdjacencyFee
                      || currentMonthlyDataListData?.[0]
                        ?.artistMonthlyAdjacencyFee) && (
                      <CountUp
                        start={0}
                        end={
                          Math.floor(
                            currentMonthlyDataListData[0]?.monthlyAdjacencyFee,
                          )
                          + Math.floor(
                            currentMonthlyDataListData?.[0]
                              ?.artistMonthlyAdjacencyFee || 0,
                          )
                        }
                        separator=","
                        duration={3}
                      />
                    )
                  }
                  원
                </H4>
                <H5
                  style={{ fontSize: '18px' }}
                  type={'Regular'}
                  color={'#646464'}
                >
                  {
                    `(사업자 ${convertToPricingComma(
                      Math.floor(
                      currentMonthlyDataListData?.[0]?.monthlyAdjacencyFee || 0,
                      ),
                    )}원, 아티스트 ${convertToPricingComma(
                      Math.floor(
                      currentMonthlyDataListData?.[0]
                        ?.artistMonthlyAdjacencyFee || 0,
                      ),
                    )}원)`
                  }
                  &nbsp;
                </H5>
              </InfoCard>
              <InfoCard type="column" style={{ position: 'relative' }}>
                <H6
                  style={
                    {
                      position: 'absolute',
                      top: '16px',
                      right: '16px',
                    }
                  }
                  color={colors.sub}
                >
                  *전체
                </H6>
                <H4>누적 인접 수익</H4>
                <H4
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '8px', marginBottom: '8px' }}
                >
                  {
                    (companyRevenueInfo?.totalAdjacencyFee
                    || companyRevenueInfo?.artistTotalAdjacencyFee) && (
                      <CountUp
                        start={0}
                        end={
                          Math.floor(companyRevenueInfo?.totalAdjacencyFee || 0)
                        + Math.floor(
                          companyRevenueInfo?.artistTotalAdjacencyFee || 0,
                        )
                        }
                        separator=","
                        duration={3}
                      />
                    )
                  }
                  원
                </H4>
                <H5
                  style={{ fontSize: '18px' }}
                  type={'Regular'}
                  color={'#646464'}
                >
                  {
                    `(사업자 ${convertToPricingComma(
                      Math.floor(companyRevenueInfo?.totalAdjacencyFee || 0),
                    )}원, 아티스트 ${convertToPricingComma(
                      Math.floor(
                      companyRevenueInfo?.artistTotalAdjacencyFee || 0,
                      ),
                    )}원)`
                  }
                  &nbsp;
                </H5>
              </InfoCard>
              <InfoCard type="column" style={{ position: 'relative' }}>
                <H6
                  style={
                    {
                      position: 'absolute',
                      top: '16px',
                      right: '16px',
                    }
                  }
                  color={colors.sub}
                >
                  {`*${displayYear}`}
                </H6>
                <H4>연간 인접권 수익</H4>
                <H4
                  type="Medium"
                  color={colors.main}
                  style={{ marginTop: '8px', marginBottom: '8px' }}
                >
                  {
                    (companyRevenueInfo?.annualAdjacencyFee
                    || companyRevenueInfo?.artistAnnualAdjacencyFee) && (
                      <CountUp
                        start={0}
                        end={
                          Math.floor(companyRevenueInfo.annualAdjacencyFee || 0)
                        + Math.floor(
                          companyRevenueInfo?.artistAnnualAdjacencyFee || 0,
                        )
                        }
                        separator=","
                        duration={3}
                      />
                    )
                  }
                  원
                </H4>
                <H5
                  style={{ fontSize: '18px' }}
                  type={'Regular'}
                  color={'#646464'}
                >
                  {
                    `(사업자 ${convertToPricingComma(
                      Math.floor(companyRevenueInfo?.annualAdjacencyFee || 0),
                    )}원, 아티스트 ${convertToPricingComma(
                      Math.floor(
                      companyRevenueInfo?.artistAnnualAdjacencyFee || 0,
                      ),
                    )}원)`
                  }
                  &nbsp;
                </H5>
              </InfoCard>
              {/* <InfoCard type="column">
                <H6 color={colors.grayLight}>(전체)</H6>
                <H4>보유 인접권</H4>
                {companyRevenueInfo?.adjacencyTrackCount ? (
                  <H4
                    type="Medium"
                    color={colors.main}
                    style={{ marginTop: '20px' }}
                  >
                    <CountUp
                      start={0}
                      end={Math.floor(companyRevenueInfo.adjacencyTrackCount)}
                      separator=","
                      duration={3}
                    />
                    건
                  </H4>
                ) : (
                  <H4
                    type="Medium"
                    color={colors.main}
                    style={{ marginTop: '20px' }}
                  >
                    -
                  </H4>
                )}
              </InfoCard> */}
            </Flex>
          </Flex>
        </Flex>

        <Flex type="column" style={{ marginTop: '50px' }}>
          <SectionTitle>세부 내역</SectionTitle>
          <SectionTitle>
            <Flex align="center" style={{ gap: 12 }}>
              <MonthlyPicker
                currentMonth={currentMonth}
                handleMonth={handleMonth}
                enableMonthList={monthlyDataList?.map(i => i.salesMonth)}
              />
              <Caption1 color={colors.brown_grey}>
                *최신 데이터를 전달받지 못하였습니다. 데이터를 전달 해 주세요.
              </Caption1>
            </Flex>
          </SectionTitle>
          <CompanyRevenueAdjacencyTrackTableChart
            currentYear={currentYear}
            currentMonth={currentMonth}
            handleMoveArtist={handleMoveArtist}
            handleMoveAlbum={handleMoveAlbum}
          />
        </Flex>
      </Content>
      <RevenueUploadModal
        show={showUpload}
        handleClose={() => setShowUpload(false)}
      />
    </Flex>
  )
}

export default observer(CompanyInsightRevenueAdjacencyTemplate)

const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px ${colors.main};
  background-color: #ffffff;
`

const HeaderBtnImg = styled(Image)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`

export const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
  padding: 0px 0px 0px 40px;
  width: 100%;

  @media ${fullHD} {
    padding: 0 40px;
  }
`
