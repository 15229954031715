import React, { useEffect, useState } from 'react'
import { RootTemplate } from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import moment from 'moment'
import { NoRevenue, RevenueUploadModal } from '@components/organisms'
import { useHistory } from 'react-router-dom'
import InsightSettlementTemplate from '@components/templates/InsightSettlementTemplate'

interface Props {
  logout: Function;
  user: Object;
}

const InsightSettlementPage = ({ user, logout }: Props) => {
  // const [type, setType] = useState(null)
  const history = useHistory()

  const { authStore, revenueStore, artistStore } = useStore()
  const { currentUser } = authStore
  const { artistRecentMonth } = revenueStore
  const { artistDetail } = artistStore

  const fetchArtistRecentMonth =
    revenueStore.fetchArtistRecentMonth || (() => { })
  const fetchRevenueAristData = revenueStore.fetchRevenueAristData || (() => { })

  const [currentYear, setCurrentYear] = useState(null)
  const [showUpload, setShowUpload] = useState(false)

  useEffect(() => {
    if (artistRecentMonth) {
      setCurrentYear(
        moment().set({
          year: `20${Math.floor(artistRecentMonth / 100)}`,
          month: (artistRecentMonth % 100) - 1,
        }),
      )
    }
  }, [artistRecentMonth, setShowUpload])

  useEffect(() => {
    if (currentUser?.artistId) {
      if (!currentYear) fetchArtistRecentMonth(currentUser?.artistId)
    }
  }, [currentUser, currentYear, fetchRevenueAristData, fetchArtistRecentMonth])

  useEffect(() => {
    if (
      currentUser
      && currentUser['type'] !== 'company'
      && !currentUser?.artistId
    ) {
      history.push('/artist')
    }
  }, [currentUser, history])

  return (
    <RootTemplate user={user} logout={logout} artist={artistDetail}>
      <RevenueUploadModal
        show={showUpload}
        handleClose={() => setShowUpload(false)}
      />
      {
        artistRecentMonth === 'none' ? (
          <NoRevenue typeToggle={() => setShowUpload(true)} />
        ) : (
          <InsightSettlementTemplate />
        )
      }
    </RootTemplate>
  )
}

export default observer(InsightSettlementPage)
