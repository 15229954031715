import styled from 'styled-components'
import { mobile, smallMobile } from '@styles/media'

export const H1 = styled.h1`
  width: 100%;
  text-align: ${(props) => (props.align ? props.align : 'center')};
  word-break: keep-all;
  line-height: 1.47;
  letter-spacing: normal;
  margin: 0;
  padding: 0;

  font-family: ${(props) =>
    props.type ? 'NotoSansCJKkr-' + props.type : 'NotoSansCJKkr-Bold'};
  color: ${(props) => (props.color ? props.color : '#242424')};

  font-size: 64px;
  @media ${mobile} {
    font-size: 40px;
  }
`

export const H2 = styled.h2`
  width: 100%;
  text-align: ${(props) => (props.align ? props.align : 'center')};
  word-break: keep-all;
  line-height: 1.6;
  letter-spacing: normal;
  margin: 0;
  padding: 0;

  font-family: ${(props) =>
    props.type ? 'NotoSansCJKkr-' + props.type : 'NotoSansCJKkr-Bold'};
  color: ${(props) => (props.color ? props.color : '#242424')};

  font-size: 50px;
  @media ${mobile} {
    font-size: 30px;
  }
`

export const H3 = styled.h3`
  text-align: ${(props) => (props.align ? props.align : 'center')};
  margin: 0;
  padding: 0;
  font-family: ${(props) =>
    props.type ? 'NotoSansCJKkr-' + props.type : 'NotoSansCJKkr-Bold'};
  color: ${(props) => (props.color ? props.color : '#242424')};

  font-size: 38px;
  @media ${mobile} {
    font-size: 26px;
  }

  @media ${smallMobile} {
    font-size: 26px;
    white-space: nowrap;
  }
`

export const H4 = styled.h4`
  text-align: ${(props) => (props.align ? props.align : 'center')};
  margin: 0;
  padding: 0;
  font-family: ${(props) =>
    props.type ? 'NotoSansCJKkr-' + props.type : 'NotoSansCJKkr-Medium'};
  color: ${(props) => (props.color ? props.color : '#242424')};

  font-size: 24px;
  @media ${mobile} {
    font-size: 20px;
  }

  @media ${smallMobile} {
    font-size: 20px;
    white-space: nowrap;
  }
`

export const H5 = styled.h5`
  text-align: ${(props) => (props.align ? props.align : 'center')};
  word-break: keep-all;
  margin: 0;
  padding: 0;

  font-family: ${(props) =>
    props.type ? 'NotoSansCJKkr-' + props.type : 'NotoSansCJKkr-Medium'};
  color: ${(props) => (props.color ? props.color : '#242424')};

  font-size: 20px;
  @media ${mobile} {
    font-size: 16px;
  }
`

export const H6 = styled.h6`
  width: auto;
  text-align: ${(props) => (props.align ? props.align : 'center')};
  word-break: keep-all;
  font-weight: 500;
  margin: 0;
  padding: 0;

  font-family: ${(props) =>
    props.type ? 'NotoSansCJKkr-' + props.type : 'NotoSansCJKkr-Medium'};
  color: ${(props) => (props.color ? props.color : '#242424')};

  font-size: 16px;
  @media ${mobile} {
    font-size: 14px;
  }
`

export const Caption1 = styled.p`
  display: inline-block;
  text-align: ${(props) => (props.align ? props.align : 'center')};
  margin: 0;
  padding: 0;
  font-family: ${(props) =>
    props.type ? 'NotoSansCJKkr-' + props.type : 'NotoSansCJKkr-Medium'};
  color: ${(props) => (props.color ? props.color : '#242424')};
  font-size: 14px;

  @media ${mobile} {
    font-size: 12px;
  }
`

export const Caption2 = styled.p`
  display: inline-block;
  text-align: ${(props) => (props.align ? props.align : 'center')};
  margin: 0;
  padding: 0;
  font-family: ${(props) =>
    props.type ? 'NotoSansCJKkr-' + props.type : 'NotoSansCJKkr-Medium'};
  color: ${(props) => (props.color ? props.color : '#242424')};
  font-size: 12px;

  @media ${mobile} {
    font-size: 10px;
  }
`
