import React from 'react'
import { inject, observer } from 'mobx-react'
import { LoginForm } from '@components/organisms'
import { Redirect } from 'react-router-dom'
import { Div, Image, Text } from '@components/atoms'
import { loginPageImage, loginPageImageMobile } from '@images/'
import {
  loginPageBanner01,
  loginPageBanner02,
  loginPageBanner03,
  loginPageBanner04,
  loginPageBanner05,
  loginPageBanner06,
  loginPageBanner07,
  loginPageBanner08,
  loginPageBanner09,
  loginPageBanner10,
  loginPageBanner11,
  loginPageBanner12,
  loginPageBanner13,
  loginPageBanner14,
  loginPageBanner15,
} from '@images/index'
import { LoginPageBox } from '@components/molecules'
import styled from 'styled-components'
import { useResponsive } from '@utils/hooks'
import { AuthStore } from '../stores'
// import { defaultKakaoConsulting } from '@utils/kakao'

interface LoginPageProps {
  authStore: AuthStore;
  match: Any;
  history: Any;
}

const LoginPage = ({ authStore, match, history }: LoginPageProps) => {
  const { width } = useResponsive()
  const login = authStore.login || (() => {})
  const isLogin = authStore.jsonWebToken

  // defaultKakaoConsulting.useKakaotalkConsulting()

  const isMobile = width < 640
  const isTabletOrMobile = width < 1200

  return (
    <>
      {
        isLogin == null ? (
          <Div
            width="100%"
            height="100vh"
            display="flex"
            flexDirection={isTabletOrMobile ? 'column' : 'row'}
            alignItems="center"
          >
            <Div style={{ width: '100%', maxWidth: 'calc(100vw - 40px)' }}>
              <LoginForm login={login} history={history} />
              {/* <defaultKakaoConsulting.KakaotalkConsulting rightPadding={isTabletOrMobile ? null : width > 1760 ? width - 880 : width / 2} /> */}
            </Div>
            <Div
              position="relative"
              width="100%"
              height="100%"
              backgroundColor="#fafafa"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              {
                isMobile ? (
                  <>
                    <Text
                      size={'20px'}
                      type="Bold"
                      color={'#242424'}
                      style={{ marginTop: 40, lineHeight: '24px' }}
                    >
                  투명하고 건강한
                    </Text>
                    <Text
                      size={'16px'}
                      type="Medium"
                      color={'#242424'}
                      style={{ marginTop: 2, lineHeight: '24px' }}
                    >
                  음악 생태계를 만들어 갑니다.
                    </Text>
                    <Text
                      size={'12px'}
                      type="Regular"
                      color={'#242424'}
                      style={{ marginTop: 10, lineHeight: '24px' }}
                    >
                  뮤직 엔터테인먼트 관리 서비스, 플램 PLAM
                    </Text>
                  </>
                ) : (
                  <>
                    <Text
                      size={'32px'}
                      type="Bold"
                      color={'#242424'}
                      style={{ marginTop: 40, lineHeight: '40px' }}
                    >
                  투명하고 건강한
                    </Text>
                    <Text
                      size={'24px'}
                      type="Medium"
                      color={'#242424'}
                      style={{ marginTop: 0, lineHeight: '40px' }}
                    >
                  음악 생태계를 만들어 갑니다.
                    </Text>
                    <Text
                      size={'16px'}
                      type="Regular"
                      color={'#242424'}
                      style={{ marginTop: 10, lineHeight: '36px' }}
                    >
                  뮤직 엔터테인먼트 관리 서비스, 플램 PLAM
                    </Text>
                  </>
                )
              }

              <Image
                marginTop="40px"
                src={isMobile ? loginPageImageMobile : loginPageImage}
                maxWidth={isMobile ? 'calc(100% - 40px)' : '960px'}
                style={{ aspectRatio: isMobile ? '320 / 180' : '960 / 540' }}
                width="100%"
                alt="logo"
              />

              <Div
                position={isTabletOrMobile ? 'initial' : 'absolute'}
                bottom={isTabletOrMobile ? '0px' : '68px'}
                width="100%"
                height="100px"
                marginTop={isMobile ? '40px' : '0px'}
              >
                <MarqueeDiv>
                  <Div className="track">
                    <Div>
                      {/* <LoginPageBox src={loginPageBanner01} /> */}
                      <LoginPageBox src={loginPageBanner02} />
                      <LoginPageBox src={loginPageBanner03} />
                      {/* <LoginPageBox src={loginPageBanner04} /> */}
                      {/* <LoginPageBox src={loginPageBanner05} /> */}
                      <LoginPageBox src={loginPageBanner06} />
                      <LoginPageBox src={loginPageBanner07} />
                      <LoginPageBox src={loginPageBanner08} type="circle" />
                      <LoginPageBox src={loginPageBanner09} type="circle" />
                      <LoginPageBox src={loginPageBanner10} type="circle" />
                      <LoginPageBox src={loginPageBanner11} type="circle" />
                      <LoginPageBox src={loginPageBanner12} type="circle" />
                      <LoginPageBox src={loginPageBanner13} type="circle" />
                      <LoginPageBox src={loginPageBanner14} type="circle" />
                      <LoginPageBox src={loginPageBanner15} type="circle" />
                      {/* <LoginPageBox src={loginPageBanner01} /> */}
                      <LoginPageBox src={loginPageBanner02} />
                      <LoginPageBox src={loginPageBanner03} />
                      {/* <LoginPageBox src={loginPageBanner04} /> */}
                      {/* <LoginPageBox src={loginPageBanner05} /> */}
                      <LoginPageBox src={loginPageBanner06} />
                      <LoginPageBox src={loginPageBanner07} />
                      <LoginPageBox src={loginPageBanner08} type="circle" />
                      <LoginPageBox src={loginPageBanner09} type="circle" />
                      <LoginPageBox src={loginPageBanner10} type="circle" />
                      <LoginPageBox src={loginPageBanner11} type="circle" />
                      <LoginPageBox src={loginPageBanner12} type="circle" />
                      <LoginPageBox src={loginPageBanner13} type="circle" />
                      <LoginPageBox src={loginPageBanner14} type="circle" />
                      <LoginPageBox src={loginPageBanner15} type="circle" />
                    </Div>
                  </Div>
                </MarqueeDiv>
              </Div>
            </Div>
          </Div>
        ) : (
          <Redirect to="/" />
        )
      }
    </>
  )
}

export default inject('authStore')(observer(LoginPage))

const MarqueeDiv = styled(Div)`
  position: relative;
  width: 100%;
  height: 100px;
  overflow-x: hidden;

  .track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 16s linear infinite;
  }

  .track > div {
    width: 100%;
    height: 100%;
    display: flex;
    padding-left: 16px;
    gap: 16px;
  }

  @keyframes marquee {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }
`
