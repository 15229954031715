import { action, observable } from 'mobx'
import { Store } from '.'
import { Network } from './networks'
import { AlbumModel, TrackModel, CompanyModel } from './models'

export default class AlbumStore {
  @observable releaseAlbumList
  @observable albumDetail

  @observable trackDetail

  @observable autocompleteCompanyList

  @observable releaseCompanyAlbumList

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.releaseAlbumList = []
    this.autocompleteCompanyList = []
    this.albumDetail = null
    this.trackDetail = null

    this.releaseCompanyAlbumList = []
  }

  @action.bound
  async initClient() {}

  @action.bound
  async firstFetchReleaseAlbumList(artistId, params) {
    // if (!this.store.authStore.currentUser.artistId) {
    if (!artistId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const albumList = await this.network.albumNetwork.getAlbums({
        // artistId: this.store.authStore.currentUser.artistId,
        // artistId: this.store.artistStore.artistDetail._id,
        artistId,
        limit: params.limit,
        offset: 0,
        sortBy: params.sortBy,
        // sort: params.sort, // default 역순 기본값
        sort: params.sortBy === 'title' ? 1 : -1, // 이름순 일때 sort 1로
      })

      if (!albumList) return

      albumList.map(album =>
        album.trackList.sort((a, b) =>
          !isNaN(parseInt(a.no)) && !isNaN(parseInt(b.no))
            ? parseInt(a.no) - parseInt(b.no)
            : 0,
        ),
      )

      this.releaseAlbumList = albumList
        .filter(elem => !!elem)
        .map(elem => new AlbumModel(this.store, elem))

      // more 의 유무 판단
      return !!(albumList.length >= params.limit)
    })
  }

  @action.bound
  async moreFetchReleaseAlbumList(params) {
    // if (!this.store.authStore.currentUser.artistId) {
    if (!this.store.artistStore.artistDetail?._id) {
      this.init()
      return
    }

    const albumList = await this.network.albumNetwork.getAlbums({
      // artistId: this.store.authStore.currentUser.artistId,
      artistId: this.store.artistStore.artistDetail._id,
      limit: params.limit,
      offset: params.offset,
      sortBy: params.sortBy,
      // sort: params.sort, // default 역순 기본값
      sort: params.sortBy === 'title' ? 1 : -1, // 이름순 일때 sort 1로
      keyword: params.keyword,
    })

    if (!albumList) return

    albumList.map(album =>
      album.trackList.sort((a, b) =>
        !isNaN(parseInt(a.no)) && !isNaN(parseInt(b.no))
          ? parseInt(a.no) - parseInt(b.no)
          : 0,
      ),
    )

    this.releaseAlbumList = this.releaseAlbumList.concat(
      albumList
        .filter(elem => !!elem)
        .map(elem => new AlbumModel(this.store, elem)),
    )

    return !!(albumList.length !== 0)
  }

  @action.bound
  async searchKeywordReleaseAlbumList(keyword) {
    return this.store.useLoading(async () => {
      // if (!this.store.authStore.currentUser.artistId) {
      if (!this.store.artistStore.artistDetail?._id) {
        this.init()
        return
      }

      const albumList = await this.network.albumNetwork.getAlbums({
        // artistId: this.store.authStore.currentUser.artistId,
        artistId: this.store.artistStore.artistDetail._id,
        keyword,
        limit: 9,
      })

      if (!albumList) return

      if (albumList.length > 0) {
        this.releaseAlbumList = albumList
          .filter(elem => !!elem)
          .map(elem => new AlbumModel(this.store, elem))
      } else {
        this.releaseAlbumList = ['NO_DATA']
      }

      // more 의 유무 판단
      return !!(albumList.length >= 9)
    })
  }

  @action.bound
  async fetchAlbumDetail(albumId) {
    return this.store.useLoading(async () => {
      if (!albumId) return

      const fetchedAlbum = await this.network.albumNetwork.getAlbum(albumId)
      if (!fetchedAlbum) return

      // if (
      //   fetchedAlbum &&
      //   fetchedAlbum.trackList &&
      //   fetchedAlbum.trackList.length > 0
      // ) {
      //   const trackList = await Promise.all(
      //     fetchedAlbum.trackList
      //       .filter((track) => !!track)
      //       .map((track) => this.network.trackNetwork.getTrack(track._id)),
      //   )

      //   // 트랙 넘버 정렬
      //   trackList.sort(function (a, b) {
      //     return Number(a.no) < Number(b.no)
      //       ? -1
      //       : Number(a.no) < Number(b.no)
      //       ? 1
      //       : 0
      //   })

      //   fetchedAlbum.trackList = trackList
      //     .filter((track) => !!track)
      //     .map((track) => new TrackModel(this.store, track))
      // }
      if (
        fetchedAlbum &&
        fetchedAlbum.trackList &&
        fetchedAlbum.trackList.length > 0
      ) {
        fetchedAlbum.trackList = fetchedAlbum.trackList
          .filter(track => !!track)
          .sort((a, b) =>
            !isNaN(parseInt(a.no)) && !isNaN(parseInt(b.no))
              ? parseInt(a.no) - parseInt(b.no)
              : 0,
          )
          .map(track => new TrackModel(this.store, track))
      }

      this.albumDetail = new AlbumModel(this.store, fetchedAlbum)
      return fetchedAlbum
    })
  }

  @action.bound
  async createReleaseAlbum(album) {
    return this.store.useLoading(async () => {
      if (!album) return

      const createdAlbum = await this.network.albumNetwork.postAlbum(album)
      if (!createdAlbum) return

      const baseAlbum = new AlbumModel(this.store, createdAlbum)
      this.releaseAlbumList && this.releaseAlbumList.push(baseAlbum)

      Object.keys(album).forEach(key => {
        baseAlbum[key] = album[key]
      })

      // this.store.artistStore.fetchAutocompleteArtistList()
      // this.store.albumStore.fetchAutocompleteCompanyList()
    })
  }

  @action.bound
  async updateReleaseAlbum(album, baseAlbum) {
    return this.store.useLoading(async () => {
      if (!album || !baseAlbum || !baseAlbum._id) return

      const updatedAlbum = await this.network.albumNetwork.putAlbum({
        ...album,
        _id: baseAlbum._id,
      })
      if (!updatedAlbum) return

      const fetchedAlbum = await this.fetchAlbumDetail(updatedAlbum._id)
      // const fetchedAlbum = await this.network.albumNetwork.getAlbum(
      //   updatedAlbum._id,
      // )
      if (!fetchedAlbum) return
      Object.keys(fetchedAlbum).forEach(key => {
        baseAlbum[key] = fetchedAlbum[key]
      })
      // this.store.artistStore.fetchAutocompleteArtistList()
      // this.store.albumStore.fetchAutocompleteCompanyList()
    })
  }

  @action.bound
  async deleteReleaseAlbum(baseAlbum) {
    return this.store.useLoading(async () => {
      if (!baseAlbum || !baseAlbum._id) return

      const deletedAlbum = await this.network.albumNetwork.deleteAlbum({
        _id: baseAlbum._id,
      })
      if (!deletedAlbum) return

      const idx =
        this.releaseAlbumList &&
        this.releaseAlbumList.findIndex(
          album => album['_id'] === deletedAlbum['_id'],
        )
      if (idx >= 0) {
        this.releaseAlbumList.splice(idx, 1)
        this.releaseAlbumList = [...this.releaseAlbumList]
      }
      // this.store.artistStore.fetchAutocompleteArtistList()
      // this.store.albumStore.fetchAutocompleteCompanyList()
    })
  }

  @action.bound
  async fetchTrackDetail(track) {
    return this.store.useLoading(async () => {
      if (!track || !track._id) {
        this.trackDetail = null
        return
      }
      if (track instanceof TrackModel) {
        this.trackDetail = track
      }

      const fetchedTrack = await this.network.trackNetwork.getTrack(track._id)
      if (!fetchedTrack) return

      if (
        fetchedTrack &&
        fetchedTrack.trackList &&
        fetchedTrack.trackList.length > 0
      ) {
        const trackList = await Promise.all(
          fetchedTrack.trackList
            .filter(elem => !!elem)
            .map(elem => this.network.trackNetwork.getTrack(elem._id)),
        )
        fetchedTrack.trackList = trackList
          .filter(elem => !!elem)
          .map(elem => new TrackModel(this.store, elem))
      }

      const trackModel = new TrackModel(this.store, fetchedTrack)
      if (this.trackDetail) {
        Object.keys(trackModel).map(
          key => (this.trackDetail[key] = trackModel[key]),
        )
      } else {
        this.trackDetail = trackModel
      }
    })
  }

  @action.bound
  async createTrack(track, baseAlbum) {
    return this.store.useLoading(async () => {
      if (!track || !baseAlbum) return

      const createdTrack = await this.network.trackNetwork.postTrack(track)
      if (!createdTrack) return

      // const baseTrack = new TrackModel(this.store, createdTrack)
      // baseAlbum &&
      //   baseAlbum.trackList &&
      //   baseAlbum.trackList.push(new TrackModel(this.store, baseTrack))

      // Object.keys(track).forEach((key) => {
      //   baseTrack[key] = track[key]
      // })

      const fetchedAlbum = await this.network.albumNetwork.getAlbum(
        baseAlbum._id,
      )

      if (
        fetchedAlbum &&
        fetchedAlbum.trackList &&
        fetchedAlbum.trackList.length > 0
      ) {
        const trackList = await Promise.all(
          fetchedAlbum.trackList
            .filter(track => !!track)
            .map(track => this.network.trackNetwork.getTrack(track._id)),
        )

        // 트랙 넘버 정렬
        trackList.sort(function(a, b) {
          return Number(a.no) < Number(b.no)
            ? -1
            : Number(a.no) < Number(b.no)
            ? 1
            : 0
        })

        fetchedAlbum.trackList = trackList
          .filter(track => !!track)
          .map(track => new TrackModel(this.store, track))
      }

      Object.keys(fetchedAlbum).forEach(key => {
        baseAlbum[key] = fetchedAlbum[key]
      })
      // this.store.artistStore.fetchAutocompleteArtistList()
    })
  }

  @action.bound
  async updateTrack(track, baseTrack, baseAlbum) {
    return this.store.useLoading(async () => {
      if (!track || !baseTrack || !baseTrack._id || !baseAlbum) return

      const updatedTrack = await this.network.trackNetwork.putTrack({
        ...track,
        _id: baseTrack._id,
      })
      if (!updatedTrack) return

      // const fetchedTrack = await this.network.trackNetwork.getTrack(
      //   baseTrack._id,
      // )

      // Object.keys(fetchedTrack).forEach((key) => {
      //   baseTrack[key] = fetchedTrack[key]
      // })
      const fetchedAlbum = await this.network.albumNetwork.getAlbum(
        baseAlbum._id,
      )

      if (
        fetchedAlbum &&
        fetchedAlbum.trackList &&
        fetchedAlbum.trackList.length > 0
      ) {
        const trackList = await Promise.all(
          fetchedAlbum.trackList
            .filter(track => !!track)
            .map(track => this.network.trackNetwork.getTrack(track._id)),
        )

        // 트랙 넘버 정렬
        trackList.sort(function(a, b) {
          return Number(a.no) < Number(b.no)
            ? -1
            : Number(a.no) < Number(b.no)
            ? 1
            : 0
        })

        fetchedAlbum.trackList = trackList
          .filter(track => !!track)
          .map(track => new TrackModel(this.store, track))
      }

      Object.keys(fetchedAlbum).forEach(key => {
        baseAlbum[key] = fetchedAlbum[key]
      })
      // this.store.artistStore.fetchAutocompleteArtistList()
    })
  }

  @action.bound
  async deleteTrack(baseTrack, baseAlbum, reload) {
    return this.store.useLoading(async () => {
      if (!baseTrack || !baseTrack._id) return

      const deletedTrack = await this.network.trackNetwork.deleteTrack({
        _id: baseTrack._id,
      })
      if (!deletedTrack) return
      const idx =
        baseAlbum &&
        baseAlbum.trackList &&
        baseAlbum.trackList.findIndex(
          track => track['_id'] === deletedTrack['_id'],
        )

      if (idx >= 0) {
        const albumIdx =
          this.releaseAlbumList &&
          this.releaseAlbumList.findIndex(
            album => album['_id'] === baseAlbum['_id'],
          )
        if (!reload) {
          this.releaseAlbumList[albumIdx].trackList.splice(idx, 1)
          this.releaseAlbumList = [...this.releaseAlbumList]
        } else {
          const fetchedAlbum = await this.network.albumNetwork.getAlbum(
            baseAlbum._id,
          )
          if (!fetchedAlbum) return
          this.albumDetail = new AlbumModel(this.store, fetchedAlbum)
        }
      }
      // this.store.artistStore.fetchAutocompleteArtistList()
    })
  }

  @action.bound
  async crawlAlbum(melonUrl) {
    return this.store.useLoading(async () => {
      if (!melonUrl) return

      const res = await this.network.crawlNetwork.postCrawlAlbum({
        userId: this.store.authStore.currentUser._id,
        // artistId: this.store.authStore.currentUser.artistId,
        artistId: this.store.artistStore.artistDetail._id,
        uniqueName: this.store.authStore.currentUser.account,
        melonUrl,
      })

      return res.data
    })
  }

  // 유통사, 권리사 리스트 조회
  @action.bound
  async fetchAutocompleteCompanyList(name = '주식회사') {
    // return this.store.useLoading(async () => {
    const fetchedCompanyList = await this.network.albumNetwork.getCompanyList({
      name,
    })
    if (fetchedCompanyList) {
      // 등록된 아이디 정렬
      // fetchedCompanyList.sort(function (a, b) {
      //   return a.uniqueName !== null && b.uniqueName === null
      //     ? -1
      //     : a.uniqueName === null && b.uniqueName !== null
      //     ? 1
      //     : 0
      // })

      this.autocompleteCompanyList =
        fetchedCompanyList
          .filter(elem => !!elem)
          .map(elem => new CompanyModel(this.store, elem)) || []
    }
    // })
  }

  @action.bound
  async firstFetchCompanyReleaseAlbumList(companyId, params) {
    // if (!this.store.authStore.currentUser.companyId) {
    if (!companyId) {
      this.init()
      return
    }

    return this.store.useLoading(async () => {
      const albumList = await this.network.albumNetwork.getAlbums({
        companyId,
        limit: params.limit,
        offset: 0,
        sortBy: params.sortBy,
        // sort: params.sort, // default 역순 기본값
        sort: params.sortBy === 'title' ? 1 : -1, // 이름순 일때 sort 1로
      })

      if (!albumList) return

      albumList.map(album =>
        album.trackList.sort((a, b) =>
          !isNaN(parseInt(a.no)) && !isNaN(parseInt(b.no))
            ? parseInt(a.no) - parseInt(b.no)
            : 0,
        ),
      )

      this.releaseCompanyAlbumList = albumList
        .filter(elem => !!elem)
        .map(elem => new AlbumModel(this.store, elem))

      // more 의 유무 판단
      return !!(albumList.length >= params.limit)
    })
  }

  @action.bound
  async moreFetchCompanyReleaseAlbumList(params) {
    // if (!this.store.authStore.currentUser.artistId) {
    if (!this.store.authStore.currentUser?.companyId) {
      this.init()
      return
    }

    const albumList = await this.network.albumNetwork.getAlbums({
      companyId: this.store.authStore.currentUser?.companyId,
      limit: params.limit,
      offset: params.offset,
      sortBy: params.sortBy,
      // sort: params.sort, // default 역순 기본값
      sort: params.sortBy === 'title' ? 1 : -1, // 이름순 일때 sort 1로
      keyword: params.keyword,
    })

    if (!albumList) return

    albumList.map(album =>
      album.trackList.sort((a, b) =>
        !isNaN(parseInt(a.no)) && !isNaN(parseInt(b.no))
          ? parseInt(a.no) - parseInt(b.no)
          : 0,
      ),
    )

    this.releaseCompanyAlbumList = this.releaseCompanyAlbumList.concat(
      albumList
        .filter(elem => !!elem)
        .map(elem => new AlbumModel(this.store, elem)),
    )

    return !!(albumList.length !== 0)
  }

  @action.bound
  async searchKeywordCompanyReleaseAlbumList(keyword) {
    return this.store.useLoading(async () => {
      // if (!this.store.authStore.currentUser.artistId) {
      if (!this.store.authStore.currentUser?.companyId) {
        this.init()
        return
      }

      const albumList = await this.network.albumNetwork.getAlbums({
        companyId: this.store.authStore.currentUser?.companyId,
        keyword,
        limit: 9,
      })

      if (!albumList) return

      if (albumList.length > 0) {
        this.releaseCompanyAlbumList = albumList
          .filter(elem => !!elem)
          .map(elem => new AlbumModel(this.store, elem))
      } else {
        this.releaseCompanyAlbumList = ['NO_DATA']
      }

      // more 의 유무 판단
      return !!(albumList.length >= 9)
    })
  }
}
