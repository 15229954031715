import { Button } from 'antd'
import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { Flex, Text, Image, Container } from '@components/atoms'

const HeaderBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  height: 44px;
  margin: 10px;
  margin-left: 0px;
  border-radius: 25px;
  border: solid 1px #eaeaea;
  background-color: #ffffff;
`
const UserImage = styled(Image)`
  width: 30px;
  height: 30px;
  border-radius: 24px;
`

export const ArtistHeader = ({
  artistList = [],
  type = 'artist',
  currentArtistId = null,
  imageUri = null,
  name = '',
  onClick = () => {},
}) => {
  return (
    <Flex>
      {
        artistList.length > 0
        && type === 'artist'
        && artistList.map((item, index) => {
          return (
            <HeaderBtn
              focusable={false}
              style={
                {
                  backgroundColor:
                  currentArtistId === item._id ? 'black' : 'white',
                  borderColor: '#d9d9d9',
                  boxShadow: 'none',
                  outline: 'none',
                }
              }
              key={`${index}-${item._id}`}
              onClick={
                () => {
                  onClick(item._id)
                }
              }
            >
              <UserImage src={item.imageUri || null} />
              <Text
                style={
                  {
                    marginLeft: '10px',
                    color: currentArtistId === item._id ? 'white' : 'black',
                  }
                }
              >
                {`${item.name}`}
              </Text>
            </HeaderBtn>
          )
        })
      }
      {
        type === 'company' && (
          <HeaderBtn
            style={
              {
                backgroundColor: 'black',
                boxShadow: 'none',
                outline: 'none',
                borderColor: '#d9d9d9',
              }
            }
          >
            <UserImage src={imageUri} />
            <Text
              style={
                {
                  marginLeft: '10px',
                  color: 'white',
                }
              }
            >
              {`${name}`}
            </Text>
          </HeaderBtn>
        )
      }
    </Flex>
  )
}
