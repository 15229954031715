import { Network } from '.'

export default class StatsNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getRecord(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/record`,
        'get',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('StatsNetwork getRecord error', error)
    }
  }

  async getRecordSmartLink(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/record/smart-link`,
        'get',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('StatsNetwork getRecordSmartLink error', error)
    }
  }

  async getArtistLike(artistId, periodType) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/like/insight/artist?artistId=${artistId}&periodType=${periodType}`,
        'get',
      )
      return data || null
    } catch (error) {
      console.warn('StatsNetwork getArtistLike error', error)
    }
  }

  async getTracksLike(artistId, periodType) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/like/insight/tracks?artistId=${artistId}&periodType=${periodType}`,
        'get',
      )
      return data || null
    } catch (error) {
      console.warn('StatsNetwork getTracksLike error', error)
    }
  }

  async getTrackLike(trackId, periodType) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/like/insight/track?trackId=${trackId}&periodType=${periodType}`,
        'get',
      )
      return data || null
    } catch (error) {
      console.warn('StatNetwork getTrackLike error', error)
    }
  }

  // async getSnsListLike(artistId, periodType) {
  //   try {
  //     const { data } = await this.network._axiosApiAuth(
  //       `/sns/insight/artist?artistId=${artistId}&periodType=${periodType}`,
  //       'get',
  //     )
  //     return data || null
  //   } catch (error) {
  //     console.warn('StatNetwork getSnsListLike error', error)
  //   }
  // }

  // async getSnsLike(snsType, artistId, periodType) {
  //   try {
  //     const { data } = await this.network._axiosApiAuth(
  //       `/sns/insight/${snsType}?artistId=${artistId}&periodType=${periodType}`,
  //       'get',
  //     )
  //     return data || null
  //   } catch (error) {
  //     console.warn('StatNetwork getSnsLike error', error)
  //   }
  // }

  async getSubdomain(subdomain) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/shorten-url/subdomain/${subdomain}`,
        'get',
      )
      return data || null
    } catch (error) {
      console.warn('StatsNetwork getCheckSubdomain error', error)
    }
  }

  async postSubdomain(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/shorten-url/subdomain',
        'post',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('StatsNetwork postSubdomain error', error)
    }
  }

  async putSubdomain(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/shorten-url/subdomain',
        'put',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('StatsNetwork putSubdomain error', error)
    }
  }

  async getCustomUrl(customUrl, artistId) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/shorten-url/custom/${customUrl}?artistId=${artistId}`,
        'get',
      )
      return data || null
    } catch (error) {
      console.warn('StatsNetwork getCustomUrl', error)
    }
  }

  async putCustomUrl(params) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/shorten-url/custom',
        'put',
        params,
      )
      return data || null
    } catch (error) {
      console.warn('StatsNetwork putCustomUrl error', error)
    }
  }
}
