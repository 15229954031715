import { action, observable } from 'mobx'
import axios from 'axios'
import { Store } from '.'
import { Network } from './networks'
import {
  ArtistTrendModel,
  ArtistTrendTrackModel,
  ArtistTrackTrendModel,
} from './models'

export default class TrendStore {
  @observable artistTrend
  @observable artistTrendTrackList
  @observable artistTrackTrend
  @observable companyTrackTrend

  @observable companyTrend
  @observable companyTrendTrackList

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.artistTrend = null
    this.artistTrendTrackList = []
    this.artistTrackTrend = null
    this.companyTrackTrend = null

    this.companyTrend = null
    this.companyTrendTrackList = []
  }

  @action.bound
  async fetchArtistTrend(aritstObjectId, params) {
    return this.store.useLoading(async () => {
      if (!aritstObjectId || !params) {
        this.init()
        return
      }

      const { data } = await this.network.trendNetwork.getTrendArtistAPI(
        aritstObjectId,
        params,
      )

      if (data?.artist) {
        /** 인스타 데이터 추가 */
        data.artist = await this._addInstagramDataToArtist(data.artist)
      }
      this.artistTrend = new ArtistTrendModel(this.store, data.artist)
    })
  }

  @action.bound
  async fetchArtistTrendTrack(aritstObjectId, params) {
    return this.store.useLoading(async () => {
      if (!aritstObjectId || !params) {
        this.init()
        return
      }

      const { data } = await this.network.trendNetwork.getTrendArtistTrackAPI(
        aritstObjectId,
        params,
      )

      if (data?.trackList) {
        this.artistTrendTrackList = data?.trackList?.map(
          track => new ArtistTrendTrackModel(this.store, track),
        )
      }
    })
  }

  @action.bound
  async fetchArtistTrackTrend(aritstObjectId, trackObjectId, params) {
    return this.store.useLoading(async () => {
      if (!aritstObjectId || !trackObjectId || !params) {
        this.init()
        return
      }

      const {
        data,
      } = await this.network.trendNetwork.getTrendArtistTrackDetailAPI(
        aritstObjectId,
        trackObjectId,
        params,
      )

      if (data?.track)
        this.artistTrackTrend = new ArtistTrackTrendModel(
          this.store,
          data.track,
        )
    })
  }

  @action.bound
  async fetchCompanyTrackTrend(companyObjectId, trackObjectId, params) {
    return this.store.useLoading(async () => {
      if (!companyObjectId || !trackObjectId || !params) {
        this.init()
        return
      }

      const {
        data,
      } = await this.network.trendNetwork.getTrendCompanyTrackDetailAPI(
        companyObjectId,
        trackObjectId,
        params,
      )

      if (data?.track)
        this.companyTrackTrend = new ArtistTrackTrendModel(
          this.store,
          data.track,
        )
    })
  }

  async fetchArtistInstagramFollowersCounts(artistId) {
    try {
      const _url = `https://asia-northeast3-spry-bricolage-390001.cloudfunctions.net/get-mongo-instagram-followers-count?plamArtistId=${artistId}`
      const _params = { plamArtistId: artistId }
      const _headers = {
        'Content-Type': 'application/json',
      }
      const res = await axios.post(_url, JSON.stringify(_params), {
        headers: _headers,
      })
      if (res?.data?.documents?.[0]?.instagramFollowersCount) {
        return res.data.documents[0].instagramFollowersCount
      } else {
        return {}
      }
    } catch (error) {
      console.error('[fetchArtistInstagramFollowersCounts] error', error)
    }
  }

  /** 플램 팔로워 데이터 구조에 맞게 데이터 추가 */
  async _addInstagramDataToArtist(artist) {
    const { _id } = artist

    /** 인스타 팔로워 저장 디비에서 해당 아티스트 데이터 패치 */
    const { total, daily } = await this.fetchArtistInstagramFollowersCounts(_id)

    if (!daily) return artist

    if (daily.length === 0) {
      return
    }

    const _dailyDesc = daily.reverse()
    const _dailyCountFilter = artist.followersInfo.dailyList.length // 1, 7, 28 필터

    const _latestDate = artist.followersInfo.dailyList[0].date
    const _index = _dailyDesc.findIndex((_elem, _index) => {
      return parseInt(_elem.date.replaceAll('-', '')) === _latestDate
    })
    const _latestDateIndex = _index !== -1 ? _index : 0
    /** 1, 7, 28 날짜수 필터 및 날짜 데이터 형식 플램이랑 맞춤 */
    const filteredInstagramDaily = _dailyDesc
      .slice(_latestDateIndex, _latestDateIndex + _dailyCountFilter)
      .map(_daily => {
        const _date = _daily.date.replaceAll('-', '')
        _daily.date = parseInt(_date)
        return _daily
      })

    /** followersInfo.dailyList에 인스타 데일리 추가 */
    filteredInstagramDaily.forEach((_instagramDaily, _index) => {
      artist.followersInfo.dailyList.find(_elem => {
        if (_elem.date === _instagramDaily.date) {
          _elem.instagram = _instagramDaily.count
          /** 데일리 증감 추가 */
          if (filteredInstagramDaily[_index + 1]) {
            _elem.increaseValue.instagram =
              filteredInstagramDaily[_index].count -
              filteredInstagramDaily[_index + 1].count
          } else if (_dailyDesc[_index + 1]) {
            _elem.increaseValue.instagram =
              filteredInstagramDaily[_index].count -
              _dailyDesc[_index + 1].count
          }
        }
      })
    })

    /** total data 추가 */
    const instagramIncreaseValue = _dailyDesc[1]
      ? _dailyDesc[0].count - _dailyDesc[1].count
      : 0
    artist.followersInfo.today.instagram = total
    artist.followersInfo.today.increaseValue.instagram = instagramIncreaseValue

    /** totalDailyList에 데이터 추가 */
    filteredInstagramDaily.forEach((_instagramDaily, _index) => {
      artist.totalDailyList.find(_elem => {
        if (_elem.date === _instagramDaily.date) {
          _elem.followerInfo.instagram = _instagramDaily.count
          if (filteredInstagramDaily[_index + 1]) {
            _elem.followerInfo.increaseValue.instagram =
              filteredInstagramDaily[_index].count -
              filteredInstagramDaily[_index + 1].count
          } else if (_dailyDesc[_index + 1]) {
            _elem.followerInfo.increaseValue.instagram =
              filteredInstagramDaily[_index].count -
              _dailyDesc[_index + 1].count
          }
        }
      })
    })
    return artist
  }

  @action.bound
  async fetchCompanyTrend(companyObjectId, params) {
    return this.store.useLoading(async () => {
      if (!companyObjectId || !params) {
        this.init()
        return
      }

      const { data } = await this.network.trendNetwork.getTrendCompanyAPI(
        companyObjectId,
        params,
      )

      this.companyTrend = new ArtistTrendModel(this.store, data.company)
    })
  }

  @action.bound
  async fetchCompanyTrendTrack(companyObjectId, params) {
    return this.store.useLoading(async () => {
      if (!companyObjectId || !params) {
        this.init()
        return
      }

      const { data } = await this.network.trendNetwork.getTrendCompanyTrackAPI(
        companyObjectId,
        params,
      )

      if (data?.trackList) {
        this.companyTrendTrackList = data?.trackList?.map(
          track => new ArtistTrendTrackModel(this.store, track),
        )
      }
    })
  }
}
