import React from 'react'
import { ChangePasswordTemplate } from '@components/templates'
import { inject, observer } from 'mobx-react'
import { AuthStore } from '../stores'

interface Props {
  authStore: AuthStore;
}

const ChangePasswordPage = ({ authStore }: Props) => {
  const { currentUser } = authStore
  const changePassword = authStore.changePassword || (() => {})
  return (
    <ChangePasswordTemplate
      currentUser={currentUser}
      changePassword={changePassword}
    />
  )
}

export default inject('authStore')(observer(ChangePasswordPage))
