import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Route, useHistory, useLocation } from 'react-router-dom'

import LoginPage from './LoginPage'

const ProtectedRoute = ({ exact = false, path, component }) => {
  const Component = component
  const { authStore } = useStore()
  const location = useLocation()
  const history = useHistory()

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    authStore.login().then(() => {
      if (authStore.jsonWebToken) {
        setIsAuthenticated(true)
        setIsLoading(false)
      }
      else {
        setIsAuthenticated(false)
        setIsLoading(false)
      }
    })
    /* 로그인 후 jwt 최신화 되는 과정에서 login 두번 호출 방지 */
  }, [authStore, authStore.jsonWebToken])

  if (!isLoading) {
    if (isAuthenticated) {
      return (
        <Route
          exact={exact}
          path={path}
          component={
            ({ match }) => (
              <Component
                user={authStore.currentUser}
                logout={authStore.logout}
                location={location}
                match={match}
                history={history}
              />
            )
          }
        />
      )
    }
    return <Route exact={exact} path={path} component={LoginPage} />
  }
}
export default observer(ProtectedRoute)
