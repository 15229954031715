import React, { useMemo, useState } from 'react'
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts'
import { CheckTextBox } from '@components/molecules'
import { Caption2, Flex } from '@components/atoms'

import styled from 'styled-components'
import { convertToPricingComma } from '@utils/format'
import moment from 'moment'

const NoData = styled(Flex)`
  width: 100%;
  height: 500px;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  border: 1px solid #dddddd;
  border-radius: 6px;
  margin: 20px 0;
`

const ArtistRevenueAdjacencyStatsChart = ({
  currentYear,
  data,
  expectedData,
}) => {
  const [filterMedia, setFilterMedia] = useState('')

  const convertData = useMemo(() => {
    const initData = []
    const year = moment(currentYear).year()
    for (let i = 0; i < 12; i++) {
      const month = i < 9 ? `0${i + 1}` : i + 1
      const salesMonth = Number(`${year % 100}${month}`)
      const serverMonths = data.filter(item => item.salesMonth === salesMonth)

      if (serverMonths?.length > 0) {
        const totalMonthlyAdjacencyFee = serverMonths?.reduce(
          (prev, curr) => prev + Number(curr.monthlyAdjacencyFee),
          0,
        )

        initData[i] = {
          salesMonth,
          monthlyAdjacencyFee: totalMonthlyAdjacencyFee,
        }
      }
      else {
        initData[i] = { salesMonth }
      }
    }
    return initData
  }, [data, currentYear])

  const parseExpectedData = useMemo(() => {
    let parseData = []

    parseData = expectedData?.map(item => ({
      ...item,
      monthlyAdjacencyFee: item.adjacencyFee,
      monthlyCopyRightFee: item.copyRightFee,
      monthlyPerformanceRightFee: item.performanceRightFee,
      salesMonth: item.month,
    }))

    return parseData
  }, [expectedData])

  const dataByService = useMemo(() => {
    const serviceList = { STREAMING: [], DOWNLOAD: [], YOUTUBE: [], SOCIAL: [] }

    for (const item of data) {
      switch (item.hitType) {
        case 'STREAMING':
          serviceList['STREAMING'].push(item)
          break
        case 'DOWNLOAD':
          serviceList['DOWNLOAD'].push(item)
          break
        case 'YOUTUBE':
          serviceList['YOUTUBE'].push(item)
          break
        case 'SOCIAL':
          serviceList['SOCIAL'].push(item)
          break
        default:
          break
      }
    }
    return serviceList
  }, [data])

  const isThisYearExpect = useMemo(() => {
    const currentYearStr = currentYear?.format?.('YY')
    const expectYearStr = `${parseExpectedData[0]?.month}`.slice(0, 2)
    return currentYearStr === expectYearStr
  }, [currentYear, expectedData])

  return (
    <Flex>
      {
        data.length === 0 ? (
          <NoData>데이터가 없습니다.</NoData>
        ) : (
          <ResponsiveContainer width="80%" height={500}>
            <ComposedChart
            // width={1054}
            // height={500}
              margin={
                {
                  top: 50,
                  right: 0,
                  bottom: 50,
                  left: 50,
                }
              }
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="salesMonth"
                tickFormatter={
                  tick => {
                    const strTick = `${tick}`
                    return `${strTick[2]}${strTick[3]}월`
                  }
                }
                allowDuplicatedCategory={false}
              />
              <YAxis
                domain={['auto', 'auto']}
                unit="원"
                tickFormatter={e => convertToPricingComma(e)}
              />
              <Tooltip content={CustomTooltip} />
              {/* <Tooltip /> */}
              <Legend verticalAlign="top" layout="horizontal" align="center" />
              <Line
                stroke="black"
                name="전체 인접권료"
                data={convertData}
                dataKey="monthlyAdjacencyFee"
                legendType="none"
                hide={filterMedia !== ''}
              />
              {
                isThisYearExpect && (
                  <Line
                    stroke="black"
                    name="전체 인접권료"
                    data={parseExpectedData}
                    dataKey="monthlyAdjacencyFee"
                    legendType="none"
                    hide={filterMedia !== ''}
                    strokeDasharray="5 5"
                  />
                )
              }
              <Line
                stroke="red"
                name="스트리밍"
                data={dataByService.STREAMING}
                dataKey="monthlyAdjacencyFee"
                hide={!(filterMedia === '' || filterMedia === 'STREAMING')}
              />
              <Line
                stroke="blue"
                name="다운로드"
                data={dataByService.DOWNLOAD}
                dataKey="monthlyAdjacencyFee"
                hide={!(filterMedia === '' || filterMedia === 'DOWNLOAD')}
              />
              <Line
                stroke="green"
                name="Youtube"
                data={dataByService.YOUTUBE}
                dataKey="monthlyAdjacencyFee"
                hide={!(filterMedia === '' || filterMedia === 'YOUTUBE')}
              />
              <Line
                stroke="black"
                name="Social"
                data={dataByService.SOCIAL}
                dataKey="monthlyAdjacencyFee"
                hide={!(filterMedia === '' || filterMedia === 'SOCIAL')}
              />
            </ComposedChart>
          </ResponsiveContainer>
        )
      }
      {
data?.length > 0 && (
          <Flex
            type="column"
            style={
              {
                margin: '0 0 50px 120px',
                width: 'auto',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }
            }
          >
            {/* <H6>유입 매체</H6> */}
            <CheckTextBox
              checked={filterMedia === '' ? true : false}
              onClick={() => setFilterMedia('')}
              text="전체"
            />
            <CheckTextBox
              checked={filterMedia === 'STREAMING' ? true : false}
              onClick={() => setFilterMedia('STREAMING')}
              text="스트리밍"
            />
            <CheckTextBox
              checked={filterMedia === 'DOWNLOAD' ? true : false}
              onClick={() => setFilterMedia('DOWNLOAD')}
              text="다운로드"
            />
            <CheckTextBox
              checked={filterMedia === 'YOUTUBE' ? true : false}
              onClick={() => setFilterMedia('YOUTUBE')}
              text="Youtube"
            />
            <CheckTextBox
              checked={filterMedia === 'SOCIAL' ? true : false}
              onClick={() => setFilterMedia('SOCIAL')}
              text="Social"
            />
          </Flex>
        )
      }
    </Flex>
  )
}

export default ArtistRevenueAdjacencyStatsChart

const ToolTipBox = styled(Flex)`
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;

  p {margin: 5px 0;}
  p:first-child {margin 0;}
`

const CustomTooltip = ({ active, payload, label }) => {
  const item = payload && payload[0] && payload[0].payload && payload[0].payload

  const strTick = `${label}`
  const customLabel = `${strTick[0]}${strTick[1]}년 ${strTick[2]}${strTick[3]}월`

  if (active) {
    if (item?.month) {
      const _uniquedData = {}
      return (
        <ToolTipBox type="column">
          <Caption2 type="Bold" align="left">
            {`${customLabel} 예상 수익`}
          </Caption2>
          {
payload?.map(data => {
  if (_uniquedData[data.name]) {
    return ''
  }
  _uniquedData[data.name] = true
  return (
    <Caption2 align="left" color={data.stroke} key={data.name}>
      {
        `${data.name} : ${data
                  && convertToPricingComma(
                    Math.floor(data?.payload?.monthlyAdjacencyFee),
                  )}원`
      }
    </Caption2>
  )
})
          }
          <Caption2 align="left" color="#949494">
            *과거 정산 자료를 등록하시면
            <br />
더 정확한 예상 수익을 측정할 수 있습니다.
          </Caption2>
        </ToolTipBox>
      )
    }
    else {
      const _uniquedData = {}
      return (
        <ToolTipBox type="column">
          <Caption2 type="Bold" align="left">{`${customLabel}`}</Caption2>
          {
payload?.map(data => {
  if (_uniquedData[data.name]) {
    return ''
  }
  _uniquedData[data.name] = true
  return (
    <Caption2 align="left" color={data.stroke} key={data.name}>
      {
        `${data.name} : ${data
                  && convertToPricingComma(
                    Math.floor(data?.payload?.monthlyAdjacencyFee),
                  )}원`
      }
    </Caption2>
  )
})
          }
        </ToolTipBox>
      )
    }
  }
  return null
}
