import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { H4, Caption1, Flex, Button, Input } from '@components/atoms'
import { CustomModal } from '@components/molecules'
import { colors } from '@colors/'
import { select_empty_img, select_img } from '@images/'
import AutocompleteArtist from './AutocompleteArtist'

const ModalBody = styled(Flex)`
  padding: 30px 45px;
  width: 490px;
  height: 406px;
  flex-direction: column;
  align-items: center;
`

const Btn = styled(Button)`
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  width: 230px;
  height: 48px;
  border-radius: 25px;
  background-color: ${props => (props.disabled ? '#f4f4f4' : colors.main)};
  color: ${props => (props.disabled ? '#949494' : '#f4f4f4')};
  border: ${props => (props.disabled ? 'none' : colors.main)};
`

const View = ({ isShowing, toggle, userId, createConnectionsCompanies }) => {
  const [artist, setArtist] = useState()
  const [companyType, setCompanyType] = useState([])
  const [directValue, setDirectValue] = useState('')

  const handleUpdateCompanyType = value => {
    if (companyType.findIndex(e => e === value) > -1) {
      setCompanyType(companyType.filter(e => e !== value))
    } else {
      setCompanyType(companyType.concat(value))
    }
  }

  const handleConfirm = async () => {
    /**
     * 유통사, 기타 없애고 소속사로 고정
     */
    // if (companyType === '기타' && directValue === '') return

    // const realCompanyType = companyType
    // if (companyType.findIndex(e => e === '기타') > -1) {
    //   realCompanyType[
    //     realCompanyType.findIndex(e => e === '기타')
    //   ] = directValue
    // }
    const realCompanyType = '소속사'
    const res = await createConnectionsCompanies(
      realCompanyType,
      artist.artistId,
      userId,
    )
    if (!res) return
    toggle()
  }
  const onClickRemove = () => {
    setArtist({
      name: '',
      uniqueName: '',
      _id: '',
    })
  }

  useEffect(() => {
    if (isShowing) {
      onClickRemove()
    }
  }, [isShowing])

  return (
    <>
      <CustomModal.View
        isShowing={isShowing}
        toggle={toggle}
      >
        <ModalBody type="column">
          <H4 type="Bold" style={{ marginTop: 30 }}>
            아티스트 추가
          </H4>
          <div style={{ width: 400, marginTop: 40, position: 'relative' }}>
            <AutocompleteArtist
              placeholder="아티스트명 또는 아이디 입력"
              inputWidth={400}
              value={artist}
              setArtist={setArtist}
              index={1}
              isDisableRemove
              keyword={'artist'}
              onClickRemove={onClickRemove}
            />
          </div>
          {/* <ReportSelectBox>
            <ReportOptionBox onClick={() => handleUpdateCompanyType('소속사')}>
              <img
                src={
                  companyType.findIndex(e => e === '소속사') > -1
                    ? select_img
                    : select_empty_img
                }
                alt="option_box"
              />
              <Caption1
                type={
                  companyType.findIndex(e => e === '소속사') > -1
                    ? 'Medium'
                    : 'Regular'
                }
              >
                소속사
              </Caption1>
            </ReportOptionBox>
            <ReportOptionBox onClick={() => handleUpdateCompanyType('유통사')}>
              <img
                src={
                  companyType.findIndex(e => e === '유통사') > -1
                    ? select_img
                    : select_empty_img
                }
                alt="option_box"
              />
              <Caption1
                type={
                  companyType.findIndex(e => e === '유통사') > -1
                    ? 'Medium'
                    : 'Regular'
                }
              >
                유통사
              </Caption1>
            </ReportOptionBox>
            <ReportOptionBox>
              <img
                onClick={() => handleUpdateCompanyType('기타')}
                src={
                  companyType.findIndex(e => e === '기타') > -1
                    ? select_img
                    : select_empty_img
                }
                alt="option_box"
              />
              <Caption1
                onClick={() => handleUpdateCompanyType('기타')}
                type={
                  companyType.findIndex(e => e === '기타') > -1
                    ? 'Medium'
                    : 'Regular'
                }
              >
                기타
              </Caption1>
              <Input
                style={
                  {
                    marginLeft: 8,
                    fontSize: 12,
                    width: 200,
                    height: 24,
                  }
                }
                placeholder="관계 입력"
                value={directValue}
                onChange={e => setDirectValue(e.target.value)}
              />
            </ReportOptionBox>
          </ReportSelectBox> */}
          <Btn
            disabled={!(artist && artist.name && companyType)}
            onClick={handleConfirm}
          >
            승인 요청
          </Btn>
        </ModalBody>
      </CustomModal.View>
    </>
  )
}

export default {
  View,
  useModal: CustomModal.useModal,
}

const ReportSelectBox = styled(Flex)`
  width: 100%;
  align-items: flex-start;

  flex-direction: column;
  margin: 20px 0px 32px 0px;

  > div {
    margin: 9px 0px;
  }

  > div:first-child {
    margin-top: 0px;
  }

  > div:last-child {
    margin-bottom: 0px;
  }
`

const ReportOptionBox = styled(Flex)`
  cursor: pointer;
  align-items: center;

  img {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
`
