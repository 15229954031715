import React from 'react'
import styled from 'styled-components'
import { H6 } from '@components/atoms'
import { Link } from 'react-router-dom'

const Img = styled.img`
  width: ${(props) => (props.size ? props.size : '16px')};
  height: ${(props) => (props.size ? props.size : '16px')};
`

const BtnBox = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const move_btn_plam = require('@images/move_btn_plam.png')

export const MoveBtn = (props) => {
  const handleClick = (e) => {
    if (props.onClick) {
      e.preventDefault()
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()

      props.onClick(e)
    }
  }
  return (
    <BtnBox to={props.href || '#'} style={props.style && props.style}>
      <H6 onClick={handleClick} size={props.size} color="#1982df">
        {props.text}
      </H6>
      {!props.noImage && (
        <Img src={move_btn_plam} alt="nextBtn" size={props.size} />
      )}
    </BtnBox>
  )
}
