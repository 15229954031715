/* eslint-disable import/no-unresolved */
import React from 'react'
import { Flex, Text } from '@components/atoms'
import { RSImageEmpty, RSWritingEmpty } from '@images/index'

export const WritingEmpty = () => {
  return (
    <Flex
      style={
        {
          flexDirection: 'column',
          width: '100%',
          height: '500px',
          alignItems: 'center',
          justifyContent: 'center',
        }
      }
    >
      <img style={{ width: '200px' }} src={RSWritingEmpty} alt={'empty data'} />
      <Text>
        소속사와 연결되지 않았거나 아직 소속사에서 정산서 작성을 시작하지
        않았습니다.
      </Text>
    </Flex>
  )
}
