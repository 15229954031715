import { observable } from 'mobx'
import ArtistModel from './ArtistModel'

export default class ArtistTrendModel extends ArtistModel {
  /* Database fields */

  @observable imageUrl
  @observable likeCountInfo
  @observable listenerInfo
  @observable followersInfo
  @observable rankInfo
  @observable totalDailyList
  @observable trendDataAddedAt

  /* Database fields end */

  constructor(stores, props) {
    super(stores, props)

    this.imageUrl = props?.imageUrl
    this.likeCountInfo = props?.likeCountInfo
    this.listenerInfo = props?.listenerInfo
    this.followersInfo = props?.followersInfo
    this.rankInfo = props?.rankInfo
    this.totalDailyList = props?.totalDailyList
    this.trendDataAddedAt = props?.trendDataAddedAt
  }
}
