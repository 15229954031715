import React from 'react'
import styled from 'styled-components'
import { Flex, Text, Image } from '@components/atoms'
import { RoundBtn } from '@components/molecules'
import { TrackInfo } from '@components/organisms'
import { dateYMD } from '@utils/format'
import { StreamingButton } from '@components/molecules/icon/StramingButton'
import { inject } from 'mobx-react'

const Container = styled(Flex)`
  flex-direction: column;
  flex: 1;
  margin: 0px 50px 0px 0px;
`
const StreamingContainer = styled(Flex)`
  flex-direction: column;
  border-radius: 6px;
  border: solid 1px #dddddd;
  margin: 34px 0 0 0;
  padding: 20px 30px;
`

const InfoContainer = styled(Flex)`
  flex-direction: column;
  margin-left: 50px;
`
const TopView = styled(Flex)`
  width: 100%;
  padding: 30px 0;
  justify-content: flex-start;
`
const UserImage = styled(Image).attrs(props => {
  return {
    src: props.src,
  }
})`
  width: 400px;
  height: 400px;
  border-radius: 5px;
`

const Title = styled(Text)`
  padding: 0px 0px 10px 0px;
  font-family: NotoSansCJKkr-Medium;
  font-size: 24px;
`

const Content = styled(Flex)`
  width: 100%;
  flex-direction: column;
  /* height: '100%'; */
  padding: 30px 0px 0px 0px;
`

const InfoItem = styled(Flex)`
  padding: 6px 0px;

  span:first-child {
    width: 66px;
    font-weight: bold;
  }
  span:nth-child(2) {
    width: 200px;
  }
`

const StreamingContent = styled(Flex)`
  flex-direction: row;
  padding: 0px;
  max-width: 600px;
  flex-wrap: wrap;
`

const TrackList = styled(Flex)`
  flex-direction: column;
  > div {
    padding: 8px 0px;
  }
  > div:first-child {
    padding-top: 0;
  }
  > div:last-child {
    padding-bottom: 0;
  }
`

const ContentTitle = styled(Text)`
  padding: 0px 0px 15px 0px;
  font-size: 18px;
  font-family: 'NotoSansCJKkr-Medium';
`
const ContentItem = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  padding: 15px 0px 15px 0px;
  align-items: center;
  overflow-wrap: break-word;
  cursor: pointer;
`

const add_btn_img = require('@images/add_btn.png')

const _AlbumInfo = ({
  authStore,
  artist,
  album,
  navigateUpdateTrack,
  navigateDeleteTrack,
  showCopyToast,
  checkCustomUrl,
  modifyCustomUrl,
}) => {
  const StreamingItem = ({ type }) => {
    return (
      <ContentItem onClick={() => window.open(album[`${type}`])}>
        <StreamingButton type={type} />
      </ContentItem>
    )
  }

  const isLoginedMaster = authStore.isAdminPassword

  return (
    <Container>
      <TopView>
        <UserImage src={album.imageUri} />
        <InfoContainer>
          <Title>{album.title}</Title>
          <Text size="18px" style={{ paddingBottom: '10px' }}>
            {album.releaseArtistName}
          </Text>
          <Flex>
            <Flex type="column">
              <InfoItem>
                <Text size="14px" color="#646464">
                  앨범 유형
                </Text>
                <Text size="14px">{album.albumType}</Text>
              </InfoItem>
              <InfoItem>
                <Text size="14px" color="#646464">
                  주 장르
                </Text>
                <Text size="14px">{album.mainGenre}</Text>
              </InfoItem>
              <InfoItem>
                <Text size="14px" color="#646464">
                  하위 장르
                </Text>
                <Text size="14px">{album.subGenre}</Text>
              </InfoItem>
            </Flex>
            <Flex type="column">
              <InfoItem>
                <Text size="14px" color="#646464">
                  발매일
                </Text>
                <Text size="14px">{dateYMD(album.releasedAt)}</Text>
              </InfoItem>
              <InfoItem>
                <Text size="14px" color="#646464">
                  유통사
                </Text>
                <Text size="14px">{album.releaseCompanyName}</Text>
              </InfoItem>
              <InfoItem>
                <Text size="14px" color="#646464">
                  권리사
                </Text>
                <Text size="14px">{album.planningCompanyName}</Text>
              </InfoItem>
            </Flex>
          </Flex>

          <StreamingContainer>
            <ContentTitle>음악 서비스</ContentTitle>
            <StreamingContent>
              {album.spotifyUrl && <StreamingItem type="spotifyUrl" />}
              {album.youtubeMusicUrl && (
                <StreamingItem type="youtubeMusicUrl" />
              )}
              {album.melonUrl && <StreamingItem type="melonUrl" />}
              {album.genieUrl && <StreamingItem type="genieUrl" />}
              {album.bugsUrl && <StreamingItem type="bugsUrl" />}
              {album.vibeUrl && <StreamingItem type="vibeUrl" />}
              {album.floUrl && <StreamingItem type="floUrl" />}
              {album.appleMusicUrl && <StreamingItem type="appleMusicUrl" />}
              {album.amazonMusicUrl && <StreamingItem type="amazonMusicUrl" />}
              {album.tidalUrl && <StreamingItem type="tidalUrl" />}
              {album.deezerUrl && <StreamingItem type="deezerUrl" />}
              {album.jooxUrl && <StreamingItem type="jooxUrl" />}
              {album.mymusicUrl && <StreamingItem type="mymusicUrl" />}
              {album.kkboxUrl && <StreamingItem type="kkboxUrl" />}
              {album.linejpUrl && <StreamingItem type="linejpUrl" />}
              {album.linetwUrl && <StreamingItem type="linetwUrl" />}
              {album.yandexUrl && <StreamingItem type="yandexUrl" />}
              {album.nctUrl && <StreamingItem type="nctUrl" />}
              {album.zingUrl && <StreamingItem type="zingUrl" />}
              {album.anghmiUrl && <StreamingItem type="anghmiUrl" />}
            </StreamingContent>
          </StreamingContainer>
        </InfoContainer>
      </TopView>
      <Content>
        <Text type="Medium" size="18px" style={{ paddingBottom: '16px' }}>
          Track List
        </Text>
        <TrackList>
          {album.trackList.length > 0 ? (
            <>
              {album.trackList.map(track => (
                <TrackInfo
                  artist={artist}
                  track={track}
                  album={album}
                  key={track._id}
                  navigateUpdateTrack={navigateUpdateTrack}
                  navigateDeleteTrack={navigateDeleteTrack}
                  showCopyToast={showCopyToast}
                  checkCustomUrl={checkCustomUrl}
                  modifyCustomUrl={modifyCustomUrl}
                />
              ))}
            </>
          ) : album && album.isOwner ? (
            <Text type="Medium" size="12px" style={{ paddingBottom: '14px' }}>
              추가된 트랙이 없습니다. 트랙을 추가해주세요.
            </Text>
          ) : (
            <Text type="Medium" size="12px" style={{ paddingBottom: '14px' }}>
              추가된 트랙이 없습니다.
            </Text>
          )}
          {isLoginedMaster && album && album.isOwner && (
            <RoundBtn
              img={add_btn_img}
              text="트랙 추가"
              onClick={() => navigateUpdateTrack(null, album)}
            />
          )}
        </TrackList>
      </Content>
    </Container>
  )
}

export const AlbumInfo = inject(
  'authStore',
)(_AlbumInfo)