/* eslint-disable import/no-unresolved */
import { action, observable } from 'mobx'
import { AdjustmentContractModel } from '@stores/models'
import { Network } from '../networks'
import { Store } from '..'

export default class AdjustmentContractStore {
  @observable contracts = []
  @observable selectedCompanyId = null

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.contracts = []
    this.selectedCompanyId = null
  }

  @action.bound
  setSelectedCompanyId = id => {
    this.selectedCompanyId = id
  }

  /**
   * [계약을 생성한 사업자 리스트 조회]
   * 아티스트가 계약 리스트 조회할때 필요한 companyId를 위해 먼저 get 필요
   */
  async fetchContractCompanyList({ artistId } = {}) {
    if (!artistId) {
      console.error('[fetchContractCompanyList] aritstId is not exist')
      return
    }

    if (!this.store?.authStore?.currentUser) {
      console.error('[fetchContractCompanyList] currentUser is not exist')
      return
    }

    if (!artistId) {
      console.error('[fetchContractCompanyList] artistId is not exist')
      return
    }

    const res = await this.network.adjustmentContractNetwork.getContractCompanyList(
      { artistId },
    )

    if (!res || res.error) {
      console.error('[fetchContractCompanyList] failed to network')
      return
    }

    return res.companyList
  }

  /**
   * [정산서 계약 리스트 조회]
   */
  async fetchAdjustmentContractList({ artistId, companyId } = {}) {
    if (!artistId) {
      console.error('[fetchAdjustmentContractList] aritstId is not exist')
      this.contracts = []
      return
    }

    if (!companyId) {
      if (this.selectedCompanyId) {
        companyId = this.selectedCompanyId
        console.warn(
          '[fetchAdjustmentContractList] companyId is not exist, use selectedCompanyId',
        )
      }
      else {
        console.error('[fetchAdjustmentContractList] companyId is not exist')
        this.contracts = []
        return
      }
    }

    if (!this.store?.authStore?.currentUser) {
      console.error('[fetchAdjustmentContractList] currentUser is not exist')
      this.contracts = []
      return
    }

    const res = await this.network.adjustmentContractNetwork.getAdjustmentContractList(
      {
        artistId,
        companyId,
      },
    )

    if (!res || res.error) {
      console.error('[fetchAdjustmentContractList] failed to network')
      this.contracts = []
      return
    }

    const { adjustmentContractList } = res.data
    if (adjustmentContractList.length === 0) {
      const _contract = this.createAdjustmentContract()
      this.contracts = [new AdjustmentContractModel(this.store, _contract)]
      return this.contracts
    }

    this.contracts = adjustmentContractList
      .map(
        _adjustmentContract =>
          new AdjustmentContractModel(this.store, _adjustmentContract),
      )
      .filter(contract => contract.deletedAt == null)

    return this.contracts
  }

  async createAdjustmentContract({
    type = null,
    companyRatio = 0,
    artistRatio = 0,
    description = '',
  } = {}) {
    if (!this.store?.authStore?.currentUser) {
      console.error('[createAdjustmentContract] currentUser is not exist')
      return
    }
    const { companyId, artistId } = this.store.authStore.currentUser
    if (!companyId || !artistId) {
      console.error(
        '[createAdjustmentContract] companyId or artistId is not exist. companyId: ',
        companyId,
        ' artistId: ',
        artistId,
      )
      return
    }
    const params = {
      companyId,
      artistId,
      type,
      companyRatio,
      artistRatio,
      description,
    }
    const res = await this.network.adjustmentContractNetwork.postAdjustmentContract(
      { params },
    )

    if (!res || res.error) {
      console.error('[createAdjustmentContract] failed to network')
      return
    }

    const adjustmentContract = new AdjustmentContractModel(
      this.store,
      res.data.adjustmentContract,
    )

    return adjustmentContract
  }

  async updateAdjustmentContract({ adjustmentContractId, params } = {}) {
    if (!params || !adjustmentContractId) {
      console.error(
        '[updateAdjustmentContract] params or adjustmentContractId is not exist',
      )
      return
    }

    const res = await this.network.adjustmentContractNetwork.putAdjustmentContract(
      { adjustmentContractId, params },
    )

    if (!res || res.error) {
      console.error('[updateAdjustmentContract] failed to network')
      return
    }

    return res.adjustmentContract
  }

  async deleteAdjustmentContract({ adjustmentContractId } = {}) {
    if (!adjustmentContractId) {
      console.error(
        '[deleteAdjustmentContract] params or adjustmentContractId is not exist',
      )
      return
    }

    const res = await this.network.adjustmentContractNetwork.deleteAdjustmentContract(
      { adjustmentContractId },
    )

    if (!res || res.error) {
      console.error('[deleteAdjustmentContract] failed to network')
      return
    }

    return res.adjustmentContract
  }
}
