class AutoCompleteCompanyModel {
  _id = ''
  name = ''
  companyUserAccount = ''
  uniqueName = ''
  constructor(props) {
    if (props) {
      this._id = props._id
      this.name = props.name
      this.companyUserAccount = props.companyUserAccount
      this.uniqueName = props.uniqueName
    }
  }
  toIdObj() {
    return {
      _id: this._id || this.name,
      isPLAM: !!this._id,
    }
  }
}

export default AutoCompleteCompanyModel
