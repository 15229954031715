import React from 'react'
import styled from 'styled-components'
import { Flex, Caption2, H6 } from '@components/atoms'
import {
  melonTrendImg,
  youtubeTrendImg,
  bugsTrendImg,
  genieTrendImg,
  spotifyTrendImg,
} from '@images/'
import { convertToPricingComma } from '@utils/format'
import { IncreaseTextBox } from '@components/molecules'
import { SpotifyPopularityTooltip, YoutubeListenTooltip } from '../Tooltip'

const Box = styled(Flex)`
  height: 40px;

  img {
    width: 54px;
    height: 54px;
    margin-right: 10px;
  }
`

const StreamingListenLikeCard = ({
  streamingType,
  listenData,
  increaseListenData,
  likeData,
  increaseLikeData,
  unit,
  onClick,
}) => {

  return (
    <Box
      onClick={onClick}
      style={{
        width: 'fit-content',
        alignItems: 'center',
        cursor: onClick ? 'pointer' : 'initial',
      }}
    >
      <img
        src={
          streamingType === 'melon'
            ? melonTrendImg
            : streamingType === 'youtube'
              ? youtubeTrendImg
              : streamingType === 'bugs'
                ? bugsTrendImg
                : streamingType === 'genie'
                  ? genieTrendImg
                  : streamingType === 'spotify'
                    ? spotifyTrendImg
                    : false
        }
        alt="sns_img"
      />
      <Flex type="column">
        <Caption2 align="left" color="#949494">
          Daily
        </Caption2>
        <Flex style={{ gap: 16 }}>
          {listenData ? (
            <Flex align="center" style={{ whiteSpace: 'nowrap' }}>
              <Flex align="center" gap="4px" style={{ position: 'relative' }}>
                <H6 type="Bold" align="left">
                  {`${convertToPricingComma(listenData || 0)} ${unit ? unit : 'Listen'
                    }`}
                </H6>
                {streamingType === 'youtube' && <YoutubeListenTooltip />}
              </Flex>

              <IncreaseTextBox
                data={increaseListenData}
                style={{ marginLeft: '10px' }}
              />
            </Flex>
          ) : null}
          <Flex align="center" style={{ whiteSpace: 'nowrap' }}>
            <Flex align="center" gap="4px" style={{ position: 'relative' }}>
              <H6 type="Bold" align="left">
                {`${convertToPricingComma(likeData || 0)} ${streamingType === 'spotify'
                  ? 'Popularity'
                  : unit
                    ? unit
                    : 'Like'
                  }`}
              </H6>
              {streamingType === 'spotify' && <SpotifyPopularityTooltip />}
            </Flex>

            <IncreaseTextBox
              data={increaseLikeData}
              style={{ marginLeft: '10px' }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

export default StreamingListenLikeCard
