import React, { useState, useEffect } from 'react'
import { RegisterForm, RegisterCompanyForm } from '@components/organisms'

import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import { AuthStore } from '../stores'

interface Props {
  match: Any;
  location: Any;
  history: Any;
  authStore: AuthStore;
}

const RegisterPage = ({ match, location, history, authStore }: Props) => {
  const [isRegister, setIsRegister] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [registerType, setRegisterType] = useState('artist')

  const register = authStore.register || (() => {})
  const login = authStore.login || (() => {})
  const emailAuth = authStore.emailAuth || (() => {})

  useEffect(() => {
    const currentUser = authStore.currentUser || null
    if (isRegister && currentUser !== null) {
      setIsComplete(true)
    }
  }, [isRegister, authStore.currentUser])

  useEffect(() => {
    if (location && location.state && location.state.type) {
      setRegisterType(location.state.type)
    }
  }, [location])

  return (
    <>
      {isComplete && <Redirect to="/register/complete" />}
      {
        registerType === 'artist' ? (
          <RegisterForm
            postUser={register}
            setIsRegister={setIsRegister}
            login={login}
            emailAuth={emailAuth}
          />
        ) : (
          <RegisterCompanyForm
            postUser={register}
            setIsRegister={setIsRegister}
            login={login}
            emailAuth={emailAuth}
          />
        )
      }
    </>
  )
}

export default inject('authStore')(observer(RegisterPage))
