import React from 'react'
import { Flex, H4, Caption1, H1, H5 } from '@components/atoms'
import styled from 'styled-components'
import { LogoImg, MoveBtn, NoHeaderBox } from '@components/molecules'
import { colors } from '@colors/'

const EmailBox = styled.div`
  margin: 60px 0 80px 0;
  width: 734px;
  height: 186px;
  border-radius: 16px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const AuthEmailTemplate = ({ type, userId }) => {
  return (
    <NoHeaderBox>
      <LogoImg />
      {type === 'success' ? (
        <H1>
          이메일이
          <br />
          인증되었습니다
        </H1>
      ) : (
        <H1>
          이메일 인증이
          <br />
          실패하였습니다
        </H1>
      )}
      {type === 'success' ? (
        <>
          <EmailBox>
            <Flex>
              <H4 color={colors.main}>{userId}</H4>
              <H4>님</H4>
            </Flex>
            <Caption1 style={{ marginTop: '30px' }} color="#949494">
              *지금 바로 아티스트를 등록하고
              <br />
              PLAM 링크를 만들어보세요
            </Caption1>
          </EmailBox>
          <MoveBtn text="마이 페이지로 이동" href="/" />
        </>
      ) : (
        <>
          <EmailBox>
            <H5 color="#ea4653">로그인하여 이메일 인증을 다시 진행해주세요.</H5>
          </EmailBox>
          <MoveBtn text="로그인 하러가기" href="/" />
        </>
      )}
    </NoHeaderBox>
  )
}
