import React from 'react'
import styled, { css } from 'styled-components'
import { Flex, H3 } from '@components/atoms'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import moment from 'moment'
import 'moment/locale/ko'
import { observer } from 'mobx-react'
import MonthlyPicker from '@components/organisms/Card/MonthlyPicker'
import ArtistSettlementMonthlyChart from '@components/organisms/Chart/ArtistSettlementMonthlyChart'
import CompanyArtistSettlementMonthlyChart from '@components/organisms/Chart/CompanyArtistSettlementMonthlyChart'

const ArtistInsightSettlementTemplate = ({
  artistMonthlyData,
  currentMonth,
  currentUser,
  handleMonth,
  filterDistributor,
  onFilterDistributor,
  filterCompanyId,
  onFilterCompany,
}) => {
  const settlementInfo = artistMonthlyData?.settlementInfo || null
  const artistAnnualSettlement = settlementInfo?.artistAnnualSettlement || null
  const artistMonthlySettlementInfo =
    settlementInfo?.artistMonthlySettlementList[0] || null

  const monthDistributorList =
    artistMonthlySettlementInfo?.distributorList?.sort((a, b) =>
      a < b ? 1 : -1,
    ) || []

  const displayMonth = currentMonth?.format('YYYY년 MM월')

  moment.locale('ko')

  return (
    <Container>
      <H3 type="bold" align="left">
        정산 세부내역
      </H3>
      <Flex style={{ margin: '32px 0 20px 0' }}>
        <MonthlyPicker
          currentMonth={currentMonth}
          handleMonth={handleMonth}
          enableMonthList={artistAnnualSettlement?.monthList.slice() || []}
        />
      </Flex>
      <DitributorList>
        <DistributorBtn
          active={!filterDistributor}
          onClick={() => onFilterDistributor(null)}
        >
          전체
        </DistributorBtn>
        {
monthDistributorList?.map(distributor => (
  <DistributorBtn
    key={distributor}
    active={filterDistributor === distributor}
    onClick={() => onFilterDistributor(distributor)}
  >
    {distributor}
  </DistributorBtn>
))
        }
      </DitributorList>
      {
currentUser?.type === 'company' ? (
  <CompanyArtistSettlementMonthlyChart displayMonth={displayMonth} />
) : (
  <ArtistSettlementMonthlyChart
    currentMonth={currentMonth}
    displayMonth={displayMonth}
    filterCompanyId={filterCompanyId}
    onFilterCompany={onFilterCompany}
  />
)
      }
    </Container>
  )
}

export default observer(ArtistInsightSettlementTemplate)

export const Container = styled(Flex)`
  flex-direction: column;
  padding: 50px;
  width: calc(100% - 200px); // SideBar
  flex: 1;
`

const DitributorList = styled(Flex)`
  width: 100%;
  gap: 16px;
  flex-wrap: wrap;
`

const DistributorBtn = styled.button`
  cursor: pointer;
  max-width: 290px;
  flex: 1;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background-color: #fff;

  font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: 500;

  ${({ active }) =>
    active
      ? css`
          border: solid 1px #242424;
          color: #242424;
        `
      : css`
          border: solid 1px #eaeaea;
          color: #646464;
        `}}
`
