/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Text, Image } from '@components/atoms'
import { numberWithCommas } from '@utils/format'
import { Checkbox, Collapse } from 'antd'
import { Loading } from '@components/molecules'
import {
  DownOutlined,
  LoadingOutlined,
  RightOutlined,
  UpOutlined,
} from '@ant-design/icons'
import { sub, subImg } from '@images/index'

const { Panel } = Collapse

const body1_16px_regular = styled.span`
  font-family: 'NotoSansCJKkr-regular';
  font-size: 16px;
`
const body1_16px_medium = styled.span`
  font-family: 'NotoSansCJKkr-medium';
  font-size: 16px;
`
const body1_16px_bold = styled.span`
  font-family: 'NotoSansCJKkr-bold';
  font-size: 16px;
`
const body2_14px_regular = styled.span`
  font-family: 'NotoSansCJKkr-regular';
  font-size: 14px;
`
const body2_14px_medium = styled.span`
  font-family: 'NotoSansCJKkr-medium';
  font-size: 14px;
`
const body2_14px_bold = styled.span`
  font-family: 'NotoSansCJKkr-bold';
  font-size: 14px;
`
const caption1_12px_regular = styled.span`
  font-family: 'NotoSansCJKkr-regular';
  font-size: 12px;
`
const caption1_12px_medium = styled.span`
  font-family: 'NotoSansCJKkr-medium';
  font-size: 12px;
`
const caption1_12px_bold = styled.span`
  font-family: 'NotoSansCJKkr-bold';
  font-size: 12px;
`

// 폰트 색상 3가지
const black = '#242424'
const grey1 = '#646464'
const grey2 = '#949494'

// 배경
const white = '#FFFFFF'
const white2 = '#FAFAFA'

// border
const border1 = '#EAEAEA' // 흰색 배경 border
const border2 = '#F4F4F4' // 회색 배경 border

const Title = styled(body1_16px_bold)`
  color: ${black};
`
const SubTitle = styled(body2_14px_medium)`
  color: ${grey1};
  margin-right: 4px;
`
const TextGrey = styled(caption1_12px_regular)`
  color: ${grey1};
`
const TextGrey2 = styled(caption1_12px_regular)`
  color: ${grey2};
`
const TextBlack = styled(caption1_12px_regular)`
  color: ${black};
`
const TextBlack2 = styled(caption1_12px_medium)`
  color: ${black};
`
const SubImage = styled(Image).attrs({ src: subImg })`
  width: 12px;
  height: 12px;
  margin-right: 2px;
`

const TotalContainer = styled(Flex)`
  flex-direction: column;
  flex-shrink: 0;
  align-self: flex-start;
  min-width: 320px;
  width: 320px;
  margin-left: 15px;
  padding: 20px;
  border: solid 1px #eaeaea;
  margin-bottom: 100px;
`

const Line = styled(Flex)`
  width: '100%';
  border-top: solid 1px #eaeaea;
  margin-top: 8px;
  margin-bottom: 8px;
`

const TitleBlack = styled(Text)`
  font-family: 'NotoSansCJKkr-medium';
  color: #242424;
  font-size: 14px;
`

const ItemContainer = styled(Flex)`
  width: 100%;
  justify-content: space-between;
`
const DetailContainer = styled(Flex)`
  flex-direction: column;
  padding: 10px;
  background-color: #fafafa;
  border: 1px solid ${border2};
`

const ListItemContainer = styled(ItemContainer)`
  margin-bottom: 4px;
  margin-top: 4px;
`
const ListGreyItemContainer = styled(ItemContainer)``

const ListCheckboxItemContainer = styled(ItemContainer)``

/** 정산요약 시작 */
const SummaryContainer = styled(Flex)`
  flex-direction: column;
  padding: 8px;
  background-color: #fafafa;
  border: 1px solid ${border2};
`
const SummaryItemContainer = styled(ItemContainer)`
  margin-bottom: 8px;
`
const SummaryItemTitleContainer = styled(Flex)`
  align-items: center;
`

/** 정산요약 끝 */

const ItemLeftContainer = styled(Flex)`
  align-items: center;
`

const TextNormal = styled(Text)`
  font-family: 'NotoSansCJKkr-regular';
  color: #646464;
  font-size: 12px;
`

const TotalAmount = styled(Text)`
  font-family: 'NotoSansCJKkr-bold';
  color: #242424;
  font-size: 14px;
`

export const TotalInfo = ({
  amountInfo = {},
  selectedTypes = [],
  onCarryOverChange = () => {},
  isUpdatingCheckbox = false,
  updatingId = null,
  readOnly = true,
  isClosed = true,
}) => {
  const adjustmentCarryOverList = amountInfo?.adjustmentCarryOverList || []
  const filteredDetailList =
    amountInfo?.amountDetailList?.filter(item => {
      if (selectedTypes.length === 0) return true
      return selectedTypes.includes(item.type)
    }) || []
  const adjustmentContractList = amountInfo?.adjustmentContractList || []

  // const incomeCount = filteredDetailList.reduce((acc, curr) => {
  //   return acc + curr.incomeCount
  // }, 0)

  // const expenditureCount = filteredDetailList.reduce((acc, curr) => {
  //   return acc + curr.expenditureCount
  // }, 0)

  // const totalCount = incomeCount + expenditureCount

  // const totalAmount = filteredDetailList.reduce((acc, curr) => {
  //   return acc + curr.amount
  // }, 0)
  // 합계 (- 포함)
  const totalAmount = amountInfo?.amount || 0

  // 총 정산 금액 (- 제외)
  const totalAdjustmentAmount = amountInfo?.adjustmentAmount || 0

  // 이월금 제외한 최종 정산금액
  const finalAdjustmentAmount = amountInfo?.finalAdjustmentAmount || 0

  /** 필터 때문에 합계 클라에서 다시 계산해야됨(위에는 서버에서 보내주는 전체 합계) */
  const filteredTotalAdjustmentAmount = filteredDetailList.reduce(
    (acc, curr) => {
      return acc + curr.adjustmentAmount
    },
    0,
  )
  const filteredTotalAmount = filteredDetailList.reduce((acc, curr) => {
    return acc + curr.amount
  }, 0)
  const filteredTotalExpenditureAmount = filteredDetailList.reduce(
    (acc, curr) => {
      if (curr.amount < 0) {
        return acc + curr.amount
      }
      return acc
    },
    0,
  )

  return (
    <TotalContainer>
      <Title>정산내역</Title>
      {/* <Line /> */}
      {/* <Flex
        width={'100%'}
        style={
          {
            justifyContent: 'space-between',
          }
        }
      >
        <Text>수입/지출</Text>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h4>{`${totalCount}`}</h4>
          <Text>{`(수입${incomeCount}+지출${expenditureCount})`}</Text>
        </div>
      </Flex> */}
      <Line style={{ marginTop: '5px' }} />
      <SubTitle style={{ marginBottom: '5px' }}>상세내역</SubTitle>
      {
        filteredDetailList.map((item, index) => (
          <ListItemContainer key={index}>
            <TextGrey style={{ marginLeft: '0px' }}>
              {
                ` ${
                  item.type === null
                    ? '구분 없음'
                    : item.type.length >= 15
                      ? `${item.type.substr(0, 15)}..`
                      : item.type
                }`
              }
            </TextGrey>
            <TextGrey>{`${numberWithCommas(item.amount) || 0}원`}</TextGrey>
          </ListItemContainer>
        ))
      }
      <Line />
      <ItemContainer style={{ marginTop: '5px', marginBottom: '5px' }}>
        <ItemLeftContainer>
          <SubTitle>합계</SubTitle>
          <TextGrey2>( -포함)</TextGrey2>
        </ItemLeftContainer>
        <TitleBlack>
          {`${numberWithCommas(filteredTotalAmount) || 0}원`}
        </TitleBlack>
      </ItemContainer>
      <ItemContainer style={{ marginTop: '5px', marginBottom: '10px' }}>
        <SubTitle>- 내역</SubTitle>
        <TitleBlack>
          {`${numberWithCommas(filteredTotalExpenditureAmount) || 0}원`}
        </TitleBlack>
      </ItemContainer>
      <DetailContainer>
        {
          filteredDetailList.filter(item => item.amount < 0).length === 0 ? (
            <TextNormal style={{ color: '#949494' }}>
            지출 내역이 없습니다.
            </TextNormal>
          ) : (
            filteredDetailList.map((item, index) => {
              if (item.amount < 0) {
                return (
                  <ListGreyItemContainer key={index}>
                    <TextGrey style={{ marginLeft: '0px' }}>
                      {
                        ` ${
                          item.type === null
                            ? '구분 없음'
                            : item.type.length >= 15
                              ? `${item.type.substr(0, 15)}..`
                              : item.type
                        }`
                      }
                    </TextGrey>
                    <TextGrey>
                      {`${numberWithCommas(item.amount) || 0}원`}
                    </TextGrey>
                  </ListGreyItemContainer>
                )
              }
            })
          )
        }
      </DetailContainer>
      <ItemContainer style={{ marginTop: '8px', marginBottom: '5px' }}>
        <ItemLeftContainer>
          <SubTitle>합계</SubTitle>
          <TextGrey2>(-제외)</TextGrey2>
        </ItemLeftContainer>
        <TitleBlack>
          {`${numberWithCommas(filteredTotalAdjustmentAmount) || 0}원`}
        </TitleBlack>
      </ItemContainer>
      {
        selectedTypes.length === 0 && (
          <div>
            <Line style={{ marginTop: '5px' }} />
            <Carry
              readOnly={readOnly}
              isClosed={isClosed}
              itemList={adjustmentCarryOverList}
              onCarryOverChange={onCarryOverChange}
              isUpdatingCheckbox={isUpdatingCheckbox}
              updatingId={updatingId}
              title={'이월 설정'}
            />
            <Line style={{ marginTop: '12px' }} />
            <Summary itemList={adjustmentContractList} title={'정산 요약'} />
            <Line />
            <ItemContainer>
              <TitleBlack>최종 정산 금액</TitleBlack>
              <TotalAmount>
                {`${numberWithCommas(finalAdjustmentAmount) || 0}원`}
              </TotalAmount>
            </ItemContainer>
          </div>
        )
      }
    </TotalContainer>
  )
}

const SummaryCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 0px;
    right: 0px;
  }
  .ant-collapse-content-box {
    padding: 0px;
  }
  .ant-collapse-content {
    background-color: white;
  }
  .ant-collapse-content-box {
    padding: 0px;
  }
  .ant-collapse-arrow {
    right: 0px;
  }
  &.ant-collapse-icon-position-end
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 0px;
  }
  &.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
    padding: 0px;
  }
  &.ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    padding: 0px;
    padding-bottom: 4px;
  }
  &.ant-collapse > .ant-collapse-item {
    border-bottom: 0px;
  }
  &.ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 0px;
  }
`

const Summary = ({ itemList = [], title = '' }) => {
  return (
    <SummaryCollapse
      bordered={false}
      expandIconPosition="right"
      className="custom-collapse"
      defaultActiveKey={['1']}
    >
      <Panel
        style={
          {
            backgroundColor: 'white',
            padding: 0,
            margin: 0,
            paddingBottom: '0px',
          }
        }
        header={<SubTitle>{title}</SubTitle>}
        key="1"
      >
        {
          itemList.map((item, index) => {
            return (
              <SummaryContainer key={index}>
                <SummaryItemContainer>
                  <TextBlack>{item.type}</TextBlack>
                  <TextBlack2>
                    {`${numberWithCommas(item.finalAdjustmentAmount) || 0}원`}
                  </TextBlack2>
                </SummaryItemContainer>
                <SummaryItemContainer>
                  <SummaryItemTitleContainer>
                    <SubImage />
                    <TextGrey>수입</TextGrey>
                  </SummaryItemTitleContainer>

                  <TextGrey>
                    {`${numberWithCommas(item.incomeAmount) || 0}원`}
                  </TextGrey>
                </SummaryItemContainer>
                <SummaryItemContainer>
                  <SummaryItemTitleContainer>
                    <SubImage />
                    <TextGrey>지출</TextGrey>
                  </SummaryItemTitleContainer>
                  <TextGrey>
                    {`${numberWithCommas(item.expenditureAmount) || 0}원`}
                  </TextGrey>
                </SummaryItemContainer>
                <SummaryItemContainer>
                  <SummaryItemTitleContainer>
                    <SubImage />
                    <TextGrey>이월</TextGrey>
                  </SummaryItemTitleContainer>
                  <TextGrey>
                    {`${numberWithCommas(item.carryOverAmount) || 0}원`}
                  </TextGrey>
                </SummaryItemContainer>
              </SummaryContainer>
            )
          })
        }
      </Panel>
    </SummaryCollapse>
  )
}

const CarryCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 0px;
  }
  .ant-collapse-content-box {
    padding: 0px;
  }
  .ant-collapse-content {
    background-color: white;
  }
  .ant-collapse-content-box {
    padding: 0px;
  }
  .ant-collapse-arrow {
    right: 0px;
  }
  &.ant-collapse-icon-position-end
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 0px;
  }
  &.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
    /* padding: 0px;
    left: 0px; */
  }
  &.ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    padding: 0px;
    padding-bottom: 4px;
  }
  &.ant-collapse > .ant-collapse-item {
    border-bottom: 0px;
  }
  &.ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 0px;
  }
`

const BodyContainer = styled.div`
  padding: 10px;
  background-color: #fafafa;
  border: 1px solid ${border2};
`

const Carry = ({
  itemList = [],
  isUpdatingCheckbox,
  updatingId,
  onCarryOverChange,
  title = '',
  readOnly = true,
  isClosed = true,
}) => {
  return (
    <CarryCollapse
      bordered={false}
      expandIconPosition="right"
      className="custom-collapse"
      defaultActiveKey={['1']}
      expandIcon={itemList.length === 0 ? () => null : undefined}
    >
      <Panel
        style={{ backgroundColor: 'white', padding: 0, margin: 0 }}
        header={<SubTitle>{title}</SubTitle>}
        key="1"
      >
        <BodyContainer>
          {
            itemList.length === 0 ? (
              <TextNormal style={{ color: '#949494' }}>
              이월된 내역이 없습니다.
              </TextNormal>
            ) : (
              itemList.map((item, index) => {
                return (
                  <ListCheckboxItemContainer key={index}>
                    <div>
                      {
                        isUpdatingCheckbox && updatingId === item.typeId ? (
                          <LoadingOutlined style={{ marginRight: '6px' }} />
                        ) : (
                          !isClosed && (
                            <Checkbox
                              disabled={readOnly}
                              style={{ marginRight: '4px' }}
                              checked={item.isCarryOver}
                              onChange={
                                () =>
                                  onCarryOverChange({
                                    typeId: item.typeId,
                                    isCreate: !item.isCarryOver,
                                  })
                              }
                            />
                          )
                        )
                      }

                      <TextGrey>
                        {
                          `${
                            item.type === null
                              ? '일반'
                              : item.type.length >= 15
                                ? `${item.type.substr(0, 15)}..`
                                : item.type
                          }`
                        }
                      </TextGrey>
                    </div>
                    <TextGrey style={{ marginTop: '3px' }}>
                      {`${numberWithCommas(item.amount) || 0}원`}
                    </TextGrey>
                  </ListCheckboxItemContainer>
                )
              })
            )
          }
        </BodyContainer>
      </Panel>
    </CarryCollapse>
  )
}
