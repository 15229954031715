import { action, observable } from 'mobx'
import { Store } from '.'
import { Network } from './networks'
import { ArtistModel, ModifyRecordModel, StageNameModel } from './models'

export default class ArtistStore {
  @observable artistDetail
  @observable autocompleteArtistList
  @observable artistModifyRecordList
  @observable artistModifyRecordListCount

  @observable artistSmartLinkList
  @observable smartLinkUpdating

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.artistDetail = null
    this.autocompleteArtistList = []
    this.artistModifyRecordList = []
    this.artistModifyRecordListCount = 0

    this.artistSmartLinkList = []
    this.smartLinkUpdating = false
  }

  @action.bound
  async initClient() { }

  @action.bound
  async fetchArtistDetail(artistId) {
    if (
      this.store.authStore.currentUser &&
      !this.store.authStore.currentUser._id
    )
      return
    return this.store.useLoading(async () => {
      if (!artistId) return

      const fetchedArtist = await this.network.artistNetwork.getArtist(artistId)
      if (fetchedArtist.status === 403) {
        this.store.authStore.logout()
        if (
          this.store.authStore.currentUser
          && this.store.authStore.currentUser._id
        ) {
          alert('접근 권한이 없습니다!')
        }
        return
      }
      if (fetchedArtist) {
        this.artistDetail = new ArtistModel(this.store, fetchedArtist)
      }
      return !!fetchedArtist
    })
  }

  @action.bound
  async createArtist(artist) {
    return this.store.useLoading(async () => {
      if (!artist) return

      const createdArtist = await this.network.artistNetwork.postArtist(artist)
      // 강제로 로그인 시켜서 토큰 최신화
      await this.store.authStore.login({ isAutoLogin: true })
      if (!createdArtist) return
      this.artistDetail = new ArtistModel(this.store, createdArtist)
      // this.store.albumStore.fetchAutocompleteCompanyList()
    })
  }

  @action.bound
  async fetchAutocompleteArtistList(name, uniqueName = null) {
    // return this.store.useLoading(async () => {
    const fetchedArtistList = []
    const _fetchedArtistList = await this.network.artistNetwork.getStageNameList(
      {
        name,
        uniqueName,
        uniqueNameOperator: 'ne',
      },
    )
    if (_fetchedArtistList) {
      // 등록된 아이디 정렬
      // fetchedArtistList.sort(function (a, b) {
      //   return a.uniqueName !== null && b.uniqueName === null
      //     ? -1
      //     : a.uniqueName === null && b.uniqueName !== null
      //     ? 1
      //     : 0
      // })

      fetchedArtistList.push(
        ..._fetchedArtistList
          .filter(elem => !!elem)
          .map(elem => new StageNameModel(this.store, elem)) || []
      )
    }
    // this.autocompleteArtistList = fetchedArtistList
    return fetchedArtistList
    // })
  }

  @action.bound
  async updateArtist(artist) {
    return this.store.useLoading(async () => {
      if (!artist) return

      const updatedArtist = await this.network.artistNetwork.putArtist(artist)
      if (!updatedArtist) return

      if (updatedArtist.status === 409) {
        return 'conflict'
      }

      // this.artistDetail = new ArtistModel(this.store, updatedArtist)
      this.fetchArtistDetail(artist._id)
      // this.store.albumStore.fetchAutocompleteCompanyList()
    })
  }

  @action.bound
  async crawlArtist(melonUrl) {
    return this.store.useLoading(async () => {
      if (!melonUrl) return

      const res = await this.network.crawlNetwork.postCrawlArtist({
        userId: this.store.authStore.currentUser._id,
        uniqueName: this.store.authStore.currentUser.account,
        melonUrl,
      })

      return res.data
    })
  }

  @action.bound
  async createStageName(name) {
    // return this.store.useLoading(async () => {
    if (!name) return

    const res = await this.network.artistNetwork.postStageName({
      artistId: this.artistDetail._id,
      name,
    })

    if (res) {
      // this.fetchAutocompleteArtistList()
      this.fetchArtistDetail(this.artistDetail._id)
    }
    return !!res
    // })
  }

  @action.bound
  async updateStageName(id, name) {
    // return this.store.useLoading(async () => {
    if (!id || !name) return

    const res = await this.network.artistNetwork.putStageName({
      stageNameId: id,
      name,
    })

    if (res) {
      // this.fetchAutocompleteArtistList()
      this.fetchArtistDetail(this.artistDetail._id)
    }
    return !!res
    // })
  }

  @action.bound
  async removeStageName(id) {
    // return this.store.useLoading(async () => {
    if (!id) return

    const res = await this.network.artistNetwork.deleteStageName({
      stageNameId: id,
    })

    if (res) {
      // this.fetchAutocompleteArtistList()
      this.fetchArtistDetail(this.artistDetail._id)
    }
    return !!res
    // })
  }

  @action.bound
  fetchArtistModifyRecordList = async (artistId: string, offset = 0) => {
    return this.store.useLoading(async () => {
      const res = await this.network.artistNetwork.getModifyRecords(artistId, {
        limit: 9,
        offset,
      })

      if (!res) return
      if (offset < 1) {
        this.artistModifyRecordListCount = res.data.modifyRecordCount
        this.artistModifyRecordList = res.data.modifyRecordList
          .filter(elem => !!elem)
          .map(elem => new ModifyRecordModel(this.store, elem))
      } else {
        this.artistModifyRecordList = this.artistModifyRecordList.concat(
          res.data.modifyRecordList
            .filter(elem => !!elem)
            .map(elem => new ModifyRecordModel(this.store, elem)),
        )
      }
    })
  }

  @action.bound
  async fetchSmartLinkList() {
    // return this.store.useLoading(async () => {
    this.smartLinkUpdating = true
    const res = await this.network.artistNetwork.getSmartLinkList(
      this.artistDetail._id,
    )

    if (res) {
      this.artistSmartLinkList = res
    }
    this.smartLinkUpdating = false
    return !!res
    // })
  }

  @action.bound
  async createSmartLink(smartLinkList) {
    // return this.store.useLoading(async () => {
    if (!smartLinkList) return
    this.smartLinkUpdating = true
    const res = await this.network.artistNetwork.postSmartLink(
      this.artistDetail._id,
      {
        smartLinkList,
      },
    )

    if (res) {
      this.fetchSmartLinkList()
    }
    this.smartLinkUpdating = false
    return !!res
    // })
  }

  @action.bound
  async updateSmartLink(id, { title, linkUrl, sortIndex }) {
    // return this.store.useLoading(async () => {
    if (!id) return
    this.smartLinkUpdating = true
    const res = await this.network.artistNetwork.putSmartLink(
      this.artistDetail._id,
      id,
      {
        title,
        linkUrl,
        sortIndex,
      },
    )

    if (res) {
      this.fetchSmartLinkList()
    }
    this.smartLinkUpdating = false
    return !!res
    // })
  }

  @action.bound
  async removeSmartLink(id) {
    // return this.store.useLoading(async () => {
    if (!id) return
    this.smartLinkUpdating = true

    const res = await this.network.artistNetwork.deleteSmartLink(
      this.artistDetail._id,
      id,
    )

    if (res) {
      this.fetchSmartLinkList()
    }
    this.smartLinkUpdating = false
    return !!res
    // })
  }
}
