/* eslint-disable import/no-unresolved */
import React from 'react'
import { Flex, Text } from '@components/atoms'
import moment from 'moment'
import { DatePicker, Select, Input, message, Button, Upload } from 'antd'
import styled from 'styled-components'
import { numberWithCommas } from '@utils/format'
import { Link } from 'react-router-dom'
import { toJS } from 'mobx'
import { DeleteOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { CarryMark } from '../CarryMark'

const NoneResizableTextArea = styled(Input.TextArea)`
  resize: none;
`

const EllipsisText = styled(Text)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
`

const DeleteFileBtn = styled(Button)`
  visibility: hidden;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: gray;
  text-align: left;
  padding: 0;
  margin-right: 5px;
  height: 100%;
  &:hover {
    background-color: transparent;
  }
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
`

const FileListContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`

const FileListItemDiv = styled(Flex)`
  align-items: center;
  &:hover {
    ${DeleteFileBtn} {
      visibility: visible;
    }
  }
`

const FileListDiv = styled.div`
  flex: ${props => (props.$isEmpty ? 0 : 9)};
  min-width: 0;
  max-width: 104px;
`

const FileNameDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-decoration: underline;
`

const FileUpload = styled(Upload)`
  flex: 1;
`

const inoutOptions = {
  수입: '수입',
  지출: '지출',
}

const PlusBtn = styled(Button)`
  background-color: inherit;
  border: none;
  box-shadow: none;
  color: gray;
  text-align: right;
  height: 100%;
  padding: 0 5px;

  &:hover {
    background-color: transparent;
  }
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
`

export const getColumns = ({
  adjustmentStore,
  adjustmentDetailStore,
  allAdjustmentTypeList,
  currentArtistId,
  readOnly,
  user,
  artistId,
  updateAdjustmentDetail,
  adjustmentDetailList,
  setIsOpenStartDatePicker,
  savingFinish,
  updateCell,
  savingStart,
  showAddAdjustmentTypeModal,
  handleDeleteFile,
  openFile,
  showDeleteModal,
  onClickViewSettlementDetail,
}) => {
  return [
    {
      accessorKey: 'date', // simple recommended way to define a column
      header: '날짜',
      muiTableHeadCellProps: {
        sx: {
          width: '110px',
          minWidth: '110px',
          flex: '0 0 auto',
        },
      }, // custom props
      muiTableBodyCellProps: {
        sx: {
          width: '110px',
          minWidth: '110px',
          flex: '0 0 auto',
        },
      },
      Cell: ({ cell }) => {
        const _isCarryOver = cell.row.original.isCarryOver
        const _date = cell.getValue()

        if (readOnly || _isCarryOver) {
          return (
            <Text>
              {_date ? moment(_date, 'YYYYMMDD').format('YYYY-MM-DD') : null}
            </Text>
          )
        }
        return (
          <>
            {
              !adjustmentStore?.writingAdjustment?.adjustmentStartDate ? (
                <DatePicker
                  open={false}
                  onClick={
                    () => {
                      alert('정산 시작일을 먼저 선택해주세요.')
                      setIsOpenStartDatePicker(true)
                    }
                  }
                  placeholder="날짜 선택"
                  format="YYYY-MM-DD"
                  value={_date ? moment(_date, 'YYYYMMDD') : null}
                  suffixIcon={<div style={{ display: 'none' }} />}
                  placement={'topLeft'}
                />
              ) : (
                <DatePicker
                  placement={'topLeft'}
                  value={_date ? moment(_date, 'YYYYMMDD') : null}
                  suffixIcon={<div style={{ display: 'none' }} />}
                  onChange={
                    date => {
                      const update = date ? date.format('YYYYMMDD') : null
                      updateCell({ cell, update })
                    }
                  }
                  onCalendarChange={
                    date => {
                      console.log('onCalendarChange', date)
                    }
                  }
                  onBlur={
                    () => {
                      savingStart()
                      updateAdjustmentDetail(cell.row.index)
                      savingFinish()
                    }
                  }
                  placeholder="날짜 선택"
                  allowClear
                  format="YYYY-MM-DD"
                // inputReadOnly
                />
              )
            }
          </>
        )
      },
    },
    {
      accessorKey: 'type', // simple recommended way to define a column
      header: '정산 구분',
      muiTableHeadCellProps: {
        sx: {
          width: '120px',
          minWidth: '120px',
          flex: '0 0 auto',
        },
      }, // custom props
      muiTableBodyCellProps: {
        sx: {
          width: '120px',
          minWidth: '120px',
          flex: '0 0 auto',
        },
      },
      Cell: ({ cell }) => {
        const _isCarryOver = cell.row.original.isCarryOver
        const label = cell.getValue() === null ? '구분 없음' : cell.getValue()
        const totalAdjustmentTypes = [
          ...(allAdjustmentTypeList
            ? allAdjustmentTypeList
              .filter(item => item.type !== null)
              .map(item => ({
                value: item.type,
                label: item.type,
              }))
            : []),
          { value: '+ 추가하기', label: '+ 추가하기' },
        ]

        if (readOnly || _isCarryOver) {
          return <EllipsisText>{label}</EllipsisText>
        }
        return (
          <Select
            value={label}
            onChange={
              option => {
                if (option === '+ 추가하기') {
                  showAddAdjustmentTypeModal({
                    adjustmentDetailId: adjustmentDetailList[cell.row.index]._id,
                  })
                }
                else {
                  updateCell({ cell, update: option })
                }
              }
            }
            onBlur={
              () => {
                savingStart()
                updateAdjustmentDetail(cell.row.index)
                savingFinish()
              }
            }
            style={{ maxWidth: '112px', minWidth: '112px' }}
            options={totalAdjustmentTypes}
            showSearch
          />
        )
      },
    },
    {
      accessorKey: 'description', // simple recommended way to define a column
      header: '내용',
      muiTableHeadCellProps: {
        sx: { flex: '1 0 auto' },
      }, // custom props
      muiTableBodyCellProps: {
        sx: { flex: '1 0 auto', whiteSpace: 'break-word' },
      },
      Cell: ({ cell }) => {
        const _isCarryOver = cell.row.original.isCarryOver

        if (readOnly || _isCarryOver) {
          return (
            <Flex>
              {_isCarryOver && <CarryMark />}
              <Text>{cell.getValue()}</Text>
            </Flex>
          )
        }
        return (
          <NoneResizableTextArea
            readOnly={readOnly}
            autoSize
            value={cell.getValue()}
            onChange={
              e => {
                const update = e.target.value
                updateCell({ cell, update })
              }
            }
            onBlur={
              () => {
                savingStart()
                updateAdjustmentDetail(cell.row.index)
                savingFinish()
              }
            }
          />
        )
      },
    },
    {
      accessorKey: 'tag', // simple recommended way to define a column
      header: '수입/지출',
      muiTableHeadCellProps: {
        sx: {
          width: '110px',
          minWidth: '110px',
          flex: '0 0 auto',
        },
      }, // custom props
      muiTableBodyCellProps: {
        sx: {
          width: '110px',
          minWidth: '110px',
          flex: '0 0 auto',
        },
      },
      Cell: ({ cell }) => {
        const _isCarryOver = cell.row.original.isCarryOver

        if (readOnly || _isCarryOver)
          return (
            <Text color={cell.getValue() === '지출' ? '#F07E87' : '#0FC129'}>
              {cell.getValue()}
            </Text>
          )
        return (
          <Select
            {...(readOnly ? { open: false } : {})}
            value={inoutOptions[cell.getValue()]}
            onChange={
              option => {
                updateCell({ cell, update: option })
              }
            }
            onBlur={
              async () => {
                savingStart()
                await updateAdjustmentDetail(cell.row.index)
                adjustmentStore.fetchWritingAdjustment({
                  artistId: currentArtistId,
                  companyId: user.companyId,
                })
                savingFinish()
              }
            }
            style={
              {
                width: 102,
                color: cell.getValue() === '지출' ? '#F07E87' : '#0FC129',
              }
            }
            options={
              [
                { value: '수입', label: '수입' },
                { value: '지출', label: '지출' },
              ]
            }
          />
        )
      },
      enableEditing: false,
    },
    {
      accessorKey: 'amountAbs', // simple recommended way to define a column
      header: '금액',
      muiTableHeadCellProps: {
        sx: {
          width: '110px',
          minWidth: '110px',
          flex: '0 0 auto',
        },
      }, // custom props
      muiTableBodyCellProps: {
        sx: {
          width: '110px',
          minWidth: '110px',
          flex: '0 0 auto',
        },
      },
      Cell: ({ cell }) => {
        const _isCarryOver = cell.row.original.isCarryOver

        if (readOnly || _isCarryOver) {
          return <Text>{numberWithCommas(cell.getValue())}</Text>
        }
        return (
          <Input
            readOnly={readOnly}
            value={numberWithCommas(cell.getValue())}
            onChange={
              e => {
                const val = e.target.value.replace(/,/g, '')
                // const reg = /^[0-9]*$/
                const number = val.replace(/[^0-9]/g, '')
                updateCell({ cell, update: number })
              }
            }
            onKeyPress={
              e => {
                if (!/[0-9]/.test(e.key)) {
                // 숫자가 아니면 입력 방지
                  e.preventDefault()
                }
              }
            }
            onBlur={
              async () => {
                savingStart()
                await updateAdjustmentDetail(cell.row.index)
                adjustmentStore.fetchWritingAdjustment({
                  artistId: currentArtistId,
                  companyId: user.companyId,
                })
                savingFinish()
              }
            }
          />
        )
      },
    },
    {
      accessorKey: 'fileList', // simple recommended way to define a column
      header: '증빙',
      muiTableHeadCellProps: {
        sx: {
          width: '130px',
          minWidth: '130px',
          flex: '0 0 auto',
        },
      }, // custom props
      muiTableBodyCellProps: {
        sx: {
          width: '130px',
          minWidth: '130px',
          flex: '0 0 auto',
        },
      },
      Cell: ({ cell }) => {
        const _isCarryOver = cell.row.original.isCarryOver
        const _carryOverAdjustmentId = cell.row.original.carryOverAdjustmentId
        const { settlementInfo } = cell.row.original

        if (settlementInfo) {
          return (
            <Button
              onClick={() => onClickViewSettlementDetail(settlementInfo.month)}
              // to={``}
              type="link"
            >
              <Text>정산 세부내역</Text>
            </Button>
          )
        }

        if (_isCarryOver) {
          if (artistId && _carryOverAdjustmentId) {
            return (
              <Link
                to={`statement-list/closed?artistId=${artistId}&adjustmentId=${_carryOverAdjustmentId}`}
              >
                <Text>마감 정산서 확인</Text>
              </Link>
            )
          }
        }

        const props = {
          name: 'file',
          beforeUpload: async file => {
            try {
              savingStart()
              const presignedPostData = await adjustmentDetailStore.uploadAdjustmentEvidenceFile(
                file,
              )
              // cell 업데이트
              const cellFiles =
                adjustmentDetailStore.adjustmentDetailList[cell.row.index][
                  cell.column.id
                ]

              const url = `${presignedPostData.url}${presignedPostData.fields.key}`

              // data의 fileList에 파일 이름, pre-signed url 추가하기
              updateCell({
                cell,
                update: [...cellFiles, { name: file.name, path: url }],
              })
              await updateAdjustmentDetail(cell.row.index).then(() =>
                savingFinish(),
              )
            }
            catch (error) {
              console.error(error)
              message.error(`${file.name} 업로드 실패.`)
            }
            return false // Prevent antd's default upload handling
          },
        }

        return (
          <FileListContainer>
            <FileListDiv
              $isEmpty={
                adjustmentDetailStore?.adjustmentDetailList?.[cell.row.index][
                  cell.column.id
                ]?.length === 0
              }
            >
              {
toJS(
  adjustmentDetailStore.adjustmentDetailList[cell.row.index][
    cell.column.id
  ],
)?.map((file, index) => (
  <FileListItemDiv key={index}>
    {
      !readOnly && (
        <DeleteFileBtn
          onClick={() => handleDeleteFile(cell, index)}
        >
          <MinusCircleOutlined />
        </DeleteFileBtn>
      )
    }
    <FileNameDiv
      role="presentation"
      onClick={() => openFile(file)}
      title={file.name}
    >
      {`증빙 자료 ${index + 1}`}
    </FileNameDiv>
  </FileListItemDiv>
))
              }
            </FileListDiv>
            {
              !readOnly && (
                <FileUpload {...props} showUploadList={false}>
                  <PlusBtn>+</PlusBtn>
                </FileUpload>
              )
            }
          </FileListContainer>
        )
      },
    },
    {
      accessorKey: 'delete',
      header: '',
      muiTableHeadCellProps: {
        sx: {
          width: readOnly ? '0' : '30px',
          minWidth: readOnly ? '0' : '30px',
          flex: '0 0 auto',
          ...(readOnly && { display: 'none' }),
        },
      },
      muiTableBodyCellProps: {
        sx: {
          width: readOnly ? '0' : '30px',
          minWidth: readOnly ? '0' : '30px',
          flex: '0 0 auto',
          ...(readOnly && { display: 'none' }),
        },
      },
      enableSorting: false,
      Cell: ({ cell }) => {
        const _isCarryOver = cell.row.original.isCarryOver

        if (_isCarryOver) {
          return null
        }

        if (!readOnly) {
          return (
            <Button
              type="link"
              onClick={
                () => {
                  if (readOnly) return
                  if (adjustmentDetailStore.adjustmentDetailList.length === 1) {
                    alert('항목이 1개만 남은 경우 삭제할 수 없습니다.')
                    return
                  }
                  showDeleteModal(cell)
                }
              }
              icon={<DeleteOutlined style={{ color: '#d9d9d9' }} />}
            />
          )
        }
      },
    },
  ]
}
