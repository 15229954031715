import React from 'react'
import { Link } from 'react-router-dom'
import { Flex, Text } from '@components/atoms'
import { LogoImg, RoundBtn } from '@components/molecules'

const NotFoundPage = () => {
  return (
    <Flex
      type="column"
      style={{
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        paddingTop: '40px',
      }}
    >
      <LogoImg isLarge />
      <Text style={{ marginTop: '250px', fontWeight: 'bold' }} size="30px">
        Not Found 404 :
      </Text>
      <Text>찾을 수 없는 페이지입니다.</Text>
      <Text>페이지 주소를 다시 한번 확인해 주세요.</Text>
      <Link to="/">
        <RoundBtn
          text={'홈으로 이동'}
          style={{
            marginTop: '40px',
            backgroundColor: 'black',
          }}
          textColor="white"
        />
      </Link>
    </Flex>
  )
}

export default NotFoundPage
