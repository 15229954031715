import { action, observable, } from 'mobx'
import { Store } from '.'
import { Network } from './networks'

import { QuestionModel, } from './models'

export default class QuestionStore {
  @observable questionDetail = null
  @observable questionList = []

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.questionList = []
    this.questionDetail = null
  }

  @action.bound
  async initClient() {
  }

  async fetchQuestionList(params) {
    return this.store.useLoading(async () => {
      const fetchedDataList = await this.network.questionNetwork.getList({ __order: 'createdAtDesc', ...params, })
      if (!fetchedDataList) return

      this.questionList = fetchedDataList.map(fortune => new QuestionModel(this.store, fortune))
      return this.questionList
    })
  }

  async updateQuestion(id, index, data) {
    return this.store.useLoading(async () => {
      if (!id || isNaN(parseInt(index)) || !data) return

      const updatedData = await this.network.questionNetwork.put(id, data)
      if (!updatedData) return

      this.questionList[index] = new QuestionModel(this.store, updatedData)
      return this.questionList[index]
    })
  }

  async createQuestion(data) {
    return this.store.useLoading(async () => {
      const createdData = await this.network.questionNetwork.post(data)
      if (!createdData) return

      const question = new QuestionModel(this.store, createdData)
      this.questionList.splice(0, 0, question)
      return question
    })
  }

  async deleteQuestion(id, index) {
    return this.store.useLoading(async () => {
      if (!id || isNaN(parseInt(index))) return

      const deletedQuestion = await this.network.questionNetwork.delete(id)
      if (!deletedQuestion) return

      this.questionList.splice(index, 1)
      return deletedQuestion
    })
  }

}