import React from 'react'
import { Flex, Caption1 } from '@components/atoms'
import styled from 'styled-components'
import { colors } from '@colors/'

const melon_unactive_img = require('@images/melon_btn_gray.png')
const melon_active_img = require('@images/melon_btn_plam.png')

const BtnImg = styled.img`
  width: 88px;
  height: 88px;
  margin-bottom: 20px;
`

export const ToggleMelonBtn = ({ active, onClick }) => {
  return (
    <Flex type="column" style={{ cursor: 'pointer' }} onClick={onClick}>
      <BtnImg src={active ? melon_active_img : melon_unactive_img} />
      <Caption1 color={active ? colors.main : '#949494'}>
        멜론 링크로 등록
      </Caption1>
    </Flex>
  )
}
