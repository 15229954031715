import React from 'react'
import styled from 'styled-components'
import { Button } from '@components/atoms'

const Btn = styled(Button)`
width: 138px;
height: 40px;
border-radius: 6px;
border: solid 1px #949494;
background-color: #ffffff;
color : #949494;
justify-content : center;
align-items : center;
font-size:14px;
`

export const AddBtn = ({onClick, style}) => {
    return (
        <Btn type="button" onClick={onClick} style={style && style}>추가</Btn>
    )
}