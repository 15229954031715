import React from 'react'
import { Table } from 'antd'

const columns = [
  { title: '유입경로', key: '유입경로', dataIndex: 'fromSite', width: '137px' },
  {
    title: '유입수',
    key: '유입수',
    dataIndex: 'totalFromCount',
    width: '135px',
    sorter: (a, b) =>
      a._isDisabledSort || b._isDisabledSort
        ? 0
        : a.totalFromCount - b.totalFromCount,
  },
  {
    title: '전환수',
    key: '전환수',
    dataIndex: 'totalClickCount',
    width: '135px',
    sorter: (a, b) =>
      a._isDisabledSort || b._isDisabledSort
        ? 0
        : a.totalClickCount - b.totalClickCount,
  },
  {
    title: 'CTR(전환율)',
    key: '전환율',
    dataIndex: 'totalClickCount',
    width: '135px',
    render: (text, record) => {
      return `${Math.floor(
        (record.totalClickCount / record.totalFromCount) * 100,
      )}%`
    },
    sorter: (a, b) =>
      a._isDisabledSort || b._isDisabledSort
        ? 0
        : Math.floor((a.totalClickCount / a.totalFromCount) * 100) -
          Math.floor((b.totalClickCount / b.totalFromCount) * 100),
    defaultSortOrder: 'descend',
  },
]

export const InflowTableChart = ({ data, sumInflow }) => {
  return (
    <Table
      className="inflow_table"
      columns={columns}
      dataSource={[
        ...data,
        {
          fromSite: '합계',
          totalFromCount: sumInflow.sumFromCount,
          totalClickCount: sumInflow.sumClickCount,
          _isDisabledSort: true,
        },
      ]}
      pagination={false}
      size={'small'}
      style={{
        width: '542px',
        height: 'fit-content',
        border: 'solid 1px #dddddd',
        borderRadius: '6px',
      }}
      rowKey="fromSite"
      // summary={(pageData) => {
      //   let totalTotalFromCount = 0
      //   let totalTotalClickCount = 0

      //   pageData.forEach(({ totalFromCount, totalClickCount }) => {
      //     totalTotalFromCount += totalFromCount
      //     totalTotalClickCount += totalClickCount
      //   })
      // }}
    />
  )
}
