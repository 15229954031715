import React, { useMemo, useState } from 'react'
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  // Bar,
  Line,
  ResponsiveContainer,
} from 'recharts'
import { CheckTextBox } from '@components/molecules'
import { Caption2, Flex } from '@components/atoms'

import styled from 'styled-components'
import { convertToPricingComma } from '@utils/format'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import moment from 'moment'

const NoData = styled(Flex)`
  width: 1054px;
  height: 500px;
  justify-content: center;
  align-items: center;
`

const COLORS = ['#fcc302', '#ea4653', '#66bc6a', '#4e5cff']

const ArtistRevenueStatsChart = ({ currentYear, data, expectedData }) => {
  const [filterMedia, setFilterMedia] = useState('')

  const { revenueStore } = useStore()

  const { artistRecentMonth } = revenueStore

  const isRecentYear =
    `${artistRecentMonth}` === moment(currentYear).format('YYMM')

  const parseExpectedData = useMemo(() => {
    let parseData = []

    parseData = expectedData.map(item => ({
      ...item,
      monthlyAdjacencyFee: item.adjacencyFee,
      monthlyCopyRightFee: item.copyRightFee,
      monthlyPerformanceRightFee: item.performanceRightFee,
      salesMonth: item.month,
    }))

    return parseData
  }, [expectedData])

  return (
    <Flex>
      {data?.length === 0 && parseExpectedData?.length === 0 ? (
        <NoData>No data</NoData>
      ) : (
        <ResponsiveContainer
          width={data?.length === 0 ? '90%' : '80%'}
          height={500}
        >
          <ComposedChart
            // width={1054}
            // height={500}

            margin={{
              top: 50,
              right: 0,
              bottom: 50,
              left: data?.length === 0 ? 100 : 50,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="salesMonth"
              tickFormatter={tick => {
                const strTick = `${tick}`
                return `${strTick[2]}${strTick[3]}월`
              }}
              allowDuplicatedCategory={false}
            />
            <YAxis
              domain={['auto', 'auto']}
              unit="원"
              tickFormatter={e => convertToPricingComma(e)}
            />
            <Tooltip
              content={props => (
                <CustomTooltip {...props} filterMedia={filterMedia} />
              )}
            />
            <Line
              data={data}
              stroke={COLORS[0]}
              name="총 수익"
              type="monotone"
              dataKey={`totalRevenue`}
              hide={!(filterMedia === '' || filterMedia === 'totalRevenue')}
            />
            <Line
              data={data}
              stroke={COLORS[1]}
              name="인접권"
              dataKey="monthlyAdjacencyFee"
              hide={!(filterMedia === 'adjacencyFee' || filterMedia === '')}
            />
            <Line
              data={data}
              stroke={COLORS[2]}
              name="저작권"
              type="monotone"
              dataKey={
                filterMedia !== ''
                  ? `monthlyCopyRightFee`
                  : `monthlyCopyRightFee`
              }
              hide={!(filterMedia === '' || filterMedia === 'copyRightFee')}
            />
            <Line
              data={data}
              stroke={COLORS[3]}
              name="실연권"
              dataKey="monthlyPerformanceRightFee"
              hide={
                !(filterMedia === 'performanceRightFee' || filterMedia === '')
              }
            />
            {/* 예상 */}
            {isRecentYear && [
              <Line
                data={parseExpectedData}
                stroke={COLORS[0]}
                name="예상 총 수익"
                type="monotone"
                dataKey={`totalRevenue`}
                hide={!(filterMedia === '' || filterMedia === 'totalRevenue')}
                strokeDasharray="5 5"
              />,
              <Line
                data={parseExpectedData}
                stroke={COLORS[1]}
                name="예상 인접권"
                dataKey="monthlyAdjacencyFee"
                hide={!(filterMedia === 'adjacencyFee' || filterMedia === '')}
                strokeDasharray="5 5"
              />,
              <Line
                data={parseExpectedData}
                stroke={COLORS[2]}
                name="예상 저작권"
                type="monotone"
                dataKey={
                  filterMedia !== ''
                    ? `monthlyCopyRightFee`
                    : `monthlyCopyRightFee`
                }
                hide={!(filterMedia === '' || filterMedia === 'copyRightFee')}
                strokeDasharray="5 5"
                color={COLORS[2]}
              />,
              <Line
                data={parseExpectedData}
                stroke={COLORS[3]}
                name="예상 실연권"
                dataKey="monthlyPerformanceRightFee"
                hide={
                  !(filterMedia === 'performanceRightFee' || filterMedia === '')
                }
                strokeDasharray="5 5"
              />,
            ]}
          </ComposedChart>
        </ResponsiveContainer>
      )}
      {data?.length > 0 && (
        <Flex
          type="column"
          style={{
            margin: '0 0 50px 120px',
            width: 'auto',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          {/* <H6>유입 매체</H6> */}
          <CheckTextBox
            checked={filterMedia === '' ? true : false}
            onClick={() => setFilterMedia('')}
            text="전체"
          />
          <CheckTextBox
            checked={filterMedia === 'totalRevenue' ? true : false}
            onClick={() => setFilterMedia('totalRevenue')}
            text="총수익"
          />
          <CheckTextBox
            checked={filterMedia === 'adjacencyFee' ? true : false}
            onClick={() => setFilterMedia('adjacencyFee')}
            text="인접권 수익"
          />
          <CheckTextBox
            checked={filterMedia === 'copyRightFee' ? true : false}
            onClick={() => setFilterMedia('copyRightFee')}
            text="저작권 수익"
          />
          <CheckTextBox
            checked={filterMedia === 'performanceRightFee' ? true : false}
            onClick={() => setFilterMedia('performanceRightFee')}
            text="실연권 수익"
          />
        </Flex>
      )}
    </Flex>
  )
}

export default observer(ArtistRevenueStatsChart)

const ToolTipBox = styled(Flex)`
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;

  p {margin: 5px 0;}
  p:first-child {margin 0;}
`

const CustomTooltip = ({ active, payload, label, filterMedia }) => {
  const item = payload && payload[0] && payload[0].payload && payload[0].payload

  const strTick = `${label}`
  const customLabel = `${strTick[0]}${strTick[1]}년 ${strTick[2]}${strTick[3]}월`
  const isExpectating = !!item?.month
  const isPendingAggregation = isExpectating && `${item?.month}` === moment().format('YYMM')

  if (active) {
    return (
      <ToolTipBox type="column">
        <Caption2 type="Bold" align="left">
          <span style={{display:'flex', justifyContent: 'space-between'}}>
            {`${customLabel} ${isExpectating ? '예상' : ''} 수익`}
            {isPendingAggregation && <Caption2 type="Regular" align="left" color="#949494" margin="0px">{`당월 집계중`}</Caption2>}
          </span>
        </Caption2>
        {(filterMedia === '' || filterMedia === 'totalRevenue') && (
          <Caption2 align="left">
            <div
              style={{
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: COLORS[0],
                display: 'inline-block',
                marginRight: 8,
              }}
            />
            총 수익 :
            {`${item &&
              convertToPricingComma(Math.floor(item.totalRevenue))}원`}
          </Caption2>
        )}
        {(filterMedia === 'totalRevenue' ||
          filterMedia === 'adjacencyFee' ||
          filterMedia === '') && (
          <Caption2 align="left">
            <div
              style={{
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: COLORS[1],
                display: 'inline-block',
                marginRight: 8,
              }}
            />
            인접권 :
            {`${item &&
              convertToPricingComma(Math.floor(item.monthlyAdjacencyFee))}원`}
          </Caption2>
        )}
        {(filterMedia === 'totalRevenue' ||
          filterMedia === 'copyRightFee' ||
          filterMedia === '') && (
          <Caption2 align="left">
            <div
              style={{
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: COLORS[2],
                display: 'inline-block',
                marginRight: 8,
              }}
            />
            저작권 :
            {`${item &&
              convertToPricingComma(Math.floor(item.monthlyCopyRightFee))}원`}
          </Caption2>
        )}
        {(filterMedia === 'totalRevenue' ||
          filterMedia === 'performanceRightFee' ||
          filterMedia === '') && (
          <Caption2 align="left">
            <div
              style={{
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: COLORS[3],
                display: 'inline-block',
                marginRight: 8,
              }}
            />
            실연권 :
            {`${item &&
              convertToPricingComma(
                Math.floor(item.monthlyPerformanceRightFee),
              )}원`}
          </Caption2>
        )}
        {isExpectating && (
          <Caption2 align="left" color="#949494">
            *과거 정산 자료를 등록하시면
            <br />더 정확한 예상 수익을 측정할 수 있습니다.
          </Caption2>
        )}
      </ToolTipBox>
    )
  }

  return null
}
