
const asyncSequence = {}
export const wrapAsyncSequence = async ({
  functionKey = 'default',
  currentKey,
  promise,
}) => {
  if (!asyncSequence[functionKey]) {
    asyncSequence[functionKey] = {
      currentKey: '',
    }
  }
  const _asyncSequence = asyncSequence[functionKey]

  _asyncSequence['currentKey'] = currentKey
  const result = await promise
  return {
    isCanceled: _asyncSequence['currentKey'] !== currentKey,
    result: result,
  }
}
