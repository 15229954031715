import React from 'react'
import { Table } from 'antd'
import { convertToPricingComma } from '@utils/format'
import styled from 'styled-components'
import { Flex } from '@components/atoms'

const NoData = styled(Flex)`
  width: 544px;
  height: 360px;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  border: 1px solid #dddddd;
  border-radius: 6px;
  margin: 20px 0;
`

const RevenueAdjacencyInflowTableChart = ({ data }) => {
  const sumHitCount = data.reduce((prev, curr) => prev + curr.annualHitCount, 0)
  const sumtotalRevenue = data.reduce(
    (prev, curr) => prev + curr.totalRevenue,
    0,
  )

  if (data?.length === 0) {
    return <NoData>데이터가 없습니다.</NoData>
  }
  return (
    <Table
      className="inflow_table"
      columns={[
        { title: '유형', key: '유형', dataIndex: 'hitType', width: '137px' },
        {
          title: '활용수',
          key: '활용수',
          dataIndex: 'annualHitCount',
          width: '135px',
          sorter: (a, b) =>
            a._isDisabledSort || b._isDisabledSort
              ? 0
              : a.annualHitCount - b.annualHitCount,
          render: text => `${convertToPricingComma(Math.floor(text))}`,
        },
        {
          title: '수익',
          key: '수익',
          dataIndex: 'totalRevenue',
          width: '135px',
          sorter: (a, b) =>
            a._isDisabledSort || b._isDisabledSort
              ? 0
              : a.totalRevenue - b.totalRevenue,
          render: text => `${convertToPricingComma(Math.floor(text))}원`,
        },
        {
          title: '비율',
          key: '비율',
          dataIndex: 'annualAdjacencyFee',
          width: '135px',
          sorter: (a, b) =>
            a._isDisabledSort || b._isDisabledSort
              ? 0
              : a.totalRevenue - b.totalRevenue,
          render: (text, record) =>
            `${Math.floor((record.totalRevenue / sumtotalRevenue) * 100)}%`,
        },
      ]}
      dataSource={[
        ...data,
        {
          hitType: '합계',
          annualHitCount: sumHitCount,
          totalRevenue: sumtotalRevenue,
          rate: Math.floor((sumtotalRevenue / sumtotalRevenue) * 100),
          _isDisabledSort: true,
        },
      ]}
      pagination={false}
      size={'small'}
      style={{
        width: '542px',
        height: 'fit-content',
        border: 'solid 1px #dddddd',
        borderRadius: '6px',
      }}
      rowKey="hitType"
    />
  )
}

export default RevenueAdjacencyInflowTableChart
