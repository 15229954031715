import React from 'react'
import styled from 'styled-components'
import { Flex, Text, Button, Image } from '@components/atoms'

import { firstAlbumPageLogoImg } from '@images'

const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 124px;
`
const EditArtistButton = styled(Button)`
  width: 230px;
  height: 48px;
  background-color: #242424;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  color: #f4f4f4;
  font-family: NotoSansCJKkr-Medium;

  :hover {
    background-color: #d4d4d4;
  }
`

export const NoAlbumList = ({ typeToggle }) => {
  return (
    <Container>
      <Image
        src={firstAlbumPageLogoImg}
        width="188px"
        height="172px"
        alt="first_logo"
      />
      <Text style={{ margin: '24px 0 28px', textAlign: 'center' }}>
        추가된 앨범이 없습니다.
        <br />
        앨범을 추가하면 <Text type="bold">공유 링크가 생성</Text>됩니다.
      </Text>
      <EditArtistButton onClick={() => typeToggle()}>
        앨범 추가
      </EditArtistButton>
    </Container>
  )
}
