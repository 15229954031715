import React, { useRef, useState } from 'react'
import { Caption2, Flex, H4, H5, Image } from '@components/atoms'

import styled, { css } from 'styled-components'
import { convertToPricingComma, dateYMD } from '@utils/format'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { Button, Input, Space, Table } from 'antd'
import CountUp from 'react-countup'
import { useHistory } from 'react-router-dom'
import { isArray } from 'lodash'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import CopyToClipboard from 'react-copy-to-clipboard'
import { STORAGE_URL } from '@consts'
import { arrowRight } from '@images/index'

const copy_btn_img = require('@images/copy_img_gray.png')

const ArtistSettlementMonthlyChart = ({
  currentMonth,
  displayMonth,
  filterCompanyId,
  onFilterCompany,
}) => {
  const history = useHistory()
  const { settlementStore } = useStore()

  const { artistMonthlyData } = settlementStore

  const settlementInfo = artistMonthlyData?.settlementInfo

  const artistAlbumMonthlySettlementList =
    settlementInfo?.artistAlbumMonthlySettlementList || []

  const artistTrackMonthlySettlementList =
    settlementInfo?.artistTrackMonthlySettlementList || []

  const artistMonthlySettlementInfo =
    settlementInfo?.artistMonthlySettlementList[0] || null

  const artistCompanyMonthlySettlementList =
    settlementInfo?.artistCompanyMonthlySettlementList || []

  // 앨범검색 기능
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={
            e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (isArray(dataIndex)) {
        return record[dataIndex[0]][dataIndex[1]]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }
  // 검색 기능 end

  // 앨범검색 기능
  const [searchTrackText, setSearchTrackText] = useState('')
  const [searchedTrackColumn, setSearchedTrackColumn] = useState('')
  const searchTrackInput = useRef(null)
  const getTrackColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchTrackInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={
            e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={
            () =>
              handleTrackSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleTrackSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleTrackReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (isArray(dataIndex)) {
        if (dataIndex.length > 2) {
          return record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        }
        return record[dataIndex[0]][dataIndex[1]]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select())
      }
    },
    render: text =>
      searchedTrackColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchTrackText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  })
  const handleTrackSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchTrackText(selectedKeys[0])
    setSearchedTrackColumn(dataIndex)
  }
  const handleTrackReset = clearFilters => {
    clearFilters()
    setSearchTrackText('')
  }
  // 검색 기능 end

  const sortHandler = (a, b, sortOrder) => {
    if (a < b) {
      return -1
    }
    else if (a > b) {
      return 1
    }
    else {
      return 0
    }
  }

  return (
    <Flex type="column">
      <Flex style={{ margin: '20px 0 30px' }}>
        <InfoCard type={'column'}>
          <Flex align={'center'}>
            <H5>
              {
                `${displayMonth} ${
                  filterCompanyId
                && artistCompanyMonthlySettlementList?.length > 0
                    ? artistCompanyMonthlySettlementList?.[0]?.companyInfo?.name
                    : '총'
                } 정산금액은`
              }
            </H5>
            <H4 type="bold">
              &nbsp;
              {
artistMonthlySettlementInfo?.artistMonthlySettlementFee && (
                  <CountUp
                    start={0}
                    end={
                      Math.floor(
                    artistMonthlySettlementInfo?.artistMonthlySettlementFee,
                      )
                    }
                    separator=","
                    duration={2}
                  />
                )
              }
              원 &nbsp;
            </H4>
            <H5>입니다.</H5>
          </Flex>
          {
            !!artistCompanyMonthlySettlementList && !filterCompanyId && (
              <DivideLine />
            )
          }
          <Flex style={{ gap: '12px' }}>
            {
              !!artistCompanyMonthlySettlementList
              && !filterCompanyId
              && artistCompanyMonthlySettlementList?.map(val => {
                return (
                  <CompanyBtn
                    key={val?.companyId}
                    onClick={
                      () => {
                        history.push(
                          `/insight/settlement/month?date=${currentMonth.format(
                            'YYMM',
                          )}&companyId=${val?.companyId}`,
                        )
                      }
                    }
                    active={
                      !!filterCompanyId && filterCompanyId === val?.companyId
                    }
                  >
                    {
                      `${val?.companyInfo?.name} ${convertToPricingComma(
                        Math.floor(val?.artistMonthlySettlementFee),
                      )}원`
                    }
                    <Image
                      src={arrowRight}
                      width={12}
                      height={12}
                      style={{ marginLeft: '2px' }}
                    />
                  </CompanyBtn>
                )
              })
            }
          </Flex>
        </InfoCard>
      </Flex>
      <H5 type="bold" align="left" style={{ margin: '50px 0 20px' }}>
        앨범별 정산 세부내역
      </H5>
      <Flex>
        <TableBox>
          <Table
            pagination={false}
            rowKey="_id"
            dataSource={artistAlbumMonthlySettlementList || []}
            scroll={{ y: 700 }}
            onRow={
              record => {
                return {
                  onClick: () => {
                    history.push(
                      `/insight/settlement/album/${record.albumId}?date=${record.month}`,
                    )
                  },
                }
              }
            }
            columns={
              [
                {
                  title: '앨범명',
                  key: 'albumInfoTitle',
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(a?.albumInfo?.title, b?.albumInfo?.title)
                  },
                  dataIndex: ['albumInfo', 'title'],
                  ...getColumnSearchProps(['albumInfo', 'title']),
                  width: '30%',
                  align: 'left',
                  render: (text, record) => (
                    <Flex align="center" style={{ flex: 1 }}>
                      {
record?.albumInfo?.image64Path && (
                          <img
                            src={STORAGE_URL + record?.albumInfo?.image64Path}
                            alt="cover_img"
                            style={
                              {
                                width: '36px',
                                height: '36px',
                                borderRadius: '6px',
                                marginRight: '16px',
                              }
                            }
                          />
                        )
                      }
                      <Caption2
                        style={
                          {
                            display: 'inline-block',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            width: '100%',
                          }
                        }
                        align={'left'}
                      >
                        {text}
                      </Caption2>
                    </Flex>
                  ),
                },
                {
                  title: '발매 아티스트명',
                  key: 'albumInfoReleaseArtistNameList',
                  dataIndex: ['albumInfo', 'releaseArtistNameList'],
                  render: text => text.join(', '),
                  width: '20%',
                  align: 'left',
                },
                {
                  title: '발매일',
                  key: 'albumInfoTitle',
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                    a?.albumInfo?.releasedAt,
                    b?.albumInfo?.releasedAt,
                    )
                  },
                  dataIndex: ['albumInfo', 'releasedAt'],
                  render: text => {
                    return `${dateYMD(text)}`
                  },
                  // width: '200px',
                  align: 'center',
                },
                {
                  title: '유통사',
                  key: 'distributor',
                  dataIndex: 'distributor',
                  // width: '320px',
                  align: 'center',
                },
                {
                  title: '앨범 정산 수익',
                  key: 'albumMonthlySettlementFee',
                  dataIndex: 'albumMonthlySettlementFee',
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  // width: '300px',
                  align: 'center',
                },
                {
                  title: '아티스트 정산 수익',
                  key: 'artistMonthlySettlementFee',
                  dataIndex: 'artistMonthlySettlementFee',
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  // width: '300px',
                  align: 'center',
                },
                {
                  title: '앨범링크',
                  key: 'albumId',
                  dataIndex: 'albumId',
                  render: text => {
                    return (
                      <Flex justify={'center'}>
                        <div
                          onClick={
                            e => {
                              window.open(`https://plam.in/album/${text}`)
                              e.stopPropagation()
                            }
                          }
                          style={
                            {
                              width: '36px',
                              height: '36px',
                              padding: '6px',
                              backgroundColor: '#f4f4f4',
                              borderRadius: '50%',
                            }
                          }
                        >
                          <img
                            src={copy_btn_img}
                            alt="copy"
                            style={
                              {
                                width: '24px',
                                height: '24px',
                                cursor: 'pointer',
                              }
                            }
                          />
                        </div>
                      </Flex>
                    )
                  },
                  width: '7%',
                  align: 'center',
                },
              ]
            }
          />
        </TableBox>
      </Flex>
      <H5 type="bold" align="left" style={{ margin: '50px 0 20px' }}>
        트랙별 정산 세부내역
      </H5>
      <Flex>
        <TableBox>
          <Table
            pagination={false}
            rowKey="_id"
            dataSource={artistTrackMonthlySettlementList || []}
            scroll={{ y: 1400 }}
            onRow={
              record => {
                return {
                  onClick: () => {
                    history.push(`/insight/settlement/track/${record.trackId}`)
                  },
                }
              }
            }
            columns={
              [
                {
                  title: '트랙명',
                  key: 'trackInfoTitle',
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(a?.trackInfo?.title, b?.trackInfo?.title)
                  },
                  dataIndex: ['trackInfo', 'title'],
                  ...getTrackColumnSearchProps(['trackInfo', 'title']),
                  width: '25%',
                  align: 'left',
                  render: (text, record) => {
                    return (
                      <Flex align="center">
                        <img
                          src={
                            STORAGE_URL
                          + record?.trackInfo?.albumInfo?.image64Path
                          }
                          alt="cover_img"
                          style={
                            {
                              width: '36px',
                              height: '36px',
                              borderRadius: '6px',
                              marginRight: '16px',
                            }
                          }
                        />
                        <Caption2
                          style={
                            {
                              fontSize: 12,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              flex: 1,
                            }
                          }
                          align="left"
                        >
                          {text}
                        </Caption2>
                      </Flex>
                    )
                  },
                },
                {
                  title: '앨범명',
                  key: 'trackInfoAlbumInfoTitle',
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                    a?.trackInfo?.albumInfo?.title,
                    b?.trackInfo?.albumInfo?.title,
                    )
                  },
                  dataIndex: ['trackInfo', 'albumInfo', 'title'],
                  ...getTrackColumnSearchProps([
                    'trackInfo',
                    'albumInfo',
                    'title',
                  ]),
                  width: '15%',
                  align: 'left',
                },
                {
                  title: '발매 아티스트명',
                  key: 'trackInfoReleaseArtistNameList',
                  dataIndex: ['trackInfo', 'releaseArtistNameList'],
                  ...getTrackColumnSearchProps([
                    'trackInfo',
                    'releaseArtistNameList',
                  ]),
                  width: '12%',
                  align: 'left',
                },
                {
                  title: '발매일',
                  key: 'trackInfoAlbumInfoReleasedAt',
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                    a?.trackInfo?.albumInfo?.releasedAt,
                    b?.trackInfo?.albumInfo?.releasedAt,
                    )
                  },
                  dataIndex: ['trackInfo', 'albumInfo', 'releasedAt'],
                  render: text => {
                    return `${dateYMD(text)}`
                  },
                  // width: '200px',
                  align: 'center',
                },
                {
                  title: '유통사',
                  key: 'distributor',
                  dataIndex: 'distributor',
                  defaultSortOrder: { sortOrder: 'descend' },
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(a?.distributor, b?.distributor)
                  },
                  // width: '236px',
                  align: 'center',
                },

                {
                  title: '트랙 정산 수익',
                  key: 'trackMonthlySettlementFee',
                  dataIndex: 'trackMonthlySettlementFee',
                  defaultSortOrder: { sortOrder: 'descend' },
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                    a?.trackMonthlySettlementFee,
                    b?.trackMonthlySettlementFee,
                    )
                  },
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  // width: '212px',
                  align: 'center',
                },
                {
                  title: '인접권 정산 비율',
                  key: 'artistSettlementFeeRatio',
                  dataIndex: 'artistSettlementFeeRatio',
                  defaultSortOrder: { sortOrder: 'descend' },
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                    a?.artistSettlementFeeRatio,
                    b?.artistSettlementFeeRatio,
                    )
                  },
                  render: text =>
                    `${
                      typeof text === 'number'
                        ? `${(text * 100).toFixed(2)}%`
                        : '-'
                    }`,
                  // width: '120px',
                  align: 'center',
                },
                {
                  title: '아티스트 정산 수익',
                  key: 'artistMonthlySettlementFee',
                  dataIndex: 'artistMonthlySettlementFee',
                  defaultSortOrder: { sortOrder: 'descend' },
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                    a?.artistMonthlySettlementFee,
                    b?.artistMonthlySettlementFee,
                    )
                  },
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  // width: '212px',
                  align: 'center',
                },
                {
                  title: '트랙링크',
                  key: 'trackId',
                  dataIndex: 'trackId',
                  render: text => {
                    return (
                      <Flex justify={'center'}>
                        <div
                          onClick={
                            e => {
                              window.open(`https://plam.in/track/${text}`)
                              e.stopPropagation()
                            }
                          }
                          style={
                            {
                              width: '36px',
                              height: '36px',
                              padding: '6px',
                              backgroundColor: '#f4f4f4',
                              borderRadius: '50%',
                            }
                          }
                        >
                          <img
                            src={copy_btn_img}
                            alt="copy"
                            style={
                              {
                                width: '24px',
                                height: '24px',
                                cursor: 'pointer',
                              }
                            }
                          />
                        </div>
                      </Flex>
                    )
                  },
                  width: '5%',
                  align: 'center',
                },
              ]
            }
          />
        </TableBox>
      </Flex>
    </Flex>
  )
}

export default observer(ArtistSettlementMonthlyChart)

const InfoCard = styled(Flex)`
  flex: 1;
  /* width: 234px; */
  height: 167px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #fafafa;
  padding: 5px;
  margin: 0 50px 0 0;
  justify-content: center;
  align-items: center;
`

const TableBox = styled.div`
  width: 100%;

  table {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #ddd;
    background-color: #fcfcfc;
  }

  tr {
    cursor: pointer;
  }

  thead > tr > th {
    background-color: transparent;
    color: #646464;
  }

  thead > tr > th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5),
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(8) {
    text-align: center;
    min-width: 88px;
  }
`

const CompanyBtn = styled.button`
  cursor: pointer;
  flex: 1;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: max-content;

  padding: 0 10px 0 16px;

  border-radius: 18px;
  background-color: #ffffff;
  border: 0;

  font-family: NotoSansCJKKR-Regular;
  font-size: 14px;
  font-weight: normal;
  color: #646464;

  ${({ active }) =>
    active
      ? css`
          border: solid 1px #242424;
          color: #242424;
        `
      : css`
          border: solid 0px;
        `}}
`
const DivideLine = styled.div`
  width: 98%;
  height: 1px;
  margin: 20px 0 19px;

  padding: 0 10px 0 16px;

  background-color: #eaeaea;
  border: 0;
`
