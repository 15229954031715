import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'

const CustomSelect = styled(Select)`
  & > div {
    width: 100%;
    min-height: 40px;
    padding: 0 0.7rem;
    border: 1px solid #949494;
    border-radius: 7px;
    background-color: #ffffff;
    font-family: NotoSansCJKkr-Medium;
    white-space: nowrap;

    div:first-child {
      justify-content: center;
      color: #242424;
    }

    & div {
      padding: 0;
      margin: 0;
    }

    & span {
      display: none;
    }

    & svg {
      color: #242424;
    }
  }
`

export const ChartDivisionBox = props => {
  return (
    <CustomSelect
      styles={{
        control: (provided, state) => ({
          ...provided,
          boxShadow: 'none',
          height: '40px',
          '&:hover': {
            border: '1px solid #1982df',
            boxShadow: 'none',
            // 클릭 전 hover
          },
        }),
        menu: (provided, state) => ({
          ...provided,
          marginTop: '5px !important',
          border: 'none !important',
          borderRadius: '5px !important',
          boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.10)',
          // menu 틀
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? '#fff' : 'rgb(51, 51, 51)',
          padding: '8px !important',
          // option 내용물 커스텀
        }),
        placeholder: defaultStyles => {
          return {
            ...defaultStyles,
            color: props.placholderColor || 'rgba(148, 148, 148, 0.6)',
            top: '33%',
            // control 내용물 커스텀
          }
        },
      }}
      id={props.id}
      name={props.name}
      isSearchable={false}
      placeholder={props.placeholder}
      options={props.options}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      value={props.value || undefined}
    />
  )
}
