import React, { useState } from 'react'
import styled from 'styled-components'
import { H4, Flex, Button, Caption1 } from '@components/atoms'
import { CustomModal } from '@components/molecules'
import { colors } from '@colors/'
import AutocompleteArtist from './AutocompleteArtist'

const ModalBody = styled(Flex)`
  padding: 30px 45px;
  width: 490px;
  height: 286px;
  flex-direction: column;
  align-items: center;
`

const Btn = styled(Button)`
  margin-top: 12px;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 48px;
  border-radius: 25px;
  background-color: ${props => (props.disabled ? '#f4f4f4' : colors.main)};
  color: ${props => (props.disabled ? '#949494' : '#f4f4f4')};
  border: ${props => (props.disabled ? 'none' : colors.main)};
`

const View = ({
  isShowing,
  toggle,
  userArtistId,
  createConnectionsArtists,
}) => {
  const [artist, setArtist] = useState()

  const handleConfirm = async () => {
    const res = await createConnectionsArtists(artist.artistId, userArtistId)
    if (!res) return
    toggle()
  }

  return (
    <>
      <CustomModal.View
        isShowing={isShowing}
        toggle={toggle}
        modalStyle={{ overflow: 'visible' }}
      >
        <ModalBody type="column">
          <H4 type="Bold" style={{ marginTop: 30 }}>
            연결 유저 추가
          </H4>
          <div
            style={
              {
                width: 400,
                marginTop: 40,
                position: 'relative',
                minHeight: 63,
                maxHeight: 63,
              }
            }
          >
            <AutocompleteArtist
              onlyUniqueName
              placeholder="아티스트명 또는 아이디 입력"
              inputWidth={400}
              value={artist}
              setArtist={setArtist}
              index={1}
              isDisableRemove
              keyword={'artist'}
              onClickRemove={
                () =>
                  setArtist({
                    name: '',
                    uniqueName: '',
                    _id: '',
                  })
              }
            />
            {
              artist && artist.name && !artist.uniqueName && (
                <Caption1 style={{ alignSelf: 'flex-start', color: '#ea4653' }}>
                존재하지 않는 아티스트입니다.
                </Caption1>
              )
            }
          </div>
          <Btn
            disabled={!(artist && artist.uniqueName)}
            onClick={handleConfirm}
          >
            승인 요청
          </Btn>
        </ModalBody>
      </CustomModal.View>
    </>
  )
}

export default {
  View,
  useModal: CustomModal.useModal,
}
