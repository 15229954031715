import React, { useEffect, useRef, useState } from 'react'
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts'
import {
  Caption1,
  Caption2,
  Flex,
  H4,
  H5,
  Image,
  Text,
} from '@components/atoms'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { convertToPricingComma } from '@utils/format'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { Button, Input, Space, Table } from 'antd'
import moment from 'moment'
import { path_menu } from '@images/'
import { STORAGE_URL } from '@consts'
import CountUp from 'react-countup'
import Highlighter from 'react-highlight-words'
import { isArray } from 'lodash'
import { SearchOutlined } from '@ant-design/icons'
import MonthlyPicker from '../Card/MonthlyPicker'

const copy_btn_img = require('@images/copy_img_gray.png')

const NoData = styled(Flex)`
  width: 1054px;
  height: 500px;
  justify-content: center;
  align-items: center;
`

const CompanyArtistAlbumSettlementAnnualChart = () => {
  const { authStore, settlementStore, revenueStore } = useStore()
  const history = useHistory()

  const { currentUser } = authStore
  const {
    albumAnnualData,
    albumCurrentMonth,
    trackCurrentMonth,
  } = settlementStore
  const { companyRecentMonth } = revenueStore

  const settlementInfo = albumAnnualData?.settlementInfo

  const setAlbumCurrentMonth =
    settlementStore.setAlbumCurrentMonth || (() => {})
  const setTrackCurrentMonth =
    settlementStore.setTrackCurrentMonth || (() => {})
  const fetchSettlementCompanyArtistAnnual =
    settlementStore?.fetchSettlementCompanyArtistAnnual || (() => {})

  const handleYear = value => {
    setAlbumCurrentMonth(moment(value))
  }

  const handleMonth = value => {
    setTrackCurrentMonth(moment(value))
  }

  // 전체 월별 리스트
  const artistAlbumMonthlySettlementList =
    settlementInfo?.artistAlbumMonthlySettlementList?.sort(
      (a, b) => b.month - a.month,
    ) || []

  // 총 정산 세부 내역
  const artistTrackMonthlySettlementList =
    settlementInfo?.artistTrackMonthlySettlementList || []

  const artistAlbumRecentMonthlySettlementInfo = artistAlbumMonthlySettlementList.find(
    i => `${i.month}` === trackCurrentMonth?.format('YYMM'),
  )

  const displayYear = albumCurrentMonth?.format('YYYY년')
  const displayMonth = trackCurrentMonth?.format('YYYY년 MM월')

  useEffect(() => {
    if (companyRecentMonth) {
      setAlbumCurrentMonth(
        moment().set({
          year: `20${Math.floor(companyRecentMonth / 100)}`,
        }),
      )
    }
  }, [companyRecentMonth, setAlbumCurrentMonth])

  useEffect(() => {
    if (currentUser && albumCurrentMonth) {
      fetchSettlementCompanyArtistAnnual({
        companyObjectId: currentUser.companyId,
        artistObjectId: currentUser.artistId,
        __year: `${albumCurrentMonth?.format('YY')}00`,
      })
    }
  }, [currentUser, albumCurrentMonth, fetchSettlementCompanyArtistAnnual])

  // 앨범검색 기능
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={
            e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (isArray(dataIndex)) {
        return record[dataIndex[0]][dataIndex[1]]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }
  // 검색 기능 end

  const sortHandler = (a, b, sortOrder) => {
    if (a < b) {
      return -1
    }
    else if (a > b) {
      return 1
    }
    else {
      return 0
    }
  }

  return (
    <Flex type="column">
      <SectionTitle>
        <Image
          src={path_menu}
          alt="prev"
          width="18px"
          height="18px"
          transform="rotate(180deg)"
          cursor="pointer"
          onClick={() => handleYear(albumCurrentMonth.subtract(1, 'years'))}
        />
        {displayYear}
        <Image
          src={path_menu}
          alt="next"
          width="18px"
          height="18px"
          opacity={moment().isSameOrBefore(albumCurrentMonth, 'year') ? 0.5 : 1}
          cursor={
            moment().isSameOrBefore(albumCurrentMonth, 'year')
              ? 'not-allowed'
              : 'pointer'
          }
          onClick={
            () => {
              if (moment().isSameOrBefore(albumCurrentMonth, 'year')) return
              handleYear(albumCurrentMonth.add(1, 'years'))
            }
          }
        />
      </SectionTitle>
      <Flex style={{ margin: '20px 0 50px' }}>
        <InfoCard>
          <H5>{`${displayYear} 앨범의 총 정산금액은`}</H5>
          <H4 type="bold">
            &nbsp;
            {
              (settlementInfo?.companyAnnualSettlementFee
              || settlementInfo?.artistAnnualSettlementFee) && (
                <CountUp
                  start={0}
                  end={
                    Math.floor(settlementInfo?.companyAnnualSettlementFee || 0)
                  + Math.floor(settlementInfo?.artistAnnualSettlementFee || 0)
                  }
                  separator=","
                  duration={3}
                />
              )
            }
            원 &nbsp;
          </H4>
          {
            <H5 style={{ fontSize: '18px' }} type={'Regular'} color={'#646464'}>
              {
                `(사업자 ${convertToPricingComma(
                  Math.floor(settlementInfo?.companyAnnualSettlementFee || 0),
                )}원, 아티스트 ${convertToPricingComma(
                  Math.floor(settlementInfo?.artistAnnualSettlementFee || 0),
                )}원)`
              }
              &nbsp;
            </H5>
          }
          <H5>입니다.</H5>
        </InfoCard>
      </Flex>

      <Flex
        style={
          {
            borderRadius: '6px',
            boxShadow: '0 4px 10px 0 rgba(36, 36, 36, 0.16)',
            backgroundColor: '#fff',
          }
        }
      >
        {
artistAlbumMonthlySettlementList?.length === 0 ? (
  <NoData>No data</NoData>
) : (
  <ResponsiveContainer width="95%" height={500}>
    <ComposedChart
      // width={1054}
      // height={500}
      data={
        artistAlbumMonthlySettlementList
          .map(item => ({
            ...item,
            totalMonthlySettlementFee:
                    item.companyMonthlySettlementFee
                    + item.artistMonthlySettlementFee,
          }))
          .slice()
          .reverse()
      }
      margin={
        {
          top: 50,
          right: 0,
          bottom: 50,
          left: 50,
        }
      }
    >
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis
        dataKey="month"
        tickFormatter={
          tick => {
            const strTick = `${tick}`
            return `${strTick[2]}${strTick[3]}월`
          }
        }
        allowDuplicatedCategory={false}
      />
      <YAxis
        dataKey="totalMonthlySettlementFee"
        domain={['auto', 'auto']}
        unit="원"
        tickFormatter={e => convertToPricingComma(e)}
      />
      <Tooltip
        content={
          ({ active, payload, label }) => (
            <CustomTooltip
              active={active}
              payload={payload}
              label={label}
            />
          )
        }
      />
      {/* <Tooltip /> */}
      <Legend verticalAlign="top" layout="horizontal" align="center" />
      <Line
        stroke="black"
        name="전체"
        legendType="none"
        dataKey="totalMonthlySettlementFee"
      />
    </ComposedChart>
  </ResponsiveContainer>
)
        }
      </Flex>
      <H5 type="bold" align="left" style={{ margin: '50px 0 20px' }}>
        총 정산 세부내역
      </H5>
      <MonthlyPicker
        currentMonth={trackCurrentMonth}
        handleMonth={handleMonth}
        enableMonthList={artistAlbumMonthlySettlementList?.map(i => i.month)}
      />
      <Flex style={{ margin: '20px 0' }}>
        <InfoCard>
          <H5>{`${displayMonth} 앨범의 총 정산금액은`}</H5>
          <H4 type="bold">
            &nbsp;
            {
artistAlbumRecentMonthlySettlementInfo?.albumMonthlySettlementFee && (
                <CountUp
                  start={0}
                  end={
                    Math.floor(
                  artistAlbumRecentMonthlySettlementInfo?.albumMonthlySettlementFee,
                    )
                  }
                  separator=","
                  duration={3}
                />
              )
            }
            원 &nbsp;
          </H4>
          {
            <H5 style={{ fontSize: '18px' }} type={'Regular'} color={'#646464'}>
              {
                `(사업자 ${convertToPricingComma(
                  Math.floor(
                  artistAlbumRecentMonthlySettlementInfo?.companyMonthlySettlementFee
                    || 0,
                  ),
                )}원, 아티스트 ${convertToPricingComma(
                  Math.floor(
                  artistAlbumRecentMonthlySettlementInfo?.artistMonthlySettlementFee
                    || 0,
                  ),
                )}원)`
              }
              &nbsp;
            </H5>
          }
          <H5>입니다.</H5>
        </InfoCard>
      </Flex>
      <Flex>
        <TableBox>
          <Table
            pagination={false}
            rowKey="_id"
            onRow={
              record => {
                return {
                  onClick: () => {
                    const isCompany =
                    currentUser?.type === 'company'
                    && currentUser?.companyId
                    && location.pathname.startsWith(`/insight/settlement/company`)
                    if (isCompany) {
                    // history.push(`/insight/settlement/company/${currentUser?.companyId}/track/${record.trackId}?isCompany=1`)
                      window.open(
                        `/insight/settlement/company/${currentUser?.companyId}/track/${record.trackId}?isCompany=1`,
                      )
                    }
                    else {
                      history.push(
                        `/insight/settlement/track/${record.trackId}?isCompany=1`,
                      )
                    }
                  },
                }
              }
            }
            dataSource={artistTrackMonthlySettlementList || []}
            columns={
              [
                {
                  title: '트랙명',
                  key: 'trackInfoTitle',
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(a?.trackInfo?.title, b?.trackInfo?.title)
                  },
                  dataIndex: ['trackInfo', 'title'],
                  ...getColumnSearchProps(['trackInfo', 'title']),
                  width: '40%',
                  align: 'left',
                  render: (text, record) => (
                    <Flex align="center">
                      {
record?.trackInfo?.albumInfo?.image64Path && (
                          <img
                            src={
                              STORAGE_URL
                          + record?.trackInfo?.albumInfo?.image64Path
                            }
                            alt="cover_img"
                            style={
                              {
                                width: '36px',
                                height: '36px',
                                borderRadius: '6px',
                                marginRight: '16px',
                              }
                            }
                          />
                        )
                      }
                      <p
                        style={
                          {
                            margin: 0,
                            padding: 0,
                            textAlign: 'left',
                            display: 'inline-block',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            width: '100%',
                          }
                        }
                      >
                        {text}
                      </p>
                    </Flex>
                  ),
                },
                {
                  title: '유통사',
                  key: 'distributor',
                  dataIndex: 'distributor',
                  align: 'center',
                // width: '280px',
                },
                {
                  title: '트랙 정산 수익',
                  key: 'trackMonthlySettlementFee',
                  dataIndex: 'trackMonthlySettlementFee',
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                    a?.trackMonthlySettlementFee,
                    b?.trackMonthlySettlementFee,
                    )
                  },
                  // width: '180px',
                  align: 'center',
                },
                {
                  title: '인접권 정산 비율',
                  key: 'companySettlementFeeRatio',
                  dataIndex: 'companySettlementFeeRatio',
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                    a?.companySettlementFeeRatio,
                    b?.companySettlementFeeRatio,
                    )
                  },
                  render: text =>
                    `${
                      typeof text === 'number'
                        ? `${(text * 100).toFixed(2)}%`
                        : '-'
                    }`,
                  // width: '120px',
                  align: 'center',
                },
                {
                  title: '사업자 정산 수익',
                  key: 'companyMonthlySettlementFee',
                  dataIndex: 'companyMonthlySettlementFee',
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  // width: '180px',
                  align: 'center',
                },
                {
                  title: '아티스트 정산 수익',
                  key: 'artistMonthlySettlementFee',
                  dataIndex: 'artistMonthlySettlementFee',
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  // width: '180px',
                  align: 'center',
                },
                {
                  title: '트랙링크',
                  key: 'trackId',
                  dataIndex: 'trackId',
                  render: text => {
                    return (
                      <Flex justify={'center'}>
                        <div
                          onClick={
                            e => {
                              window.open(`https://plam.in/track/${text}`)
                              e.stopPropagation()
                            }
                          }
                          style={
                            {
                              width: '36px',
                              height: '36px',
                              padding: '6px',
                              backgroundColor: '#f4f4f4',
                              borderRadius: '50%',
                            }
                          }
                        >
                          <img
                            src={copy_btn_img}
                            alt="copy"
                            style={
                              {
                                width: '24px',
                                height: '24px',
                                cursor: 'pointer',
                              }
                            }
                          />
                        </div>
                      </Flex>
                    )
                  },
                  width: 100,
                  align: 'center',
                },
              ]
            }
          />
        </TableBox>
      </Flex>
      <H5 type="bold" align="left" style={{ margin: '50px 0 20px' }}>
        월간 정산 세부내역
      </H5>
      <Flex>
        <TableBox>
          <Table
            pagination={false}
            rowKey="_id"
            dataSource={artistAlbumMonthlySettlementList || []}
            columns={
              [
                {
                  title: '월',
                  key: 'month',
                  dataIndex: 'month',
                  render: text => {
                    return `20${parseInt(text / 100)}년 ${text % 100}월`
                  },
                  width: '200px',
                  align: 'center',
                },
                {
                  title: '유통사',
                  key: 'distributorList',
                  dataIndex: 'distributorList',
                  align: 'center',
                  render: list => {
                    return Array.isArray(list)
                      ? list?.sort((a, b) => (a > b ? 1 : -1))?.join(', ')
                      : list
                  },
                  width: '400px',
                },
                {
                  title: '앨범 정산 수익',
                  key: 'albumMonthlySettlementFee',
                  dataIndex: 'albumMonthlySettlementFee',
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  width: '238px',
                  align: 'center',
                },
                {
                  title: '사업자 정산 수익',
                  key: 'companyMonthlySettlementFee',
                  dataIndex: 'companyMonthlySettlementFee',
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  width: '238px',
                  align: 'center',
                },
                {
                  title: '아티스트 정산 수익',
                  key: 'artistMonthlySettlementFee',
                  dataIndex: 'artistMonthlySettlementFee',
                  render: text => {
                    return `${convertToPricingComma(parseInt(text))}원`
                  },
                  width: '238px',
                  align: 'center',
                },
              ]
            }
          />
        </TableBox>
      </Flex>
    </Flex>
  )
}

export default observer(CompanyArtistAlbumSettlementAnnualChart)

const ToolTipBox = styled(Flex)`
  width:360px;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;

  p {margin: 3px 0;}
  p:first-child {margin 0;}
`

const SectionTitle = styled(Text)`
  font-family: NotoSansCJKkr-Bold;
  font-size: 18px;
  margin-bottom: 20px;
`

const InfoCard = styled(Flex)`
  flex: 1;
  /* width: 234px; */
  height: 100px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #fcfcfc;
  padding: 5px;
  justify-content: center;
  align-items: center;
`

const TableBox = styled.div`
  width: 100%;

  table {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #ddd;
    background-color: #fcfcfc;
  }

  tr {
    cursor: pointer;
  }

  thead > tr > th {
    background-color: transparent;
    color: #646464;
  }

  thead > tr > th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5),
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(8) {
    text-align: center;
    min-width: 88px;
  }
`

const CustomTooltip = ({ active, payload, label }) => {
  const strTick = `${label}`
  const customLabel = `${strTick[0]}${strTick[1]}년 ${strTick[2]}${strTick[3]}월`

  if (active && payload && payload[0]) {
    return (
      <ToolTipBox type="column">
        <Caption2 type="Bold" align="left">{`${customLabel}`}</Caption2>

        <Flex key={payload[0].name} align="center" justify="space-between">
          <Caption1 align="left">총 정산금액</Caption1>
          <Caption1>
            {
              `${payload[0]
              && convertToPricingComma(
                Math.floor(payload[0]?.payload?.totalMonthlySettlementFee),
              )}원`
            }
          </Caption1>
        </Flex>
        <Flex key={'사업자'} align="center" justify="space-between">
          <Caption2 align="left">사업자 정산금액</Caption2>
          <Caption2>
            {
              `${payload[0]
              && convertToPricingComma(
                Math.floor(payload[0]?.payload?.companyMonthlySettlementFee),
              )}원`
            }
          </Caption2>
        </Flex>
        <Flex key={'아티스트'} align="center" justify="space-between">
          <Caption2 align="left">아티스트 정산금액</Caption2>
          <Caption2>
            {
              `${payload[0]
              && convertToPricingComma(
                Math.floor(payload[0]?.payload?.artistMonthlySettlementFee),
              )}원`
            }
          </Caption2>
        </Flex>
      </ToolTipBox>
    )
  }
  return null
}
