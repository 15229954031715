import React from 'react'
import styled from 'styled-components'
import { Flex, Caption1, Caption2, Div } from '@components/atoms'
import { convertToPricingComma } from '@utils/format'

const ModalBody = styled(Flex)`
  position: absolute;
  top: 24px;
  right: 0px;
  width: 320px;
  height: 338px;
  padding: 16px 0 20px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #fff;

  flex-direction: column;
  align-items: center;
`

const Card = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 280px;
  height: 48px;
  padding: 15px 16px;
  border-radius: 6px;
  border: solid 1px #ddd;
  background-color: #fcfcfc;

  cursor: pointer;

  :hover {
    background-color: #f4f4f4;
  }
`

const ValuationInfoModal = ({ valuationInfo, handleType }) => {
  return (
    <ModalBody type="column">
      <Caption1 type="bold">
        음악 가치 평가액{' '}
        <Caption1 type="Medium" color="#949494">
          (합계)
        </Caption1>
      </Caption1>
      <Flex type="column" style={{ margin: '16px 0' }}>
        <Caption2 type="Regular" color="#646464">
          보유한 음악 권리의 가치 평가액은
        </Caption2>
        <Caption1 style={{ margin: '12px 0' }}>
          {convertToPricingComma(
            Math.floor(valuationInfo?.dayWeightedTotalValue),
          )}
          원
        </Caption1>
        <Caption2 size="10px" color="#646464">
          (인접권 + 저작권 + 실연권의 예상수익 현재가치 환산)
        </Caption2>
      </Flex>
      <Div height="1px" width="100%" backgroundColor="#f4f4f4" />
      <Flex type="column" style={{ gap: '12px' }}>
        <Card onClick={() => handleType('adjacency')}>
          <Caption2 color="#646464">인접권</Caption2>
          <Caption2 type="Bold">
            {convertToPricingComma(
              Math.floor(valuationInfo?.dayWeightedAdjacencyValue),
            )}
            원
          </Caption2>
        </Card>
        <Card onClick={() => handleType('copyRight')}>
          <Caption2 color="#646464">저작권</Caption2>
          <Caption2 type="Bold">
            {convertToPricingComma(
              Math.floor(valuationInfo?.dayWeightedCopyRightValue),
            )}
            원
          </Caption2>
        </Card>
        <Card onClick={() => handleType('performanceRight')}>
          <Caption2 color="#646464">실연권</Caption2>
          <Caption2 type="Bold">
            {convertToPricingComma(
              Math.floor(valuationInfo?.dayWeightedPerformanceRightValue),
            )}
            원
          </Caption2>
        </Card>
      </Flex>
    </ModalBody>
  )
}

export default ValuationInfoModal
