import React from 'react'
import { Table } from 'antd'
import { convertToPricingComma } from '@utils/format'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'

const RevenueTrackDetailTableChart = ({ data, isCompany = false }) => {
  const { authStore } = useStore()
  const currentUser = authStore?.currentUser || null

  const isCompanyData = isCompany && currentUser?.type === 'company'

  const sortHandler = (a, b, sortOrder) => {
    if (a < b) {
      return -1
    }
    else if (a > b) {
      return 1
    }
    else {
      return 0
    }
  }

  return (
    <Table
      className="inflow_table"
      columns={
        [
          {
            title: '월',
            key: 'salesMonth',
            dataIndex: 'salesMonth',
            defaultSortOrder: 'descend',
            sorter: (a, b, sortOrder) => {
              return sortHandler(a?.salesMonth, b?.salesMonth)
            },
            render: (text, record) =>
              `20${parseInt(record.salesMonth / 100)}년 ${record.salesMonth
              % 100}월`,
          },
          {
            title: '총 수익',
            key: 'totalRevenue',
            dataIndex: 'totalRevenue',
            defaultSortOrder: { sortOrder: 'descend' },
            render: text => `${convertToPricingComma(Math.floor(text))}원`,
            sorter: (a, b, sortOrder) => {
              return sortHandler(a?.totalRevenue, b?.totalRevenue)
            },
          },
          {
            title: '내 인접권료',
            key: 'monthlyAdjacencyFee',
            dataIndex: isCompanyData
              ? 'companyMonthlyAdjacencyFee'
              : 'artistMonthlyAdjacencyFee',
            defaultSortOrder: { sortOrder: 'descend' },
            render: text => `${convertToPricingComma(Math.floor(text))}원`,
            sorter: (a, b, sortOrder) => {
              if (isCompanyData) {
                return sortHandler(
                a?.companyMonthlyAdjacencyFee,
                b?.companyMonthlyAdjacencyFee,
                )
              }
              else {
                return sortHandler(
                a?.artistMonthlyAdjacencyFee,
                b?.artistMonthlyAdjacencyFee,
                )
              }
            },
          },
          {
            title: '인접권 정산 비율',
            key: 'adjacencyFeeRate',
            dataIndex: isCompanyData
              ? 'companyAdjacencyFeeRate'
              : 'artistAdjacencyFeeRate',
            defaultSortOrder: { sortOrder: 'descend' },
            width: '10%',
            render: text =>
              `${typeof text === 'number' ? `${(text * 100).toFixed(2)}%` : '-'}`,
            sorter: (a, b, sortOrder) => {
              if (isCompanyData) {
                return sortHandler(
                a?.companyAdjacencyFeeRate,
                b?.companyAdjacencyFeeRate,
                )
              }
              else {
                return sortHandler(
                a?.artistAdjacencyFeeRate,
                b?.artistAdjacencyFeeRate,
                )
              }
            },
          },
          {
            title: '트랙 인접권료',
            key: 'trackMonthlyAdjacencyFee',
            dataIndex: 'trackMonthlyAdjacencyFee',
            defaultSortOrder: { sortOrder: 'descend' },
            render: text =>
              typeof text === 'number'
                ? `${convertToPricingComma(Math.floor(text))}원`
                : '-',
            sorter: (a, b, sortOrder) => {
              return sortHandler(
              a?.trackMonthlyAdjacencyFee,
              b?.trackMonthlyAdjacencyFee,
              )
            },
          },
          {
            title: '내 저작권료',
            key: 'monthlyCopyRightFee',
            dataIndex: isCompanyData
              ? 'companyMonthlyCopyRightFee'
              : 'artistMonthlyCopyRightFee',
            defaultSortOrder: { sortOrder: 'descend' },
            render: text => `${convertToPricingComma(Math.floor(text))}원`,
            sorter: (a, b, sortOrder) => {
              if (isCompanyData) {
                return sortHandler(
                a?.companyMonthlyCopyRightFee,
                b?.companyMonthlyCopyRightFee,
                )
              }
              else {
                return sortHandler(
                a?.artistMonthlyCopyRightFee,
                b?.artistMonthlyCopyRightFee,
                )
              }
            },
          },
          {
            title: '저작권 보유',
            key: 'copyRightFeeRate',
            dataIndex: isCompanyData
              ? 'companyCopyRightFeeRate'
              : 'artistCopyRightFeeRate',
            defaultSortOrder: { sortOrder: 'descend' },
            render: text => `${(text * 100).toFixed(2)}%`,
            sorter: (a, b, sortOrder) => {
              if (isCompanyData) {
                return sortHandler(
                a?.companyCopyRightFeeRate,
                b?.companyCopyRightFeeRate,
                )
              }
              else {
                return sortHandler(
                a?.artistCopyRightFeeRate,
                b?.artistCopyRightFeeRate,
                )
              }
            },
          },
          {
            title: '트랙 저작권료',
            key: 'trackMonthlyCopyRightFee',
            dataIndex: 'trackMonthlyCopyRightFee',
            defaultSortOrder: { sortOrder: 'descend' },
            render: text => `${convertToPricingComma(Math.floor(text))}원`,
            sorter: (a, b, sortOrder) => {
              return sortHandler(
              a?.trackMonthlyCopyRightFee,
              b?.trackMonthlyCopyRightFee,
              )
            },
          },
          {
            title: '내 실연권료',
            key: 'monthlyPerformanceRightFee',
            dataIndex: isCompanyData
              ? 'companyMonthlyPerformanceRightFee'
              : 'artistMonthlyPerformanceRightFee',
            defaultSortOrder: { sortOrder: 'descend' },
            render: text => `${convertToPricingComma(Math.floor(text))}원`,
            sorter: (a, b, sortOrder) => {
              if (isCompanyData) {
                return sortHandler(
                a?.companyMonthlyPerformanceRightFee,
                b?.companyMonthlyPerformanceRightFee,
                )
              }
              else {
                return sortHandler(
                a?.artistMonthlyPerformanceRightFee,
                b?.artistMonthlyPerformanceRightFee,
                )
              }
            },
          },
          {
            title: '실연권 보유',
            key: 'performanceRightFeeRate',
            dataIndex: isCompanyData
              ? 'companyPerformanceRightFeeRate'
              : 'artistPerformanceRightFeeRate',
            defaultSortOrder: { sortOrder: 'descend' },
            render: text => `${(text * 100).toFixed(2)}%`,
            sorter: (a, b, sortOrder) => {
              if (isCompanyData) {
                return sortHandler(
                a?.companyPerformanceRightFeeRate,
                b?.companyPerformanceRightFeeRate,
                )
              }
              else {
                return sortHandler(
                a?.artistPerformanceRightFeeRate,
                b?.artistPerformanceRightFeeRate,
                )
              }
            },
          },
          {
            title: '트랙 실연권료',
            key: 'trackMonthlyPerformanceRightFee',
            dataIndex: 'trackMonthlyPerformanceRightFee',
            defaultSortOrder: { sortOrder: 'descend' },
            render: text => `${convertToPricingComma(Math.floor(text))}원`,
            sorter: (a, b, sortOrder) => {
              return sortHandler(
              a?.trackMonthlyPerformanceRightFee,
              b?.trackMonthlyPerformanceRightFee,
              )
            },
          },
        ]
      }
      dataSource={data}
      pagination={false}
      size={'small'}
      style={
        {
          width: '100%',
          height: '360px',
          border: 'solid 1px #dddddd',
          borderRadius: '6px',
          marginBottom: '100px',
        }
      }
      rowKey="dataObjectId"
      scroll={{ y: 300 }}
    />
  )
}

export default observer(RevenueTrackDetailTableChart)
