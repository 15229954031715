import styled from 'styled-components'
import { Input } from '@components/atoms'

export const LinkInput = styled(Input)`
  width : 565px;
  margin 8px 0px;

  // :nth-child(-n+2) {
  //   margin-top:0;
  // }
`
