export default class AdjustmentContractNetwork {
  constructor(network: Network) {
    this.network = network
  }

  async getContractCompanyList({ artistId } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/contract/company?artistId=${artistId}`,
      )
      return data.data
    } catch (error) {
      console.error(
        '[AdjustmentContractNetwork][getContractCompanyList] error: ',
        error,
      )
    }
  }

  async getAdjustmentContractList({ artistId, companyId } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/contract?artistId=${artistId}&companyId=${companyId}`,
      )
      return data
    } catch (error) {
      console.error(
        '[AdjustmentContractNetwork][getAdjustmentContractList] error: ',
        error,
      )
    }
  }

  async postAdjustmentContract({ params } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/contract`,
        'post',
        params,
      )
      return data
    } catch (error) {
      console.error(
        '[AdjustmentContractNetwork][postAdjustmentContract] error: ',
        error,
      )
    }
  }

  async putAdjustmentContract({ adjustmentContractId, params } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/contract/${adjustmentContractId}`,
        'put',
        params,
      )
      return data.data
    } catch (error) {
      console.error(
        '[AdjustmentContractNetwork][putAdjustmentContract] error: ',
        error,
      )
    }
  }

  async deleteAdjustmentContract({ adjustmentContractId } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/contract/${adjustmentContractId}`,
        'delete',
      )
      return data.data
    } catch (error) {
      console.error(
        '[AdjustmentContractNetwork][deleteAdjustmentContract] error: ',
        error,
      )
    }
  }
}
