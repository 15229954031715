import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Text, Button, Image, Div } from '@components/atoms'
import {
  ArtistFormModal,
  InputTypeModal,
  MelonLinkModal,
  ConfirmPopup,
} from '@components/organisms'
import { useHistory } from 'react-router-dom'

import { firstMyPageLogoImg } from '@images'

const Container = styled(Flex)`
  flex-direction: column;
`
const EditArtistButton = styled(Button)`
  width: 230px;
  height: 48px;
  background-color: #242424;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  color: #f4f4f4;
  font-family: NotoSansCJKkr-Medium;

  :hover {
    background-color: #d4d4d4;
  }
`

interface NoArtistInfoProps {
  onCreateArtist: Function;
  user: Object;
  crawlArtist: () => void;
}

export const NoArtistInfo = ({
  onCreateArtist,
  user,
  crawlArtist,
}: NoArtistInfoProps) => {
  const [showForm, setShowForm] = useState(false) // 아티스트 정보 입력 모달

  const [type, setType] = useState('') // 입력 방법

  const history = useHistory()

  const {
    isShowing: typeShowing,
    toggle: typeToggle,
  } = InputTypeModal.useModal()
  const {
    isShowing: melonShowing,
    toggle: melonToggle,
  } = MelonLinkModal.useModal()

  const {
    isShowing: showConfirm,
    toggle: confirmToggle,
  } = ConfirmPopup.usePopup()

  const handleClose = () => {
    setShowForm(false)
  }

  return (
    <Container>
      {user && user.nowArtistCrawling ? (
        <>
          <Text style={{ marginTop: '16px' }}>{`ArtistID : ${user &&
            user.melonArtistId}`}</Text>
          <Text color="#1982df">아티스트 정보 등록 중</Text>
          <Text>24시간 이내에 등록됩니다.</Text>
        </>
      ) : (
        <>
          <Div
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="center"
            marginTop="124px"
          >
            <Image
              src={firstMyPageLogoImg}
              width="188px"
              height="172px"
              alt="first_logo"
            />
            <Text
              type="bold"
              style={{ margin: '24px 0 28px', textAlign: 'center' }}
            >
              아티스트 정보가 없습니다.
              <br />
              정보를 입력하면 공유 링크가 생성됩니다.
            </Text>
            <EditArtistButton onClick={() => typeToggle()}>
              아티스트 정보 입력
            </EditArtistButton>
          </Div>
          <ArtistFormModal
            show={showForm}
            handleClose={handleClose}
            onCreateArtist={onCreateArtist}
            userId={user && user._id}
            account={user && user.account}
            isUpdating={false}
          />
          <InputTypeModal.View
            isShowing={typeShowing}
            toggle={typeToggle}
            melonToggle={melonToggle}
            type={type}
            setType={setType}
            openDirect={() => setShowForm(true)}
          />
          <MelonLinkModal.View
            isShowing={melonShowing}
            toggle={melonToggle}
            pageType="아티스트"
            handleCrawl={crawlArtist}
            confirmToggle={confirmToggle}
          />
          <ConfirmPopup.View
            isShowing={showConfirm}
            toggle={confirmToggle}
            title={`등록 신청 완료`}
            body={`24시간 이내에 아티스트 정보가 등록됩니다.`}
            onClose={() => history.go(0)}
          />
        </>
      )}
    </Container>
  )
}
