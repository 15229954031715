import React, { useState } from 'react'
import { Upload, message } from 'antd'

import { STORAGE_URL, API_URL } from '@consts'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

const getPresignedPostData = (selectedFile, jwt) => {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest()
    // Set the proper URL here.
    const url = `${API_URL}/file`

    xhr.open('POST', url, true)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.setRequestHeader('Authorization', `jwt ${jwt}`)
    xhr.send(
      JSON.stringify({
        name: selectedFile.name,
        type: selectedFile.type,
      }),
    )
    xhr.onload = function() {
      resolve(JSON.parse(this.responseText))
    }
  })
}

const uploadFileToS3 = (presignedPostData, file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    Object.keys(presignedPostData.fields).forEach(key => {
      formData.append(key, presignedPostData.fields[key])
    })
    // Actual file has to be appended last.
    formData.append('file', file)
    const xhr = new XMLHttpRequest()
    xhr.open('POST', presignedPostData.url, true)
    xhr.send(formData)
    xhr.onload = function(ev) {
      this.status === 204 ? resolve(true) : reject(this.responseText)
    }
  })
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

  if (!isJpgOrPng) {
    message.error('jpg나 png 파일 형식을 업로드해주세요.')
  }
  const isLt10M = file.size / 1024 / 1024 < 10
  if (!isLt10M) {
    message.error('10MB 이하의 파일을 업로드해주세요.')
  }
  return isJpgOrPng && isLt10M
}

const StyledUploadContainer = styled.div`
  width: 400px;
  height: 400px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImageUploadForm = ({
  imageOriginalPath,
  setImageUrl,
  label,
  authStore,
}) => {
  const jwt = authStore.jsonWebToken || null

  const [tempImage, setTempImage] = useState(null)

  const _onChange = async info => {
    let key = null
    if (info.file.status !== 'uploading') {
      const { data: presignedPostData } = await getPresignedPostData(
        info.file,
        jwt,
      )
      key = presignedPostData.fields.key
      try {
        await uploadFileToS3(presignedPostData, info.file.originFileObj)
        setImageUrl(`/${key}`)
        setTempImage(`${STORAGE_URL}/${key}`)
        message.success(`${info.file.name} 업로드 완료!`)
      }
      catch (e) {
        console.log('upload error occurred!', e.message)
        message.error(`${info.file.name} 업로드 실패.`)
      }
    }
  }

  const uploadButton = (
    <div
      style={
        {
          width: '400px',
          height: '400px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          borderRadius: '6px',
          backgroundColor: '#f2f2f2',
          cursor: 'pointer',
        }
      }
    >
      <p
        className="ant-upload-hint"
        style={{ color: 'rgba(0, 0, 0, .45)', fontSize: 14 }}
      >
        {label}
      </p>
      <p
        className="ant-upload-hint"
        style={{ color: 'rgba(0, 0, 0, .45)', fontSize: 14 }}
      >
        512px 이상의 정사각형 이미지(10mb 이하)
      </p>
    </div>
  )

  return (
    <StyledUploadContainer>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={_onChange}
      >
        {
          imageOriginalPath ? (
            <img
              src={tempImage || imageOriginalPath}
              alt="artist_cover_image"
              style={{ width: '400px', height: '400px' }}
            />
          ) : (
            uploadButton
          )
        }
      </Upload>
    </StyledUploadContainer>
  )
}

export default inject('authStore')(observer(ImageUploadForm))
