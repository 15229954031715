import React, { useEffect, useState } from 'react'
import { RootTemplate, InsightTemplate } from '@components/templates'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/ko'
import { Redirect } from 'react-router-dom'
import qs from 'qs'
import { InsightSmartLinkTemplate } from '@components/templates/InsightSmartLinkTemplate'

interface Props {
  artistStore: ArtistStore;
  authStore: AuthStore;
  statsStore: StatsStore;
  match: Any;
  location: Any;
  logout: Function;
  user: Object;
}

const InsightSmartLinkPage = ({
  statsStore,
  artistStore,
  logout,
  location,
  user,
}: Props) => {
  moment.locale('ko')
  const artist = artistStore.artistDetail
  const {
    sumInflowSmartLink: sumInflow,
    dailyInflowSmartLinkRecordList: DailyInflowStatsList,
  } = statsStore

  const [startDate, setStartDate] = useState(moment().subtract(1, 'months'))
  const [endDate, setEndDate] = useState(moment())

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  useEffect(() => {
    if (query?.link) {
      if (query.artistId && query.artistId !== 'null') {
        artistStore.fetchArtistDetail(query.artistId)
        statsStore.fetchSmartLinkRecord({
          artistId: query.artistId,
          smartLinkUrl: query?.link,
          periodType: 'custom',
          fromDate: startDate && startDate.format('YYYY-MM-DD'),
          toDate: endDate && endDate.format('YYYY-MM-DD'),
        })
      } else {
        artistStore.fetchArtistDetail(user && user['artistId'])
        statsStore.fetchSmartLinkRecord({
          artistId: user && user['artistId'],
          smartLinkUrl: query?.link,
          periodType: 'custom',
          fromDate: startDate && startDate.format('YYYY-MM-DD'),
          toDate: endDate && endDate.format('YYYY-MM-DD'),
        })
      }
    }
  }, [user, artistStore, statsStore, startDate, endDate, location])

  return (
    <>
      <RootTemplate user={user} artist={artist} logout={logout}>
        {!(user && user['artistId']) && <Redirect to="/" />}
        <InsightSmartLinkTemplate
          DailyInflowStatsList={DailyInflowStatsList}
          sumInflow={sumInflow}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          link={query?.link}
        />
      </RootTemplate>
    </>
  )
}

export default inject(
  'artistStore',
  'statsStore',
)(observer(InsightSmartLinkPage))
