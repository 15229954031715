import axios from 'axios'

export default class AdjustmentDetailNetwork {
  constructor(network: Network) {
    this.network = network
    this.s3Client = axios.create()
  }

  async getAdjustmentDetail({ adjustmentId } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/${adjustmentId}/detail`,
      )
      return data
    } catch (error) {
      console.error(
        '[AdjustmentDetailNetwork][getAdjustmentDetail] error: ',
        error,
      )
    }
  }

  // pre-signed data 가져오기
  async postAdjustmentEvidenceFile({ filename } = {}) {
    try {
      const params = {
        isAdjustmentDetail: true,
        name: filename,
      }
      const { data } = await this.network._axiosApiAuth(`/file`, 'post', params)
      return data
    } catch (error) {
      console.error(
        '[AdjustmentDetailNetwork][postAdjustmentEvidenceFile] error: ',
        error,
      )
    }
  }

  // pre-signed data s3에 업로드
  async postPreSignedData({ formdata, url } = {}) {
    try {
      const { data } = await this.s3Client.post(url, formdata)
      return data
    } catch (error) {
      console.error(
        '[AdjustmentDetailNetwork][postPreSignedData] error: ',
        error,
      )
    }
  }

  async postAdjustmentDetail({ adjustmentId, params } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/${adjustmentId}/detail`,
        'post',
        params,
      )
      return data
    } catch (error) {
      console.error(
        '[AdjustmentDetailNetwork][postAdjustmentDetail] error: ',
        error,
      )
    }
  }

  async putAdjustmentDetail({ adjustmentId, adjustmentDetailId, params } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/${adjustmentId}/detail/${adjustmentDetailId}`,
        'put',
        params,
      )
      return data
    } catch (error) {
      console.error(
        '[AdjustmentDetailNetwork][putAdjustmentDetail] error: ',
        error,
      )
    }
  }

  async deleteAdjustmentDetail({ adjustmentId, adjustmentDetailId } = {}) {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/adjustment/${adjustmentId}/detail/${adjustmentDetailId}`,
        'delete',
      )
      return data
    } catch (error) {
      console.error(
        '[deleteAdjustmentDetail][putAdjustmentDetail] error: ',
        error,
      )
    }
  }
}
