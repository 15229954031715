/* eslint-disable import/no-unresolved */
import { fullHD } from '@styles/media'
import { Flex } from '@components/atoms'
import styled from 'styled-components'

export const RoyaltyStatementContainer = styled(Flex)`
  flex: 1;
  flex-direction: column;
  margin: 0px 40px 20px;
  width: 100%;
  /* max-width: 1412px; */

  @media ${fullHD} {
    margin: 0px 40px 20px;
  }
`
