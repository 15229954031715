import React, { useState, useEffect } from 'react'
// antd
import { Progress, message } from 'antd'
import styled from 'styled-components'
import { Flex, Text } from '@components/atoms'
import { API_URL } from '@consts'
import Files from 'react-butterfiles'
import { colors } from '@colors/'
import { inject, observer } from 'mobx-react'

const clip_img = require('@images/clip.png')
const close_img = require('@images/x_btn_img.png')

const UploadBox = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 375px;
  height: 72px;
  border-radius: 6px;
  background-color: #f2f2f2;
  cursor: pointer;
  font-size: 14px;
  color: #949494;
`

const FileList = styled.ol`
  width: 375px;
  height: 40px;
  border-radius: 6px;
  border: solid 1px #1982df;
  background-color: #ffffff;
  display: flex;
  margin: 10px 0px 0px 0px;
  padding: 16px 10px;
`

const FileItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  span {
    flex-grow: 1;
  }

  img {
    width: 16px;
    height: 16px;
  }

  img:last-child {
    cursor: pointer;
  }
`

const AudioUploadForm = props => {
  const [fileList, setFileList] = useState()
  const [result, setResult] = useState(null)
  const [isUpload, setIsUpload] = useState(false)
  const [percent, setPercent] = useState(0)

  const { setAudioUrl, audioOriginalPath, authStore } = props

  const jwt = authStore.jsonWebToken || null

  useEffect(() => {
    if (audioOriginalPath) {
      setFileList([{ name: audioOriginalPath }])
    }
  }, [audioOriginalPath])

  /**
   * Retrieve pre-signed POST data from a dedicated API endpoint.
   * @param selectedFile
   * @returns {Promise<any>}
   */
  const getPresignedPostData = selectedFile => {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest()
      // Set the proper URL here.
      const url = `${API_URL}/file`

      xhr.open('POST', url, true)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.setRequestHeader('Authorization', `jwt ${jwt}`)
      xhr.send(
        JSON.stringify({
          name: selectedFile.name,
          type: selectedFile.type,
        }),
      )
      xhr.onload = function() {
        resolve(JSON.parse(this.responseText))
      }
    })
  }

  /**
   * Upload file to S3 with previously received pre-signed POST data.
   * @param presignedPostData
   * @param file
   * @returns {Promise<any>}
   */
  const uploadFileToS3 = (presignedPostData, file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      Object.keys(presignedPostData.fields).forEach(key => {
        console.log(key, ' : ', presignedPostData.fields[key])
        formData.append(key, presignedPostData.fields[key])
      })
      // Actual file has to be appended last.
      formData.append('file', file)
      const xhr = new XMLHttpRequest()
      xhr.upload.addEventListener(
        'progress',
        function(event) {
          if (event.lengthComputable) {
            setPercent(Math.floor((100 * event.loaded) / event.total))
          }
        },
        false,
      )

      xhr.open('POST', presignedPostData.url, true)
      xhr.send(formData)
      xhr.onload = function() {
        this.status === 204 ? resolve() : reject(this.responseText)
      }
    })
  }

  return (
    <Files
      multiple={false}
      maxSize="1gb"
      multipleMaxSize="1gb"
      multipleMaxCount={1}
      accept={'audio/*'}
      onSuccess={
        async ([selectedFile]) => {
          setFileList(false)
          setIsUpload(true)
          // Step 1 - get pre-signed POST data.
          const { data: presignedPostData } = await getPresignedPostData(
            selectedFile,
          )

          // Step 2 - upload the file to S3.
          try {
            const { file } = selectedFile.src
            await uploadFileToS3(presignedPostData, file)
            setAudioUrl(`/${presignedPostData.fields.key}`)
            setPercent(100)
            message.success('업로드 성공!')
            console.log('File was successfully uploaded!')
          }
          catch (e) {
            console.log('An error occurred!', e.message)
            message.error('업로드 실패.')
          }

          setFileList([selectedFile])
        }
      }
      onError={errors => setResult(errors)}
    >
      {
        ({ browseFiles, getDropZoneProps }) => (
          <>
            <UploadBox onClick={browseFiles} {...getDropZoneProps()}>
              <Text>오디오 파일을 선택하거나 마우스로 옮겨서 업로드하세요.</Text>
              <Text>1GB 이하의 wav, mp3, flac 오디오 파일</Text>
            </UploadBox>
            {
              isUpload ? (
                fileList ? (
                  <FileList>
                    {
                      fileList.map(file => (
                        <FileItem key={file.name}>
                          <img src={clip_img} alt="clip" />
                          <Text
                            style={
                              {
                                display: 'inline-block',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                paddingRight: 8,
                              }
                            }
                          >
                            {file.name}
                          </Text>
                          <img
                            src={close_img}
                            alt="close"
                            onClick={
                              () => {
                                setFileList(null)
                                setIsUpload(false)
                              }
                            }
                          />
                        </FileItem>
                      ))
                    }
                    {
                      result
                  && result.map(error => (
                    <li key={error.file.name}>
                      {error.file.name}
                      {' '}
-
                      {error.type}
                    </li>
                  ))
                    }
                  </FileList>
                ) : (
                  <Progress
                    percent={percent}
                    strokeColor={colors.main}
                    strokeWidth="4px"
                  />
                )
              ) : fileList ? (
                <FileList>
                  {
                    fileList.map(file => (
                      <FileItem key={file.name}>
                        <img src={clip_img} alt="clip" />
                        <Text
                          style={
                            {
                              display: 'inline-block',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              paddingRight: 8,
                            }
                          }
                        >
                          {file.name}
                        </Text>
                        <img
                          src={close_img}
                          alt="close"
                          onClick={
                            () => {
                              setFileList(null)
                              setIsUpload(false)
                            }
                          }
                        />
                      </FileItem>
                    ))
                  }
                  {
                    result
                && result.map(error => (
                  <li key={error.file.name}>
                    {error.file.name}
                    {' '}
-
                    {error.type}
                  </li>
                ))
                  }
                </FileList>
              ) : (
                false
              )
            }
          </>
        )
      }
    </Files>
  )
}

export default inject('authStore')(observer(AudioUploadForm))
