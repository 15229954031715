import styled from 'styled-components'

export const TextArea = styled.textarea`
    font-size: 14px;
    height: 100px;
    border-radius: 6px;
    background-color: #ffffff;
    resize : none;
    padding : 12px;
    :placeholder-shown {
        border : solid 1px #949494;
    }
    border : solid 1px #1985df;
`