import React, { useState } from 'react'
import styled from 'styled-components'
import { Text, Input, Button, Flex } from '@components/atoms'
import {
  SubmitBtn,
  CheckTextBox,
  LogoImg,
  NoHeaderBox,
} from '@components/molecules'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import Inko from 'inko'
import ClientTypeModal from '../Modal/ClientTypeModal'
import { useResponsive } from '@utils/hooks'

const Form = styled.form`
  max-width: 518px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FormGroup = styled.div`
  padding: 30px 0px;
  width: 100%;
`

const RegisterBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 48px;
  margin: 10px;
  border-radius: 25px;
  border: solid 2px #646464;
  background-color: #ffffff;
  color: #646464;

  :hover {
    background-color: #646464;
    color: #ffffff;
    transition-property: background-color, color;
    transition-duration: 0.2s;
    transition-timing-function: linear;
  }
`

const IntroduceBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 40px;
  margin: 12px;
  border-radius: 25px;
  border: solid 2px #646464;
  background-color: #ffffff;
  color: #646464;
  font-size: 14px;

  :hover {
    background-color: #646464;
    color: #ffffff;
    transition-property: background-color, color;
    transition-duration: 0.2s;
    transition-timing-function: linear;
  }
`

const inko = new Inko()
export const LoginForm = ({ login, history }) => {
  const { width } = useResponsive()
  const [isAutoLogin, setIsAutoLogin] = useState(false)
  const [isFail, setIsFail] = useState(false)
  const [type, setType] = useState('') // 입력 방법
  const { register, handleSubmit } = useForm()

  const isMobile = width < 640

  const {
    isShowing: typeShowing,
    toggle: typeToggle,
  } = ClientTypeModal.useModal()

  const onSubmit = async data => {
    const { account, password } = data

    const res = await login({ account: account, password: inko.ko2en(password), isAutoLogin: isAutoLogin })

    if (res && !res.data.isLogin) {
      setIsFail(true)
    }
  }

  return (
    <NoHeaderBox style={{ width: '100%', padding: '80px 0 0 0' }}>
      <LogoImg isLarge />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Input
            style={{ width: '100%', marginBottom: '20px' }}
            placeholder="가입한 아이디 입력"
            type="text"
            name="account"
            ref={register({
              required: true,
            })}
          />
          <Input
            style={{ width: '100%' }}
            type="password"
            placeholder="비밀번호"
            name="password"
            ref={register({ required: true })}
          />

          {isFail && (
            <Text color="#ea4653" size="15px" type="Medium">
              아이디 혹은 비밀번호가 일치하지 않습니다. 입력한 내용을 다시
              확인해 주세요.
            </Text>
          )}
        </FormGroup>

        <FormGroup style={{ padding: '20px 0px 30px 0px' }}>
          <CheckTextBox
            onClick={() => setIsAutoLogin(!isAutoLogin)}
            checked={isAutoLogin}
            text="로그인 상태유지"
          />
        </FormGroup>

        <Text color="#949494">
          <Link to="/find/account">
            <Text color="#1982df" style={{ cursor: 'pointer' }}>
              아이디
            </Text>
          </Link>
          &nbsp;또는&nbsp;
          <Link to="/find/password">
            <Text color="#1982df" style={{ cursor: 'pointer' }}>
              비밀번호
            </Text>
          </Link>
          를 잊으셨습니까?
        </Text>
        <SubmitBtn type="submit" style={{ width: '230px', margin: '30px 0px 40px 0px' }}>
          로그인
        </SubmitBtn>

        <div
          style={{ width: '100%', textAlign: 'center' }}
        >
          <hr style={{ backgroundColor: '#242424', marginBottom: '60px' }} />
          <Text type="Medium" size="16px" color="#646464">
            아직 플램 회원이 아니세요?
          </Text>
        </div>

        <RegisterBtn onClick={typeToggle} style={{ margin: '24px 0px 40px 0px' }}>
          회원 가입
        </RegisterBtn>
        {/* <Link to="/register">
          <RegisterBtn>회원 가입</RegisterBtn>
        </Link> */}

        <div
          style={{ width: '100%', textAlign: 'center' }}
        >
          <hr style={{ backgroundColor: '#242424', marginBottom: '60px' }} />
          <Text type="Medium" size="16px" color="#646464">
            플램 서비스 소개
          </Text>
        </div>

        <Flex>
          <IntroduceBtn onClick={() => window.open(`https://plam.kr/musician`)} style={{ margin: '24px 0px 20px 0px' }}>
            for Musician
          </IntroduceBtn>
          <IntroduceBtn onClick={() => window.open(`https://plam.kr/label`)} style={{ margin: '24px 0px 20px 12px' }}>
            for Business
          </IntroduceBtn>
        </Flex>
      </Form>
      <ClientTypeModal.View
        isShowing={typeShowing}
        toggle={typeToggle}
        handleCompany={() => {
          history.push({
            pathname: '/register',
            state: {
              type: 'company',
            },
          })
        }}
        handleArtist={() => {
          history.push({
            pathname: '/register',
            state: {
              type: 'artist',
            },
          })
        }}
        type={type}
        setType={setType}
      />
    </NoHeaderBox>
  )
}
