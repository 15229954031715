/* eslint-disable import/no-unresolved */
import { observer, inject } from 'mobx-react'
import React, { useEffect, useState, useMemo, useRef } from 'react'
import { RootTemplate } from '@components/templates'
import qs from 'qs'
import styled from 'styled-components'
import { MaterialReactTable } from 'material-react-table'
import { Flex, H6, Text } from '@components/atoms'

import 'react-data-grid/lib/styles.css'
import { Button, Input, Select, Modal, Spin } from 'antd'
import moment from 'moment'

import {
  DeleteOutlined,
  LoadingOutlined,
  CheckOutlined,
} from '@ant-design/icons'
import { ArtistHeader } from './components/ArtistHeader'
import { RoyaltyStatementContainer } from './components/RoyaltyStatementContainer'
import { RSHeader } from './components/RSHeader'
import { CompanySelector } from './components/CompanySelector'
import { borderColor } from './styles'
import { PAGE_TYPE } from './consts'

const SheetContainer = styled(Flex)`
  flex-direction: column;
  max-width: calc(100vw - 280px);
`

const SavingTextContainer = styled.div`
  margin: 0 0 5px 15px;
  color: ${props => (props.$isSaving ? '#949494' : '#53a9ff')};
  font-size: 12px;
`

// 1 ~100까지 {key: value%} 형태로 만들어주는 함수
const percentageOption = () => {
  const __data = []
  for (let i = 0; i <= 100; i++) {
    __data.push({ key: i, value: `${Math.trunc(i)}%` })
  }
  return __data
}

// Filter `option.key` match the user type `input`
const filterOption = (input, option) => `${option?.key ?? ''}`.includes(input)

moment.locale('ko')

const CompanyArtistContractPage = ({
  location,
  authStore,
  artistStore,
  adjustmentContractStore,
  adjustmentTypeStore,
  adjustmentStore,
  user,
  history,
}) => {
  const readOnly = user?.type === 'artist'
  const artist = artistStore.artistDetail
  const [currentArtistId, setCurrentArtistId] = useState(null)
  const [allowViewArtistList, setAllowViewArtistList] = useState([]) // 정산서 조회 권한이
  const logout = authStore?.logout || (() => {})
  const [isLoading, setIsLoading] = useState(true)

  // 계약 삭제
  const [
    isContractDeleteModalVisible,
    setIsContractDeleteModalVisible,
  ] = useState(false) // 계약 삭제 모달
  const [
    handleContractDelete,
    setHandleContractDelete,
  ] = useState(() => () => {}) // 계약 삭제 함수

  // 정산 구분 삭제
  const [
    isAdjustmentTypeDeleteModalVisible,
    setIsAdjustmentTypeDeleteModalVisible,
  ] = useState(false) // 정산 구분 삭제 모달
  const [
    handleAdjustmentTypeDelete,
    setHandleAdjustmentTypeDelete,
  ] = useState(() => () => {}) // 정산 구분 삭제 함수

  // 저장 중 표시 관련
  const [isSaving, setIsSaving] = useState(false)
  const [showSaving, setShowSaving] = useState(false)
  const [lastSavedTime, setLastSavedTime] = useState('')

  // company selector
  const { selectedCompanyId, setSelectedCompanyId } = adjustmentContractStore
  const [companyList, setCompanyList] = useState([])

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const _fetchContract = async () => {
    if (query.artistId && query.artistId !== 'null') {
      // 현재 보고 있는 아티스트 (개인, 그룹 구별용)
      setCurrentArtistId(query.artistId)
      if (user?.type === 'company') {
        await authStore.artistViewMode(query.artistId)
      }
      const res = await adjustmentContractStore.fetchAdjustmentContractList({
        artistId: query.artistId,
        companyId: adjustmentContractStore.selectedCompanyId,
      })
    }
  }

  const _fetchAdjustmentAllowArtistList = async () => {
    // 아티스트가 그룹도 보기위해..
    if (user?.type === 'artist') {
      // currentArtistId가 아니라 현재 아티스트 아이디
      const artists = await adjustmentStore.fetchAdjustmentAllowArtistList()

      if (artists && artists.length > 0) {
        const _sortedArtist = artists.sort((a, b) => {
          if (a.type === '그룹' && b.type === '개인') return -1 // '그룹'이 '개인'보다 먼저 오도록
          if (a.type === '개인' && b.type === '그룹') return 1 // '개인'이 '그룹'보다 나중에 오도록
          return 0 // 동일한 경우 순서 변경 없음
        })
        setAllowViewArtistList([..._sortedArtist])
      }
    }
  }

  const _fetchContractCompanyList = async () => {
    if (user?.type === 'company') {
      adjustmentContractStore.selectedCompanyId = user.companyId
    }
    else if (user?.type === 'artist') {
      const companys = await adjustmentStore.fetchAdjustmentCompanyList({
        artistId: query.artistId,
      })
      if (!companys || companys.length === 0) {
        // alert('소속사를 불러오는데 실패했습니다. 관리자에게 문의하세요')
        console.error('소속사를 불러오는데 실패했습니다.')
        setIsLoading(false)
        return
      }
      setCompanyList(companys)
      if (!adjustmentContractStore.selectedCompanyId) {
        adjustmentContractStore.selectedCompanyId = companys?.[0]._id
      }
    }
    else {
      adjustmentContractStore.selectedCompanyId = null
    }
  }

  const _fetchAdjustmentType = async () => {
    setIsLoading(true)

    if (query.artistId && query.artistId !== 'null') {
      const res = await adjustmentTypeStore.fetchAdjustmentTypeList({
        artistId: query.artistId,
        companyId: adjustmentContractStore.selectedCompanyId,
      })
    }
    setIsLoading(false)
  }

  const _init = async () => {
    setIsLoading(true)
    await _fetchContractCompanyList()

    if (!adjustmentContractStore.selectedCompanyId) return

    await _fetchContract()
    await _fetchAdjustmentAllowArtistList()
    await _fetchAdjustmentType()
    setIsLoading(false)
  }

  // artist company 선택 전용 패치
  const _fetch = async () => {
    setIsLoading(true)
    if (
      user?.type === 'company'
      || !adjustmentContractStore.selectedCompanyId
    ) {
      setIsLoading(false)
      return
    }
    await _fetchContract()
    await _fetchAdjustmentAllowArtistList()
    await _fetchAdjustmentType()
    setIsLoading(false)
  }

  // init
  useEffect(() => {
    _init()
  }, [artistStore])

  useEffect(() => {
    _fetch()
  }, [selectedCompanyId])

  const addContractRow = async () => {
    savingStart()
    const res = await adjustmentContractStore.createAdjustmentContract()
    if (res) {
      adjustmentContractStore.contracts.push(res)
      adjustmentContractStore.contracts = [...adjustmentContractStore.contracts]
      savingFinish()
    }
  }

  const addAdjustmentTypeRow = async () => {
    savingStart()
    const res = await adjustmentTypeStore.createAdjustmentType({
      artistId: query.artistId,
    })
    if (res) {
      adjustmentTypeStore.adjustmentTypeList.push(res)
      adjustmentTypeStore.adjustmentTypeList = [
        ...adjustmentTypeStore.adjustmentTypeList,
      ]
      savingFinish()
    }
  }

  // 계약 삭제 모달
  const showContractDeleteModal = cell => {
    setHandleContractDelete(() => async () => {
      savingStart()
      const _row = adjustmentContractStore.contracts[cell.row.index]
      const res = await adjustmentContractStore.deleteAdjustmentContract({
        adjustmentContractId: _row._id,
      })

      if (res) {
        const temp = adjustmentContractStore.contracts.filter(
          (item, index) => index !== cell.row.index,
        )
        adjustmentContractStore.contracts = [...temp]
        savingFinish()
      }
      setIsContractDeleteModalVisible(false)
    })
    setIsContractDeleteModalVisible(true)
  }

  // 정산 구분 삭제 모달
  const showAdjustmentTypeDeleteModal = cell => {
    setHandleAdjustmentTypeDelete(() => async () => {
      savingStart()
      const _row = adjustmentTypeStore.adjustmentTypeList[cell.row.index]
      const res = await adjustmentTypeStore.deleteAdjustmentType({
        adjustmentTypeId: _row._id,
      })

      if (res) {
        const temp = adjustmentTypeStore.adjustmentTypeList.filter(
          (item, index) => index !== cell.row.index,
        )

        adjustmentTypeStore.adjustmentTypeList = [...temp]
        savingFinish()
      }
      setIsAdjustmentTypeDeleteModalVisible(false)
    })
    setIsAdjustmentTypeDeleteModalVisible(true)
  }

  const savingStart = () => {
    setLastSavedTime('')
    setShowSaving(true)
    setIsSaving(true)
  }

  const savingFinish = () => {
    setIsSaving(false)
    // 저장됨 텍스트 2초 후에 사라지게
    setTimeout(() => {
      setShowSaving(false)
      setLastSavedTime(moment())
    }, 2000)
  }

  const updateContractCell = ({ cell, update }) => {
    const _row = adjustmentContractStore.contracts[cell.row.index]
    _row[cell.column.id] = update

    adjustmentContractStore.contracts = [...adjustmentContractStore.contracts]
  }

  const updateAdjustmentTypeCell = ({ cell, update }) => {
    const _row = adjustmentTypeStore.adjustmentTypeList[cell.row.index]
    _row[cell.column.id] = update

    adjustmentTypeStore.adjustmentTypeList = [
      ...adjustmentTypeStore.adjustmentTypeList,
    ]
  }

  const updateAdjustmentContract = async index => {
    savingStart()
    const _row = adjustmentContractStore.contracts[index]
    const params = {
      type: _row.type,
      companyRatio: _row.companyRatio,
      artistRatio: _row.artistRatio,
      description: _row.description,
    }
    const res = await adjustmentContractStore.updateAdjustmentContract({
      adjustmentContractId: _row._id,
      params,
    })
    savingFinish()
  }

  const updateAdjustmentType = async index => {
    savingStart()
    const _row = adjustmentTypeStore.adjustmentTypeList[index]

    const params = {
      description: _row.description,
      adjustmentContractId: _row.adjustmentContractId,
    }

    if (_row.type || _row.type !== '') {
      params.type = _row.type
    }

    const res = await adjustmentTypeStore.updateAdjustmentType({
      adjustmentTypeId: _row._id,
      params,
    })
    savingFinish()
  }

  // int 100 => double 1.00 으로 바꾸는 함수
  const convertToDouble = value => {
    if (!value) {
      return 0
    }
    return value / 100
  }
  const columnHeaderAlign = 'center'

  const inputValueRef = useRef('')

  const handleChange = (e, cell) => {
    // 숫자 뒤 % 지우는 정규식
    const update = e.replace(/[^0-9]/g, '')
    const _row = adjustmentContractStore.contracts[cell.row.index]
    inputValueRef.current = update

    if (cell.column.id === 'companyRatioPercent') {
      _row['companyRatioPercent'] = update
      _row['companyRatio'] = convertToDouble(update)

      _row['artistRatioPercent'] = 100 - update
      _row['artistRatio'] = convertToDouble(100 - update)
    }
    else {
      _row['artistRatioPercent'] = update
      _row['artistRatio'] = convertToDouble(update)

      _row['companyRatioPercent'] = 100 - update
      _row['companyRatio'] = convertToDouble(100 - update)
    }

    adjustmentContractStore.contracts = [...adjustmentContractStore.contracts]
  }

  const handleBlur = cell => {
    // filterOption 함수를 사용하여 inputValue와 일치하는 첫 번째 옵션 찾기
    const matchedOption = percentageOption().find(option =>
      filterOption(inputValueRef.current, option),
    )
    if (matchedOption) {
      handleChange(inputValueRef.current, cell)
      updateAdjustmentContract(cell.row.index)
    }
  }

  const contractColumns = useMemo(
    () => [
      {
        accessorKey: 'type',
        header: '계약',
        muiTableHeadCellProps: {
          sx: {
            width: '250px',
            minWidth: '250px',
            flex: '0 0 auto',
          },
        }, // custom props
        muiTableBodyCellProps: {
          sx: {
            width: '250px',
            minWidth: '250px',
            flex: '0 0 auto',
          },
        },
        Cell: ({ cell }) => {
          const isConfirmed =
            adjustmentContractStore?.contracts?.[cell.row.index]?.[
              'isConfirmed'
            ]
          if (readOnly || isConfirmed) {
            return <Text>{cell.getValue()}</Text>
          }
          return (
            <Input
              readOnly={
                readOnly
                || adjustmentContractStore?.contracts?.[cell.row.index]?.[
                  'isConfirmed'
                ] === true
              }
              placeholder="계약 종류"
              value={cell.getValue()}
              onChange={
                e => {
                  const update = e.target.value
                  updateContractCell({ cell, update })
                }
              }
              onBlur={e => updateAdjustmentContract(cell.row.index)}
            />
          )
        },
      },
      {
        accessorKey: 'companyRatioPercent',
        header: '소속사',
        muiTableHeadCellProps: {
          sx: {
            width: '125px',
            minWidth: '125px',
            flex: '0 0 auto',
          },
        }, // custom props
        muiTableBodyCellProps: {
          sx: {
            width: '125px',
            minWidth: '125px',
            flex: '0 0 auto',
          },
        },
        Cell: ({ cell }) => {
          const { isConfirmed } = adjustmentContractStore?.contracts[
            cell.row.index
          ]
          if (readOnly || isConfirmed) {
            return <Text>{`${parseInt(cell.getValue(), 10)}%`}</Text>
          }
          return (
            <Select
              {...(readOnly
              || adjustmentContractStore?.contracts?.[cell.row.index]?.[
                'isConfirmed'
              ] === true
                ? { open: false }
                : {})}
              value={cell.getValue() >= 0 ? `${cell.getValue()}%` : null}
              onChange={e => handleChange(e, cell)}
              style={{ width: 92, maxWidth: 92 }}
              onSearch={
                value => {
                  if (value) inputValueRef.current = value
                }
              }
              onBlur={() => handleBlur(cell)}
              options={percentageOption()}
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
            />
          )
        },
      },
      {
        accessorKey: 'artistRatioPercent',
        header: '아티스트',
        muiTableHeadCellProps: {
          sx: {
            width: '125px',
            minWidth: '125px',
            flex: '0 0 auto',
          },
        }, // custom props
        muiTableBodyCellProps: {
          sx: {
            width: '125px',
            minWidth: '125px',
            flex: '0 0 auto',
          },
        },
        Cell: ({ cell }) => {
          const { isConfirmed } = adjustmentContractStore?.contracts?.[
            cell.row.index
          ]
          if (readOnly || isConfirmed) {
            return <Text>{`${parseInt(cell.getValue(), 10)}%`}</Text>
          }
          return (
            <Select
              {...(readOnly
              || adjustmentContractStore?.contracts?.[cell.row.index]?.[
                'isConfirmed'
              ] === true
                ? { open: false }
                : {})}
              value={cell.getValue() >= 0 ? `${cell.getValue()}%` : null}
              onChange={e => handleChange(e, cell)}
              style={{ width: 92, maxWidth: 92 }}
              onSearch={
                value => {
                  if (value) inputValueRef.current = value
                }
              }
              onBlur={() => handleBlur(cell)}
              options={percentageOption()}
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
            />
          )
        },
      },
      {
        accessorKey: 'description',
        header: '비고',
        muiTableHeadCellProps: {
          sx: { flex: '1 0 auto' },
        }, // custom props
        muiTableBodyCellProps: {
          sx: { flex: '1 0 auto', whiteSpace: 'break-word' },
        },
        Cell: ({ cell }) => {
          // const isConfirmed =
          //   adjustmentContractStore?.contracts?.[cell.row.index]?.[
          //   'isConfirmed'
          //   ]
          const isConfirmed = false // 마감된 계약이 있어도 비고는 수정가능하게 변경
          if (readOnly || isConfirmed) {
            return <Text>{cell.getValue()}</Text>
          }
          return (
            <Input.TextArea
              readOnly={readOnly || isConfirmed}
              autoSize
              value={cell.getValue()}
              onChange={
                e => {
                  const update = e.target.value
                  updateContractCell({ cell, update })
                }
              }
              onBlur={() => updateAdjustmentContract(cell.row.index)}
            />
          )
        },
      },
      {
        accessorKey: 'status',
        header: '상태',
        muiTableHeadCellProps: {
          sx: {
            width: '300px',
            minWidth: '300px',
            flex: '0 0 auto',
          },
        }, // custom props
        muiTableBodyCellProps: {
          sx: {
            width: '300px',
            minWidth: '300px',
            flex: '0 0 auto',
          },
        },
        Cell: ({ cell }) => {
          const isConfirmed =
            adjustmentContractStore?.contracts?.[cell.row.index]?.[
              'isConfirmed'
            ]
          return (
            <Text style={{ fontSize: '12px' }}>
              {
                isConfirmed
                  ? '해당 계약은 마감된 정산서가 있어서 삭제할 수 없습니다.'
                  : '수정 가능'
              }
            </Text>
          )
        },
      },
      {
        accessorKey: 'delete',
        header: '',
        muiTableHeadCellProps: {
          sx: {
            width: readOnly ? '0' : '30px',
            minWidth: readOnly ? '0' : '30px',
            flex: '0 0 auto',
            ...(readOnly && { display: 'none' }),
          },
        },
        muiTableBodyCellProps: {
          sx: {
            width: readOnly ? '0' : '30px',
            minWidth: readOnly ? '0' : '30px',
            flex: '0 0 auto',
            ...(readOnly && { display: 'none' }),
          },
        },
        size: 30,
        Cell: ({ cell }) => {
          if (
            !readOnly
            && adjustmentContractStore.contracts[cell.row.index]['isConfirmed']
              === false
            && adjustmentContractStore.contracts[cell.row.index]['isDefault']
              !== true
          ) {
            return (
              <Button
                type="link"
                onClick={() => showContractDeleteModal(cell)}
                icon={<DeleteOutlined style={{ color: '#d9d9d9' }} />}
              />
            )
          }
        },
      },
    ],
    [adjustmentContractStore.contracts],
  )

  const adjustmentTypeColumns = useMemo(
    () => [
      {
        accessorKey: 'type',
        header: '정산 구분',
        muiTableHeadCellProps: {
          sx: {
            width: '250px',
            minWidth: '250px',
            flex: '0 0 auto',
          },
        },
        muiTableBodyCellProps: {
          sx: {
            width: '250px',
            minWidth: '250px',
            flex: '0 0 auto',
          },
        },
        Cell: ({ cell }) => {
          const isConfirmed =
            adjustmentTypeStore?.adjustmentTypeList?.[cell.row.index]?.[
              'isConfirmed'
            ]

          if (readOnly || isConfirmed) {
            return <Text>{cell.getValue()}</Text>
          }
          return (
            <Input
              placeholder="정산 구분"
              value={cell.getValue()}
              onChange={
                e => {
                  const update = e.target.value
                  updateAdjustmentTypeCell({ cell, update })
                }
              }
              onBlur={() => updateAdjustmentType(cell.row.index)}
            />
          )
        },
      },
      {
        accessorKey: 'adjustmentContractId',
        header: '연결된 계약',
        muiTableHeadCellProps: {
          sx: {
            width: '250px',
            minWidth: '250px',
            flex: '0 0 auto',
          },
        },
        muiTableBodyCellProps: {
          sx: {
            width: '250px',
            minWidth: '250px',
            flex: '0 0 auto',
          },
        },
        Cell: ({ cell }) => {
          const contractId = cell.getValue()
          const isConfirmed =
            adjustmentTypeStore?.adjustmentTypeList?.[cell.row.index]?.[
              'isConfirmed'
            ]

          const contractList = adjustmentContractStore?.contracts
            ?.filter(
              item =>
                item.type !== undefined
                && item.type !== null
                && item.type !== ''
                && item.deletedAt === null,
            )
            .map(item => ({ value: item.type, label: item.type }))

          const contract = adjustmentContractStore?.contracts.find(
            item => item._id === contractId,
          )

          if (readOnly || isConfirmed) {
            return <Text>{contract?.type || ''}</Text>
          }
          return (
            <Select
              value={contract?.type || ''}
              onChange={
                option => {
                  console.log('option', option)
                  const selectedContract = adjustmentContractStore.contracts.find(
                    item => item.type === option,
                  )

                  updateAdjustmentTypeCell({ cell, update: selectedContract._id })
                }
              }
              options={contractList}
              style={{ width: 240 }}
              onBlur={() => updateAdjustmentType(cell.row.index)}
            />
          )
        },
      },
      {
        accessorKey: 'description',
        header: '비고',
        muiTableHeadCellProps: {
          sx: {
            flex: '1 0 auto',
          },
        },
        muiTableBodyCellProps: {
          sx: {
            flex: '1 0 auto',
          },
        },
        Cell: ({ cell }) => {
          // const isConfirmed =
          //   adjustmentContractStore?.contracts?.[cell.row.index]?.[
          //   'isConfirmed'
          //   ]
          const isConfirmed = false // 마감된 계약이 있는 구분도 비고는 수정가능하게 변경

          if (readOnly || isConfirmed) {
            return <Text>{cell.getValue()}</Text>
          }
          return (
            <Input
              value={cell.getValue()}
              onChange={
                e => {
                  const update = e.target.value
                  updateAdjustmentTypeCell({ cell, update })
                }
              }
              onBlur={() => updateAdjustmentType(cell.row.index)}
            />
          )
        },
      },
      {
        accessorKey: 'status',
        header: '상태',
        muiTableHeadCellProps: {
          sx: {
            width: '300px',
            minWidth: '300px',
            flex: '0 0 auto',
          },
        }, // custom props
        muiTableBodyCellProps: {
          sx: {
            width: '300px',
            minWidth: '300px',
            flex: '0 0 auto',
          },
        },
        Cell: ({ cell }) => {
          const isConfirmed =
            adjustmentTypeStore?.adjustmentTypeList?.[cell.row.index]?.[
              'isConfirmed'
            ]
          return (
            <Text style={{ fontSize: '12px' }}>
              {
                isConfirmed
                  ? '해당 계약은 마감된 정산서가 있어서 삭제할 수 없습니다.'
                  : '수정 가능'
              }
            </Text>
          )
        },
      },
      {
        accessorKey: 'delete',
        header: '',
        muiTableHeadCellProps: {
          sx: {
            width: readOnly ? '0' : '30px',
            minWidth: readOnly ? '0' : '30px',
            flex: '0 0 auto',
            ...(readOnly && { display: 'none' }),
          },
        },
        muiTableBodyCellProps: {
          sx: {
            width: readOnly ? '0' : '30px',
            minWidth: readOnly ? '0' : '30px',
            flex: '0 0 auto',
            ...(readOnly && { display: 'none' }),
          },
        },
        size: 30,
        Cell: ({ cell }) => {
          if (
            !readOnly
            && adjustmentTypeStore?.adjustmentTypeList?.[cell.row.index]?.[
              'isConfirmed'
            ] === false
            && adjustmentTypeStore?.adjustmentTypeList?.[cell.row.index]?.[
              'isDefault'
            ] !== true
          ) {
            return (
              <Button
                type="link"
                onClick={() => showAdjustmentTypeDeleteModal(cell)}
                icon={<DeleteOutlined style={{ color: '#d9d9d9' }} />}
              />
            )
          }
        },
      },
    ],
    [adjustmentTypeStore.adjustmentTypeList],
  )

  const { imageUri, name } = artist || {}

  const selectedCompany = companyList.find(
    company => company._id === selectedCompanyId,
  )

  const isDeleted = selectedCompany?.connectionInfo?.isDeleted

  return (
    <>
      <RootTemplate user={user} artist={artist} logout={logout}>
        <RoyaltyStatementContainer>
          <RSHeader>
            <ArtistHeader
              artistList={allowViewArtistList}
              type={user?.type}
              currentArtistId={currentArtistId}
              imageUri={imageUri}
              name={name}
              onClick={
                artistId => {
                  history.push(`/contract?artistId=${artistId}`)
                }
              }
            />
            {
              showSaving && (
                <SavingTextContainer $isSaving={isSaving}>
                  {isSaving ? <LoadingOutlined /> : <CheckOutlined />}
                  {isSaving ? ' 저장 중...' : ' 저장됨'}
                </SavingTextContainer>
              )
            }
            {
              lastSavedTime && (
                <SavingTextContainer $isSaving="true">
                  {`${lastSavedTime.format('YYYY년 MM월 DD일 HH:mm')} 저장`}
                </SavingTextContainer>
              )
            }
          </RSHeader>
          <CompanySelector
            isLender={user?.type === 'artist' && !isLoading}
            onChange={
              _companyId => {
                setSelectedCompanyId(_companyId)
              }
            }
            companyList={companyList}
            selectedCompanyId={selectedCompanyId}
            isDeleted={isDeleted}
            pageType={PAGE_TYPE.CONTRACT_PAGE}
          />
          {
            isLoading ? (
              <Spin />
            ) : adjustmentContractStore?.contracts?.length > 0 ? (
              <SheetContainer>
                <H6
                  align={'left'}
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                >
                계약
                </H6>
                <MaterialReactTable
                  columns={contractColumns}
                  data={adjustmentContractStore?.contracts}
                  enablePagination={false}
                  enableBottomToolbar={false}
                  enableTopToolbar={false}
                  enableColumnActions={false}
                  muiTablePaperProps={
                    {
                      sx: {
                        boxShadow: 'none',
                        border: borderColor,
                        ...(readOnly && { borderRight: 'none' }),
                        td: {
                          color: '#242424',
                        },
                        th: {
                          color: '#949494',
                        },
                        '& td > span': {
                          display: 'inline-block',
                          minHeight: '19px',
                        },
                        '& thead > tr': {
                          backgroundColor: '#fafafa',
                          borderBottom: borderColor,
                        },
                        '& td, th': {
                          padding: '8px 4px',
                          fontFamily: 'NotoSansCJKkr-Regular',
                          fontWeight: 400,
                          borderRight: borderColor,
                          borderBottom: 'none',
                          ':last-child': {
                            borderRight: 'none',
                          },
                        },
                        '& tr:not(:last-child)': {
                          borderBottom: borderColor,
                        },
                      },
                    }
                  }
                  muiTableHeadCellProps={
                    {
                      align: columnHeaderAlign,
                    }
                  }
                  muiTableBodyCellProps={
                    {
                      align: columnHeaderAlign,
                    }
                  }
                  initialState={{ density: 'compact' }}
                  layoutMode="grid"
                />
                {
                  !readOnly && (
                    <Button
                      style={
                        {
                          backgroundColor: '#fafafa',
                          color: '#949494',
                          border: borderColor,
                          borderTop: 'none',
                        }
                      }
                      onClick={addContractRow}
                    >
                  + 추가하기
                    </Button>
                  )
                }
                <H6
                  align={'left'}
                  style={{ marginTop: '40px', marginBottom: '10px' }}
                >
                정산 구분
                </H6>
                <MaterialReactTable
                  columns={adjustmentTypeColumns}
                  data={adjustmentTypeStore?.adjustmentTypeList}
                  enablePagination={false}
                  enableBottomToolbar={false}
                  enableTopToolbar={false}
                  enableColumnActions={false}
                  muiTableHeadCellProps={
                    {
                      align: columnHeaderAlign,
                    }
                  }
                  muiTableBodyCellProps={
                    {
                      align: columnHeaderAlign,
                    }
                  }
                  initialState={{ density: 'compact' }}
                  layoutMode="grid"
                  muiTablePaperProps={
                    {
                      sx: {
                        boxShadow: 'none',
                        border: borderColor,
                        td: {
                          color: '#242424', // row 콘텐츠 색상
                        },
                        th: {
                          color: '#949494', // header 콘텐츠 색상
                        },
                        '& td > span': {
                          display: 'inline-block',
                          minHeight: '19px',
                        },
                        '& thead > tr': {
                          backgroundColor: '#fafafa',
                          borderBottom: borderColor,
                        },
                        '& td, th': {
                          padding: '8px 4px',
                          fontFamily: 'NotoSansCJKkr-Regular',
                          fontWeight: 400,
                          borderRight: borderColor,
                          borderBottom: 'none',
                          ':last-child': {
                            borderRight: 'none',
                          },
                        },
                        '& tr:not(:last-child)': {
                          borderBottom: borderColor,
                        },
                      },
                    }
                  }
                />
                {
                  !readOnly && (
                    <Button
                      style={
                        {
                          backgroundColor: '#fafafa',
                          color: '#949494',
                          border: borderColor,
                          borderTop: 'none',
                        }
                      }
                      onClick={addAdjustmentTypeRow}
                    >
                  + 추가하기
                    </Button>
                  )
                }
              </SheetContainer>
            ) : (
              <Flex
                style={
                  {
                    width: '100%',
                    height: '500px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }
                }
              >
                <Text>소속사와 연결하고 작성중인 정산서를 확인해보세요.</Text>
              </Flex>
            )
          }
          <div style={{ paddingBottom: '50px', color: '#abcdef' }} />
        </RoyaltyStatementContainer>
        <Modal
          title="계약 삭제 확인"
          visible={isContractDeleteModalVisible}
          onOk={handleContractDelete}
          onCancel={() => setIsContractDeleteModalVisible(false)}
          okText="삭제"
          cancelText="취소"
          okType="danger"
        >
          <p>정말 삭제하시겠습니까?</p>
        </Modal>
        <Modal
          title="정산 구분 삭제 확인"
          visible={isAdjustmentTypeDeleteModalVisible}
          onOk={handleAdjustmentTypeDelete}
          onCancel={() => setIsAdjustmentTypeDeleteModalVisible(false)}
          okText="삭제"
          cancelText="취소"
          okType="danger"
        >
          <p>정말 삭제하시겠습니까?</p>
        </Modal>
      </RootTemplate>
    </>
  )
}

export default inject(
  'authStore',
  'artistStore',
  'adjustmentContractStore',
  'adjustmentTypeStore',
  'adjustmentStore',
)(observer(CompanyArtistContractPage))
