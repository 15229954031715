import { Caption1, Caption2, Flex, H6, Image } from '@components/atoms'
import { STORAGE_URL } from '@consts'
import styled from 'styled-components'
import { arrowLeft, copyGrayImg } from '@images'
import {
  convertToPricingComma,
  dateYMD,
  insightConverseUnitEng,
} from '@utils/format'
import React, { useMemo, useState } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts'

import { Table } from 'antd'
import { IncreaseTextBox } from '@components/molecules'

const YoutubeVideoViewCard = ({ item }) => {
  const [isShow, setIsShow] = useState(true)

  return (
    <Flex type="column">
      <Card onClick={() => setIsShow(prev => !prev)}>
        <Flex
          style={{ position: 'relative', cursor: 'pointer' }}
          onClick={
            e => {
              e.preventDefault()
              e.stopPropagation()

              window.open(
                `https://plam.in/track/${item.trackId}?youtubeIdx=${item.index}`,
              )
            }
          }
        >
          <Flex
            style={
              {
                position: 'absolute',
                top: 4,
                left: 4,
                width: 20,
                height: 20,
                borderRadius: '50%',
                backgroundColor: 'black',
              }
            }
            justify="center"
          >
            <Caption2 color="white" style={{ lineHeight: '18px' }}>
              {(item?.index || 0) + 1}
            </Caption2>
          </Flex>
          <img
            src={STORAGE_URL + item?.youtubeVideoInfo?.image128Path}
            width={160}
            height={90}
          />
        </Flex>
        <Flex
          type="column"
          style={
            {
              flex: 1,
              justifyContent: 'center',
              alignItems: 'flex-start',
            }
          }
        >
          <Caption1 color="#949494">
            {item?.youtubeVideoInfo?.channelTitle}
          </Caption1>
          <H6
            style={{ margin: '4px 0 6px 0', cursor: 'pointer' }}
            onClick={
              e => {
                e.preventDefault()
                window.open(item.link)
              }
            }
          >
            {item?.youtubeVideoInfo?.title}
          </H6>
          <Flex>
            <Caption2 color="#646464" type="Regular">
              {dateYMD(item?.youtubeVideoInfo?.publishedAt)}
            </Caption2>
            <Caption2 color="#646464">{` ∙ `}</Caption2>
            <Caption2 color="#646464" type="Regular">
              조회수
              {' '}
              {convertToPricingComma(item.youtubeVideoInfo?.viewCount)}
회
            </Caption2>
            <Caption2 color="#646464">{` ∙ `}</Caption2>
            <Caption2 color="#646464" type="Regular">
              좋아요 수
              {' '}
              {convertToPricingComma(item.youtubeVideoInfo?.likeCount)}
명
            </Caption2>
          </Flex>
        </Flex>
        <Image
          src={arrowLeft}
          width={12}
          height={12}
          transform={'rotate(-90deg)'}
        />
      </Card>
      {
        isShow && (
          <>
            <Flex
              gap="5px"
              align="center"
              style={{ cursor: 'pointer' }}
              onClick={() => window.open(item.link)}
            >
              <Flex
                style={
                  {
                    width: 28,
                    height: 28,
                    borderRadius: '50%',
                    backgroundColor: '#f4f4f4',
                  }
                }
                justify="center"
                align="center"
              >
                <img src={copyGrayImg} width={20} height={20} />
              </Flex>
              <Caption2>{item.link}</Caption2>
            </Flex>
            <Flex gap="24px">
              <ViewChart dailyListData={item?.youtubeVideoTrendList || []} />
              <ViewTable data={item?.youtubeVideoTrendList || []} />
            </Flex>
          </>
        )
      }
    </Flex>
  )
}

export default YoutubeVideoViewCard

const Card = styled(Flex)`
  width: 100%;
  height: 122px;
  padding: 16px 0;
  gap: 20px;
  align-items: center;
  padding-right: 24px;
  cursor: pointer;
`

const ToolTipBox = styled(Flex)`
  width: 280px;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  gap: 12px;
`

const COLORS = ['#ea4653']

const ViewChart = ({ dailyListData }) => {
  return (
    <div
      style={
        {
          marginTop: '30px',
          borderRadius: '6px',
          boxShadow: '0 4px 10px 0 rgba(36,36,36,0.16)',
          backgroundColor: '#ffffff',
          padding: '20px',
          width: '100%',
          height: '333px',
        }
      }
    >
      {
        !dailyListData ? (
          <Flex
            style={
              {
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }
            }
          >
            <Caption1>No data</Caption1>
          </Flex>
        ) : (
          <ResponsiveContainer width="100%" height={333}>
            <LineChart
              data={
                dailyListData.map(elem => ({
                  ...elem,
                  increaseValueViewCount: elem?.increaseValue?.viewCount,
                  increaseValueLikeCount: elem?.increaseValue?.likeCount,
                })).reverse()
              }
              margin={
                {
                  top: 0,
                  right: 0,
                  bottom: 50,
                  left: 50,
                }
              }
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="date"
                tickFormatter={
                  tick => {
                    const strTick = `${tick}`
                    return `          ${strTick.slice(2, 4)}.${strTick.slice(
                      4,
                      6,
                    )}.${strTick.slice(6, 8)}          `
                  }
                }
                allowDuplicatedCategory={false}
                padding={{ left: 40, right: 40 }}
              />
              <YAxis
                dataKey="increaseValueViewCount"
                yAxisId="left"
                domain={['auto', 'auto']}
                tickFormatter={value => insightConverseUnitEng(value)}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                verticalAlign="top"
                layout="horizontal"
                align="left"
                height="50px"
              />
              <Line
                dataKey="increaseValueViewCount"
                yAxisId="left"
                name="View"
                type="monotone"
                stroke={COLORS[0]}
              />
            </LineChart>
          </ResponsiveContainer>
        )
      }
    </div>
  )
}

const CustomTooltip = ({ active, payload, label: _label }) => {
  const item = payload && payload[0] && payload[0].payload && payload[0].payload
  const label = `${_label}`

  if (active) {
    return (
      <ToolTipBox type="column">
        <Caption2
          type="Bold"
          align="left"
          color="#646464"
          style={{ fontSize: 10 }}
        >
          {
            `${label.slice(0, 4)}년 ${label.slice(4, 6)}월 ${label.slice(
              6,
              8,
            )}일`
          }
        </Caption2>
        <Flex justify="space-between">
          <Flex align="center" style={{ gap: 4 }}>
            <div
              style={
                {
                  display: 'inline-block',
                  width: 6,
                  height: 6,
                  borderRadius: '50%',
                  backgroundColor: COLORS[0],
                }
              }
            />
            <Caption2>View</Caption2>
            <Caption2 style={{ fontSize: 10 }} color="#949494">
              *Youtube
            </Caption2>
          </Flex>
          <Caption2 align="left">
            {`${item && convertToPricingComma(item?.increaseValue?.viewCount)}`}
          </Caption2>
        </Flex>
      </ToolTipBox>
    )
  }
  return null
}

const TableBox = styled.div`
  width: 100%;
  height: 333px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  padding: 0 30px 30px 30px;
  margin: 30px 0;

  thead > tr > th {
    background-color: transparent;
    color: #646464;
  }

  thead > tr > th:nth-child(1),
  th:nth-child(2),
  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5) {
    text-align: center;
    min-width: 88px;
  }

  td {
    text-align: center;
  }
`

const ViewTable = ({ data }) => {
  const sortHandler = (a, b, sortOrder) => {
    if(a < b){
      return -1
    }
    else if(a > b){
      return 1
    }
    else{
      return 0
    }
  }

  const columns = [
    {
      title: '날짜',
      key: 'date',
      dataIndex: 'date',
      width: '160px',
      render: text => {
        const strText = `${text}`
        return `${strText?.slice(0, 4)}. ${strText?.slice(
          4,
          6,
        )}. ${strText?.slice(6, 8)}`
      },
      sorter: (a, b, sortOrder) => {
        return sortHandler(a.date, b.date)
      }
    },
    {
      title: (
        <Flex type="column">
          <Caption1 color="#646464">View</Caption1>
        </Flex>
      ),
      key: `increaseValue.viewCount`,
      dataIndex: ['increaseValue', 'viewCount'],
      render: (text, record) => {
        return (
          <Flex type="column">
            <Caption1>{convertToPricingComma(text)}</Caption1>
            <IncreaseTextBox
              data={record.increaseValueOfincreaseValue?.['viewCount'] || null}
              style={{ alignSelf: 'center' }}
            />
          </Flex>
        )
      },
      sorter: (a, b, sortOrder) => {
        return sortHandler(a?.increaseValue.viewCount, b?.increaseValue.viewCount)
      }
    },
    {
      title: (
        <Flex type="column">
          <Caption1 color="#646464">누적 View</Caption1>
        </Flex>
      ),
      key: `viewCount`,
      dataIndex: `viewCount`,
      render: (text, record) => {
        return (
          <Flex type="column">
            <Caption1>{convertToPricingComma(text)}</Caption1>
            <IncreaseTextBox
              data={record.increaseValue?.['viewCount'] || null}
              style={{ alignSelf: 'center' }}
            />
          </Flex>
        )
      },
      sorter: (a, b, sortOrder) => {
        return sortHandler(a?.viewCount, b?.viewCount)
      }
    },
    {
      title: (
        <Flex type="column">
          <Caption1 color="#646464">Like</Caption1>
        </Flex>
      ),
      key: `likeCount`,
      dataIndex: ['increaseValue', `likeCount`],
      render: (text, record) => {
        return (
          <Flex type="column">
            <Caption1>{convertToPricingComma(text)}</Caption1>
            <IncreaseTextBox
              data={record.increaseValueOfincreaseValue?.['likeCount'] || null}
              style={{ alignSelf: 'center' }}
            />
          </Flex>
        )
      },
      sorter: (a, b, sortOrder) => {
        return sortHandler(a?.increaseValue?.likeCount, b?.increaseValue?.likeCount)
      }
    },
  ]

  return (
    <TableBox>
      <Table
        scroll={{ y: 260 }}
        dataSource={data || []}
        rowKey="date"
        pagination={false}
        columns={columns}
      />
    </TableBox>
  )
}
