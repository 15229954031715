import { observable, computed } from 'mobx'
import { v4 } from 'uuid'

import { Network } from './networks'

import AuthStore from './AuthStore'
import NoticeStore from './NoticeStore'
import FaqStore from './FaqStore'
import QuestionStore from './QuestionStore'
import ArtistStore from './ArtistStore'
import AlbumStore from './AlbumStore'
import AppStateStore from './AppStateStore'
import StatsStore from './StatsStore'
import ConnectionsStore from './ConnectionsStore'
import RevenueStore from './RevenueStore'
import TrendStore from './TrendStore'
import SettlementStore from './SettlementStore'

/** 정산서 */
import AdjustmentStore from './royaltyStatements/AdjustmentStore'
import AdjustmentDetailStore from './royaltyStatements/AdjustmentDetailStore'
import AdjustmentContractStore from './royaltyStatements/AdjustmentContractStore'
import AdjustmentTypeStore from './royaltyStatements/AdjustmentTypeStore'

export {
  AuthStore,
  NoticeStore,
  FaqStore,
  QuestionStore,
  ArtistStore,
  AlbumStore,
  AppStateStore,
  StatsStore,
  ConnectionsStore,
  RevenueStore,
  TrendStore,
  SettlementStore,
  AdjustmentStore,
  AdjustmentDetailStore,
  AdjustmentContractStore,
  AdjustmentTypeStore,
}

export class Store {
  @computed get isLoading() {
    const list = Object.keys(this.handlerObj)
    let res = false
    if (this.handlerObj && list && list.length > 0) {
      res = true
    }

    return res
  }
  @observable handlerObj = {}

  _network: Network

  authStore: AuthStore
  noticeStore: NoticeStore
  faqStore: FaqStore
  questionStore: QuestionStore
  artistStore: ArtistStore
  albumStore: AlbumStore
  appStateStore: AppStateStore
  statsStore: StatsStore
  connectionsStore: ConnectionsStore
  revenueStore: RevenueStore
  trendStore: TrendStore
  settlementStore: SettlementStore

  adjustmentStore: AdjustmentStore
  adjustmentDetailStore: AdjustmentDetailStore
  adjustmentContractStore: AdjustmentContractStore
  adjustmentTypeStore: AdjustmentTypeStore

  constructor(hydrateCallback) {
    this._network = new Network({
      getJwt: () => this.authStore['jsonWebToken'],
    })

    this.authStore = new AuthStore(this, this._network)
    this.noticeStore = new NoticeStore(this, this._network)
    this.faqStore = new FaqStore(this, this._network)
    this.questionStore = new QuestionStore(this, this._network)
    this.artistStore = new ArtistStore(this, this._network)
    this.albumStore = new AlbumStore(this, this._network)
    this.appStateStore = new AppStateStore(this, this._network)
    this.statsStore = new StatsStore(this, this._network)
    this.connectionsStore = new ConnectionsStore(this, this._network)
    this.revenueStore = new RevenueStore(this, this._network)
    this.trendStore = new TrendStore(this, this._network)
    this.settlementStore = new SettlementStore(this, this._network)

    this.adjustmentStore = new AdjustmentStore(this, this._network)
    this.adjustmentDetailStore = new AdjustmentDetailStore(this, this._network)
    this.adjustmentContractStore = new AdjustmentContractStore(
      this,
      this._network,
    )
    this.adjustmentTypeStore = new AdjustmentTypeStore(this, this._network)

    console.log(`[DEV][LOG][Store][constructor] end`)
  }

  initClient = async () => {
    await Promise.all([
      this.authStore &&
        this.authStore.initClient &&
        this.authStore.initClient(),
    ])
    console.log(`[DEV][LOG][Store][initClient] end`)
  }

  useLoading = async func => {
    if (this && func) {
      const handlerKey = v4()
      this.handlerObj[handlerKey] = { isLoading: true }

      let res = null
      try {
        res = await func()
      } catch (error) {
        console.log('[Store] [useLoading] error', error)
      }

      delete this.handlerObj[handlerKey]

      return res
    }
  }
}
