import React from 'react';
import styled from 'styled-components';
import {InvisibleInput } from '@components/atoms'

const no_check_img = require('@images/no_check_plam.png')
const check_img = require('@images/check_plam.png')

const Box = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;

    > label {
        background-image : url(${no_check_img});
        background-size : 22px;
        background-repeat : no-repeat;
        background-position: left;
    }

    > input[type="checkbox"]:checked + label {
        background-image : url(${check_img});
        background-size : 22px;
        background-repeat : no-repeat;
        background-position: left;
    }
`

const Text = styled.label`
    padding-left: 32px;
    cursor : pointer;
    font-size : 18px;
    `

export const CheckBox = ({text, value, _ref}) => {
    return (
        <Box>
            <InvisibleInput id={value} type="checkbox" name={value} ref={_ref}/>
            <Text htmlFor={value}>{text}</Text>
        </Box>
    );
};
